import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { CapSentence, convertDateFormat } from "./utils/Utils";
import AvatarAzul from "../assetss/img/logo_azul_reportes.png";
import Image from "mui-image";

export const RenderReportParteDiarioPrestaciones = React.forwardRef(
  (props, ref) => {
    const { allData } = props;
    const cellStyle = {
      fontFamily: "Open Sans",
      fontSize: "11px",
      padding: "1px",
      height: "10px",
    };
    const headerStyle = {
      border: "1px solid #94a3b8",
      height: "10px",
      padding: "8px",
      fontWeight: "bold",
      fontFamily: "Open Sans",
      marginBottom: "10px",
      backgroundColor: "#f1f5f9",
    };
    const rowsPerPage = 35;

    const tableHeadStyle = {
      marginBottom: "10px",
      padding: "20px", // Adjust this as needed
    };

    const cantPacientes = new Set(allData.map((item) => item.paciente_id));
    const cantPrestaciones = new Set(
      allData.map((item) => item.tratamiento_id)
    );

    const renderTableHeader = () => (
      <>
        <TableRow style={tableHeadStyle}>
          <TableCell style={{ ...headerStyle, width: "5%" }}>Hora</TableCell>
          <TableCell style={{ ...headerStyle, width: "5%" }}>Emp.</TableCell>
          <TableCell style={{ ...headerStyle, width: "5%" }}>
            Documento
          </TableCell>
          <TableCell style={{ ...headerStyle, width: "35%" }}>Nombre</TableCell>
          <TableCell style={{ ...headerStyle, width: "10%" }}>Código</TableCell>
          <TableCell style={{ ...headerStyle, width: "30%" }}>
            Descripción
          </TableCell>
          <TableCell style={{ ...headerStyle, width: "5%" }}>Pieza</TableCell>
          <TableCell style={{ ...headerStyle, width: "5%" }}>Caras</TableCell>
        </TableRow>
        <TableRow />
      </>
    );

    const evenRowStyle = {
      backgroundColor: "#f8fafc", // Light background color for even rows
      height: "30px", // Fixed row height
    };

    const oddRowStyle = {
      backgroundColor: "#ffffff", // Default background color for odd rows
      height: "30px", // Fixed row height
    };

    return (
      <div ref={ref} style={{ width: "100%", padding: "20px" }}>
        <style
          type="text/css"
          media="print"
          dangerouslySetInnerHTML={{
            __html: `
                  @page {
                    size: Legal ;
                    margin: 20mm;
                  }
                  @media print {
                    body {
                      -webkit-print-color-adjust: exact;
                    }
                    .page-break {
                      page-break-before: always;
                       margin-top: 35mm; 
                    }
                    .content {
                      margin-top: 3cm; 
                    }
                  }
                `,
          }}
        />
        <div className="header">
          <Grid container>
            <Grid item xs={6}>
              <Box
                display="flex"
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  height: "8vh", // Adjust this as needed
                }}
              >
                <Image
                  src={AvatarAzul}
                  height="65px"
                  width="256px"
                  duration={0}
                  sx={{
                    marginLeft: 4,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  marginTop: 2,
                  px: 2,
                  alignItems: "start",
                }}
              >
                <Typography variant="h7">
                  {CapSentence(allData[0].centro)}
                </Typography>
                <Typography variant="h7">
                  {CapSentence(allData[0].especialidad)}
                </Typography>
                <Typography variant="h7">
                  {CapSentence(allData[0].odontologo)}
                </Typography>
                <Typography variant="h7">
                  {convertDateFormat(allData[0].fecha)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="content">
          <TableContainer component={Paper}>
            <Table>
              {renderTableHeader()}
              <TableBody>
                {allData
                  .filter((p) => p.nomenclador_id !== null)
                  .map((row, index) => {
                    const isNewPage = index % rowsPerPage === 0;
                    return (
                      <React.Fragment key={index}>
                        {isNewPage && index !== 0 && (
                          <div className="page-break"></div>
                        )}
                        {isNewPage && index !== 0 && renderTableHeader()}
                        <TableRow
                          key={index}
                          style={index % 2 === 0 ? evenRowStyle : oddRowStyle}
                        >
                          <TableCell style={cellStyle}>
                            {row.hora_desde}
                          </TableCell>
                          <TableCell style={cellStyle}>
                            {row.codigo_empresa}
                          </TableCell>
                          <TableCell style={cellStyle}>
                            {row.documento_paciente}
                          </TableCell>
                          <TableCell style={cellStyle}>
                            {CapSentence(row.nombre_paciente)}
                          </TableCell>
                          <TableCell style={cellStyle}>{row.codigo}</TableCell>
                          <TableCell style={cellStyle}>
                            {CapSentence(row.descripcion)}
                          </TableCell>
                          <TableCell style={cellStyle}>
                            {row.diente !== null ? row.diente : ""}
                          </TableCell>
                          <TableCell style={cellStyle}>
                            {row.caras !== null ? row.caras : ""}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="mt-6">
            <h2>Cantidad de pacientes: {cantPacientes.size}</h2>
            <h2>Cantidad de prestaciones: {cantPrestaciones.size}</h2>
          </div>
        </div>
      </div>
    );
  }
);
