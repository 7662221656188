import React, { useState, useRef, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { ModeloGetUrl } from "../ModeloCrud";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CapSentence } from "../utils/Utils";
import * as yup from "yup";
import "dayjs/locale/en-gb";
import crypto from "../Crypto";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalInformation from "../modals/ModalInformation";
import { CapSentenceArray } from "../utils/Utils";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const validationSchema = yup.object({
  codigo: yup.object().required("Seleccione un Código"),
  alternativa: yup
    .number()
    .typeError("Campo Numérico.")
    .required("Campo Obligatorio.")
    .min(1, "Mínimo 1.")
    .max(10, "Máximo 10."),
  caras: yup
    .string()
    .nullable(true)
    .matches(/[o,m,d,p,v,l,g,i,O,M,D,P,V,L,G,I]+/gi, "Cara(s) Inválida(s).")
    .max(5, "Máximo 5 Caracteres."),
});

export default function InsertPresupuestoItem({
  newItems,
  setNewItems,
  setExpanded,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const piezasDentales = crypto.decryptDataStorage("piezas_dentales");
  const nomencladores = CapSentenceArray(
    crypto.decryptDataStorage("nomencladores"),
    ["codigo_descripcion"]
  );
  const planId = crypto.decryptDataStorage("currentplanid");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      alternativa: 1,
      codigo: null,
      pieza: null,
      caras: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      agregarItem(values);
    },
  });

  const piezaInputRef = useRef(null);
  const carasInputRef = useRef(null);
  const addButtonInputRef = useRef(null);

  useEffect(() => {
    // Focus on the input element when it mounts
    if (addButtonInputRef.current) {
      addButtonInputRef.current.focus();
    }
  }, []);

  async function agregarItem(values) {
    let seguir = true;

    if (
      formik.values.codigo.requiere_pieza === 1 &&
      formik.values.pieza === null
    ) {
      formik.setFieldError("pieza", "El código requiere pieza.");
      seguir = false;
    }
    if (
      formik.values.codigo.requiere_cara === 1 &&
      formik.values.caras === null
    ) {
      formik.setFieldError("caras", "El código requiere cara(s).");
      seguir = false;
    }
    if (!seguir) {
      return;
    }
    var row = {};
    let maxValue = newItems.reduce(
      (acc, item) => (acc = acc > item.id ? acc : item.id),
      0
    );
    row["id"] = maxValue + 1;
    row["aprobado"] = 1;
    row["ordenes"] = "FALSE";
    row["idoriginal"] = -1;
    row["alternativa"] = formik.values.alternativa;
    row["codigo"] = formik.values.codigo.codigo;
    row["nomenclador_id"] = formik.values.codigo.nomenclador_id;
    row["descripcion"] = formik.values.codigo.descripcion;
    row["pieza"] =
      formik.values.pieza !== null ? formik.values.pieza.diente : null;
    row["pieza_dental_id"] =
      formik.values.pieza !== null ? formik.values.pieza.id : null;
    row["caras"] = formik.values.pieza !== null ? formik.values.caras : null;
    row["importe"] = 0;
    setLoading(true);
    let result = await ModeloGetUrl(
      "plan-prepaga",
      planId,
      "precios-nomenclador/" + formik.values.codigo.nomenclador_id,
      null
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        row["importe"] = result.listado[0].precio;
      }
    }
    newItems.push(row);
    const nextList = [...newItems];
    setNewItems(nextList);
    // setExpanded(false);
    //  setExpanded(true);
  }

  const onCodigoChange = (event, values) => {
    formik.setFieldValue("codigo", values);
  };
  const onPiezaChange = (event, values) => {
    formik.setFieldValue("pieza", values);
  };

  const handleKeyDownCodigo = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      event.preventDefault(); // Prevent form submission
      if (piezaInputRef.current) {
        piezaInputRef.current.focus();
      }
    }
  };

  const handleKeyDownPieza = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      event.preventDefault(); // Prevent form submission

      if (carasInputRef.current) {
        carasInputRef.current.focus();
      }
    }
  };

  const handleKeyDownAutoComplete = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 3].focus();
      event.preventDefault();
    }
  };

  const handleKeyDownTextField = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 2].focus();
      event.preventDefault();
    }
  };

  const handleKeyDownAddButton = (event) => {
    if (event.key === "Enter") {
      // Trigger the click event
      event.preventDefault();
      event.target.click();
    }
  };

  const handleClose = () => {
    setExpanded(false);
  };

  return (
    <>
      <div>
        <form key="item-form" onSubmit={formik.handleSubmit}>
          <Container maxWidth="lg">
            <Box sx={{ marginTop: 2, marginBottom: 6 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={2}>
                  <TextField
                    id="alternativa"
                    onKeyDown={handleKeyDownTextField}
                    fullWidth
                    label="Alternativa"
                    value={formik.values.alternativa}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.alternativa
                        ? formik.errors.alternativa
                        : ""
                    }
                    error={
                      formik.touched.alternativa &&
                      Boolean(formik.errors.alternativa)
                    }
                    margin="dense"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  sx={{
                    marginTop: 1,
                  }}
                >
                  <Autocomplete
                    id="codigo"
                    options={nomencladores}
                    value={formik.values.codigo}
                    autoHighlight
                    size="small"
                    onChange={onCodigoChange}
                    getOptionLabel={(option) =>
                      CapSentence(option.codigo_descripcion.replace(/:/g, ""))
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={handleKeyDownCodigo}
                        label="Código"
                        error={
                          formik.touched.codigo && Boolean(formik.errors.codigo)
                        }
                        helperText={
                          formik.touched.codigo && formik.errors.codigo
                        }
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: { justifyContent: "center" },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={2}
                  sx={{
                    marginTop: 1,
                  }}
                >
                  <Autocomplete
                    id="pieza"
                    options={piezasDentales}
                    value={formik.values.pieza}
                    autoHighlight
                    size="small"
                    onChange={onPiezaChange}
                    getOptionLabel={(option) => option.diente}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        inputRef={(el) => (piezaInputRef.current = el)} // Callback ref to assign the input element
                        {...params}
                        onKeyDown={handleKeyDownPieza}
                        label="Pieza"
                        error={
                          formik.touched.pieza && Boolean(formik.errors.pieza)
                        }
                        helperText={formik.touched.pieza && formik.errors.pieza}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: { justifyContent: "center" },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8} lg={2}>
                  <TextField
                    inputRef={(el) => (carasInputRef.current = el)} // Callback ref to assign the input element
                    id="caras"
                    label="Caras"
                    onKeyDown={handleKeyDownTextField}
                    value={formik.values.caras}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.caras ? formik.errors.caras : ""}
                    error={formik.touched.caras && Boolean(formik.errors.caras)}
                    margin="dense"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} lg={1}>
                  <Tooltip title="Agegar Item">
                    <LoadingButton
                      inputRef={addButtonInputRef} // Callback ref to assign the input element
                      onClick={formik.handleSubmit}
                      onKeyDown={handleKeyDownAddButton}
                      color="primary"
                      fullWidth
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<AddRoundedIcon />}
                      variant="contained"
                      sx={{
                        backgroundColor: "icongreen",
                        ":hover": {
                          bgcolor: "#475569", // theme.palette.primary.main
                          color: "white",
                        },
                        textTransform: "capitalize",
                        marginTop: 1,
                        height: 40,
                      }}
                    ></LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </form>
      </div>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
}
