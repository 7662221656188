import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import SaveIcon from "@mui/icons-material/Save";
import { ModeloPost } from "../ModeloCrud";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CapSentence, FormikValidatePhone } from "../utils/Utils";
import * as yup from "yup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import "dayjs/locale/en-gb";
import crypto from "../Crypto";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import ModalInformation from "../modals/ModalInformation";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Draggable from "react-draggable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      <AddIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const fullCelularValidation = (celular, email) => {
  let valid = true;
  let errorMessage = "";
  if (/^[\d-]+$/.test(celular) === false) {
    valid = false;
    errorMessage = "El celular solo tiene que tener números.";
  } else if (celular.trim().length === 10 && celular.slice(0, 2) !== "15") {
    valid = false;
    errorMessage =
      "El celular en Amba tiene que tener 10 dígitos y empezar con 15.";
  } else if (
    celular.slice(0, 1) === "0" &&
    (celular.trim().length < 10 || celular.trim().length > 14)
  ) {
    valid = false;
    errorMessage =
      "El celular en Interior tiene que empezar con 0 y tener entre 10 y 14 dígitos.";
  } else if (celular.trim().length < 10 || celular.trim().length > 14) {
    valid = false;
    errorMessage = "El celular no cumple los requerimientos de formato.";
  }
  return valid
    ? {
        isValid: true,
      }
    : {
        isValid: false,
        errorMessage: errorMessage,
      };
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = yup.object({
  motivo: yup.object().required("Seleccione un Motivo"),
  email: yup
    .string()
    .required("Campo Obligatorio.")
    .email("Ingrese un Email válido."),
  celular: yup
    .string()
    .required("Campo Obligatorio.")
    .test("validator-custom-celular", function (value) {
      const validation = FormikValidatePhone(value);
      if (!validation.isValid) {
        return this.createError({
          path: this.path,
          message: validation.errorMessage,
        });
      } else {
        return true;
      }
    }),
});

export default function ModalAsignarTurnos({
  turnos,
  showModal,
  hideModal,
  buscaragenda,
  tipoTurno,
}) {
  const [allPrepagas, setAllPrepagas] = useState(null);
  const [allPlanes, setAllPlanes] = useState(null);
  const [planesLista, setPlanesLista] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [myloading, setMyLoading] = React.useState(false);
  const [fagendaChecked, setFagendaChecked] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const [piezasDentales, setPiezasDentales] = useState(
    crypto.decryptDataStorage("piezas_dentales")
  );
  const [derivadores, setDerivadores] = useState(
    crypto.decryptDataStorage("derivadores")
  );
  const [selectedMotivo, setSelectedMotivo] = useState(null);
  const [selectedPiezas, setSelectedPiezas] = useState([]);
  const [selectedDerivador, setSelectedDerivador] = useState(null);
  const [loading, setLoading] = useState(false);
  const [motivosEspecialidad, setMotivosEspecialidad] = useState([]);
  const [agLugar, setAgLugar] = useState("");
  const [agOdontologo, setAgOdontologo] = useState("");
  const [agFecha, setAgFecha] = useState("");
  const [agFechaEng, setAgFechaEng] = useState("");
  const currentpacpreid = crypto.decryptDataStorage("currentpacpreid");
  const currentPatient = crypto.decryptDataStorage("dataPaciente");

  const formik = useFormik({
    initialValues: {
      email: crypto.decryptDataStorage("dataPaciente").email,
      celular: crypto.decryptDataStorage("dataPaciente").celular,
      motivo: null,
      piezas: [],
      derivador: null,
      observacion: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      grabarTurno(values);
    },
  });

  useEffect(() => {
    InformacionInicial();
  }, []);

  function InformacionInicial() {
    const data = turnos[0];
    setAgLugar(
      data["clinica_domicilio"].toLowerCase() +
        "  -  " +
        data["clinica_localidad"].toLowerCase()
    );
    setAgOdontologo(data["odontologo"].toLowerCase());
    setAgFecha(data["fecha_ag_arg"]);
    setAgFechaEng(data["fecha_ag"]);
    const motivos = crypto.decryptDataStorage("motivos_especialidades");
    if (motivos !== null) {
      const motivos_especialidades = motivos.filter(
        (g) => g.especialidad_id === data["especialidad_id"]
      );
      setMotivosEspecialidad(motivos_especialidades);
    }
  }

  async function grabarTurno(values) {
    const data = turnos;
    let asignarids = "";
    let tipoturno = "";
    let piezas = "";
    let operacion = "";
    let asTipoTurno = "";

    if (tipoTurno === "Editar") {
      operacion = "editar";
    } else if (tipoTurno.slice(0, 1) === "T") {
      asTipoTurno = "T";
      operacion = "asignar";
    } else if (tipoTurno.slice(0, 1) === "E") {
      asTipoTurno = "E";
      operacion = "asignar";
    } else {
      setModalMessage("Tarea desconocida.Avise a Sistemas.");
      setModalInformationOpen(true);
      return;
    }
    let derivadopor =
      selectedDerivador === null ? "" : selectedDerivador.odontologo;
    for (let i = 0; i < data.length; i++) {
      asignarids += asignarids.length === 0 ? data[i].id : "," + data[i].id;
      tipoturno = asTipoTurno;
    }
    for (let i = 0; i < selectedPiezas.length; i++) {
      piezas +=
        piezas.length === 0
          ? selectedPiezas[i].diente
          : "-" + selectedPiezas[i].diente;
    }
    let seguir = true;
    const motivo = selectedMotivo;
    if (motivo.requiere_pieza === 1) {
      if (selectedPiezas.length === 0) {
        formik.setFieldError("piezas", "El motivo requiere piezas.");
        seguir = false;
      }
    }
    if (motivo.requiere_derivador === 1) {
      if (selectedDerivador === null) {
        formik.setFieldError("derivador", "El motivo requiere derivador.");
        seguir = false;
      }
    }
    if (seguir) {
      setErrorSave(null);
      const dataAsignar = {
        turno_id: asignarids,
        tipo: tipoturno,
        motivo_turno_id: parseInt(motivo.motivo_turno_id),
        derivado_por:
          selectedDerivador === null ? "" : selectedDerivador.odontologo,
        piezas: piezas,
        observaciones: formik.values.observacion,
        fuera_de_agenda: fagendaChecked ? 1 : 0,
        email: values.email,
        celular: values.celular,
      };
      const dataEditar = {
        turno_id: asignarids,
        motivo_turno_id: parseInt(motivo.motivo_turno_id),
        derivado_por:
          selectedDerivador === null ? "" : selectedDerivador.odontologo,
        piezas: piezas,
        observaciones: formik.values.observacion,
      };

      setMyLoading(true);
      let result = "";
      if (operacion === "asignar") {
        result = await ModeloPost(
          "paciente-prepaga",
          currentpacpreid,
          "asignar-turno-web",
          dataAsignar
        );
      } else {
        result = await ModeloPost(
          "paciente-prepaga",
          null,
          "editar-turno-web",
          dataEditar
        );
      }
      setMyLoading(false);

      if (result.error === false) {
        if (operacion === "asignar" && typeof result.listado === "object") {
          crypto.encryptDataStorage("dataPaciente", result.listado);
        }
        if (buscaragenda !== null) {
          buscaragenda(agFechaEng);
        }
        handleClose();
      } else {
        if (
          Array.isArray(result.errormessage) === true &&
          result.errormessage.length > 0
        ) {
          setModalMessage(result.errormessage[0].error);
          setModalInformationOpen(true);
        } else {
          setModalMessage(result.errormessage);
          setModalInformationOpen(true);
        }
      }
    }
  }

  const handleChangeFAgendaChecked = (event) => {
    setFagendaChecked(event.target.checked);
  };

  const handleClose = () => {
    hideModal();
  };

  const onMotivosChange = (event, values) => {
    if (values !== null) {
      formik.setFieldValue("motivo", values);
      setSelectedMotivo(values);
      crypto.encryptDataStorage("selectedmotivo", values);
    } else {
      formik.setFieldValue("motivo", null);
      sessionStorage.removeItem("selectedmotivo");
      setSelectedMotivo(null);
    }
  };

  const onPiezasChange = (event, values) => {
    if (values !== null) {
      formik.setErrors("piezas", null);
      formik.setFieldValue("piezas", values);
      setSelectedPiezas(values);
    } else {
      formik.setFieldValue("piezas", []);
      setSelectedPiezas([]);
    }
  };

  const onDerivadorChange = (event, values) => {
    if (values !== null) {
      formik.setErrors("derivador", null);
      formik.setFieldValue("derivador", values);
      setSelectedDerivador(values);
    } else {
      formik.setFieldValue("derivador", null);
      setSelectedDerivador(null);
    }
  };

  return (
    <>
      <div>
        <Draggable cancel=".content, .actions">
          <BootstrapDialog
            hideBackdrop={true}
            disableAutoFocus
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            open={showModal}
            fullWidth={true}
            maxWidth="xs"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "20px",
              },
              "& .MuiPaper-root": {
                backgroundColor: "bgmodal", // Adjust transparency here
              },
            }}
          >
            <BootstrapDialogTitle
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
              onClose={handleClose}
            >
              Asignar Turnos
            </BootstrapDialogTitle>
            <form onSubmit={formik.handleSubmit}>
              <DialogContent
                className="content"
                sx={{
                  mt: -2,
                  mb: -6,
                }}
              >
                <div className="flex justify-center items-center ">
                  <Grid
                    container
                    spacing={2}
                    marginTop={0.5}
                    marginBottom={4}
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    <Paper
                      elevation={2}
                      sx={{
                        width: 400,
                        height: "auto",
                        pt: 1,
                        pb: 2,
                        bgcolor: "#e5e5e5",
                        ml: 1,
                      }}
                    >
                      <Box sx={{ ml: 4, justifyContent: "center" }}>
                        <div className="flex mt-2 -ml-2 items-end capitalize ">
                          <i className='fas fa-location-dot fa-xl " text-slate-500' />
                          <p className="ml-4 font-semibold">{agLugar}</p>
                        </div>
                        <div className="flex mt-2 -ml-2 items-end capitalize">
                          <i className="fas fa-user fa-xl  text-slate-500" />
                          <p className="ml-4 font-semibold">{agOdontologo}</p>
                        </div>
                      </Box>
                    </Paper>
                    <TableContainer
                      component={Paper}
                      sx={{
                        width: 400,
                        height: "auto",
                        pb: 1,
                        bgcolor: "#e5e5e5",
                        ml: 1,
                        marginTop: -1,
                      }}
                    >
                      <Table
                        sx={{ maxWidth: "40%", marginLeft: 1 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableBody>
                          {turnos.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell component="th" width="5%" scope="row">
                                <i className="fa-regular fa-calendar fa-xl  text-slate-500" />
                              </TableCell>
                              <TableCell width="65%">
                                {row.fecha_ag_arg}
                              </TableCell>
                              <TableCell width="30%">
                                {row.hora_desde.substring(0, 2) +
                                  ":" +
                                  row.hora_desde.substring(2, 4)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Paper
                      elevation={2}
                      sx={{
                        width: 400,
                        height: "auto",
                        pt: 0,
                        pb: 1,
                        bgcolor: "white",
                        ml: 1,
                      }}
                    >
                      <Box sx={{ ml: 3, justifyContent: "center" }}>
                        <div className="flex mt-2 -ml-2 items-end  ">
                          <TextField
                            id="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                              formik.touched.email ? formik.errors.email : ""
                            }
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            margin="dense"
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{
                              marginLeft: -1,
                            }}
                          />
                        </div>
                        <div className="flex mt-2 -ml-2 items-end ">
                          <TextField
                            id="celular"
                            label="Celular"
                            value={formik.values.celular}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            helperText={
                              formik.touched.celular
                                ? formik.errors.celular
                                : ""
                            }
                            error={
                              formik.touched.celular &&
                              Boolean(formik.errors.celular)
                            }
                            margin="dense"
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{
                              marginLeft: -1,
                            }}
                          />
                        </div>
                      </Box>
                    </Paper>

                    <Grid item xs={12}>
                      <Autocomplete
                        id="motivo"
                        options={motivosEspecialidad}
                        value={formik.values.motivo}
                        autoHighlight
                        size="small"
                        onChange={onMotivosChange}
                        getOptionLabel={(option) => CapSentence(option.motivo)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Motivo"
                            error={
                              formik.touched.motivo &&
                              Boolean(formik.errors.motivo)
                            }
                            helperText={
                              formik.touched.motivo && formik.errors.motivo
                            }
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { justifyContent: "center" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: -1,
                      }}
                    >
                      <Autocomplete
                        multiple
                        id="piezas"
                        options={piezasDentales}
                        value={formik.values.piezas}
                        disableCloseOnSelect
                        filterSelectedOptions
                        autoHighlight
                        size="small"
                        onChange={onPiezasChange}
                        getOptionLabel={(option) => option.diente}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Piezas"
                            error={
                              formik.touched.piezas &&
                              Boolean(formik.errors.piezas)
                            }
                            helperText={
                              formik.touched.piezas && formik.errors.piezas
                            }
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { justifyContent: "center" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: -1,
                      }}
                    >
                      <Autocomplete
                        id="derivador"
                        options={derivadores}
                        value={formik.values.derivador}
                        autoHighlight
                        size="small"
                        onChange={onDerivadorChange}
                        getOptionLabel={(option) =>
                          CapSentence(option.odontologo)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Derivador"
                            error={
                              formik.touched.derivador &&
                              Boolean(formik.errors.derivador)
                            }
                            helperText={
                              formik.touched.derivador &&
                              formik.errors.derivador
                            }
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { justifyContent: "center" },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="observacion"
                        sx={{
                          mt: -1,
                        }}
                        label="Observaciones"
                        multiline
                        rows={2}
                        value={formik.values.observacion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyItems: "center",
                          gap: "20px",
                          mt: -2,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={fagendaChecked}
                              onChange={handleChangeFAgendaChecked}
                            />
                          }
                          label={
                            <Typography
                              color={fagendaChecked ? "avatar" : "#94a3b8"}
                              fontWeight={fagendaChecked ? "bold" : "regular"}
                            >
                              Fuera de agenda
                            </Typography>
                          }
                          sx={{
                            marginTop: {
                              xs: 0.5,
                              md: 0,
                            },
                            marginLeft: {
                              xs: -2,
                              md: 0,
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions className="actions">
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                  loadingPosition="end"
                  sx={{
                    backgroundColor: "#cbd5e1",
                    ":hover": {
                      bgcolor: "#475569", // theme.palette.primary.main
                      color: "white",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  loading={myloading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.main",
                    ":hover": {
                      bgcolor: "#475569", // theme.palette.primary.main
                      color: "white",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  <span>Grabar</span>
                </LoadingButton>
              </DialogActions>
            </form>
          </BootstrapDialog>
        </Draggable>
      </div>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
    </>
  );
}
