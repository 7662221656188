import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Divider,
  Box,
  Alert,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import CustomModal from "./CustomModal";
import { ModeloPost } from "../ModeloCrud";
import SaveCancelButtons from "../SaveCancelButtons";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
// Esquema de validación básico para redondeo y sin validación condicional
const validationSchema = Yup.object({
  porcentaje: Yup.number()
    .min(1, "El porcentaje debe ser mayor o igual a 1")
    .max(100, "El porcentaje no puede ser mayor a 100")
    .nullable(),
  nuevoPrecio: Yup.number()
    .min(0, "El nuevo precio debe ser positivo o igual a 0")
    .nullable(),
  redondeo: Yup.number()
    .min(1, "El redondeo debe ser mayor o igual a 1")
    .max(1000, "El redondeo no puede ser mayor a 1000")
    .nullable(),
});

const ModalActualizarPreciosLista = ({
  open,
  handleClose,
  listaId,
  ids,
  handleSuccess,
}) => {
  const [option, setOption] = useState("nuevoprecio"); // Estado para la opción seleccionada
  const [showAlert, setShowAlert] = useState("");
  const [isSubmitting, setSubmitting] = React.useState(false);

  const handleOptionChange = (event) => {
    setOption(event.target.value);
    formikRef.current.setErrors({});
    formikRef.current.setTouched({});
    setShowAlert("");
    if (formikRef.current) {
      formikRef.current.setFieldValue("porcentaje", "");
      formikRef.current.setFieldValue("nuevoprecio", "");
    }
  };

  const formikRef = useRef();
  const handleSubmit = async (values, { setErrors }) => {
    let hasError = false;
    setShowAlert("");
    if (
      option === "nuevoprecio" &&
      (values.nuevoprecio === "" || values.nuevoprecio == null)
    ) {
      setErrors({ nuevoprecio: "El nuevo precio es requerido" });
      hasError = true;
    }
    if (option === "porcentaje" && !values.porcentaje) {
      setErrors({ porcentaje: "El porcentaje es requerido" });
      hasError = true;
    }
    if (ids === null || ids.length === 0) {
      setShowAlert("Seleccione al menos un codigo");
      hasError = true;
    }

    if (hasError) {
      setSubmitting(false);
      return;
    }
    const stringWithSemicolons = ids.join(";");
    try {
      const Data = {
        ids: stringWithSemicolons,
        redondeo: values.redondeo,
        ...(option === "nuevoprecio" && { nuevoprecio: values.nuevoprecio }),
        ...(option === "porcentaje" && { porcentaje: values.porcentaje }),
      };

      setSubmitting(true);
      const response = await ModeloPost(
        "listas-precios",
        listaId,
        "actualizar-precios-lista",
        Data
      );
      setSubmitting(false);
      if (response.error === false) {
        handleClose();
        handleSuccess();
      } else {
        setShowAlert(response.errormessage);
      }
    } catch (error) {
      alert(`Error: ${error.response?.data?.mensaje || "Ocurrió un error"}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      title="Actualizar precios"
      loading={isSubmitting}
      disableActions
      maxWidth="xs"
      icon={<AppRegistrationIcon fontSize="medium" sx={{ color: "white" }} />}
    >
      <Box>
        <Formik
          initialValues={{ nuevoprecio: "", porcentaje: "", redondeo: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formikRef}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setErrors,
          }) => (
            <Form>
              <Box sx={{ mt: 2, mb: 4 }}>
                <FormControl sx={{ mb: 2 }}>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Tipo de actualización
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={option}
                    onChange={handleOptionChange}
                  >
                    <FormControlLabel
                      value="nuevoprecio"
                      control={<Radio />}
                      label="Precio"
                    />
                    <FormControlLabel
                      value="porcentaje"
                      control={<Radio />}
                      label="Porcentaje"
                    />
                  </RadioGroup>
                </FormControl>

                <TextField
                  label="Nuevo Precio"
                  name="nuevoprecio"
                  type="number"
                  size="small"
                  fullWidth
                  disabled={option !== "nuevoprecio"}
                  value={values.nuevoprecio}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nuevoprecio && Boolean(errors.nuevoprecio)}
                  helperText={touched.nuevoprecio && errors.nuevoprecio}
                />

                <TextField
                  label="Porcentaje"
                  name="porcentaje"
                  type="number"
                  size="small"
                  fullWidth
                  disabled={option !== "porcentaje"}
                  sx={{ mt: 2 }}
                  value={values.porcentaje}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.porcentaje && Boolean(errors.porcentaje)}
                  helperText={touched.porcentaje && errors.porcentaje}
                />

                <TextField
                  label="Redondeo (Opcional)"
                  name="redondeo"
                  type="number"
                  size="small"
                  fullWidth
                  sx={{ mt: 2 }}
                  value={values.redondeo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.redondeo && Boolean(errors.redondeo)}
                  helperText={touched.redondeo && errors.redondeo}
                />
              </Box>
              {showAlert?.length > 0 && (
                <Alert variant="filled" severity="error">
                  {showAlert}
                </Alert>
              )}
              <Divider />
              <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
                <SaveCancelButtons
                  handleCancel={handleClose}
                  loading={isSubmitting}
                  isModal={true}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </CustomModal>
  );
};

export default ModalActualizarPreciosLista;
