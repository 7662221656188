import React, { useState, useEffect } from "react";
import { ModeloDelete } from "./ModeloCrud";
import ModalConfirmation from "./modals/ModalConfirmation";
import ModalInformation from "./modals/ModalInformation";

export default function BorrarImagen({
  imageId,
  setLoading,
  afterMethod,
  setDeleteImageId,
}) {
  const [modalMessage, setModalMessage] = useState(
    "Seguro quiere Borrar la Foto ?"
  );
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(true);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  function ConfirmationModalOk() {
    if (imageId === null) {
      setModalMessage("No se encontró la foto.");
      setModalInformationOpen(true);
      return;
    } else {
      RemovePhoto();
    }
  }

  const hideConfirmationModal = () => {
    setDeleteImageId(null);
    setShowConfirmationModal(false);
    setModalMessage(null);
  };
  const hideInformationModal = () => {
    setModalInformationOpen(false);
    setModalMessage(null);
  };
  async function RemovePhoto() {
    hideConfirmationModal();
    if (setLoading !== null) {
      setLoading(true);
    }
    let result = await ModeloDelete("paciente-imagenes-adjuntos", imageId);
    if (setLoading !== null) {
      setLoading(true);
    }
    setDeleteImageId(null);
    if (result.error !== true) {
      if (afterMethod !== null) {
        afterMethod();
      }
    } else {
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
  }

  return (
    <>
      <ModalConfirmation
        showModal={showConfirmationModal}
        confirmModal={ConfirmationModalOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
    </>
  );
}
