import React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";

const BackPage = ({ text, handleClick = null, url = null }) => {
  const history = useHistory();
  function handleClickVolver() {
    const myUrl = "/" + url;
    history.push(myUrl);
  }

  return (
    <div className="flex justify-start mt-3 mb-3  ">
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={handleClick !== null ? handleClick : handleClickVolver}
          size="small"
          sx={{ marginLeft: 2, marginRight: 1 }}
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: "avatar" }}>
            <ArrowBackIosNewIcon />
          </Avatar>
        </IconButton>
        <Typography
          color="grey.600"
          variant="body2"
          display="block"
          gutterBottom
          onClick={handleClick !== null ? handleClick : handleClickVolver}
          sx={{ cursor: "pointer", marginTop: 0.5 }}
        >
          {text}
        </Typography>
      </Box>
    </div>
  );
};

export default BackPage;
