import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import crypto from "./Crypto";
import Enumerable from "linq";
import { ModeloGetUrl } from "./ModeloCrud";
import * as moment from "moment";
import FacebookCircularProgress from "./FacebookCircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useHistory } from "react-router-dom";
import { StyledTableCell } from "./utils/Utils";
import BackPage from "./BackPage";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useLocation } from "react-router-dom";
import { CapSentence } from "./utils/Utils";

export default function Consultorios() {
  const [expanded, setExpanded] = React.useState(true);
  const [mostrar, setMostrar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allAgendas, setAllAgendas] = useState([]);
  let [selectedClinica, setSelectedClinica] = useState(null);
  let [selectedTurno, setSelectedTurno] = useState(null);
  let [loggedChecked, setLoggedChecked] = useState(false);
  const history = useHistory();
  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];
  const allConsultorios =
    crypto.decryptDataStorage("consultorios") !== null
      ? crypto.decryptDataStorage("consultorios")
      : [];

  const horarios = ["Mañana", "Tarde"];

  const location = useLocation();
  const centroId = location.state?.centroId;

  useEffect(() => {
    if (centroId) {
      setSelectedClinica(centroId);
      buscarConsultorios(centroId);
    }
  }, []); // Incluyes `changeButton` en las dependencias

  const handleGoToAgenda = (row) => {
    const Today = new Date();
    const Fecha = moment(Today).format("YYYY-MM-DD");
    crypto.encryptDataStorage("ags_centro_id", row.centro_id);
    crypto.encryptDataStorage("ags_especialidad_id", row.especialidad_id);
    crypto.encryptDataStorage("ags_odontologo_id", row.odontologo_id);
    crypto.encryptDataStorage("ags_fecha", Fecha);
    history.push("/agendassimples");
  };

  const handleChangeLoggedChecked = (event) => {
    loggedChecked = event.target.checked;
    const tempChecked = loggedChecked;
    setLoggedChecked(tempChecked);
    if (selectedClinica !== null) {
      buscarConsultorios(selectedClinica);
    }
  };

  const onTurnoChange = (event, values) => {
    selectedTurno = values;
    const tempTurno = selectedTurno;
    setSelectedTurno(tempTurno);
    buscarConsultorios(selectedClinica);
  };

  async function buscarAgendasConsultorios(centroid) {
    try {
      setMostrar([]);
      const Today = new Date();
      const Fecha = moment(Today).format("YYYY-MM-DD");
      let params = "fecha=" + Fecha;
      if (loggedChecked) {
        params += "&logged=SI";
      }
      if (selectedTurno !== null) {
        params += "&turno=" + selectedTurno.slice(0, 1);
      }

      setLoading(true);
      let result = await ModeloGetUrl(
        "centro",
        centroid,
        "agendas-del-dia",
        params
      );
      setLoading(false);

      if (result.error === false) {
        setAllAgendas(result.listado);
      } else {
      }
    } catch (error) {
      alert("es este error " + error.message);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }

  function MostrarConsultorios(consultorio) {
    const row = consultorio.consultorio;
    const agendas = Enumerable.from(allAgendas)
      .where((a) => a.consultorio_id === row.id)
      .toArray();
    return (
      <Box
        sx={{
          boxShadow: 1,
          width: { xs: "18rem", md: "25rem" },
          bgcolor: agendas.length > 0 ? "blue50" : "#FFFFFF",
          height: "18rem",
          color: "#64748b",
          p: 1,
          m: 1,
          borderRadius: 2,
          minWidth: { xs: "20rem", md: "25rem" },
          minHeight: "20rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontWeight: "100",
              fontSize: "1.5rem",
              textAlign: "start",
              marginTop: 1,
              marginRight: 1,
            }}
            gutterBottom
          >
            Piso: {row.piso}
          </Typography>
          <Avatar
            sx={{
              width: 54,
              height: 54,
              bgcolor: agendas.length > 0 ? "icongreen" : "icondisabled",
              marginBottom: 0.5,
              marginRight: 1,
              border: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: "100",
                fontSize: "2.0rem",
                marginTop: 1,
              }}
              gutterBottom
            >
              {row.numero}
            </Typography>
          </Avatar>
        </Box>
        {agendas.length > 0 && (
          <Box sx={{ mt: 1, ml: -1, mr: 0, mb: 0 }}>
            <TableContainer
              sx={{
                maxHeight: "16rem",
                maxWidth: { xs: "20rem", md: "26rem" },
                borderBottom: "none",
                padding: 1,
              }}
            >
              <Table
                sx={{
                  borderBottom: "none",
                }}
                size="small"
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Odontólogo</StyledTableCell>
                    <StyledTableCell>Especialidad</StyledTableCell>
                    <StyledTableCell>Turno</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ textTransform: "capitalize" }}>
                  {agendas !== null &&
                    agendas.length > 0 &&
                    agendas.map((row) => (
                      <TableRow key={row.agenda_id}>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            color: "#0369a1",
                            fontSize: "1rem",
                            fontWeight: "200",
                          }}
                        >
                          {row.apellido.toLowerCase() +
                            " " +
                            row.nombres.toString().slice(0, 1) +
                            "."}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          {row.especialidad_sm}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          {row.turno.toLowerCase()}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          <Tooltip title="Ir a la agenda">
                            <EventNoteIcon
                              cursor="pointer"
                              sx={{
                                color: "primary.main",
                                fontSize: 24,
                              }}
                              onClick={() => handleGoToAgenda(row)}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    );
  }

  function buscarConsultorios(pClinica) {
    setMostrar([]);
    if (pClinica !== null) {
      buscarAgendasConsultorios(pClinica);
      const consultoriosClinica = Enumerable.from(allConsultorios)
        .where((c) => c.centro_id === pClinica)
        .toArray();
      setMostrar(consultoriosClinica);
    }
  }

  const onClinicasChange = (event, values) => {
    if (values === null) {
      setSelectedClinica(null);
      setMostrar([]);
      setExpanded(false);
    } else {
      setExpanded(true);
      setSelectedClinica(values);
      buscarConsultorios(values);
    }
  };

  const ConsultorioCard = ({ consultorio }) => {
    // Filtrar agendas por consultorio_id
    const row = consultorio;
    const filteredAgendas = allAgendas.filter(
      (agenda) => agenda.consultorio_id === row.id
    );

    // Determinar si el consultorio está ocupado
    const isOccupied = filteredAgendas.length > 0;

    return (
      <Card
        sx={{
          width: 420,
          margin: 2,
          backgroundColor: "white",
          boxShadow: 2, // Sombra personalizada
        }}
      >
        <CardContent>
          {/* Título: Número del consultorio */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Piso {row.piso}</Typography>
            <Avatar
              sx={{
                bgcolor: isOccupied ? "icongreen" : "icondisabled",
                width: 50,
                height: 50,
                fontSize: 28,
              }}
            >
              {row.numero}
            </Avatar>
          </Box>

          {/* Subtítulo: Piso */}

          {/* Cuerpo: Tabla pequeña y densa */}
          {isOccupied && (
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Especialidad</TableCell>
                    <TableCell>Odontólogo</TableCell>
                    <TableCell>Turno</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAgendas.map((agenda) => (
                    <TableRow key={agenda.id}>
                      <TableCell>{agenda.especialidad_sm}</TableCell>
                      <TableCell>
                        {CapSentence(agenda.apellido) +
                          " " +
                          CapSentence(agenda.nombres.toString().slice(0, 1)) +
                          "."}
                      </TableCell>
                      <TableCell>{agenda.turno}</TableCell>
                      <TableCell>
                        <Tooltip title="Ir a la agenda">
                          <EventNoteIcon
                            cursor="pointer"
                            sx={{
                              color: "primary.main",
                              fontSize: 20,
                            }}
                            onClick={() => handleGoToAgenda(agenda)}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>
    );
  };

  const goToAgenda = (consultorioId) => {
    // Función para manejar la redirección a la agenda del consultorio
    console.log(`Redirigiendo a la agenda del consultorio ${consultorioId}`);
  };

  return (
    <>
      <BackPage text="Volver" url="home" />
      <Box sx={{ mt: 1, ml: 2, mb: 8, mr: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: 0,
            justifyContent: "start",
            marginBottom: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: 22,
              marginTop: 1,
              marginLeft: { xs: 1, lg: 3 },
              marginRight: 2,
            }}
          >
            Consultorios
          </Typography>
        </Box>
        {1 === 2 && ( // lo dejo asi por si en algun momento alguien lo pide
          <>
            <Autocomplete
              id="turno"
              sx={{ px: 1, py: 1, width: { xs: "180px", sm: "300px" } }}
              options={horarios}
              value={selectedTurno}
              autoHighlight
              size="small"
              onChange={onTurnoChange}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Turno"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    style: { justifyContent: "center" },
                  }}
                />
              )}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={loggedChecked}
                  onChange={handleChangeLoggedChecked}
                />
              }
              label={
                <Typography
                  color={loggedChecked ? "avatar" : "#94a3b8"}
                  fontWeight={loggedChecked ? "bold" : "regular"}
                >
                  Ingresaron al sistema
                </Typography>
              }
              sx={{
                marginTop: {
                  xs: 0.5,
                  md: 0,
                },
                marginLeft: {
                  xs: 0,
                  md: 0,
                },
              }}
            />
          </>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {mostrar !== null &&
            mostrar.length > 0 &&
            !loading &&
            mostrar.map((row) => <ConsultorioCard consultorio={row} />)}
        </Box>
        {loading && (
          <div className="flex justify-center mt-2">
            <FacebookCircularProgress />{" "}
          </div>
        )}
      </Box>
    </>
  );
}
