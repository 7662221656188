import React, { useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  stringToColor,
  LetterAvatar,
  useViewport,
  fotoAvatar,
} from "./utils/Utils";
import ModalSystemInfo from "./modals/ModalSystemInfo";
import ModalUserChangePassword from "./modals/ModalUserChangePassword";
import ModalUserInfo from "./modals/ModalUserInfo";
import LogoutMenu from "./LogoutMenu";
import MenuMoreOptions from "./MenuMoreOptions";
import crypto from "./Crypto";
import LogoBlanco from "../assetss/img/logo_blanco_full_156.png";
import CmLeftDrawer from "./CmLeftDrawer.js";
import { useHistory } from "react-router-dom";
import BugReportIcon from "@mui/icons-material/BugReport";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TopBarContext from "./contexts/TopBarContext";
import InfoIcon from "@mui/icons-material/Info";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import CustomMenu from "./CustomMenu"; // Import your menu component
import RightDrawer from "./RightDrawer";
import LogoutIcon from "@mui/icons-material/Logout";

const pages = ["Products", "Pricing", "Blog"];

function CmAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const username = crypto.decryptLocalStorage("user_name");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const [anchorElCustom, setAnchorElCustom] = React.useState(null);
  const [openOption, setOpenOption] = useState(false);
  const [modalSystemInfoOpen, setModalSystemInfoOpen] = useState(false);
  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);
  const [modalUserInfoOpen, setModalUserInfoOpen] = useState(false);
  const { width } = useViewport();
  const [showDrawer, setShowDrawer] = useState(false);

  const history = useHistory();
  const userAvatar =
    crypto.decryptLocalStorage("useravatar") !== null
      ? crypto.decryptLocalStorage("useravatar")
      : "";
  const { activeButton, changeButton } = useContext(TopBarContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClickMoreOptions = (event) => {
    changeButton("Menu");
    setAnchorElOptions(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setShowDrawer(!showDrawer);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const hideModalSystemInfo = () => {
    setModalSystemInfoOpen(false);
  };

  const hideModalUserInfo = () => {
    setModalUserInfoOpen(false);
  };

  const handleChangePassword = () => {
    setModalChangePasswordOpen(true);
  };

  const hideModalChangePassword = () => {
    setModalChangePasswordOpen(false);
  };

  const handleHome = () => {
    changeButton("Home");
    history.push({
      pathname: "/home",
    });
  };

  const handleLogout = () => {
    // Obtener el último email guardado del localStorage (desencriptado)
    let lastLogged = crypto.decryptLocalStorage("user_email");

    // Limpiar sessionStorage y localStorage
    sessionStorage.clear();
    localStorage.clear();

    // Si existe un valor válido para lastLogged, lo encripta y lo guarda nuevamente
    if (
      typeof lastLogged !== "undefined" &&
      lastLogged !== null &&
      lastLogged.length > 0
    ) {
      crypto.encryptLocalStorage("user_email", lastLogged);
    }

    // Navegar a la página principal
    history.push("/");
  };

  const handleUserInfo = () => {
    setModalUserInfoOpen(true);
  };

  const handleAgendasSimples = () => {
    changeButton("AgendasSimples");
    history.push({
      pathname: "/agendassimples",
    });
  };

  const handleAgendasMultiples = () => {
    changeButton("AgendasMúltiples");
    history.push({
      pathname: "/agendasmultiples",
    });
  };
  const handleClientes = () => {
    changeButton("Clientes");
    history.push({
      pathname: "/clientes",
    });
  };
  const handlePacientes = () => {
    changeButton("Pacientes");
    history.push({
      pathname: "/pacientes",
    });
  };
  const handleOdontologos = () => {
    changeButton("Odontólogos");
    history.push({
      pathname: "/odontologos",
    });
  };
  const handleInsertUpdatePaciente = () => {
    changeButton("Padrones");
    history.push({
      pathname: "/insertupdatepaciente",
    });
  };
  const open = Boolean(anchorEl);
  const openoptions = Boolean(anchorElOptions);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleClickAvatar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          variant="dense"
          disableGutters
          sx={{
            minHeight: 60,
            height: 60,
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            bgcolor: "primary",
            fontFamily: "Roboto",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
              mt: 0.5,
            }}
          >
            <div className="aspect-w-16 aspect-h-9">
              <img className="w-full" src={LogoBlanco} alt="navbar-logo" />
            </div>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
              mt: 0,
            }}
          >
            <Button
              key="Home"
              centerRipple
              onClick={handleHome}
              sx={{
                m: { md: 0.5, lg: 1.5 },
                color: activeButton === "Home" ? "yellow" : "white",
                borderBottom: activeButton === "Home" ? 1 : 0,
                borderRadius: 1,
                display: "block",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#475569",
                },
              }}
            >
              Inicio
            </Button>
            <Button
              key="Pacientes"
              centerRipple
              onClick={handlePacientes}
              sx={{
                m: { md: 0.5, lg: 1.5 },
                color: activeButton === "Pacientes" ? "yellow" : "white",
                borderBottom: activeButton === "Pacientes" ? 1 : 0,
                borderRadius: 1,
                display: "block",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#475569",
                },
              }}
            >
              Pacientes
            </Button>
            <Button
              key="Agendas"
              centerRipple
              onClick={handleAgendasSimples}
              sx={{
                m: { md: 0.5, lg: 1.5 },
                color: activeButton === "AgendasSimples" ? "yellow" : "white",
                borderBottom: activeButton === "AgendasSimples" ? 1 : 0,
                borderRadius: 1,
                display: "block",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#475569",
                },
              }}
            >
              Agendas
            </Button>
            <Button
              key="AgendasMultiples"
              centerRipple
              onClick={handleAgendasMultiples}
              sx={{
                m: { md: 0.5, lg: 1.5 },
                color: activeButton === "AgendasMúltiples" ? "yellow" : "white",
                borderBottom: activeButton === "AgendasMúltiples" ? 1 : 0,
                borderRadius: 1,
                display: "block",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#475569",
                },
              }}
            >
              Ag.Múltiples
            </Button>
            <Button
              key="Padrones"
              onClick={handleInsertUpdatePaciente}
              sx={{
                m: { md: 0.5, lg: 1.5 },
                color: activeButton === "Padrones" ? "yellow" : "white",
                borderBottom: activeButton === "Padrones" ? 1 : 0,
                borderRadius: 1,
                display: "block",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#475569",
                },
              }}
            >
              Padrones
            </Button>
            <Button
              key="Clientes"
              onClick={handleClientes}
              sx={{
                m: { md: 0.5, lg: 1.5 },
                color: activeButton === "Clientes" ? "yellow" : "white",
                borderBottom: activeButton === "Clientes" ? 1 : 0,
                borderRadius: 1,
                display: "block",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#475569",
                },
              }}
            >
              Clientes
            </Button>
            <Button
              key="Odontologos"
              onClick={handleOdontologos}
              sx={{
                m: { md: 0.5, lg: 1.5 },
                color: activeButton === "Odontólogos" ? "yellow" : "white",
                borderBottom: activeButton === "Odontólogos" ? 1 : 0,
                borderRadius: 1,
                display: "block",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#475569",
                },
              }}
            >
              Odontólogos
            </Button>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ marginLeft: 4 }}
            >
              <MenuIcon />
            </IconButton>

            <RightDrawer open={drawerOpen} setShowDrawer={setDrawerOpen} />
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              mt: 0,
            }}
          >
            {crypto.decryptLocalStorage("is-beta") === true && (
              <Tooltip title="Test Version">
                <BugReportIcon
                  size="small"
                  sx={{ ml: 2, marginTop: 1 }}
                  color="white"
                ></BugReportIcon>
              </Tooltip>
            )}

            <Tooltip title={username}>
              <IconButton
                onClick={handleClickAvatar}
                size="small"
                sx={{ ml: 2, width: 56, height: 56 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                {userAvatar?.length
                  ? fotoAvatar(userAvatar)
                  : LetterAvatar(username)}
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
                style: {
                  width: 250,
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => handleUserInfo()}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                Información del usuario
              </MenuItem>
              <MenuItem onClick={() => handleChangePassword()}>
                <ListItemIcon>
                  <KeyIcon />
                </ListItemIcon>
                Cambiar contraseña
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleLogout()}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                Cerrar sesión
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "space-between",
              alignItems: "center",
              // mt: -1,
              width: "100%",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon style={{ fontSize: 32 }} />
            </IconButton>
            <CmLeftDrawer
              showDrawer={showDrawer}
              setShowDrawer={setShowDrawer}
            />
            <img className="max-w-[35%]" src={LogoBlanco} alt="navbar-logo" />
          </Box>
        </Toolbar>
        {modalSystemInfoOpen && (
          <ModalSystemInfo
            showModal={modalSystemInfoOpen}
            hideModal={hideModalSystemInfo}
          />
        )}
        {modalUserInfoOpen && (
          <ModalUserInfo
            showModal={modalUserInfoOpen}
            hideModal={hideModalUserInfo}
          />
        )}
        {modalChangePasswordOpen && (
          <ModalUserChangePassword
            open={modalChangePasswordOpen}
            handleClose={hideModalChangePassword}
          />
        )}
      </Container>
    </AppBar>
  );
}
export default CmAppBar;
