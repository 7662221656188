import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Enumerable from "linq";
import { CapSentence, formatNumberES } from "../utils/Utils";
export const RenderReportPresupuestosTable = (props) => {
  const { presupuesto, allItems } = props;

  let allItemsToPrint = null;
  let allAlternativas = null;
  if (presupuesto.estado === "APROBADO") {
    allItemsToPrint = allItems.filter((i) => i.aprobado === 1);
    allAlternativas = Enumerable.from(allItems)
      .where((w) => w.aprobado === 1)
      .groupBy((g) => g.aprobado)
      .select((e) => ({
        alternativa: e.key(),
      }))
      .toArray();
  } else {
    allItemsToPrint = allItems;
    allAlternativas = Enumerable.from(allItems)
      .groupBy((g) => g.alternativa)
      .select((e) => ({
        alternativa: e.key(),
      }))
      .toArray();
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#d1d5db",
      color: "black",
      border: "10px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2.5px 15px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCellMedium = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#d1d5db",
      color: "black",
      border: "10px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2.5px 25px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCellLarge = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#d1d5db",
      color: "black",
      border: "10px",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2.5px 30px",
      width: "50px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const composturas = allItemsToPrint.filter(
    (i) => ["04:04:01", "04:04:07"].indexOf(i.codigo_nomenclador) >= 0
  );

  const consultas = allItemsToPrint.filter(
    (i) => i.codigo_nomenclador.slice(0, 6) === "06:06:"
  );
  const rowsPerPage = 27;

  const renderTableHeader = () => (
    <StyledTableRow>
      <StyledTableCell align="right">
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          Alt.
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          Código
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          Descripción
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          Pieza
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          Caras
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          Valor
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  );

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(true);

    return (
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Box>
            <Table sx={{ minWidth: 740 }}>
              {renderTableHeader()}
              <TableBody>
                {allItemsToPrint
                  .filter((i) =>
                    presupuesto.estado === "PROVISORIO"
                      ? i.alternativa === row.alternativa
                      : i.aprobado === 1
                  )
                  .map((itemRow, index) => {
                    const isNewPage = index % rowsPerPage === 0;
                    return (
                      <React.Fragment key={index}>
                        {isNewPage && index !== 0 && (
                          <div className="page-break"></div>
                        )}
                        {isNewPage && index !== 0 && renderTableHeader()}
                        <StyledTableRow key={itemRow.id}>
                          <StyledTableCell
                            align="right"
                            component="th"
                            scope="row"
                            sx={{ width: "5%" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontFamily: "Open Sans",
                              }}
                            >
                              {itemRow.alternativa}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left" sx={{ width: "5%" }}>
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontFamily: "Open Sans",
                              }}
                            >
                              {itemRow.codigo_nomenclador}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCellLarge
                            align="left"
                            sx={{ width: "70%" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontFamily: "Open Sans",
                              }}
                            >
                              {CapSentence(itemRow.descripcion_nomenclador)}
                            </Typography>
                          </StyledTableCellLarge>
                          <StyledTableCell align="left" sx={{ width: "5%" }}>
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  fontFamily: "Open Sans",
                                  marginLeft: 1,
                                  marginTop: 1,
                                }}
                              >
                                {itemRow.numero_pieza_dental}
                              </Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="left" sx={{ width: "5%" }}>
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontFamily: "Open Sans",
                              }}
                            >
                              {itemRow.caras}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCellMedium
                            align="right"
                            sx={{ width: "10%" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontFamily: "Open Sans",
                              }}
                            >
                              {formatNumberES(itemRow.importe, 2)}
                            </Typography>
                          </StyledTableCellMedium>
                        </StyledTableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                paddingRight: 1,
                marginTop: presupuesto.estado === "APROBADO" ? 2 : 0.5,
                marginLeft: 2,
                marginRight: 2,
              }}
            >
              <Typography variant="subtitle2" gutterBotto>
                Total
              </Typography>
              <Typography variant="subtitle2" gutterBotto>
                {formatNumberES(
                  allItems
                    .filter((i) =>
                      presupuesto.estado === "PROVISORIO"
                        ? i.alternativa === row.alternativa
                        : i.aprobado === 1
                    )
                    .reduce(function (prev, cur) {
                      return prev + parseFloat(cur.importe);
                    }, 0),
                  2
                )}
              </Typography>
            </Box>
            {presupuesto.bonificacion > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  paddingRight: 1,
                  marginTop: presupuesto.estado === "APROBADO" ? 2 : 0.5,
                  marginLeft: 2,
                  marginRight: 2,
                }}
              >
                <Typography variant="subtitle2" gutterBotto>
                  Bonificación
                </Typography>
                <Typography variant="subtitle2" gutterBotto>
                  {formatNumberES(Number(presupuesto.bonificacion), 2)}
                </Typography>
              </Box>
            )}
            {presupuesto.bonificacion > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  paddingRight: 1,
                  marginTop: presupuesto.estado === "APROBADO" ? 2 : 0.5,
                  marginLeft: 2,
                  marginRight: 2,
                }}
              >
                <Typography variant="subtitle2" gutterBotto>
                  Total a pagar
                </Typography>
                <Typography variant="subtitle2" gutterBotto>
                  {presupuesto.estado === "APROBADO" &&
                    formatNumberES(Number(presupuesto.importe_neto), 2)}
                  {presupuesto.estado === "PROVISORIO" &&
                    formatNumberES(
                      allItems
                        .filter((i) =>
                          presupuesto.estado === "PROVISORIO"
                            ? i.alternativa === row.alternativa
                            : i.aprobado === 1
                        )
                        .reduce(function (prev, cur) {
                          const importe = parseFloat(cur.importe);
                          const bonificacion = parseFloat(
                            presupuesto.bonificacion || 0
                          ); // Assuming bonificacion is in percentage
                          const netImporte = importe * (1 - bonificacion / 100); // Calculate net importe after discount
                          return prev + netImporte;
                        }, 0),
                      2
                    )}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingRight: 1.5,
                marginTop: presupuesto.estado === "APROBADO" ? 2 : 0.5,
                marginTop: 1,
                marginLeft: 2,
                marginRight: 2,
                marginBottom: 6,
              }}
            >
              <Typography variant="subtitle2" sx={{ fontStyle: "oblique" }}>
                Estos valores son solo para pagos al contado.
              </Typography>
              {allItemsToPrint.filter(
                (i) =>
                  ["04:04:01", "04:04:07"].indexOf(i.codigo_nomenclador) >= 0 &&
                  i.alternativa === row.alternativa
              ).length > 0 && (
                <Typography variant="subtitle2" sx={{ fontStyle: "oblique" }}>
                  Las composturas de prótesis no tienen garantía.
                </Typography>
              )}
              {allItemsToPrint.filter(
                (i) =>
                  i.codigo_nomenclador.slice(0, 6) === "06:06:" &&
                  i.alternativa === row.alternativa
              ).length > 0 && (
                <Typography variant="subtitle2" sx={{ fontStyle: "oblique" }}>
                  En consultas de control puede variar el valor.
                </Typography>
              )}
            </Box>
          </Box>
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <Container maxWidth="lg">
      <TableContainer>
        <Table sx={{ minWidth: 740 }}>
          <TableBody>
            {allAlternativas.map((row) => (
              <>
                <Box>
                  <Row key={row.alternativa} row={row} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    paddingRight: 2,
                  }}
                ></Box>
              </>
            ))}
            {presupuesto.observaciones !== null &&
              presupuesto.observaciones.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginLeft: 4,
                    marginRight: 4,
                  }}
                >
                  <Typography variant="h7" sx={{ fontStyle: "oblique" }}>
                    Observaciones: {presupuesto.observaciones}
                  </Typography>
                </Box>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default RenderReportPresupuestosTable;
