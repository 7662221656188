import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";

export default function CustomizedInputBase({
  value,
  onchange,
  handlebusqueda,
  placeholder,
  advancedsearch = null,
  width = 400,
  starticon = null,
  handlestart = null,
}) {
  function HandleEnter(e) {
    e.preventDefault();
    handlebusqueda();
  }
  return (
    <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: width,
      }}
    >
      {starticon !== null && (
        <>
          <IconButton
            sx={{ p: "1px", marginLeft: 0.5 }}
            aria-label="menu"
            onClick={handlestart}
          >
            {starticon}
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </>
      )}

      <InputBase
        id="search"
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ "aria-label": "Customizaed Search" }}
        onKeyDown={(e) => e.key === "Enter" && HandleEnter(e)}
        autoFocus={true}
        value={value}
        onChange={onchange}
        autoComplete="off"
      />
      <IconButton
        type="button"
        color="#475569"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={handlebusqueda}
      >
        <SearchIcon sx={{ fontSize: 32, color: "#475569" }} />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      {advancedsearch !== null && (
        <IconButton
          color="#475569"
          sx={{ p: "10px" }}
          aria-label="directions"
          onClick={advancedsearch}
        >
          <TuneIcon sx={{ fontSize: 32, color: "#475569" }} />
        </IconButton>
      )}
    </Paper>
  );
}
