import React, { useEffect, useRef, useState, useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import multiMonthPlugin from "@fullcalendar/multimonth";
import esLocale from "@fullcalendar/core/locales/es";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import "../../assetss/css/CalendarMM.css";
import Enumerable from "linq";
import * as moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useHistory } from "react-router-dom";
import "../../assetss/css/CalendarMM.css";
import "../../assetss/css/Pacientes.css";
import crypto from "../Crypto";
import { CapSentence, StringToDate, hasPermission } from "../utils/Utils";
import AgendasTurnosTable from "./AgendasTurnosTable";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../assetss/css/SyncfusionMaterial.css";
import { ModeloGetUrl, ModeloPost } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import PacienteHeader from "../pacientes/PacienteHeader";
import platform from "platform";
import ModalConfirmation from "../modals/ModalConfirmation";
import ModalInformation from "../modals/ModalInformation";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PostAddIcon from "@mui/icons-material/PostAdd";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CoeAutocomplete_2 from "../CoeAutocomplete_2";
import EditIcon from "@mui/icons-material/Edit";
import TopBarContext from "../contexts/TopBarContext";
import ProtectedRoute from "../ProtectedRoute";

const AgendasSimples = () => {
  let [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  let [selectedOdontologo, setSelectedOdontologo] = useState(null);
  const [showAgenda, setShowAgenda] = useState(false);
  const [dataAgenda, setDataAgenda] = useState([]);
  const [dateAgenda, setDateAgenda] = useState("");
  const [entreTurnos, setEntreTurnos] = useState(0);
  const [dateAgendaEng, setDateAgendaEng] = useState("");
  const [dateGenerarAgenda, setDateGenerarAgenda] = useState("");
  let [eventosOdontologo, setEventosOdontologo] = useState([]);
  const [renderCalendar, setRenderCalendar] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let [sinTurnos, setSinTurnos] = useState([]);
  let [conTurnos, setConTurnos] = useState([]);
  let [bloqueados, setBloqueados] = useState([]);
  let [feriados, setFeriados] = useState([]);
  let [ausencias, setAusencias] = useState([]);
  const [, setModalTableInformationOpen] = useState(false);
  let eventodo = [];
  const { changeButton } = useContext(TopBarContext);

  let allEspecialidades = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .groupBy((g) => g.especialidad_id)
    .select((e) => ({
      id: e.key(),
      especialidad: e.max((m) => m.especialidad),
    }))
    .toArray();
  let allOdontologos = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .where((w) => w.especialidad_id > 0)
    .groupBy((g) => g.odontologo_id)
    .select((e) => ({
      id: e.key(),
      odontologo: e.max((m) => m.odontologo),
    }))
    .toArray();
  const [especialidadesLista, setEspecialidadesLista] =
    useState(allEspecialidades);
  const [odontologosLista, setOdontologosLista] = useState([]);
  const history = useHistory();
  //const Linqer = require('@siderite/linqer');
  const Today = new Date();
  const desde = moment(Today).format("YYYY-MM-DD");
  const hasta = moment(Today).add(120, "months").format("YYYY-MM-DD");
  //hiddenDays.push(0, 6);
  const [loading, setLoading] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const calendarRef = useRef();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  function getWindowWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  useEffect(() => {
    changeButton("AgendasSimples");
    document.documentElement.style.setProperty(
      "--buttonBgColor",
      crypto.decryptLocalStorage("primarymain")
    );
    const allferiados = crypto.decryptDataStorage("feriados");
    if (allferiados !== null) {
      for (let i = 0; i < allferiados.length; i++) {
        feriados.push(allferiados[i]["fecha"]);
      }
      let tempFeriados = [...feriados];
      setFeriados(tempFeriados);
    }
    if (
      crypto.decryptDataStorage("ags_centro_id") !== null &&
      crypto.decryptDataStorage("ags_especialidad_id") !== null &&
      crypto.decryptDataStorage("ags_odontologo_id") !== null &&
      crypto.decryptDataStorage("ags_fecha") !== null
    ) {
      goToAgenda();
    } else {
      if (crypto.decryptDataStorage("selectedespecialidad") !== null) {
        selectedEspecialidad = crypto.decryptDataStorage(
          "selectedespecialidad"
        );
        setSelectedEspecialidad(selectedEspecialidad);
        allOdontologos = Enumerable.from(crypto.decryptDataStorage("allcoes"))
          .where((es) => es.especialidad_id === selectedEspecialidad.id)
          .groupBy((gr) => gr.centro_odontologo)
          .select((e) => ({
            id: e.key(),
            odontologo: e.max((od) => od.odontologo),
            odontologo_id: e.max((odi) => odi.odontologo_id),
            centro_id: e.max((ce) => ce.centro_id),
            identificador: e.max((id) => id.identificador),
            color: e.max((co) => co.color),
          }))
          .toArray();
        crypto.encryptDataStorage("odontologoslista", allOdontologos);
        crypto.encryptDataStorage("agsOdontologosLista", allOdontologos);
        setOdontologosLista(allOdontologos);
      }
      if (crypto.decryptDataStorage("selectedodontologo") !== null) {
        selectedOdontologo = crypto.decryptDataStorage("selectedodontologo");
        setSelectedOdontologo(selectedOdontologo);
      }
      if (
        crypto.decryptDataStorage("selectedespecialidad") !== null &&
        crypto.decryptDataStorage("selectedodontologo") !== null
      ) {
        if (crypto.decryptDataStorage("allausencias") !== null) {
          ausencias = crypto.decryptDataStorage("allausencias");
          var tempAu = [...ausencias];
          setAusencias(tempAu);
          agregarEventosOdontologo(crypto.decryptDataStorage("allagendas"));
          setRenderCalendar(true);
        }
        if (crypto.decryptDataStorage("allagendas") !== null) {
          eventosOdontologo = crypto.decryptDataStorage("allagendas");
          var tempEv = [...eventosOdontologo];
          setEventosOdontologo(tempEv);
          agregarEventosOdontologo(crypto.decryptDataStorage("allagendas"));
          setRenderCalendar(true);
        }
        if (crypto.decryptDataStorage("currenttableday") !== null) {
          buscarAgenda(crypto.decryptDataStorage("currenttableday"), false);
        }
      }
    }
  }, []);

  function goToAgenda() {
    allEspecialidades = Enumerable.from(crypto.decryptDataStorage("allcoes"))
      .groupBy((g) => g.especialidad_id)
      .select((e) => ({
        id: e.key(),
        especialidad: e.max((m) => m.especialidad),
      }))
      .toArray();
    const selEspecialidad = allEspecialidades.filter(
      (e) => e.id === crypto.decryptDataStorage("ags_especialidad_id")
    );
    if (selEspecialidad.length > 0) {
      setSelectedEspecialidad(selEspecialidad[0]);
      crypto.encryptDataStorage("selectedespecialidad", selEspecialidad[0]);
      setEspecialidadesLista(allEspecialidades);
      crypto.encryptDataStorage("especialidadeslista", allEspecialidades);
      allOdontologos = Enumerable.from(crypto.decryptDataStorage("allcoes"))
        .where((es) => es.especialidad_id === selEspecialidad[0].id)
        .groupBy((gr) => gr.centro_odontologo)
        .select((e) => ({
          id: e.key(),
          odontologo: e.max((od) => od.odontologo),
          odontologo_id: e.max((odi) => odi.odontologo_id),
          centro_id: e.max((ce) => ce.centro_id),
          identificador: e.max((id) => id.identificador),
          color: e.max((co) => co.color),
        }))
        .toArray();
      crypto.encryptDataStorage("odontologoslista", allOdontologos);
      crypto.encryptDataStorage("agsOdontologosLista", allOdontologos);
      setOdontologosLista(allOdontologos);
      const selOdontologo = allOdontologos.filter(
        (o) =>
          o.centro_id === crypto.decryptDataStorage("ags_centro_id") &&
          o.odontologo_id === crypto.decryptDataStorage("ags_odontologo_id")
      );
      if (selOdontologo.length > 0) {
        setSelectedOdontologo(selOdontologo[0]);
        crypto.encryptDataStorage("selectedodontologo", selOdontologo[0]);
        sessionStorage.removeItem("ags_centro_id");
        sessionStorage.removeItem("ags_especialidad_id");
        sessionStorage.removeItem("ags_odontologo_id");
        handleClickBuscar(true, crypto.decryptDataStorage("ags_fecha"));
      }
    }
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
    setModalErrorOpen(false);
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
    setModalMessage(null);
  };

  async function GenerarAgenda() {
    setShowConfirmationModal(false);
    if (
      selectedEspecialidad !== null &&
      selectedOdontologo !== null &&
      dateGenerarAgenda !== null
    ) {
      setLoadingAgenda(true);
      const params =
        "centro_id=" +
        selectedOdontologo.centro_id +
        "&especialidad_id=" +
        selectedEspecialidad.id +
        "&odontologo_id=" +
        selectedOdontologo.odontologo_id +
        "&fecha=" +
        dateGenerarAgenda;
      let result = await ModeloPost(
        "centro-odontologo-especialidad",
        null,
        "crear-agenda-web",
        params
      );
      setLoadingAgenda(false);

      if (result.error === false) {
        handleClickBuscar(true);
      } else {
        setModalMessage(result.errormessage);
        setModalErrorOpen(true);
      }
    }
    return;
  }

  const handleOnChange = () => {
    setRenderCalendar(false);
    setShowAgenda(false);
    setRenderCalendar(false);
    sessionStorage.removeItem("ags_centro_id");
    sessionStorage.removeItem("ags_especialidad_id");
    sessionStorage.removeItem("ags_odontologo_id");
    sessionStorage.removeItem("ags_fecha");
  };

  function limpiarAgenda() {
    setModalMessage(null);
    setModalInformationOpen(false);
    setShowConfirmationModal(false);
    sessionStorage.removeItem("currentodontologo");
    sessionStorage.removeItem("currentespecialidad");
    sessionStorage.removeItem("allagendas");
    sessionStorage.removeItem("currenttableday");
    sessionStorage.removeItem("odoesp");
    sessionStorage.removeItem("currentagenda");
    sessionStorage.removeItem("dataOdontologo");
  }

  async function handleClickBuscar(
    searchAgenda = false,
    dateBuscarAgenda = ""
  ) {
    sessionStorage.removeItem("soloproximos");
    setRenderCalendar(false);
    setShowAgenda(false);
    setDataAgenda([]);
    setAusencias([]);
    setDateAgenda("");
    setDateAgendaEng("");
    setEntreTurnos(0);
    setDateGenerarAgenda("");
    eventosOdontologo = [];
    ausencias = [];
    var tempEv1 = [...eventosOdontologo];
    setEventosOdontologo(tempEv1);
    if (
      crypto.decryptDataStorage("selectedespecialidad") == null ||
      crypto.decryptDataStorage("selectedodontologo") == null
    ) {
      setModalMessage("Seleccione  especialidad y odontólogo.");
      setModalInformationOpen(true);
      return;
    }
    limpiarAgenda();
    setLoading(true);
    let params =
      "especialidad_id=" +
      crypto.decryptDataStorage("selectedespecialidad").id +
      "&desde=" +
      desde +
      "&hasta=" +
      hasta;
    if (crypto.decryptDataStorage("selectedodontologo") !== null) {
      params +=
        "&centro_id=" +
        crypto.decryptDataStorage("selectedodontologo").centro_id +
        "&odontologo_id=" +
        crypto.decryptDataStorage("selectedodontologo").odontologo_id;
    }
    const paramAusencias = "desde=" + desde + "&hasta=" + hasta;
    let result = await ModeloGetUrl(
      "centro-odontologo-especialidad",
      null,
      "agendas-coe-fechas",
      params
    );
    if (result.error === false) {
      if (result.listado.length > 0) {
        eventosOdontologo = result.listado;
        var tempEv = [...eventosOdontologo];
        let resultAus = await ModeloGetUrl(
          "odontologo",
          crypto.decryptDataStorage("selectedodontologo").odontologo_id,
          "ausencia-odontologo-web",
          paramAusencias
        );
        setLoading(false);
        if (resultAus.error === false) {
          if (resultAus.listado.length > 0) {
            for (let i = 0; i < resultAus.listado.length; i++) {
              let ausDesde = resultAus.listado[i]["fecha_desde"];
              let ausHasta = resultAus.listado[i]["fecha_hasta"];
              let tempString = "";
              while (ausDesde <= ausHasta) {
                ausencias.push(ausDesde);
                tempString += ausDesde;
                //var tempDate = new Date(ausDesde + " ");
                var tempDate = StringToDate(ausDesde);
                ausDesde = moment(tempDate).add(1, "days").format("YYYY-MM-DD");
              }
            }
            var tempAus = [...ausencias];
            setAusencias(tempAus);

            crypto.encryptDataStorage("allausencias", tempAus);
          }
        }
        setEventosOdontologo(tempEv);
        setRenderCalendar(true);
        crypto.encryptDataStorage("allagendas", result.listado);
        agregarEventosOdontologo(result.listado);
      } else {
        eventosOdontologo = [];
        var tempEv = [...eventosOdontologo];
        setEventosOdontologo(tempEv);
        setLoading(false);
        setRenderCalendar(true);
        agregarEventosOdontologo(null);
      }
    } else {
      setRenderCalendar(false);
      var tempEv = [...eventosOdontologo];
      setEventosOdontologo(tempEv);
      setLoading(false);
      setModalMessage(result.mensaje);
      setModalInformationOpen(true);
    }
    if (searchAgenda === true) {
      if (dateGenerarAgenda !== null && dateGenerarAgenda.length > 0) {
        buscarAgenda(dateGenerarAgenda, false);
      } else if (dateBuscarAgenda !== null && dateBuscarAgenda.length > 0) {
        buscarAgenda(dateBuscarAgenda, false);
      }
    }
    return;
  }

  const handledatesSet = () => {
    //  let newDate = dateInfo.start.toISOString().slice(0, 10);
    //   crypto.encryptDataStorage("currentcalendarday", newDate);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBloquearAgendas = () => {
    history.push({
      pathname: "/bloquearagendas",
    });
  };

  const handleCambiarAgenda = () => {
    history.push({
      pathname: "/cambiaragendas",
    });
  };

  const handleBuscarTurnosLibres = () => {
    if (selectedEspecialidad === null) {
      setModalMessage("Seleccione la especialidad.");
      setModalInformationOpen(true);
      return;
    }
    history.push({
      pathname: "/turnoslibres",
    });
  };

  const handleGenerarAgendas = () => {
    history.push({
      pathname: "/generaragendas",
    });
  };

  function agregarEventosOdontologo(data1) {
    conTurnos = [];
    sinTurnos = [];
    bloqueados = [];
    const data = data1;
    eventodo = [];
    if (data !== null) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].habilitado_turnos == "1") {
          if (data[i].turnos === "TRUE") {
            conTurnos.push(data[i].fecha.toString());
          } else {
            sinTurnos.push(data[i].fecha.toString());
          }
        } else {
          bloqueados.push(data[i].fecha.toString());
        }
      }
    }
    var tempCt = [...conTurnos];
    setConTurnos(tempCt);
    var tempSt = [...sinTurnos];
    setSinTurnos(tempSt);
    var tempBl = [...bloqueados];
    setBloqueados(tempBl);

    if (
      typeof calendarRef.current !== "undefined" &&
      calendarRef.current !== null
    ) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.render();
    }
  }

  async function buscarAgenda(pfecha, pgenerar) {
    const fecha = pfecha;
    if (
      crypto.decryptDataStorage("selectedespecialidad") !== null &&
      crypto.decryptDataStorage("selectedodontologo") !== null &&
      pfecha !== null
    ) {
      const dateObj = StringToDate(pfecha);
      const dayname = CapSentence(
        dateObj.toLocaleDateString("es-AR", { weekday: "long" })
      );
      setShowAgenda(false);
      setDataAgenda([]);
      setDateAgenda("");
      setDateAgendaEng("");
      setEntreTurnos(0);
      setDateGenerarAgenda("");
      const params =
        "centro_id=" +
        crypto.decryptDataStorage("selectedodontologo").centro_id +
        "&especialidad_id=" +
        crypto.decryptDataStorage("selectedespecialidad").id +
        "&odontologo_id=" +
        crypto.decryptDataStorage("selectedodontologo").odontologo_id +
        "&fecha_desde=" +
        fecha +
        "&fecha_hasta=" +
        fecha;
      setLoadingAgenda(true);
      let result = await ModeloGetUrl(
        "centro-odontologo-especialidad",
        null,
        "parte-diario-web",
        params
      );
      setLoadingAgenda(false);
      if (result.error === false) {
        if (result.listado.length > 0) {
          const cantEntreturnos = result.listado.filter(
            (c) => c.tipo_turno === "E"
          );
          crypto.encryptDataStorage("currenttableday", pfecha);

          setDataAgenda(result.listado);
          setDateAgenda(dayname + " " + dateObj.toLocaleDateString("es-AR"));
          setDateAgendaEng(fecha);
          setEntreTurnos(cantEntreturnos.length);
          setShowAgenda(true);
        } else {
          if (pgenerar === true) {
            setDateGenerarAgenda(fecha);
            setModalMessage("Seguro quiere generar las agendas ? ");
            setShowConfirmationModal(true);
          }
        }
      } else {
        history.push("/");
      }
    }
  }

  const handleDateClick = (arg) => {
    buscarAgenda(arg.date.toISOString().slice(0, 10), true);
  };

  const handleDayLink = (Date) => {
    buscarAgenda(Date.toISOString().slice(0, 10), true);
  };

  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"VISUALIZAR AGENDAS"}
    >
      <Box sx={{ marginTop: 2 }}>
        <>
          <PacienteHeader backpage="agendassimples" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginLeft: { xs: 2, md: 2 },
              marginRight: { xs: 2, md: 0 },
              marginTop: 1,
              borderColor: "#64748b",
              paddingTop: 1,
            }}
          >
            <Grid
              container
              rowSpacing={{ xs: 2, md: 4 }}
              columnSpacing={{ xs: 0, md: 0 }}
              sx={{
                display: "flex",
                justifyItems: "center",
                paddingTop: 1,
              }}
            >
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    marginTop: 0,
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "start" }}>
                    <CoeAutocomplete_2
                      selectedEspecialidad={selectedEspecialidad}
                      selectedOdontologo={selectedOdontologo}
                      setSelectedEspecialidad={setSelectedEspecialidad}
                      setSelectedOdontologo={setSelectedOdontologo}
                      handlebusqueda={handleClickBuscar}
                      stEspecialidad="selectedespecialidad"
                      stOdontologo="selectedodontologo"
                      handleOnChange={handleOnChange}
                      stEspecialidadesLista="agsEspecialidadesLista"
                      odontologosLista={odontologosLista}
                      setOdontologosLista={setOdontologosLista}
                    />
                    {loading && (
                      <div className="flex justify-end ">
                        <FacebookCircularProgress />
                      </div>
                    )}
                    {!loading && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          marginTop: 0.5,
                          marginLeft: -0.5,
                        }}
                      >
                        <Tooltip title="Menu Agendas">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{
                              ml: 0,
                              width: 32,
                              height: 32,
                            }}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                          >
                            <Avatar
                              sx={{ width: 32, height: 32, bgcolor: "avatar" }}
                            >
                              <MoreVertIcon />
                            </Avatar>
                          </IconButton>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <div key={"Div1"}>
                            <MenuItem onClick={handleBuscarTurnosLibres}>
                              <ListItemIcon>
                                <ManageSearchIcon
                                  fontSize="medium"
                                  sx={{ color: "primary.main", mr: 1 }}
                                />
                              </ListItemIcon>
                              Buscar turnos libres
                            </MenuItem>
                            {userRoles.indexOf(
                              "CLINICASAPP GESTIONAR AGENDAS"
                            ) >= 0 && (
                              <div key={"Div2"}>
                                <MenuItem onClick={handleGenerarAgendas}>
                                  <ListItemIcon>
                                    <PostAddIcon
                                      fontSize="medium"
                                      sx={{ color: "primary.main", mr: 1 }}
                                    />
                                  </ListItemIcon>
                                  Generar agendas
                                </MenuItem>
                                <MenuItem onClick={handleCambiarAgenda}>
                                  <ListItemIcon>
                                    <EditIcon
                                      fontSize="medium"
                                      sx={{ color: "primary.main", mr: 1 }}
                                    />
                                  </ListItemIcon>
                                  Cambiar agenda
                                </MenuItem>
                                <MenuItem onClick={handleBloquearAgendas}>
                                  <ListItemIcon>
                                    <RemoveCircleIcon
                                      fontSize="medium"
                                      sx={{ color: "iconred", mr: 1 }}
                                    />
                                  </ListItemIcon>
                                  Bloquear agendas
                                </MenuItem>
                              </div>
                            )}
                            <Divider />
                          </div>
                        </Menu>
                      </Box>
                    )}
                  </Box>
                </Box>

                {renderCalendar === true && (
                  <FullCalendar
                    ref={calendarRef}
                    initialView={
                      windowWidth >= 460
                        ? "multiMonthSixMonth"
                        : "multiMonthOneMonth"
                    }
                    firstDay={0}
                    initialDate={moment(Today).format("YYYY-MM-DD")}
                    multiMonthMaxColumns="3"
                    multiMonthMinWidth={180}
                    duration={{ months: windowWidth >= 460 ? "6" : "1" }}
                    id="cal_odo"
                    height="auto"
                    selectMirror="true"
                    titleFormat={{
                      day: "numeric",
                      year: "numeric",
                      month: "short",
                    }}
                    views={{
                      multiMonthSixMonth: {
                        type: "multiMonth",
                        duration: { months: 6 },
                      },
                      multiMonthOneMonth: {
                        type: "multiMonth",
                        duration: { months: 1 },
                      },
                    }}
                    eventTimeFormat={{
                      hour: "2-digit",
                      minute: "2-digit",
                      omitZeroMinute: false,
                      meridiem: "narrow",
                    }}
                    buttonText={{
                      today: "Hoy",
                      month: windowWidth >= 460 ? "Calendario" : "Cal",
                      week: "Semana",
                      day: "Dia",
                      list: "Lista",
                    }}
                    navLinks="true"
                    timeZone="UTC-3"
                    locale={esLocale}
                    plugins={[
                      multiMonthPlugin,
                      interactionPlugin,
                      timeGridPlugin,
                      listPlugin,
                    ]}
                    dateClick={handleDateClick}
                    datesSet={handledatesSet}
                    //initialView={crypto.decryptDataStorage("actualview")}
                    //  eventClick={HandleEventClick}
                    navLinkDayClick={function (date) {
                      handleDayLink(date);
                    }}
                    slotMinTime="08:00"
                    slotMaxTime="20:00"
                    slotDuration="00:05:00"
                    timeFormat="h:mm"
                    eventDisplay="auto"
                    themeSystem="bootstrap5"
                    displayEventTime={false}
                    eventDidMount={function (info) {
                      info.el.addEventListener("contextmenu", (jsEvent) => {
                        jsEvent.preventDefault();
                        // gopatient(eventId);
                      });
                      info.el.className =
                        info.el.className + " context-menu-one";
                      info.el.addEventListener("dblclick", (jsEvent) => {
                        jsEvent.preventDefault();
                        //  gopatient(eventId);
                      });
                    }}
                    dayCellDidMount={function (info) {
                      var date = info.date.toISOString().slice(0, 10);
                      const now = new Date().toISOString().slice(0, 10);
                      var el = info.el;
                      if (
                        info.isDisabled === false &&
                        feriados.indexOf(date) >= 0
                      ) {
                        el.style.backgroundColor = "#a855f7";
                        el.style.color = "#fff";
                      }
                      if (
                        info.isDisabled === false &&
                        ausencias.indexOf(date) >= 0 &&
                        conTurnos.indexOf(date) < 0 &&
                        sinTurnos.indexOf(date) < 0 &&
                        feriados.indexOf(date) < 0
                      ) {
                        el.style.background =
                          "repeating-linear-gradient(45deg,#8b5cf6,#8b5cf6,5px,#FFFFFF 5px,#FFFFFF 10px";
                      }
                      if (date === now) {
                        el.style.fontWeight = "bold";
                      }
                      if (
                        info.isDisabled === false &&
                        sinTurnos.indexOf(date) >= 0
                      ) {
                        if (info.isPast === true) {
                          const myColor = "#fef3c7";
                          el.style.backgroundColor = myColor;
                          //      el.style.borderRadius = "35px";
                        } else {
                          const myColor = "#facc15";
                          el.style.backgroundColor = myColor;
                          el.style.cursor = "pointer";
                          el.style.tooltip = "<div >Hover me</div>";

                          //      el.style.borderRadius = "35px";
                        }
                      }

                      if (
                        info.isDisabled === false &&
                        conTurnos.indexOf(date) >= 0
                      ) {
                        if (info.isPast === true) {
                          const myColor = "#dcfce7";
                          el.style.backgroundColor = myColor;
                          //  el.style.borderRadius = "35px";
                        } else {
                          const myColor = "#4ade80";
                          el.style.backgroundColor = myColor;
                          el.style.Tooltip = "Turnos libres";
                          el.style.cursor = "pointer";

                          //   el.style.borderRadius = "35px";
                        }
                      }
                      if (
                        info.isDisabled === false &&
                        bloqueados.indexOf(date) >= 0
                      ) {
                        el.style.backgroundColor = "#ef4444";
                        el.style.color = "#fff";
                      }

                      //el.style.borderWidth = "thin";
                      // el.innerHTML =
                      //  "<div className='flex items-center justify-center h-12 w-12 rounded-full bg-green-600'></div>";
                      //   "<div className='text-center'><i class='calendar-icon fas fa-plus'></i></span></div>";
                    }}
                    fixedWeekCount={false}
                    showNonCurrentDates={false}
                    //datesSet={function (info) {
                    //   alert(info.start);
                    //   alert(info.end);
                    //  }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  marginTop: { xs: 1, md: -1 },
                  marginLeft: { xs: -2, md: 0 },
                }}
              >
                {loadingAgenda && (
                  <div className="flex justify-center mt-2">
                    <FacebookCircularProgress />{" "}
                  </div>
                )}
                {showAgenda && dataAgenda.length > 0 && (
                  <AgendasTurnosTable
                    dataAgenda={dataAgenda}
                    dateAgenda={dateAgenda}
                    dateAgendaEng={dateAgendaEng}
                    buscarAgenda={buscarAgenda}
                    entreturnos={entreTurnos}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </>
        <ModalConfirmation
          showModal={showConfirmationModal}
          confirmModal={GenerarAgenda}
          hideModal={hideConfirmationModal}
          message={modalMessage}
        />
        <ModalInformation
          showModal={modalInformationOpen && modalMessage !== null}
          hideModal={hideInformationModal}
          message={modalMessage}
        />
        <ModalInformation
          showModal={modalErrorOpen && modalMessage !== null}
          hideModal={hideInformationModal}
          message={modalMessage}
          tipo="error"
        />
      </Box>
    </ProtectedRoute>
  );
};
export default AgendasSimples;
