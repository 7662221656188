import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import { useLocation } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { TextField } from "../TextField";
import * as Yup from "yup";
import * as moment from "moment";
import { ModeloSearch, ModeloUpdate } from "../ModeloCrud";
import FootPage from "../FootPage";
import SubmitOk from "../../assetss/img/check-48.png";
import Grabar from "../../assetss/img/Grabar-24.png";
import SubmitError from "../../assetss/img/error-48.png";
import loadingGif from "../../assetss/img/loading_64.gif";
import ModalError from "../modals/ModalError";
import MyButton from "../MyButton";
import crypto from "../Crypto";

const EditarPaciente = () => {
  const inputRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [submitOk, setSubmitOk] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  let Paciente = location.currentPaciente;

  const $ = require("jquery");

  useEffect(() => {
    buscarPaciente();
  }, []);

  $(document).ready(function () {
    var allInputs = $(":text:visible"); //(1)collection of all the inputs I want (not all the inputs on my form)
    $(":text").on("keydown", function (e) {
      //(2)When an input field detects a keydown event
      if (e.keyCode == 13) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) + 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
      if (e.keyCode == 27) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) - 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
    });
    if ($("#nombres").length) {
      $("#nombres").focus();
    }
  });

  function handleKeyPress(event) {
    if (event.target.type != "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  const validate = Yup.object({
    nombres: Yup.string()
      .max(50, "Máximo 50 caracteres.")
      .required("Campo Obligatorio."),
    apellido: Yup.string()
      .max(50, "Máximo 50 caracteres.")
      .required("Campo Obligatorio."),
    email: Yup.string().nullable().email("Ingrese un Email válido."),
    sexo: Yup.string()
      .min(1, "Ingrese F o M")
      .max(1, "Ingrese F o M")
      .required("Campo Obligatorio.")
      .matches(/[FMfm]+/, "Ingrese F o M"),
    domicilio: Yup.string().max(50, "Máximo 50 caracteres."),
    localidad: Yup.string().nullable().max(50, "Máximo 50 caracteres."),
  });

  async function buscarPaciente() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    let Data = {
      id: PacienteId,
    };
    if (Paciente === null || typeof Paciente === "undefined") {
      setLoading(true);
      setCurrentPatient(null);
      setErrorMessage(null);
      let result = await ModeloSearch("paciente", Data);
      setLoading(false);
      if (result.error === false) {
        setCurrentPatient(result.listado[0]);
      } else {
        setErrorMessage(result.mensaje);
      }
    } else {
      setCurrentPatient(Paciente);
    }
  }

  async function grabarPaciente(values) {
    setLoading(true);
    setSubmitOk(false);
    setErrorMessage(null);
    const Data = {
      nombres: typeof values.nombres !== "undefined" ? values.nombres : "",
      apellido: typeof values.apellido !== "undefined" ? values.apellido : "",
      email: typeof values.email !== "undefined" ? values.email : "",
      fecha_nacimiento:
        typeof values.fecha_nacimiento !== "undefined" &&
        values.fecha_nacimiento !== "Invalid date" &&
        values.fecha_nacimiento.length > 0
          ? values.fecha_nacimiento
          : null,
      sexo: typeof values.sexo !== "undefined" ? values.sexo : "",
      domicilio:
        typeof values.domicilio !== "undefined" ? values.domicilio : "",
      localidad:
        typeof values.localidad !== "undefined" ? values.localidad : "",
      telefono: typeof values.telefono !== "undefined" ? values.telefono : "",
      telefono2:
        typeof values.telefono2 !== "undefined" ? values.telefono2 : "",
      celular: typeof values.celular !== "undefined" ? values.celular : "",
    };
    let result = await ModeloUpdate("paciente", currentPatient.id, Data);
    setLoading(false);
    if (result.error === false) {
      crypto.encryptDataStorage("dataPaciente", result.listado[0]);
      setSubmitOk(true);
      handleClickVolver();
    } else {
      setErrorMessage(result.mensaje);
    }
  }

  const hideErrorModal = () => {
    setErrorMessage(null);
  };

  function handleClickVolver() {
    history.push("/pacientes");
  }

  return (
    <>
      <div className="flex justify-start ml-3 mt-3 mb-3 capitalize ">
        <i
          className="fas fa-chevron-circle-left fa-2x text-gray-500 mt-1 mr-2 cursor-pointer"
          onClick={handleClickVolver}
        />
        <button
          className="text-gray-500"
          type="button"
          onClick={handleClickVolver}
        >
          Volver al Paciente
        </button>
      </div>
      <div className="flex justify-center ">
        {currentPatient !== null && (
          <Formik
            initialValues={{
              nombres: currentPatient.nombres,
              apellido: currentPatient.apellido,
              email: currentPatient.email,
              fecha_nacimiento: moment(currentPatient.fecha_nacimiento).format(
                "YYYY-MM-DD"
              ),
              sexo: currentPatient.sexo,
              tipo_documento: currentPatient.tipo_documento,
              nro_documento: currentPatient.nro_documento,
              domicilio: currentPatient.domicilio,
              localidad: currentPatient.localidad,
              telefono: currentPatient.telefono,
              telefono2: currentPatient.telefono2,
              celular: currentPatient.celular,
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              grabarPaciente(values);
            }}
          >
            {(formik) => (
              <Form onKeyPress={handleKeyPress}>
                <div className="flex justify-center ml-3 mr-3 border-b-2  border-[#3F8CCB]">
                  <div className="grid grid-cols-1 justify-center w-full lg:grid-cols-2 lg:gap-x-10 ">
                    <TextField
                      label="T.Documento"
                      name="tipo_documento"
                      type="text"
                      style={{ width: "5em" }}
                      readOnly
                    />
                    <TextField
                      label="N.Documento"
                      name="nro_documento"
                      type="text"
                      style={{ width: "10em" }}
                      readOnly
                    />
                    <TextField
                      label="F.Nacimiento"
                      name="fecha_nacimiento"
                      type="date"
                      style={{ width: "12em" }}
                      autoComplete="off"
                    />
                    <TextField
                      label="Nombres"
                      name="nombres"
                      type="text"
                      autoComplete="off"
                    />
                    <TextField
                      label="Apellido"
                      name="apellido"
                      type="text"
                      autoComplete="off"
                    />
                    <TextField
                      label="Sexo"
                      name="sexo"
                      type="text"
                      style={{ width: "5em" }}
                    />
                    <TextField
                      label="Domicilio"
                      name="domicilio"
                      type="text"
                      autoComplete="off"
                    />
                    <TextField
                      label="Localidad"
                      name="localidad"
                      type="text"
                      autoComplete="off"
                    />
                    <TextField
                      label="Teléfono"
                      name="telefono"
                      type="text"
                      autoComplete="off"
                    />
                    <TextField
                      label="Teléfono Alternativo"
                      name="telefono2"
                      type="text"
                      autoComplete="off"
                    />
                    <TextField
                      label="Celular"
                      name="celular"
                      type="text"
                      autoComplete="off"
                    />
                    <TextField
                      label="Email"
                      name="email"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div>
                  {submitOk && (
                    <div className="flex justify-center">
                      <img className="mr-1" src={SubmitOk} />
                      <p className="mt-2 text-xl">Grabado Ok !</p>
                    </div>
                  )}
                </div>
                <div className="flex justify-center items-center h-16 border-2 ml-3 mr-3 border-[#3F8CCB]">
                  <MyButton
                    type="submit"
                    onClick={null}
                    text="Grabar"
                    width="16em"
                    icon={Grabar}
                  />
                  {loading && (
                    <div className="flex justify-center mt-2">
                      {loading && <img alt="Loading" src={loadingGif} />}
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <div className="flex justify-start ml-3 mt-3 mb-3 capitalize ">
        <i
          className="fas fa-chevron-circle-left fa-2x text-gray-500 mt-1 mr-2 cursor-pointer"
          onClick={handleClickVolver}
        />
        <button
          className="text-gray-500"
          type="button"
          onClick={handleClickVolver}
        >
          Volver al Paciente
        </button>
      </div>
      {errorMessage !== null && (
        <ModalError
          showModal={errorMessage !== null}
          hideModal={hideErrorModal}
          message={errorMessage}
        />
      )}
    </>
  );
};
export default EditarPaciente;
