import React, { forwardRef, useState, useEffect } from "react";
import Odontogram from "./Odontogram";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import AvatarAzul from "../assetss/img/logo_azul_reportes.png";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import "./../assetss/css/PageA4.css";
import { CapSentence } from "./utils/Utils";
import crypto from "./Crypto";
import * as moment from "moment";
import { date } from "yup";

export const RenderReportFichaGuardia = forwardRef((props, ref) => {
  const currentPaciente = crypto.decryptDataStorage("dataPaciente");
  const prepagacodigo = crypto.decryptDataStorage("currentprepagacodigo");
  const prepagarazon = crypto.decryptDataStorage("currentprepagarazon");
  const credencial = crypto.decryptDataStorage("currentcredencial");
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    setToday(new Date());
  }, []);

  return (
    <div ref={ref}>
      <Box
        display="flex"
        sx={{
          marginTop: 4,
          marginLeft: 4,
          marginRight: 4,
          border: 1,
        }}
      >
        <Image
          src={AvatarAzul}
          height="65px"
          width="256px"
          duration={0}
          sx={{
            marginLeft: 4,
            marginTop: 4,
            marginRight: 1,
          }}
        />
        <Box
          display="flex"
          flexDirection={"column"}
          sx={{
            marginLeft: 4,
            marginRight: 4,
            paddingBottom: 0.5,
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              marginTop: 1,
              marginLeft: 4,
              fontFamily: "Roboto",
              fontWeight: "medium",
            }}
          >
            {CapSentence(currentPaciente.nombres) +
              " " +
              CapSentence(currentPaciente.apellido)}
          </Typography>
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: 4,
              fontFamily: "Roboto",
              fontWeight: "medium",
            }}
          >
            {currentPaciente.tipo_documento +
              " " +
              currentPaciente.nro_documento}
          </Typography>
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: 4,
              fontFamily: "Roboto",
              fontWeight: "medium",
            }}
          >
            {CapSentence(prepagarazon) + "  (" + prepagacodigo + ")"}
          </Typography>
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: 4,
              fontFamily: "Roboto",
              fontWeight: "medium",
            }}
          >
            Credencial {credencial}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          marginLeft: 4,
          marginRight: 4,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          border: 1,
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={3.5}>
            <Typography
              variant="subtitle"
              sx={{
                marginLeft: 1,
                fontFamily: "Roboto",
                fontWeight: "medium",
                gap: 2,
              }}
            >
              <i className='far fa-calendar-alt fa-xl mr-4 " text-slate-500' />
              {currentPaciente.fecha_nacimiento !== null &&
              currentPaciente.fecha_nacimiento.length > 0
                ? moment(currentPaciente.fecha_nacimiento).format(
                    "DD/MM/YYYY"
                  ) +
                  " ( " +
                  currentPaciente.edad +
                  " años )"
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography
              variant="subtitle"
              sx={{
                marginLeft: 1,
                marginTop: 0,
                fontFamily: "Roboto",
                fontWeight: "medium",
                gap: 2,
              }}
            >
              <i className="fas fa-mobile-alt fa-xl text-slate-500 mr-4" />
              {currentPaciente.celular !== null &&
              currentPaciente.celular.length > 0
                ? currentPaciente.celular
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle"
              sx={{
                marginLeft: 4,
                marginTop: 0,
                fontFamily: "Roboto",
                fontWeight: "medium",
                gap: 2,
              }}
            >
              <i className="fas fa-envelope fa-xl text-slate-500 mr-4" />
              {currentPaciente.email !== null &&
              currentPaciente.email.length > 0
                ? currentPaciente.email.toLowerCase()
                : ""}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginLeft: 4,
          marginRight: 4,
          marginBottom: 4,
          paddingBottom: 0.5,
          border: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: 18,
            marginLeft: 4,
            marginTop: 1,
            fontFamily: "Roboto",
            fontWeight: "medium",
            gap: 2,
          }}
        >
          Odontólogo
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          sx={{
            marginLeft: 4,
            marginRight: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography
                variant="subtitle"
                sx={{
                  marginTop: 3,
                  fontFamily: "Roboto",
                  fontWeight: "medium",
                }}
              >
                Nombre
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="subtitle"
                sx={{
                  marginTop: 3,
                  fontFamily: "Roboto",
                  fontWeight: "medium",
                  gap: 2,
                }}
              >
                Matrícula
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle"
                sx={{
                  marginTop: 3,
                  fontFamily: "Roboto",
                  fontWeight: "medium",
                  gap: 2,
                }}
              >
                Firma y Sello
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginTop: -4,
          marginLeft: 4,
          marginRight: 4,
          marginBottom: 2,
          paddingLeft: 8,
          paddingTop: 0.5,
          border: 1,
        }}
      >
        <Odontogram data={[]} tratamiento={null} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: 2,
          paddingY: 0.5,
          marginLeft: 4,
          marginRight: 4,
          marginTop: -2,
          border: 1,
        }}
      >
        <Typography variant="caption" align="left">
          Por la presente reconozco que el/la Dr./a tratante me ha informado en
          términos comprensibles para mi y he entendido acabadamente el tipo de
          tratamiento de urgencia que debe efectuarse en base al Diagnóstico
          realizado, así como también las alternativa s de tratamiento y sus
          riesgos.
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          El tratamiento aceptado
          es----------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: 1.5,
          paddingY: 0.5,
          marginLeft: 4,
          marginRight: 4,
          border: 1,
        }}
      >
        <Typography variant="caption" align="left" sx={{ fontWeight: "bold" }}>
          ALERGIA A ALGUNA MEDICACIÓN: SI - NO
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          ESPECIFIQUE A
          CUAL/ES----------------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{ fontWeight: "bold", marginTop: 1 }}
        >
          DIABETES: SI - NO
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          ESTÁ EN TRATAMIENTO
          ?---------------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          INDIQUE SI TOMA
          MEDICACIÓN------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{ fontWeight: "bold", marginTop: 1 }}
        >
          HIPERTENSIÓN: SI - NO
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          ESTÁ EN TRATAMIENTO
          ?---------------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          INDIQUE SI TOMA
          MEDICACIÓN------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{ fontWeight: "bold", marginTop: 1 }}
        >
          ANTECEDENTES CARDIOVASCULARES: SI - NO
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          ESTÁ EN TRATAMIENTO
          ?---------------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          INDIQUE SI TOMA
          MEDICACIÓN------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{ fontWeight: "bold", marginTop: 1 }}
        >
          TOMA ANTICOAGULANTES? SI - NO
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          INDIQUE CUALES
          ?--------------------------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{ fontWeight: "bold", marginTop: 1 }}
        >
          ESTA REALIZANDO TRATAMIENTO CON FIJADORES DE CALCIO. SI - NO
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{ fontWeight: "bold", marginTop: 1 }}
        >
          ESTÁ BAJO ALGÚN TRATAMIENTO MÉDICO ? SI - NO
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{
            marginTop: 1,
          }}
        >
          ESPECIFIQUE------------------------------------------------------------------------------------------------------------------------------------------------------------------
        </Typography>
        <Typography
          variant="caption"
          align="left"
          sx={{ fontWeight: "bold", marginTop: 1 }}
        >
          EMBARAZADA O CREE ESTARLO? SI - NO
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingX: 1.5,
          paddingY: 0.5,
          marginLeft: 4,
          marginRight: 4,
          marginTop: 6,
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <Typography
              variant="caption"
              align="left"
              sx={{ fontWeight: "bold", marginTop: 1 }}
            >
              Fecha {moment(today).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="caption"
              align="left"
              sx={{ fontWeight: "bold", marginTop: 1 }}
            >
              Hora {moment(today).format("HH:mm:ss")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="caption"
              align="left"
              sx={{ fontWeight: "bold", marginTop: 1 }}
            >
              FIRMA DEL PACIENTE
            </Typography>
          </Grid>
        </Grid>
      </Box>
      )
    </div>
  );
});
