import React, { useState, useRef } from "react";
import "../../assetss/css/App.css";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import SettingsIcon from "@mui/icons-material/Settings";
import RecibirTrabajosLaboratorio from "../process/RecibirTrabajosLaboratorio";

export default function Procesos() {
  const [selectedProcess, setSelectedProcess] = React.useState(null);
  const allProcesss = [
    { id: 1, grupo: "Afiliados", process: "Unificar pacientes." },
    { id: 2, grupo: "Cajas", process: "Exportar cajas al sistema contable." },
    {
      id: 3,
      grupo: "Presupuestos",
      process: "Recibir trabajos de laboratorio.",
    },
  ];
  const [key, setKey] = useState(0);
  const componentRef = useRef();

  function onChangeProcess(value) {
    setSelectedProcess(value);
  }

  return (
    <>
      <div className="flex justify-center mt-6 ml-2 mr-3 ">
        <Grid
          container
          maxWidth="xl"
          rowSpacing={{ xs: 2, md: 4 }}
          columnSpacing={{ xs: 0, md: 6 }}
          sx={{ marginLeft: { xs: 1, md: 6 } }}
        >
          <Grid item xs={12} md={5}>
            <Box display={"flex"}>
              <SettingsIcon
                sx={{ fontSize: 32, color: "primary.main", mt: 0.5, mr: 1 }}
              />
              <Autocomplete
                id="grouped-demo"
                options={allProcesss}
                value={selectedProcess}
                onChange={(event, newValue) => {
                  onChangeProcess(newValue);
                }}
                groupBy={(option) => option.grupo}
                getOptionLabel={(option) => option.process}
                sx={{ width: { xs: 320, md: 500 } }}
                renderInput={(params) => (
                  <TextField {...params} label="Proceso" />
                )}
                size="small"
                renderGroup={(params) => (
                  <Box key={params.key}>
                    <Typography
                      fontSize={20}
                      color="avatar"
                      sx={{
                        marginLeft: 2,
                        marginBottom: 1,
                        marginRight: 2,
                        borderBottom: 1,
                        bgcolor: "#dbeafe",
                      }}
                    >
                      {params.group}
                    </Typography>
                    {params.children}
                  </Box>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7} lg={9}></Grid>
        </Grid>
      </div>
      <Box>
        {selectedProcess !== null && selectedProcess.id === 3 && (
          <RecibirTrabajosLaboratorio />
        )}
      </Box>
    </>
  );
}
