import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import { useLocation } from "react-router-dom";
import crypto from "../Crypto";
import { ModeloGetUrl } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ModalInformation from "../modals/ModalInformation";
import BackPage from "../BackPage";
import PacienteHeader from "./PacienteHeader";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteModel from "../DeleteModel";
import ModalQuejas from "../modals/ModalQuejas";
import RenderCustomTable from "../RenderCustomTable";
import { Troubleshoot } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const PacienteQuejas = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allQuejas, setallQuejas] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [backPage, setBackPage] = useState("pacientes");
  const location = useLocation();
  const [deleteQuejaId, setDeleteQuejaId] = useState(null);
  const [selectedQueja, setSelectedQueja] = useState(null);
  const [insertarQueja, setInsertarQueja] = useState(false);
  const [editarQueja, setEditarQueja] = useState(false);

  const columns = [
    {
      field: "fecha",
      headerName: "Fecha",
      isDate: true,
      // style: {
      //   backgroundColor: "#bae6fd",
      //    border: "1px solid #334155",
      //   padding: "10px",
      //  },
    },
    {
      field: "motivo",
      headerName: "Motivo",
    },
    { field: "queja", headerName: "Queja" },
  ];

  const actions = [
    {
      icon: EditIcon,
      handle: (row) => handleEdit(row),
      sx: { color: "primary.main" },
    },
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
    },
  ];

  useEffect(() => {
    if (typeof location.backpage !== "undefined") {
      setBackPage(location.backpage);
    }
    buscarQuejas();
  }, []);

  async function buscarQuejas() {
    setallQuejas(null);
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      const result = await ModeloGetUrl("paciente", PacienteId, "quejas", null);
      setLoading(false);
      if (result.error === false) {
        if (result.listado.length > 0) {
          let sorted = result.listado.sort((a, b) =>
            a.fecha > b.fecha ? -1 : 1
          );
          setallQuejas(sorted);
        }
      } else {
        history.push("/");
      }
    } else {
      handleClickVolver();
    }
  }

  const handleNew = () => {
    setInsertarQueja(true);
  };

  const handleEdit = (row) => {
    setSelectedQueja(row);
    setEditarQueja(true);
  };

  function handleDelete(row) {
    setDeleteQuejaId(row.id);
  }

  const hideQueja = () => {
    setEditarQueja(false);
    setInsertarQueja(false);
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  function handleClickVolver() {
    history.push("/" + backPage);
  }
  return (
    <>
      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <PacienteHeader />
      {loading && (
        <div className="flex justify-center mt-2">
          <FacebookCircularProgress />
        </div>
      )}
      {!loading && (
        <>
          <Box sx={{ display: "flex", marginTop: 3, marginBottom: 0, px: 3 }}>
            <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
              Quejas
            </Typography>
            <IconButton
              aria-label="new"
              onClick={handleNew}
              sx={{ p: "1px", marginTop: -1 }}
            >
              <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
            </IconButton>
          </Box>
          <RenderCustomTable
            data={allQuejas}
            columns={columns}
            actions={actions}
          />
        </>
      )}
      {insertarQueja && (
        <ModalQuejas
          row={null}
          showModal={insertarQueja}
          hideModal={hideQueja}
          buscarQuejas={buscarQuejas}
        />
      )}
      {editarQueja && (
        <ModalQuejas
          row={selectedQueja}
          showModal={editarQueja}
          hideModal={hideQueja}
          buscarQuejas={buscarQuejas}
        />
      )}

      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      {deleteQuejaId !== null && (
        <DeleteModel
          model={"quejas"}
          modelid={deleteQuejaId}
          okmethod={buscarQuejas}
          nullmethod={setDeleteQuejaId}
        />
      )}
    </>
  );
};
export default PacienteQuejas;
