import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import SaveIcon from "@mui/icons-material/Save";
import { ModeloPost } from "./ModeloCrud";
import { CapSentence } from "./utils/Utils";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import crypto from "./Crypto";
import LoadingButton from "@mui/lab/LoadingButton";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ModalInformation from "./modals/ModalInformation";
import { CapSentenceArray, BpRadio } from "./utils/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import RadioGroup from "@mui/material/RadioGroup";
import Odontogram from "./Odontogram";
import * as moment from "moment";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  IconButton,
  TextField,
  Typography,
  Button,
  Container,
  Box,
  Avatar,
  Grid,
  Autocomplete,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  tableRowClasses,
  Switch,
} from "@mui/material";

const validationSchema = yup.object({
  tipo: yup.string().required("Seleccione el Tipo"),
  coe: yup.object().required("Seleccione un Odontólogo"),
  fecha: yup
    .date()
    .min(new Date("2023-01-01"), "Fecha inválida")
    .nullable()
    .typeError("Fecha inválida")
    .required("Ingrese la fecha de alta"),
});

export default function InsertUpdateFichado({
  fichadorow,
  itemsfichado,
  buscarCoes,
  hideModal,
  buscarFichados,
}) {
  const [allCoes, setAllCoes] = useState(null);
  const [fromTooth, setFromTooth] = useState(null);
  const [toTooth, setToTooth] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [myloading, setMyLoading] = React.useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const [newItems, setNewItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [piezasDentales, setPiezasDentales] = useState(null);
  const [nomencladores, setNomencladores] = useState(null);
  const [valuetipo, setValueTipo] = React.useState("F");
  const [valuecolor, setValueColor] = React.useState("rojo");
  const [selectedTratamiento, setSelectedTratamiento] = React.useState(null);
  let [itemstoadd, setItemsToAdd] = React.useState([]);
  const [rfichado, serRFichado] = useState(
    crypto
      .decryptDataStorage("rfichado")
      .filter((obj) => obj.descripcion !== "SIN PATOLOGIA")
      .sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))
  );
  const [sinPatologiaChecked, setSinPatologiaChecked] = useState(
    fichadorow !== null && fichadorow.sin_patologia === 1 ? true : false
  );
  const [cleanOdontograma, setCleanOdontograma] = React.useState(false);
  const handleChangeColor = (event) => {
    setValueColor(event.target.value);
  };
  const onTratamientoChange = (event, values) => {
    if (values !== null) {
      setSelectedTratamiento(values);
    } else {
      setSelectedTratamiento(null);
    }
    setCleanOdontograma(false);
  };

  const teethNumbers = [
    18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46,
    45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38, 55, 54, 53, 52, 51, 61,
    62, 63, 64, 65, 85, 84, 83, 82, 81, 71, 72, 73, 74, 75,
  ];

  const formik = useFormik({
    initialValues: {
      tipo: fichadorow !== null ? fichadorow.tipo_fichado : "F",
      fecha:
        fichadorow !== null ? dayjs(fichadorow.fecha_emision) : dayjs(Date()),
      coe: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      EmitirFichado();
    },
  });

  const handleChangeSinPatologiaChecked = (event) => {
    setSinPatologiaChecked(event.target.checked);
  };

  const ldToday = new Date();
  const lcDate =
    ldToday.getFullYear() +
    "-" +
    (ldToday.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    ldToday.getDate().toString().padStart(2, "0");

  const handleChangeTipo = (event) => {
    formik.setFieldValue("tipo", event.target.value);
  };

  const componentRef = useRef();

  let odontogramState = {};
  let odontogramaRows = [];

  const handleToothUpdate = (id, toothState) => {
    odontogramState[id] = toothState;
  };

  function handleOdontogramaRow(row) {
    odontogramaRows.push(row);
  }

  crypto.encryptDataStorage("odontogramaRows", []);

  const initialState = {
    Cavities: {
      center: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    Extract: 0,
    Crown: 0,
    Cimplante: 0,
    Endodoncia: 0,
    Implant: 0,
    Perio: 0,
    Fija: 0,
    Removible: 0,
    Perno: 0,
  };

  const handleClean = () => {
    odontogramState = {};
    setSelectedTratamiento(null);
    setCleanOdontograma(true);
  };

  const addRangeItems = () => {
    itemstoadd = [];

    let from = fromTooth;
    let to = toTooth;
    let temp = null;
    if (selectedTratamiento?.descripcion.length > 0) {
      if (!isNaN(fromTooth - 1) && !isNaN(toTooth)) {
        if (from > to) {
          temp = from;
          from = to;
          to = temp;
          setFromTooth(from);
          setToTooth(to);
        }
        for (var i = from; i <= to; i++) {
          if (teethNumbers.indexOf(i) >= 0) {
            const item = {
              diente: i,
              tratamiento: selectedTratamiento.descripcion,
              color: valuecolor === "rojo" ? "r" : "a",
            };
            itemstoadd.push(item);
          }
        }
        const tempitem = [...itemstoadd];
        setItemsToAdd(tempitem);
      }
    }
  };

  const handleChangeFrom = (event) => {
    const inputValue = event.target.value;
    // Validate if the input is a number with two digits
    if (/^\d{0,2}$/.test(inputValue)) {
      setFromTooth(Number(inputValue));
    }
  };

  const handleChangeTo = (event) => {
    const inputValue = event.target.value;
    // Validate if the input is a number with two digits
    if (/^\d{0,2}$/.test(inputValue)) {
      setToTooth(Number(inputValue));
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "2px",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function checkItems() {
    let addRow = crypto.decryptDataStorage("odontogramaRows");

    let lnTotal = 0;
    for (const property in odontogramState) {
      //
      const selPiezas = piezasDentales.filter((p) => p.diente === property);
      const selTratamiento = rfichado.filter(
        (t) => t.descripcion === "PROTESIS REMOVIBLE"
      );

      lnTotal += odontogramState[property].Cavities.bottom;
      lnTotal += odontogramState[property].Cavities.center;
      lnTotal += odontogramState[property].Cavities.left;
      lnTotal += odontogramState[property].Cavities.right;
      lnTotal += odontogramState[property].Cavities.top;
      lnTotal += odontogramState[property].Cimplante;
      lnTotal += odontogramState[property].Crown;
      lnTotal += odontogramState[property].Endodoncia;
      lnTotal += odontogramState[property].Extract;
      lnTotal += odontogramState[property].Fija;
      lnTotal += odontogramState[property].Implant;
      lnTotal += odontogramState[property].Perio;
      lnTotal += odontogramState[property].Perno;
      lnTotal += odontogramState[property].Removible;
    }
    return lnTotal;
  }

  async function EmitirFichado() {
    if (checkItems() === 0 && sinPatologiaChecked === false) {
      setModalMessage("Ingrese al menos un Tratamiento o marque sin patología");
      setModalInformationOpen(true);
      return;
    }
    let user_id = crypto.decryptLocalStorage("user_id");
    let paciente_id = crypto.decryptDataStorage("currentpatient");
    let coe_id = formik.values.coe.coe_id;
    let fecha_emision = formik.values.fecha.format().slice(0, 10);
    let tipo_fichado = formik.values.tipo;
    const Data = [];
    const row = {};
    const sinPatologia = sinPatologiaChecked && checkItems() === 0 ? "1" : "0";
    if (fichadorow === null) {
      row["id"] = -1;
    } else {
      row["id"] = fichadorow.id;
    }
    row["fecha_emision"] = fecha_emision;
    row["fecha_auditoria"] = fecha_emision;
    row["tipo_fichado"] = tipo_fichado;
    row["paciente_id"] = paciente_id;
    row["centro_odontologo_especialidad_id"] = coe_id;
    row["user_id_emision"] = user_id;
    row["sin_patologia"] = sinPatologiaChecked ? 1 : 0;
    row["created_at"] = fecha_emision;
    row["updated_at"] = fecha_emision;
    const lcId = fichadorow === null ? "-1" : fichadorow.id.toString();
    let stencabezado =
      "id=" +
      lcId +
      "&fecha_emision=" +
      fecha_emision +
      "&fecha_auditoria=" +
      fecha_emision +
      "&tipo_fichado=" +
      tipo_fichado +
      "&paciente_id=" +
      paciente_id +
      "&centro_odontologo_especialidad_id=" +
      coe_id +
      "&user_id_emision=" +
      user_id +
      "&sin_patologia=" +
      sinPatologia +
      "&created_at=" +
      fecha_emision +
      "&updated_at=" +
      fecha_emision;

    //Items
    let lnItem = 0;
    let stitems = "&items=";
    let lcTratamientoId = "";
    let lcTipo = "";
    let sector1 = "";
    let sector2 = "";
    let sector3 = "";
    let sector4 = "";
    let sector5 = "";
    let sector6 = "";
    for (const property in odontogramState) {
      const cuadrante = property.toString().substring(0, 1);
      const selPiezas = piezasDentales.filter(
        (p) => p.diente.toString() === property.toString()
      );
      let lcPiezaId = selPiezas[0].id;
      let lcCaras = "";
      if (
        odontogramState[property].Cavities.top +
          odontogramState[property].Cavities.left +
          odontogramState[property].Cavities.right +
          odontogramState[property].Cavities.bottom +
          odontogramState[property].Cavities.center >
        0
      ) {
        if (odontogramState[property].Cavities.top > 0) {
          if (odontogramState[property].Cavities.top < 3) {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "OBTURACION"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo = odontogramState[property].Cavities.top === 1 ? "A" : "R";
          } else {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "ESTETICA"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo = odontogramState[property].Cavities.top === 3 ? "A" : "R";
          }
          if (["1", "2", "5", "6"].indexOf(cuadrante) >= 0) {
            lcCaras = "V";
          }
          if (["3", "4", "7", "8"].indexOf(cuadrante) >= 0) {
            lcCaras = "L";
          }
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            lcPiezaId,
            lcCaras
          );
        }
        if (odontogramState[property].Cavities.left > 0) {
          if (odontogramState[property].Cavities.left < 3) {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "OBTURACION"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo = odontogramState[property].Cavities.left === 1 ? "A" : "R";
          } else {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "ESTETICA"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo = odontogramState[property].Cavities.left === 3 ? "A" : "R";
          }
          if (["1", "4", "5", "8"].indexOf(cuadrante) >= 0) {
            lcCaras = "M";
          }
          if (["2", "3", "6", "7"].indexOf(cuadrante) >= 0) {
            lcCaras = "D";
          }
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            lcPiezaId,
            lcCaras
          );
        }
        if (odontogramState[property].Cavities.right > 0) {
          if (odontogramState[property].Cavities.right < 3) {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "OBTURACION"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo = odontogramState[property].Cavities.right === 1 ? "A" : "R";
          } else {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "ESTETICA"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo = odontogramState[property].Cavities.right === 3 ? "A" : "R";
          }
          if (["1", "4", "5", "8"].indexOf(cuadrante) >= 0) {
            lcCaras = "D";
          }
          if (["2", "3", "6", "7"].indexOf(cuadrante) >= 0) {
            lcCaras = "M";
          }
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            lcPiezaId,
            lcCaras
          );
        }
        if (odontogramState[property].Cavities.bottom > 0) {
          if (odontogramState[property].Cavities.bottom < 3) {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "OBTURACION"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo =
              odontogramState[property].Cavities.bottom === 1 ? "A" : "R";
          } else {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "ESTETICA"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo =
              odontogramState[property].Cavities.bottom === 3 ? "A" : "R";
          }
          if (["1", "2", "5", "6"].indexOf(cuadrante) >= 0) {
            lcCaras = "L";
          }
          if (["3", "4", "7", "8"].indexOf(cuadrante) >= 0) {
            lcCaras = "V";
          }
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            lcPiezaId,
            lcCaras
          );
        }
        if (odontogramState[property].Cavities.center > 0) {
          if (odontogramState[property].Cavities.center < 3) {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "OBTURACION"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo =
              odontogramState[property].Cavities.center === 1 ? "A" : "R";
          } else {
            const selTratamiento = rfichado.filter(
              (t) => t.descripcion === "ESTETICA"
            );
            lcTratamientoId = selTratamiento[0].id;
            lcTipo =
              odontogramState[property].Cavities.center === 3 ? "A" : "R";
          }
          lcCaras = "O";
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            lcPiezaId,
            lcCaras
          );
        }
      }
      if (odontogramState[property].Cimplante > 0) {
        const selTratamiento = rfichado.filter(
          (t) => t.descripcion === "CORONA SOBRE IMPLANTE"
        );
        lcTratamientoId = selTratamiento[0].id;
        lcTipo = odontogramState[property].Cimplante === 1 ? "A" : "R";
        lnItem += 1;
        stitems += addItem(
          lnItem,
          lcId,
          lcTratamientoId,
          lcTipo,
          lcPiezaId,
          ""
        );
      }
      if (odontogramState[property].Crown > 0) {
        const selTratamiento = rfichado.filter(
          (t) => t.descripcion === "CORONA"
        );
        lcTratamientoId = selTratamiento[0].id;
        lcTipo = odontogramState[property].Crown === 1 ? "A" : "R";
        lnItem += 1;
        stitems += addItem(
          lnItem,
          lcId,
          lcTratamientoId,
          lcTipo,
          lcPiezaId,
          ""
        );
      }
      if (odontogramState[property].Endodoncia > 0) {
        const selTratamiento = rfichado.filter(
          (t) => t.descripcion === "ENDODONCIA"
        );
        lcTratamientoId = selTratamiento[0].id;
        lcTipo = odontogramState[property].Endodoncia === 1 ? "A" : "R";
        lnItem += 1;
        stitems += addItem(
          lnItem,
          lcId,
          lcTratamientoId,
          lcTipo,
          lcPiezaId,
          ""
        );
      }
      if (odontogramState[property].Extract > 0) {
        lcTipo = odontogramState[property].Extract === 1 ? "A" : "R";
        if (lcTipo === 1) {
          const selTratamiento = rfichado.filter(
            (t) => t.descripcion === "DIENTES AUSENTES"
          );
          lcTratamientoId = selTratamiento[0].id;
        } else {
          const selTratamiento = rfichado.filter(
            (t) => t.descripcion === "EXTRACCION"
          );
          lcTratamientoId = selTratamiento[0].id;
        }
        lnItem += 1;
        stitems += addItem(
          lnItem,
          lcId,
          lcTratamientoId,
          lcTipo,
          lcPiezaId,
          ""
        );
      }
      if (odontogramState[property].Fija > 0) {
        const selTratamiento = rfichado.filter(
          (t) => t.descripcion === "PROTESIS FIJA"
        );
        lcTratamientoId = selTratamiento[0].id;
        lcTipo = odontogramState[property].Fija === 1 ? "A" : "R";
        lnItem += 1;
        stitems += addItem(
          lnItem,
          lcId,
          lcTratamientoId,
          lcTipo,
          lcPiezaId,
          ""
        );
      }
      if (odontogramState[property].Implant > 0) {
        const selTratamiento = rfichado.filter(
          (t) => t.descripcion === "IMPLANTE"
        );
        lcTratamientoId = selTratamiento[0].id;
        lcTipo = odontogramState[property].Implant === 1 ? "A" : "R";
        lnItem += 1;
        stitems += addItem(
          lnItem,
          lcId,
          lcTratamientoId,
          lcTipo,
          lcPiezaId,
          ""
        );
      }
      if (odontogramState[property].Perio > 0) {
        const selTratamiento = rfichado.filter(
          (t) => t.descripcion === "PERIODONCIA"
        );
        lcTratamientoId = selTratamiento[0].id;
        lcTipo = odontogramState[property].Perio === 1 ? "A" : "R";
        if (
          ["14", "15", "16", "17", "18"].indexOf(property.toString()) >= 0 &&
          sector1 === ""
        ) {
          sector1 = "OK";
          const selPerio = piezasDentales.filter((p) => p.diente === "1S");
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            selPerio[0].id,
            ""
          );
        }
        if (
          ["11", "12", "13", "21", "22", "23"].indexOf(property.toString()) >=
            0 &&
          sector2 === ""
        ) {
          sector2 = "OK";
          const selPerio = piezasDentales.filter((p) => p.diente === "2S");
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            selPerio[0].id,
            ""
          );
        }
        if (
          ["24", "25", "26", "27", "28"].indexOf(property.toString()) >= 0 &&
          sector3 === ""
        ) {
          sector3 = "OK";
          const selPerio = piezasDentales.filter((p) => p.diente === "3S");
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            selPerio[0].id,
            ""
          );
        }
        if (
          ["34", "35", "36", "37", "38"].indexOf(property.toString()) >= 0 &&
          sector4 === ""
        ) {
          sector4 = "OK";
          const selPerio = piezasDentales.filter((p) => p.diente === "4S");
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            selPerio[0].id,
            ""
          );
        }
        if (
          ["31", "32", "33", "41", "42", "43"].indexOf(property.toString()) >=
            0 &&
          sector5 === ""
        ) {
          sector5 = "OK";
          const selPerio = piezasDentales.filter((p) => p.diente === "5S");
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            selPerio[0].id,
            ""
          );
        }
        if (
          ["44", "45", "46", "47", "48"].indexOf(property.toString()) >= 0 &&
          sector6 === ""
        ) {
          sector6 = "OK";
          const selPerio = piezasDentales.filter((p) => p.diente === "6S");
          lnItem += 1;
          stitems += addItem(
            lnItem,
            lcId,
            lcTratamientoId,
            lcTipo,
            selPerio[0].id,
            ""
          );
        }
      }
      if (odontogramState[property].Perno > 0) {
        const selTratamiento = rfichado.filter(
          (t) => t.descripcion === "PERNO MUÑON"
        );
        lcTratamientoId = selTratamiento[0].id;
        lcTipo = odontogramState[property].Perno === 1 ? "A" : "R";
        lnItem += 1;
        stitems += addItem(
          lnItem,
          lcId,
          lcTratamientoId,
          lcTipo,
          lcPiezaId,
          ""
        );
      }
      if (odontogramState[property].Removible > 0) {
        const selTratamiento = rfichado.filter(
          (t) => t.descripcion === "PROTESIS REMOVIBLE"
        );
        lcTratamientoId = selTratamiento[0].id;
        lcTipo = odontogramState[property].Removible === 1 ? "A" : "R";
        lnItem += 1;
        stitems += addItem(
          lnItem,
          lcId,
          lcTratamientoId,
          lcTipo,
          lcPiezaId,
          ""
        );
      }
    }
    setMyLoading(true);

    if (stitems === "&items=") {
      stitems = "&items=[]";
    }
    if (fichadorow === null) {
      let result = await ModeloPost(
        "fichado/crear",
        null,
        null,
        stencabezado + stitems
      );
      if (result.error === false) {
        buscarFichados();
        handleClose();
      } else {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    } else {
      let result = await ModeloPost(
        "fichado",
        fichadorow.id,
        "actualizar-web",
        stencabezado + stitems
      );
      if (result.error === false) {
        buscarFichados();
        handleClose();
      } else {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    }
    setMyLoading(false);
    return;
  }

  function addItem(lnItem, lcId, lcTratamientoId, lcTipo, lcPiezaId, lcCaras) {
    let lcRet =
      "linea" +
      "-1," +
      lcId.toString().trim() +
      "," +
      lcTratamientoId +
      "," +
      lcTipo +
      "," +
      lcPiezaId +
      "," +
      lcCaras;
    return lcRet;
  }

  function NewaddItem(
    lnItem,
    lcId,
    lcTratamientoId,
    lcTipo,
    lcPiezaId,
    lcCaras
  ) {
    let lcRet =
      "&items[" +
      lnItem.toString().trim() +
      "][id]=-1&items[" +
      lnItem.toString().trim() +
      "][fichado_id]=" +
      lcId.toString().trim() +
      "&items[" +
      lnItem.toString().trim() +
      "][referencia_fichado_id]=" +
      lcTratamientoId +
      "&items[" +
      lnItem.toString().trim() +
      "][tipo_referencia]=" +
      lcTipo +
      "&items[" +
      lnItem.toString().trim() +
      "][pieza_dental_id]=" +
      lcPiezaId +
      "&items[" +
      lnItem.toString().trim() +
      "][caras]=" +
      lcCaras;
    return lcRet;
  }

  const handleClose = () => {
    hideModal();
  };

  function searchCoes() {
    const coes = CapSentenceArray(crypto.decryptDataStorage("allcoes"), "coe");
    const nomencladores = CapSentenceArray(
      crypto.decryptDataStorage("nomencladores"),
      ["codigo_descripcion"]
    );
    setAllCoes(coes);
    setNomencladores(nomencladores);
    setPiezasDentales(crypto.decryptDataStorage("piezas_dentales"));
    if (fichadorow !== null) {
      const selCoe = coes.filter((c) => c.coe_id === fichadorow.coe_id);

      if (selCoe.length > 0) {
        formik.setFieldValue("coe", selCoe[0]);
      }
    }
    return;
  }

  function busquedaCoes() {
    searchCoes();
  }

  const onCoeChange = (event, values) => {
    formik.setFieldValue("coe", values);
  };

  return (
    <>
      <div>
        {allCoes == null && buscarCoes && busquedaCoes()}
        {allCoes !== null && allCoes.length > 0 && (
          <form key="fichado-form" onSubmit={formik.handleSubmit}>
            <Container maxWidth="lg" sx={{ marginTop: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={2}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"en-gb"}
                  >
                    <DateField
                      id="fecha"
                      label="Fecha"
                      value={formik.values.fecha}
                      inputFormat="DD/MM/YYYY"
                      format="DD/MM/YYYY"
                      onChange={(value) => formik.setFieldValue("fecha", value)}
                      size="small"
                      helperText={
                        formik.touched.fecha ? formik.errors.fecha : ""
                      }
                      error={
                        formik.touched.fecha && Boolean(formik.errors.fecha)
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Autocomplete
                    id="coe"
                    options={allCoes}
                    autoHighlight
                    size="small"
                    onChange={onCoeChange}
                    value={formik.values.coe}
                    getOptionLabel={(option) => CapSentence(option.coe)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Odontólogo"
                        error={formik.touched.coe && Boolean(formik.errors.coe)}
                        helperText={formik.touched.coe && formik.errors.coe}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: { justifyContent: "center" },
                        }}
                      />
                    )}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.coe, inputValue, {
                        insideWords: true,
                      });
                      const parts = parse(option.coe, matches);
                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 800 : 400,
                                  //color: part.highlight ? "#0ea5e9" : "black",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={5} sx={{ marginTop: -0.5 }}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={formik.values.tipo}
                    onChange={handleChangeTipo}
                  >
                    <FormControlLabel
                      value="F"
                      control={<BpRadio />}
                      label={
                        <Typography sx={{ fontWeight: "bold" }}>
                          Fichado
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="P"
                      control={<BpRadio />}
                      label={
                        <Typography sx={{ fontWeight: "bold" }}>
                          Periodontal
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Container>

            <Container
              maxWidth="lg"
              sx={{ borderBottom: 1, borderColor: "grey.400", marginTop: 2 }}
            >
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Typography sx={{ marginTop: 2, marginBottom: 2 }} variant="h6">
                  Odontograma
                </Typography>
                <DeleteIcon
                  sx={{
                    color: "iconred",
                    fontSize: 32,
                    marginTop: 2,
                    marginLeft: 2,
                    cursor: "pointer",
                  }}
                  onClick={handleClean}
                />
              </Box>
              <>
                <div className="alert alert-warning m-3 sm:hidden" role="alert">
                  El ancho de la pantalla no permite visualizar el Odontograma.
                  Pruebe colocando el móvil en posición horizontal.
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        justifyItems: "start",
                        alignItems: "start",
                        flexDirection: "column",
                        marginBottom: 4,
                      }}
                    >
                      <Autocomplete
                        id="rfichado"
                        sx={{ width: 300, py: 1, marginBottom: 2 }}
                        options={rfichado}
                        value={selectedTratamiento}
                        autoHighlight
                        size="small"
                        onChange={onTratamientoChange}
                        getOptionLabel={(option) => option.descripcion}
                        renderInput={(params) => (
                          <TextField {...params} label="Tratamiento" />
                        )}
                      />
                      <Box
                        sx={{
                          width: 300,
                          display: "flex",
                          justifyItems: "center",
                          alignItems: "center",
                          marginTop: 1,
                          marginLeft: 1,
                        }}
                      >
                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={valuecolor}
                          onChange={handleChangeColor}
                        >
                          <FormControlLabel
                            value="rojo"
                            control={
                              <BpRadio
                                sx={{
                                  color: "#ef4444",
                                  "&.Mui-checked": {
                                    color: "#ef4444",
                                  },
                                }}
                              />
                            }
                            label={
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  bgcolor: "iconred",
                                  marginBottom: 0.5,
                                }}
                              >
                                R
                              </Avatar>
                            }
                          />
                          <FormControlLabel
                            value="azul"
                            control={
                              <BpRadio
                                sx={{
                                  color: "#3b82f6",
                                  "&.Mui-checked": {
                                    color: "#3b82f6",
                                  },
                                }}
                              />
                            }
                            label={
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  bgcolor: "primary.main",
                                  marginBottom: 0.5,
                                }}
                              >
                                A
                              </Avatar>
                            }
                          />
                        </RadioGroup>
                      </Box>
                      <Box dislplay="flex" sx={{ p: "1px", mt: 3, gap: 2 }}>
                        <TextField
                          label="Desde"
                          variant="outlined"
                          value={fromTooth}
                          onChange={handleChangeFrom}
                          inputProps={{
                            maxLength: 2,
                            inputMode: "numeric",
                          }}
                          sx={{ width: "100px" }}
                        />
                        <TextField
                          label="Hasta"
                          variant="outlined"
                          value={toTooth}
                          onChange={handleChangeTo}
                          inputProps={{
                            maxLength: 2,
                            inputMode: "numeric",
                          }}
                          sx={{ width: "100px", ml: 2 }}
                        />
                        <IconButton
                          aria-label="additems"
                          onClick={addRangeItems}
                          sx={{ p: "1px", marginTop: 0 }}
                        >
                          <AddRoundedIcon
                            sx={{ fontSize: 48, color: "icongreen" }}
                          />
                        </IconButton>
                      </Box>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={sinPatologiaChecked}
                              onChange={handleChangeSinPatologiaChecked}
                            />
                          }
                          label={
                            <Typography
                              color={
                                sinPatologiaChecked ? "#0284c7" : "#94a3b8"
                              }
                              fontWeight={
                                sinPatologiaChecked ? "bold" : "regular"
                              }
                            >
                              Sin patología
                            </Typography>
                          }
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Odontogram
                      data={itemsfichado}
                      tratamiento={
                        selectedTratamiento !== null
                          ? selectedTratamiento.descripcion
                          : null
                      }
                      color={valuecolor === "rojo" ? 1 : 2}
                      readonly={false}
                      handleToothUpdate={handleToothUpdate}
                      cleanodontograma={cleanOdontograma}
                      itemstoadd={itemstoadd}
                      setItemsToAdd={setItemsToAdd}
                    />
                  </Grid>
                </Grid>
              </>
            </Container>

            <Container maxWidth="sm">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  marginTop: 4,
                }}
                fullWidth
              >
                <Button
                  onClick={handleClose}
                  color="primary"
                  fullWidth
                  variant="contained"
                  loadingPosition="end"
                  sx={{
                    backgroundColor: "#cbd5e1",
                    ":hover": {
                      bgcolor: "#475569", // theme.palette.primary.main
                      color: "white",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  fullWidth
                  loading={myloading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.main",
                    ":hover": {
                      bgcolor: "#475569", // theme.palette.primary.main
                      color: "white",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  <span>Grabar</span>
                </LoadingButton>
              </Box>
            </Container>
          </form>
        )}
      </div>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
    </>
  );
}
