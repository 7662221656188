import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PrintButton } from "../PrintButton";
import { RenderReportFichaGuardia } from "../RenderReportFichaGuardia";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import BackPage from "../BackPage";

export default function RenderReport() {
  const [backPage, setBackPage] = useState("pacientes");
  const componentRef = useRef();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (typeof location.backpage !== "undefined") {
      setBackPage(location.backpage);
    }
  }, []);

  function handleClickVolver() {
    history.push(backPage);
  }

  return (
    <div className="mt-3 ">
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          marginBottom: 1,
          gap: 6,
        }}
      >
        <BackPage text="Volver" handleClick={handleClickVolver} />
        <PrintButton
          componentToPrint={componentRef}
          text={"Imprimir Ficha de Guardia"}
        />
      </Box>
      <Box>
        <div style={{ display: "none" }}>
          <RenderReportFichaGuardia ref={componentRef} />
        </div>
      </Box>
    </div>
  );
}
