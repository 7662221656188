import React, { useRef, useEffect, useState } from "react";
import {
  Switch,
  FormControlLabel,
  styled,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  DialogContent,
  DialogActions,
  Box,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import CustomAutoComplete from "../CustomAutoComplete";
import CustomTextField from "../CustomTextField";
import FormikCustomTextField from "../FormikCustomTextField";
import crypto from "../Crypto";
import { ModeloGetUrlJson, ModeloPost, ModeloUpdate } from "../ModeloCrud";
import SaveCancelButtons from "../SaveCancelButtons";
import { CapSentence } from "../utils/Utils";
import CoeAutocomplete from "../CoeAutocomplete";
import Draggable from "react-draggable";
import FacebookCircularProgress from "../FacebookCircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 0,
        bgcolor: "primary.main",
        color: "white",
      }}
      {...other}
    >
      {row !== null && (
        <EditIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {row === null && (
        <AddIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = Yup.object({
  plan_cobertura_id: Yup.object().required("Campo obligatorio"),
  codigo: Yup.string().max(20).required("Campo obligatorio"),
  descripcion: Yup.string().max(50).required("Campo obligatorio"),
  lista_precios_id: Yup.object().required("Campo obligatorio"),
  lista_basica_id: Yup.object().required("Campo obligatorio"),
  requiere_bonos: Yup.boolean(),
  requiere_autorizacion: Yup.boolean(),
  requiere_odontograma: Yup.boolean(),
  requiere_planilla_aparte: Yup.boolean(),
  requiere_planilla_propia: Yup.boolean(),
  requiere_planilla_baja: Yup.boolean(),
  observaciones: Yup.string(),
  habilitado: Yup.boolean(),
});

export default function ModalPlanPrepaga({
  row,
  showModal,
  hideModal,
  currentCliente,
  busqueda,
}) {
  const [allPlanes, setAllPlanes] = useState(null);
  const [allListas, setAllListas] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [searchLoading, setSearchyLoading] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState(null); // Control error state
  const [saveError, setSaveError] = useState(null);
  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];

  useEffect(() => {
    buscarPlanes();
  }, []);

  const inputRefs = {
    plan_cobertura_id: useRef(null),
    codigo: useRef(null),
    descripcion: useRef(null),
    lista_precios_id: useRef(null),
    lista_basica_id: useRef(null),
    observaciones: useRef(null),
    exclusivo_centro_id: useRef(null),
  };

  const formik = useFormik({
    initialValues: {
      plan_cobertura_id: null,
      codigo: row?.codigo || "",
      descripcion: row?.descripcion || "",
      lista_precios_id: null,
      lista_basica_id: null,
      requiere_bonos: row !== null && row.requiere_bonos === 1 ? true : false,
      requiere_autorizacion:
        row !== null && row.requiere_autorizacion === 1 ? true : false,
      requiere_odontograma:
        row !== null && row.requiere_odontograma === 1 ? true : false,
      requiere_planilla_aparte:
        row !== null && row.requiere_planilla_aparte ? true : false,
      requiere_planilla_propia:
        row !== null && row.requiere_planilla_propia ? true : false,
      requiere_planilla_baja:
        row !== null && row.requiere_planilla_baja ? true : false,
      habilitado: row !== null && row.habilitado ? true : false,
      observaciones: row?.observaciones || "",
      exclusivo_centro_id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const theme = createTheme({
    components: {
      MuiAlert: {
        styleOverrides: {
          filledError: {
            backgroundColor: "#f44336", // Red color for error
          },
        },
      },
    },
  });

  async function handleSubmit(values) {
    setSaveLoading(true);
    setSaveError(null);
    const Data = {
      prepaga_id: currentCliente.id,
      plan_cobertura_id: values.plan_cobertura_id.id,
      codigo: values.codigo,
      descripcion: values.descripcion,
      lista_precios_id: values.lista_precios_id.id,
      lista_basica_id: values.lista_basica_id.id,
      requiere_bonos: values.requiere_bonos ? 1 : 0,
      requiere_autorizacion: values.requiere_autorizacion ? 1 : 0,
      requiere_odontograma: values.requiere_odontograma ? 1 : 0,
      requiere_planilla_aparte: values.requiere_planilla_aparte ? 1 : 0,
      requiere_planilla_propia: values.requiere_planilla_propia ? 1 : 0,
      requiere_planilla_baja: values.requiere_planilla_baja ? 1 : 0,
      habilitado: values.habilitado ? 1 : 0,
      observaciones: values.observaciones,
      exclusivo_centro_id: values.exclusivo_centro_id?.id,
    };
    let result = null;
    if (!row) {
      result = await ModeloPost("plan-prepaga", null, null, Data);
    } else {
      result = await ModeloUpdate("plan-prepaga", row.id, Data);
    }
    setSaveLoading(false);
    if (result.error === false) {
      busqueda();
      hideModal();
    } else {
      if (Array.isArray(result.errormessage)) {
        setSaveError(result.errormessage[0].error);
      } else if (typeof result.errormessage === "string") {
        setSaveError(result.errormessage);
      } else {
        setSaveError("Error desconocido !");
      }
    }
  }

  const handleClose = () => {
    hideModal();
  };

  async function buscarPlanes() {
    setSearchyLoading(true);
    let result = await ModeloGetUrlJson("planes-cobertura", null, null, null);
    if (result.error === false) {
      setAllPlanes(result.listado);
      if (row) {
        if (row?.exclusivo_centro_id !== null) {
          const selClinica = allCentros.filter(
            (c) => c.id === row.exclusivo_centro_id
          );
          if (selClinica.length > 0) {
            formik.setFieldValue("exclusivo_centro_id", selClinica[0]);
          }
        }
        const selPlan = result.listado.filter(
          (p) => p.id === row.plan_cobertura_id
        );
        if (selPlan.length > 0) {
          formik.setFieldValue("plan_cobertura_id", selPlan[0]);
        }
      }
      result = await ModeloGetUrlJson("listas-precios", null, null, null);
      setSearchyLoading(false);
      if (result.error === false) {
        setAllListas(result.listado);
        if (row) {
          const selListaPre = result.listado.filter(
            (lp) => lp.id === row.lista_precios_id
          );
          if (selListaPre.length > 0) {
            formik.setFieldValue("lista_precios_id", selListaPre[0]);
          }
          const selListaBas = result.listado.filter(
            (lb) => lb.id === row.lista_basica_id
          );
          if (selListaBas.length > 0) {
            formik.setFieldValue("lista_basica_id", selListaBas[0]);
          }
        }
        setShowForm(true);
      }
    } else {
      setSearchyLoading(false);
    }
    return;
  }

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth="md"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              // backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
            row={row}
          >
            {row !== null ? "Editar" : "Nuevo Registro"}
          </BootstrapDialogTitle>

          <form onSubmit={formik.handleSubmit}>
            <>
              <DialogContent className="content" dividers>
                {searchLoading && (
                  <Box
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FacebookCircularProgress />
                  </Box>
                )}
                {!searchLoading && showForm && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomAutoComplete
                        id="plan_cobertura_id"
                        options={allPlanes}
                        value={formik.values.plan_cobertura_id}
                        label="Plan de cobertura"
                        formik={formik}
                        getOptionLabel={(option) => {
                          return option.codigo;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.codigo === value.codigo
                        }
                        renderOption={null}
                        inputRef={(el) =>
                          (inputRefs.plan_cobertura_id.current = el)
                        }
                        nextRef={inputRefs.codigo}
                        setNullOnClear={true} // Do not set to null when cleared
                        handleKeyDown={null}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        label="Código"
                        name="codigo"
                        value={formik.values.codigo}
                        inputRef={inputRefs.codigo}
                        nextInputRef={inputRefs.descripcion}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.codigo && Boolean(formik.errors.codigo)
                        }
                        helperText={
                          formik.touched.codigo && formik.errors.codigo
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        label="Descripción"
                        name="descripcion"
                        value={formik.values.descripcion}
                        inputRef={inputRefs.descripcion}
                        nextInputRef={inputRefs.lista_precios_id}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.descripcion &&
                          Boolean(formik.errors.descripcion)
                        }
                        helperText={
                          formik.touched.descripcion &&
                          formik.errors.descripcion
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomAutoComplete
                        id="lista_precios_id"
                        options={allListas}
                        value={formik.values.lista_precios_id}
                        label="Lista de precios"
                        formik={formik}
                        getOptionLabel={(option) => {
                          return CapSentence(option.codigo);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.codigo === value.codigo
                        }
                        renderOption={null}
                        inputRef={(el) =>
                          (inputRefs.lista_precios_id.current = el)
                        }
                        nextRef={inputRefs.lista_basica_id}
                        setNullOnClear={true} // Do not set to null when cleared
                        handleKeyDown={null}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomAutoComplete
                        id="lista_basica_id"
                        options={allListas}
                        value={formik.values.lista_basica_id}
                        label="Lista básica"
                        formik={formik}
                        getOptionLabel={(option) => {
                          return CapSentence(option.codigo);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.codigo === value.codigo
                        }
                        renderOption={null}
                        inputRef={(el) =>
                          (inputRefs.lista_basica_id.current = el)
                        }
                        nextRef={inputRefs.exclusivo_centro_id}
                        setNullOnClear={true} // Do not set to null when cleared
                        handleKeyDown={null}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requiere_bonos"
                            checked={formik.values.requiere_bonos}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Bonos"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requiere_autorizacion"
                            checked={formik.values.requiere_autorizacion}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Autorización"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requiere_odontograma"
                            checked={formik.values.requiere_odontograma}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Odontograma"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requiere_planilla_aparte"
                            checked={formik.values.requiere_planilla_aparte}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Planilla Aparte"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requiere_planilla_propia"
                            checked={formik.values.requiere_planilla_propia}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Planilla Propia"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="requiere_planilla_baja"
                            checked={formik.values.requiere_planilla_baja}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Planilla Baja"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {/* Observaciones Field */}
                      <CustomTextField
                        label="Observaciones"
                        name="observaciones"
                        multiline
                        rows={4}
                        inputRef={inputRefs.observaciones}
                        value={formik.values.observaciones}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.observaciones &&
                          Boolean(formik.errors.observaciones)
                        }
                        helperText={
                          formik.touched.observaciones &&
                          formik.errors.observaciones
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomAutoComplete
                        id="exclusivo_centro_id"
                        options={allCentros}
                        value={formik.values.exclusivo_centro_id}
                        label="Plan exclusivo de la clínica"
                        formik={formik}
                        getOptionLabel={(option) => option.razonsocial}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderOption={null}
                        inputRef={(el) =>
                          (inputRefs.exclusivo_centro_id.current = el)
                        }
                        nextRef={inputRefs.observaciones}
                        setNullOnClear={true} // Do not set to null when cleared
                        handleKeyDown={null}
                        isClinic={true}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* Habilitado Field */}
                      <FormControlLabel
                        control={
                          <Switch
                            name="habilitado"
                            checked={formik.values.habilitado}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Habilitado"
                      />
                    </Grid>
                  </Grid>
                )}
                {saveError !== null && (
                  <Alert variant="filled" severity="error">
                    {saveError}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions className="actions">
                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                  <SaveCancelButtons
                    handleCancel={hideModal}
                    loading={saveLoading}
                  />
                </Grid>
              </DialogActions>
            </>
          </form>
        </BootstrapDialog>
      </Draggable>
    </div>
  );
}
