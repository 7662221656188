import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import "../../assetss/css/EditarPaciente.css";
import "../../assetss/css/MyTypeahead.css";
import ModalInformation from "../modals/ModalInformation";
import { ModeloPost, ModeloUpdate } from "../ModeloCrud";
import TextField from "@mui/material/TextField";
import crypto from "../Crypto";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import BackPage from "../BackPage";
import SaveCancelButtons from "../SaveCancelButtons";
import "dayjs/locale/en-gb";
import FacebookCircularProgress from "../FacebookCircularProgress";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../assetss/css/SyncfusionMaterial.css";
import Enumerable from "linq";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ProtectedRoute from "../ProtectedRoute";

const InsertUpdateCoe = () => {
  const [allCoes, setAllCoes] = useState(null);
  let [filterCoes, setFilterCoes] = useState(null);
  const [modalCoesOpen, setModalCoesOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const $ = require("jquery");
  const tablafull = crypto.decryptDataStorage("tablafull");
  const [isAlta, setIsAlta] = useState(true);
  const [coeid, setCoeId] = useState(null);
  const [padronCodigo, setPadronCodigo] = useState(null);
  const [padronPlan, setPadronPlan] = useState(null);
  const [allPrepagas, setAllPrepagas] = useState([]);
  const [allPlanes, setAllPlanes] = useState([]);
  const [planesLista, setPlanesLista] = useState([]);
  const [gravadoChecked, setGravadoChecked] = useState(false);
  const [searchCoe, setSearchCoe] = useState("");
  const [automaticoChecked, setAutomaticoChecked] = useState(false);
  const [habilitadoChecked, setHabilitadoChecked] = useState(false);
  let [especialidadesLista, setEspecialidesLista] = useState([]);
  let [consultoriosLista, setConsultoriosLista] = useState([]);
  const diasSemana = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];
  const horarios = ["Mañana", "Tarde"];
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  useEffect(() => {
    setIsAlta(location.isAlta);
    if (typeof location.row !== "undefined") {
      setCoeId(location.row.id);
      fillInitialValues(location.row);
    }
  }, []);

  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];
  let allEspecialidades = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .groupBy((g) => g.especialidad_id)
    .select((e) => ({
      id: e.key(),
      especialidad: e.max((m) => m.especialidad),
    }))
    .toArray();

  const [anchorElBa, setAnchorElBa] = React.useState(null);

  const openBa = Boolean(anchorElBa);
  const idBa = openBa ? "simple-popover" : undefined;

  $(document).ready(function () {
    var allInputs = $(":text:visible"); //(1)collection of all the inputs I want (not all the inputs on my form)
    $(":text").on("keydown", function (e) {
      //(2)When an input field detects a keydown event
      if (e.keyCode == 13) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) + 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
      if (e.keyCode == 27) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) - 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
    });
  });

  const handleChangeAutomatico = (event) => {
    setAutomaticoChecked(event.target.checked);
  };
  const handleChangeHabilitado = (event) => {
    setHabilitadoChecked(event.target.checked);
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };
  const hideWarningModal = () => {
    setModalMessage(null);
    setModalWarningOpen(false);
  };

  function handleKeyPress(event) {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  const validationSchemaAlta = yup.object({
    clinica: yup.object().required("Campo Obligatorio."),
    especialidad: yup.object().required("Campo Obligatorio."),
    dia_semana: yup.string().required("Campo Obligatorio."),
    turno: yup.string().required("Campo Obligatorio."),
    consultorio: yup.object().required("Campo Obligatorio."),
    horario_desde: yup
      .number()
      .required("Campo Obligatorio.")
      .typeError("Campo numérico")
      .min(0, "el horario no puede ser menor a 0")
      .max(2359, "El horario no puede ser mayor a 2359"),
    horario_hasta: yup
      .number()
      .required("Campo Obligatorio.")
      .typeError("Campo numérico")
      .min(
        yup.ref("horario_desde"),
        "El horario hasta no puede ser menor al horario desde."
      )
      .max(2359, "El horario no puede ser mayor a 2359")
      .notOneOf(
        [yup.ref("horario_desde")],
        "Horario desde y hasta no pueden ser iguales."
      ),
    duracion_turno: yup
      .number()
      .required("Campo Obligatorio.")
      .typeError("Campo numérico")
      .min(1, "La duración del turno debe ser mayor a 0.")
      .max(360, "La duración del turno no puede ser mayor a 360"),
    cant_max_entreturnos: yup
      .number()
      .required("Campo Obligatorio.")
      .typeError("Campo numérico")
      .min(0, "El valor debe ser mayor o igoal a 0.")
      .max(100, "El valor debe ser menor o igual a 100"),
    entreturnos_desde: yup
      .number()
      .required("Campo Obligatorio.")
      .typeError("Campo numérico")
      .min(
        yup.ref("horario_desde"),
        "El horario hasta no puede ser menor al horario desde."
      )
      .max(
        yup.ref("horario_hasta"),
        "El horario  no puede ser mayor al horario hasta."
      ),
    entreturnos_hasta: yup
      .number()
      .required("Campo Obligatorio.")
      .typeError("Campo numérico")
      .min(
        yup.ref("entreturnos_desde"),
        "El horario hasta no puede ser menor al horario desde."
      )
      .max(
        yup.ref("horario_hasta"),
        "El horario  no puede ser mayor al horario hasta."
      )
      .notOneOf(
        [yup.ref("entreturnos_desde")],
        "Horario desde y hasta no pueden ser iguales."
      ),
  });

  function fillInitialValues(Coe) {
    formik.resetForm();
    formik.setFieldValue("dia_semana", Coe.dia_semana);
    formik.setFieldValue("turno", Coe.turno === "M" ? "Mañana" : "Tarde");
    formik.setFieldValue("horario_desde", Coe.horario_desde);
    formik.setFieldValue("horario_hasta", Coe.horario_hasta);
    formik.setFieldValue("duracion_turno", Coe.duracion_turno);
    formik.setFieldValue("cant_max_entreturnos", Coe.cant_max_entreturnos);
    formik.setFieldValue("entreturnos_desde", Coe.entreturnos_desde);
    formik.setFieldValue("entreturnos_hasta", Coe.entreturnos_hasta);
    formik.setFieldValue("observaciones", Coe.observaciones);
    const selClinica = allCentros.filter((c) => c.id === Coe.centro_id);
    if (selClinica.length > 0) {
      formik.setFieldValue("clinica", selClinica[0]);
    }
    const especialidades = Enumerable.from(crypto.decryptDataStorage("allcoes"))
      .where((w) => w.centro_id === Coe.centro_id)
      .groupBy((g) => g.especialidad_id)
      .select((e) => ({
        id: e.key(),
        especialidad: e.max((m) => m.especialidad),
      }))
      .toArray();
    const consultorios = Enumerable.from(
      crypto.decryptDataStorage("consultorios")
    )
      .where((w) => w.centro_id === Coe.centro_id)
      .select((e) => ({
        id: e.id,
        consultorio: e.consultorio,
      }))
      .toArray();
    const selEspecialidad = especialidades.filter(
      (c) => c.id === Coe.especialidad_id
    );
    if (selEspecialidad.length > 0) {
      formik.setFieldValue("especialidad", selEspecialidad[0]);
    }
    const selConsultorio = consultorios.filter(
      (c) => c.id === Coe.consultorio_id
    );
    if (selConsultorio.length > 0) {
      formik.setFieldValue("consultorio", selConsultorio[0]);
    }
    setEspecialidesLista(especialidades);
    setConsultoriosLista(consultorios);
    setAutomaticoChecked(Coe.genera_agendas_auto === 1 ? true : false);
    setHabilitadoChecked(Coe.habilitado === 1 ? true : false);
  }

  const formik = useFormik({
    initialValues: {
      clinica: null,
      especialidad: null,
      dia_semana: null,
      turno: null,
      consultorio: null,
      horario_desde: null,
      horario_hasta: null,
      duracion_turno: null,
      cant_max_entreturnos: null,
      entreturnos_desde: null,
      entreturnos_hasta: null,
      observaciones: null,
      habilitado: null,
      genera_agendas_auto: null,
    },
    validationSchema: validationSchemaAlta,
    onSubmit: (values) => {
      let seguir = true;
      if (values.turno.slice(0, 1) === "M") {
        if (Number(values.horario_desde) > 1359) {
          formik.setFieldError(
            "horario_desde",
            "Horario no válido para el turno."
          );
          seguir = false;
        }
        if (Number(values.horario_hasta) > 1459) {
          formik.setFieldError(
            "horario_hasta",
            "Horario no válido para el turno.."
          );
          seguir = false;
        }
      }
      if (values.turno.slice(0, 1) === "T") {
        if (Number(values.horario_desde) < 1400) {
          formik.setFieldError(
            "horario_desde",
            "Horario no válido para el turno."
          );
          seguir = false;
        }
        if (Number(values.horario_hasta) < 1400) {
          formik.setFieldError(
            "horario_hasta",
            "Horario no válido para el turno.."
          );
          seguir = false;
        }
      }
      if (seguir) {
        grabarCoe(values);
      }
    },
  });

  async function grabarCoe(values) {
    setLoading(true);
    const Data = {
      centro_id: values.clinica.id,
      odontologo_id: crypto.decryptDataStorage("currentOdontologo").id,
      especialidad_id: values.especialidad.id,
      dia_semana: values.dia_semana,
      turno: values.turno.slice(0, 1),
      consultorio_id: values.consultorio.id,
      horario_desde: values.horario_desde,
      horario_hasta: values.horario_hasta,
      duracion_turno: values.duracion_turno,
      cant_max_entreturnos: values.cant_max_entreturnos,
      entreturnos_desde: values.entreturnos_desde,
      entreturnos_hasta: values.entreturnos_hasta,
      observaciones: values.observaciones,
      genera_agendas_auto: automaticoChecked === true ? 1 : 0,
      habilitado: habilitadoChecked === true ? 1 : 0,
      contrato_id: 1,
    };
    let result = null;
    if (isAlta) {
      result = await ModeloPost(
        "centro-odontologo-especialidad",
        null,
        null,
        Data
      );
    } else {
      result = await ModeloUpdate(
        "centro-odontologo-especialidad",
        coeid,
        Data
      );
    }
    setLoading(false);

    if (result.error === false) {
      handleClickVolver();
    } else {
      setModalMessage(result.errormessage);
      setModalErrorOpen(true);
    }
  }

  const onClinicasChange = (event, values) => {
    formik.setFieldValue("clinica", values);
    formik.setFieldValue("especialidad", null);
    formik.setFieldValue("consultorio", null);
    setEspecialidesLista([]);
    setConsultoriosLista([]);
    if (values !== null) {
      const especialidades = Enumerable.from(
        crypto.decryptDataStorage("allcoes")
      )
        .where((w) => w.centro_id === values.id)
        .groupBy((g) => g.especialidad_id)
        .select((e) => ({
          id: e.key(),
          especialidad: e.max((m) => m.especialidad),
        }))
        .toArray();
      const consultorios = Enumerable.from(
        crypto.decryptDataStorage("consultorios")
      )
        .where((w) => w.centro_id === values.id)
        .select((e) => ({
          id: e.id,
          consultorio: e.consultorio,
        }))
        .toArray();

      setEspecialidesLista(especialidades);
      setConsultoriosLista(consultorios);
    }
  };

  const onConsultoriosChange = (event, values) => {
    formik.setFieldValue("consultorio", values);
  };

  const onEspecialidadesChange = (event, values) => {
    formik.setFieldValue("especialidad", values);
  };

  const onDiaChange = (event, values) => {
    formik.setFieldValue("dia_semana", values);
  };
  const onTurnoChange = (event, values) => {
    formik.setFieldValue("turno", values);
  };

  const onHorarioDesdeChange = (value) => {
    formik.setFieldValue("horario_desde", value);
  };

  const onHorarioDesdeBlur = (value) => {
    let horario = value;
    if (horario !== null && horario.length > 0) {
      horario = horario.padStart(4, "0");
    }
    formik.setFieldValue("horario_desde", horario);
  };

  const onHorarioHastaChange = (value) => {
    formik.setFieldValue("horario_hasta", value);
  };
  const onHorarioHastaBlur = (value) => {
    let horario = value;
    if (horario !== null && horario.length > 0) {
      horario = horario.padStart(4, "0");
    }
    formik.setFieldValue("horario_hasta", horario);
  };

  const onEntreturnosDesdeChange = (value) => {
    formik.setFieldValue("entreturnos_desde", value);
  };

  const onEntreturnosDesdeBlur = (value) => {
    let horario = value;
    if (horario !== null && horario.length > 0) {
      horario = horario.padStart(4, "0");
    }
    formik.setFieldValue("entreturnos_desde", horario);
  };

  const onEntreturnosHastaChange = (value) => {
    formik.setFieldValue("entreturnos_hasta", value);
  };
  const onEntreturnosHastaBlur = (value) => {
    let horario = value;
    if (horario !== null && horario.length > 0) {
      horario = horario.padStart(4, "0");
    }
    formik.setFieldValue("entreturnos_hasta", horario);
  };

  function handleClickVolver() {
    history.push("/odontologos");
  }

  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"ALTA CCENTROSODONTOLOGOSESPECIALIDADES"}
    >
      <BackPage text="Volver" handleClick={handleClickVolver} />

      <Container maxWidth="xl">
        <Grid
          container
          spacing={1}
          sx={{
            marginBottom: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <Grid xs={12} md={6} sx={{ mt: 1, ml: { xs: 2, md: 4 }, mb: 2 }}>
            <Typography variant="h5">Configuración de Agendas</Typography>
          </Grid>
          <Grid xs={12} md={6} sx={{ mt: 1, ml: { xs: 2, md: 4 }, mb: 2 }}>
            <Typography variant="h6">
              {isAlta && <AddIcon sx={{ mt: -0.5, mr: 1, fontSize: 30 }} />}
              {isAlta === false && (
                <EditIcon sx={{ mt: -0.5, mr: 1, fontSize: 30 }} />
              )}
              {isAlta ? "Nuevo registro" : "Modificar registro"}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {searchLoading && (
        <div className="flex justify-center mt-2">
          <FacebookCircularProgress />
        </div>
      )}
      {!modalCoesOpen && !searchLoading && (
        <form key="odontologo-form" onSubmit={formik.handleSubmit}>
          <Container
            maxWidth="xl"
            sx={{
              borderBottom: 1,
              borderColor: "grey.400",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{
                marginLeft: {
                  xs: 0,
                  md: 2,
                },
                marginBottom: 2,
              }}
            >
              <Grid xs={12} lg={3}>
                <Autocomplete
                  id="clinica"
                  sx={{ width: 280, px: 0, py: 1, ml: { xs: 0, md: 0 } }}
                  options={allCentros}
                  value={formik.values.clinica}
                  autoHighlight
                  size="small"
                  onChange={onClinicasChange}
                  getOptionLabel={(option) => option.razonsocial}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          bgcolor: option.color,
                          marginBottom: 0.5,
                          marginRight: 1,
                          border: 1,
                          borderColor: "black",
                        }}
                      >
                        {option.identificador}
                      </Avatar>
                      {option.razonsocial}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Clínica"
                      error={
                        formik.touched.clinica && Boolean(formik.errors.clinica)
                      }
                      helperText={
                        formik.touched.clinica && formik.errors.clinica
                      }
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        style: { justifyContent: "center" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} lg={3}>
                <Autocomplete
                  id="especialidad"
                  sx={{ width: 280, px: 0, py: 1, ml: { xs: 0, md: 0 } }}
                  options={especialidadesLista}
                  value={formik.values.especialidad}
                  autoHighlight
                  size="small"
                  onChange={onEspecialidadesChange}
                  getOptionLabel={(option) => option.especialidad}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Especialidad"
                      error={
                        formik.touched.especialidad &&
                        Boolean(formik.errors.especialidad)
                      }
                      helperText={
                        formik.touched.especialidad &&
                        formik.errors.especialidad
                      }
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        style: { justifyContent: "center" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} lg={3}>
                <Autocomplete
                  id="dia_semana"
                  sx={{ width: 280, px: 0, py: 1, ml: { xs: 0, md: 0 } }}
                  options={diasSemana}
                  value={formik.values.dia_semana}
                  autoHighlight
                  size="small"
                  onChange={onDiaChange}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dia Semana"
                      error={
                        formik.touched.dia_semana &&
                        Boolean(formik.errors.dia_semana)
                      }
                      helperText={
                        formik.touched.dia_semana && formik.errors.dia_semana
                      }
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        style: { justifyContent: "center" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} lg={3}>
                <Autocomplete
                  id="turno"
                  sx={{ width: 280, px: 0, py: 1, ml: { xs: 0, md: 0 } }}
                  options={horarios}
                  value={formik.values.turno}
                  autoHighlight
                  size="small"
                  onChange={onTurnoChange}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Turno"
                      error={
                        formik.touched.turno && Boolean(formik.errors.turno)
                      }
                      helperText={formik.touched.turno && formik.errors.turno}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        style: { justifyContent: "center" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} lg={3}>
                <Autocomplete
                  id="consultorio"
                  sx={{ width: 280, px: 0, py: 1, ml: { xs: 0, md: 0 } }}
                  options={consultoriosLista}
                  value={formik.values.consultorio}
                  autoHighlight
                  size="small"
                  onChange={onConsultoriosChange}
                  getOptionLabel={(option) => option.consultorio}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Consultorio"
                      error={
                        formik.touched.consultorio &&
                        Boolean(formik.errors.consultorio)
                      }
                      helperText={
                        formik.touched.consultorio && formik.errors.consultorio
                      }
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        style: { justifyContent: "center" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} lg={1}>
                <TextField
                  id="horario_desde"
                  label="Hora Desde"
                  value={formik.values.horario_desde}
                  onChange={(event) => {
                    onHorarioDesdeChange(event.target.value);
                  }}
                  onBlur={(event) => {
                    onHorarioDesdeBlur(event.target.value);
                  }}
                  helperText={
                    formik.touched.horario_desde
                      ? formik.errors.horario_desde
                      : ""
                  }
                  error={
                    formik.touched.horario_desde &&
                    Boolean(formik.errors.horario_desde)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} lg={1}>
                <TextField
                  id="horario_hasta"
                  label="Hora Hasta"
                  value={formik.values.horario_hasta}
                  onChange={(event) => {
                    onHorarioHastaChange(event.target.value);
                  }}
                  onBlur={(event) => {
                    onHorarioHastaBlur(event.target.value);
                  }}
                  helperText={
                    formik.touched.horario_hasta
                      ? formik.errors.horario_hasta
                      : ""
                  }
                  error={
                    formik.touched.horario_hasta &&
                    Boolean(formik.errors.horario_hasta)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft: {
                      xs: 0,
                      lg: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} lg={7}></Grid>
              <Grid xs={12} lg={1}>
                <TextField
                  id="duracion_turno"
                  label="Duración Turno"
                  value={formik.values.duracion_turno}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  helperText={
                    formik.touched.duracion_turno
                      ? formik.errors.duracion_turno
                      : ""
                  }
                  error={
                    formik.touched.duracion_turno &&
                    Boolean(formik.errors.duracion_turno)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} lg={2}>
                <TextField
                  id="cant_max_entreturnos"
                  label="Máximo Entreturnos"
                  value={formik.values.cant_max_entreturnos}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                  helperText={
                    formik.touched.cant_max_entreturnos
                      ? formik.errors.cant_max_entreturnos
                      : ""
                  }
                  error={
                    formik.touched.cant_max_entreturnos &&
                    Boolean(formik.errors.cant_max_entreturnos)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft: {
                      xs: 0,
                      lg: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} lg={1}>
                <TextField
                  id="entreturnos_desde"
                  label="Ent. Desde"
                  value={formik.values.entreturnos_desde}
                  onChange={(event) => {
                    onEntreturnosDesdeChange(event.target.value);
                  }}
                  onBlur={(event) => {
                    onEntreturnosDesdeBlur(event.target.value);
                  }}
                  helperText={
                    formik.touched.entreturnos_desde
                      ? formik.errors.entreturnos_desde
                      : ""
                  }
                  error={
                    formik.touched.entreturnos_desde &&
                    Boolean(formik.errors.entreturnos_desde)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft: {
                      xs: 0,
                      lg: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} lg={1}>
                <TextField
                  id="entreturnos_hasta"
                  label="Ent. Hasta"
                  value={formik.values.entreturnos_hasta}
                  onChange={(event) => {
                    onEntreturnosHastaChange(event.target.value);
                  }}
                  onBlur={(event) => {
                    onEntreturnosHastaBlur(event.target.value);
                  }}
                  helperText={
                    formik.touched.entreturnos_hasta
                      ? formik.errors.entreturnos_hasta
                      : ""
                  }
                  error={
                    formik.touched.entreturnos_hasta &&
                    Boolean(formik.errors.entreturnos_hasta)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft: {
                      xs: 0,
                      lg: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} lg={7.5}></Grid>
              <Grid xs={10} lg={10}>
                <TextField
                  id="observaciones"
                  label="Observaciones"
                  value={formik.values.observaciones}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.observaciones
                      ? formik.errors.observaciones
                      : ""
                  }
                  error={
                    formik.touched.observaciones &&
                    Boolean(formik.errors.observaciones)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                />
              </Grid>
              <Grid xs={12} lg={2}></Grid>
              <Grid
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 2,
                  },
                  marginLeft: {
                    xs: 0,
                    md: 0,
                  },
                }}
                xs={12}
                lg={12}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={automaticoChecked}
                      onChange={handleChangeAutomatico}
                    />
                  }
                  label={
                    <Typography
                      color={automaticoChecked ? "avatar" : "#94a3b8"}
                      fontWeight={automaticoChecked ? "bold" : "regular"}
                    >
                      Agendas Automáticas
                    </Typography>
                  }
                  sx={{
                    marginTop: {
                      xs: 0.5,
                      md: -1,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
              <Grid
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 2,
                  },
                  marginLeft: {
                    xs: 0,
                    md: 0,
                  },
                }}
                xs={12}
                lg={12}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={habilitadoChecked}
                      onChange={handleChangeHabilitado}
                    />
                  }
                  label={
                    <Typography
                      color={habilitadoChecked ? "avatar" : "#94a3b8"}
                      fontWeight={habilitadoChecked ? "bold" : "regular"}
                    >
                      Habilitado
                    </Typography>
                  }
                  sx={{
                    marginTop: {
                      xs: 0.5,
                      md: -1,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Container>
          <SaveCancelButtons
            handleCancel={handleClickVolver}
            loading={loading}
          />
        </form>
      )}
      <BackPage text="Volver" handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen === true && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo={"error"}
      />
      <ModalInformation
        showModal={modalWarningOpen && modalMessage !== null}
        hideModal={hideWarningModal}
        message={modalMessage}
        tipo={"warning"}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo={"info"}
      />
    </ProtectedRoute>
  );
};
export default InsertUpdateCoe;
