/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { CapSentence } from "../utils/Utils";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Draggable from "react-draggable";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      <i class="fa-solid fa-info text-white fa-xl p-4 m-2 "></i>

      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalInformationTurno({
  clinica,
  especialidad,
  especialidad_id,
  odontologo,
  fecha,
  hora,
  direccion,
  tipoturno,
  showModal,
  hideModal,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const Today = new Date();
  const [loading, setLoading] = React.useState(false);
  const [errorSave, setErrorSave] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fotoPaciente, setFotoPaciente] = useState(null);
  const [fotoPacienteId, setFotoPacienteId] = useState(null);
  const [currentPaciente, setCurrentPaciente] = useState(null);
  const [saldoFacturas, setSaldoFacturas] = useState(null);
  const [saldoPresupuestos, setSaldoPresupuestos] = useState(null);
  const [valueTipoAviso, setValueTipoAviso] = React.useState("asignacion");

  const handleChangeTipoAviso = (event) => {
    setValueTipoAviso(event.target.value);
  };

  const handleClose = () => {
    hideModal();
  };

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
          >
            Información del turno
          </BootstrapDialogTitle>
          <DialogContent className="content" dividers>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={valueTipoAviso}
              onChange={handleChangeTipoAviso}
              sx={{
                marginLeft: { xs: 0, md: 4 },
                marginTop: { xs: 6, md: 1 },
              }}
            >
              <FormControlLabel
                value="asignacion"
                control={<Radio />}
                label="Asignación de Cita"
              />
              <FormControlLabel
                value="vemos"
                control={<Radio />}
                label="Vemos que tomo Cita"
              />
            </RadioGroup>
            <div>
              <Divider />
              <br />
              {valueTipoAviso === "asignacion" && (
                <p className="mb-3">
                  📝 *ASIGNACIÓN DE {tipoturno === "T" ? "CITA" : "ENTRETURNO"}{" "}
                  :*
                </p>
              )}
              {valueTipoAviso === "vemos" && (
                <p className="mb-3">
                  👩‍💼➡️ VEMOS QUE TOMO{" "}
                  {tipoturno === "T" ? "CITA" : "ENTRETURNO"}{" "}
                </p>
              )}
              <p>
                *{fecha}, a las {hora} Hs* <br /> Especialidad: {especialidad}
                <br /> Profesional: {CapSentence(odontologo)}
                <br /> Clínica {clinica} {" (" + direccion + ")"}
              </p>
              <p>👉👉 Por favor, presentar: DNI y credencial</p>
              <p>
                📧 Recibirá un correo con la información de la cita para que
                pueda agendarla.
              </p>
              <p>
                ⚠️ Si no puede asistir, cancele la cita para que otro paciente
                pueda aprovecharla.
              </p>
              <p>
                📲 Recuerda seguirnos en Instagram y Facebook para estar al
                tanto de nuestras novedades y consejos!!!
              </p>
              {[2, 3, 6].indexOf(especialidad_id) >= 0 && (
                <p>👉Recuerde traer la ORDEN DE DERIVACIÓN</p>
              )}
            </div>
          </DialogContent>
          <DialogActions className="actions">
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              loadingPosition="end"
              sx={{
                backgroundColor: "primary.main",
                ":hover": {
                  bgcolor: "#475569", // theme.palette.primary.main
                  color: "white",
                },
                textTransform: "capitalize",
              }}
            >
              Salir
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Draggable>
    </div>
  );
}
