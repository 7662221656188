import React, { useState, useRef, useEffect, useContext } from "react";
import { TextField, Grid, Typography, Container, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import { ModeloGetUrlJson, ModeloPost } from "../ModeloCrud";
import RenderCustomTable from "../RenderCustomTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import crypto from "../Crypto";
import CustomAutoComplete from "../CustomAutoComplete";
import { CapSentence, ValorizarTrabajoLaboratorio } from "../utils/Utils";
import ModalInformation from "../modals/ModalInformation";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TopBarContext from "../contexts/TopBarContext";

const RecibirTrabajosLaboratorio = () => {
  const today = dayjs().format("DD/MM/YYYY");
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [allData, setAllData] = useState(null);
  const [nroDocumento, setNroDocumento] = useState("");
  const [nroComprobante, setNroComprobante] = useState("");
  const [precio, setPrecio] = useState(null);
  const [fechaCbte, setFechaComprobante] = useState(dayjs(today, "DD/MM/YYYY"));
  const [selectedRows, setSelectedRows] = useState([]);
  const tablafull = crypto.decryptDataStorage("tablafull");
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const motivos = tablafull
    .filter((obj) => obj.codigo_tabla === "MOTIVOS_ORDENES_TRABAJO")
    .map((obj) => ({ codigo: obj.valor, descripcion: obj.descripcion }));
  const selMotivo = motivos.filter((c) => c.codigo === "OK");
  const [estadoDevolucion, setEstadoDevolucion] = useState(selMotivo[0]);

  const fechaInputRef = useRef(null);
  const motivoInputRef = useRef(null);
  const precioInputRef = useRef(null);
  const documentoInputRef = useRef(null);
  const { changeButton } = useContext(TopBarContext);

  useEffect(() => {
    // Check if there's a component in the state
    changeButton("Reportes");
  }, []);

  function handleSelectedRowsChange(
    newSelectedRows,
    lastSelectedId,
    isSelected
  ) {
    setSelectedRows(newSelectedRows);
    const row = allData.find((row) => row.ot_linea_id === lastSelectedId);
    if (!isSelected) {
      if (row) {
        const precio_manual = precio !== null && precio > 0 ? precio : 0;
        const estado_devolucion =
          estadoDevolucion !== null ? estadoDevolucion.codigo : "OK";
        if (estadoDevolucion !== null) {
          row.estado_devolucion = estadoDevolucion?.codigo;
          row.oti_precio = ValorizarTrabajoLaboratorio(
            row,
            precio_manual,
            estado_devolucion
          );
        } else {
          setModalMessage("Ingrese el estado de devolución.");
          setModalInformationOpen(true);
        }
      }
    } else {
      if (row) {
        row.estado_devolucion = null;
        row.oti_precio = null;
      }
    }
  }

  const handleNroDocumentoChange = (event) => {
    setNroDocumento(event.target.value);
  };

  const columnsItems = [
    { field: "ot_linea_id", headerName: "Id" },
    { field: "paciente_nombres", headerName: "Paciente" },
    { field: "laboratorio_codigo", headerName: "Laboratorio" },
    { field: "ot_fecha_emision", headerName: "Fecha", isDate: true },
    { field: "codigo_nomenclador", headerName: "Código" },
    { field: "numero_pieza_dental", headerName: "Pieza" },
    { field: "motivo", headerName: "Motivo" },
    { field: "descripcion_nomenclador_paso", headerName: "Paso" },
    { field: "estado_devolucion", headerName: "Estado" },
    { field: "oti_precio", headerName: "Precio" },
  ];

  const handleKeyDownFecha = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      if (documentoInputRef.current) {
        documentoInputRef.current.focus();
      }
    }
  };

  const handleKeyDownComprobante = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      if (fechaInputRef.current) {
        fechaInputRef.current.focus();
      }
    }
  };

  const handleKeyDownDocumento = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      handleClickBuscar();
    }
  };

  const handleFocusPrecio = () => {
    if (precioInputRef.current) {
      precioInputRef.current.setSelectionRange(0, 0);
    }
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  async function handleClickBuscar() {
    if (!nroDocumento || nroDocumento.trim() === "") {
      setModalMessage("Ingrese el número de documento.");
      setModalInformationOpen(true);
      return;
    }
    setAllData(null);
    let params = "nro_documento=" + nroDocumento.trim();
    setLoading(true);
    const result = await ModeloGetUrlJson(
      "paciente",
      null,
      "ordenes-trabajo-pendientes",
      params
    );
    setLoading(false);
    if (result.error === false) {
      setAllData(result.listado);
    }
    if (precioInputRef.current) {
      precioInputRef.current.focus();
    }
  }

  async function buscarPrecio(row) {
    const precio_manual = precio !== null && precio > 0 ? precio : 0;
    const nomenclador_paso_id = row.nomenclador_paso_id;
    const laboratorio_id = row.laboratorio_id;
    const motivo_envio = row.motivo;
    const estado_devolucion =
      estadoDevolucion !== null ? estadoDevolucion.codigo : "OK";
    let params =
      "precio_manual=" +
      precio_manual +
      "&nomenclador_paso_id=" +
      nomenclador_paso_id +
      "&laboratorio_id=" +
      laboratorio_id +
      "&motivo_envio=" +
      motivo_envio +
      "&estado_devolucion=" +
      estado_devolucion;
    setLoading(true);
    const result = await ModeloGetUrlJson(
      "orden-trabajo",
      null,
      "valorizar-trabajo-laboratorio",
      params
    );
    setLoading(false);
    let retPrecio = 0;
    if (result.error === false) {
      retPrecio = result.precio;
    }
    return retPrecio;
  }

  async function handleClickGrabar() {
    if (selectedRows?.length && nroComprobante?.trim().length) {
      const itemsToUpdate = allData.filter((item) =>
        selectedRows.includes(item.ot_linea_id)
      );
      //Items
      let stitems = "items=";
      for (var i = 0; i < itemsToUpdate.length; i++) {
        if (stitems.length > 7) {
          stitems += "|";
        }
        const itemId = itemsToUpdate[i].ot_linea_id;
        const fechaDevolucion = fechaCbte.format().slice(0, 10);
        const userIdRecibido = crypto.decryptLocalStorage("user_id");
        const remitoDevolucion =
          "19-0001-" + String(nroComprobante).padStart(8, "0");
        const estadoDevolucion =
          itemsToUpdate[i].oti_precio !== null
            ? itemsToUpdate[i].estado_devolucion
            : "OK";
        const precio =
          itemsToUpdate[i].oti_precio !== null
            ? itemsToUpdate[i].oti_precio
            : 0;
        stitems +=
          itemId +
          ";" +
          fechaDevolucion +
          ";" +
          userIdRecibido +
          ";" +
          remitoDevolucion +
          ";" +
          estadoDevolucion +
          ";" +
          precio;
      }
      setLoadingSave(true);
      const result = await ModeloPost(
        "orden-trabajo",
        null,
        "recibir-trabajos-ot",
        stitems
      );
      if (result?.error === false) {
        handleClickBuscar();
        setModalMessage(result.listado);
        setModalInformationOpen(true);
      } else {
        setModalMessage(result.errormessage);
        setModalErrorOpen(true);
      }
      setLoadingSave(false);
      setSelectedRows([]);
    } else {
      setModalMessage(
        "Seleccione al menos un item e ingrese el número de remito."
      );
      setModalInformationOpen(true);
    }
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 6 }}>
      <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
        Recibir trabajos de laboratorio
      </Typography>
      <div className="flex justify-center mt-4  mr-3 ">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              label="Tipo Comprobante"
              value="19"
              size="small"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              label="Prefijo Comprobante"
              value="0001"
              size="small"
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              label="Nro Comprobante"
              size="small"
              fullWidth
              value={nroComprobante}
              onChange={(e) => {
                setNroComprobante(e.target.value);
              }}
              onKeyDown={handleKeyDownComprobante}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"en-gb"}
            >
              <DateField
                id="fechaCbte"
                label="Fecha  Comprobante"
                inputFormat="DD/MM/YYYY"
                inputRef={(el) => (fechaInputRef.current = el)}
                format="DD/MM/YYYY"
                value={fechaCbte}
                //defaultValue={dayjs(row.alta_usa)}
                onChange={(value) => setFechaComprobante(value)}
                size="small"
                onKeyDown={handleKeyDownFecha}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onKeyDown={handleKeyDownFecha} // Attach the handleKeyDown event here
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={0} sm={0} md={4}></Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              label="Nro Documento"
              size="small"
              value={nroDocumento}
              inputRef={(el) => (documentoInputRef.current = el)}
              onKeyDown={handleKeyDownDocumento}
              onChange={handleNroDocumentoChange}
              fullWidth
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <LoadingButton
              size="medium"
              onClick={handleClickBuscar}
              loading={loading}
              loadingPosition="end"
              endIcon={<SearchIcon />}
              sx={{
                backgroundColor: "primary.main",
                ":hover": {
                  bgcolor: "#475569", // theme.palette.primary.main
                  color: "white",
                },
                height: 40,
              }}
              variant="contained"
            >
              <span></span>
            </LoadingButton>
          </Grid>
          <Grid item xs={0} sm={0} md={8}></Grid>
          <Grid item xs={12} lg={3.5}>
            <CustomAutoComplete
              id="motivo"
              options={motivos}
              value={estadoDevolucion}
              label="Estado"
              formik={null}
              getOptionLabel={(option) => {
                return `${option.codigo}  ${CapSentence(
                  option.descripcion?.toLowerCase()
                )} `;
              }}
              isOptionEqualToValue={(option, value) =>
                option.codigo === value.codigo
              }
              onChange={(e, value) => {
                setEstadoDevolucion(value);
              }}
              renderOption={null}
              inputRef={(el) => (motivoInputRef.current = el)}
              nextRef={precioInputRef}
              setNullOnClear={true} // Do not set to null when cleared
              handleKeyDown={null}
            />
          </Grid>
          <Grid item xs={8} lg={1.5}>
            <TextField
              id="precio"
              // onKeyDown={handleKeyDownImporte}
              inputRef={(el) => (precioInputRef.current = el)}
              label="Importe"
              value={precio}
              onChange={(e) => {
                setPrecio(e.target.value);
              }}
              onFocus={handleFocusPrecio}
              variant="outlined"
              size="small"
              fullWidth
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <LoadingButton
              size="medium"
              onClick={handleClickGrabar}
              loading={loadingSave}
              loadingPosition="end"
              endIcon={<SaveAsIcon />}
              sx={{
                backgroundColor: "primary.main",
                ":hover": {
                  bgcolor: "#475569", // theme.palette.primary.main
                  color: "white",
                },
                height: 40,
              }}
              variant="contained"
            >
              <span>Ingresar Remito</span>
            </LoadingButton>
          </Grid>
        </Grid>
      </div>
      {allData?.length > 0 && (
        <Box sx={{ mt: 3, ml: -3 }}>
          <RenderCustomTable
            data={allData}
            columns={columnsItems}
            selectable={true}
            onSelectedRowsChange={handleSelectedRowsChange}
            parentIdColumn="ot_linea_id"
          />
        </Box>
      )}
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </Container>
  );
};

export default RecibirTrabajosLaboratorio;
