/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import Enumerable from "linq";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import crypto from "../Crypto";
import FacebookCircularProgress from "../FacebookCircularProgress";
import { ModeloUpdate } from "../ModeloCrud";
import ModalInformation from "./ModalInformation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ModaAgendaConsultorio({
  dataAgenda,
  showModal,
  hideModal,
}) {
  let [selectedClinica, setSelectedClinica] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [allData, setAllData] = React.useState(null);
  let [consultoriosLista, setConsultoriosLista] = useState([]);
  const [allIds, setAllIds] = React.useState(null);
  const [modalMessage, setModalMessage] = React.useState(null);
  const [modalInformationOpen, setModalInformationOpen] = React.useState(false);

  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];

  useEffect(() => {
    let allAgendas = Enumerable.from(dataAgenda)
      .groupBy((g) => g.agenda_id)
      .select((a) => ({
        id: a.key(),
      }))
      .toArray();
    let myIds = "";
    for (var i = 0; i < allAgendas.length; i++) {
      const agendaid = allAgendas[i].id;
      myIds += agendaid + ";";
    }
    setAllIds(myIds);
  }, []);

  const handleClose = () => {
    formik.setFieldValue("clinica", null);
    formik.setFieldValue("consultorio", null);
    hideModal();
  };

  const hideInformationModal = () => {
    setModalInformationOpen(false);
    setModalMessage(null);
    handleClose();
  };

  const onClinicasChange = (event, values) => {
    formik.setFieldValue("clinica", values);
    formik.setFieldValue("consultorio", null);
    setConsultoriosLista([]);
    if (values !== null) {
      const consultorios = Enumerable.from(
        crypto.decryptDataStorage("consultorios")
      )
        .where((w) => w.centro_id === values.id)
        .select((e) => ({
          id: e.id,
          consultorio: e.consultorio,
        }))
        .toArray();
      setConsultoriosLista(consultorios);
    }
  };

  const onConsultoriosChange = (event, values) => {
    formik.setFieldValue("consultorio", values);
  };

  const validationSchema = yup.object({
    clinica: yup.object().required("Campo Obligatorio."),
    consultorio: yup.object().required("Campo Obligatorio."),
  });

  const formik = useFormik({
    initialValues: {
      clinica: null,
      consultorio: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      cambiarConsultorio(values);
    },
  });

  async function cambiarConsultorio(values) {
    setLoading(true);
    const Data = {
      consultorio_id: values.consultorio.id,
    };
    let allAgendas = Enumerable.from(dataAgenda)
      .groupBy((g) => g.agenda_id)
      .select((a) => ({
        id: a.key(),
      }))
      .toArray();
    let updatedOk = 0;
    for (var i = 0; i < allAgendas.length; i++) {
      const agendaid = allAgendas[i].id;
      const result = await ModeloUpdate("agenda", agendaid, Data);

      if (result.error === false) {
        updatedOk++;
      }
    }
    setLoading(false);
    if (updatedOk === allAgendas.length) {
      setModalMessage(`Se actualizaron ${updatedOk} agendas.`);
      setModalInformationOpen(true);
    }
  }

  function BootstrapDialogTitle(props) {
    const { children, onClose, row, ...other } = props;

    return (
      <DialogTitle
        sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
        {...other}
      >
        <EditIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
          >
            Cambiar consultorio
          </BootstrapDialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <>
              <DialogContent dividers>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Grid xs={12}>
                    <Autocomplete
                      id="clinica"
                      sx={{ width: 280, px: 0, py: 1, ml: { xs: 0, md: 0 } }}
                      options={allCentros}
                      value={formik.values.clinica}
                      autoHighlight
                      size="small"
                      onChange={onClinicasChange}
                      getOptionLabel={(option) => option.razonsocial}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <Avatar
                            sx={{
                              width: 32,
                              height: 32,
                              bgcolor: option.color,
                              marginBottom: 0.5,
                              marginRight: 1,
                              border: 1,
                              borderColor: "black",
                            }}
                          >
                            {option.identificador}
                          </Avatar>
                          {option.razonsocial}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Clínica"
                          error={
                            formik.touched.clinica &&
                            Boolean(formik.errors.clinica)
                          }
                          helperText={
                            formik.touched.clinica && formik.errors.clinica
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Autocomplete
                      id="consultorio"
                      sx={{ width: 280, px: 0, py: 1, ml: { xs: 0, md: 0 } }}
                      options={consultoriosLista}
                      value={formik.values.consultorio}
                      autoHighlight
                      size="small"
                      onChange={onConsultoriosChange}
                      getOptionLabel={(option) => option.consultorio}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Consultorio"
                          error={
                            formik.touched.consultorio &&
                            Boolean(formik.errors.consultorio)
                          }
                          helperText={
                            formik.touched.consultorio &&
                            formik.errors.consultorio
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {loading && (
                    <Box
                      sx={{
                        marginLeft: 4,
                      }}
                    >
                      <FacebookCircularProgress />
                    </Box>
                  )}
                </Box>
                <ModalInformation
                  showModal={modalInformationOpen && modalMessage !== null}
                  hideModal={hideInformationModal}
                  message={modalMessage}
                  tipo="info"
                />
              </DialogContent>
              <DialogActions className="actions">
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                  loadingPosition="end"
                  sx={{
                    backgroundColor: "#cbd5e1",
                    ":hover": {
                      bgcolor: "#475569", // theme.palette.primary.main
                      color: "white",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.main",
                    ":hover": {
                      bgcolor: "#475569", // theme.palette.primary.main
                      color: "white",
                    },
                    textTransform: "capitalize",
                  }}
                >
                  <span>Grabar</span>
                </LoadingButton>
              </DialogActions>
            </>
          </form>
        </BootstrapDialog>
      </Draggable>
    </div>
  );
}
