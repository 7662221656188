/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DateField } from "@mui/x-date-pickers/DateField";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { ModeloGetUrlJson, ModeloPost, ModeloUpdate } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import crypto from "../Crypto";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import {
  subirFotoUsuario,
  LetterAvatar,
  fotoAvatar,
  FormikValidatePhone,
} from "../utils/Utils";
import ModalInformation from "../modals/ModalInformation";
import ModalConfirmation from "../modals/ModalConfirmation";
import { useFormik } from "formik";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SaveIcon from "@mui/icons-material/Save";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, userInfo, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      <i class="fa-solid fa-info text-white fa-xl p-4 m-2 "></i>

      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalUserInfo({ showModal, hideModal }) {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [groupsInfo, setGroupsInfo] = useState(null);
  const [foto, setFoto] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const handleClose = () => {
    hideModal();
  };
  const userAvatar =
    crypto.decryptLocalStorage("useravatar") !== null
      ? crypto.decryptLocalStorage("useravatar")
      : "";

  const validationSchema = yup.object({
    nombre: yup
      .string()
      .min(4, "El nombre requiere al menos 4 caracteres")
      .max(50, "El nombre no puede superar los 50 caracteres")
      .required("Ingrese el nombre"),
    fecha_nacimiento: yup
      .date()
      .nullable()
      .typeError("Fecha inválida")
      .test("min-age", "Debes tener al menos 16 años", function (value) {
        if (!value) return true; // Permitir valor nulo
        const birthDate = new Date(value);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // Si no ha cumplido años este año, reducir la edad en 1
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        return age >= 16;
      }),
    celular: yup
      .string()
      .nullable()
      .max(20, "El celular no puede superar los 20 caracteres"),
    email_personal: yup
      .string()
      .nullable(true)
      .email("Ingrese un Email válido."),
  });

  const formik = useFormik({
    initialValues: {
      nombre: null,
      celular: null,
      email_personal: null,
      fecha_nacimiento: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      grabarUsuario(values);
    },
  });

  async function grabarUsuario(values) {
    setSaveLoading(true);
    const user_id = crypto.decryptLocalStorage("user_id");
    const Data = {
      nombre: values.nombre,
      celular: values.celular,
      email_personal: values.email_personal,
      fecha_nacimiento:
        values.fecha_nacimiento !== null &&
        typeof values.fecha_nacimiento !== "undefined"
          ? values.fecha_nacimiento.format().slice(0, 10)
          : null,
    };
    let result = await ModeloUpdate("user", user_id, Data);
    setSaveLoading(false);
    if (result.error === false) {
      handleClose();
    } else {
      setModalMessage(result.errormessage);
      setModalInformationOpen(true);
    }
  }

  useEffect(() => {
    buscarInfo();
  }, []);

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const okMethod = () => {
    setModalMessage("El archivo se subió correctamente.");
    setModalSuccessOpen(true);
  };

  const hideSuccessModal = () => {
    setModalMessage(null);
    setModalSuccessOpen(false);
  };

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  const avatarSize = 128;

  async function buscarInfo() {
    const user_id = crypto.decryptLocalStorage("user_id");
    setUserInfo(null);
    setLoading(true);
    let result = await ModeloGetUrlJson(
      "user",
      user_id,
      "user-full-info",
      null
    );
    setLoading(false);
    if (result.error === false) {
      setUserInfo(result.usuario);
      setGroupsInfo(result.grupos);
      setFoto(result.foto);
      setAvatar(result.avatar);
      crypto.encryptDataStorage("useravatar", result.avatar);
      formik.setFieldValue(
        "nombre",
        result.usuario.nombre !== null ? result.usuario.nombre : null
      );
      formik.setFieldValue(
        "fecha_nacimiento",
        result.usuario.fecha_nacimiento !== null &&
          typeof result.usuario.fecha_nacimiento !== "undefined"
          ? dayjs(result.usuario.fecha_nacimiento)
          : null
      );
      formik.setFieldValue(
        "email_personal",
        result.usuario.email_personal !== null
          ? result.usuario.email_personal
          : null
      );
      formik.setFieldValue(
        "celular",
        result.usuario.celular !== null ? result.usuario.celular : null
      );
    }
  }
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };

  async function deleteFoto() {
    const user_id = crypto.decryptLocalStorage("user_id");
    setLoading(true);
    const params = "carpeta=avatar";
    let result = await ModeloPost("user", user_id, "borrar-foto", params);
    setLoading(false);
    if (result.error === false) {
      sessionStorage.removeItem("useravatar");
      buscarInfo();
    } else {
      setModalMessage(result.errormessage);
      setModalErrorOpen(true);
    }
    return;
  }

  function handleBorrarFoto() {
    setModalMessage("Seguro quiere borrar el avatar ?");
    setDisplayConfirmationModal(true);
  }

  function confirmOk() {
    hideConfirmationModal();
    deleteFoto();
  }

  async function handleSubirFoto(carpeta) {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_) => {
      let files = Array.from(input.files);
      const uploadedFile = files[0];
      const filextension = uploadedFile.name.split(".")[1];
      const atypes = ["jpg", "jpeg", "png", "gif", "bmp"];
      let itype = atypes.indexOf(filextension);

      if (itype >= 0) {
        toBase64(uploadedFile)
          .then((res) => {
            let result = subirFotoUsuario(
              res,
              userInfo,
              setLoading,
              filextension,
              carpeta
            ).then((res) => {
              if (res.error === false) {
                //setModalMessage("El archivo se subió correctamente.");
                // setModalSuccessOpen(true);
                buscarInfo();
              } else {
                setModalMessage(res.mensaje);
                setModalErrorOpen(true);
              }
            });
          })
          .catch((err) => {});
      } else {
        setModalMessage(
          "Tipo de archivo no permitido.\nPermitidos:\nImágenes\nPdf\nTxt\nCsv\nDoc\nXls"
        );
        setModalInformationOpen(true);
      }
    };
    input.click();
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
          >
            Información del usuario
          </BootstrapDialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent className="content" dividers>
              {loading && (
                <Box
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FacebookCircularProgress />
                </Box>
              )}
              {userInfo !== null && (
                <Box
                  sx={{
                    marginTop: 0,
                    marginBottom: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 22,
                      fontFamily: "Open Sans",
                      fontWeight: "medium",
                    }}
                  >
                    {`Usuario:  ${userInfo.username}`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 1,
                    }}
                  >
                    {userAvatar?.length
                      ? fotoAvatar(userAvatar, 192)
                      : LetterAvatar(userInfo.nombre, 192)}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      marginTop: 2,
                      justifyContent: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<EditIcon />}
                      sx={{ textTransform: "none" }}
                      onClick={() => handleSubirFoto("avatar")}
                    >
                      Cambiar avatar
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      sx={{ textTransform: "none" }}
                      onClick={() => handleBorrarFoto()}
                    >
                      Quitar avatar
                    </Button>
                  </Box>

                  <Grid container marginTop={1} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        id="nombre"
                        label="Nombre"
                        value={formik.values.nombre}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.nombre ? formik.errors.nombre : ""
                        }
                        error={
                          formik.touched.nombre && Boolean(formik.errors.nombre)
                        }
                        margin="dense"
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ width: "400px" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"en-gb"}
                      >
                        <DateField
                          id="fecha_nacimiento"
                          label="Fecha de nacimiento"
                          value={formik.values.fecha_nacimiento}
                          inputFormat="DD/MM/YYYY"
                          format="DD/MM/YYYY"
                          //defaultValue={dayjs(row.alta_usa)}
                          onChange={(value) =>
                            formik.setFieldValue("fecha_nacimiento", value)
                          }
                          size="small"
                          helperText={formik.errors.fecha_nacimiento}
                          error={Boolean(formik.errors.fecha_nacimiento)}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="email_personal"
                        label="Email personal"
                        value={formik.values.email_personal}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.email_personal
                            ? formik.errors.email_personal
                            : ""
                        }
                        error={
                          formik.touched.email_personal &&
                          Boolean(formik.errors.email_personal)
                        }
                        margin="dense"
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ width: "400px" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="celular"
                        label="Celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.celular ? formik.errors.celular : ""
                        }
                        error={
                          formik.touched.celular &&
                          Boolean(formik.errors.celular)
                        }
                        margin="dense"
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ width: "400px" }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </DialogContent>
            <DialogActions className="actions">
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                loadingPosition="end"
                sx={{
                  backgroundColor: "#cbd5e1",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                loading={saveLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                <span>Grabar</span>
              </LoadingButton>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </Draggable>
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalSuccessOpen && modalMessage !== null}
        hideModal={hideSuccessModal}
        message={modalMessage}
        tipo="success"
      />
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={confirmOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
    </div>
  );
}
