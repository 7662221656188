/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React, { useState } from "react";
import crypto from "../Crypto";
import { ModeloPost } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Draggable from "react-draggable";
import MailIcon from "@mui/icons-material/Mail";
import { CapSentence } from "../utils/Utils";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import ModalInformation from "../modals/ModalInformation";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      <MailIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = yup.object({
  email: yup
    .string()
    .required("Campo Obligatorio.")
    .email("Ingrese un Email válido."),
});

export default function ModalEnviarImagen({ row, showModal, hideModal }) {
  const [modalMessage, setModalMessage] = useState(null);
  const [myloading, setMyLoading] = React.useState(false);
  const currentPatient = crypto.decryptDataStorage("dataPaciente");
  const [pacienteChecked, setPacienteChecked] = useState(false);
  const [facturacionChecked, setFacturacionChecked] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);

  const handleClose = () => {
    hideModal();
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
    setModalInformationOpen(false);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      enviarImagen(values);
    },
  });

  const handleChangePacienteChecked = (event) => {
    if (event.target.checked) {
      formik.setFieldValue("email", currentPatient.email);
    } else {
      formik.setFieldValue("email", "");
    }
    setPacienteChecked(event.target.checked);
    setFacturacionChecked(false);
  };

  const handleChangeFacturacionChecked = (event) => {
    if (event.target.checked) {
      formik.setFieldValue("email", "cmiara@consulmed.com.ar");
    } else {
      formik.setFieldValue("email", "");
    }
    setFacturacionChecked(event.target.checked);
    setPacienteChecked(false);
  };

  async function enviarImagen(values) {
    let params =
      "filename=" +
      row.carpeta +
      row.nombre_imagen +
      "&filetag=" +
      row.descripcion +
      "." +
      row.extension +
      "&paciente=" +
      CapSentence(currentPatient.apellido) +
      ", " +
      CapSentence(currentPatient.nombres) +
      "&fecha=" +
      row.fecha +
      "&email=" +
      values.email;
    setMyLoading(true);
    let result = await ModeloPost(
      "paciente",
      currentPatient.id,
      "enviar-imagen-mail",
      params
    );
    setMyLoading(false);

    if (result.error === false) {
      setModalMessage(result.listado);
      setModalInformationOpen(true);
    } else {
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
  }

  return (
    <React.Fragment>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          open={showModal}
          aria-labelledby="draggable-dialog-title"
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
            row={row}
          >
            Enviar imagen
          </BootstrapDialogTitle>
          <form
            key="enviar-imagen-form"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <DialogContent className="content">
              <DialogContentText>
                <Grid container spacing={2} marginTop={0.5} marginBottom={4}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={pacienteChecked}
                            onChange={handleChangePacienteChecked}
                          />
                        }
                        label={
                          <Typography
                            color={pacienteChecked ? "avatar" : "#94a3b8"}
                            fontWeight={pacienteChecked ? "bold" : "regular"}
                          >
                            Emal al paciente
                          </Typography>
                        }
                        sx={{
                          marginTop: {
                            xs: 0.5,
                            md: 0,
                          },
                          marginLeft: {
                            xs: 0,
                            md: 0,
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={facturacionChecked}
                            onChange={handleChangeFacturacionChecked}
                          />
                        }
                        label={
                          <Typography
                            color={facturacionChecked ? "avatar" : "#94a3b8"}
                            fontWeight={facturacionChecked ? "bold" : "regular"}
                          >
                            Emal a facturación
                          </Typography>
                        }
                        sx={{
                          marginTop: {
                            xs: 0.5,
                            md: 0,
                          },
                          marginLeft: {
                            xs: 0,
                            md: 0,
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      label="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        shrink: formik.values.email?.length > 0 ? true : false,
                      }}
                      helperText={
                        formik.touched.email ? formik.errors.email : ""
                      }
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
              <ModalInformation
                showModal={modalErrorOpen && modalMessage !== null}
                hideModal={hideInformationModal}
                message={modalMessage}
                tipo="error"
              />
              <ModalInformation
                showModal={modalInformationOpen && modalMessage !== null}
                hideModal={hideInformationModal}
                message={modalMessage}
                tipo="info"
              />
            </DialogContent>
            <DialogActions className="actions">
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                loadingPosition="end"
                sx={{
                  backgroundColor: "#cbd5e1",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                loading={myloading}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                <span>Enviar</span>
              </LoadingButton>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </Draggable>
    </React.Fragment>
  );
}
