import React, { forwardRef, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import AvatarAzul from "../assetss/img/logo_azul_reportes.png";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
const today = new Date();
const formattedDate = today.toLocaleDateString("en-GB").replace(/\//g, "/");

export const RenderReportParteGuardia = forwardRef((props, ref) => {
  useEffect(() => {}, []);

  const boxes = [];

  const widthSizes = [200, 150, 250, 50, 310, 120, 50, 50, 100];

  function makeColumns(id) {
    const boxes2 = [];
    const titles = [
      "Credencial",
      "Documento",
      "Nombre",
      "Edad",
      "Tratamiento",
      "Código",
      "Pieza",
      "Caras",
      "Firma",
    ];
    let sub = 0;
    for (let i = 20; i < 29; i++) {
      boxes2.push(
        <Box
          sx={{
            borderRight: 1,
            width: widthSizes[sub],
            height: i === 0 ? "58px" : "58px",
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#1e293b",
          }}
        >
          {id === 0 && (
            <Typography
              variant="caption"
              sx={{ backgroundColor: "blue", mt: -8 }}
            >
              {titles[sub]}
            </Typography>
          )}
        </Box>
      );
      sub++;
    }
    return boxes2;
  }
  // Create an array of 10 elements
  for (let i = 0; i < 11; i++) {
    boxes.push(
      <Box
        key={i} // Add a unique key for each Box component
        sx={{
          display: "flex",
          marginLeft: 4,
          marginRight: 4,
          border: 1,
          height: i === 0 ? "58px" : "58px", // Set the height to 50 pixels (adjust as needed)
          borderRadius: 0,
        }}
      >
        {makeColumns(i)}
      </Box>
    );
  }

  return (
    <div ref={ref}>
      <style
        type="text/css"
        media="print"
        dangerouslySetInnerHTML={{
          __html: `
            @page {
              size: landscape;
              /* Your custom styles for printing */
              margin: 0; /* Example: Remove default margins */
            }
          `,
        }}
      />
      <Box
        display="flex"
        sx={{
          marginTop: 2,
          marginLeft: 4,
          marginRight: 4,
        }}
      >
        <Image
          src={AvatarAzul}
          height="65px"
          width="256px"
          duration={0}
          sx={{
            marginLeft: 0,
            marginTop: 2,
            marginRight: 1,
          }}
        />
        <Box
          display="flex"
          flexDirection={"row"}
          sx={{
            marginLeft: 4,
            marginRight: 4,
            paddingBottom: 0.5,
            fontSize: 20,
            fontFamily: "Roboto",
            fontWeight: "medium",
            marginTop: 1,
            marginLeft: 20,
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ textDecoration: "underline" }}>
                INFORME DIARIO ODONTOLOGICO
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Typography align="right" sx={{ marginLeft: 30 }}>
                  Fecha: {formattedDate}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ mt: 2 }}>Matrícula:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ mt: 2 }}>Odontólogo</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ marginTop: 2 }}>{boxes}</Box>
      <Box sx={{ marginLeft: 6, marginTop: 2 }}>
        <Typography>Firma del Odontólogo:</Typography>
      </Box>
    </div>
  );
});
