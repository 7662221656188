import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import crypto from "./Crypto";
import LogoutIcon from "@mui/icons-material/Logout";
import { Typography, Box } from "@mui/material";

class LogoutMenu extends Component {
  state = {
    navigate: false,
  };
  logout = () => {
    let lastLogged = crypto.decryptLocalStorage("user_email");
    sessionStorage.clear();
    localStorage.clear();
    if (
      typeof lastLogged !== "undefined" &&
      lastLogged !== null &&
      lastLogged.length > 0
    ) {
      crypto.encryptLocalStorage("user_email", lastLogged);
    }
    this.setState({ navigate: true });
  };
  render() {
    const { navigate } = this.state;
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }
    return (
      <Link to="#" onClick={this.logout}>
        <Box sx={{ display: "flex" }}>
          <LogoutIcon sx={{ mr: 1 }} />
          <Typography sx={{ fontFamily: "Roboto" }}> Cerrar sesión</Typography>
        </Box>
      </Link>
    );
  }
}

export default LogoutMenu;
