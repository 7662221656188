/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <div>
      <DialogTitle
        sx={{
          m: 0,
          p: 0,
          bgcolor: "primary.main",
          color: "white",
          textTransform: "capitalize",
        }}
        {...other}
      >
        <AddAPhotoIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    </div>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalShowImage({
  showModal,
  hideModal,
  currentpatient,
  image,
}) {
  const handleClose = () => {
    hideModal();
  };

  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {currentpatient.nombres.toLowerCase() +
            " " +
            currentpatient.apellido.toLowerCase()}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <img src={`${image}`} alt="imagen" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              mt: 2,
              color: "white",
              backgroundColor: "primary.main",
              ":hover": {
                bgcolor: "avatar",
                color: "white",
              },
              textTransform: "capitalize",
            }}
            autoFocus
            onClick={handleClose}
            variant="text"
          >
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}
