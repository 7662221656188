import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

const RenderEncuestas = ({ alldata }) => {
  const history = useHistory();
  const tableRef = useRef();
  const [filterText, setFilterText] = useState("");
  const location = useLocation();
  const [allTratamientos, setAllTratamientos] = useState(alldata);
  let [filterTratamientos, setFilterTratamientos] = useState([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: { xs: 12, sm: 14 },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: 30,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow key={row.id}>
          <StyledTableCell component="th" scope="row">
            {row.fecha_turno}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {row.hora}
          </StyledTableCell>
          <StyledTableCell>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: row.color,
                marginBottom: 0.5,
                border: 1,
                borderColor: "black",
              }}
            >
              {row.identificador}
            </Avatar>
          </StyledTableCell>
          <StyledTableCell>
            <Box sx={{ textTransform: "capitalize" }}>{row.especialidad}</Box>
          </StyledTableCell>
          <StyledTableCell>
            <Box sx={{ textTransform: "capitalize" }}>
              {row.odontologo?.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            <Rating
              name={`rating-${row.turno_id}`}
              value={row.Odontólogo}
              readOnly
            />
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            <Rating
              name={`rating-${row.turno_id}`}
              value={row.Recepción}
              readOnly
            />
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            <Rating
              name={`rating-${row.turno_id}`}
              value={row.Resolución}
              readOnly
            />
          </StyledTableCell>
          <StyledTableCell>
            <Box sx={{ textTransform: "lowercase" }}>{row.Nota}</Box>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Container maxWidth="xl">
        <Typography sx={{ fontSize: 22, mt: 2, mb: 3 }}>Encuestas</Typography>
        <TableContainer component={Paper}>
          <Table
            ref={tableRef}
            sx={{ minWidth: { xs: 512, sm: 800 } }}
            aria-label="customized table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Fecha</StyledTableCell>
                <StyledTableCell>Hora</StyledTableCell>
                <StyledTableCell>Cli</StyledTableCell>
                <StyledTableCell>Especialidad</StyledTableCell>
                <StyledTableCell>Atendió</StyledTableCell>
                <StyledTableCell>Odontólogo</StyledTableCell>
                <StyledTableCell>Recepción</StyledTableCell>
                <StyledTableCell>Resolución</StyledTableCell>
                <StyledTableCell>Notas</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alldata !== null &&
                alldata.length > 0 &&
                alldata
                  .sort((a, b) => (a.orden > b.orden ? 1 : -1))
                  .map((row) => <Row key={row.id} row={row} />)}
              {alldata !== null && alldata.length === 0 && (
                <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    Sin Encuestas.
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default RenderEncuestas;
