import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Enumerable from "linq";
import TodayIcon from "@mui/icons-material/Today";
import { formatNumberES } from "./utils/Utils";
import banktransfer48 from "../assetss/img/banktransfer48.png";
export const RenderReportPagosTable = (props) => {
  const { allData } = props;

  let allFechas = Enumerable.from(allData)
    .groupBy((g) => g.fecha)
    .select((e) => ({
      fecha: e.key(),
      fecha_arg: e.max((m) => m.fecha_arg),
    }))
    .toArray();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#475569",
      color: "#1e293b",
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "4px 10px",
      border: "1px solid #020617",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "0.5px solid #cbd5e1",
    },
  }));

  const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: "#1e293b",
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "0px 5px",
      backgroundColor: "#d1d5db",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(true);

    return (
      <>
        <TableRow>
          <StyledHeaderTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <TodayIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledHeaderTableCell>
          <StyledHeaderTableCell
            width="98%"
            align="left"
            component="th"
            scope="row"
          >
            <Typography
              sx={{
                fontSize: 14,
                textAlign: "left",
                paddingLeft: 4,
                height: "25%",
              }}
            >
              Fecha {row.fecha_arg}
            </Typography>
          </StyledHeaderTableCell>
        </TableRow>
        <Box sx={{ margin: 0 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">
                  <Typography
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Usua.
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Cbte.
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Typography
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Id
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Descripción
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Número
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Cupón
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Typography
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Importe
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allData
                .filter((p) => p.fecha === row.fecha)
                .map((ctaRow) => (
                  <TableRow key={ctaRow.id}>
                    <StyledTableCell align="left" component="th" scope="row">
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontFamily: "Open Sans",
                        }}
                      >
                        {ctaRow.username}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontFamily: "Open Sans",
                        }}
                      >
                        {ctaRow.prefcbte}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontFamily: "Open Sans",
                        }}
                      >
                        {ctaRow.id}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          sx={{
                            fontSize: 10,
                            fontFamily: "Open Sans",
                            marginLeft: 1,
                            marginTop: 1,
                          }}
                        >
                          {ctaRow.descripcion}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontFamily: "Open Sans",
                        }}
                      >
                        {ctaRow.numero_cheque}
                        {ctaRow.descripcion.indexOf("Transferencia") >= 0 && (
                          <img src={banktransfer48} />
                        )}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontFamily: "Open Sans",
                        }}
                      >
                        {ctaRow.numero_cupon}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Typography
                        sx={{
                          fontSize: 10,
                          fontFamily: "Open Sans",
                        }}
                      >
                        {ctaRow.importe}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ margin: 2 }}>
      <Table size="small" aria-label="purchases">
        <TableBody>
          {allFechas.map((row) => (
            <>
              <Box sx={{ marginTop: 0 }}>
                <Row key={row.fecha} row={row} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  border: 1,
                  paddingRight: 2,
                }}
              >
                <Typography variant="subtitle2" gutterBotto>
                  Total del {row.fecha_arg}
                </Typography>
                <Typography variant="subtitle2" gutterBotto>
                  {formatNumberES(
                    allData
                      .filter((p) => p.fecha === row.fecha)
                      .reduce(function (prev, cur) {
                        return prev + parseFloat(cur.importe);
                      }, 0),
                    2
                  )}
                </Typography>
              </Box>
            </>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              border: 1,
              paddingRight: 2,
              bgcolor: "#d1d5db",
            }}
          >
            <Typography variant="subtitle2" gutterBotto>
              Total general
            </Typography>
            <Typography variant="subtitle2" gutterBotto>
              {formatNumberES(
                allData.reduce(function (prev, cur) {
                  return prev + parseFloat(cur.importe);
                }, 0),
                2
              )}
            </Typography>
          </Box>
        </TableBody>
      </Table>
    </Box>
  );
};

export default RenderReportPagosTable;
