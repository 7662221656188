import React, { useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BadgeIcon from "@mui/icons-material/Badge";
import GroupsIcon from "@mui/icons-material/Groups";
import { useViewport, LetterAvatar } from "./utils/Utils.js";
import LogoutDrawer from "./LogoutDrawer";
import crypto from "./Crypto";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuMoreOptions from "./MenuMoreOptions";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import ModalSystemInfo from "./modals/ModalSystemInfo";
import RightDrawer from "./RightDrawer";

export default function CmLeftDrawer({ showDrawer, setShowDrawer }) {
  const [state, setState] = React.useState({
    top: false,
    left: showDrawer,
    bottom: false,
    right: false,
  });
  const { width } = useViewport();
  const username = crypto.decryptLocalStorage("user_name");
  const history = useHistory();
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const openoptions = Boolean(anchorElOptions);
  const [modalSystemInfoOpen, setModalSystemInfoOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClickMoreOptions = (event) => {
    setAnchorElOptions(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorElOptions(null);
  };

  const hideModalSystemInfo = () => {
    setModalSystemInfoOpen(false);
  };

  const handleHome = () => {
    history.push({
      pathname: "/home",
    });
  };

  const handleAgendasSimples = () => {
    history.push({
      pathname: "/agendassimples",
    });
  };

  const handleAgendasMultiples = () => {
    history.push({
      pathname: "/agendasmultiples",
    });
  };
  const handleClientes = () => {
    history.push({
      pathname: "/clientes",
    });
  };
  const handlePacientes = () => {
    history.push({
      pathname: "/pacientes",
    });
  };
  const handleOdontologos = () => {
    history.push({
      pathname: "/odontologos",
    });
  };
  const handleInsertUpdatePaciente = () => {
    history.push({
      pathname: "/insertupdatepaciente",
    });
  };
  const handleOpciones = (event) => {
    //setAnchorElOptions(event.currentTarget);
    setDrawerOpen(true);
  };
  const handleInfo = () => {
    setModalSystemInfoOpen(true);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    setShowDrawer(open);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const toggleRightDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const list = (anchor) => (
    <Box
      sx={{
        width:
          anchor === "top" || anchor === "bottom"
            ? "auto"
            : width < 600
            ? 250
            : 350,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          px: 4,
          py: 4,
          backgroundColor: "primary.main",
          color: "white",
        }}
      >
        {LetterAvatar(username)}
        <Typography variant="caption" sx={{ ml: 2 }}>
          {username}
        </Typography>
      </Box>

      <Divider />
      <List>
        <ListItem key="Home" disablePadding>
          <ListItemButton onClick={handleHome}>
            <ListItemIcon>
              <HomeIcon sx={{ color: "#475569" }} />
            </ListItemIcon>
            <ListItemText primary="Inicio" sx={{ ml: -2, color: "#475569" }} />
          </ListItemButton>
        </ListItem>
        <ListItem key="Pacientes" disablePadding>
          <ListItemButton onClick={handlePacientes}>
            <ListItemIcon>
              <ContactPageIcon sx={{ color: "#475569" }} />
            </ListItemIcon>
            <ListItemText
              primary="Pacientes"
              sx={{ ml: -2, color: "#475569" }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key="Agendas" disablePadding>
          <ListItemButton onClick={handleAgendasSimples}>
            <ListItemIcon>
              <CalendarMonthIcon sx={{ color: "#475569" }} />
            </ListItemIcon>
            <ListItemText primary="Agendas" sx={{ ml: -2, color: "#475569" }} />
          </ListItemButton>
        </ListItem>
        <ListItem key="Ag.Múltiples" disablePadding>
          <ListItemButton onClick={handleAgendasMultiples}>
            <ListItemIcon>
              <CalendarMonthIcon sx={{ color: "#475569" }} />
            </ListItemIcon>
            <ListItemText
              primary="Ag.Múltiples"
              sx={{ ml: -2, color: "#475569" }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key="Padrones" disablePadding>
          <ListItemButton onClick={handleInsertUpdatePaciente}>
            <ListItemIcon>
              <BadgeIcon sx={{ color: "#475569" }} />
            </ListItemIcon>
            <ListItemText
              primary="Padrones"
              sx={{ ml: -2, color: "#475569" }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key="Clientes" disablePadding>
          <ListItemButton onClick={handleClientes}>
            <ListItemIcon>
              <GroupsIcon sx={{ color: "#475569" }} />
            </ListItemIcon>
            <ListItemText
              primary="Clientes"
              sx={{ ml: -2, color: "#475569" }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem key="Odontólogos" disablePadding>
          <ListItemButton onClick={handleOdontologos}>
            <ListItemIcon>
              <CalendarMonthIcon sx={{ color: "#475569" }} />
            </ListItemIcon>
            <ListItemText
              primary="Odontólogos"
              sx={{ ml: -2, color: "#475569" }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{ color: "#475569", mt: 1, mb: 1 }}
          key="Opciones"
          disablePadding
        >
          <IconButton
            onClick={handleOpciones}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openoptions ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openoptions ? "true" : undefined}
          >
            <MoreVertIcon sx={{ ml: -1 }} />
            <Typography
              sx={{ ml: 2.5, fontFamily: "Roboto", color: "#475569" }}
            >
              Más Opciones
            </Typography>
          </IconButton>
          <MenuMoreOptions
            openoptions={openoptions}
            anchoreloptions={anchorElOptions}
            handleclose={handleCloseOptions}
          />
        </ListItem>
      </List>
      <Divider />
      <ListItem sx={{ color: "#475569", mt: 1 }} key="Info" disablePadding>
        <ListItemButton onClick={handleInfo}>
          <ListItemIcon>
            <InfoIcon sx={{ color: "#475569" }} />
          </ListItemIcon>
          <ListItemText primary="Info" sx={{ ml: -2, color: "#475569" }} />
        </ListItemButton>
      </ListItem>
      <List>
        <ListItem key="Logout" disablePadding>
          <ListItemIcon>
            <LogoutDrawer />
          </ListItemIcon>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <SwipeableDrawer
          anchor="left"
          open={showDrawer}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
        {modalSystemInfoOpen && (
          <ModalSystemInfo
            showModal={modalSystemInfoOpen}
            hideModal={hideModalSystemInfo}
          />
        )}
        <RightDrawer open={drawerOpen} setShowDrawer={setDrawerOpen} />
      </React.Fragment>
    </div>
  );
}
