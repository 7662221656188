import React, { useState } from 'react';

export default function CuadranteStd3Std(data) {
    const [testOdo, setTestOdo] = useState(true);

    const getSourceImage = (dienteimage) => {
        let sourceimage = '/iconos_odontograma/' + dienteimage
        return sourceimage
    }


    return (
        <div className="flex ml-1">
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">31</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="31" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">32</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="32" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">33</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="33" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">34</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="34" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">35</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="35" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">36</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="36" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">37</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="37" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">38</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="38" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>

        </div>
    );
}
