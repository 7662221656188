import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import crypto from "./Crypto";
import TextField from "@mui/material/TextField";
import { CapSentence } from "./utils/Utils";
import Enumerable from "linq";
import LoadingButton from "@mui/lab/LoadingButton";
import es from "@fullcalendar/core/locales/es";

export default function CoeAutocomplete({
  selectedClinica,
  selectedEspecialidad,
  selectedOdontologo,
  setSelectedClinica,
  setSelectedEspecialidad,
  setSelectedOdontologo,
  handlebusqueda = null,
  loading = null,
  stClinica = null,
  stEspecialidad = null,
  stOdontologo = null,
  stEspecialidadesLista = null,
  stOdontologosLista = null,
  handleOnChange = null,
  onlyEspecialidad = false,
  flexdirection = "row",
  visibleClinica = true,
  visibleEspecialidad = true,
  visibleOdontologo = true,
  onChangeClinica = null,
  clinicaWidth = "180px",
  especialidadWidth = "250px",
  odontologoWidth = "250px",
}) {
  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];
  let allEspecialidades = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .groupBy((g) => g.especialidad_id)
    .select((e) => ({
      id: e.key(),
      especialidad: e.max((m) => m.especialidad),
    }))
    .toArray();
  let allOdontologos = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .where((w) => w.especialidad_id > 0)
    .groupBy((g) => g.odontologo_id)
    .select((e) => ({
      id: e.key(),
      odontologo: e.max((m) => m.odontologo),
    }))
    .toArray();

  let [especialidadesLista, setEspecialidadesLista] = useState(
    crypto.decryptDataStorage(stEspecialidadesLista) !== null
      ? crypto.decryptDataStorage(stEspecialidadesLista)
      : []
  );
  if (
    onlyEspecialidad &&
    crypto.decryptDataStorage(stEspecialidadesLista) === null &&
    especialidadesLista.length === 0
  ) {
    setEspecialidadesLista(allEspecialidades);
  }
  const [odontologosLista, setOdontologosLista] = useState(
    crypto.decryptDataStorage(stOdontologosLista) !== null
      ? crypto.decryptDataStorage(stOdontologosLista)
      : []
  );

  const onClinicasChange = (event, values) => {
    if (handleOnChange !== null) {
      handleOnChange();
    }
    if (onChangeClinica !== null) {
      onChangeClinica(values);
    }
    if (values !== null) {
      if (stClinica !== null) {
        crypto.encryptDataStorage(stClinica, values);
      }
      setSelectedClinica(values);
      if (visibleEspecialidad) {
        setSelectedEspecialidad(null);
        allEspecialidades = Enumerable.from(
          crypto.decryptDataStorage("allcoes")
        )
          .where((w) => w.centro_id === values.id)
          .groupBy((g) => g.especialidad_id)
          .select((e) => ({
            id: e.key(),
            especialidad: e.max((m) => m.especialidad),
          }))
          .toArray();
        setEspecialidadesLista(allEspecialidades);
      }
      if (visibleOdontologo) {
        allOdontologos = [];
      }
    } else {
      if (stClinica !== null) {
        sessionStorage.removeItem(stClinica);
      }
      if (stEspecialidad !== null) {
        sessionStorage.removeItem(stEspecialidad);
      }
      if (stOdontologo !== null) {
        sessionStorage.removeItem(stOdontologo);
      }
      if (stEspecialidadesLista !== null) {
        sessionStorage.removeItem(stEspecialidadesLista);
      }
      if (stOdontologosLista !== null) {
        sessionStorage.removeItem(stOdontologosLista);
      }
      setSelectedClinica(null);
      if (visibleEspecialidad) {
        setSelectedEspecialidad(null);
        if (onlyEspecialidad === true) {
          allEspecialidades = Enumerable.from(
            crypto.decryptDataStorage("allcoes")
          )
            .groupBy((g) => g.especialidad_id)
            .select((e) => ({
              id: e.key(),
              especialidad: e.max((m) => m.especialidad),
            }))
            .toArray();
        } else {
          allEspecialidades = [];
        }
        if (visibleOdontologo) {
          allOdontologos = [];
        }
      }
      setEspecialidadesLista(allEspecialidades);
    }
    if (visibleOdontologo) {
      setSelectedOdontologo(null);
      setOdontologosLista(allOdontologos);
    }
    if (stEspecialidadesLista !== null) {
      crypto.encryptDataStorage(stEspecialidadesLista, allEspecialidades);
    }
    if (stOdontologosLista !== null) {
      crypto.encryptDataStorage(stOdontologosLista, allOdontologos);
    }
  };

  const onEspecialidadesChange = (event, values) => {
    if (handleOnChange !== null) {
      handleOnChange();
    }
    if (values !== null) {
      if (stEspecialidad !== null) {
        crypto.encryptDataStorage(stEspecialidad, values);
      }
      setSelectedEspecialidad(values);
      setSelectedOdontologo(null);
      allOdontologos = Enumerable.from(crypto.decryptDataStorage("allcoes"))
        .where(
          (w) => selectedClinica === null || w.centro_id === selectedClinica.id
        )
        .where((w) => w.especialidad_id === values.id)
        .groupBy((g) => g.odontologo_id)
        .select((e) => ({
          id: e.key(),
          odontologo: e.max((m) => m.odontologo),
        }))
        .toArray();
    } else {
      if (stEspecialidad !== null) {
        sessionStorage.removeItem(stEspecialidad);
      }
      if (stOdontologo !== null) {
        sessionStorage.removeItem(stOdontologo);
      }
      setSelectedEspecialidad(null);
      setSelectedOdontologo(null);
      allOdontologos = [];
    }
    setOdontologosLista(allOdontologos);
    if (stOdontologosLista !== null) {
      crypto.encryptDataStorage(stOdontologosLista, allOdontologos);
    }
  };

  const onOdontologosChange = (event, values) => {
    if (handleOnChange !== null) {
      handleOnChange();
    }
    if (values !== null) {
      if (stOdontologo !== null) {
        crypto.encryptDataStorage(stOdontologo, values);
      }
      setSelectedOdontologo(values);
    } else {
      if (stOdontologo !== null) {
        sessionStorage.removeItem(stOdontologo);
      }
      setSelectedOdontologo(null);
    }
  };

  return (
    <Box
      sx={{
        marginTop: -1,
        marginBottom: 2,
        textTransform: "capitalize",
        display: "flex",
        flexDirection: { xs: "column", md: flexdirection },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {visibleClinica && (
        <Autocomplete
          id="clinica"
          sx={{ width: clinicaWidth, px: 1, py: 1 }}
          options={allCentros}
          value={selectedClinica}
          autoHighlight
          size="small"
          onChange={onClinicasChange}
          getOptionLabel={(option) => option.razonsocial}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: option.color,
                  marginBottom: 0.5,
                  marginRight: 1,
                  border: 1,
                  borderColor: "black",
                }}
              >
                {option.identificador}
              </Avatar>
              {option.razonsocial}
            </Box>
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Clínica"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                style: { justifyContent: "center" },
              }}
            />
          )}
        />
      )}
      {visibleEspecialidad && (
        <Autocomplete
          id="especialidad"
          sx={{ width: especialidadWidth, px: 1, py: 1 }}
          options={especialidadesLista}
          value={selectedEspecialidad}
          autoHighlight
          size="small"
          onChange={onEspecialidadesChange}
          getOptionLabel={(option) => option.especialidad}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Especialidad"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                style: { justifyContent: "center" },
              }}
            />
          )}
        />
      )}
      {visibleOdontologo && (
        <Autocomplete
          id="odontologo"
          sx={{ width: odontologoWidth, px: 1, py: 1 }}
          options={odontologosLista}
          value={selectedOdontologo}
          autoHighlight
          size="small"
          onChange={onOdontologosChange}
          getOptionLabel={(option) => CapSentence(option.odontologo)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Odontólogo"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                style: { justifyContent: "center" },
              }}
            />
          )}
        />
      )}
      {handlebusqueda !== null && (
        <LoadingButton
          size="medium"
          onClick={handlebusqueda}
          loading={loading}
          loadingPosition="end"
          endIcon={<SearchIcon />}
          sx={{
            backgroundColor: "primary.main",
            ":hover": {
              bgcolor: "#475569", // theme.palette.primary.main
              color: "white",
            },
            height: 40,
            marginTop: { xs: 2, md: 0 },
            marginLeft: { xs: -18, md: flexdirection === "row" ? 0 : -10 },
          }}
          variant="contained"
        >
          <span></span>
        </LoadingButton>
      )}
    </Box>
  );
}
