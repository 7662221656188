import React from "react";
import { Modal, Button } from "react-bootstrap";
//import 'bootstrap/dist/css/bootstrap.min.css';

const ModalTextInformation = ({ showModal, hideModal, message, title }) => {
  let alto = message !== null ? message.length : 0;

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      animation={true}
      backdrop="static"
    >
      <Modal.Header
        className="bg-gradient-to-r from-blue-500 to-blue-300"
        closeButton
      >
        <div className="flex justify-center items-center">
          <i className="fas fa-info-circle fa-3x text-white mr-2" />
          <Modal.Title>
            <div className="capitalize text-white text-2xl">{title}</div>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="h-30 rounded-lg mt-3 mb-3 flex items-center justify-center text-md text-gray-500 ">
          <p>{message !== null && message.toLowerCase()}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="focus:outline-none h-10 text-white text-sm py-1 px-4 rounded-md bg-blue-400 hover:bg-blue-500 hover:shadow-lg"
          onClick={hideModal}
        >
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTextInformation;
