import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link, useHistory } from "react-router-dom"; // Importamos useHistory
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"; // Importa el icono de error

const NotFoundPage = () => {
  const history = useHistory(); // Hook para controlar la navegación

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        marginTop: 12,
        backgroundColor: "#f5f5f5", // Fondo suave
        textAlign: "center",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "296px", // Tamaño específico de 296px
          fontWeight: 100, // Usar Raleway ExtraLight 200
          fontFamily: "'Raleway', sans-serif", // Fuente Google Raleway
          color: "primary.main",
          mb: 2,
        }}
      >
        404
      </Typography>

      {/* Título */}
      <Typography variant="h3" color="textPrimary" gutterBottom>
        Página no encontrada
      </Typography>

      {/* Botones de acción */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: 6, gap: 3 }}
      >
        <Button
          variant="outlined"
          onClick={() => history.goBack()} // Navegar a la página anterior
          sx={{
            textTransform: "capitalize",
            width: 150,
          }}
        >
          Página anterior
        </Button>
        <Button
          variant="outlined"
          component={Link}
          to="/home"
          sx={{
            textTransform: "capitalize",
            width: 150,
          }}
        >
          Ir al inicio
        </Button>
        <Button
          variant="outlined"
          component={Link}
          to="/"
          sx={{
            textTransform: "capitalize",
            width: 150,
          }}
        >
          Cerrar sesión
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
