import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Grouped Material-UI imports
import { Grid, Box, Container, capitalize, Typography } from "@mui/material";

import {
  Person as PersonIcon,
  Healing as HealingIcon,
  EventNote as EventNoteIcon,
  CheckCircle as CheckCircleIcon,
  Apartment as ApartmentIcon,
  PersonAddAlt1 as PersonAddAlt1Icon,
  Cancel as CancelIcon,
} from "@mui/icons-material";

// Internal Components
import BoxButton from "../BoxButton";
import BoxDate from "../BoxDate";
import BoxWeather from "../BoxWeather";
import BoxDolar from "../BoxDolar";
import AnalogClock from "../AnalogClock";
import ModalCarteleria from "../modals/ModaCarteleria";
import GenericCard from "../charts/GenericCard";
import FacebookCircularProgress from "../FacebookCircularProgress";
import TopBarContext from "../contexts/TopBarContext";
import { ModeloGetUrlJson } from "../ModeloCrud";
import CustomAutoComplete from "../CustomAutoComplete";
import crypto from "../Crypto";
import RenderCustomTable from "../RenderCustomTable";
import FaceIcon from "@mui/icons-material/Face";
import Face2Icon from "@mui/icons-material/Face2";
import TreemapTurnosPorPrepaga from "../charts/TreemapTurnosPorPrepaga";
import InteractiveChart from "../charts/InteractiveChart";
import ChartPacientesCitados from "../charts/ChartPacientesCitados";
import * as moment from "moment";

// Assets (CSS, Images)
import "../../assetss/css/App.css";

export default function Home() {
  const history = useHistory();
  const { changeButton } = useContext(TopBarContext);
  const [modalCarteleriaOpen, setModalCarteleriaOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [pacientesCitados, setPacientesCitados] = useState(null);
  const [pacientesCitadosAtendidos, setpacientesCitadosAtendidos] =
    useState(null);
  const [pacientesGuardia, setPacientesGuardia] = useState(null);
  const [pacientesGuardiaAtendidos, setpacientesGuardiaAtendidos] =
    useState(null);
  const [pacientesNuevos, setPacientesNuevos] = useState(null);
  const [pacientesNuevosAtendidos, setpacientesNuevosAtendidos] =
    useState(null);
  const [pacientesEnClinica, setpacientesEnClinica] = useState(null);
  const [pacientesCitadosChart, setPacientesCitadosChart] = useState(null);
  const [agendas, setAgendas] = useState(null);
  const allClinics =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];

  const [clinic, setClinic] = useState(null);

  const columns = [
    { field: "habilitado_turnos", headerName: "Ok" },
    { field: "nombre_corto", headerName: "Especialidad" },
    { field: "sexo", headerName: "Dr(a)" },
    { field: "odontologo", headerName: "Odontólogo", capitalize: true },
    { field: "turno", headerName: "Turno" },
    { field: "consultorio", headerName: "Cons." },
  ];

  const actions = [
    {
      icon: EventNoteIcon,
      handle: (row) => handleGoToAgenda(row),
      sx: { color: "primary.main" },
    },
  ];

  const handleGoToAgenda = (row) => {
    if (
      row.centro_id &&
      row.especialidad_id &&
      row.odontologo_id &&
      row.fecha
    ) {
      crypto.encryptDataStorage("ags_centro_id", row.centro_id);
      crypto.encryptDataStorage("ags_especialidad_id", row.especialidad_id);
      crypto.encryptDataStorage("ags_odontologo_id", row.odontologo_id);
      crypto.encryptDataStorage("ags_fecha", row.fecha);
      history.push("/agendassimples");
    }
  };

  const iconColumns = {
    habilitado_turnos: (props) =>
      props?.value === 1 ? (
        <CheckCircleIcon
          sx={{
            color: "icongreen",
            fontSize: 24,
          }}
        />
      ) : (
        <CancelIcon
          sx={{
            color: "iconred",
            fontSize: 24,
          }}
        />
      ),
    sexo: (props) =>
      props?.value === "M" ? (
        <FaceIcon
          sx={{
            color: "primary.main",
            fontSize: 24,
            ml: -0.2,
          }}
        />
      ) : (
        <Face2Icon
          sx={{
            color: "pink",
            fontSize: 20,
          }}
        />
      ),
  };

  const handleClinicChange = (event, newValue) => {
    setClinic(newValue); // newValue contains the entire selected object
    if (newValue) {
      crypto.encryptDataStorage("selectedClinic", newValue.id);
      searchData(newValue.id);
    } else {
      sessionStorage.removeItem("selectedClinic");
      setShowDashboard(false);
    }
  };
  const initialClinicId =
    crypto.decryptDataStorage("selectedClinic") !== null
      ? crypto.decryptDataStorage("selectedClinic")
      : 1;

  useEffect(() => {
    changeButton("Home");
    const initialClinicAc = allClinics.find(
      (clinic) => clinic.id === initialClinicId
    );
    setClinic(initialClinicAc); // Set the found clinic as the initial value
    searchData(initialClinicId);
  }, []);
  //

  function formatNumber(num) {
    if (Math.abs(num) >= 1e6) {
      return (num / 1e6).toFixed(1) + "M"; // Millones
    } else if (Math.abs(num) >= 1e3) {
      return (num / 1e3).toFixed(1) + "K"; // Miles
    }
    return num.toString(); // Menos de mil
  }

  async function searchData(centroId) {
    try {
      setShowDashboard(false);
      const today = moment().format("YYYY-MM-DD");
      const params = "fecha=" + today + "&centro_id=" + centroId;
      setLoading(true);
      const response = await ModeloGetUrlJson(
        "turno",
        null,
        "dashboard-web-home",
        params
      );
      setLoading(false);
      console.log(response);
      if (response.error === false) {
        setPacientesCitados(response.pacientes_atendidos[0].pacientes_citados);
        setpacientesCitadosAtendidos(
          response.pacientes_atendidos[0].pacientes_citados_atendidos
        );
        setPacientesGuardia(response.pacientes_atendidos[0].pacientes_guardia);
        setpacientesGuardiaAtendidos(
          response.pacientes_atendidos[0].pacientes_guardia_atendidos
        );
        setPacientesNuevos(response.pacientes_primera_vez[0].nuevos_pacientes);
        setpacientesNuevosAtendidos(
          response.pacientes_primera_vez[0].pacientes_atendidos
        );
        setpacientesEnClinica(
          response.pacientes_atendidos[0].pacientes_en_clinica
        );
        setAgendas(
          response.agendas.filter((agenda) => agenda.centro_id === centroId)
        );
        setPacientesCitadosChart(response.chart_data);
        setShowDashboard(true);
      } else {
        alert(response.errormessage);
      }
    } catch (error) {
      alert(`Error: ${error.response?.data?.mensaje || "Ocurrió un error"}`);
    } finally {
      setLoading(false);
    }
  }

  function handleAgendas() {
    if (clinic) {
      history.push({
        pathname: "/consultorios",
        state: { centroId: clinic.id }, // Key-value pair with the correct syntax
      });
    }
  }

  function handleSalaESpera() {
    if (clinic) {
      setModalCarteleriaOpen(true);
    }
  }

  const hideModalCarteleria = () => {
    setModalCarteleriaOpen(false);
  };

  return (
    <Container maxWidth={false}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "center",
          borderBottom: 1,
          borderColor: "#94a3b8",
          paddingBottom: 2,
          marginTop: 4,
        }}
      >
        <BoxDate />
        <BoxWeather />
        <AnalogClock />
        <BoxDolar tipo={"oficial"} />
        <BoxDolar tipo={"blue"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          gap: { xs: 0.5, sm: 1.5 },
          justifyContent: { xs: "center", lg: "center" },
          borderBottom: 1,
          borderColor: "#94a3b8",
          paddingBottom: 1,
          marginTop: -3,
        }}
      >
        <Grid container spacing={3} sx={{ mt: 2, mb: 1 }}>
          <Grid item xs={12} md={6}>
            <CustomAutoComplete
              id="clinicas"
              options={allClinics}
              width="100%" // Esto ajustará el autocomplete al ancho del grid
              value={clinic}
              label="Clíinica"
              formik={null}
              getOptionLabel={(option) => option?.razonsocial || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleClinicChange}
              isClinic={true}
            />
          </Grid>
          <Grid item xs={12} md={1.8}>
            <BoxButton handleClick={handleAgendas} text={"Consultorios"} />
          </Grid>
          <Grid item xs={12} md={1.8}>
            <BoxButton handleClick={handleSalaESpera} text={"Llamador"} />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        {/* Left side: Table */}
        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
          {/* Contenedor para que la tabla ocupe el espacio disponible */}
          <Box sx={{ height: "100%", overflowY: "auto" }}>
            {showDashboard && (
              <>
                <Typography variant="subtitle1">Agendas del dia</Typography>
                <RenderCustomTable
                  data={agendas}
                  columns={columns}
                  iconColumns={iconColumns}
                  paddingLeft={0}
                  paddingRight={0}
                  actions={actions}
                  iconSize={18}
                />
              </>
            )}
          </Box>
        </Grid>

        {/* Right side: Cards */}
        {showDashboard && (
          <>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <GenericCard
                    icon={<PersonIcon />}
                    bgColor="#8BC34A"
                    text1="Pacientes citados"
                    cifra1={pacientesCitados ? pacientesCitados : 0}
                    text2="Pacientes ya atendidos"
                    cifra2={
                      pacientesCitadosAtendidos ? pacientesCitadosAtendidos : 0
                    }
                    textColor="white"
                    sx={{ width: 300, height: 150 }} // Fijar el tamaño de la tarjeta
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <GenericCard
                    icon={<HealingIcon />}
                    bgColor="#36A2EB"
                    text1="Guardias"
                    cifra1={pacientesGuardia ? pacientesGuardia : 0}
                    text2="Guardias ya atendidas"
                    cifra2={
                      pacientesGuardiaAtendidos ? pacientesGuardiaAtendidos : 0
                    }
                    textColor="white"
                    sx={{ width: 300, height: 150 }} // Fijar el tamaño de la tarjeta
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <GenericCard
                    icon={<ApartmentIcon />}
                    bgColor="#FF6384"
                    text1="Pacientes en la clínica"
                    cifra1={pacientesEnClinica ? pacientesEnClinica : 0}
                    textColor="white"
                    sx={{ width: 300, height: 150 }} // Fijar el tamaño de la tarjeta
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <GenericCard
                    icon={<PersonAddAlt1Icon />}
                    bgColor="#B39DDB"
                    text1="Pacientes nuevos"
                    cifra1={pacientesNuevos ? pacientesNuevos : 0}
                    text2="Pacientes nuevos atendidos"
                    cifra2={
                      pacientesNuevosAtendidos ? pacientesNuevosAtendidos : 0
                    }
                    textColor="white"
                    sx={{ width: 300, height: 150 }} // Fijar el tamaño de la tarjeta
                  />
                </Grid>
              </Grid>
              <ChartPacientesCitados data={pacientesCitadosChart} />
            </Grid>
          </>
        )}
      </Grid>

      {loading && (
        <div className="flex justify-center mt-2">
          <FacebookCircularProgress />
        </div>
      )}
      {modalCarteleriaOpen && (
        <ModalCarteleria
          showModal={modalCarteleriaOpen}
          hideModal={hideModalCarteleria}
          centroId={clinic.id}
        />
      )}
    </Container>
  );
}
