import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import * as React from "react";
import Grow from "@mui/material/Grow";
import { useViewport } from "../utils/Utils.js";
import { useHistory } from "react-router-dom";
import crypto from "../Crypto";

const ModalInformation = ({ showModal, hideModal, message, tipo = "info" }) => {
  const { width } = useViewport();
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
    Transition: Grow,
  });
  const history = useHistory();
  const logout = () => {
    let lastLogged = crypto.decryptLocalStorage("user_email");
    sessionStorage.clear();
    localStorage.clear();
    if (
      typeof lastLogged !== "undefined" &&
      lastLogged !== null &&
      lastLogged.length > 0
    ) {
      crypto.encryptLocalStorage("user_email", lastLogged);
    }

    history.push({
      pathname: "/",
    });
  };
  const { vertical, horizontal } = state;
  const handleClose = () => {
    if (
      (crypto.decryptDataStorage("apistatus") !== null &&
        crypto.decryptDataStorage("apistatus") === 401) ||
      message === "Ud. No esta autenticado o su sesión Expiró."
    ) {
      logout();
    }
    hideModal();
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <Stack spacing={10} sx={{ width: "100%" }}>
      <Snackbar
        sx={{
          height: {
            xs: "20%",
            sm: "40%",
          },
        }}
        anchorOrigin={{ vertical, horizontal }}
        open={showModal}
        onClose={handleClose}
        key={vertical + horizontal}
        TransitionComponent={state.Transition}
        autoHideDuration={10000}
      >
        <Alert onClose={handleClose} severity={tipo} sx={{ width: "100%" }}>
          <AlertTitle>
            {tipo === "info"
              ? "Información"
              : tipo === "success"
              ? "Operación exitosa"
              : tipo === "error"
              ? "Error"
              : tipo === "warning"
              ? "Atención"
              : ""}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
export default ModalInformation;
