import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  DialogContent,
  DialogActions,
  Box,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Draggable from "react-draggable";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { ModeloPost, ModeloPostSetPassword } from "../ModeloCrud";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import SaveIcon from "@mui/icons-material/Save";
import crypto from "../Crypto";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, userInfo, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 1.2, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      <KeyIcon sx={{ fontSize: 32, marginRight: 2 }} />

      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalUserChangePassword({ open, handleClose }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Yup validation schema
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(4, "Mínimo 4 caracteres")
      .required("Campo obligatorio"),
    confirmPassword: Yup.string()
      .min(4, "Mínimo 4 caracteres")
      .oneOf([Yup.ref("password"), null], "Los valores no coinciden")
      .required("Campo obligatorio"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      grabarCambios(values);
    },
  });

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  async function grabarCambios(values) {
    setErrorMessage(null);
    setLoading(true);
    const userApiId = crypto.decryptLocalStorage("user_api_id");
    const userId = crypto.decryptLocalStorage("user_id");
    const Data = {
      password: values.password,
    };
    let resultApi = await ModeloPostSetPassword(userApiId, Data);
    if (resultApi.error === false) {
      let result = await ModeloPost("user", userId, "set-password", Data);
      setLoading(false);
      if (resultApi.error === false) {
        handleClose();
      } else {
        setErrorMessage(result.errormessage);
      }
    } else {
      setLoading(false);
      setErrorMessage(resultApi.errormessage);
    }
  }

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
          >
            Cambiar contraseña
          </BootstrapDialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent className="content" dividers>
              <Box
                sx={{
                  marginTop: 0,
                  marginBottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="password"
                  label="Nueva contraseña"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password ? formik.errors.password : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  style={{ width: "400px" }}
                />

                <TextField
                  id="confirmPassword"
                  label="Confirme la contraseña"
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={toggleShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  style={{ width: "400px" }}
                />
              </Box>
              {errorMessage && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  margin={2}
                >
                  <Alert
                    variant="filled"
                    severity="error"
                    sx={{
                      backgroundColor: (theme) => theme.palette.error.main, // Ensure it's solid
                      color: "white", // Ensure text color is correct
                      width: "100%",
                    }}
                  >
                    {errorMessage}
                  </Alert>
                </Box>
              )}
            </DialogContent>
            <DialogActions className="actions">
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                loadingPosition="end"
                sx={{
                  backgroundColor: "#cbd5e1",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                <span>Grabar</span>
              </LoadingButton>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </Draggable>
    </div>
  );
}
