import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Switch,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import ProtectedRoute from "../ProtectedRoute";
import crypto from "../Crypto";
import CustomizedSearch from "../CustomizedSearch.js";
import {
  CapSentenceArray,
  hasPermission,
  CapSentence,
} from "../utils/Utils.js";
import { ModeloGetUrlJson, ModeloPost } from "../ModeloCrud";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FacebookCircularProgress from "../FacebookCircularProgress";
import MultipleResultsTable from "../MultipleResultsTable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ListasPreciosFormNomencladores from "../ListasPreciosFormNomencladores";
import CustomAutoComplete from "../CustomAutoComplete";
import TopBarContext from "../contexts/TopBarContext";
import ModalListaPrecios from "../modals/ModalListaPrecios";
import DeleteModel from "../DeleteModel";
import { useLocation } from "react-router-dom";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ModalReemplazarPreciosLista from "../modals/ModalReemplazarPreciosLista";
import ModalActualizarPreciosLista from "../modals/ModalActualizarPreciosLista";
import ModalCopiarPreciosLista from "../modals/ModalCopiarPreciosLista";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModalInformation from "../modals/ModalInformation";
import ModalConfirmation from "../modals/ModalConfirmation";

const ListasPreciosForm = () => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(null);
  const [currentLista, setCurrentLista] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [searchLista, setSearchLista] = useState("");
  const [modalListasOpen, setModalListasOpen] = useState(false);
  const [allListas, setAllListas] = useState(null);
  const [allNomencladores, setAllNomencladores] = useState([]);
  const [allEmpresas, setAllEmpresas] = useState([]);
  let [filterListas, setFilterListas] = useState(null);
  let [deleteListaId, setDeleteListaId] = useState(null);
  const { changeButton } = useContext(TopBarContext);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const [listaOk, setListaOk] = useState(false);
  const objHeaders = ["Ver", "Código", "Observaciones"];
  const objFields = ["codigo", "observaciones"];
  const objDates = [];
  const [editarLista, setEditarLista] = useState(false);
  const [insertarLista, setInsertarLista] = useState(false);
  const [reemplazarLista, setReemplazarLista] = useState(false);
  const [actualizarLista, setActualizarLista] = useState(false);
  const [copiarLista, setCopiarLista] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [borrarCodigos, setBorrarCodigos] = useState(false);

  const handleEdit = (row) => {
    handleCloseMenu();
    setEditarLista(true);
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const hideBorrarCodigos = () => {
    setModalMessage(null);
    setBorrarCodigos(false);
  };

  const handleReplace = () => {
    handleCloseMenu();
    setReemplazarLista(true);
  };

  const handleUpdateSelected = () => {
    if (selectedRows?.length > 0) {
      handleCloseMenu();
      setActualizarLista(true);
    } else {
      setModalMessage("Seleccione al menos un código.");
      setModalInformationOpen(true);
    }
  };

  const handleCopy = () => {
    if (selectedRows?.length > 0) {
      handleCloseMenu();
      setCopiarLista(true);
    } else {
      setModalMessage("Seleccione al menos un código.");
      setModalInformationOpen(true);
    }
  };

  const handleDeleteCodigos = () => {
    if (selectedRows?.length > 0) {
      setModalMessage("Seguro quiere borrar los códigos seleccionados ?");
      setBorrarCodigos(true);

      handleCloseMenu();
    } else {
      setModalMessage("Seleccione al menos un código.");
      setModalInformationOpen(true);
    }
  };

  const handleCloseModalLista = () => {
    setEditarLista(false);
    setInsertarLista(false);
    setReemplazarLista(false);
    setActualizarLista(false);
    setCopiarLista(false);
  };

  function handleSelectedRowsChange(newSelectedRows) {
    setSelectedRows(newSelectedRows);
  }

  const location = useLocation();
  const listaId = location.state?.listaId; // Recupera el `listaId` del estado

  useEffect(() => {
    if (listaId) {
      // Aquí puedes usar el `listaId` para cargar datos o realizar acciones
      buscarLista(listaId, true);
    }
    changeButton("Reportes");
  }, [listaId]); // Incluyes `changeButton` en las dependencias

  function onChangeSearchLista(e) {
    setSearchLista(e.target.value);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function FiltrarListas(filtro) {
    if (filtro.length > 0) {
      filterListas = allListas.filter(
        (p) => p.codigo.toLowerCase().indexOf(filtro.toLowerCase()) >= 0
      );
      if (filterListas.length <= 0) {
        filterListas = allListas;
      }
    } else {
      filterListas = allListas;
    }
    //crypto.encryptDataStorage("hctratamientos", filterData);
    let tempData = [...filterListas];
    setFilterListas(tempData);
    if (filterListas.length > 0) {
    }
  }

  const renderEmpresasOption = (props, option) => {
    const tableStyles = {
      width: "100%", // Ensure the table takes full width
      borderCollapse: "collapse", // Ensure there's no spacing between cells
    };

    const cellStyles = {
      padding: "8px 12px", // Consistent padding for both header and rows
    };
    if (option.isHeader) {
      return (
        <li style={{ pointerEvents: "none", padding: 0 }}>
          {/* Disable click events for the header */}
          <table style={tableStyles}>
            <thead>
              <tr style={{ backgroundColor: "#dbeafe" }}>
                {/* Set header background color */}
                <th align="left" style={{ ...cellStyles, width: 50 }}>
                  Código
                </th>
                <th align="left" style={{ ...cellStyles, width: 200 }}>
                  Razón
                </th>
                <th align="left" style={{ ...cellStyles, width: 100 }}>
                  Plan
                </th>
              </tr>
            </thead>
          </table>
        </li>
      );
    }

    return (
      <li {...props} style={{ padding: 0 }}>
        <table style={tableStyles}>
          <tbody>
            <tr>
              <td align="left" style={{ ...cellStyles, width: 50 }}>
                {option.codigo}
              </td>
              <td align="left" style={{ ...cellStyles, width: 200 }}>
                {CapSentence(option.razon_social)}
              </td>
              <td align="left" style={{ ...cellStyles, width: 100 }}>
                {option.plan_codigo}
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    );
  };

  function limpiarLista() {
    setShowAlert(null);
    setCurrentLista(null);
    setListaOk(false);
    setModalListasOpen(false);
    setAllListas(null);
    setFilterListas(null);
    setAllNomencladores([]);
    setAllEmpresas([]);
    setLoading(false);
    sessionStorage.removeItem("currentlistaid");
    sessionStorage.removeItem("currentlista");
    setSelectedRows([]);
  }

  function borradoOk() {
    setCurrentLista(null);
    limpiarLista();
    setModalMessage("La lista se borró correctamente.");
    setModalInformationOpen(true);
  }

  function busqueda() {
    buscarLista(searchLista, false);
  }

  async function buscarLista(Busqueda, IsId) {
    limpiarLista();
    if (!IsId && Busqueda.length < 3) {
      setShowAlert("Ingrese al menos 3 caracteres !");
      return;
    }
    let params = "busqueda=" + Busqueda;
    if (IsId) {
      params = params + "&is_id=SI";
    }
    setLoading(true);
    let result = await ModeloGetUrlJson(
      "listas-precios",
      null,
      "busqueda-lista",
      params
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado == null && result.lista !== null) {
        crypto.encryptDataStorage("currentlista", result.lista);
        crypto.encryptDataStorage("currentlistaid", result.lista[0].id);
        setCurrentLista(result.lista[0]);
        setAllNomencladores(result.nomencladores);
        const tempEmpresas = [
          { isHeader: true }, // Add this as the first item to serve as the header
          ...result.empresas,
        ];
        setAllEmpresas(tempEmpresas);
        setListaOk(true);
      } else if (result.listado.length > 1) {
        if (result.listado.length > 500) {
          setModalMessage(
            "Demasiados resultados(" +
              result.listado.length.toString() +
              ") refine la búsqueda."
          );
          setModalInformationOpen(true);
        } else {
          setAllListas(result.listado);
          setFilterListas(result.listado);
          setModalListasOpen(true);
        }
      } else if (result.listado.length === 0) {
        setLoading(false);
        setModalMessage("No se encontraron registros.");
        setModalInformationOpen(true);
      }
    } else {
      setLoading(false);
      setModalMessage(result.errormessage);
      setModalErrorOpen(true);
    }
    return;
  }

  async function handleEliminarCodigos() {
    try {
      hideBorrarCodigos();
      const stringWithSemicolons = selectedRows.join(";");
      const Data = {
        ids: stringWithSemicolons,
      };
      setLoading(true);
      const response = await ModeloPost(
        "listas-precios",
        currentLista.id,
        "borrar-precios-lista",
        Data
      );
      setLoading(false);
      if (response.error === false) {
        buscarLista(currentLista.id, true);
      } else {
        setShowAlert(response.errormessage);
      }
    } catch (error) {
      alert(`Error: ${error.response?.data?.mensaje || "Ocurrió un error"}`);
    } finally {
      setLoading(false);
    }
  }

  function newLista() {
    setInsertarLista(true);
  }

  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"ALTA CLISTASPRECIOSNOMENCLADOR"}
    >
      <div className="w-auto mt-4 mx-4">
        <div className="flex justify-center ml-2 mr-3 ">
          <CustomizedSearch
            value={searchLista}
            onchange={onChangeSearchLista}
            handlebusqueda={busqueda}
            placeholder="Código de lista..."
            width={{ xs: 400, md: 500 }}
            starticon={
              hasPermission(userRoles, "ALTA CLISTASPRECIOSNOMENCLADOR") ? (
                <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
              ) : null
            }
            handlestart={
              hasPermission(userRoles, "ALTA CLISTASPRECIOSNOMENCLADOR")
                ? newLista
                : null
            }
          />
        </div>
        {listaOk === false && (
          <div className="flex justify-center mt-2">
            {loading && <FacebookCircularProgress />}
          </div>
        )}
        {modalListasOpen && filterListas.length > 0 && (
          <MultipleResultsTable
            setShowModal={setModalListasOpen}
            pallData={CapSentenceArray(filterListas, ["codigo"])}
            objFields={objFields}
            objHeaders={objHeaders}
            objDates={objDates}
            selectedMethod={buscarLista}
            highlightText={searchLista}
          />
        )}
        <Box
          sx={{
            padding: 1,
            maxWidth: "100%",
            boxSizing: "border-box",
          }}
        >
          {currentLista && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Listas de precios</Typography>
                <IconButton onClick={handleClick} sx={{ marginLeft: 2 }}>
                  <Avatar sx={{ width: 32, height: 32, bgcolor: "avatar" }}>
                    <MoreVertIcon />
                  </Avatar>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  onClick={handleCloseMenu}
                >
                  <MenuItem onClick={() => handleEdit(currentLista)}>
                    <ListItemIcon>
                      <EditIcon
                        fontSize="medium"
                        sx={{ color: "primary.main", mr: 1 }}
                      />
                    </ListItemIcon>
                    Editar
                  </MenuItem>
                  <MenuItem onClick={() => setDeleteListaId(currentLista.id)}>
                    <ListItemIcon>
                      <DeleteIcon
                        fontSize="medium"
                        sx={{ color: "iconred", mr: 1 }}
                      />
                    </ListItemIcon>
                    Borrar Lista
                  </MenuItem>
                  <MenuItem onClick={() => handleDeleteCodigos()}>
                    <ListItemIcon>
                      <DeleteSweepIcon
                        fontSize="medium"
                        sx={{ color: "iconred", mr: 1 }}
                      />
                    </ListItemIcon>
                    Borrar códigos seleccionados
                  </MenuItem>
                  <MenuItem onClick={() => handleCopy()}>
                    <ListItemIcon>
                      <FileCopyIcon
                        fontSize="medium"
                        sx={{ color: "primary.main", mr: 1 }}
                      />
                    </ListItemIcon>
                    Copiar códigos seleccionados
                  </MenuItem>
                  <MenuItem onClick={() => handleReplace()}>
                    <ListItemIcon>
                      <CurrencyExchangeIcon
                        fontSize="medium"
                        sx={{ color: "primary.main", mr: 1 }}
                      />
                    </ListItemIcon>
                    Reemplazar precios
                  </MenuItem>
                  <MenuItem onClick={() => handleUpdateSelected()}>
                    <ListItemIcon>
                      <AppRegistrationIcon
                        fontSize="medium"
                        sx={{ color: "primary.main", mr: 1 }}
                      />
                    </ListItemIcon>
                    Actualizar códigos seleccionados
                  </MenuItem>
                </Menu>
              </Box>

              {/* Grid Layout */}
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{
                  marginBottom: 2,
                  borderBottom: 1,
                  borderColor: "grey.400",
                  paddingBottom: 2,
                }}
              >
                {/* Primera columna: ID y Código */}
                <Grid item xs={12} sm={2} lg={1}>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>ID: </span>
                    {currentLista.id || ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} lg={2}>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>Lista: </span>
                    {currentLista.codigo || ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography variant="body1">Habilitado:</Typography>
                    <Switch
                      checked={Boolean(currentLista.habilitado)}
                      readOnly
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomAutoComplete
                    id="empresas"
                    options={allEmpresas}
                    width="100%" // Esto ajustará el autocomplete al ancho del grid
                    value={null}
                    label="Empresas que utilizan la lista y se verán afectadas..."
                    formik={null}
                    getOptionLabel={(option) => option?.nombre || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.codigo === value.codigo
                    }
                    renderOption={renderEmpresasOption}
                  />
                </Grid>

                {/* Observaciones */}
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>Observaciones: </span>
                    {currentLista.observaciones || ""}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {(insertarLista || (editarLista && currentLista)) && (
            <ModalListaPrecios
              open={insertarLista || Boolean(editarLista)} // Abre el modal si estás insertando o editando
              handleClose={handleCloseModalLista}
              row={editarLista ? currentLista : null} // Si estás editando, pasas la lista a editar; si estás insertando, `null`
              handleSuccess={(newId) => {
                if (newId) {
                  buscarLista(newId, true); // Pasa el nuevo o actualizado ID y `true`
                }
              }}
            />
          )}
          {reemplazarLista && (
            <ModalReemplazarPreciosLista
              open={reemplazarLista}
              handleClose={handleCloseModalLista}
              listaId={currentLista.id}
              handleSuccess={
                () => buscarLista(currentLista.id, true) // Pasa el nuevo o actualizado ID y `true`
              }
            />
          )}
          {actualizarLista && (
            <ModalActualizarPreciosLista
              open={actualizarLista}
              handleClose={handleCloseModalLista}
              listaId={currentLista.id}
              ids={selectedRows}
              handleSuccess={
                () => buscarLista(currentLista.id, true) // Pasa el nuevo o actualizado ID y `true`
              }
            />
          )}
          {copiarLista && (
            <ModalCopiarPreciosLista
              open={copiarLista}
              handleClose={handleCloseModalLista}
              listaId={currentLista.id}
              ids={selectedRows}
              handleSuccess={(newId) => {
                if (newId) {
                  buscarLista(newId, true); // Pasa el nuevo o actualizado ID y `true`
                }
              }}
            />
          )}
          {allNomencladores && allNomencladores.length > 0 && (
            <ListasPreciosFormNomencladores
              allNomencladores={allNomencladores}
              onSelectedRowsChange={handleSelectedRowsChange}
              listaId={currentLista.id}
            />
          )}
          {deleteListaId !== null && (
            <DeleteModel
              model={"listas-precios"}
              modelid={deleteListaId}
              okmethod={() => borradoOk()}
              nullmethod={setDeleteListaId}
            />
          )}
          <ModalInformation
            showModal={modalInformationOpen && modalMessage !== null}
            hideModal={hideInformationModal}
            message={modalMessage}
            tipo="info"
          />
          <ModalConfirmation
            showModal={borrarCodigos}
            confirmModal={handleEliminarCodigos}
            hideModal={hideBorrarCodigos}
            message={modalMessage}
          />
        </Box>
      </div>
    </ProtectedRoute>
  );
};

export default ListasPreciosForm;
