import React, { useState } from "react";
import Button from "@mui/material/Button";

export default function CuadranteStd1Std(data) {
  const [testOdo, setTestOdo] = useState(true);

  const getSourceImage = (dienteimage) => {
    let sourceimage = "/iconos_odontograma/" + dienteimage;
    return sourceimage;
  };

  return (
    <div className="flex">
      <div className="flex" onClick={() => alert("18")}>
        <img className="mr-1" src={getSourceImage("diente.png")} />
        <p
          className="absolute -mt-6 ml-2"
          onClick={() => alert("click")}
          cursor="pointer"
        >
          18
        </p>
        {data.data.length > 0 &&
          data.data.map((item) => {
            return (
              <img
                className={
                  item.imagen.search("periodoncia") > 0
                    ? "absolute mr-1 -mt-2"
                    : "absolute mr-1"
                }
                src={item.diente === "18" ? getSourceImage(item.imagen) : ""}
              />
            );
          })}
      </div>
      <div className="flex" onClick={() => alert("17")}>
        <img className="mr-1" src={getSourceImage("diente.png")} />
        <p className="absolute -mt-6 ml-2">17</p>
        {data.data.length > 0 &&
          data.data.map((item) => {
            return (
              <img
                className={
                  item.imagen.search("periodoncia") > 0
                    ? "absolute mr-1 -mt-2"
                    : "absolute mr-1"
                }
                src={item.diente === "17" ? getSourceImage(item.imagen) : ""}
              />
            );
          })}
      </div>
      <div className="flex">
        <img className="mr-1" src={getSourceImage("diente.png")} />
        <p className="absolute -mt-6 ml-2">16</p>
        {data.data.length > 0 &&
          data.data.map((item) => {
            return (
              <img
                className={
                  item.imagen.search("periodoncia") > 0
                    ? "absolute mr-1 -mt-2"
                    : "absolute mr-1"
                }
                src={item.diente === "16" ? getSourceImage(item.imagen) : ""}
              />
            );
          })}
      </div>
      <div className="flex">
        <img className="mr-1" src={getSourceImage("diente.png")} />
        <p className="absolute -mt-6 ml-2">15</p>
        {data.data.length > 0 &&
          data.data.map((item) => {
            return (
              <img
                className={
                  item.imagen.search("periodoncia") > 0
                    ? "absolute mr-1 -mt-2"
                    : "absolute mr-1"
                }
                src={item.diente === "15" ? getSourceImage(item.imagen) : ""}
              />
            );
          })}
      </div>
      <div className="flex">
        <img className="mr-1" src={getSourceImage("diente.png")} />
        <p className="absolute -mt-6 ml-2">14</p>
        {data.data.length > 0 &&
          data.data.map((item) => {
            return (
              <img
                className={
                  item.imagen.search("periodoncia") > 0
                    ? "absolute mr-1 -mt-2"
                    : "absolute mr-1"
                }
                src={item.diente === "14" ? getSourceImage(item.imagen) : ""}
              />
            );
          })}
      </div>
      <div className="flex">
        <img className="mr-1" src={getSourceImage("diente.png")} />
        <p className="absolute -mt-6 ml-2">13</p>
        {data.data.length > 0 &&
          data.data.map((item) => {
            return (
              <img
                className={
                  item.imagen.search("periodoncia") > 0
                    ? "absolute mr-1 -mt-2"
                    : "absolute mr-1"
                }
                src={item.diente === "13" ? getSourceImage(item.imagen) : ""}
              />
            );
          })}
      </div>
      <div className="flex">
        <img className="mr-1" src={getSourceImage("diente.png")} />
        <p className="absolute -mt-6 ml-2">12</p>
        {data.data.length > 0 &&
          data.data.map((item) => {
            return (
              <img
                className={
                  item.imagen.search("periodoncia") > 0
                    ? "absolute mr-1 -mt-2"
                    : "absolute mr-1"
                }
                src={item.diente === "12" ? getSourceImage(item.imagen) : ""}
              />
            );
          })}
      </div>
      <div className="flex">
        <img className="mr-1" src={getSourceImage("diente.png")} />
        <p className="absolute -mt-6 ml-2">11</p>
        {data.data.length > 0 &&
          data.data.map((item) => {
            return (
              <img
                className={
                  item.imagen.search("periodoncia") > 0
                    ? "absolute mr-1 -mt-2"
                    : "absolute mr-1"
                }
                src={item.diente === "11" ? getSourceImage(item.imagen) : ""}
              />
            );
          })}
      </div>
    </div>
  );
}
