import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AvatarAzul from "../../assetss/img/logo_azul_reportes.png";
import { CapSentence } from "../utils/Utils";

const Footer = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "0",
        right: "0",
        width: "100%",
        textAlign: "right",
        paddingRight: "2cm",
        fontSize: "12px",
      }}
    >
      Pagina: <span className="pageNumber"></span>
    </div>
  );
};

export const RenderReportLiquidacionLaboratorios = React.forwardRef(
  (props, ref) => {
    const { data, labInfo, dateRange, clinic } = props;

    const groupedData = data.reduce((acc, item) => {
      const { remito } = item;
      if (!acc[remito]) acc[remito] = [];
      acc[remito].push(item);
      return acc;
    }, {});
    const [pageNumber, setPageNumber] = useState(1);

    // Lógica para incrementar el número de página en cada salto
    const handlePageBreak = () => {
      setPageNumber(pageNumber + 1);
    };

    useEffect(() => {}, []);

    let totalValue = 0;

    return (
      <div ref={ref}>
        <style
          type="text/css"
          media="print"
          dangerouslySetInnerHTML={{
            __html: `
      @page {
        size: Legal landscape;
        margin: 10mm; /* Consistent margin on all sides */
       @top-right-corner {
            content: "Page " counter(page);
          }
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          margin: 0;
          padding: 0;
          box-shadow: inset 20mm 0 0 0 white, inset -20mm 0 0 0 white;
        }

        .header {
          position: fixed;
          top: 0;
          width: 100%;
          height: 20mm;
          text-align: center;
          background-color: white;
        }

        .footer {
          position: fixed;
          bottom: 0;
          width: 100%;
          height: 20mm;
          text-align: center;
          padding-bottom: 10mm;
          background-color: white;
        }

     .content {
        margin-top: 5mm; /* Space for header */
        margin-bottom: 10mm; /* Space for footer */
        padding-left: 5mm;
        padding-right: 5mm;
      }

        .page-break {
          page-break-before: always;
           margin-top: 5mm; /* Space for header */
        }

        .keep-together {
          page-break-inside: avoid;
        }

        .MuiAvatar-root {
          border-radius: 50% !important;
        }

        /* Remove borders and scrollbars */
        *,
        *::before,
        *::after {
          box-shadow: none !important;
          overflow: visible !important;
        }
      }
          
    `,
          }}
        />
        {/* Header Section */}
        <div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={2}
          >
            <img src={AvatarAzul} alt="Logo" style={{ height: 50 }} />
            <Box textAlign="center">
              <Typography variant="h4">Reporte de Laboratorio</Typography>
              <Typography variant="h6">
                Clínica:&nbsp;{CapSentence(clinic)}
              </Typography>
              <Typography variant="h6">
                Laboratorio:&nbsp;{CapSentence(labInfo)}
              </Typography>
              <Typography variant="h7">
                Rango de Fechas:&nbsp; {dateRange}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                {new Date().toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginLeft: 3, marginTop: -1, marginRight: 3 }}>
            <Table
              size="small"
              sx={{
                "& .MuiTableCell-root": {
                  padding: "2px",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "150px" }}>Orden-F.Espera</TableCell>
                  <TableCell sx={{ width: "120px" }}>Nro.Doc.</TableCell>
                  <TableCell sx={{ width: "125px" }}>Nombre</TableCell>
                  <TableCell sx={{ width: "125px" }}>Prestador</TableCell>
                  <TableCell sx={{ width: "325px" }}>Nomenclador</TableCell>
                  <TableCell sx={{ width: "325px" }}>Trabajo</TableCell>
                  <TableCell sx={{ width: "20px" }}>Dte</TableCell>
                  <TableCell sx={{ width: "20px" }}>Mot</TableCell>
                  <TableCell sx={{ width: "20px" }}>Aut</TableCell>
                  <TableCell sx={{ width: "20px" }}>Est</TableCell>
                  <TableCell align="right" sx={{ width: "100px" }}>
                    Precio
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Box>
        </div>
        <div sx={{ padding: 10 }}>
          <div className="content">
            {Object.keys(groupedData).map((remito, index) => {
              const groupItems = groupedData[remito];
              const comprobanteTotal = groupItems.reduce(
                (sum, item) => sum + parseFloat(item.precio),
                0
              );
              totalValue += comprobanteTotal;

              return (
                <Box key={index} mb={2}>
                  <Typography
                    variant="h6"
                    sx={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #ddd",
                    }}
                  >
                    {`${remito
                      .replace(/ /g, "\u00A0")
                      .padEnd(
                        30,
                        "\u00A0"
                      )} Subtotal: ${comprobanteTotal.toFixed(2)}`}
                  </Typography>
                  <Table
                    size="small"
                    sx={{ "& .MuiTableCell-root": { padding: "2px" } }}
                  >
                    <TableBody>
                      {groupItems.map((item, itemIndex) => (
                        <TableRow key={itemIndex}>
                          <TableCell sx={{ width: "150px" }}>
                            {`${item.orden_id}-${item.fecha_espera
                              .split("-")
                              .reverse()
                              .join("/")}`}
                          </TableCell>
                          <TableCell sx={{ width: "120px" }}>
                            {item.documento_paciente}
                          </TableCell>
                          <TableCell sx={{ width: "140px" }}>
                            {CapSentence(item.nombre_paciente).substring(0, 15)}
                          </TableCell>
                          <TableCell sx={{ width: "140px" }}>
                            {CapSentence(item.prestador).substring(0, 15)}
                          </TableCell>
                          <TableCell sx={{ width: "300px" }}>
                            {item.item +
                              " " +
                              CapSentence(item.desc_item).substring(0, 20)}
                          </TableCell>
                          <TableCell sx={{ width: "300px" }}>
                            {item.paso +
                              " " +
                              CapSentence(item.desc_paso).substring(0, 20)}
                          </TableCell>
                          <TableCell sx={{ width: "20px" }}>
                            {item.diente}
                          </TableCell>
                          <TableCell sx={{ width: "20px" }}>
                            {item.motivo}
                          </TableCell>
                          <TableCell sx={{ width: "20px" }}>
                            {item.autorizado_por}
                          </TableCell>
                          <TableCell sx={{ width: "20px" }}>
                            {item.estado}
                          </TableCell>
                          <TableCell align="right" sx={{ width: "100px" }}>
                            {parseFloat(item.precio).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {/* Subtotal for each Comprobante */}
                  <Typography
                    variant="body2"
                    align="right"
                    sx={{ marginRight: 2 }}
                  ></Typography>
                </Box>
              );
            })}
            <Typography
              variant="h6"
              align="right"
              sx={{
                backgroundColor: "#f5f5f5",
                border: "1px solid #ddd",
              }}
            >
              Total de Todos los Comprobantes: {totalValue.toFixed(2)}
            </Typography>
          </div>
        </div>
        {/* Footer Section */}
      </div>
    );
  }
);
