import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AvatarAzul from "../../assetss/img/logo_azul_reportes.png";
import { CapSentence } from "../utils/Utils";
import crypto from "../Crypto";

const RenderReportOrdenesSection = ({
  type,
  orden,
  orderNumber,
  customerName,
  documentNumber,
  budgetNumber,
  issueDate,
  waitDate,
  labName,
  items,
}) => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={1}
        pb={2}
        mb={2}
      >
        <Box>
          <img src={AvatarAzul} />
          <Typography
            variant="subtitle2"
            align="left"
            gutterBottom
            sx={{ marginLeft: 2, fontStyle: "italic" }}
          >
            {type}
          </Typography>
        </Box>
        <Box textAlign="center" flex={1}>
          <Typography
            variant="h5"
            component="h1"
            sx={{ margin: 0, fontWeight: "bold" }}
          >
            Orden de Trabajo
          </Typography>
          <Typography variant="h6" component="h3" sx={{ margin: 0 }}>
            Número: {orden.orden_id}
          </Typography>
        </Box>
      </Box>

      {/* Información del encabezado */}
      <Box mb={2}>
        <Typography variant="body1">
          <strong>Laboratorio:</strong>
          <Box component="span" sx={{ ml: 2 }}>
            {CapSentence(orden.laboratorio_codigo.toLowerCase()) +
              " - " +
              CapSentence(orden.laboratorio_razon.toLowerCase())}
          </Box>
        </Typography>
        <Typography variant="body1">
          <strong>Odontólogo:</strong>
          <Box component="span" sx={{ ml: 2 }}>
            {CapSentence(orden.odontologo_or.toLowerCase()) +
              " (" +
              CapSentence(orden.especialidad.toLowerCase()) +
              " - " +
              orden.identificador +
              ") "}
          </Box>
        </Typography>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Typography variant="body1">
            <strong>Paciente:</strong>
            <Box component="span" sx={{ ml: 2 }}>
              {CapSentence(crypto.decryptDataStorage("currentpatientname"))}
            </Box>
          </Typography>
          <Typography variant="body1">
            <Box component="span" sx={{ ml: 26, fontWeight: "bold" }}>
              {crypto.decryptDataStorage("dataPaciente").nro_documento +
                " " +
                crypto.decryptDataStorage("dataPaciente").tipo_documento}
            </Box>
          </Typography>
        </Box>
        <Typography variant="body1">
          <strong>Presupuesto:</strong>
          <Box component="span" sx={{ ml: 2 }}>
            {orden.presupuesto_id}
          </Box>
        </Typography>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Typography variant="body1">
            <strong>Fecha de Emisión:</strong>
            <Box component="span" sx={{ ml: 2 }}>
              {orden.fecha_emision.split("-").reverse().join("/")}
            </Box>
          </Typography>
          <Typography variant="body1">
            <strong>Fecha de Espera:</strong>{" "}
            <Box component="span" sx={{ ml: 2 }}>
              {orden.fecha_espera.split("-").reverse().join("/")}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box
        component="span"
        sx={{
          marginBottom: 4,
          textDecoration: "underline",
          fontWeight: "bold",
        }}
      >
        Material Remitido
      </Box>

      {/* Tabla de ítems */}
      <TableContainer component={Paper} sx={{ mt: 2, mb: 4, mr: 4 }}>
        <Table sx={{ tableLayout: "fixed" }} size="small">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#f5f5f5",
                border: "1px solid #ddd",
              }}
            >
              <TableCell sx={{ width: "9%", fontWeight: "bold" }}>
                Item
              </TableCell>
              <TableCell sx={{ width: "11%", fontWeight: "bold" }}>
                Código
              </TableCell>
              <TableCell sx={{ width: "8%", fontWeight: "bold" }}>
                Pieza
              </TableCell>
              <TableCell sx={{ width: "11%", fontWeight: "bold" }}>
                Paso
              </TableCell>
              <TableCell sx={{ width: "41%", fontWeight: "bold" }}>
                Descripción
              </TableCell>
              <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                Motivo
              </TableCell>
              <TableCell sx={{ width: "10%", fontWeight: "bold" }}>
                Cubeta
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.codigo_nomenclador}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {item.numero_pieza_dental}
                </TableCell>
                <TableCell>{item.codigo_nomenclador_paso}</TableCell>
                <TableCell>
                  {CapSentence(item.descripcion_nomenclador_paso.toLowerCase())}
                </TableCell>
                <TableCell>{item.motivo}</TableCell>
                <TableCell>{item.tipo_cubeta}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Notas */}
      <Box border={1} p={2} height="100px">
        <Typography variant="body1">
          <strong>Indicaciones:</strong>
        </Typography>
        {orden.observaciones}
      </Box>
    </Box>
  );
};

export default RenderReportOrdenesSection;
