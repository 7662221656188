import React, { useState, useEffect } from "react";
import { Alert, Box } from "@mui/material";
import { hasPermission } from "./utils/Utils";

// Función que verifica los permisos del usuario

// Componente reutilizable ProtectedRoute
const ProtectedRoute = ({
  userRoles,
  requiredPermission,
  onPermissionGranted,
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const checkPermissions = () => {
      if (hasPermission(userRoles, requiredPermission)) {
        setHasAccess(true);

        // Si se proporcionó una función onPermissionGranted, la ejecuta
        if (onPermissionGranted) {
          onPermissionGranted();
        }
      } else {
        setHasAccess(false);
      }
      setLoading(false);
    };

    checkPermissions();
  }, [userRoles, requiredPermission, onPermissionGranted]);

  if (loading) {
    return null; // Mientras verifica, no renderiza nada
  }

  if (hasAccess) {
    return children; // Si tiene acceso, renderiza la página
  }

  // Si no tiene acceso, muestra el Alert dentro de un Box
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={8}>
      <Alert variant="filled" severity="error">
        No tienes permiso para visualizar esta página.
      </Alert>
    </Box>
  );
};

export default ProtectedRoute;
