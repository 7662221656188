import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { CapSentence, convertDateFormat } from "./utils/Utils";
import AvatarAzul from "../assetss/img/logo_azul_reportes.png";
import Image from "mui-image";

export const RenderReportParteDiarioTurnos = React.forwardRef((props, ref) => {
  const { allData } = props;
  const cellStyle = {
    border: "1px solid black",
    height: "10px",
    padding: "8px",
    fontFamily: "Open Sans",
  };
  const headerStyle = {
    border: "1px solid black",
    height: "10px",
    padding: "8px",
    fontWeight: "bold",
    fontFamily: "Open Sans",
  };
  const rowsPerPage = 10;

  const renderTableHeader = () => (
    <TableRow>
      <TableCell style={{ ...headerStyle, width: "5%" }}>Hora</TableCell>
      <TableCell style={{ ...headerStyle, width: "10%" }}>Empresa</TableCell>
      <TableCell style={{ ...headerStyle, width: "5%" }}>Documento</TableCell>
      <TableCell style={{ ...headerStyle, width: "10%" }}>Nombre</TableCell>
      <TableCell style={{ ...headerStyle, width: "10%" }}>T.Turno</TableCell>
      <TableCell style={{ ...headerStyle, width: "10%" }}>Derivado</TableCell>
      <TableCell style={{ ...headerStyle, width: "5%" }}>Edad</TableCell>
      <TableCell style={{ ...headerStyle, width: "5%" }}>Notas</TableCell>
      <TableCell style={{ ...headerStyle, width: "20%" }}>
        Tratamiento
      </TableCell>
      <TableCell style={{ ...headerStyle, width: "5%" }}>Código</TableCell>
      <TableCell style={{ ...headerStyle, width: "5%" }}>Pieza</TableCell>
      <TableCell style={{ ...headerStyle, width: "10%" }}>Firma</TableCell>
    </TableRow>
  );

  return (
    <div ref={ref} style={{ width: "100%", padding: "20px" }}>
      <style
        type="text/css"
        media="print"
        dangerouslySetInnerHTML={{
          __html: `
                  @page {
                    size: Legal landscape;
                    margin: 20mm;
                  }
                  @media print {
                    body {
                      -webkit-print-color-adjust: exact;
                    }
                    .page-break {
                      page-break-before: always;
                       margin-top: 25mm; 
                    }
                    .content {
                      margin-top: 2cm; 
                    }
                  }
                `,
        }}
      />
      <div className="header">
        <Box
          display="flex"
          sx={{
            marginTop: 2,
            px: 2,
            border: 1,
            gap: 10,
            alignItems: "center",
            width: "97%",
          }}
        >
          <Image
            src={AvatarAzul}
            height="65px"
            width="256px"
            duration={0}
            sx={{
              marginLeft: 4,
            }}
          />
          <Typography variant="h5">{CapSentence(allData[0].centro)}</Typography>
          <Typography variant="h5" sx={{ marginLeft: 2 }}>
            {CapSentence(allData[0].especialidad)}
          </Typography>
          <Typography variant="h5" sx={{ marginLeft: 2 }}>
            {CapSentence(allData[0].odontologo)}
          </Typography>
          <Typography variant="h5" sx={{ marginLeft: 2 }}>
            {convertDateFormat(allData[0].fecha_agenda)}
          </Typography>
        </Box>
      </div>
      <div className="content">
        <TableContainer component={Paper}>
          <Table>
            {renderTableHeader()}
            <TableBody>
              {allData
                .filter((p) => p.nro_documento !== null)
                .map((row, index) => {
                  const isNewPage = index % rowsPerPage === 0;
                  return (
                    <React.Fragment key={index}>
                      {isNewPage && index !== 0 && (
                        <div className="page-break"></div>
                      )}
                      {isNewPage && index !== 0 && renderTableHeader()}
                      <TableRow>
                        <TableCell style={cellStyle}>
                          {row.hora_desde}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {row.codigo +
                            " " +
                            CapSentence(row.denominacion_comercial)}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {row.nro_documento}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {CapSentence(row.nombre)}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {row.motivo +
                            " " +
                            (row.piezas !== null
                              ? row.piezas.toString().replaceAll("-", " ")
                              : "")}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {CapSentence(row.derivado_por)}
                        </TableCell>
                        <TableCell style={cellStyle}>{row.edad}</TableCell>
                        <TableCell style={cellStyle}>
                          {row.vip === 1 ? "VIP " : " "}
                          {row.presento_queja === "S" ? "QUEJA " : " "}
                          {row.hiv === "S" ? "HIV" : " "}
                        </TableCell>
                        <TableCell style={cellStyle}></TableCell>
                        <TableCell style={cellStyle}></TableCell>
                        <TableCell style={cellStyle}></TableCell>
                        <TableCell style={cellStyle}></TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="footer">
        <Grid container sx={{ marginTop: 4 }}>
          <Grid item xs={4}>
            <Typography
              variant="caption"
              align="left"
              sx={{ fontWeight: "bold", marginTop: 1 }}
            >
              Firma del Odontólogo
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="caption"
              align="left"
              sx={{ fontWeight: "bold", marginTop: 1 }}
            >
              Aclaración
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="caption"
              align="left"
              sx={{ fontWeight: "bold", marginTop: 1 }}
            >
              Matrícula
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
});
