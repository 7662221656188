import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import RenderCustomTable from "../RenderCustomTable";

const PacienteCitasAnteriores = ({ alldata }) => {
  const columns = [
    { field: "identificador", headerName: "Clínica", isClinic: true },
    { field: "especialidad_corto", headerName: "Especialidad" },
    { field: "odontologo", headerName: "Odontólogo", capitalize: true },
    { field: "cantidad", headerName: "Cantidad" },
    { field: "ultima_fecha_arg", headerName: "U.Fecha" },
  ];

  return (
    <Card
      sx={{
        backgroundColor: "bgcards",
        ml: { xs: -2, md: -3 },
        mr: { xs: -2, md: -2 },
      }}
    >
      <CardHeader
        avatar={<RestoreRoundedIcon sx={{ color: "avatar", fontSize: 48 }} />}
        title={<Typography sx={{ fontSize: 22 }}>Citas Anteriores</Typography>}
        subheader=""
      />
      <CardContent sx={{ mt: -2 }}>
        <RenderCustomTable
          data={alldata}
          columns={columns}
          paddingLeft={0}
          paddingRight={0}
        />
      </CardContent>
    </Card>
  );
};

export default PacienteCitasAnteriores;
