import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import "../../assetss/css/EditarPaciente.css";
import "../../assetss/css/MyTypeahead.css";
import ModalInformation from "../modals/ModalInformation";
import { ModeloGetUrlJson, ModeloPost, ModeloUpdate } from "../ModeloCrud";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import crypto from "../Crypto";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Autocomplete from "@mui/material/Autocomplete";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CapSentence, BpRadio } from "../utils/Utils";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import BackPage from "../BackPage";
import SaveCancelButtons from "../SaveCancelButtons";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import FacebookCircularProgress from "../FacebookCircularProgress";
import TuneIcon from "@mui/icons-material/Tune";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../assetss/css/SyncfusionMaterial.css";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ProtectedRoute from "../ProtectedRoute";
const InsertUpdateOdontologo = () => {
  const [allOdontologos, setAllOdontologos] = useState(null);
  let [filterOdontologos, setFilterOdontologos] = useState(null);
  const [modalOdontologosOpen, setModalOdontologosOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const $ = require("jquery");
  const tablafull = crypto.decryptDataStorage("tablafull");
  const [isAlta, setIsAlta] = useState(true);
  const [odontologoId, setOdontologoId] = useState(null);
  const [padronCodigo, setPadronCodigo] = useState(null);
  const [padronPlan, setPadronPlan] = useState(null);
  const [allPrepagas, setAllPrepagas] = useState([]);
  const [allPlanes, setAllPlanes] = useState([]);
  const [planesLista, setPlanesLista] = useState([]);
  const [gravadoChecked, setGravadoChecked] = useState(false);
  const [searchOdontologo, setSearchOdontologo] = useState("");
  const [dependenciaChecked, setDependenciaChecked] = useState(false);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  useEffect(() => {
    if (typeof location.currentOdontologo === "undefined") {
      setIsAlta(true);
    } else {
      setIsAlta(false);
      setOdontologoId(location.currentOdontologo.id);
      fillInitialValues(location.currentOdontologo);
    }
  }, []);

  const [anchorElBa, setAnchorElBa] = React.useState(null);

  const iconStyles = {
    color: "avatar",
    fontSize: 32,
    marginLeft: 1,
    marginRight: 1,
  };

  const openBa = Boolean(anchorElBa);
  const idBa = openBa ? "simple-popover" : undefined;

  $(document).ready(function () {
    var allInputs = $(":text:visible"); //(1)collection of all the inputs I want (not all the inputs on my form)
    $(":text").on("keydown", function (e) {
      //(2)When an input field detects a keydown event
      if (e.keyCode == 13) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) + 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
      if (e.keyCode == 27) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) - 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
    });
  });

  const handleChangeDependencia = (event) => {
    setDependenciaChecked(event.target.checked);
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };
  const hideWarningModal = () => {
    setModalMessage(null);
    setModalWarningOpen(false);
  };

  function handleKeyPress(event) {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  const validationSchemaAlta = yup.object({
    matricula: yup
      .string()
      .required("Campo Obligatorio.")
      .max(10, "Máximo 10 caracteres."),
    apellido: yup
      .string()
      .test(
        "empty-check",
        "El apellido requiere al menos 2 caracteres.",
        (apellido) => apellido.trim().length >= 2
      )
      .max(50, "Máximo 50 caracteres.")
      .required("Campo Obligatorio."),
    nombres: yup
      .string()
      .test(
        "empty-check",
        "El nombre requiere al menos 2 caracteres.",
        (nombres) => nombres.trim().length >= 2
      )
      .max(50, "Máximo 50 caracteres.")
      .required("Campo Obligatorio."),
    fechaalta: yup
      .date()
      .min(new Date("1986-01-01"), "Fecha inválida")
      .typeError("Fecha inválida")
      .required("Campo Obligatorio."),
    fechabaja: yup
      .date()
      .min(
        yup.ref("fechaalta"),
        "Fecha de baja no puede ser menor a la de alta"
      )
      .nullable()
      .typeError("Fecha inválida"),
    cuit: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]+$/, "Campo numérico")
      .min(10, "mínimo 10 dígitos")
      .max(11, "máxino 1 dígitos")
      .typeError("Campo numérico"),
    sexo: yup.string().required("Seleccione una opción."),
    domicilio: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    localidad: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    telefono: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    celular: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    email: yup.string().nullable(true).email("Ingrese un Email válido."),
    importe_seguro: yup.number().min(0, "el seguro no puede ser menor a 0"),
  });

  function fillInitialValues(Odontologo) {
    formik.resetForm();
    formik.setFieldValue("matricula", Odontologo.matricula);
    formik.setFieldValue("nombres", Odontologo.nombres);
    formik.setFieldValue("apellido", Odontologo.apellido);
    formik.setFieldValue("sexo", Odontologo.sexo);
    if (Odontologo.fechaalta !== null) {
      formik.setFieldValue("fechaalta", dayjs(Odontologo.fechaalta));
    }
    if (Odontologo.fechabaja !== null) {
      formik.setFieldValue("fechabaja", dayjs(Odontologo.fechabaja));
    }
    formik.setFieldValue("domicilio", Odontologo.domicilio);
    formik.setFieldValue("localidad", Odontologo.localidad);
    formik.setFieldValue("telefono", Odontologo.telefono);
    formik.setFieldValue("celular", Odontologo.celular);
    formik.setFieldValue("email", Odontologo.email);
    formik.setFieldValue("cuit", Odontologo.cuit);
    formik.setFieldValue("importe_seguro", Odontologo.importe_seguro);
    setDependenciaChecked(Odontologo.relacion_dependencia === 1 ? true : false);
  }

  const formik = useFormik({
    initialValues: {
      matricula: null,
      nombres: null,
      apellido: null,
      sexo: null,
      fechaalta: null,
      fechabaja: null,
      domicilio: null,
      localidad: null,
      telefono: null,
      celular: null,
      relacion_dependencia: null,
      importe_seguro: 0,
    },
    validationSchema: validationSchemaAlta,
    onSubmit: (values) => {
      let seguir = true;
      if (seguir) {
        grabarOdontologo(values);
      }
    },
  });

  const handleChangeSexo = (event) => {
    formik.setFieldValue("sexo", event.target.value);
  };

  async function grabarOdontologo(values) {
    setLoading(true);
    const Data = {
      matricula: values.matricula,
      apellido: values.apellido,
      nombres: values.nombres,
      fechaalta:
        values.fechaalta !== null
          ? values.fechaalta.toISOString().slice(0, 10)
          : null,
      fechabaja:
        values.fechabaja !== null
          ? values.fechabaja.toISOString().slice(0, 10)
          : null,
      sexo: values.sexo,
      cuit: values.cuit,
      domicilio: values.domicilio,
      localidad: values.localidad,
      telefono: values.telefono,
      celular: values.celular,
      email: values.email,
      relacion_dependencia: dependenciaChecked === true ? 1 : 0,
      importe_seguro: values.importe_seguro,
      iva_id: 1,
    };
    let result = null;
    if (isAlta) {
      result = await ModeloPost("odontologo", null, null, Data);
    } else {
      result = await ModeloUpdate("odontologo", odontologoId, Data);
    }
    setLoading(false);

    if (result.error === false) {
      crypto.encryptDataStorage("currentOdontologo", result.listado[0]);
      handleClickVolver();
    } else {
      const arrobj = result.errormessage[0]["error"];

      setModalMessage(arrobj);
      setModalErrorOpen(true);
    }
  }

  function handleClickVolver() {
    history.push("/odontologos");
  }

  return (
    <ProtectedRoute userRoles={userRoles} requiredPermission={"ALTA CODONTO"}>
      <BackPage text="Volver" handleClick={handleClickVolver} />
      <Container maxWidth="xl">
        <Grid xs={12} sm={6}>
          <Paper
            elevation={2}
            sx={{ width: "50%", height: "55px", marginBottom: 4 }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                height: "100%", // Ensure the Box uses the full height
              }}
            >
              {!isAlta ? (
                <EditIcon sx={iconStyles} />
              ) : (
                <AddIcon sx={iconStyles} />
              )}

              <Typography variant="h6">
                {!isAlta ? "Editar odontólogo" : "Nuevo odontólogo"}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            marginBottom: {
              xs: 0,
              md: 2,
            },
          }}
        ></Grid>
      </Container>
      {searchLoading && (
        <div className="flex justify-center mt-2">
          <FacebookCircularProgress />
        </div>
      )}
      {!modalOdontologosOpen && !searchLoading && (
        <form key="odontologo-form" onSubmit={formik.handleSubmit}>
          <Container
            maxWidth="xl"
            sx={{
              borderBottom: 1,
              borderColor: "grey.400",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{
                marginLeft: {
                  xs: 0,
                  md: 0,
                },
                marginBottom: 2,
              }}
            >
              <Grid xs={12} md={2}>
                <TextField
                  id="matricula"
                  label="Matrícula"
                  value={formik.values.matricula}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.matricula ? formik.errors.matricula : ""
                  }
                  error={
                    formik.touched.matricula && Boolean(formik.errors.matricula)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 0,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={8}></Grid>
              <Grid xs={12} md={4}>
                <TextField
                  id="apellido"
                  label="apellido"
                  value={formik.values.apellido}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.apellido ? formik.errors.apellido : ""
                  }
                  error={
                    formik.touched.apellido && Boolean(formik.errors.apellido)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <TextField
                  id="nombres"
                  label="nombres"
                  value={formik.values.nombres}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.nombres ? formik.errors.nombres : ""
                  }
                  error={
                    formik.touched.nombres && Boolean(formik.errors.nombres)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}></Grid>
              <Grid xs={12} md={2}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                >
                  <DateField
                    id="falta"
                    label="Fecha de Alta"
                    value={formik.values.fechaalta}
                    inputFormat="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    //defaultValue={dayjs(row.alta_usa)}
                    onChange={(value) =>
                      formik.setFieldValue("fechaalta", value)
                    }
                    sx={{
                      marginTop: {
                        xs: 2,
                        md: 2,
                      },
                    }}
                    size="small"
                    helperText={
                      formik.touched.fechaalta ? formik.errors.fechaalta : ""
                    }
                    error={
                      formik.touched.fechaalta &&
                      Boolean(formik.errors.fechaalta)
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid xs={12} md={2}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                >
                  <DateField
                    id="fbaja"
                    label="Fecha de Baja"
                    value={formik.values.fechabaja}
                    inputFormat="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    //defaultValue={dayjs(row.alta_usa)}
                    onChange={(value) =>
                      formik.setFieldValue("fechabaja", value)
                    }
                    sx={{
                      bgcolor:
                        formik.values.fechabaja !== null
                          ? "lightred"
                          : "lightgreen",
                      marginTop: {
                        xs: 2,
                        md: 2,
                      },
                    }}
                    size="small"
                    helperText={
                      formik.touched.fechabaja ? formik.errors.fechabaja : ""
                    }
                    error={
                      formik.touched.fechabaja &&
                      Boolean(formik.errors.fechabaja)
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid xs={12} md={3}>
                <RadioGroup
                  id="sexo"
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formik.values.sexo}
                  onChange={handleChangeSexo}
                  error={formik.touched.sexo && Boolean(formik.errors.sexo)}
                  helperText={formik.touched.sexo ? formik.errors.sexo : ""}
                  sx={{
                    border: 1,
                    paddingX: 2,
                    paddingY: 0.2,
                    borderRadius: 1.2,
                    borderColor:
                      formik.touched.sexo && Boolean(formik.errors.sexo)
                        ? "#d32f2f"
                        : "#bdbdbd",
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                    marginRight: 2,
                  }}
                >
                  <FormControlLabel
                    value="F"
                    control={<BpRadio />}
                    label={
                      <Typography sx={{ fontSize: 14 }}>Femenino</Typography>
                    }
                  />
                  <FormControlLabel
                    value="M"
                    control={<BpRadio />}
                    label={
                      <Typography sx={{ fontSize: 14 }}>Masculino</Typography>
                    }
                  />
                </RadioGroup>
                <FormHelperText sx={{ marginLeft: 4, color: "#d32f2f" }}>
                  {formik.touched.sexo ? formik.errors.sexo : ""}
                </FormHelperText>
              </Grid>
              <Grid xs={12} md={3}>
                <TextField
                  id="cuit"
                  label="Cuit"
                  value={formik.values.cuit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.cuit ? formik.errors.cuit : ""}
                  error={formik.touched.cuit && Boolean(formik.errors.cuit)}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <TextField
                  id="domicilio"
                  label="Domicilio"
                  value={formik.values.domicilio}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.domicilio ? formik.errors.domicilio : ""
                  }
                  error={
                    formik.touched.domicilio && Boolean(formik.errors.domicilio)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                />
              </Grid>
              <Grid xs={12} md={3}>
                <TextField
                  id="localidad"
                  label="Localidad"
                  value={formik.values.localidad}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.localidad ? formik.errors.localidad : ""
                  }
                  error={
                    formik.touched.localidad && Boolean(formik.errors.localidad)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={3}>
                <TextField
                  id="telefono"
                  label="Teléfono"
                  value={formik.values.telefono}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.telefono ? formik.errors.telefono : ""
                  }
                  error={
                    formik.touched.telefono && Boolean(formik.errors.telefono)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 4,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={2}></Grid>
              <Grid xs={12} md={2}>
                <TextField
                  id="celular"
                  label="Celular"
                  value={formik.values.celular}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.celular ? formik.errors.celular : ""
                  }
                  error={
                    formik.touched.celular && Boolean(formik.errors.celular)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <TextField
                  id="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                  }}
                />
              </Grid>
              <Grid
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 2,
                  },
                  marginLeft: {
                    xs: 0,
                    md: 6,
                  },
                }}
                xs={12}
                md={2}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={dependenciaChecked}
                      onChange={handleChangeDependencia}
                    />
                  }
                  label={
                    <Typography
                      color={dependenciaChecked ? "avatar" : "#94a3b8"}
                      fontWeight={dependenciaChecked ? "bold" : "regular"}
                    >
                      Relación de dependencia
                    </Typography>
                  }
                  sx={{
                    marginTop: {
                      xs: 0.5,
                      md: -0.5,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={2}
                sx={{
                  marginTop: {
                    xs: 2,
                    md: 1,
                  },
                  marginLeft: {
                    xs: 0,
                    md: 0,
                  },
                }}
              >
                <TextField
                  id="importe_seguro"
                  label="Seguro"
                  value={formik.values.importe_seguro}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.importe_seguro
                      ? formik.errors.importe_seguro
                      : ""
                  }
                  error={
                    formik.touched.importe_seguro &&
                    Boolean(formik.errors.importe_seguro)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Container>
          <SaveCancelButtons
            handleCancel={handleClickVolver}
            loading={loading}
          />
        </form>
      )}
      <BackPage text="Volver" handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen === true && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo={"error"}
      />
      <ModalInformation
        showModal={modalWarningOpen && modalMessage !== null}
        hideModal={hideWarningModal}
        message={modalMessage}
        tipo={"warning"}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo={"info"}
      />
    </ProtectedRoute>
  );
};
export default InsertUpdateOdontologo;
