import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import loadingGif from "../assetss/img/loading_64.gif";
import SubirArchivo from "./SubirArchivo";
import { CapSentence, hasPermission } from "./utils/Utils";
import BorrarImagen from "./BorrarImagen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ModalInformation from "./modals/ModalInformation";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { subirLogoClientes } from "./utils/Utils";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import crypto from "./Crypto";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import DeleteModel from "./DeleteModel";

export default function ClientesDatos({
  currentCliente,
  logoCliente,
  logoClienteid,
  metodolimpiar,
}) {
  const [loadingRx, setLoadingRx] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [deleteClienteId, setDeleteClienteId] = useState(null);
  const history = useHistory();

  const [openSubirArchivo, setOpenSubirArchivo] = useState(false);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const okMethod = () => {
    setModalMessage("El archivo se subió correctamente.");
    setModalSuccessOpen(true);
  };

  const hideSuccessModal = () => {
    setModalMessage(null);
    setModalSuccessOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {}, []);

  async function NUploadImage() {
    SubirArchivo(currentCliente, setLoading, alert("ok"));
  }

  async function SubirLogo() {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_) => {
      let files = Array.from(input.files);
      const uploadedFile = files[0];
      const filextension = uploadedFile.name.split(".")[1];
      const atypes = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "pdf",
        "txt",
        "csv",
        "doc",
        "docx",
        "xls",
        "xlsx",
      ];
      let itype = atypes.indexOf(filextension);

      if (itype >= 0) {
        toBase64(uploadedFile)
          .then((res) => {
            let result = subirLogoClientes(
              res,
              currentCliente,
              setLoading,
              filextension
            ).then((res) => {
              if (res.error === false) {
                setModalMessage("El archivo se subió correctamente.");
                setModalSuccessOpen(true);
              } else {
                setModalMessage(res.mensaje);
                setModalErrorOpen(true);
              }
            });
          })
          .catch((err) => {});
      } else {
        setModalMessage(
          "Tipo de archivo no permitido.\nPermitidos:\nImágenes\nPdf\nTxt\nCsv\nDoc\nXls"
        );
        setModalInformationOpen(true);
      }
    };
    input.click();
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  function handleEdit() {
    history.push({
      pathname: "/insertupdatecliente",
      currentCliente,
      //     cleanMethod: cleanpaciente,
    });
  }

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  return (
    <>
      {loadingRx && (
        <div className="ml-2 mt-2">
          <img alt="Loading" src={loadingGif} />
        </div>
      )}
      <Card
        sx={{
          ml: -2,
          mr: -2,
          backgroundColor: "bgcards",
          marginBottom: 4,
          height: "100%",
        }}
      >
        <CardHeader
          action={
            <>
              <Tooltip title="Menu Clientes">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ mt: 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32, bgcolor: "avatar" }}>
                    <MoreVertIcon />
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {hasPermission(userRoles, "ALTA CPACIENTES") && (
                  <>
                    <MenuItem onClick={handleEdit}>
                      <ListItemIcon>
                        <EditIcon
                          fontSize="small"
                          sx={{ color: "primary.main", mr: 1 }}
                        />
                      </ListItemIcon>
                      Editar
                    </MenuItem>
                    <MenuItem
                      onClick={() => setDeleteClienteId(currentCliente.id)}
                    >
                      <ListItemIcon>
                        <DeleteIcon
                          fontSize="small"
                          sx={{ color: "iconred", mr: 1 }}
                        />
                      </ListItemIcon>
                      Borrar
                    </MenuItem>
                    <MenuItem onClick={SubirLogo}>
                      <ListItemIcon>
                        <AccountCircleIcon
                          fontSize="small"
                          sx={{ color: "primary.main", mr: 1 }}
                        />
                      </ListItemIcon>
                      Subir Logo
                    </MenuItem>
                    <MenuItem
                      onClick={() => setDeleteImageId(logoClienteid)}
                      disabled={logoCliente === null}
                    >
                      <ListItemIcon>
                        <NoAccountsIcon
                          fontSize="small"
                          sx={{ color: "primary.main", mr: 1 }}
                        />
                      </ListItemIcon>
                      Quitar Logo
                    </MenuItem>{" "}
                  </>
                )}
                <MenuItem onClick={metodolimpiar}>
                  <ListItemIcon>
                    <CleaningServicesIcon
                      fontSize="small"
                      sx={{ color: "primary.main", mr: 1 }}
                    />
                  </ListItemIcon>
                  Limpiar Pantalla
                </MenuItem>

                <Divider />
              </Menu>
            </>
          }
          title={
            <>
              {logoCliente !== null && (
                <div>
                  <img
                    class="object-contain h-24 w-48 mb-2"
                    src={`${logoCliente}`}
                    alt="imageToShow"
                    loading="lazy"
                  />
                </div>
              )}
            </>
          }
          subheader={
            <>
              <Typography
                sx={{
                  fontSize: 22,
                  fontFamily: "Open Sans",
                  fontWeight: "medium",
                  mt: 1,
                }}
              >
                {CapSentence(
                  currentCliente.denominacion_comercial.toLowerCase()
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontFamily: "Open Sans",
                  fontWeight: "medium",
                  mt: 1,
                }}
              >
                {CapSentence(currentCliente.razon_social.toLowerCase())}
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "Open Sans",
                  fontWeight: "medium",
                  color: "primary.main",
                  mt: 1,
                }}
              >
                Código: {currentCliente.codigo}
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontFamily: "Open Sans",
                  fontWeight: "medium",
                  mt: 1,
                }}
              >
                Id: {currentCliente.id}
              </Typography>
            </>
          }
        />

        <Box sx={{ mt: -1, ml: 3 }}>
          <div className="flex mt-2 -ml-2 items-end">
            <i className='far fa-calendar-alt fa-xl mr-5" text-sky-600' />
            <p className="ml-2">
              {currentCliente.fecha_alta !== null &&
              currentCliente.fecha_alta.length > 0
                ? "Fecha  Alta: " +
                  moment(currentCliente.fecha_alta).format("DD/MM/YYYY")
                : ""}
            </p>
          </div>
          <Box
            sx={{
              display: "flex",
              marginTop: 2,
              marginLeft: -1,
              marginRight: 4,
              justifyContent: "start",
              border: currentCliente.fecha_baja !== null ? 1 : 0,
              borderRadius: 1,
              borderColor: "black",
              bgcolor:
                currentCliente.fecha_baja !== null ? "iconred" : "FAFAFA",
              color: currentCliente.fecha_baja !== null ? "white" : "black",
            }}
          >
            {currentCliente.fecha_baja !== null && (
              <i className='far fa-calendar-alt fa-xl mr-5" text-white' />
            )}
            {currentCliente.fecha_baja === null && (
              <i className='far fa-calendar-alt fa-xl mr-5" text-emerald-600' />
            )}
            <p className="ml-2">
              {currentCliente.fecha_baja !== null &&
              currentCliente.fecha_baja.length > 0
                ? "Fecha Baja: " +
                  moment(currentCliente.fecha_baja).format("DD/MM/YYYY")
                : "Fecha de Baja:"}
            </p>
          </Box>
          <div className="flex mt-4 -ml-2 items-end">
            <p className="ml-0">
              {currentCliente.cuit !== null && currentCliente.cuit.length > 0
                ? "Cuit: " + currentCliente.cuit
                : ""}
            </p>
          </div>
          <div className="flex mt-4 -ml-2 mb-4 items-end ">
            <p className="ml-0">
              {currentCliente.tipo !== null && currentCliente.tipo.length > 0
                ? "Tipo:  " + CapSentence(currentCliente.tipo)
                : ""}
            </p>
          </div>
          <div className="flex mt-2 -ml-2 mb-4 items-end ">
            <p className="ml-0 mr-2">Presenta padrón: </p>
            {currentCliente.presenta_padron}
          </div>
          <div className="flex mt-2 -ml-2 mb-4 items-end ">
            <p className="ml-0 mr-2">Precios Bonificados: </p>
            {currentCliente.precios_bonificados}
          </div>
        </Box>
      </Card>
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalSuccessOpen && modalMessage !== null}
        hideModal={hideSuccessModal}
        message={modalMessage}
        tipo="success"
      />
      {deleteClienteId !== null && (
        <DeleteModel
          model={"prepaga"}
          modelid={deleteClienteId}
          okmethod={metodolimpiar}
          nullmethod={setDeleteClienteId}
        />
      )}
      {deleteImageId !== null && (
        <BorrarImagen
          imageId={deleteImageId}
          setLoading={setLoading}
          //   afterMethod={() => metodorefresh()}
          setDeleteImageId={setDeleteImageId}
        />
      )}
      {openSubirArchivo && (
        <SubirArchivo
          currentCliente={currentCliente}
          setLoading={setLoading}
          okmethod={okMethod}
          setOpenSubirArchivo={setOpenSubirArchivo}
        />
      )}
    </>
  );
}
