import ApiService from "../apis/ApiService";
import { Redirect } from "react-router-dom";
import { render } from "preact/compat";
import crypto from "./Crypto";

export async function ModeloSearch(Url, Data) {
  let SearchUrl = Url + "/buscar";
  let SearchData = Data;
  try {
    let result = await ApiService.Create(SearchUrl, SearchData);
    return result;
  } catch (e) {
    alert("modelo");
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloUpdate(Url, ModeloId, Data) {
  let UpdateUrl = Url;
  let UpdateData = Data;

  try {
    return await ApiService.Update(UpdateUrl, ModeloId, UpdateData).then(
      (response) => response.data
    );
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloPost(Url, ModeloId, urlVista, Data) {
  let PostUrl = Url;
  let PostData = Data;
  if (ModeloId !== null) {
    PostUrl += "/" + ModeloId.toString().trim();
  }
  if (urlVista !== null) {
    PostUrl += "/" + urlVista.toString().trim();
  }
  try {
    let result = await ApiService.Create(PostUrl, PostData);
    return result;
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloGetUrl(Url, ModeloId, urlVista, Params) {
  let GettUrl = Url;
  let GetParams = Params;
  if (ModeloId !== null) {
    GettUrl += "/" + ModeloId.toString().trim();
  }
  if (urlVista !== null) {
    GettUrl += "/" + urlVista.toString().trim();
  }
  try {
    return await ApiService.GetUrl(GettUrl, GetParams).then(
      (response) => response.data
    );
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloGetUrlJson(Url, ModeloId, urlVista, Params) {
  let GettUrl = Url;
  let GetParams = Params;
  if (ModeloId !== null) {
    GettUrl += "/" + ModeloId.toString().trim();
  }
  if (urlVista !== null) {
    GettUrl += "/" + urlVista.toString().trim();
  }
  try {
    console.log(GettUrl);
    console.log(GetParams);
    let result = await ApiService.GetUrlJson(GettUrl, GetParams);

    if (result.error === true) {
      // if (result.errormessage != null) {
      //  alert(result.errormessage);
      // }
      // var mainurl = window.location.origin;
      // window.location = mainurl;
      // if (crypto.decryptLocalStorage("is-beta") === false) {
      //    window.location = "https://clinicasapp.consulmed.com.ar/";
      //  } else {
      //    window.location = "http://localhost:3000/";
      //  }
      alert("error");
    } else {
      return result;
    }
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function LoggedUser(jwtoken) {
  try {
    return await ApiService.LoggedUser(jwtoken).then(
      (response) => response.data
    );
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloPacienteGetInformation(
  pacienteid,
  tipo_doc,
  nro_doc
) {
  try {
    return await ApiService.Informacionpaciente(
      pacienteid,
      tipo_doc,
      nro_doc
    ).then((response) => response);
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloDelete(Url, Id) {
  try {
    return await ApiService.Remove(Url, Id).then((response) => response.data);
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloGetFotoPaciente(folder, filename) {
  try {
    return await ApiService.GetFotoPaciente(folder, filename).then(
      (response) => response.data
    );
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloGetRxsPaciente(nro_doc, carpeta) {
  try {
    return await ApiService.GetRxsPaciente(nro_doc, carpeta).then(
      (response) => response.data
    );
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function InitialData() {
  try {
    return await ApiService.InitialData().then((response) => response);
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloPresupuestoGetInformation() {
  try {
    return await ApiService.Informaciongrabarpresupuesto().then(
      (response) => response
    );
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function ModeloPostSetPassword(userId, data) {
  try {
    return await ApiService.setPassword(userId, data).then(
      (response) => response.data
    );
  } catch (e) {
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}
