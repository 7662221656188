import React, { useState, useEffect } from "react";
import ModalInformation from "./modals/ModalInformation";
import { grabarFoto } from "./utils/Utils";

export default function SubirArchivo({
  currentPaciente,
  setLoading,
  okmethod,
  setOpenSubirArchivo,
  setModalMessage,
  setModalInformationOpen,
}) {
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);

  useEffect(() => {
    setOpenSubirArchivo(false);
  }, []);

  const hideSuccessModal = () => {
    setModalMessage(null);
    setModalSuccessOpen(false);
  };
  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  let input = document.createElement("input");
  input.type = "file";
  input.onchange = (_) => {
    let files = Array.from(input.files);
    const uploadedFile = files[0];
    const filextension = uploadedFile.name.split(".")[1];
    const atypes = ["jpg", "jpeg", "png", "gif", "bmp", "pdf", "txt", "csv"];
    let itype = atypes.indexOf(filextension);
    if (itype >= 0) {
      toBase64(uploadedFile)
        .then((res) => {
          let result = grabarFoto(
            res,
            currentPaciente,
            "adjuntos",
            setLoading,
            filextension
          ).then((res) => {
            if (res.error === false) {
              //setModalMessage("El archivo se subió correctamente.");
              // setModalSuccessOpen(true);
              if (okmethod !== null) {
                okmethod();
              }
            } else {
              setModalMessage(res.mensaje);
              setModalErrorOpen(true);
            }
          });
        })
        .catch((err) => {});
    } else {
      setModalMessage(
        "Tipo de archivo No Permitido\n\
        Permitidos:\n\
        Imágenes\n\
        Pdf\n\
        Txt\n\
        Csv"
      );
      setModalInformationOpen(true);
    }
  };
  input.click();

  return <></>;
}
