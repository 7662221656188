import React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import crypto from "../Crypto";
import { CapSentence } from "../utils/Utils";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import PacienteMenu from "./PacienteMenu";
import PacienteMenuWide from "./PacienteMenuWide";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";

const PacienteHeader = ({
  title = null,
  prepaga = true,
  foto = true,
  collapse = false,
  backpage = "pacientes",
  menu = true,
}) => {
  const currentpatient = crypto.decryptDataStorage("currentpatient");

  const gopatient = () => {
    if (currentpatient !== null) {
      history.push("/pacientes");
    }
  };
  const history = useHistory();
  const fotopaciente = foto ? crypto.decryptDataStorage("dataFoto") : null;
  let Texto = "Paciente sin seleccionar";
  if (title !== null) {
    Texto = title;
  } else if (
    crypto.decryptDataStorage("currentpatientname") !== null &&
    crypto.decryptDataStorage("currentprepagacodigo") !== null
  ) {
    if (prepaga === true) {
      Texto =
        "Paciente: " +
        CapSentence(
          crypto.decryptDataStorage("currentpatientname") +
            " (" +
            crypto.decryptDataStorage("currentprepagacodigo") +
            ")"
        );
    } else {
      Texto =
        "Paciente: " +
        CapSentence(crypto.decryptDataStorage("currentpatientname"));
    }
  } else {
    //   Texto = "Paciente sin seleccionar";
  }
  return (
    <Container maxWidth="xl">
      {collapse === true && (
        <Accordion sx={{ marginTop: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <div className="flex items-center justify-start ">
              {foto === true && fotopaciente !== null && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    alt="Foto"
                    src={"data:image/jpg;base64," + fotopaciente}
                    sx={{
                      width: 48,
                      height: 48,
                      justifyContent: "center",
                      boxShadow: 10,
                      ml: 2,
                      mr: 2,
                    }}
                  />
                </Box>
              )}
              {fotopaciente === null &&
                Texto === "Paciente sin seleccionar" && (
                  <NoAccountsIcon
                    sx={{
                      color: "#94a3b8",
                      fontSize: 48,
                      marginLeft: 1,
                      marginRight: 1,
                    }}
                  />
                )}
              {fotopaciente === null &&
                Texto !== "Paciente sin seleccionar" && (
                  <AccountCircleIcon
                    sx={{
                      color: "#94a3b8",
                      fontSize: 48,
                      marginLeft: 1,
                      marginRight: 1,
                    }}
                  />
                )}
              <Typography variant="h6">{Texto}</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PacienteMenu backpage={backpage} />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {collapse === false && (
        <Paper
          elevation={2}
          sx={{
            with: "90%",
            height: "auto",
            py: 0.25,
            mb: 2,
          }}
        >
          <div className="flex items-center justify-start ">
            <Grid container>
              <Grid item xs={12} md={menu ? 4 : 12}>
                <Box sx={{ display: "flex" }}>
                  {foto === true && fotopaciente !== null && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Avatar
                        alt="Foto"
                        src={"data:image/jpg;base64," + fotopaciente}
                        sx={{
                          width: 48,
                          height: 48,
                          justifyContent: "center",
                          boxShadow: 10,
                          ml: 2,
                          mr: 2,
                          cursor: "pointer",
                        }}
                        onClick={() => gopatient()}
                      />
                    </Box>
                  )}
                  {fotopaciente === null &&
                    Texto === "Paciente sin seleccionar" && (
                      <NoAccountsIcon
                        sx={{
                          color: "#94a3b8",
                          fontSize: 48,
                          marginLeft: 1,
                          marginRight: 1,
                        }}
                      />
                    )}
                  {fotopaciente === null &&
                    Texto !== "Paciente sin seleccionar" && (
                      <AccountCircleIcon
                        sx={{
                          color: "#94a3b8",
                          fontSize: 48,
                          marginLeft: 1,
                          marginRight: 1,
                          cursor: "pointer",
                        }}
                        onClick={() => gopatient()}
                      />
                    )}
                  <Typography variant="h6" sx={{ mt: 1, mr: 4 }}>
                    {Texto}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                {currentpatient !== null && (
                  <>
                    <Box
                      sx={{
                        display: { xs: "none", lg: "flex" },
                        mt: 1,
                      }}
                    >
                      {menu === true && <PacienteMenuWide />}
                    </Box>
                    <Box sx={{ display: { xs: "flex", lg: "none" }, mt: 1 }}>
                      {menu === true && <PacienteMenu />}
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </Paper>
      )}
    </Container>
  );
};

export default PacienteHeader;
