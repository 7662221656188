import React, { useRef, useState, useEffect } from "react";
import { ModalTableForm } from "../utils/Utils.js";
import Alert from "react-bootstrap/Alert";
import ModalTextInformation from "../modals/ModalTextInformation";
import ModalInformation from "../modals/ModalInformation";
import { ModeloGetUrlJson, ModeloGetUrl } from "../ModeloCrud";
import crypto from "../Crypto";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ClientesDatos from "../ClientesDatos";
import ClientesPlanes from "../ClientesPlanes";
import { Grid, Container } from "@mui/material";
import MultipleResultsTable from "../MultipleResultsTable";
import { CapSentenceArray, hasPermission } from "../utils/Utils.js";
import CustomizedSearch from "../CustomizedSearch.js";
import ProtectedRoute from "../ProtectedRoute";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useHistory } from "react-router-dom";

const Clientes = () => {
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchCliente, setSearchCliente] = useState("");
  const [showAlert, setShowAlert] = useState(null);
  const [currentCliente, setCurrentCliente] = useState(null);
  const [clienteOk, setClienteOk] = useState(false);
  const [allClientes, setAllClientes] = useState(null);
  const [allPlanes, setAllPlanes] = useState(null);
  const [modalClientesOpen, setModalClientesOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalTextMessage, setModalTextMessage] = useState(null);
  const [displayInformationModal, setDisplayInformationModal] = useState(false);
  const [displayTextInformationModal, setDisplayTextInformationModal] =
    useState(false);
  const [modalPreciosOpen, setModalPreciosOpen] = useState(false);
  const [listaPrecios, setListaPrecios] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [logo, setLogo] = useState(null);
  let [filterClientes, setFilterClientes] = useState(null);
  const history = useHistory();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const ltaFields = [
    "id",
    "codigo_nomenclador",
    "precio",
    "descripcion_nomenclador",
    "grupo_dental",
    "observaciones",
  ];
  const ltaNames = ["I", "C", "P", "Descripción", "Piezas", "Observaciones"];
  const ltaHeader =
    '<table id="mitabla" className="display responsive nowrap " style={{ width: "100%" }}>';
  // const Linqer = require('@siderite/linqer');

  const objHeaders = [
    "Ver",
    "Código",
    "Razón Social",
    "Nombre Comercial",
    "Alta",
    "Baja",
  ];

  const objFields = [
    "codigo",
    "razon_social",
    "denominacion_comercial",
    "fecha_alta",
    "fecha_baja",
  ];
  const objDates = ["fecha_alta", "fecha_baja"];

  useEffect(() => {
    if (crypto.decryptDataStorage("currentcliente") !== null) {
      buscarCliente(crypto.decryptDataStorage("currentcliente").id, true);
    }
  }, []);

  function newcliente() {
    history.push({
      pathname: "/insertupdatecliente",
    });
  }

  function onChangeSearchCliente(e) {
    setSearchCliente(e.target.value);
    if (modalClientesOpen) {
      FiltrarClientes(searchCliente);
    }
  }
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      buscarCliente(searchCliente, false, null);
    }
  }

  function busqueda() {
    buscarCliente(searchCliente, false);
  }

  function FiltrarClientes(filtro) {
    if (filtro.length > 0) {
      filterClientes = allClientes.filter(
        (p) =>
          p.razon_social.toLowerCase().indexOf(filtro.toLowerCase()) >= 0 ||
          p.denominacion_comercial
            .toLowerCase()
            .indexOf(filtro.toLowerCase()) >= 0
      );
      if (filterClientes.length <= 0) {
        filterClientes = allClientes;
      }
    } else {
      filterClientes = allClientes;
    }
    //crypto.encryptDataStorage("hctratamientos", filterData);
    let tempData = [...filterClientes];
    setFilterClientes(tempData);
    if (filterClientes.length > 0) {
    }
  }

  function limpiarCliente() {
    setShowAlert(null);
    setCurrentCliente(null);
    setClienteOk(false);
    setModalClientesOpen(false);
    setAllClientes(null);
    setFilterClientes(null);
    setAllPlanes(null);
    setLoading(false);
    setLogo(null);
    sessionStorage.removeItem("currentclienteid");
    sessionStorage.removeItem("currentcliente");
    sessionStorage.removeItem("planescliente");
    sessionStorage.removeItem("logocliente");
  }
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };
  function clean() {
    limpiarCliente();
  }

  async function buscarCliente(Busqueda, IsId) {
    limpiarCliente();
    if (!IsId && Busqueda.length < 3) {
      setShowAlert("Ingrese al menos 3 caracteres !");
      return;
    }
    let params = "busqueda=" + Busqueda;
    if (IsId) {
      params = params + "&is_id=SI";
    }
    setLoading(true);
    let result = await ModeloGetUrlJson(
      "prepaga",
      null,
      "busqueda-prepaga",
      params
    );
    setLoading(false);

    if (result.error === false) {
      if (result.listado == null && result.prepaga !== null) {
        const logo = result.logo.length > 0 ? result.logo : null;
        setCurrentCliente(result.prepaga[0]);
        crypto.encryptDataStorage("currentcliente", result.prepaga[0]);
        crypto.encryptDataStorage("currentclienteid", result.prepaga[0].id);
        crypto.encryptDataStorage("planescliente", result.planes);
        setAllPlanes(result.planes);
        if (logo !== null) {
          setLogo("data:image/jpg;base64," + result.logo);
          crypto.encryptDataStorage(
            "logocliente",
            "data:image/jpg;base64," + result.logo
          );
        }
        setClienteOk(true);
      } else if (result.listado.length > 1) {
        if (result.listado.length > 500) {
          setModalMessage(
            "Demasiados resultados(" +
              result.listado.length.toString() +
              ") refine la búsqueda."
          );
          setModalInformationOpen(true);
        } else {
          setAllClientes(result.listado);
          setFilterClientes(result.listado);
          setModalClientesOpen(true);
        }
      } else if (result.listado.length === 0) {
        setLoading(false);
        setModalMessage("No se encontraron registros.");
        setModalInformationOpen(true);
      }
    } else {
      setLoading(false);
      setModalMessage(result.errormessage);
      setModalErrorOpen(true);
    }
    return;
  }

  async function buscarPlanes(prepagaid) {
    setLoading(true);
    let result = await ModeloGetUrl("prepaga", prepagaid, "vista-planes", null);
    if (result.error === false) {
      if (result.listado.length > 0) {
        crypto.encryptDataStorage("planescliente", result.listado);
        setAllPlanes(result.listado);
      }
      buscarLogo(prepagaid);
    } else {
      setLoading(false);
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
    return;
  }

  async function buscarLogo(prepagaid) {
    setLoading(true);
    let result = await ModeloGetUrl("prepaga", prepagaid, "bajar-logo", null);
    if (result.error === false) {
      if (result.listado.length > 0) {
        setLogo("data:image/jpg;base64," + result.listado);
        crypto.encryptDataStorage(
          "logocliente",
          "data:image/jpg;base64," + result.listado
        );
        setLoading(false);
        setClienteOk(true);
      } else {
        setClienteOk(true);
      }
    } else {
      setLoading(false);
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
    return;
  }

  async function buscarPrecios(PlanId) {
    setLoading(true);
    let result = await ModeloGetUrl(
      "plan-prepaga",
      PlanId,
      "precios-nomenclador",
      null
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        //let lista = Linqer.Enumerable.from(result.listado).orderBy(g => g.codigo_nomenclador).toArray();
        let lista = result.listado.sort((a, b) =>
          a.codigo_nomenclador > b.codigo_nomenclador ? 1 : -1
        );
        setListaPrecios(lista);
        setModalPreciosOpen(true);
      }
    } else {
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
    return;
  }

  async function buscarPreciosLista(ListaId) {
    setLoading(true);
    let result = await ModeloGetUrl(
      "listas-precios",
      ListaId,
      "precios-nomenclador",
      null
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        //let lista = Linqer.Enumerable.from(result.listado).orderBy(g => g.codigo_nomenclador).toArray();
        let lista = result.listado.sort((a, b) =>
          a.codigo_nomenclador > b.codigo_nomenclador ? 1 : -1
        );
        setListaPrecios(lista);
        setModalPreciosOpen(true);
      }
    } else {
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
    return;
  }

  function verObservaciones(element) {
    setDisplayTextInformationModal(true);
    setModalTextMessage(element);
  }
  const hideTextInformationModal = () => {
    setDisplayTextInformationModal(false);
    setModalTextMessage(null);
  };

  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"VISUALIZAR CPREPAGAS"}
    >
      <div className="w-full">
        <div className="flex justify-center mt-4 ml-2 mr-3 ">
          <CustomizedSearch
            value={searchCliente}
            onchange={onChangeSearchCliente}
            handlebusqueda={busqueda}
            placeholder="Código o Razón..."
            width={{ xs: 400, md: 500 }}
            starticon={
              hasPermission(userRoles, "ALTA CPREPAGAS") ? (
                <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
              ) : null
            }
            handlestart={
              hasPermission(userRoles, "ALTA CPREPAGAS") ? newcliente : null
            }
          />
        </div>
        {clienteOk === false && (
          <div className="flex justify-center mt-2">
            {loading && <FacebookCircularProgress />}
          </div>
        )}
        {modalClientesOpen && filterClientes.length > 0 && (
          <MultipleResultsTable
            setShowModal={setModalClientesOpen}
            pallData={CapSentenceArray(filterClientes, [
              "razon_social",
              "denominacion_comercial",
            ])}
            objFields={objFields}
            objHeaders={objHeaders}
            objDates={objDates}
            selectedMethod={buscarCliente}
            highlightText={searchCliente}
          />
        )}
        {currentCliente !== null && clienteOk === true && (
          <>
            <div className="flex justify-center w-full py-4 mt-4 px-8  ">
              <Container maxWidth={false} sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={{ xs: 2, md: 4 }}
                  columnSpacing={{ xs: 0, md: 6 }}
                >
                  <Grid item xs={12} md={3}>
                    {currentCliente !== null && (
                      <ClientesDatos
                        currentCliente={currentCliente}
                        logoCliente={logo}
                        logoClienteid={null}
                        metodolimpiar={limpiarCliente}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    {currentCliente !== null && (
                      <ClientesPlanes
                        allPlanes={allPlanes}
                        currentCliente={currentCliente}
                        busqueda={() =>
                          buscarCliente(
                            crypto.decryptDataStorage("currentcliente").id,
                            true
                          )
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Container>
            </div>
          </>
        )}
      </div>
      <div>
        {showAlert !== null && (
          <div className="flex items-center justify-center mt-5 ml-12">
            <Alert variant="primary">{showAlert}</Alert>
          </div>
        )}

        <ModalInformation
          showModal={modalInformationOpen && modalMessage !== null}
          hideModal={hideInformationModal}
          message={modalMessage}
        />
        <ModalInformation
          showModal={modalErrorOpen && modalMessage !== null}
          hideModal={hideErrorModal}
          message={modalMessage}
          tipo="error"
        />

        <ModalTextInformation
          showModal={displayTextInformationModal && modalTextMessage !== null}
          hideModal={hideTextInformationModal}
          message={modalTextMessage}
          title="Observaciones"
        />
      </div>
    </ProtectedRoute>
  );
};

export default Clientes;
