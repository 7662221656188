import React, { useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import { ModeloSearch } from "../ModeloCrud";

const PadronesBusquedaAvanzada = ({
  open,
  anchorEl,
  id,
  handleClose,
  setResultados,
  setLoadingPacientes,
}) => {
  const [codigo, setCodigo] = React.useState(null);
  const [credencial, setCredencial] = React.useState(null);
  const [apellido, setApellido] = React.useState(null);
  const [nombres, setNombres] = React.useState(null);
  const [fnacimiento, setFNacimiento] = React.useState(null);

  function handleCloseForm() {
    setCodigo(null);
    setCredencial(null);
    setApellido(null);
    setNombres(null);
    setFNacimiento(null);
    handleClose();
  }

  async function handleBuscar() {
    let data = {};
    if (codigo !== null) {
      data["codigo"] = codigo;
    }
    if (credencial !== null) {
      data["credencial"] = credencial;
    }
    if (apellido !== null) {
      data["apellido"] = apellido;
    }
    if (nombres !== null) {
      data["nombres"] = nombres;
    }
    if (fnacimiento !== null) {
      data["date_nac"] = fnacimiento;
    }
    const keysArray = Object.keys(data);
    const count = keysArray.length;
    handleCloseForm();
    if (count > 0) {
      data["tipo_busqueda"] = "and";
      data["comparacion_busqueda"] = "like";
      setLoadingPacientes(true);
      let result = await ModeloSearch("padron", data);

      setLoadingPacientes(false);
      if (result.error === false) {
        const sortresult = result.listado.sort(function (a, b) {
          if (a.date_baja === null) {
            return -1;
          }
          if (b.date_baja === null) {
            return 1;
          }
          if (a.date_baja === b.date_baja) return 0;
          return a.date_baja < b.date_baja ? 1 : -1;
        });
        setResultados(sortresult);
        return;
      }
    }
    return;
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseForm}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          p: 1,
          m: 1,
          bgcolor: "background.paper",
          borderRadius: 1,
          width: 400,
        }}
      >
        <TextField
          id="standard-search"
          label="Código"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={codigo}
          onChange={(event) => {
            setCodigo(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="Credencial"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={credencial}
          onChange={(event) => {
            setCredencial(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="Apellido"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={apellido}
          onChange={(event) => {
            setApellido(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="Nombres"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={nombres}
          onChange={(event) => {
            setNombres(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="F.Nacimiento (yyyy-mm-dd)"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={fnacimiento}
          onChange={(event) => {
            setFNacimiento(event.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          marginTop: 4,
          marginBottom: 2,
          marginRight: 2,
        }}
      >
        <Button
          onClick={handleCloseForm}
          color="primary"
          variant="contained"
          loadingPosition="end"
          sx={{
            backgroundColor: "#cbd5e1",
            ":hover": {
              bgcolor: "#475569", // theme.palette.primary.main
              color: "white",
            },
            textTransform: "capitalize",
            width: "80px",
          }}
        >
          Cancelar
        </Button>
        <LoadingButton
          onClick={handleBuscar}
          color="primary"
          loadingPosition="start"
          variant="contained"
          sx={{
            backgroundColor: "primary.main",
            ":hover": {
              bgcolor: "#475569", // theme.palette.primary.main
              color: "white",
            },
            textTransform: "capitalize",
            width: "80px",
          }}
        >
          <span>Buscar</span>
        </LoadingButton>
      </Box>
    </Popover>
  );
};

export default PadronesBusquedaAvanzada;
