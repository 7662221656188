import React, { useState, useEffect, useRef } from "react";

import ReactToPrint from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';

import {
  Button,
  IconButton,
  Tooltip,
 } from "@mui/material";



export const PrintButton = (props: ActionButtonProps) => {
  const { componentToPrint,text,promiseResolveRef,beforePrint,isPrinting,setIsPrinting,buttonType = "button"} = props;



  // We store the resolve Promise being used in `onBeforeGetContent` here
 

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  
  
  // We store the resolve Promise being used in `onBeforeGetContent` here



const pageStyle = `
            @media print {
              .page-break {
                page-break-before: always;
                margin-top: 20mm; /* Adjust this value as needed */
              }
            }
            `;
           
  return (
    <>
    {buttonType === "button" && <ReactToPrint
        trigger={() => (
          <Button id="print" variant="outlined"    startIcon={<PrintIcon />}
            sx={{             
            textTransform: "capitalize",
            paddingY: 1,
          }}>
           {text !== null ? text : ""}
          </Button>
        )}
        content={() => componentToPrint.current}
        onBeforePrint={() => beforePrint !== null ? beforePrint() : null} 
        pageStyle={pageStyle}
      /> }
       {buttonType === "table" && <ReactToPrint
        trigger={() => (
          <Tooltip title="Imprimir"> <IconButton><PrintIcon sx={{color:"primary.main",cursor:"pointer"}} /></IconButton></Tooltip>
        )}
        content={() => componentToPrint.current}
        
        onBeforeGetContent={ () => {
          return new Promise((resolve) => {
            promiseResolveRef.current = resolve;
            setIsPrinting(true);
            beforePrint();
                 });}}

          onAfterPrint={ () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
          }}
   
        pageStyle={pageStyle}
      /> 
        }</>
  );
};
