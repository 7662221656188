import React, { useState } from "react";
import {
  Box,
  Avatar,
  Container,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import crypto from "./Crypto";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { CapSentence } from "./utils/Utils";
import { useFormik } from "formik";
import * as yup from "yup";
import * as moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";
import { DateField } from "@mui/x-date-pickers/DateField";
import { ModeloGetUrlJson } from "./ModeloCrud";

export default function ReporteCajaPorDia_1(props) {
  const { setAllData, setChecked, setAllPagos, setAllPagosTarjetas } = props;
  const [loading, setLoading] = useState(false);
  let [selectedClinica, setSelectedClinica] = useState(null);
  const now = new Date().toISOString().slice(0, 10);
  const [cajas, setCajas] = useState(crypto.decryptDataStorage("cajas"));
  let [cajasLista, setCajasLista] = useState([]);
  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];

  const validationSchema = yup.object({
    clinica: yup.object().required("Campo Obligatorio."),
    fecha_desde: yup
      .date()
      .nullable()
      .typeError("Fecha inválida.")
      .required("Campo obligatorio."),
    fecha_hasta: yup
      .date()
      .min(yup.ref("fecha_desde."), "Fecha inválida.")
      .nullable()
      .typeError("Fecha inválida.")
      .required("Campo obligatorio."),
  });

  const formik = useFormik({
    initialValues: {
      fecha_desde: dayjs(Date()),
      fecha_hasta: dayjs(Date()),
      clinica: null,
      caja: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClickBuscar(values);
    },
  });

  const onClinicasChange = (event, values) => {
    formik.setFieldValue("clinica", values);

    if (values !== null) {
      const tempCajas = cajas.filter((ca) => ca.centro_id === values.id);

      setCajasLista(tempCajas);
    } else {
      setCajasLista([]);
    }
  };

  const onCajaChange = (event, values) => {
    formik.setFieldValue("caja", values);
  };

  async function handleClickBuscar(values) {
    setAllData(null);
    setChecked([]);
    let params =
      "fechadesde=" +
      values.fecha_desde.format().slice(0, 10) +
      "&fechahasta=" +
      values.fecha_hasta.format().slice(0, 10);
    if (values.clinica !== null) {
      params += "&centro_id=" + values.clinica.id;
    }
    if (values.caja !== null) {
      params += "&caja_id=" + values.caja.id;
    }
    setLoading(true);
    const result = await ModeloGetUrlJson(
      "factura",
      null,
      "movimientos-cajas-web",
      params
    );
    setLoading(false);

    if (result.error === false) {
      setAllData(result.comprobantes);
      setAllPagos(result.pagos);
      const tempTarjetas = result.pagos.filter(
        (p) => ["Dinero", "Efectivo"].indexOf(p.descripcion) < 0
      );
      setAllPagosTarjetas(tempTarjetas);
    }
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          marginBottom: 4,
        }}
      >
        <Typography variant="h6">Movimientos de cajas</Typography>
      </Box>
      <Grid
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "start",
          marginTop: 1,
          marginBottom: 5,
        }}
      >
        <Grid item xs={12} lg={3}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DateField
              id="fecha_desde"
              label="Desde"
              value={formik.values.fecha_desde}
              inputFormat="DD/MM/YYYY"
              format="DD/MM/YYYY"
              onChange={(value) => formik.setFieldValue("fecha_desde", value)}
              size="small"
              helperText={
                formik.touched.fecha_desde ? formik.errors.fecha_desde : ""
              }
              error={
                formik.touched.fecha_desde && Boolean(formik.errors.fecha_desde)
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={3}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DateField
              sx={{
                marginTop: { xs: 3, md: 0 },
                marginLeft: { xs: 0, md: 1 },
              }}
              id="fecha_hasta"
              label="Hasta"
              value={formik.values.fecha_hasta}
              inputFormat="DD/MM/YYYY"
              format="DD/MM/YYYY"
              onChange={(value) => formik.setFieldValue("fecha_hasta", value)}
              size="small"
              helperText={formik.errors.fecha_hasta}
              error={Boolean(formik.errors.fecha_hasta)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Autocomplete
            id="clinica"
            sx={{
              width: "17.8rem",
              marginTop: { xs: 3, md: 0 },
              marginLeft: { xs: 0, md: 1 },
            }}
            options={allCentros}
            value={formik.values.clinica}
            autoHighlight
            size="small"
            onChange={onClinicasChange}
            getOptionLabel={(option) => option.razonsocial}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: option.color,
                    marginBottom: 0.5,
                    marginRight: 1,
                    border: 1,
                    borderColor: "black",
                  }}
                >
                  {option.identificador}
                </Avatar>
                {option.razonsocial}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Clínica"
                error={formik.touched.clinica && Boolean(formik.errors.clinica)}
                helperText={formik.touched.clinica && formik.errors.clinica}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { justifyContent: "center" },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Autocomplete
            id="caja"
            options={cajasLista}
            autoHighlight
            size="small"
            onChange={onCajaChange}
            value={formik.values.caja}
            sx={{
              width: "17.8rem",
              marginTop: { xs: 3, md: 0 },
              marginLeft: { xs: 0, md: 1 },
            }}
            getOptionLabel={(option) => CapSentence(option.caja)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Caja"
                error={formik.touched.caja && Boolean(formik.errors.caja)}
                helperText={formik.touched.caja && formik.errors.caja}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { justifyContent: "center" },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <LoadingButton
            size="medium"
            onClick={formik.handleSubmit}
            loading={loading}
            loadingPosition="start"
            endIcon={<SearchIcon />}
            sx={{
              backgroundColor: "primary.main",
              ":hover": {
                bgcolor: "#475569", // theme.palette.primary.main
                color: "white",
              },
              width: 80,
              marginTop: { xs: 3, md: 0.5 },
              marginLeft: { xs: 0, md: 1 },
            }}
            variant="contained"
          >
            <span></span>
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
}
