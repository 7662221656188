import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DeleteModel from "./DeleteModel";
import ModalInformation from "./modals/ModalInformation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { CardContent } from "@mui/material";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useHistory } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { makeStyles } from "@mui/styles";
import RenderCustomTable from "./RenderCustomTable";
import crypto from "./Crypto";
import { hasPermission } from "./utils/Utils";

export default function OdontologosAgendas({ allCoes, searchmethod }) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [deleteCoeId, setDeleteCoeId] = useState(null);
  const history = useHistory();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const columns = [
    { field: "identificador", headerName: "Clínica", isClinic: true },
    { field: "especialidad", headerName: "Especialidad" },
    { field: "habilitado", headerName: "Activo" },
    { field: "dia_semana", headerName: "Dia" },
    { field: "turno", headerName: "Turno" },
    { field: "horario_desde", headerName: "Desde" },
    { field: "horario_hasta", headerName: "Hasta" },
  ];

  const actions = [
    {
      icon: ContentCopyIcon,
      handle: (row) => handleCopy(row),
      sx: { color: "primary.main" },
      requiredPermission: "ALTA CCENTROSODONTOLOGOSESPECIALIDADES",
    },
    {
      icon: EditIcon,
      handle: (row) => handleEdit(row),
      sx: { color: "primary.main" },
      requiredPermission: "MODIFICACION CCENTROSODONTOLOGOSESPECIALIDADES",
    },
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
      requiredPermission: "BAJA CCENTROSODONTOLOGOSESPECIALIDADES",
    },
  ];

  const iconColumns = {
    habilitado: (props) =>
      props?.value === 1 ? (
        <CheckCircleIcon
          sx={{
            color: "icongreen",
            fontSize: 24,
          }}
        />
      ) : (
        <CancelIcon
          sx={{
            color: "iconred",
            fontSize: 24,
          }}
        />
      ),
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const hideSuccessModal = () => {
    setModalMessage(null);
    setModalSuccessOpen(false);
  };

  const handleEdit = (row) => {
    const isAlta = false;
    history.push({
      pathname: "/insertupdatecoe",
      row,
      isAlta,
    });
  };
  function handleDelete(row) {
    setDeleteCoeId(row.id);
  }
  const handleCopy = (row) => {
    const isAlta = true;
    history.push({
      pathname: "/insertupdatecoe",
      row,
      isAlta,
    });
  };
  const handleNew = () => {
    const isAlta = true;
    history.push({
      pathname: "/insertupdatecoe",
      isAlta,
    });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {}, []);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          ml: -2,
          mr: -2,
          backgroundColor: "bgcards",
          marginBottom: 4,
          //       height: "100%",
        }}
      >
        <CardHeader
          avatar={<CalendarMonthIcon sx={{ color: "avatar", fontSize: 48 }} />}
          title={
            <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Agendas
              </Typography>
              {hasPermission(
                userRoles,
                "ALTA CCENTROSODONTOLOGOSESPECIALIDADES"
              ) && (
                <IconButton
                  aria-label="new"
                  onClick={handleNew}
                  sx={{ p: "1px", marginTop: -1 }}
                >
                  <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
                </IconButton>
              )}
            </Box>
          }
          subheader={<></>}
        />
        <CardContent
          sx={{
            ml: -2,
            mr: -2,
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ mb: 4, marginTop: -2 }}>
              <RenderCustomTable
                data={allCoes
                  .filter((p) => p.habilitado === 1)
                  .sort((a, b) => {
                    if (a.num_dia === b.num_dia) {
                      return a.turno < b.turno ? -1 : 1;
                    } else {
                      return a.num_dia < b.num_dia ? -1 : 1;
                    }
                  })}
                columns={columns}
                iconColumns={iconColumns}
                paddingLeft={0}
                paddingRight={0}
                actions={actions}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: -2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  bordeTop: 1,
                }}
              >
                <Typography variant="caption" sx={{ mt: 2, mr: 1 }}>
                  Deshabilitados
                </Typography>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandCircleDownIcon
                    sx={{ color: "primary.main", fontSize: 36 }}
                  />
                </ExpandMore>
              </Box>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Box sx={{ mt: 0, mb: 4, ml: { xs: -2, sm: -2 }, mr: -2 }}>
                  <Typography sx={{ fontSize: 16, mb: 1 }}>
                    Deshabilitados
                  </Typography>
                  <RenderCustomTable
                    data={allCoes
                      .filter((p) => p.habilitado === 0)
                      .sort((a, b) => {
                        if (a.num_dia === b.num_dia) {
                          return a.turno < b.turno ? -1 : 1;
                        } else {
                          return a.num_dia < b.num_dia ? -1 : 1;
                        }
                      })}
                    columns={columns}
                    iconColumns={iconColumns}
                    paddingLeft={0}
                    paddingRight={0}
                    actions={actions}
                  />
                </Box>
              </CardContent>
            </Collapse>
          </Container>
        </CardContent>
      </Card>
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalSuccessOpen && modalMessage !== null}
        hideModal={hideSuccessModal}
        message={modalMessage}
        tipo="success"
      />
      {deleteCoeId !== null && (
        <DeleteModel
          model={"centro-odontologo-especialidad"}
          modelid={deleteCoeId}
          okmethod={searchmethod}
          nullmethod={setDeleteCoeId}
        />
      )}
    </>
  );
}
