import React from "react";
import Button from "@mui/material/Button";

const MuiButton = ({
  onClick,
  text,
  width,
  textColor = "white",
  backColor = "#1976d2",
  paddingX = 1.5,
  paddingY = 0.6,
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        color: textColor,
        fontFamily: "Roboto",
        backgroundColor: backColor,
        ":hover": {
          bgcolor: "#475569", // theme.palette.primary.main
          color: "white",
        },
        textTransform: "capitalize",
        paddingX: paddingX,
        paddingY: paddingY,
        marginBottom: 1,
      }}
      variant="contained"
    >
      {text}
    </Button>
  );
};

export default MuiButton;
