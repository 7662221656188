import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { ModeloUpdate, ModeloPost } from "../ModeloCrud";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import crypto from "../Crypto";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ModalInformation from "../modals/ModalInformation";
import InsertPresupuestoItem from "./InsertPresupuestoItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { formatNumberES, CapSentenceArray, CapSentence } from "../utils/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DescriptionIcon from "@mui/icons-material/Description";
import SaveCancelButtons from "../SaveCancelButtons";
import Checkbox from "@mui/material/Checkbox";
import { hasPermission } from "../utils/Utils";

const validationSchema = yup.object({
  coe: yup.object().required("Seleccione un Odontólogo"),
  bonificacion: yup
    .number()
    .min(0, "La bonificación no puede ser menor a 0")
    .max(100, "La bonificación no puede ser mayor a 100"),
});

export default function InsertUpdatePresupuesto({
  presupuestorow,
  itemspresupuesto,
  buscarCoes,
  hideModal,
  buscarPresupuestos,
}) {
  const [allCoes, setAllCoes] = useState(null);
  const [myloading, setMyLoading] = React.useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const [newItems, setNewItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [piezasDentales, setPiezasDentales] = useState(null);
  const [nomencladores, setNomencladores] = useState(null);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const formik = useFormik({
    initialValues: {
      coe: null,
      bonificacion: presupuestorow !== null ? presupuestorow.bonificacion : 0.0,
      observaciones:
        presupuestorow !== null ? presupuestorow.observaciones : null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      GrabarPresupuesto();
    },
  });

  const ldToday = new Date();
  const lcDate =
    ldToday.getFullYear() +
    "-" +
    (ldToday.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    ldToday.getDate().toString().padStart(2, "0");

  function borrarItem(id) {
    setNewItems(newItems.filter((i) => i.id !== id));
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "2px",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function handleKeyPress(event) {
    if (event.target.type != "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  function updateSelectedItem(Row) {
    const oldValue = Row.aprobado;
    const index = newItems.findIndex((obj) => {
      return obj.id === Row.id;
    });
    if (index >= 0) {
      newItems[index].aprobado = oldValue === 1 ? 0 : 1;
    }
    var tempItems = [...newItems];
    setNewItems(tempItems);
  }

  async function GrabarPresupuesto() {
    if (newItems.length === 0) {
      setModalMessage("Ingrese al menos un Item.");
      setModalInformationOpen(true);
      return;
    }
    let user_id = crypto.decryptLocalStorage("user_id");
    let pacpre_id = crypto.decryptDataStorage("currentpacpreid");
    let coe_id = formik.values.coe.coe_id;
    let fecha_emision =
      presupuestorow !== null ? presupuestorow.fecha_emision : lcDate;
    let observaciones =
      formik.values.observaciones !== null
        ? formik.values.observaciones.trim()
        : "";
    let bonificacion = formik.values.bonificacion;
    const Data = [];
    const row = {};
    if (presupuestorow === null) {
      row["id"] = -1;
    } else {
      row["id"] = presupuestorow.id;
    }
    row["fecha_emision"] = fecha_emision;
    row["user_id_emision"] = user_id;
    row["centro_odontologo_especialidad_id"] = coe_id;
    row["paciente_prepaga_id"] = pacpre_id;
    let stencabezado =
      "id=-1" +
      "&fecha_emision=" +
      fecha_emision +
      "&user_id_emision=" +
      user_id +
      "&centro_odontologo_especialidad_id=" +
      coe_id +
      "&paciente_prepaga_id=" +
      pacpre_id;
    if (observaciones.length > 0) {
      stencabezado += "&observaciones=" + observaciones;
      row["observaciones"] = observaciones;
    } else {
      stencabezado += "&observaciones=";
      row["observaciones"] = "";
    }
    if (bonificacion > 0) {
      stencabezado += "&bonificacion=" + bonificacion;
      row["bonificacion"] = bonificacion;
    } else {
      stencabezado += "&bonificacion=0";
      row["bonificacion"] = 0;
    }
    //Items
    let stitems = "&items=";
    for (var i = 0; i < newItems.length; i++) {
      if (stitems.length > 7) {
        stitems += "|";
      }
      const itemaprobado =
        presupuestorow === null || presupuestorow.estado === "PROVISORIO"
          ? 0
          : newItems[i].aprobado;
      const itemid = presupuestorow === null ? -1 : newItems[i].idoriginal;
      const pieza =
        newItems[i].pieza_dental_id !== null ? newItems[i].pieza_dental_id : "";
      const caras = newItems[i].caras?.length > 0 ? newItems[i].caras : "";
      stitems +=
        itemid +
        ";" +
        newItems[i].alternativa +
        ";" +
        newItems[i].nomenclador_id +
        ";" +
        pieza +
        ";" +
        caras +
        ";" +
        itemaprobado +
        ";" +
        newItems[i].importe;
    }
    setMyLoading(true);

    let result = null;
    if (presupuestorow === null) {
      result = await ModeloPost(
        "presupuesto",
        0,
        "insert-update-presupuesto",
        stencabezado + stitems
      );
    } else {
      result = await ModeloPost(
        "presupuesto",
        presupuestorow.id,
        "insert-update-presupuesto",
        stencabezado + stitems
      );
    }
    if (result?.error === false) {
      buscarPresupuestos();
      handleClose();
    } else {
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
    setMyLoading(false);
  }

  const handleClose = () => {
    hideModal();
  };

  function searchCoes() {
    const coes = CapSentenceArray(crypto.decryptDataStorage("allcoes"), "coe");
    const nomencladores = CapSentenceArray(
      crypto.decryptDataStorage("nomencladores"),
      ["codigo_descripcion"]
    );
    setAllCoes(coes);
    setNomencladores(nomencladores);
    setPiezasDentales(crypto.decryptDataStorage("piezas_dentales"));
    if (presupuestorow !== null) {
      let selCoe = coes.filter((c) => c.coe_id === presupuestorow.coe_id);
      if (selCoe.length > 0) {
        formik.setFieldValue("coe", selCoe[0]);
      } else {
        let selCoe = coes.filter(
          (c) =>
            c.centro_id === presupuestorow.centro_id &&
            c.especialidad_id === presupuestorow.especialidad_id &&
            c.odontologo_id === presupuestorow.odontologo_id
        );
        if (selCoe.length > 0) {
          formik.setFieldValue("coe", selCoe[0]);
        }
      }
    }
    if (itemspresupuesto !== null) {
      itemspresupuesto.forEach((element, index, array) => {
        var row = {};
        row["aprobado"] = element.aprobado;
        row["id"] = element.id;
        row["alternativa"] = element.alternativa;
        row["codigo"] = element.codigo_nomenclador;
        row["nomenclador_id"] = element.nomenclador_id;
        row["descripcion"] = element.descripcion_nomenclador;
        row["pieza"] = element.numero_pieza_dental;
        row["pieza_dental_id"] = element.pieza_dental_id;
        row["caras"] = element.caras;
        row["ordenes"] = element.ordenes;
        row["importe"] = element.importe;
        row["idoriginal"] = element.id;
        newItems.push(row);
      });
      setNewItems(newItems);
    }

    return;
  }

  function busquedaCoes() {
    searchCoes();
  }

  const onCoeChange = (event, values) => {
    formik.setFieldValue("coe", values);
  };

  return (
    <>
      <div>
        {allCoes == null && buscarCoes && busquedaCoes()}
        {allCoes !== null && allCoes.length > 0 && (
          <form
            key="presupuesto-form"
            onSubmit={formik.handleSubmit}
            onKeyPress={handleKeyPress}
          >
            <Container maxWidth="lg" sx={{ marginTop: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: "avatar",
                    marginBottom: 2,
                    marginRight: 2,
                  }}
                >
                  <DescriptionIcon />
                </Avatar>
                <Typography sx={{ marginBottom: 2 }} variant="h6">
                  Encabezado
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                sx={{ borderBottom: 1, borderColor: "grey.400" }}
              >
                <Grid item xs={12} lg={8}>
                  <Autocomplete
                    id="coe"
                    options={allCoes}
                    autoHighlight
                    size="small"
                    onChange={onCoeChange}
                    value={formik.values.coe}
                    getOptionLabel={(option) => CapSentence(option.coe)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Odontólogo"
                        error={formik.touched.coe && Boolean(formik.errors.coe)}
                        helperText={formik.touched.coe && formik.errors.coe}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: { justifyContent: "center" },
                        }}
                      />
                    )}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.coe, inputValue, {
                        insideWords: true,
                      });
                      const parts = parse(option.coe, matches);
                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 800 : 400,
                                  //color: part.highlight ? "#0ea5e9" : "black",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4} sx={{ marginTop: -1 }}>
                  <TextField
                    id="bonificacion"
                    label="Bonificación"
                    value={formik.values.bonificacion}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.bonificacion
                        ? formik.errors.bonificacion
                        : ""
                    }
                    error={
                      formik.touched.bonificacion &&
                      Boolean(formik.errors.bonificacion)
                    }
                    margin="dense"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      readOnly: !hasPermission(
                        userRoles,
                        "BONIFICAR PRESUPUESTOS"
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <TextField
                    id="observaciones"
                    label="Nota"
                    multiline
                    rows={4}
                    value={formik.values.observaciones}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.observaciones
                        ? formik.errors.observaciones
                        : ""
                    }
                    error={
                      formik.touched.observaciones &&
                      Boolean(formik.errors.observaciones)
                    }
                    margin="dense"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Container>

            <Container
              maxWidth="lg"
              sx={{ borderBottom: 1, borderColor: "grey.400" }}
            >
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: "avatar",
                    marginTop: 2,
                    marginBottom: 0,
                    marginRight: 2,
                  }}
                >
                  <TableRowsIcon />
                </Avatar>
                <Typography sx={{ marginTop: 2, marginBottom: 0 }} variant="h6">
                  Items
                </Typography>
              </Box>
              <Box sx={{ m: 1, p: 1 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="right">Sel</StyledTableCell>
                        <StyledTableCell align="right">Id</StyledTableCell>
                        <StyledTableCell align="right">Id Or.</StyledTableCell>
                        <StyledTableCell align="right">Alt.</StyledTableCell>
                        <StyledTableCell align="left">Código</StyledTableCell>
                        <StyledTableCell align="left">
                          Descripción
                        </StyledTableCell>
                        <StyledTableCell align="left">Pieza</StyledTableCell>
                        <StyledTableCell align="left">Caras</StyledTableCell>
                        <StyledTableCell align="right">Importe</StyledTableCell>
                        <StyledTableCell align="left"></StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {newItems !== null &&
                        newItems
                          .sort((a, b) => (a.aprobado > b.aprobado ? -1 : 1))
                          .map((rowi) => (
                            <StyledTableRow hover tabIndex={-1} key={rowi.id}>
                              <TableCell>
                                {presupuestorow?.estado === "APROBADO" && (
                                  <>
                                    {rowi.aprobado !== 1 && (
                                      <i
                                        class="fa-regular fa-square fa-xl text-slate-800 mr-2 ml-2"
                                        onClick={() => updateSelectedItem(rowi)}
                                      ></i>
                                    )}
                                    {rowi.aprobado === 1 && (
                                      <i
                                        class="fa-regular fa-square-check fa-xl text-slate-800 mr-2 ml-2"
                                        onClick={() => updateSelectedItem(rowi)}
                                      ></i>
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <StyledTableCell
                                align="right"
                                component="th"
                                scope="row"
                              >
                                {rowi.id}
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                component="th"
                                scope="row"
                              >
                                {rowi.idoriginal}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {rowi.alternativa}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {rowi.codigo}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Box
                                  sx={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {rowi.descripcion.toLowerCase()}
                                </Box>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {rowi.pieza}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {rowi.caras}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {formatNumberES(rowi.importe, 2)}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {(rowi.ordenes === "FALSE" ||
                                  presupuestorow?.estado === "PROVISORIO") && (
                                  <DeleteIcon
                                    cursor="pointer"
                                    sx={{
                                      color: "primary.red",
                                      fontSize: 24,
                                    }}
                                    onClick={() => borrarItem(rowi.id)}
                                  />
                                )}
                                {rowi.ordenes === "TRUE" &&
                                  presupuestorow?.estado === "APROBADO" && (
                                    <>
                                      <i class="fa-solid fa-o text-sky-600"></i>
                                      <i class="fa-solid fa-t text-sky-600"></i>
                                    </>
                                  )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ marginTop: 2 }}>
                <InsertPresupuestoItem
                  newItems={newItems}
                  setNewItems={setNewItems}
                  setExpanded={setExpanded}
                />
              </Box>
            </Container>
            <SaveCancelButtons handleCancel={handleClose} loading={myloading} />
          </form>
        )}
      </div>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
    </>
  );
}
