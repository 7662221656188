import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import crypto from "../Crypto";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { CSVLink } from "react-csv";
import EventNoteIcon from "@mui/icons-material/EventNote";
import RenderCustomTable from "../RenderCustomTable";

const RenderHistoriaClinica = ({ alldata, showsearch = true }) => {
  const history = useHistory();
  const [filterText, setFilterText] = useState("");
  const [allTratamientos, setAllTratamientos] = useState(alldata);
  let [filterTratamientos, setFilterTratamientos] = useState([]);

  const columns = [
    { field: "fecha", headerName: "Fecha" },
    { field: "hora_desde", headerName: "Hora" },
    { field: "identificador", headerName: "Cli", isClinic: true },
    { field: "especialidad_corta", headerName: "Especialidad" },
    { field: "odontologo", headerName: "Odontólogo", capitalize: true },
    { field: "empresa", headerName: "Empresa" },
    { field: "codigo_nomenclador", headerName: "Código", isCodigoHc: true },
    { field: "descripcion", headerName: "Prestación", capitalize: true },
    { field: "pieza", headerName: "Dte." },
    { field: "caras", headerName: "Car." },
    { field: "notas", headerName: "Notas" },
  ];

  const actions = [
    {
      icon: EventNoteIcon,
      handle: (row) => handleGoToAgenda(row),
      sx: { color: "primary.main" },
    },
  ];

  const handleGoToAgenda = (row) => {
    if (
      row.centro_id.length > 0 &&
      row.especialidad_id.length > 0 &&
      row.odontologo_id.length > 0 &&
      row.fecha_usa.length > 0
    ) {
      crypto.encryptDataStorage("ags_centro_id", Number(row.centro_id));
      crypto.encryptDataStorage(
        "ags_especialidad_id",
        Number(row.especialidad_id)
      );
      crypto.encryptDataStorage("ags_odontologo_id", Number(row.odontologo_id));
      crypto.encryptDataStorage("ags_fecha", row.fecha_usa);
      history.push("/agendassimples");
    }
  };

  function onChangeSearch(e) {
    const filterText = e.target.value;
    setFilterText(filterText);
    FiltrarHc(filterText);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      FiltrarHc(filterText);
    }
  }

  function QuitarFiltro() {
    setFilterText("");
    setFilterTratamientos(allTratamientos);
  }

  function FiltrarHc(pFiltro) {
    if (pFiltro.length > 0) {
      filterTratamientos = allTratamientos.filter(
        (g) =>
          g.codigo_nomenclador.indexOf(pFiltro) >= 0 ||
          g.odontologo.toLowerCase().indexOf(pFiltro.toLowerCase()) >= 0 ||
          g.descripcion.toLowerCase().indexOf(pFiltro.toLowerCase()) >= 0
      );
      if (filterTratamientos.length <= 0) {
        filterTratamientos = [];
      }
    } else {
      filterTratamientos = allTratamientos;
    }
    let tempData = [...filterTratamientos];
    setFilterTratamientos(tempData);
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent:
              alldata !== null && alldata.length > 0 ? "space-between" : "end",
          }}
        >
          {alldata !== null && alldata.length > 0 && showsearch === true && (
            <div className="mt-4">
              <CSVLink data={alldata} separator={";"} filename={"HClinica.csv"}>
                <Box sx={{ display: "flex" }}>
                  <i class="fa-solid fa-file-csv fa-xl ml-1 mt-3 mr-2 text-emerald-600"></i>
                  <Typography variant="h6">Descargar </Typography>
                </Box>
              </CSVLink>
            </div>
          )}
        </Box>
        <RenderCustomTable
          data={alldata.sort((a, b) => (a.orden > b.orden ? 1 : -1))}
          columns={columns}
          paddingLeft={0}
          paddingRight={0}
          actions={actions}
          showSearch={showsearch}
        />
      </Container>
    </Box>
  );
};

export default RenderHistoriaClinica;
