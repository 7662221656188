import React from "react";
//import './assetss/css/App2.css';
//import "bootstrap/dist/css/bootstrap.css";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import CmAppBar from "./components/CmAppBar";
//import Navbar from './components/pages/Navbar';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import InsertUpdatePaciente from "./components/pacientes/InsertUpdatePaciente";
import InsertUpdateOdontologo from "./components/pages/InsertUpdateOdontologo";
import InsertUpdateCliente from "./components/pages/InsertUpdateCliente";
import InsertUpdateCoe from "./components/pages/InsertUpdateCoe";
import Clientes from "./components/pages/Clientes";
import Odontologos from "./components/pages/Odontologos";
import EditarPaciente from "./components/pacientes/EditarPaciente";
import PacientePresupuestos from "./components/pacientes/PacientePresupuestos";
import PacienteOrdenes from "./components/pacientes/PacienteOrdenes";
import PacienteFichados from "./components/pacientes/PacienteFichados";
import PacienteCtaCte from "./components/pacientes/PacienteCtaCte";
import PacientePlanTratamiento from "./components/pacientes/PacientePlanTratamiento";
import PacienteHClinica from "./components/pacientes/PacienteHClinica";
import PacienteEncuestas from "./components/pacientes/PacienteEncuestas";
import PacienteLPrecios from "./components/pacientes/PacienteLPrecios";
import PacienteAnamnesis from "./components/pacientes/PacienteAnamnesis";
import PacienteQuejas from "./components/pacientes/PacienteQuejas";
import PacienteImagenes from "./components/pacientes/PacienteImagenes";
import PacienteDiscapacidad from "./components/pacientes/PacienteDiscapacidad";
import FichadoStd from "./components/pages/FichadoStd";
import Home from "./components/pages/Home";
import Consultorios from "./components/Consultorios";
import Reportes from "./components/pages/Reportes";
import Procesos from "./components/pages/Procesos";
import ListasPreciosForm from "./components/pages/ListasPreciosForm";
import Pacientes from "./components/pacientes/Pacientes";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AgendasMultiples from "./components/agendas/AgendasMultiples";
import AgendasSimples from "./components/agendas/AgendasSimples";
import TurnosLibres from "./components/pages/TurnosLibres";
import GenerarAgendas from "./components/agendas/GenerarAgendas";
import BloquearAgendas from "./components/agendas/BloquearAgendas";
import CambiarAgendas from "./components/agendas/CambiarAgendas";
import RenderReport from "./components/pages/RenderReport";
import RecibirTrabajosLaboratorio from "./components/process/RecibirTrabajosLaboratorio";
import crypto from "./components/Crypto";
import { TopBarProvider } from "./components/contexts/TopBarContext";
import NotFoundPage from "./components/pages/NotFoundPage";
import PrivateRoute from "./components/PrivateRoute";

const theme = createTheme({
  palette: {
    primary: {
      main:
        crypto.decryptLocalStorage("primarymain") !== null
          ? crypto.decryptLocalStorage("primarymain")
          : "#1976d2",
      hover: "#475569",
      red: "#ef4444",
      green: "#22c55e",
      yellow: "#eab308",
    },
    secondary: {
      main: "#edf2ff",
    },
    bgmodal: "#f8fafc",
    avatar: "#475569",
    icongreen: "#10b981",
    iconred: "#ef4444",
    icondisabled: "#cbd5e1",
    iconyellow: "#f59e0b",
    bgavatarblue: "#f0f9ff",
    homebox: "#6b7280",
    bgcards: "#fafafa",
    cdisabled: "#475569",
    slate200: "#e2e8f0",
    amber800: "#ff8f00",
    lightgreen: "#dcfce7",
    lightyellow: "#fef9c3",
    lightred: "#fee2e2",
    mediumgrey: "#6b7280",
    Ayacucho: "#3b82f6",
    Belgrano: "#10b981",
    Ossos: "#f59e0b",
    blue100: "#dbeafe",
    blue50: "#eff6ff",
    red100: "#fee2e2",
    yellow100: "#fef9c3",
    buttonsubmenu: "#f252a7",
    pink: "#f252a7",
    drawericon: "#6b7280",
    drawertext: "#374151",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: `"Open Sans","Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        },
      },
    },
  },
});

function App() {
  // crypto.decryptLocalStorage("primarymain", "#1976d2");
  return (
    <TopBarProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route
              render={({ location }) => (
                <>
                  <CmAppBar />
                  <Switch location={location}>
                    <PrivateRoute path="/home" exact component={Home} />
                    <PrivateRoute path="/pacientes" component={Pacientes} />
                    <PrivateRoute
                      path="/agendasmultiples"
                      component={AgendasMultiples}
                    />
                    <PrivateRoute
                      path="/agendassimples"
                      component={AgendasSimples}
                    />
                    <PrivateRoute
                      path="/turnoslibres"
                      component={TurnosLibres}
                    />
                    <PrivateRoute
                      path="/generaragendas"
                      component={GenerarAgendas}
                    />
                    <PrivateRoute
                      path="/bloquearagendas"
                      component={BloquearAgendas}
                    />
                    <PrivateRoute
                      path="/cambiaragendas"
                      component={CambiarAgendas}
                    />
                    <PrivateRoute path="/clientes" component={Clientes} />
                    <PrivateRoute path="/odontologos" component={Odontologos} />
                    <PrivateRoute path="/reportes" component={Reportes} />
                    <PrivateRoute path="/procesos" component={Procesos} />
                    <PrivateRoute
                      path="/renderreport"
                      component={RenderReport}
                    />
                    <PrivateRoute
                      path="/insertupdatepaciente"
                      component={InsertUpdatePaciente}
                    />
                    <PrivateRoute
                      path="/insertupdatecliente"
                      component={InsertUpdateCliente}
                    />
                    <PrivateRoute
                      path="/insertupdateodontologo"
                      component={InsertUpdateOdontologo}
                    />
                    <PrivateRoute
                      path="/insertupdatecoe"
                      component={InsertUpdateCoe}
                    />
                    <PrivateRoute
                      path="/editarpaciente"
                      component={EditarPaciente}
                    />
                    <PrivateRoute
                      path="/pacientepresupuestos"
                      component={PacientePresupuestos}
                    />
                    <PrivateRoute
                      path="/pacienteordenes"
                      component={PacienteOrdenes}
                    />
                    <PrivateRoute
                      path="/pacientefichados"
                      component={PacienteFichados}
                    />
                    <PrivateRoute
                      path="/pacientectacte"
                      component={PacienteCtaCte}
                    />
                    <PrivateRoute
                      path="/pacienteplantratamiento"
                      component={PacientePlanTratamiento}
                    />
                    <PrivateRoute
                      path="/pacientehclinica"
                      component={PacienteHClinica}
                    />
                    <PrivateRoute
                      path="/pacienteencuestas"
                      component={PacienteEncuestas}
                    />
                    <PrivateRoute
                      path="/pacientelprecios"
                      component={PacienteLPrecios}
                    />
                    <PrivateRoute
                      path="/pacienteanamnesis"
                      component={PacienteAnamnesis}
                    />
                    <PrivateRoute
                      path="/pacientequejas"
                      component={PacienteQuejas}
                    />
                    <PrivateRoute
                      path="/pacientediscapacidad"
                      component={PacienteDiscapacidad}
                    />
                    <PrivateRoute
                      path="/imagenespaciente"
                      component={PacienteImagenes}
                    />
                    <PrivateRoute
                      path="/listaspreciosform"
                      component={ListasPreciosForm}
                    />
                    <PrivateRoute path="/fichadostd" component={FichadoStd} />
                    <PrivateRoute
                      path="/consultorios"
                      component={Consultorios}
                    />
                    <PrivateRoute
                      path="/recibirtrabajoslaboratorios"
                      component={RecibirTrabajosLaboratorio}
                    />
                    <PrivateRoute component={NotFoundPage} />
                  </Switch>
                </>
              )}
            />
          </Switch>
        </Router>
      </ThemeProvider>
    </TopBarProvider>
  );
}

export default App;
