import React, { useRef, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalNota from "../modals/ModalNota";
import Tooltip from "@mui/material/Tooltip";
import ModalConfirmation from "../modals/ModalConfirmation";
import ModalInformation from "../modals/ModalInformation";
import { ModeloGetUrl, ModeloPost } from "../ModeloCrud";
import crypto from "../Crypto";
import * as moment from "moment";
import ModalInformationTurno from "../modals/ModalInformationTurno";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useHistory } from "react-router-dom";
import { StyledTableCell, StyledTableRow, hasPermission } from "../utils/Utils";
import PacienteCitasAnteriores from "./PacienteCitasAnteriores";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import HowToRegIcon from "@mui/icons-material/HowToReg";

export default function PacientesNotas({
  allPendientes,
  setallPendientes,
  allAnteriores,
}) {
  const [editarPacienteNota, setEditarPacienteNota] = useState(false);
  const [insertarPacienteNota, setInsertarPacienteNota] = useState(false);
  const [selectedNota, setSelectedNota] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUltimos, setLoadingUltimos] = useState(false);
  const [updateOk, setUpdateOk] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteNotaId, setDeleteNotaId] = useState(0);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  let currentPatient = crypto.decryptDataStorage("currentpatient");
  let limitdate = new Date();
  limitdate.setMonth(limitdate.getMonth() - 3);
  let lcLimitDate = limitdate.toISOString().split("T")[0];
  const [ingresarId, setIngresarId] = useState(0);
  const [quitarIngresoId, setQuitarIngresoId] = useState(0);
  const [desasignarId, setDesasignarId] = useState(0);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const [ultimosTratamientos, setUltimosTratamientos] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const tableRef = useRef();
  const [valueDias, setValueDias] = React.useState(720);
  const [modalTurnoInfoOpen, setModalTurnoInfoOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const history = useHistory();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  var ldToday = new Date();
  var lcDate =
    ldToday.getFullYear() +
    "-" +
    (ldToday.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    ldToday.getDate().toString().padStart(2, "0");

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };

  const hideTurnoInfoModal = () => {
    setModalTurnoInfoOpen(false);
  };

  function handleClickTurnoInfo(row) {
    setSelectedRow(row);
    setModalTurnoInfoOpen(true);
  }

  const hidePacienteNota = () => {
    setEditarPacienteNota(false);
    setInsertarPacienteNota(false);
  };

  const handleGoToAgenda = (row) => {
    crypto.encryptDataStorage("ags_centro_id", row.centro_id);
    crypto.encryptDataStorage("ags_especialidad_id", row.especialidad_id);
    crypto.encryptDataStorage("ags_odontologo_id", row.odontologo_id);
    crypto.encryptDataStorage("ags_fecha", row.fecha_usa);
    history.push("/agendassimples");
  };

  function InfoPendiente(id) {}

  function DesasignarTurno(id) {
    setDesasignarId(id);
    setDisplayConfirmationModal(true);
    setModalMessage("Seguro quiere Desasignar el Turno ?");
  }

  async function buscarTurnosPendientesDia() {
    setLoading(true);
    setallPendientes(null);
    let currentpatient = crypto.decryptDataStorage("currentpatient");
    let result = await ModeloGetUrl(
      "paciente",
      currentpatient,
      "turnos-pendientes",
      null
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        const TPendientes = result.listado.sort((a, b) =>
          a.fecha_usa > b.fecha_usa ? 1 : -1
        );
        crypto.encryptDataStorage("dataTPendientes", TPendientes);
        setallPendientes(TPendientes);
      }
    } else {
      setModalMessage(
        result.mensaje != null
          ? result.mensaje
          : result.errormessage != null
          ? result.errormessage
          : "Error desconocido."
      );
      setModalWarningOpen(true);
    }
    return;
  }

  async function buscarUltimosTratamientos() {
    setLoadingUltimos(true);
    setUltimosTratamientos(null);
    const Today = new Date();
    const desde = moment(Today)
      .subtract(valueDias, "days")
      .format("YYYY-MM-DD");
    const hasta = moment(Today).format("YYYY-MM-DD");
    let currentpatient = crypto.decryptDataStorage("currentpatient");
    const params = "paciente_id=" + currentpatient;
    const result = await ModeloGetUrl(
      "paciente",
      null,
      "historia-clinica",
      params
    );
    setLoadingUltimos(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        const ultimos = result.listado
          .filter((g) => g.fecha_usa >= desde)
          .filter((g) => g.fecha_usa <= hasta)
          .sort((a, b) => (a.fecha_usa > b.fecha_usa ? -1 : 1));

        setUltimosTratamientos(ultimos);
      }
    } else {
      setModalMessage(
        result.mensaje != null
          ? result.mensaje
          : result.errormessage != null
          ? result.errormessage
          : "Error desconocido."
      );
      setModalWarningOpen(true);
    }
    return;
  }

  async function Desasignar() {
    if (desasignarId > 0) {
      setLoading(true);
      let result = await ModeloPost("turno", desasignarId, "liberar", null);
      setLoading(false);
      if (result.error === false) {
        buscarTurnosPendientesDia();
      } else {
        setModalMessage(
          result.mensaje != null
            ? result.mensaje
            : result.errormessage != null
            ? result.errormessage
            : "Error desconocido."
        );
        setModalWarningOpen(true);
      }
      setDisplayConfirmationModal(false);
      setDesasignarId(0);
      setLoading(false);
    }
  }

  function Confirmacion() {
    if (modalMessage === "Seguro quiere Desasignar el Turno ?") {
      Desasignar();
    } else {
      if (ingresarId > 0) {
        Ingresar();
      } else if (quitarIngresoId > 0) {
        QuitarIngreso();
      }
    }
  }

  function IngresarPaciente(id, HoraIngreso) {
    if (HoraIngreso.length === 0) {
      setIngresarId(id);
      setDisplayConfirmationModal(true);
      setModalMessage("Seguro quiere Ingresar el Paciente ?");
    } else {
      setQuitarIngresoId(id);
      setDisplayConfirmationModal(true);
      setModalMessage(
        <>
          {"El paciente ingresó a las " + HoraIngreso.slice(0, 6) + " horas."}
          <br />
          <br />
          {"¿Quiere quitarlo?"}
        </>
      );
    }
  }

  async function Ingresar() {
    if (ingresarId > 0) {
      setLoading(true);
      let result = await ModeloPost(
        "turno",
        ingresarId,
        "ingresar/paciente-presente",
        null
      );
      setLoading(false);
      if (result.error === false) {
        buscarTurnosPendientesDia();
      } else {
        setModalMessage(
          result.mensaje != null
            ? result.mensaje
            : result.errormessage != null
            ? result.errormessage
            : "Error desconocido."
        );
        setModalWarningOpen(true);
      }
      setDisplayConfirmationModal(false);
      setIngresarId(0);
      setLoading(false);
    }
  }

  async function QuitarIngreso() {
    if (quitarIngresoId > 0) {
      setLoading(true);
      let result = await ModeloPost(
        "turno",
        quitarIngresoId,
        "quitar/paciente-presente",
        null
      );
      setLoading(false);
      if (result.error === false) {
        buscarTurnosPendientesDia();
      } else {
        setModalMessage(
          result.mensaje != null
            ? result.mensaje
            : result.errormessage != null
            ? result.errormessage
            : "Error desconocido."
        );
        setModalWarningOpen(true);
      }
      setDisplayConfirmationModal(false);
      setQuitarIngresoId(0);
      setLoading(false);
    }
  }

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded === true) {
      buscarUltimosTratamientos();
    }
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow key={row.id}>
          <StyledTableCell width="5px" component="th" scope="row">
            {row.fecha}
          </StyledTableCell>
          <StyledTableCell align="left" width="5%">
            {row.codigo_nomenclador}
          </StyledTableCell>
          <StyledTableCell width="2%">{row.pieza}</StyledTableCell>
          <StyledTableCell width="2%">{row.caras}</StyledTableCell>
          <StyledTableCell width="10%">
            <Box sx={{ textTransform: "capitalize" }}>
              {row.descripcion.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell width="10%">
            <Box sx={{ textTransform: "capitalize" }}>
              {row.odontologo.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell width="66%">{row.notas}</StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <Card
        sx={{
          backgroundColor: "bgcards",
          ml: { xs: -2, md: -3 },
          mr: { xs: -2, md: -2 },
        }}
      >
        <CardHeader
          avatar={<UpdateRoundedIcon sx={{ color: "avatar", fontSize: 48 }} />}
          title={<Typography sx={{ fontSize: 22 }}>Próximas Citas</Typography>}
          subheader=""
        />
        <CardContent>
          <TableContainer component={Paper}>
            <Table
              ref={tableRef}
              sx={{ minWidth: 100 }}
              aria-label="customized table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Fecha</StyledTableCell>
                  <StyledTableCell>Cli</StyledTableCell>
                  <StyledTableCell>Especialidad</StyledTableCell>
                  <StyledTableCell>Odontólogo</StyledTableCell>
                  <StyledTableCell>Motivo</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ textTransform: "capitalize" }}>
                {allPendientes !== null &&
                  allPendientes.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={{
                        backgroundColor:
                          row.habilitado_turnos === 0 ? "#fecaca" : "white",
                      }}
                    >
                      <TableCell>
                        {hasPermission(userRoles, "ASIGNAR TURNOS") &&
                          row.fecha_usa === lcDate && (
                            <Tooltip
                              title={
                                row.hora_ingreso_clinica !== ""
                                  ? "Quitar Ingreso"
                                  : "Ingresar Paciente"
                              }
                            >
                              <HowToRegIcon
                                cursor="pointer"
                                onClick={() =>
                                  IngresarPaciente(
                                    row.id,
                                    row.hora_ingreso_clinica
                                  )
                                }
                                sx={{
                                  color:
                                    row.hora_ingreso_clinica !== ""
                                      ? "primary.green"
                                      : "#64748b",
                                  fontSize: 32,
                                  mr: -3,
                                }}
                              />
                            </Tooltip>
                          )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.fecha_hora}
                      </TableCell>
                      <TableCell>
                        <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            bgcolor: row.clinica_color,
                            marginBottom: 0.5,
                            border: 1,
                            borderColor: "black",
                          }}
                        >
                          {row.clinica_avatar}
                        </Avatar>
                      </TableCell>
                      <TableCell>{row.especialidad_corto}</TableCell>
                      <TableCell>{row.odontologo.toLowerCase()}</TableCell>
                      <TableCell>{row.motivo.toLowerCase()}</TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title={"Información del turno"}>
                          <InfoIcon
                            cursor="pointer"
                            onClick={() => handleClickTurnoInfo(row)}
                            sx={{
                              color: "primary.main",
                              fontSize: 24,
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title={"Ir a la agenda"}>
                          <EventNoteIcon
                            cursor="pointer"
                            onClick={() => handleGoToAgenda(row)}
                            sx={{
                              color: "primary.main",
                              fontSize: 24,
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {hasPermission(userRoles, "DESASIGNAR TURNOS") && (
                          <Tooltip title={"Desasignar el turno"}>
                            <DeleteIcon
                              cursor="pointer"
                              onClick={() => DesasignarTurno(row.id)}
                              sx={{
                                color: "primary.red",
                                fontSize: 24,
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Box sx={{ marginTop: 2 }}>
        <PacienteCitasAnteriores alldata={allAnteriores} />
      </Box>

      {editarPacienteNota && (
        <ModalNota
          row={selectedNota}
          showModal={editarPacienteNota}
          hideModal={hidePacienteNota}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
        />
      )}
      {insertarPacienteNota && (
        <ModalNota
          row={null}
          showModal={insertarPacienteNota}
          hideModal={hidePacienteNota}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
        />
      )}
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={Confirmacion}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      {modalTurnoInfoOpen && (
        <ModalInformationTurno
          clinica={selectedRow.clinica}
          especialidad={selectedRow.especialidad}
          especialidad_id={selectedRow.especialidad_id}
          odontologo={selectedRow.odontologo}
          fecha={selectedRow.fecha}
          hora={selectedRow.hora}
          direccion={selectedRow.clinica_domicilio}
          tipoturno={selectedRow.tipo_turno}
          showModal={modalTurnoInfoOpen}
          hideModal={hideTurnoInfoModal}
        />
      )}
    </>
  );
}
