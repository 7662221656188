/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import crypto from "../Crypto";
import { Box, Button, TextField, InputAdornment } from "@mui/material";
import RenderCustomTable from "../RenderCustomTable";
import SearchIcon from "@mui/icons-material/Search";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ModalTarjetasCoeficiente({ showModal, hideModal }) {
  const [amount, setAmount] = useState("");
  const [feeCoefficients, setFeeCoefficients] = useState({});
  const [groupedTotals, setGroupedTotals] = useState({});
  const [showTable, setShowTable] = useState(false);
  const tablafull = crypto.decryptDataStorage("tablafull");

  useEffect(() => {
    setGroupedTotals({});
    buscarPlanesTarjeta();
  }, []);

  const handleClose = () => {
    setGroupedTotals({});
    setAmount("");
    hideModal();
  };

  const columns = [
    { field: "payments", headerName: "Cuotas", rigthAign: true },
    { field: "coefficient", headerName: "Coeficiente" },
    {
      field: "monthlyPayment",
      headerName: "Valor Cuota",
      formatNumber: true,
      rigthAign: true,
    },
    {
      field: "total",
      headerName: "Total",
      formatNumber: true,
      rigthAign: true,
    },
  ];

  const logos = {
    AMERICAN: "img/amex.png",
    VISA: "img/visa.png",
    MASTERCARD: "img/master.png",
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const renderTable = () => {
    return (
      <Box>
        {Object.keys(groupedTotals).map((brand) => (
          <div key={brand}>
            <img
              src={groupedTotals[brand][0].logo}
              alt={`${brand} logo`}
              style={{ width: "100px", margin: "20px" }}
            />
            <RenderCustomTable
              data={groupedTotals[brand]}
              columns={columns}
              paddingLeft={0}
              paddingRight={0}
            />
          </div>
        ))}
      </Box>
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      calculateTotals();
    }
  };

  function buscarPlanesTarjeta() {
    const planes = tablafull
      .filter(
        (obj) =>
          obj.codigo_tabla.slice(0, 7) === "PLANES_" &&
          (obj.descripcion.toLowerCase().includes("visa") ||
            obj.descripcion.toLowerCase().includes("mastercard") ||
            obj.descripcion.toLowerCase().includes("american"))
      )
      .sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))
      .map((obj) => ({
        tarjeta: obj.descripcion,
        cuotas: obj.cuotas,
        coeficiente: obj.coeficiente,
      }));
    const data = planes;
    const coefficients = {};
    data.forEach((item) => {
      const brandName = item.tarjeta.split(" ")[0]; // Extract the brand name from the 'tarjeta' field
      if (!coefficients[brandName]) {
        coefficients[brandName] = {
          logo: logos[brandName.toUpperCase()] || "", // Get the logo from the logos object
          coefficients: {},
        };
      }
      coefficients[brandName].coefficients[item.cuotas] = parseFloat(
        item.coeficiente
      );
    });
    setFeeCoefficients(coefficients);
  }

  const calculateTotals = () => {
    setShowTable(false);
    if (amount.length === 0) {
      return;
    }
    const allTotals = Object.keys(feeCoefficients)
      .map((brand) => {
        const selectedBrand = feeCoefficients[brand];
        const coefficients = selectedBrand.coefficients;
        const totalCalculations = Object.keys(coefficients).map((payments) => {
          const coefficient = coefficients[payments];
          const total = (amount * coefficient).toFixed(2);
          const monthlyPayment = (total / payments).toFixed(2);
          return {
            brand,
            payments,
            coefficient,
            monthlyPayment,
            total,
            logo: selectedBrand.logo,
          };
        });
        return totalCalculations;
      })
      .flat();
    const tempTotals = allTotals.reduce((acc, total) => {
      if (!acc[total.brand]) {
        acc[total.brand] = [];
      }
      acc[total.brand].push(total);
      return acc;
    }, {});
    setGroupedTotals(tempTotals);
    if (Object.keys(tempTotals).length > 0) {
      setShowTable(true);
    }
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, row, ...other } = props;

    return (
      <DialogTitle
        sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
        {...other}
      >
        <i class="fa-solid fa-info text-white fa-xl p-4 m-2 "></i>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <Box>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth="md"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
          >
            Calculadora de cuotas
          </BootstrapDialogTitle>
          <DialogContent className="content" dividers>
            <div>
              <Box sx={{ display: "flex" }}>
                <TextField
                  autoFocus
                  type="number"
                  value={amount}
                  size="small"
                  onChange={handleAmountChange}
                  label="Total a pagar con tarjeta"
                  variant="outlined"
                  margin="normal"
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={calculateTotals}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </div>
            {showTable && renderTable()}
          </DialogContent>
          <DialogActions className="actions">
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              loadingPosition="end"
              sx={{
                backgroundColor: "primary.main",
                ":hover": {
                  bgcolor: "#475569", // theme.palette.primary.main
                  color: "white",
                },
                textTransform: "capitalize",
              }}
            >
              Salir
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Draggable>
    </Box>
  );
}
