import * as moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import loadingGif from "../../assetss/img/loading_64.gif";
import ModalConfirmation from "../modals/ModalConfirmation";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";
import { ModeloPost } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/joy";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import crypto from "../Crypto";

export function CapSentence(text) {
  if (text === null || text.length === 0) {
    return "";
  }
  if (!isNaN(text)) {
    return text;
  }
  try {
    let wordsArray = text
      .toLowerCase()
      .split(" ")
      .filter((word) => word.length > 0); // Remove empty strings caused by multiple spaces
    let capsArray = wordsArray.map((word) => {
      return word.replace(word[0], word[0].toUpperCase());
    });
    return capsArray.join(" ");
  } catch (error) {
    return text;
  }
}

export function CapSentenceArray(array, fields) {
  if (array?.length === 0 || fields?.length === 0) {
    return array;
  }
  for (let i = 0; i < array?.length; i++) {
    if (typeof fields === "object") {
      for (let j = 0; j < fields?.length; j++) {
        let myField = array[i][fields[j]];
        myField = myField.toString().toLowerCase();
        array[i][fields[j]] = CapSentence(myField);
      }
    } else {
      array[i][fields] = CapSentence(array[i][fields]);
    }
  }
  return array;
}

export function highlightedText(pText, pHighlightText, pColor = "#1976d2") {
  try {
    if (typeof pText !== "undefined" && pText !== null) {
      const matches = match(pText, pHighlightText, {
        insideWords: true,
      });
      const parts = parse(pText, matches);
      return (
        <Box sx={{ textTransform: "capitalize", marginTop: 0.5 }}>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 500 : 400,
                color: part.highlight ? "white" : "black",
                backgroundColor: part.highlight ? pColor : null,
              }}
            >
              {part.text}
            </span>
          ))}
        </Box>
      );
    } else {
      return pText;
    }
  } catch (error) {
    return pText;
  }
}

export function sinPermisos(message) {
  return (
    <Box sx={{ display: "flex", marginLeft: 4, gap: 2 }}>
      <DoNotDisturbOnIcon sx={{ color: "red" }} />
      <Typography variant="h6">{message}</Typography>
    </Box>
  );
}

export const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
};

export function TableHtmlStringFromJson(data, fields, names, tableheader) {
  if (
    data === null ||
    fields === null ||
    names === null ||
    tableheader === null ||
    data.length === 0 ||
    fields.length === 0 ||
    names.length === 0 ||
    tableheader.length === 0
  ) {
    return null;
  }
  // var html = '<table id="mitabla" className="display responsive nowrap " style={{ width: "100%" }}>'
  let html = tableheader;
  html += "<thead><tr>";
  for (var key = 0, size = names.length; key < size; key++) {
    html += "<th>" + names[key] + "</th>";
  }
  html += "</tr></thead><tbody>";
  for (var row = 0, size1 = data.length; row < size1; row++) {
    html += "<tr>";
    for (var col = 0, size2 = names.length; col < size2; col++) {
      let MyText = data[row][fields[col]];
      if (
        MyText !== null &&
        MyText.length >= 10 &&
        MyText.substring(4, 5) === "-" &&
        MyText.substring(7, 8) === "-"
      ) {
        MyText = moment(MyText.substring(0, 10)).format("DD/MM/YYYY");
      } else if (isNaN(MyText) && MyText.length > 3) {
        MyText = CapSentence(data[row][fields[col]]);
      } else if (
        MyText == null ||
        MyText === "0.00" ||
        MyText === "0" ||
        MyText === "00" ||
        MyText === "000" ||
        MyText === 0
      ) {
        MyText = "";
      }
      html += "<td>" + MyText + "</td>";
    }
    html += "</tr>";
  }
  html += "</tbody></table>";
  return html;
}

export function FormatHorario45(text) {
  if (text === null || text.length < 4) {
    return "";
  } else {
    return text.substring(0, 2) + ":" + text.substring(2, 4);
  }
}

export function formatNumberES(n, d = 0) {
  n = isNaN(n)
    ? 0
    : new Intl.NumberFormat("de-DE").format(parseFloat(n).toFixed(d));
  if (d > 0) {
    // Obtenemos la cantidad de decimales que tiene el numero
    const decimals = n.indexOf(",") > -1 ? n.length - 1 - n.indexOf(",") : 0;

    // añadimos los ceros necesios al numero
    n = decimals === 0 ? n + "," + "0".repeat(d) : n + "0".repeat(d - decimals);
  }
  return n;
}

export function RandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function TraduccionError(lcError) {
  let lcRet = lcError;
  let lbSeguir = true;
  let ClassError = "";
  if (lcError === "No se Encontraron Datos.") {
    lcRet = lcError;
    return lcRet;
  }
  if (lcError === "Error en el servidor remoto: (401) No autorizado.") {
    lcRet = lcError;
    return lcRet;
  }
  if (
    lcError.search("Integrity constraint violation") > 0 &&
    lcError.search("Duplicate entry") > 0 &&
    lcError.search("for key") > 0
  ) {
    let lnDup, lnFor, lnLen;
    lnDup = lcError.search("Duplicate entry");
    lnFor = lcError.search("for key");
    lnLen = lnFor - lnDup - 16;

    lcRet = lcError.substring(lnDup + 16, lnFor - 1);

    lcRet = "Clave Unica Duplicada: " + lcRet.trim();
    lbSeguir = false;
  }

  if (
    lbSeguir === true &&
    lcError.search("Error ! [") >= 0 &&
    lcError.search("{") >= 0 &&
    lcError.search(" }") >= 0
  ) {
    lcError = lcError.Replace('"', "");
    let lnllaveini, lnllavefin, lnLen;
    lnllaveini = lcError.search("{");
    lnllavefin = lcError.search("}");
    lnLen = lnllavefin - lnllaveini - 1;
    lcRet = lcError.Substring(lnllaveini + 1, lnllavefin - 1);
    lcRet = lcRet.Replace("is required", "NO puede ser vacío");
    lcRet = lcRet.Replace("is invalid", "Es Inválido.");
    lcRet = lcRet.Replace("selected", "");
    lcRet = lcRet.Replace("error:", "");
    lcRet = lcRet.Replace("field", "");
    lcRet = lcRet.Replace("The", "El campo ");
    lcRet = lcRet.trim();
    lbSeguir = false;
  }
  if (
    lbSeguir == true &&
    (lcError.toLowerCase().search("is required") >= 0) |
      (lcError.toLowerCase().search("is invalid") >= 0)
  ) {
    lcError = lcError.Replace('"', "");
    let lnllaveini, lnllavefin, lnLen;
    lnllaveini = lcError.search("{");
    lnllavefin = lcError.search("}");
    lnLen = lnllavefin - lnllaveini - 1;
    lcRet = lcError.Substring(lnllaveini + 1, lnllavefin - 1);
    lcRet = lcRet.Replace("is required", "NO puede ser vacío");
    lcRet = lcRet.Replace("is invalid", "Es Inválido.");
    lcRet = lcRet.Replace("selected", "");
    lcRet = lcRet.Replace("error:", "");
    lcRet = lcRet.Replace("field", "");
    lcRet = lcRet.Replace("The", "El campo ");
    lcRet = lcRet.trim();
    lbSeguir = false;
  }
  if (
    lbSeguir == true &&
    lcError.toLowerCase().search("integrity constraint violation") >= 0 &&
    lcError.toLowerCase().search("foreign key") >= 0
  ) {
    lcRet =
      "Problemas con la Integridad Referencial." +
      +"\n" +
      "Existen claves foráneas relacionadas.";
    lbSeguir = false;
  }
  if (lbSeguir == true) {
    lcRet = lcRet
      .Replace("[", "")
      .Replace("]", "")
      .Replace("{", "")
      .Replace("}", "");
  }
  return lcRet;
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  if (string === null) {
    return null;
  }

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const getColorFromString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const vibrantFactor = 1; // Adjust this factor to control the vibrancy of the color
  const saturation = 100; // Adjust this value to control the saturation of the color
  const lightness = 12; // Adjust this value to control the darkness/lightness of the color

  const h = hash % 360;
  const s = saturation;
  const l = lightness + vibrantFactor * 50; // Shift lightness towards the brighter side

  const color = `hsl(${h}, ${s}%, ${l}%)`;

  return color;
};

const isDarkFromString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const vibrantFactor = 0.8; // Adjust this factor to control the vibrancy of the color
  const saturation = 90; // Adjust this value to control the saturation of the color
  const lightness = 10; // Adjust this value to control the darkness/lightness of the color

  const hue = hash % 360;
  const s = saturation;
  const l = lightness + vibrantFactor * 50; // Shift lightness towards the brighter side

  const darkHues = {
    blue: [200, 280], // Adjust these values as needed
    purple: [300, 320],
    magenta: [320, 360],
    red: [0, 40], // Red hue can also be considered dark
  };

  // Normalize hue value to [0, 360] range

  // Check if hue falls within any of the dark hue ranges
  for (const [color, [start, end]] of Object.entries(darkHues)) {
    if (
      (start <= hue && hue <= end) ||
      (start >= end && (hue >= start || hue <= end))
    ) {
      return true; // Color is considered dark
    }
  }
  // Determine if the color is dark or light based on luminance
  return false;
};

function stringAvatar(name, size = 48) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: size,
      height: size,
      fontSize: size / 2, // Tamaño de la letra
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

function stringAvatar2(name, size = 72) {
  const bgcolor = getColorFromString(name);
  const isdark = false;

  return {
    sx: {
      bgcolor: bgcolor,
      width: size,
      height: size,
      color: isdark ? "white" : "black",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[0][1]}`,
  };
}

export function LetterAvatar(fullName, size = 48) {
  return (
    fullName !== null && (
      <Avatar
        sx={{ width: size, height: size }}
        {...stringAvatar(fullName, size)}
      />
    )
  );
}

export function fotoAvatar(avatar, size = 48) {
  return (
    avatar !== null && (
      <Avatar
        alt="Foto"
        src={"data:image/jpg;base64," + avatar}
        sx={{
          width: size,
          height: size,
          justifyContent: "center",
          boxShadow: 24,
        }}
      />
    )
  );
}

export function LetterAvatar2(fullName) {
  return (
    fullName !== null &&
    fullName.length > 1 && <Avatar {...stringAvatar2(fullName)} />
  );
}

export async function grabarFoto(
  imgSrc,
  currentpatient,
  folder,
  setLoading,
  extension
) {
  if (imgSrc === null) {
    return "Error:Debe enviar una foto.";
  }
  const dateObj = new Date();
  let year = dateObj.getFullYear();
  let month = dateObj.getMonth();
  month = ("0" + (month + 1)).slice(-2);
  let day = dateObj.getDate();
  day = ("0" + day).slice(-2);
  let hour = dateObj.getHours();
  hour = ("0" + hour).slice(-2);
  let minute = dateObj.getMinutes();
  minute = ("0" + minute).slice(-2);
  const fcreacion = year + "-" + month + "-" + day + " " + hour + ":" + minute;
  var fotoid = RandomString(20);
  const Data = {
    documento: currentpatient.nro_documento,
    fecha_creacion: fcreacion,
    carpeta:
      folder + "/" + currentpatient.nro_documento.toString().slice(0, 2) + "/",
    descripcion:
      folder === "pacientes" ? "Foto Paciente" : "Imagen desde el programa",
    extension: extension,
    bucket: folder,
    size_kb: "10",
    nombre_original: "img_" + fotoid + "." + extension,
    archivo: imgSrc,
  };
  if (setLoading !== null) {
    setLoading(true);
  }
  try {
    let result = await ModeloPost(
      "paciente-imagenes-adjuntos/crear",
      null,
      null,
      Data
    );
    if (setLoading !== null) {
      setLoading(false);
    }
    return result;
  } catch (e) {
    if (setLoading !== null) {
      setLoading(false);
    }
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function subirLogoClientes(
  imgSrc,
  currentclient,
  setLoading,
  extension
) {
  if (imgSrc === null) {
    return "Error:Debe enviar una foto.";
  }

  const Data = {
    archivo: imgSrc,
    extension: extension,
  };
  if (setLoading !== null) {
    setLoading(true);
  }
  try {
    let result = await ModeloPost(
      "prepaga",
      currentclient.id,
      "subir-logo",
      Data
    );
    if (setLoading !== null) {
      setLoading(false);
    }
    return result;
  } catch (e) {
    if (setLoading !== null) {
      setLoading(false);
    }
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

export async function subirFotoUsuario(
  imgSrc,
  currentUser,
  setLoading,
  extension,
  carpeta
) {
  if (imgSrc === null) {
    return "Error:Debe enviar una foto.";
  }

  const Data = {
    archivo: imgSrc,
    extension: extension,
    carpeta: carpeta,
  };
  if (setLoading !== null) {
    setLoading(true);
  }
  try {
    let result = await ModeloPost("user", currentUser.id, "subir-foto", Data);
    if (setLoading !== null) {
      setLoading(false);
    }
    return result;
  } catch (e) {
    if (setLoading !== null) {
      setLoading(false);
    }
    const ret = {
      error: true,
      mensaje: e.message,
    };
    const jret = JSON.stringify(ret);
    return JSON.parse(jret);
  }
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
export function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export function StringToDate(pDate) {
  if (pDate !== null) {
    const dateObj = new Date(pDate.replace(/-/g, "/"));
    return dateObj;
  } else {
    return pDate;
  }
}

export function DateToArgString(pDate) {
  if (pDate !== null) {
    return (
      pDate.slice(8, 10) + "/" + pDate.slice(5, 7) + "/" + pDate.slice(0, 4)
    );
  } else {
    return pDate;
  }
}

// utils.js

export const hasPermission = (userRoles, permission) => {
  return !permission || userRoles.includes(permission);
};

export function convertDateFormat(dateString) {
  // Split the date string by the dash
  const parts = dateString.split("-");
  // Rearrange and join them in the desired format
  const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
  return formattedDate;
}

export function OnlyNumbers(str) {
  return str.length > 0 && /^\d+$/.test(str);
}

export function TodayFullText() {
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const obj = new Date();
  const dayweek = obj.getDay();
  const day = obj.getDate();
  const month = obj.getMonth();
  const year = obj.getFullYear();
  const fullText =
    days[dayweek] +
    " " +
    day.toString() +
    " de " +
    months[month] +
    " de " +
    year.toString();
  return fullText;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#374151",
    color: theme.palette.common.white,
  },
}));

export const StyledTableCellLarge = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#374151",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.root}`]: {
    padding: "2.5px 30px",
    width: "100rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { xs: 12, sm: 14 },
  },
}));

export const StyledTableCellXLarge = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#374151",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.root}`]: {
    padding: "2.5px 30px",
    width: "200px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { xs: 12, sm: 14 },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    height: "5px",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function FormikValidatePhone(PhoneNumber) {
  // Expresiones regulares para validar los diferentes patrones
  const regex15 = /^15\d{8}$/;
  const regex0_3 = /^0\d{3}-15\d{7}$/;
  const regex0_4 = /^0\d{4}-15\d{6}$/;
  if (/^[\d-]+$/.test(PhoneNumber) === false) {
    return {
      isValid: false,
      errorMessage: "El celular solo tiene que tener números.",
    };
  }
  let lcRet = "El número de teléfono no es válido.";
  // Validar el número de teléfono
  if (regex15.test(PhoneNumber)) {
    // Cumple con el patrón de 15 seguido de 8 dígitos
    lcRet = "ok";
  } else if (regex0_3.test(PhoneNumber)) {
    // Cumple con el patrón de 0 seguido de 3 dígitos, un guión y luego 7 dígitos
    lcRet = "ok";
  } else if (regex0_4.test(PhoneNumber)) {
    // Cumple con el patrón de 0 seguido de 4 dígitos, un guión y luego 6 dígitos
    lcRet = "ok";
  }
  const valid = lcRet === "ok";
  return valid
    ? {
        isValid: true,
      }
    : {
        isValid: false,
        errorMessage: lcRet,
      };
}

export function ValorizarTrabajoLaboratorio(
  row,
  precio_manual,
  estado_devolucion
) {
  // Step 1: Retrieve necessary parameters from the row object
  const motivo_envio = row.motivo;
  const precio_laboratorio = row.laboratorio_precio || 0; // Default to 0 if not provided

  // Step 2: Initialize the price variable
  let precio = 0;

  // Step 3: If precio_manual is greater than zero, set it as the price
  if (precio_manual > 0) {
    precio = precio_manual;
  }
  // Step 4: If motivo_envio or estado_devolucion is 'EL' or 'RI', set the price to 0
  else if (
    ["EL", "RI"].includes(motivo_envio) ||
    ["EL", "RI"].includes(estado_devolucion)
  ) {
    precio = 0;
  }
  // Step 5: Otherwise, use the precio_lista provided in the row
  else {
    precio = precio_laboratorio;
  }

  // Step 6: Return the calculated price
  return precio;
}

export const handleKeyDownField = (event, nextInputRef, sendTab = false) => {
  if (event.key === "Enter") {
    event.preventDefault(); // Prevent form submission
    if (sendTab) {
      // Manually find the next focusable element and focus on it
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const elements = Array.prototype.slice.call(
        document.querySelectorAll(focusableElements)
      );
      const index = elements.indexOf(event.target);
      if (index > -1 && index < elements.length - 1) {
        elements[index + 1].focus();
      }
    } else if (nextInputRef && nextInputRef.current) {
      nextInputRef.current.focus(); // Focus on the next input
    }
  }
};
