import React, { forwardRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AvatarAzul from "../assetss/img/logo_azul_reportes.png";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import "./../assetss/css/PageA4.css";
import { RenderReportCajasTable } from "./RenderReportCajasTable";
import { RenderReportPagosTable } from "./RenderReportPagosTable";

export const RenderReportCajas = forwardRef((props, ref) => {
  const { allData, allTipoPagos, allPagos } = props;
  useEffect(() => {}, []);

  return (
    <div ref={ref}>
      <style
        type="text/css"
        media="print"
        dangerouslySetInnerHTML={{
          __html: `
                  @page {
                    size: Oficio portrait;
                    margin: 20mm;
                  }
                `,
        }}
      />
      <div className="header">
        <Box
          display="flex"
          sx={{
            marginTop: 2,
            marginLeft: 2,
            marginRight: 2,
            py: 0,
            border: 1,
            gap: 10,
            alignItems: "center",
          }}
        >
          <Image
            src={AvatarAzul}
            height="65px"
            width="256px"
            duration={0}
            sx={{
              marginLeft: 4,
            }}
          />
          <Typography variant="h5">
            Movimientos de Cuentas Corrientes
          </Typography>
        </Box>
      </div>
      <div className="content">
        <Box
          display="flex"
          flexDirection="row"
          sx={{
            marginLeft: 4,
            marginRight: 4,
            paddingTop: 0.5,
            paddingBottom: 0.5,
          }}
        ></Box>
        <RenderReportCajasTable allData={allData} allTipoPagos={allTipoPagos} />
      </div>
      <div className="content2">
        {allPagos !== null && allPagos.length > 0 && (
          <>
            <div className="page-break" />
            <Typography variant="h5" sx={{ marginLeft: 4 }}>
              Informe de tarjetas y transferencias
            </Typography>
            <RenderReportPagosTable allData={allPagos} />
          </>
        )}
      </div>
    </div>
  );
});
