/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import Webcam from "react-webcam";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ModalInformation from "../modals/ModalInformation";
import Alert from "@mui/material/Alert";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import Tooltip from "@mui/material/Tooltip";
import nocamera from "../../assetss/img/nocamera.png";
import { RandomString, grabarFoto } from "../utils/Utils";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <div>
      <DialogTitle
        sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
        {...other}
      >
        <AddAPhotoIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    </div>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalCapturePhoto({
  showModal,
  hideModal,
  metodorefresh,
  currentpatient,
  folder,
  height,
  width,
}) {
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  const handleClose = () => {
    hideModal();
  };

  useEffect(() => {
    setImgSrc(null);
    setMirrored(false);
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [mirrored, setMirrored] = useState(false);
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  async function savePicture() {
    if (imgSrc === null) {
      setModalMessage("Debe tomar una foto.");
      setModalInformationOpen(true);
      return;
    } else {
      let result = grabarFoto(
        imgSrc,
        currentpatient,
        folder,
        setLoading,
        "jpg"
      ).then((res) => {
        if (res.error === false) {
          setImgSrc(null);
          handleClose();
          metodorefresh();
        }
      });
      if (result.error === false) {
      } else {
      }
    }
  }

  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Tomar Foto
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <>
            {devices.length > 0 && (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                  gap: "20px",
                }}
              >
                {imgSrc ? (
                  <img src={imgSrc} alt="webcam" />
                ) : (
                  <Webcam
                    forceScreenshotSourceSize
                    videoConstraints={{
                      ...videoConstraints,
                      facingMode,
                      height: height,
                      width: width,
                    }}
                    height={height}
                    width={width}
                    ref={webcamRef}
                    mirrored={mirrored}
                    screenshotFormat="image/jpeg"
                    screenshotQuality={1} // Valores de 0 a 1 default 0.92
                  />
                )}
              </Box>
            )}
            {devices.length === 0 && (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                  gap: "20px",
                }}
              >
                <img alt="Loading64" src={nocamera} />
              </Box>
            )}

            {devices.length > 0 && (
              <>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                    gap: "20px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={mirrored}
                        onChange={(e) => setMirrored(e.target.checked)}
                      />
                    }
                    label={
                      <Typography
                        color={mirrored ? "avatar" : "#94a3b8"}
                        fontWeight={mirrored ? "bold" : "regular"}
                      >
                        Efecto Espejo
                      </Typography>
                    }
                    sx={{
                      marginTop: {
                        xs: 0.5,
                        md: 0,
                      },
                      marginLeft: {
                        xs: -2,
                        md: 0,
                      },
                    }}
                  />

                  <Tooltip title="Cambiar Cámara">
                    <IconButton
                      size="medium"
                      onClick={handleClick}
                      color="primary"
                      variant="contained"
                    >
                      <CameraswitchIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )}
            {devices.length === 0 && (
              <Alert
                severity="warning"
                sx={{
                  mt: 2,
                }}
              >
                No se encontraron dispositivos de captura de imágenes.
              </Alert>
              //devices.map((device, key) => (
              //  <Alert severity="info">
              //    {device.label || `Device ${key + 1}`}
              //  </Alert>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={imgSrc ? retake : capture}
            disabled={devices.length === 0}
            color="primary"
            fullWidth
            variant="contained"
            loadingPosition="end"
            startIcon={<AddAPhotoIcon />}
            sx={{
              backgroundColor: "primary.main",
              ":hover": {
                bgcolor: "avatar", // theme.palette.primary.main
                color: "white",
              },
              textTransform: "capitalize",
            }}
          >
            {imgSrc ? "Nueva Foto" : "Tomar Foto"}
          </Button>
          <LoadingButton
            color="primary"
            onClick={savePicture}
            disabled={imgSrc === null}
            fullWidth
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              ":hover": {
                bgcolor: "avatar", // theme.palette.primary.main
                color: "white",
              },
              textTransform: "capitalize",
            }}
          >
            <span>Grabar Foto</span>
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="warning"
      />
    </Box>
  );
}
