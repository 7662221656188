import React from "react";
import { Container, Button, Divider, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const SaveCancelButtons = ({ handleCancel, loading, isModal = false }) => {
  const buttonAlignment = {
    justifyContent: isModal ? "center" : "center", // Alineación horizontal
    alignItems: "center", // Alineación vertical
  };

  const buttonSize = isModal ? "120px" : "150px"; // Tamaño del botón
  const gap = isModal ? "10px" : "20px"; // Tamaño del gap
  const marginTop = isModal ? 0 : 4;

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          gap: gap,
          ...buttonAlignment,
          marginTop: marginTop,
        }}
        fullWidth
      >
        <Button
          onClick={handleCancel}
          color="primary"
          fullWidth
          variant="contained"
          loadingPosition="end"
          sx={{
            backgroundColor: "#cbd5e1",
            ":hover": {
              bgcolor: "#475569", // theme.palette.primary.main
              color: "white",
            },
            textTransform: "capitalize",
            paddingY: 1,
            width: buttonSize,
          }}
        >
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          fullWidth
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          sx={{
            backgroundColor: "primary.main",
            ":hover": {
              bgcolor: "#475569", // theme.palette.primary.main
              color: "white",
            },
            textTransform: "capitalize",
            paddingY: 1,
            width: buttonSize,
          }}
        >
          <span>Grabar</span>
        </LoadingButton>
      </Box>
    </Container>
  );
};

export default SaveCancelButtons;
