import React, { useState, useEffect } from "react";
import { ModeloGetUrlJson } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import { Box } from "@mui/material";
import crypto from "../Crypto";

const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  let browserName = "Unknown";
  let fullVersion = "Unknown";
  let fullName = ua;

  if (/Opera\//.test(ua)) {
    browserName = "Opera";
    fullVersion = ua.match(/Opera\/(\d+\.\d+)/)[1];
  } else if (/OPR\//.test(ua)) {
    browserName = "Opera";
    fullVersion = ua.match(/OPR\/(\d+\.\d+)/)[1];
  } else if (/Edg\//.test(ua)) {
    browserName = "Microsoft Edge";
    fullVersion = ua.match(/Edg\/(\d+\.\d+)/)[1];
  } else if (/EdgA\//.test(ua)) {
    browserName = "Microsoft Edge";
    fullVersion = ua.match(/EdgA\/(\d+\.\d+)/)[1];
  } else if (/SamsungBrowser\//.test(ua)) {
    browserName = "Samsung Browser";
    fullVersion = ua.match(/SamsungBrowser\/(\d+\.\d+)/)[1];
  } else if (/Chrome\//.test(ua)) {
    browserName = "Chrome";
    fullVersion = ua.match(/Chrome\/(\d+\.\d+)/)[1];
  } else if (/Safari\//.test(ua)) {
    browserName = "Safari";
    fullVersion = ua.match(/Version\/(\d+\.\d+)/)[1];
  } else if (/Firefox\//.test(ua)) {
    browserName = "Firefox";
    fullVersion = ua.match(/Firefox\/(\d+\.\d+)/)[1];
  } else if (/MSIE|Trident\//.test(ua)) {
    browserName = "Internet Explorer";
    fullVersion = ua.match(/(MSIE |rv:)(\d+\.\d+)/)[2];
  }

  return {
    browserName,
    fullVersion,
    fullName,
  };
};

const getDeviceType = () => {
  const ua = navigator.userAgent;

  if (/tablet|ipad|playbook|silk/i.test(ua) && !/mobile|android/i.test(ua)) {
    return "Tablet";
  }

  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|Fennec|Maemo|Windows Phone|BB10|RIM Tablet OS|webOS|PalmOS|Opera Mini|Mobile Safari|Bada/i.test(
      ua
    )
  ) {
    return "Celular";
  }

  return "Computadora";
};

const getOSInfo = () => {
  const platform = navigator.platform;
  let os = "Unknown OS";

  if (platform.indexOf("Win") !== -1) os = "Windows";
  if (platform.indexOf("Mac") !== -1) os = "MacOS";
  if (platform.indexOf("X11") !== -1) os = "UNIX";
  if (platform.indexOf("Linux") !== -1) os = "Linux";

  return { os, platform };
};

const getHardwareInfo = () => {
  return {
    memory: navigator.deviceMemory || "Unknown",
    cores: navigator.hardwareConcurrency || "Unknown",
  };
};

const SystemInfo = () => {
  const [info, setInfo] = useState({
    browser: {},
    os: {},
    hardware: {},
    laravelVersion: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    buscarInfo();
  }, []);

  async function buscarInfo() {
    const browserInfo = getBrowserInfo();
    const osInfo = getOSInfo();
    const hardwareInfo = getHardwareInfo();
    const deviceType = getDeviceType();

    setInfo({
      browser: browserInfo,
      os: osInfo,
      hardware: hardwareInfo,
      laravelVersion: "",
      devicetype: deviceType,
    });
    setLoading(true);
    let result = await ModeloGetUrlJson("laravel-version", null, "", null);
    setLoading(false);
    if (result.error === false) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        laravelVersion: result.listado,
      }));
    }
  }

  return (
    <div className="text-black flex justify-center">
      {!loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <p>
            <strong>Browser:</strong> {info.browser.browserName}
          </p>
          <p>
            <strong>Browser Version:</strong> {info.browser.fullVersion}
          </p>
          <p>
            <strong>Sistema Operativo:</strong> {info.os.os}
          </p>
          <p>
            <strong>Plataforma:</strong> {info.os.platform}
          </p>
          <p>
            <strong>Dispositivo:</strong> {info.devicetype}
          </p>
          <p>
            <strong>Laravel Version:</strong> {info.laravelVersion}
          </p>
          <p>
            <strong>Base de Datos:</strong>
            {crypto.decryptLocalStorage("is-beta") === true
              ? " Testing"
              : " Producción"}
          </p>
          <p>
            <strong>Memoria:</strong> {info.hardware.memory} GB
          </p>
          <p>
            <strong>CPU Cores:</strong> {info.hardware.cores}
          </p>
        </Box>
      ) : (
        <div className="flex justify-center m-2">
          <FacebookCircularProgress />
        </div>
      )}
    </div>
  );
};

export default SystemInfo;
