import React, { useState } from 'react';

export default function CuadranteStd8Std(data) {
    const [testOdo, setTestOdo] = useState(true);

    const getSourceImage = (dienteimage) => {
        let sourceimage = '/iconos_odontograma/' + dienteimage
        return sourceimage
    }


    return (
        <div className="flex">
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">85</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "85" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">84</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "84" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">83</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "83" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">82</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "82" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">81</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "81" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
        </div>
    );
}
