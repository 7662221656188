import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import { useLocation } from "react-router-dom";
import crypto from "../Crypto";
import { ModeloGetUrl } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ModalInformation from "../modals/ModalInformation";
import BackPage from "../BackPage";
import RenderEncuestas from "./RenderEncuestas";
import PacienteHeader from "./PacienteHeader";

const PacienteEncuestas = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allEncuestas, setAllEncuestas] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [backPage, setBackPage] = useState("pacientes");
  const location = useLocation();

  useEffect(() => {
    if (typeof location.backpage !== "undefined") {
      setBackPage(location.backpage);
    }
    buscarEncuestas();
  }, []);

  async function buscarEncuestas() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      const result = await ModeloGetUrl(
        "paciente",
        PacienteId,
        "vista-encuestas",
        null
      );
      setLoading(false);
      if (result.error === false) {
        if (result.listado.length > 0) {
          const sorted = result.listado.sort((a, b) =>
            a.fecha > b.fecha ? 1 : -1
          );
          setAllEncuestas(sorted);
        } else {
          setAllEncuestas([]);
        }
      } else {
        history.push("/");
      }
    } else {
      handleClickVolver();
    }
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  function handleClickVolver() {
    history.push("/" + backPage);
  }
  return (
    <>
      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <PacienteHeader />
      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {!loading && (
        <div>
          <RenderEncuestas alldata={allEncuestas} />
        </div>
      )}

      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
};
export default PacienteEncuestas;
