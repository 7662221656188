import React from "react";
import { ErrorMessage, useField } from "formik";

function handleKeyDown(e) {
  if (e.keyCode === 13) {
    e.target.nextSibling.nextSibling.focus();
    e.preventDefault();
  }
}

export const TextField = ({ label, Ref, white = "false", ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-2">
      <label
        className={
          white === "true"
            ? "absolute text-white text-sm -mt-2 ml-1"
            : "absolute text-slate-700 text-sm -mt-2 ml-1"
        }
        htmlFor={field.name}
      >
        {label}
      </label>
      <input
        className={` mt-3 mb-3 ml-1 p-2 shadow-none border bg-sky-50 h-9 rounded-lg  w-64 sm:w-96 focus:border-blue-500  ${
          meta.touched && meta.error
            ? " border-red-700 bg-gradient-to-r from-red-50 to-red-50"
            : "border-blue-400"
        }`}
        {...field}
        {...props}
        autoComplete="off"
        id={field.name}
        multiline={true}
        ref={Ref}
      />
      <ErrorMessage
        component="div"
        name={field.name}
        className="absolute text-pink-700 text-xs -mt-4 ml-1"
      />
      {meta.touched && meta.error && (
        <i className="fa fa-exclamation-circle absolute text-pink-700 -ml-6 mt-4"></i>
      )}
    </div>
  );
};
