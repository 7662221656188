import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ModalError from "../modals/ModalError";
import crypto from "../Crypto";
import { ModeloGetUrlJson, ModeloDelete, ModeloGetUrl } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ModalConfirmation from "../modals/ModalConfirmation";
import ModalInformation from "../modals/ModalInformation";
import Search from "@mui/icons-material/Search";
import Odontogram from "../Odontogram";
import InsertUpdateFichado from "../InsertUpdateFichado";
import PacienteHeader from "./PacienteHeader";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RenderCustomTable from "../RenderCustomTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { hasPermission } from "../utils/Utils";
import * as moment from "moment";

const userRoles =
  crypto.decryptLocalStorage("user_roles") !== null
    ? crypto.decryptLocalStorage("user_roles")
    : [];

const PacienteFichados = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingitems, setLoadingItems] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [allFichados, setAllFichados] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [borrarId, setBorrarId] = useState(0);
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [rowFichado, setRowFichado] = React.useState([]);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [nuevoFichado, setNuevoFichado] = useState(false);
  const [editarFichado, setEditarFichado] = useState(false);
  const [showFichado, setShowFichado] = useState(false);
  const [itemsFichado, setItemsFichado] = useState([]);
  const [odontogramaRows, setOdontoGramaRows] = useState([]);
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    const userRoles =
      crypto.decryptLocalStorage("user_roles") !== null
        ? crypto.decryptLocalStorage("user_roles")
        : [];
    buscarFichados();
  }, []);

  const columns = [
    { field: "id", headerName: "Id" },
    { field: "fecha_arg", headerName: "Fecha" },
    { field: "tipo_fichado", headerName: "Tipo" },
    { field: "identificador", headerName: "Clínica", isClinic: true },
    { field: "especialidad", headerName: "Especialidad" },
    { field: "odontologo", headerName: "Odontólogo", capitalize: true },
  ];

  const actions = [
    {
      icon: Search,
      handle: (row) => handleVerFichado(row, false),
      sx: { color: "primary.main" },
    },
    {
      icon: EditIcon,
      handle: (row) => handleEdit(row),
      sx: { color: "primary.main" },
      hidden: (row) =>
        userRoles.indexOf("MODIFICACION FICHADOS DIAS POSTERIORES") < 0 &&
        row.fecha_emision !== moment(today).format("YYYY-MM-DD"),
      requiredPermission: "ALTA CFICHADOS",
    },
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
      hidden: (row) =>
        !(
          userRoles.indexOf("MODIFICACION FICHADOS DIAS POSTERIORES") >= 0 ||
          row.fecha_emision === moment(today).format("YYYY-MM-DD")
        ),
      requiredPermission: "ALTA CFICHADOS",
    },
  ];

  function BorrarFichado(row) {
    const editpermitted =
      userRoles.indexOf("MODIFICACION FICHADOS DIAS POSTERIORES") >= 0 ||
      row.fecha_emision === moment(today).format("YYYY-MM-DD")
        ? true
        : false;
    if (editpermitted) {
      setBorrarId(row.id);
      setDisplayConfirmationModal(true);
      setModalMessage("Seguro quiere Borrar el Fichado ?");
    } else {
      setModalMessage("Sin permisos para Borrar el Fichado ?");
      setModalInformationOpen(true);
    }
  }

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };
  const hideInformationModal = () => {
    setModalInformationOpen(false);
    setModalMessage(null);
  };

  let odontogramState = {};

  const handleToothUpdate = (id, toothState) => {
    odontogramState[id] = toothState;
  };

  const handleOdontogramaRow = (row) => {
    setOdontoGramaRows(...odontogramaRows.push(row));
  };

  const handleNew = () => {
    setNuevoFichado(true);
  };

  function confirmOk() {
    if (modalMessage === "Seguro quiere Borrar el Fichado ?") {
      EliminarFichado();
    }
  }

  async function handleVerFichado(Fichado, Edit) {
    if (Fichado.id > 0) {
      setLoadingItems(true);
      setSelectedRow(Fichado);
      let result = await ModeloGetUrl(
        "fichado",
        Fichado.id,
        "buscar-items-grafico",
        null
      );
      setLoadingItems(false);
      if (result.error === false) {
        setItemsFichado(result.listado);
        setRowFichado(Fichado);
        if (Edit) {
          setEditarFichado(true);
        } else {
          setShowFichado(true);
        }
      } else {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    }
  }

  async function EliminarFichado() {
    hideConfirmationModal();
    if (borrarId > 0) {
      let result = await ModeloDelete("fichado", borrarId);

      if (result.error === false) {
        buscarFichados();
      } else {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    }
  }

  async function buscarFichados() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      let result = await ModeloGetUrlJson(
        "paciente",
        PacienteId,
        "fichados-view",
        null
      );
      setLoading(false);
      if (result.error === false) {
        setAllFichados(result.listado);
        setItemsFichado([]);
      } else {
      }
    } else {
      handleClickVolver();
    }
  }

  const hideErrorModal = () => {
    setErrorMessage(null);
  };

  function handleClickVolver() {
    if (!nuevoFichado && !editarFichado) {
      history.push("/pacientes");
    } else {
      hideFichado();
    }
  }

  const hideFichado = () => {
    setNuevoFichado(false);
    setEditarFichado(false);
    setShowFichado(false);
  };

  const handleEdit = (row) => {
    handleVerFichado(row, true, true);
  };

  const handleDelete = (row) => {
    BorrarFichado(row);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      <div className="flex justify-start ml-3 mt-3 mb-3 capitalize ">
        <i
          className="fas fa-chevron-circle-left fa-2x text-gray-500 mt-1 mr-2 cursor-pointer"
          onClick={handleClickVolver}
        />
        <button
          className="text-gray-500"
          type="button"
          onClick={handleClickVolver}
        >
          {!nuevoFichado && !editarFichado
            ? "Volver al Paciente"
            : "Volver a Fichados"}
        </button>
      </div>
      <>
        <PacienteHeader />

        <div className="flex justify-center mt-2">
          {loading && <FacebookCircularProgress />}
        </div>
        {!loading && !nuevoFichado && !editarFichado && (
          <Container maxWidth="xl">
            <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Fichados
              </Typography>
              {hasPermission(userRoles, "ALTA CFICHADOS") && (
                <IconButton
                  aria-label="new"
                  onClick={handleNew}
                  sx={{ p: "1px", marginTop: -1 }}
                >
                  <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
                </IconButton>
              )}
            </Box>
            <RenderCustomTable
              data={allFichados?.sort((a, b) =>
                a.fecha_emsion > b.fecha_emsion ? -1 : 1
              )}
              columns={columns}
              paddingLeft={0}
              paddingRight={0}
              actions={actions}
            />
          </Container>
        )}
        {!loading &&
          !nuevoFichado &&
          !editarFichado &&
          (itemsFichado.length > 0 || loadingitems || showFichado) && (
            <>
              <Container maxWidth="xl">
                <Paper sx={{ mt: 2, mb: 2, p: 4 }}>
                  {loadingitems && (
                    <div className="flex justify-center ">
                      <FacebookCircularProgress />
                    </div>
                  )}
                  {!loadingitems && (
                    <>
                      <div
                        class="alert alert-warning m-3 sm:hidden"
                        role="alert"
                      >
                        El ancho de la pantalla no permite visualizar el
                        Odontograma. Pruebe colocando el móvil en posición
                        horizontal.
                      </div>
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexDirection: "column",
                              marginLeft: 1,
                              marginBottom: 4,
                            }}
                          >
                            <Typography variant="h6">
                              Fecha : {selectedRow.fecha_arg}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                textTransform: "capitalize",
                              }}
                            >
                              Odontólogo :{" "}
                              {selectedRow.odontologo.toLowerCase()}
                            </Typography>
                            <Typography variant="h7">
                              Fichado Id : {selectedRow.id}
                            </Typography>
                            <Typography variant="h7">
                              Clínica : {selectedRow.centro}
                            </Typography>
                            <Typography variant="h7">
                              Especialidad : {selectedRow.especialidad}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Odontogram
                            data={itemsFichado}
                            tratamiento={null}
                            color={null}
                            readonly={true}
                            handleToothUpdate={handleToothUpdate}
                            handleOdontogramaRow={handleOdontogramaRow}
                            cleanodontograma={false}
                          />
                          {selectedRow.sin_patologia === 1 &&
                            itemsFichado.length === 0 && (
                              <Typography
                                variant="h4"
                                sx={{ ml: -12, mt: -3, color: "primary.main" }}
                              >
                                Sin Patología
                              </Typography>
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Paper>
              </Container>
            </>
          )}
        {nuevoFichado && (
          <InsertUpdateFichado
            fichadorow={null}
            itemsfichado={[]}
            buscarCoes={true}
            hideModal={hideFichado}
            buscarFichados={buscarFichados}
          />
        )}
        {editarFichado && (
          <InsertUpdateFichado
            fichadorow={rowFichado}
            itemsfichado={itemsFichado}
            buscarCoes={true}
            hideModal={hideFichado}
            buscarFichados={buscarFichados}
          />
        )}
      </>
      <div className="flex justify-start ml-3 mt-6  mb-3 capitalize ">
        <i
          className="fas fa-chevron-circle-left fa-2x text-gray-500 mt-1 mr-2 cursor-pointer"
          onClick={handleClickVolver}
        />
        <button
          className="text-gray-500"
          type="button"
          onClick={handleClickVolver}
        >
          {!nuevoFichado && !editarFichado
            ? "Volver al Paciente"
            : "Volver a Fichados"}
        </button>
      </div>
      {errorMessage !== null && (
        <ModalError
          showModal={errorMessage !== null}
          hideModal={hideErrorModal}
          message={errorMessage}
        />
      )}
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={confirmOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
};
export default PacienteFichados;
