import React, { useRef, useState, useEffect } from "react";
import { ModalTableForm, hasPermission } from "../utils/Utils.js";
import Alert from "react-bootstrap/Alert";
import ModalTextInformation from "../modals/ModalTextInformation";
import ModalInformation from "../modals/ModalInformation";
import { ModeloGetUrlJson } from "../ModeloCrud";
import crypto from "../Crypto";
import FacebookCircularProgress from "../FacebookCircularProgress";
import OdontologosDatos from "../OdontologosDatos";
import OdontologosAgendas from "../OdontologosAgendas";
import OdontologosAusencias from "../OdontologosAusencias";
import { Grid, Box, Typography } from "@mui/material";
import MultipleResultsTable from "../MultipleResultsTable";
import { CapSentenceArray } from "../utils/Utils.js";
import CustomizedSearch from "../CustomizedSearch.js";
import { useHistory } from "react-router-dom";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ProtectedRoute from "../ProtectedRoute";

const Odontologos = () => {
  const [loading, setLoading] = useState(false);
  const [searchOdontologo, setSearchOdontologo] = useState("");
  const [showAlert, setShowAlert] = useState(null);
  const [currentOdontologo, setCurrentOdontologo] = useState(null);
  const [OdontologoOk, setOdontologoOk] = useState(false);
  const [allOdontologo, setAllOdontologo] = useState(null);
  const [allCoes, setAllCoes] = useState(null);
  const [allAusencias, setAllAusencias] = useState(null);
  const [modalOdontologoOpen, setModalOdontologoOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalTextMessage, setModalTextMessage] = useState(null);
  const [displayInformationModal, setDisplayInformationModal] = useState(false);
  const [displayTextInformationModal, setDisplayTextInformationModal] =
    useState(false);
  const [modalPreciosOpen, setModalPreciosOpen] = useState(false);
  const [listaPrecios, setListaPrecios] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [logo, setLogo] = useState(null);
  let [filterOdontologo, setFilterOdontologo] = useState(null);
  const history = useHistory();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const ltaFields = [
    "id",
    "codigo_nomenclador",
    "precio",
    "descripcion_nomenclador",
    "grupo_dental",
    "observaciones",
  ];
  const ltaNames = ["I", "C", "P", "Descripción", "Piezas", "Observaciones"];
  const ltaHeader =
    '<table id="mitabla" className="display responsive nowrap " style={{ width: "100%" }}>';
  // const Linqer = require('@siderite/linqer');

  const objHeaders = ["Ver", "Apellido", "Nombres", "Alta", "Baja"];
  const objFields = ["apellido", "nombres", "fechaalta", "fechabaja"];
  const objDates = ["fechaalta", "fechabaja"];

  useEffect(() => {
    if (crypto.decryptDataStorage("currentOdontologo") !== null) {
      setCurrentOdontologo(crypto.decryptDataStorage("currentOdontologo"));
      buscarOdontologo(crypto.decryptDataStorage("currentOdontologo").id, true);
    }
  }, []);

  function onChangeSearchOdontologo(e) {
    setSearchOdontologo(e.target.value);
    if (modalOdontologoOpen) {
      FiltrarOdontologo(searchOdontologo);
    }
  }
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      buscarOdontologo(searchOdontologo, false, null);
    }
  }

  function busqueda() {
    buscarOdontologo(searchOdontologo, false);
  }

  function newodontologo() {
    history.push({
      pathname: "/insertupdateodontologo",
    });
  }

  function FiltrarOdontologo(filtro) {
    if (filtro.length > 0) {
      filterOdontologo = allOdontologo.filter(
        (p) =>
          p.razon_social.toLowerCase().indexOf(filtro.toLowerCase()) >= 0 ||
          p.denominacion_comercial
            .toLowerCase()
            .indexOf(filtro.toLowerCase()) >= 0
      );
      if (filterOdontologo.length <= 0) {
        filterOdontologo = allOdontologo;
      }
    } else {
      filterOdontologo = allOdontologo;
    }
    //crypto.encryptDataStorage("hctratamientos", filterData);
    let tempData = [...filterOdontologo];
    setFilterOdontologo(tempData);
    if (filterOdontologo.length > 0) {
    }
  }

  function limpiarOdontologo() {
    setShowAlert(null);
    setCurrentOdontologo(null);
    setOdontologoOk(false);
    setModalOdontologoOpen(false);
    setAllOdontologo(null);
    setFilterOdontologo(null);
    setAllCoes(null);
    setLoading(false);
    setLogo(null);
    sessionStorage.removeItem("currentOdontologoid");
    sessionStorage.removeItem("currentOdontologo");
    sessionStorage.removeItem("planesOdontologo");
    sessionStorage.removeItem("logoOdontologo");
  }
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };
  function clean() {
    limpiarOdontologo();
  }

  async function buscarOdontologo(Busqueda, IsId) {
    limpiarOdontologo();
    if (!IsId && Busqueda.length < 3) {
      setShowAlert("Ingrese al menos 3 caracteres !");
      return;
    }
    let params = "busqueda=" + Busqueda;
    if (IsId) {
      params = params + "&is_id=SI";
    }
    setLoading(true);
    let result = await ModeloGetUrlJson(
      "odontologo",
      null,
      "busqueda-odontologo",
      params
    );
    setLoading(false);

    if (result.error === false) {
      if (result.listado == null && result.odontologo !== null) {
        setCurrentOdontologo(result.odontologo[0]);
        setAllCoes(result.agendas);
        setAllAusencias(result.ausencias);
        crypto.encryptDataStorage("currentOdontologo", result.odontologo[0]);
        crypto.encryptDataStorage(
          "currentOdontologoid",
          result.odontologo[0].id
        );
        setOdontologoOk(true);
      } else if (result.listado.length > 1) {
        if (result.listado.length > 500) {
          setModalMessage(
            "Demasiados resultados(" +
              result.listado.length.toString() +
              ") refine la búsqueda."
          );
          setModalInformationOpen(true);
        } else {
          setAllOdontologo(result.listado);
          setFilterOdontologo(result.listado);
          setModalOdontologoOpen(true);
        }
      } else if (result.listado.length === 0) {
        setLoading(false);
        setModalMessage("No se encontraron registros.");
        setModalInformationOpen(true);
      }
    } else {
      setLoading(false);
      setModalMessage(result.errormessage);
      setModalErrorOpen(true);
    }
    return;
  }

  const hideTextInformationModal = () => {
    setDisplayTextInformationModal(false);
    setModalTextMessage(null);
  };

  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"VISUALIZAR CODONTO"}
    >
      <div className="">
        <div className="flex justify-center mt-4 ml-2 mr-3 ">
          <CustomizedSearch
            value={searchOdontologo}
            onchange={onChangeSearchOdontologo}
            handlebusqueda={busqueda}
            placeholder="Apellido, Nombres..."
            width={{ xs: 400, md: 500 }}
            starticon={
              hasPermission(userRoles, "ALTA CODONTO") ? (
                <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
              ) : null
            }
            handlestart={
              hasPermission(userRoles, "ALTA CODONTO") ? newodontologo : null
            }
          />
        </div>

        {OdontologoOk === false && (
          <div className="flex justify-center mt-2">
            {loading && <FacebookCircularProgress />}
          </div>
        )}
        {modalOdontologoOpen && filterOdontologo.length > 0 && (
          <MultipleResultsTable
            setShowModal={setModalOdontologoOpen}
            pallData={CapSentenceArray(filterOdontologo, [
              "apellido",
              "nombres",
            ])}
            objFields={objFields}
            objHeaders={objHeaders}
            objDates={objDates}
            selectedMethod={buscarOdontologo}
            highlightText={searchOdontologo}
          />
        )}
      </div>
      <div>
        {currentOdontologo !== null && OdontologoOk === true && (
          <>
            <div className="flex justify-center w-full py-4 mt-4 px-8 ">
              <Grid
                container
                maxWidth="xl"
                rowSpacing={{ xs: 2, md: 4 }}
                columnSpacing={{ xs: 0, md: 6 }}
              >
                <Grid item xs={12} md={3}>
                  {currentOdontologo !== null && (
                    <OdontologosDatos
                      currentOdontologo={currentOdontologo}
                      metodolimpiar={limpiarOdontologo}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={9}>
                  {currentOdontologo !== null && (
                    <OdontologosAgendas
                      allCoes={allCoes}
                      searchmethod={() =>
                        buscarOdontologo(
                          crypto.decryptDataStorage("currentOdontologo").id,
                          true
                        )
                      }
                    />
                  )}
                  {currentOdontologo !== null && (
                    <OdontologosAusencias
                      allAusencias={allAusencias}
                      searchmethod={() =>
                        buscarOdontologo(
                          crypto.decryptDataStorage("currentOdontologo").id,
                          true
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </>
        )}
        {showAlert !== null && (
          <div className="flex items-center justify-center mt-5 ml-12">
            <Alert variant="primary">{showAlert}</Alert>
          </div>
        )}
        <ModalInformation
          showModal={modalInformationOpen && modalMessage !== null}
          hideModal={hideInformationModal}
          message={modalMessage}
        />
        <ModalInformation
          showModal={modalErrorOpen && modalMessage !== null}
          hideModal={hideErrorModal}
          message={modalMessage}
          tipo="error"
        />

        <ModalTextInformation
          showModal={displayTextInformationModal && modalTextMessage !== null}
          hideModal={hideTextInformationModal}
          message={modalTextMessage}
          title="Observaciones"
        />
      </div>
    </ProtectedRoute>
  );
};

export default Odontologos;
