import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import HelpIcon from "@mui/icons-material/Help";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 1, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      <HelpIcon sx={{ color: "white", fontSize: 48, mr: 2 }} />
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: "primary.main",
          }}
        ></IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalConfirmation = ({ showModal, hideModal, confirmModal, message }) => {
  const handleClose = () => {
    hideModal();
  };
  const handleConfirm = () => {
    confirmModal();
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModal}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Confirmación
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              mt: 2,
              color: "white",
              backgroundColor: "#cbd5e1",
              ":hover": {
                bgcolor: "#475569",
                color: "white",
              },
              textTransform: "capitalize",
            }}
            autoFocus
            onClick={handleClose}
            variant="text"
          >
            No
          </Button>
          <Button
            sx={{
              mt: 2,
              color: "white",
              backgroundColor: "primary.main",
              ":hover": {
                bgcolor: "#475569",
                color: "white",
              },
              textTransform: "capitalize",
            }}
            autoFocus
            onClick={handleConfirm}
            variant="text"
          >
            Si
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default ModalConfirmation;
