import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ModalInformation from "./modals/ModalInformation";
import { CardContent } from "@mui/material";
import Container from "@mui/material/Container";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import * as moment from "moment";
import ModalOdontologoAusencia from "./modals/ModalOdontologoAusencia";
import DeleteModel from "./DeleteModel";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RenderCustomTable from "./RenderCustomTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import crypto from "./Crypto";
import { hasPermission } from "./utils/Utils";

export default function OdontologosAusencias({ allAusencias, searchmethod }) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [deleteAusenciaId, setDeleteAusenciaId] = useState(null);
  const Today = moment(new Date()).format("YYYY-MM-DD");
  const [editarOdontologoAusencia, setEditarOdontologoAusencia] =
    useState(false);
  const [insertarOdontologoAusencia, setInsertarOdontologoAusencia] =
    useState(false);
  const [selectedAusencia, setSelectedAusencia] = useState(null);
  const [updateOk, setUpdateOk] = useState(false);
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const columns = [
    { field: "motivo", headerName: "Motivo" },
    { field: "fecha_desde", headerName: "Fecha Desde", isDate: true },
    { field: "fecha_hasta", headerName: "Fecha Hasta", isDate: true },
  ];

  const iconColumns = {
    motivos: (props) => (
      <EventBusyIcon
        sx={{
          color:
            props.value?.trim() === "Especialización" ? "icongreen" : "iconred",
          mr: 2,
        }}
      />
    ),
  };

  const actions = [
    {
      icon: EditIcon,
      handle: (row) => handleEdit(row),
      sx: { color: "primary.main" },
      requiredPermission: "MODIFICACION CODONTOLOGOSAUSENCIAS",
    },
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
      requiredPermission: "BAJA CODONTOLOGOSAUSENCIAS",
    },
  ];

  const hideSuccessModal = () => {
    setModalMessage(null);
    setModalSuccessOpen(false);
  };

  const hideOdontologoAusencia = () => {
    setEditarOdontologoAusencia(false);
    setInsertarOdontologoAusencia(false);
  };

  const handleEdit = (row) => {
    setSelectedAusencia(row);
    setEditarOdontologoAusencia(true);
  };
  const handleNew = () => {
    setInsertarOdontologoAusencia(true);
  };
  function handleDelete(row) {
    setDeleteAusenciaId(row.id);
  }
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {}, []);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          ml: -2,
          mr: -2,
          backgroundColor: "bgcards",
          marginBottom: 4,
          //      height: "100%",
        }}
      >
        <CardHeader
          avatar={<EventBusyIcon sx={{ color: "avatar", fontSize: 48 }} />}
          title={
            <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Períodos de Ausencia
              </Typography>
              {hasPermission(userRoles, "ALTA CODONTOLOGOSAUSENCIAS") && (
                <IconButton
                  aria-label="new"
                  onClick={handleNew}
                  sx={{ p: "1px", marginTop: -1 }}
                >
                  <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
                </IconButton>
              )}
            </Box>
          }
          subheader={<></>}
        />
        <CardContent
          sx={{
            ml: -2,
            mr: -2,
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ mb: 4, marginTop: -2 }}>
              <RenderCustomTable
                data={allAusencias}
                columns={columns}
                iconColumns={iconColumns}
                paddingLeft={0}
                paddingRight={0}
                actions={actions}
              />
            </Box>
          </Container>
        </CardContent>
      </Card>
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalSuccessOpen && modalMessage !== null}
        hideModal={hideSuccessModal}
        message={modalMessage}
        tipo="success"
      />
      {editarOdontologoAusencia && (
        <ModalOdontologoAusencia
          row={selectedAusencia}
          showModal={editarOdontologoAusencia}
          hideModal={hideOdontologoAusencia}
          setLoading={setLoading}
          okmethod={searchmethod}
        />
      )}
      {insertarOdontologoAusencia && (
        <ModalOdontologoAusencia
          row={null}
          showModal={insertarOdontologoAusencia}
          hideModal={hideOdontologoAusencia}
          setLoading={setLoading}
          okmethod={searchmethod}
        />
      )}
      {deleteAusenciaId !== null && (
        <DeleteModel
          model={"ausencia-odontologo"}
          modelid={deleteAusenciaId}
          okmethod={searchmethod}
          nullmethod={setDeleteAusenciaId}
        />
      )}
    </>
  );
}
