import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CapSentence, BpRadio } from "./utils/Utils";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import FormControlLabel from "@mui/material/FormControlLabel";
import "dayjs/locale/en-gb";
import crypto from "./Crypto";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalInformation from "./modals/ModalInformation";
import ModalConfirmation from "./modals/ModalConfirmation";
import { CapSentenceArray } from "./utils/Utils";
import RadioGroup from "@mui/material/RadioGroup";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const validationSchema = yup.object({
  efectivo: yup.object().nullable(true),
  tarjeta: yup.object().nullable(true),
  plantarjeta: yup.object().nullable(true),
  numerotarjeta: yup
    .string()
    .nullable(true)
    .min(16, "El número tiene que tener 16 caracteres")
    .max(16, "El número tiene que tener 16 caracteres"),
  cupontarjeta: yup
    .string()
    .nullable(true)
    .min(4, "El número tiene que tener 4 caracteres como mínimo"),
  aprobtarjeta: yup
    .string()
    .nullable(true)
    .min(4, "El número tiene que tener 4 caracteres como mínimo"),
  banco: yup.object().nullable(true),
  numerocheque: yup
    .string()
    .nullable(true)
    .min(7, "El número tiene que tener 7 caracteres como mínimo"),
  importe: yup
    .number()
    .nullable(true)
    .min(0, "Ingrese un valor positivo.")
    .typeError("Campo numérico"),
  fechacheque: yup
    .date()
    .min(new Date("2023-01-01"), "Fecha inválida")
    .nullable()
    .typeError("Fecha inválida"),
  fechapago: yup
    .date()
    .min(new Date("2023-01-01"), "Fecha inválida")
    .nullable()
    .typeError("Fecha inválida"),
});

export default function InsertCtaCtePago({
  comprobante,
  newItems,
  setNewItems,
  setTotalPagos,
  setTotalIntereses,
  saldo,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const piezasDentales = crypto.decryptDataStorage("piezas_dentales");
  const nomencladores = CapSentenceArray(
    crypto.decryptDataStorage("nomencladores"),
    ["codigo_descripcion"]
  );
  const planId = crypto.decryptDataStorage("currentplanid");
  const tasaIva = crypto.decryptDataStorage("currenttasaiva");
  const [loading, setLoading] = useState(false);
  const [formaPago, setFormaPago] = useState("");
  const [planesTarjeta, setPlanesTarjeta] = useState([]);
  const optionsPago = [
    { forma: "Dinero", id: 1 },
    { forma: "Transferencia", id: 2 },
  ];
  const tablafull = crypto.decryptDataStorage("tablafull");

  const tarjetas = tablafull
    .filter((obj) => obj && obj.codigo_tabla === "TARJETAS_DE_CREDITO")
    .map((obj) => ({
      codigo: obj?.valor ?? "",
      tarjeta: obj?.descripcion ?? "",
    }));

  const bancos = tablafull
    .filter((obj) => obj.codigo_tabla === "BANCOS")
    .map((obj) => ({ codigo: obj.valor, banco: obj.descripcion }));

  const [errorForma, setErrorForma] = React.useState(false);
  const [helperTextForma, setHelperTextForma] = React.useState(null);

  function confirmOk() {
    if (modalMessage === "El importe es mayor al saldo. Continua igual ?") {
      hideConfirmationModal();
      AddItem();
    }
  }

  function AddItem() {
    var row = {};
    let maxValue = newItems.reduce(
      (acc, item) => (acc = acc > item.id ? acc : item.id),
      0
    );
    row["id"] = maxValue + 1;
    row["codigo"] = "81:00:00";
    row["nomenclador_id"] = null;
    row["descripcion"] =
      "Tratamiento de " + CapSentence(formik.values.presupuesto.especialidad);
    row["cantidad"] = 1;
    row["precio"] = formik.values.importe;
    row["importe"] = formik.values.importe;
    row["tasa_iva"] = tasaIva;
    row["neto_iva"] = Number(row["importe"] / (1 + tasaIva / 100)).toFixed(2);
    row["importe_iva"] = (
      Number(row["importe"]) - Number(row["neto_iva"])
    ).toFixed(2);
    newItems.push(row);

    const nextList = [...newItems];
    setNewItems(nextList);
  }

  const formik = useFormik({
    initialValues: {
      efectivo: null,
      tarjeta: null,
      plantarjeta: null,
      numerotarjeta: null,
      cupontarjeta: null,
      aprobtarjeta: null,
      banco: null,
      numerocheque: null,
      fechacheque: null,
      fechapago: null,
      importe: saldo > 0 ? saldo : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      agregarPago(values);
    },
  });

  const handleChangePago = (event) => {
    setErrorForma(false);
    setHelperTextForma(null);
    formik.setFieldValue("efectivo", null);
    formik.setFieldValue("tarjeta", null);
    formik.setFieldValue("plantarjeta", null);
    formik.setFieldValue("numerotarjeta", null);
    formik.setFieldValue("cupontarjeta", null);
    formik.setFieldValue("aprobtarjeta", null);
    formik.setFieldValue("banco", null);
    formik.setFieldValue("numerocheque", null);
    formik.setFieldValue("fechacheque", null);
    formik.setFieldValue("fechapago", null);
    formik.setFieldValue("importe", saldo > 0 ? saldo : "");
    setFormaPago(event.target.value);
  };

  const handleChangeEfectivo = (value) => {
    formik.setFieldValue("efectivo", value);
    formik.setFieldValue("importe", saldo > 0 ? saldo : "");
  };

  function buscarPlanesTarjeta(tarjeta) {
    if (tarjeta !== null) {
      let plan = "PLANES_" + tarjeta.tarjeta;
      formik.setFieldValue("plantarjeta", null);
      setPlanesTarjeta([]);
      const planes = tablafull
        .filter((obj) => obj.codigo_tabla === plan)
        .sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))
        .map((obj) => ({
          codigo: obj.valor,
          plan: obj.descripcion,
          cuotas: obj.cuotas,
          coeficiente: obj.coeficiente,
        }));

      setPlanesTarjeta(planes);
    } else {
      formik.setFieldValue("plantarjeta", null);
      setPlanesTarjeta([]);
    }
  }

  async function agregarPago(values) {
    let seguir = true;
    var row = {};
    let maxValue = newItems.reduce(
      (acc, item) => (acc = acc > item.id ? acc : item.id),
      0
    );

    if (formaPago.length === 0) {
      seguir = false;
      setErrorForma(true);
      setHelperTextForma("Seleccione una opción !");
    }
    if (formaPago === "Efectivo") {
      if (formik.values.efectivo === null) {
        formik.setFieldError("efectivo", "Campo Obligatorio.");
        seguir = false;
      }
      let lnImporte = Number(formik.values.importe);

      if (lnImporte <= 0) {
        formik.setFieldError("importe", "El importe debe ser mayor a 0 (cero)");
        seguir = false;
      }
    }
    if (formaPago === "Tarjeta") {
      if (formik.values.tarjeta === null) {
        formik.setFieldError("tarjeta", "Campo Obligatorio.");
        seguir = false;
      }
      if (formik.values.plantarjeta === null) {
        formik.setFieldError("plantarjeta", "Campo Obligatorio.");
        seguir = false;
      }
      if (formik.values.numerotarjeta === null) {
        formik.setFieldError("numerotarjeta", "Campo Obligatorio.");
        seguir = false;
      }
      if (formik.values.cupontarjeta === null) {
        formik.setFieldError("cupontarjeta", "Campo Obligatorio.");
        seguir = false;
      }
      if (formik.values.aprobtarjeta === null) {
        formik.setFieldError("aprobtarjeta", "Campo Obligatorio.");
        seguir = false;
      }
      let lnImporte = Number(formik.values.importe);

      if (lnImporte <= 0) {
        formik.setFieldError("importe", "El importe debe ser mayor a 0 (cero)");
        seguir = false;
      }
    }
    if (formaPago === "Cheque") {
      if (formik.values.banco === null) {
        formik.setFieldError("banco", "Campo Obligatorio.");
        seguir = false;
      }
      if (formik.values.numerocheque === null) {
        formik.setFieldError("numerocheque", "Campo Obligatorio.");
        seguir = false;
      }
      if (formik.values.fechacheque === null) {
        formik.setFieldError("fechacheque", "Campo Obligatorio.");
        seguir = false;
      }
      if (formik.values.fechapago === null) {
        formik.setFieldError("fechapago", "Campo Obligatorio.");
        seguir = false;
      }
      let lnImporte = Number(formik.values.importe);

      if (lnImporte <= 0) {
        formik.setFieldError("importe", "El importe debe ser mayor a 0 (cero)");
        seguir = false;
      }
    }
    if (seguir) {
      var rowp = {};
      rowp["id"] = maxValue + 1;
      rowp["formapago"] = formaPago;
      rowp["descripcion"] = "";
      rowp["efectivo"] = "";
      rowp["tarjeta"] = "";
      rowp["plantarjeta"] = "";
      rowp["numerotarjeta"] = "";
      rowp["cupontarjeta"] = "";
      rowp["aprobtarjeta"] = "";
      rowp["banco"] = "";
      rowp["numerocheque"] = "";
      rowp["fechacheque"] = "";
      rowp["fechapago"] = "";
      rowp["importe"] = Number(formik.values.importe);
      rowp["coeficiente"] = 0;
      rowp["intereses"] = 0;
      if (formaPago === "Efectivo") {
        rowp["efectivo"] = formik.values.efectivo.forma;
        rowp["descripcion"] = CapSentence(formik.values.efectivo.forma);
      } else if (formaPago === "Tarjeta") {
        rowp["tarjeta"] = formik.values.tarjeta.codigo;
        rowp["plantarjeta"] = formik.values.plantarjeta.codigo;
        rowp["descripcion"] = CapSentence(formik.values.plantarjeta.plan);
        rowp["numerotarjeta"] = formik.values.numerotarjeta;
        rowp["cupontarjeta"] = formik.values.cupontarjeta;
        rowp["aprobtarjeta"] = formik.values.aprobtarjeta;
        rowp["coeficiente"] = Number(formik.values.plantarjeta.coeficiente);
        if (
          Number(formik.values.plantarjeta.coeficiente) > 0 &&
          ["FA", "FB"].indexOf(comprobante) >= 0
        ) {
          rowp["intereses"] =
            Number(formik.values.importe) *
            (Number(formik.values.plantarjeta.coeficiente) - 1);
          rowp["importe"] += rowp["intereses"];
        }
      } else if (formaPago === "Cheque") {
        rowp["banco"] = formik.values.banco.codigo;
        rowp["descripcion"] =
          "Cheque banco " + CapSentence(formik.values.banco.banco);
        rowp["numerocheque"] = formik.values.numerocheque;
        rowp["fechacheque"] = formik.values.fechacheque
          .toISOString()
          .slice(0, 10);
        rowp["fechapago"] = formik.values.fechapago.toISOString().slice(0, 10);
      }
      newItems.push(rowp);

      const nextList = [...newItems];
      setNewItems(nextList);
      let sumValue = nextList.reduce(function (prev, cur) {
        return prev + parseFloat(cur.importe);
      }, 0);
      let sumIntereses = nextList.reduce(function (prev, cur) {
        return prev + parseFloat(cur.intereses);
      }, 0);

      setTotalPagos(sumValue);
      setTotalIntereses(sumIntereses);
    }
  }

  return (
    <>
      <div>
        <form key="item-form" onSubmit={formik.handleSubmit}>
          <Container maxWidth="xl">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 2,
                marginBottom: 0,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={1}>
                  <Box sx={{ display: "flex", justifyContent: "start" }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        bgcolor: "avatar",
                        marginTop: 2,
                        marginBottom: 0,
                        marginRight: 1,
                      }}
                    >
                      <AttachMoneyIcon />
                    </Avatar>
                    <Typography
                      sx={{ marginTop: 2, marginBottom: 0 }}
                      variant="h6"
                    >
                      Pagos
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={0.8}>
                  <FormControl component="fieldset" error={errorForma}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formaPago}
                      onChange={handleChangePago}
                    >
                      <FormControlLabel
                        value="Efectivo"
                        control={<BpRadio />}
                        label={
                          <Typography sx={{ fontSize: 14 }}>
                            Efectivo
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Tarjeta"
                        control={<BpRadio />}
                        label={
                          <Typography sx={{ fontSize: 14 }}>Tarjeta</Typography>
                        }
                      />
                      <FormControlLabel
                        value="Cheque"
                        control={<BpRadio />}
                        label={
                          <Typography sx={{ fontSize: 14 }}>Cheque</Typography>
                        }
                      />
                    </RadioGroup>
                    <FormHelperText>{helperTextForma}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={
                    formaPago === "Efectivo"
                      ? 3.5
                      : formaPago === "Cheque"
                      ? 2.5
                      : 1.5
                  }
                  sx={{
                    marginTop: 1,
                  }}
                >
                  {formaPago === "Efectivo" && (
                    <Autocomplete
                      id="efectivo"
                      options={optionsPago}
                      value={formik.values.efectivo}
                      autoHighlight
                      size="small"
                      onChange={(e, value) => handleChangeEfectivo(value)}
                      getOptionLabel={(option) => CapSentence(option.forma)}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Medio de Pago"
                          error={
                            formik.touched.efectivo &&
                            Boolean(formik.errors.efectivo)
                          }
                          helperText={
                            formik.touched.efectivo && formik.errors.efectivo
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                    />
                  )}
                  {formaPago === "Tarjeta" && (
                    <Autocomplete
                      id="tarjeta"
                      options={tarjetas}
                      value={formik.values.tarjeta}
                      autoHighlight
                      size="small"
                      onChange={(e, value) => {
                        formik.setFieldValue("tarjeta", value);
                        buscarPlanesTarjeta(value);
                      }}
                      getOptionLabel={(option) => CapSentence(option.tarjeta)}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tarjeta"
                          error={
                            formik.touched.tarjeta &&
                            Boolean(formik.errors.tarjeta)
                          }
                          helperText={
                            formik.touched.tarjeta && formik.errors.tarjeta
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                    />
                  )}
                  {formaPago === "Cheque" && (
                    <Autocomplete
                      id="banco"
                      options={bancos}
                      value={formik.values.banco}
                      autoHighlight
                      size="small"
                      onChange={(e, value) =>
                        formik.setFieldValue("banco", value)
                      }
                      getOptionLabel={(option) => CapSentence(option.banco)}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Banco"
                          error={
                            formik.touched.banco && Boolean(formik.errors.banco)
                          }
                          helperText={
                            formik.touched.banco && formik.errors.banco
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={formaPago === "Efectivo" ? 0.5 : 2}
                  sx={{
                    marginTop: formaPago === "Tarjeta" ? 1 : 0,
                  }}
                >
                  {formaPago === "Tarjeta" && (
                    <Autocomplete
                      id="plantarjeta"
                      options={planesTarjeta}
                      value={formik.values.plantarjeta}
                      autoHighlight
                      size="small"
                      onChange={(e, value) => {
                        formik.setFieldValue("plantarjeta", value);
                      }}
                      getOptionLabel={(option) => CapSentence(option.plan)}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cuotas"
                          error={
                            formik.touched.plantarjeta &&
                            Boolean(formik.errors.plantarjeta)
                          }
                          helperText={
                            formik.touched.plantarjeta &&
                            formik.errors.plantarjeta
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                    />
                  )}
                  {formaPago === "Cheque" && (
                    <TextField
                      id="numerocheque"
                      label="Número"
                      value={formik.values.numerocheque}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.numerocheque
                          ? formik.errors.numerocheque
                          : ""
                      }
                      error={
                        formik.touched.numerocheque &&
                        Boolean(formik.errors.numerocheque)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={formaPago === "Cheque" ? 1.5 : 2}
                  sx={{
                    marginTop: formaPago === "Cheque" ? 1 : 0,
                  }}
                >
                  {formaPago === "Tarjeta" && (
                    <TextField
                      id="numerotarjeta"
                      label="Número"
                      value={formik.values.numerotarjeta}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.numerotarjeta
                          ? formik.errors.numerotarjeta
                          : ""
                      }
                      error={
                        formik.touched.numerotarjeta &&
                        Boolean(formik.errors.numerotarjeta)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  {formaPago === "Cheque" && (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"en-gb"}
                    >
                      <DateField
                        id="fechacheque"
                        label="Fecha Cheque"
                        value={formik.values.fechacheque}
                        inputFormat="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        //defaultValue={dayjs(row.alta_usa)}
                        onChange={(value) =>
                          formik.setFieldValue("fechacheque", value)
                        }
                        size="small"
                        helperText={formik.errors.fechacheque}
                        error={Boolean(formik.errors.fechacheque)}
                      />
                    </LocalizationProvider>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={formaPago === "Tarjeta" ? 1 : 1.5}
                  sx={{
                    marginTop: formaPago === "Cheque" ? 1 : 0,
                  }}
                >
                  {formaPago === "Tarjeta" && (
                    <TextField
                      id="cupontarjeta"
                      label="Cupón"
                      value={formik.values.cupontarjeta}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.cupontarjeta
                          ? formik.errors.cupontarjeta
                          : ""
                      }
                      error={
                        formik.touched.cupontarjeta &&
                        Boolean(formik.errors.cupontarjeta)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  {formaPago === "Cheque" && (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"en-gb"}
                    >
                      <DateField
                        id="fechapago"
                        label="Fecha Pago"
                        value={formik.values.fechapago}
                        inputFormat="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        //defaultValue={dayjs(row.alta_usa)}
                        onChange={(value) =>
                          formik.setFieldValue("fechapago", value)
                        }
                        size="small"
                        helperText={formik.errors.fechapago}
                        error={Boolean(formik.errors.fechapago)}
                      />
                    </LocalizationProvider>
                  )}
                </Grid>
                <Grid item xs={12} lg={formaPago === "Tarjeta" ? 1.2 : 0.2}>
                  {formaPago === "Tarjeta" && (
                    <TextField
                      id="aprobtarjeta"
                      label="Aprobación"
                      value={formik.values.aprobtarjeta}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.aprobtarjeta
                          ? formik.errors.aprobtarjeta
                          : ""
                      }
                      error={
                        formik.touched.aprobtarjeta &&
                        Boolean(formik.errors.aprobtarjeta)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                </Grid>

                <Grid item xs={8} lg={1.5}>
                  <TextField
                    id="importe"
                    label="Importe"
                    disabled={formaPago === "Lista" ? true : false}
                    value={formik.values.importe}
                    helperText={
                      formik.touched.importe ? formik.errors.importe : null
                    }
                    error={
                      formik.touched.importe && Boolean(formik.errors.importe)
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} lg={1}>
                  <Tooltip title="Agegar Item">
                    <LoadingButton
                      onClick={formik.handleSubmit}
                      color="primary"
                      fullWidth
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<AddRoundedIcon />}
                      variant="contained"
                      sx={{
                        backgroundColor: "icongreen",
                        ":hover": {
                          bgcolor: "#475569", // theme.palette.primary.main
                          color: "white",
                        },
                        textTransform: "capitalize",
                        marginTop: 1,
                        height: 40,
                      }}
                    ></LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </form>
      </div>
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={confirmOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
}
