import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import crypto from "../Crypto";
import { ModeloGetUrlJson, ModeloDelete, ModeloPost } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import { formatNumberES, hasPermission } from "../utils/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ModalConfirmation from "../modals/ModalConfirmation";
import Tooltip from "@mui/material/Tooltip";
import ModalInformation from "../modals/ModalInformation";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import InsertUpdateCtaCte from "../InsertUpdateCtaCte";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MoneyIcon from "@mui/icons-material/Money";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BackPage from "../BackPage";
import PacienteHeader from "./PacienteHeader";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const PacienteCtaCte = () => {
  const inputRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [submitOk, setSubmitOk] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [allCtaCtes, setAllCtaCtes] = useState(null);
  const [allPagos, setAllPagos] = useState(null);
  const [allTratamientos, setAllTratamientos] = useState(null);
  const [aprobados, setAprobados] = useState(null);
  const [provisorios, setProvisorios] = useState(null);
  const [allItems, setAllItems] = useState(null);
  let Paciente = location.currentPaciente;
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [borrarId, setBorrarId] = useState(0);
  const [editarId, setEditarId] = useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowCtaCte, setRowCtaCte] = React.useState([]);
  let selectedItems = [];
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const fotopaciente = crypto.decryptDataStorage("dataFoto");
  const [nuevoCtaCte, setNuevoCtaCte] = useState(false);
  const [editarCtaCte, setEditarCtaCte] = useState(false);
  const [updateOk, setUpdateOk] = useState(false);
  const [saldoCtaCte, setSaldoCtaCte] = useState(0);
  const currentPaciente = crypto.decryptDataStorage("dataPaciente");
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  // Pass userRoles to components or functions that need it

  let currentPacientePrepaga =
    crypto.decryptDataStorage("currentpatientname") !== null &&
    crypto.decryptDataStorage("currentprepagacodigo") !== null
      ? crypto.decryptDataStorage("currentpatientname") +
        " (" +
        crypto.decryptDataStorage("currentprepagacodigo") +
        ")"
      : null;

  useEffect(() => {
    buscarCtaCtes();
  }, []);

  function BorrarAnularCtaCte(row) {
    setBorrarId(row.id);
    const operacion =
      row.fecha_transferencia_bas.length === 0 &&
      row.ticket.length === 0 &&
      row.tipo_prev !== "PR"
        ? "Borrar el movimiento ?"
        : "Anular la factura ?";
    setDisplayConfirmationModal(true);
    setModalMessage("Seguro quiere " + operacion);
  }

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };
  const hideInformationModal = () => {
    setModalErrorOpen(false);
    setDisplayConfirmationModal(false);
    setModalInformationOpen(false);
    setModalMessage(null);
  };

  const handleNew = () => {
    if (currentPaciente !== null) {
      if (currentPaciente.email !== null && currentPaciente.email.length > 0) {
        setNuevoCtaCte(true);
      } else {
        setModalMessage("Ingrese el email del paciente.");
        setModalInformationOpen(true);
      }
    } else {
      setModalMessage("Seleccione un paciente.");
      setModalInformationOpen(true);
    }
  };

  function confirmOk() {
    if (modalMessage === "Seguro quiere Borrar el movimiento ?") {
      EliminarCtaCte();
    } else {
      AnularCtaCte();
    }
  }

  async function EliminarCtaCte() {
    hideConfirmationModal();
    if (borrarId > 0) {
      let result = await ModeloDelete("factura", borrarId);

      if (result.error === false) {
        buscarCtaCtes();
      } else {
        const errormessage =
          result.errormessage.indexOf("Integrity constraint violation") >= 0
            ? "Error de Integridad Referencial. Elimine primero el recibo."
            : result.errormessage;
        setModalMessage(errormessage);
        setModalErrorOpen(true);
      }
    }
  }

  async function AnularCtaCte() {
    hideConfirmationModal();
    if (borrarId > 0) {
      try {
        setLoading(true);
        let result = await ModeloPost("factura", borrarId, "revertir", null);

        if (result.error === false) {
          buscarCtaCtes();
        } else {
          setLoading(false);
          setModalMessage(result.errormessage);
          setModalErrorOpen(true);
        }
      } catch (e) {
        setLoading(false);
        setModalMessage(e.message);
        setModalErrorOpen(true);
      }
    }
  }
  async function buscarCtaCtes() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    let params = "fullcta=SI";
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      let result = await ModeloGetUrlJson(
        "paciente",
        PacienteId,
        "vista-cta-cte",
        params
      );
      setLoading(false);
      if (result.error === false) {
        let sumSaldo = result.ctacte.reduce(function (prev, cur) {
          return prev + parseFloat(cur.saldo);
        }, 0);
        setSaldoCtaCte(sumSaldo);
        setAllCtaCtes(result.ctacte);
        setAllPagos(result.pagos);
        setAllTratamientos(result.tratamientos);
        setAllItems(null);
      } else {
      }
    } else {
      handleClickVolver();
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "2px",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const hideErrorModal = () => {
    setErrorMessage(null);
  };

  function handleClickVolver() {
    if (!nuevoCtaCte && !editarCtaCte) {
      history.push("/pacientes");
    } else {
      hideCtaCte();
    }
  }

  const hideCtaCte = () => {
    setNuevoCtaCte(false);
    setEditarCtaCte(false);
  };

  function handleClickItem(value, row) {
    if (value === true) {
      const addItem = {
        id: row.id,
        ctacte_id: row.ctacte_id,
        importe: row.importe,
      };
      selectedItems.push(addItem);
    } else {
      const newItems = selectedItems.filter((item) => item.id !== row.id);
      selectedItems = newItems;
    }
  }

  const handleEdit = (row) => {
    setRowCtaCte(row);
    setEditarCtaCte(true);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const isItemSelected = isSelected(row.id);
    return (
      <React.Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell align="right">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="right" component="th" scope="row">
            {row.id}
          </StyledTableCell>
          <StyledTableCell align="left">{row.fecha}</StyledTableCell>
          <StyledTableCell align="left">
            <Avatar
              variant="rounded"
              sx={{
                border: "1px solid ",
                color: "black",
                bgcolor:
                  ["FA", "FB"].indexOf(row.tipo_prev) >= 0
                    ? "#fecaca"
                    : ["RE", "NC"].indexOf(row.tipo_prev) >= 0
                    ? "#bbf7d0"
                    : "#dbeafe",
                fontSize: 14,
                fontWeight: "light",
                width: 30,
                height: 30,
                mr: -0.5,
              }}
            >
              {row.tipo_prev}
            </Avatar>
          </StyledTableCell>
          <StyledTableCell align="left">{row.referencia}</StyledTableCell>
          <StyledTableCell align="right">
            {row.presupuesto_id !== "0," ? row.presupuesto_id : null}
          </StyledTableCell>
          <StyledTableCell align="right">
            {formatNumberES(row.importe_bruto, 2)}
          </StyledTableCell>
          <StyledTableCell align="right">
            {formatNumberES(row.porc_bonificacion, 2)}
          </StyledTableCell>
          <StyledTableCell align="right">
            {row.debe > 0 && (
              <Avatar
                variant="rounded"
                sx={{
                  border: "1px solid ",
                  color: "black",
                  bgcolor:
                    ["FA", "FB"].indexOf(row.tipo_prev) >= 0
                      ? "#fecaca"
                      : ["RE", "NC"].indexOf(row.tipo_prev) >= 0
                      ? "#bbf7d0"
                      : "#dbeafe",
                  fontSize: 14,
                  width: 105,
                  height: 30,
                  mr: -0.5,
                }}
              >
                {formatNumberES(row.debe, 2)}
              </Avatar>
            )}
          </StyledTableCell>
          <StyledTableCell align="right">
            {row.haber > 0 && (
              <Avatar
                variant="rounded"
                sx={{
                  border: "1px solid ",
                  color: "black",
                  bgcolor:
                    ["FA", "FB"].indexOf(row.tipo_prev) >= 0
                      ? "#fecaca"
                      : ["RE", "NC"].indexOf(row.tipo_prev) >= 0
                      ? "#bbf7d0"
                      : "#dbeafe",
                  fontSize: 14,
                  width: 105,
                  height: 30,
                  mr: -0.5,
                }}
              >
                {formatNumberES(row.haber, 2)}
              </Avatar>
            )}
          </StyledTableCell>
          <StyledTableCell align="right">
            {row.saldo !== 0 && (
              <Avatar
                variant="rounded"
                sx={{
                  border: "1px solid ",
                  color: "black",
                  bgcolor: row.saldo > 0 ? "#fecaca" : "#bbf7d0",
                  fontSize: 14,
                  width: 105,
                  height: 30,
                  mr: -0.5,
                }}
              >
                {formatNumberES(row.saldo, 2)}
              </Avatar>
            )}
          </StyledTableCell>
          <StyledTableCell align="left">
            <Box
              sx={{
                textTransform: "capitalize",
              }}
            >
              {row.odontologo.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">{row.prefijo_cbte}</StyledTableCell>
          <StyledTableCell align="left">{row.ticket}</StyledTableCell>
          <StyledTableCell align="left">
            {hasPermission(userRoles, "ALTA CCTACTES") &&
              row.fecha_transferencia_bas.length === 0 &&
              row.ticket.length === 0 &&
              row.tipo_prev !== "PR" && (
                <Tooltip title="Borrar movimiento">
                  <DeleteIcon
                    cursor="pointer"
                    sx={{
                      color: "primary.red",
                      fontSize: 24,
                    }}
                    onClick={() => BorrarAnularCtaCte(row)}
                  />
                </Tooltip>
              )}
            {hasPermission(userRoles, "ALTA CCTACTES") &&
              row.fecha_transferencia_bas.length > 0 &&
              row.ticket.length > 0 &&
              ["FA", "FB"].indexOf(row.tipo_prev) >= 0 &&
              row.tipo_movimiento === "CO" && (
                <Tooltip title="Anular movimiento">
                  <DeleteForeverIcon
                    cursor="pointer"
                    sx={{
                      color: "primary.main",
                      fontSize: 24,
                    }}
                    onClick={() => BorrarAnularCtaCte(row)}
                  />
                </Tooltip>
              )}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  ml: 2,
                  mb: -1,
                  bgcolor: "blue100",
                  width: 1,
                }}
              >
                <Typography
                  sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    marginLeft: 2,
                    fontSize: 20,
                  }}
                >
                  Tratamientos
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="items">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 16 }} align="right">
                        Id
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Código
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Descripción
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="right">
                        Cantidad
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="right">
                        Precio
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="right">
                        Importe
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allTratamientos !== null &&
                      allTratamientos
                        .filter(
                          (t) =>
                            t.ctacte_id === row.id || t.referencia === row.id
                        )
                        .sort((a, b) => (a.id > b.id ? 1 : -1))
                        .map((rowi) => (
                          <TableRow hover key={rowi.id}>
                            <TableCell align="right" component="th" scope="row">
                              {rowi.id}
                            </TableCell>
                            <TableCell align="left">
                              <Avatar
                                variant="rounded"
                                sx={{
                                  border: "1px solid ",
                                  color: "primary.main",
                                  fontSize: 14,
                                  width: 90,
                                  height: 30,
                                  bgcolor: "white",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Open Sans",
                                    fontSize: 14,
                                    color: "black",
                                  }}
                                >
                                  {rowi.codigo}
                                </Typography>
                              </Avatar>
                            </TableCell>
                            <TableCell align="left">
                              {rowi.descripcion}
                            </TableCell>
                            <TableCell align="right">{rowi.cantidad}</TableCell>
                            <TableCell align="right">
                              {formatNumberES(rowi.precio, 2)}
                            </TableCell>
                            <TableCell align="right">
                              {formatNumberES(rowi.importe, 2)}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>

              <Box
                sx={{
                  ml: 2,
                  mb: -1,
                  bgcolor: "blue100",
                  mr: 2,
                  width: 1,
                }}
              >
                <Typography
                  sx={{
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2,
                    fontSize: 20,
                  }}
                >
                  Pagos
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="items">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 16 }} align="right">
                        Id
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Fecha
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Medio de pago
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Descripción
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="right">
                        Importe
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allPagos !== null &&
                      allPagos
                        .filter(
                          (p) =>
                            p.ctacte_id === row.id || p.referencia === row.id
                        )
                        .sort((a, b) => (a.fecha_usa > b.fecha_usa ? 1 : -1))
                        .map((rowi) => (
                          <TableRow hover key={rowi.id}>
                            <TableCell align="right" component="th" scope="row">
                              {rowi.id}
                            </TableCell>
                            <TableCell align="left">{rowi.fecha}</TableCell>
                            <TableCell align="left">
                              {rowi.tipo === "T" && (
                                <CreditCardIcon
                                  sx={{
                                    color: "primary.main",
                                    fontSize: 24,
                                    mr: { sm: 2 },
                                    ml: { xs: 2, sm: 2 },
                                  }}
                                />
                              )}
                              {rowi.tipo === "E" &&
                                (rowi.descripcion === "Dinero" ||
                                  rowi.descripcion === "Efectivo") && (
                                  <MoneyIcon
                                    sx={{
                                      color: "primary.main",
                                      fontSize: 24,
                                      mr: { sm: 2 },
                                      ml: { xs: 2, sm: 2 },
                                    }}
                                  />
                                )}
                              {rowi.tipo === "E" &&
                                rowi.descripcion === "Transferencia" && (
                                  <CurrencyExchangeIcon
                                    sx={{
                                      color: "primary.main",
                                      fontSize: 24,
                                      mr: { sm: 2 },
                                      ml: { xs: 2, sm: 2 },
                                    }}
                                  />
                                )}
                              {rowi.tipo === "C" && (
                                <AccountBalanceIcon
                                  sx={{
                                    color: "primary.main",
                                    fontSize: 24,
                                    mr: { sm: 2 },
                                    ml: { xs: 2, sm: 2 },
                                  }}
                                />
                              )}
                              {rowi.tipo}
                            </TableCell>
                            <TableCell align="left">
                              {rowi.descripcion}
                            </TableCell>
                            <TableCell align="right">
                              {formatNumberES(rowi.importe, 2)}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <BackPage
        text={
          !nuevoCtaCte && !editarCtaCte
            ? "Volver al Paciente"
            : "Volver a CtaCtes"
        }
        handleClick={handleClickVolver}
      />
      <PacienteHeader />
      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {!loading && !nuevoCtaCte && !editarCtaCte && (
        <Container maxWidth="xl">
          <Box sx={{ mr: 2, ml: 2, mb: 4 }}>
            <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Cuenta Corriente
              </Typography>
              {hasPermission(userRoles, "ALTA CCTACTES") && (
                <IconButton
                  aria-label="new"
                  onClick={handleNew}
                  sx={{ p: "1px", marginTop: -1 }}
                >
                  <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
                </IconButton>
              )}
            </Box>

            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Ver</StyledTableCell>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="left">Fecha</StyledTableCell>
                    <StyledTableCell align="left">Cbte.</StyledTableCell>
                    <StyledTableCell align="left">Ref.</StyledTableCell>
                    <StyledTableCell align="left">Pres.</StyledTableCell>
                    <StyledTableCell align="right">Bruto</StyledTableCell>
                    <StyledTableCell align="right">Bonif.</StyledTableCell>
                    <StyledTableCell align="right">Debe</StyledTableCell>
                    <StyledTableCell align="right">Haber</StyledTableCell>
                    <StyledTableCell align="right">Saldo</StyledTableCell>
                    <StyledTableCell align="left">Odontólogo</StyledTableCell>
                    <StyledTableCell align="left">Prefijo</StyledTableCell>
                    <StyledTableCell align="left">Ticket</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allCtaCtes !== null &&
                    allCtaCtes.length > 0 &&
                    allCtaCtes
                      .sort((a, b) => (a.orden > b.orden ? 1 : -1))
                      .map((row) => <Row key={row.id} row={row} />)}
                  {allCtaCtes !== null && allCtaCtes.length === 0 && (
                    <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                      <Typography variant="body1" gutterBottom>
                        Sin CtaCtes.
                      </Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Paper
            elevation={2}
            sx={{
              with: "90%",
              height: "auto",
              py: 0.75,
              mb: 3,
            }}
          >
            <div className="flex items-center justify-center">
              <Typography variant="h6">Saldo:</Typography>
              {saldoCtaCte !== 0 && (
                <Avatar
                  variant="rounded"
                  sx={{
                    border: "1px solid ",
                    color: "black",
                    bgcolor:
                      saldoCtaCte > 0
                        ? "#fecaca"
                        : saldoCtaCte < 0
                        ? "#bbf7d0"
                        : "#bfdbfe",
                    fontSize: 14,
                    width: 105,
                    height: 30,
                    mr: -0.5,
                    ml: 2,
                  }}
                >
                  {formatNumberES(saldoCtaCte, 2)}
                </Avatar>
              )}
            </div>
          </Paper>
        </Container>
      )}
      {nuevoCtaCte && (
        <InsertUpdateCtaCte
          allctacte={allCtaCtes}
          ctacterow={null}
          itemsctacte={null}
          buscarCoes={true}
          showModal={nuevoCtaCte}
          hideModal={hideCtaCte}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
          buscarCtaCtes={buscarCtaCtes}
        />
      )}
      {editarCtaCte && (
        <InsertUpdateCtaCte
          allctacte={allCtaCtes}
          ctacterow={rowCtaCte}
          itemsctacte={allItems.filter((i) => i.ctacte_id === rowCtaCte.id)}
          buscarCoes={true}
          showModal={nuevoCtaCte}
          hideModal={hideCtaCte}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
          buscarCtaCtes={buscarCtaCtes}
        />
      )}
      <BackPage
        text={
          !nuevoCtaCte && !editarCtaCte
            ? "Volver al Paciente"
            : "Volver a CtaCtes"
        }
        handleClick={handleClickVolver}
      />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={confirmOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
};
export default PacienteCtaCte;
