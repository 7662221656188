import React from "react";
//import './tailwind.output.css';
//import './index.css';
import App from "./App";
import Logout from "../src/components/Logout";
//import 'react-tooltip/dist/react-tooltip.css'
import { registerLicense } from "@syncfusion/ej2-base";
import { createRoot } from "react-dom/client";
import { render } from "preact/compat";

const logout = new Logout();

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<App />);
