import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import { useLocation } from "react-router-dom";
import crypto from "../Crypto";
import { ModeloGetUrl } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ModalInformation from "../modals/ModalInformation";
import BackPage from "../BackPage";
import RenderHistoriaClinica from "./RenderHistoriaClinica";
import PacienteHeader from "./PacienteHeader";

const PacienteHClinica = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allTratamientos, setAllTratamientos] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [backPage, setBackPage] = useState("pacientes");
  const location = useLocation();

  useEffect(() => {
    if (typeof location.backpage !== "undefined") {
      setBackPage(location.backpage);
    }
    buscarHClinica();
  }, []);

  async function buscarHClinica() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    const params = "paciente_id=" + PacienteId;
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      const result = await ModeloGetUrl(
        "paciente",
        null,
        "historia-clinica",
        params
      );
      setLoading(false);
      if (result.error === false) {
        if (result.listado.length > 0) {
          var ldToday = new Date();
          var lcDate =
            ldToday.getFullYear() +
            "-" +
            (ldToday.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            ldToday.getDate().toString().padStart(2, "0");
          const allData = result.listado
            .filter((g) => g.fecha_usa <= lcDate)
            .sort((a, b) => (a.fecha_usa > b.fecha_usa ? -1 : 1));
          setAllTratamientos(allData);
          crypto.encryptDataStorage("hctratamientos", allData);
        } else {
          setModalMessage("No se encontraron prestaciones.");
          setModalInformationOpen(true);
        }
      } else {
        history.push("/");
      }
    } else {
      handleClickVolver();
    }
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  function handleClickVolver() {
    history.push("/" + backPage);
  }
  return (
    <>
      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <PacienteHeader />
      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {!loading && allTratamientos !== null && (
        <div>
          <RenderHistoriaClinica alldata={allTratamientos} />
        </div>
      )}

      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
};
export default PacienteHClinica;
