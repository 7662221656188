import React, { useState } from 'react';

export default function CuadranteStd4Std(data) {
    const [testOdo, setTestOdo] = useState(true);

    const getSourceImage = (dienteimage) => {
        let sourceimage = '/iconos_odontograma/' + dienteimage
        return sourceimage
    }


    return (
        <div className="flex">
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">48</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="48" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">47</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="47" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">46</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="46" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">45</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="45" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">44</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="44" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">43</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="43" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">42</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" }  src={item.diente==="42" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">41</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente==="41" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>

        </div>
    );
}
