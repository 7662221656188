import React from "react";
import { TextField } from "@mui/material";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useField } from "formik";

const FormikCustomTextField = ({
  name,
  label,
  fieldType = "text",
  size = "small",
  width = "100%",
  autoFocus = false,
  autoComplete = "off",
  inputRef = null,
  nextInputRef = null,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const isError = Boolean(meta.touched && meta.error);

  // Maneja el evento KeyDown para detectar la tecla Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && nextInputRef) {
      e.preventDefault();
      nextInputRef.current?.focus(); // Enfocar el siguiente campo
    }
  };

  // Manejo de cambios para texto y fecha
  const handleChange = (value) => {
    if (fieldType === "date") {
      helpers.setValue(value);
    } else {
      field.onChange({ target: { name, value: value.target.value } });
    }
  };

  return fieldType === "date" ? (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
      <DateField
        {...field}
        size={size}
        label={label}
        value={field.value}
        onChange={(newValue) => handleChange(newValue)}
        error={isError}
        helperText={meta.touched && meta.error ? meta.error : " "} // Espacio vacío si no hay error
        FormHelperTextProps={{
          sx: { minHeight: "1.5em" }, // Reservar espacio para el helper text
        }}
        inputRef={inputRef}
        onKeyDown={handleKeyDown} // Manejo del evento Enter
        style={{ width }}
        autoFocus={autoFocus}
        inputProps={{ autoComplete: "off" }}
        {...props}
      />
    </LocalizationProvider>
  ) : (
    <TextField
      {...field}
      size={size}
      label={label}
      value={field.value}
      onChange={handleChange}
      error={isError}
      helperText={meta.touched && meta.error ? meta.error : " "} // Espacio vacío si no hay error
      FormHelperTextProps={{
        sx: { minHeight: "1.5em" }, // Reservar espacio para el helper text
      }}
      inputRef={inputRef}
      onKeyDown={handleKeyDown} // Manejo del evento Enter
      style={{ width }}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      {...props}
    />
  );
};

export default FormikCustomTextField;
