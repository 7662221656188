import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box } from "@mui/material";
import * as moment from "moment"; // Para manejar fechas
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importar el plugin

// Registrar los componentes de Chart.js, incluyendo DataLabels
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const CombinedChartPacientesCitados = ({ data }) => {
  const [chartData, setChartData] = useState(null);
  const today = moment().format("YYYY-MM-DD"); // Fecha de hoy

  useEffect(() => {
    // Si hay datos, procesarlos
    if (data && data.length > 0) {
      // Ordenar los datos por fecha
      const sortedData = [...data].sort((a, b) =>
        moment(a.fecha).diff(moment(b.fecha))
      );

      // Crear los datos del gráfico de barras
      const chartData = {
        labels: sortedData.map((item) => item.fecha_arg), // Mostrar fecha_arg en el eje X
        datasets: [
          {
            label: "Pacientes Citados",
            data: sortedData.map((item) => item.citas), // Usar el campo citas
            backgroundColor: sortedData.map((item) => {
              if (moment(item.fecha).isSame(today, "day")) {
                return "rgba(75, 192, 192, 0.6)"; // Color especial para la fecha actual
              } else if (moment(item.fecha).isAfter(today)) {
                return "rgba(255, 99, 132, 0.2)"; // Color para fechas futuras
              } else {
                return "#FF6384"; // Color para fechas pasadas
              }
            }),
            borderColor: sortedData.map((item) => {
              if (moment(item.fecha).isSame(today, "day")) {
                return "#4BC0C0"; // Borde especial para la fecha actual
              } else if (moment(item.fecha).isAfter(today)) {
                return "#FF6384"; // Borde para fechas futuras
              } else {
                return "#FF6384"; // Borde para fechas pasadas
              }
            }),
            borderWidth: sortedData.map(
              (item) =>
                moment(item.fecha).isAfter(today) ||
                moment(item.fecha).isSame(today, "day")
                  ? 2
                  : 0 // Borde para fechas futuras y la actual
            ),
          },
        ],
      };

      setChartData(chartData);
    }
  }, [data]);

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      {/* Ajustar el contenedor para ocupar el 100% */}
      {/* Gráfico de barras */}
      {chartData && (
        <div style={{ width: "100%" }}>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false, // Permite que el gráfico ocupe el 100% del contenedor
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: `Pacientes Citados`,
                },
                datalabels: {
                  display: true,
                  color: "black", // Color del texto
                  anchor: "end", // Posición del texto (final de la barra)
                  align: "top", // Alineación encima de la barra
                  formatter: (value) => value, // Mostrar el valor numérico
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Fecha",
                  },
                  ticks: {
                    maxRotation: 90, // Rotación máxima
                    minRotation: 90, // Rotación mínima, forzando que las fechas queden verticales
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Pacientes Citados",
                  },
                  beginAtZero: true,
                },
              },
            }}
            style={{ width: "100%", height: "100%" }} // Ajustar el gráfico para ocupar el 100%
          />
        </div>
      )}
    </Box>
  );
};

export default CombinedChartPacientesCitados;
