import React, { useState, useRef } from "react";
import "../../assetss/css/App.css";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CoeAutocomplete from "../CoeAutocomplete";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ModeloGetUrl } from "../ModeloCrud";
import { Typography } from "@mui/material";
import ModalInformation from "../modals/ModalInformation";
import { RenderReportParteDiarioTurnos } from "../RenderReportParteDiarioTurnos";
import { RenderReportParteDiarioPrestaciones } from "../RenderReportParteDiarioPrestaciones";
import { PrintButton } from "../PrintButton";

export default function ReporteTurnosPorAgenda() {
  let [selectedClinica, setSelectedClinica] = useState(null);
  let [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  let [selectedOdontologo, setSelectedOdontologo] = useState(null);
  let [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const now = new Date().toISOString().slice(0, 10);
  const [fechaDesde, setFechaDesde] = useState(dayjs(now));
  const [valueTipo, setValueTipo] = React.useState("turnos");
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const componentRef = useRef();

  const handleChangeTipo = (event) => {
    setValueTipo(event.target.value);
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  async function handleClickBuscar() {
    //  lcParam='centro_id='+ALLTRIM(pmCentro)+'&odontologo_id='+ALLTRIM(pmOdontologo)+'&especialidad_id='+ALLTRIM(STR(VAL(pmEspecialidad)))+'&fecha='+SUBSTR(DTOS(pmFecha),1,4)+'-'+SUBSTR(DTOS(pmFecha),5,2)+'-'+SUBSTR(DTOS(pmFecha),7,2)
    //	oCentrosOdontologosEspecialidadesAbm.datasessionid=pmDataSessionId
    //	lcResult = oCentrosOdontologosEspecialidadesAbm.GetView(lnId,"parte-diario","cParteDiario",lcParam)
    if (
      selectedClinica === null ||
      selectedEspecialidad === null ||
      selectedOdontologo === null
    ) {
      setModalMessage("Seleccione clínica, especialidad y odontólogo.");
      setModalInformationOpen(true);
      return;
    }
    setReportData(null);

    let result = "";
    setLoading(true);
    if (valueTipo.slice(0, 1) === "t") {
      const params =
        "fecha=" +
        fechaDesde.format().slice(0, 10) +
        "&centro_id=" +
        selectedClinica.id +
        "&especialidad_id=" +
        selectedEspecialidad.id +
        "&odontologo_id=" +
        selectedOdontologo.id;
      result = await ModeloGetUrl(
        "centro-odontologo-especialidad",
        null,
        "parte-diario",
        params
      );
    } else {
      const params =
        "fecha-desde=" +
        fechaDesde.format().slice(0, 10) +
        "&fecha-hasta=" +
        fechaDesde.format().slice(0, 10) +
        "&centro_id=" +
        selectedClinica.id +
        "&especialidad_id=" +
        selectedEspecialidad.id +
        "&odontologo_id=" +
        selectedOdontologo.id;
      result = await ModeloGetUrl(
        "tratamiento",
        null,
        "tratamientos-entre-fechas",
        params
      );
    }
    setLoading(false);
    if (result.error === false) {
      setReportData(result.listado);
    } else {
      alert(result.errormessage);
    }
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          marginBottom: 4,
          marginLeft: 1,
        }}
      >
        <Typography variant="h6">Parte Diario</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "start",
          marginTop: 1,
          marginBottom: 5,
          marginLeft: 1,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={"en-gb"}
        >
          <DatePicker
            label="Fecha Desde"
            value={fechaDesde}
            slotProps={{ textField: { size: "small" } }}
            onChange={(newValue) => setFechaDesde(newValue)}
            sx={{
              height: "1rem",
              width: "17.8rem",
            }}
          />
        </LocalizationProvider>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={valueTipo}
          onChange={handleChangeTipo}
          sx={{
            marginLeft: { xs: 0, md: 4 },
            marginTop: { xs: 6, md: 0 },
          }}
        >
          <FormControlLabel value="turnos" control={<Radio />} label="Turnos" />
          <FormControlLabel
            value="prestaciones"
            control={<Radio />}
            label="Prestaciones"
          />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          marginBottom: 5,
        }}
      >
        <CoeAutocomplete
          selectedClinica={selectedClinica}
          selectedEspecialidad={selectedEspecialidad}
          selectedOdontologo={selectedOdontologo}
          setSelectedClinica={setSelectedClinica}
          setSelectedEspecialidad={setSelectedEspecialidad}
          setSelectedOdontologo={setSelectedOdontologo}
          handlebusqueda={handleClickBuscar}
          loading={loading}
          onlyEspecialidad={true}
        />
      </Box>
      {reportData !== null &&
        reportData.length > 0 &&
        valueTipo.slice(0, 1) === "t" && (
          <Box
            sx={{
              marginLeft: 3,
              marginTop: 4,
              marginBottom: 1,
            }}
          >
            <div style={{ display: "none" }}>
              <RenderReportParteDiarioTurnos
                ref={componentRef}
                allData={reportData}
              />
            </div>
            <PrintButton
              componentToPrint={componentRef}
              text={"Imprimir parte diario"}
              beforePrint={null}
              buttonType={"button"}
            />
          </Box>
        )}
      {reportData !== null &&
        reportData.length > 0 &&
        valueTipo.slice(0, 1) === "p" && (
          <Box
            sx={{
              marginLeft: 3,
              marginTop: 4,
              marginBottom: 1,
            }}
          >
            <div style={{ display: "none" }}>
              <RenderReportParteDiarioPrestaciones
                ref={componentRef}
                allData={reportData}
              />
            </div>
            <PrintButton
              componentToPrint={componentRef}
              text={"Imprimir parte de prestaciones"}
              beforePrint={null}
              buttonType={"button"}
            />
          </Box>
        )}

      {reportData !== null && reportData.length === 0 && (
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          No se encontraron resultados.
        </Typography>
      )}
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
      />
    </Container>
  );
}
