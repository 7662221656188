import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  ModeloGetUrl,
  ModeloGetUrlJson,
  ModeloUpdate,
  ModeloPost,
} from "./ModeloCrud";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CapSentence, BpRadio } from "./utils/Utils";
import * as yup from "yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import esmx from "dayjs/locale/es-mx";
import { TimelineOppositeContent } from "@mui/lab";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import crypto from "./Crypto";
import ErrorIcon from "@mui/icons-material/Error";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ModalInformation from "./modals/ModalInformation";
import ModalConfirmation from "./modals/ModalConfirmation";
import { CapSentenceArray } from "./utils/Utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { formatNumberES } from "./utils/Utils";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import RadioGroup from "@mui/material/RadioGroup";
import TableRowsIcon from "@mui/icons-material/TableRows";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const validationSchema = yup.object({
  codigo: yup.object().nullable(true),
  presupuesto: yup.object().nullable(true),
  cantidad: yup
    .number()
    .typeError("Campo Numérico.")
    .required("Campo Obligatorio.")
    .min(1, "Mínimo 1.")
    .max(20, "Máximo 20."),
  importe: yup
    .number()
    .nullable(true)
    .min(0, "Ingrese un valor positivo.")
    .typeError("Campo numérico"),
});

export default function InsertCtaCteItem({
  presupuestos,
  newItems,
  setNewItems,
  setTotalBruto,
  setTotalNeto,
  bonificacion,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const piezasDentales = crypto.decryptDataStorage("piezas_dentales");
  const nomencladores = CapSentenceArray(
    crypto.decryptDataStorage("nomencladores"),
    ["codigo_descripcion"]
  );
  const planId = crypto.decryptDataStorage("currentplanid");
  const tasaIva = crypto.decryptDataStorage("currenttasaiva");
  const [loading, setLoading] = useState(false);
  const [listaPrecios, setListaPrecios] = useState("Lista");

  const cantidadInputRef = useRef(null);
  const importeInputRef = useRef(null);
  const buttonRef = useRef(null);

  function confirmOk() {
    if (modalMessage === "El importe es mayor al saldo. Continua igual ?") {
      hideConfirmationModal();
      AddItem();
    }
  }

  function AddItem() {
    var row = {};
    let maxValue = newItems.reduce(
      (acc, item) => (acc = acc > item.id ? acc : item.id),
      0
    );
    row["id"] = maxValue + 1;
    row["tipo"] = "P";
    row["presupuesto_id"] = formik.values.presupuesto.id;
    row["codigo"] = "81:00:00";
    row["nomenclador_id"] = null;
    row["descripcion"] =
      "Tratamiento de " + formik.values.presupuesto.especialidad;
    row["cantidad"] = 1;
    row["precio"] = formik.values.importe;
    row["importe"] = formik.values.importe;
    row["tasa_iva"] = tasaIva;
    row["neto_iva"] = Number(row["importe"] / (1 + tasaIva / 100)).toFixed(2);
    row["importe_iva"] = (
      Number(row["importe"]) - Number(row["neto_iva"])
    ).toFixed(2);
    newItems.push(row);

    const nextList = [...newItems];
    setNewItems(nextList);
    let sumValue = nextList.reduce(function (prev, cur) {
      return prev + parseFloat(cur.importe);
    }, 0);
    setTotalBruto(sumValue);
    if (bonificacion > 0) {
      const neto = sumValue - (sumValue * bonificacion) / 100;
      setTotalNeto(neto);
    } else {
      setTotalNeto(sumValue);
    }
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues: {
      cantidad: 1,
      importe: null,
      codigo: null,
      presupuesto: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      agregarItem(values);
    },
  });

  const handleChangeLista = (event) => {
    formik.setFieldValue("cantidad", 1);
    formik.setFieldValue("importe", "");
    formik.setFieldValue("codigo", null);
    formik.setFieldValue("presupuesto", null);
    setListaPrecios(event.target.value);
  };

  const handleKeyDownTextField = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 2].focus();
      event.preventDefault();
    }
  };

  const handleKeyDownCodigo = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      event.preventDefault(); // Prevent form submission
      if (cantidadInputRef.current) {
        cantidadInputRef.current.focus();
      }
    }
  };

  const handleKeyDownPresupuesto = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      event.preventDefault(); // Prevent form submission
      if (importeInputRef.current) {
        importeInputRef.current.focus();
      }
    }
  };

  const handleKeyDownCantidad = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 4].focus();
      event.preventDefault();
    }
  };

  const handleKeyDownImporte = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 2].focus();
      event.preventDefault();
    }
  };

  async function agregarItem(values) {
    let seguir = true;
    var row = {};
    let maxValue = newItems.reduce(
      (acc, item) => (acc = acc > item.id ? acc : item.id),
      0
    );

    if (listaPrecios === "Lista") {
      if (formik.values.codigo === null) {
        formik.setFieldError("codigo", "Campo Obligatorio.");
        seguir = false;
      } else {
        setLoading(true);
        let result = await ModeloGetUrl(
          "plan-prepaga",
          planId,
          "precios-nomenclador/" + formik.values.codigo.nomenclador_id,
          null
        );
        setLoading(false);

        if (result.error === false) {
          if (result.listado.length > 0) {
            let lnImporte = Number(result.listado[0].precio);
            if (lnImporte <= 0) {
              formik.setFieldError(
                "importe",
                "El importe debe ser mayor a 0 (cero)"
              );
              seguir = false;
            } else {
              formik.setFieldValue("importe", lnImporte);

              row["id"] = maxValue + 1;
              row["tipo"] = "N";
              row["presupuesto_id"] = 0;
              row["codigo"] = formik.values.codigo.codigo;
              row["nomenclador_id"] = formik.values.codigo.nomenclador_id;
              row["descripcion"] = CapSentence(
                formik.values.codigo.descripcion
              );
              row["cantidad"] = formik.values.cantidad;
              row["precio"] = lnImporte;
              row["importe"] = formik.values.cantidad * lnImporte;
              row["tasa_iva"] = tasaIva;
              row["neto_iva"] = Number(
                row["importe"] / (1 + tasaIva / 100)
              ).toFixed(2);
              row["importe_iva"] = (
                Number(row["importe"]) - Number(row["neto_iva"])
              ).toFixed(2);
              newItems.push(row);

              const nextList = [...newItems];
              setNewItems(nextList);
              let sumValue = nextList.reduce(function (prev, cur) {
                return prev + parseFloat(cur.importe);
              }, 0);
              setTotalBruto(sumValue);
              if (bonificacion > 0) {
                const neto = sumValue - (sumValue * bonificacion) / 100;
                setTotalNeto(neto);
              } else {
                setTotalNeto(sumValue);
              }
              formik.resetForm();
            }
          } else {
            formik.setFieldError(
              "importe",
              "El importe debe ser mayor a 0 (cero)"
            );
            seguir = false;
          }
        }
      }
    }
    if (listaPrecios === "Presupuesto") {
      if (formik.values.presupuesto === null) {
        formik.setFieldError("presupuesto", "Campo Obligatorio.");
        seguir = false;
      } else {
        let lnImporte = Number(formik.values.importe);
        if (lnImporte <= 0) {
          formik.setFieldError(
            "importe",
            "El importe debe ser mayor a 0 (cero)"
          );
          seguir = false;
        } else {
          if (lnImporte > Number(formik.values.presupuesto.saldo)) {
            setDisplayConfirmationModal(true);
            setModalMessage("El importe es mayor al saldo. Continua igual ?");
          } else {
            AddItem();
          }
        }
      }
    }
    if (!seguir) {
      return;
    }
  }

  const onCodigoChange = (event, values) => {
    formik.setFieldValue("codigo", values);
  };

  const onPresupuestoChange = (event, values) => {
    formik.setFieldValue("presupuesto", values);
  };

  const onPiezaChange = (event, values) => {
    formik.setFieldValue("pieza", values);
  };

  return (
    <>
      <div>
        <form key="item-form" onSubmit={formik.handleSubmit}>
          <Container maxWidth="xl">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 1,
                marginBottom: 0,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={1.5}>
                  <Box sx={{ display: "flex", justifyContent: "start" }}>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        bgcolor: "avatar",
                        marginTop: 2,
                        marginBottom: 0,
                        marginRight: 1,
                      }}
                    >
                      <TableRowsIcon />
                    </Avatar>
                    <Typography
                      sx={{ marginTop: 2, marginBottom: 0 }}
                      variant="h6"
                    >
                      Items
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={1.5}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={listaPrecios}
                    onChange={handleChangeLista}
                  >
                    <FormControlLabel
                      value="Lista"
                      control={<BpRadio />}
                      label={
                        <Typography sx={{ fontSize: 14 }}>Lista</Typography>
                      }
                    />
                    <FormControlLabel
                      value="Presupuesto"
                      control={<BpRadio />}
                      label={
                        <Typography sx={{ fontSize: 14 }}>
                          Presupuesto
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  sx={{
                    marginTop: 1,
                  }}
                >
                  {listaPrecios === "Lista" && (
                    <Autocomplete
                      id="codigo"
                      options={nomencladores}
                      value={formik.values.codigo}
                      autoHighlight
                      size="small"
                      onChange={onCodigoChange}
                      getOptionLabel={(option) =>
                        CapSentence(option.codigo_descripcion.replace(/:/g, ""))
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={handleKeyDownCodigo}
                          label="Código"
                          error={
                            formik.touched.codigo &&
                            Boolean(formik.errors.codigo)
                          }
                          helperText={
                            formik.touched.codigo && formik.errors.codigo
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                    />
                  )}
                  {listaPrecios === "Presupuesto" && (
                    <Autocomplete
                      id="presupuesto"
                      options={presupuestos}
                      value={formik.values.presupuesto}
                      autoHighlight
                      size="small"
                      onChange={onPresupuestoChange}
                      getOptionLabel={(option) =>
                        option.tipo_prev + " " + option.fecha.toString()
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={handleKeyDownPresupuesto}
                          label="Presupuesto"
                          error={
                            formik.touched.presupuesto &&
                            Boolean(formik.errors.presupuesto)
                          }
                          helperText={
                            formik.touched.presupuesto &&
                            formik.errors.presupuesto
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        return (
                          <li {...props}>
                            <React.Fragment>
                              <table>
                                <tbody>
                                  <tr>
                                    <td align="left" style={{ width: 100 }}>
                                      {option.tipo_prev}
                                    </td>
                                    <td align="left" style={{ width: 200 }}>
                                      {option.fecha}
                                    </td>
                                    <td
                                      align="right"
                                      style={{
                                        width: 200,
                                      }}
                                    >
                                      {option.importe_bruto}
                                    </td>
                                    <td align="right" style={{ width: 200 }}>
                                      {option.saldo}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </React.Fragment>
                          </li>
                        );
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={1.5}>
                  <TextField
                    id="cantidad"
                    onKeyDown={handleKeyDownCantidad}
                    inputRef={(el) => (cantidadInputRef.current = el)}
                    label="Cantidad"
                    disabled={listaPrecios !== "Lista" ? true : false}
                    value={formik.values.cantidad}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.cantidad ? formik.errors.cantidad : ""
                    }
                    error={
                      formik.touched.cantidad && Boolean(formik.errors.cantidad)
                    }
                    margin="dense"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={8} lg={1.5}>
                  <TextField
                    id="importe"
                    onKeyDown={handleKeyDownImporte}
                    inputRef={(el) => (importeInputRef.current = el)}
                    label="Importe"
                    disabled={listaPrecios === "Lista" ? true : false}
                    value={formik.values.importe}
                    helperText={
                      formik.touched.importe ? formik.errors.importe : null
                    }
                    error={
                      formik.touched.importe && Boolean(formik.errors.importe)
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    margin="dense"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} lg={1}>
                  <Tooltip title="Agegar Item">
                    <LoadingButton
                      onClick={formik.handleSubmit}
                      inputRef={(el) => (buttonRef.current = el)}
                      color="primary"
                      fullWidth
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<AddRoundedIcon />}
                      variant="contained"
                      sx={{
                        backgroundColor: "icongreen",
                        ":hover": {
                          bgcolor: "#475569", // theme.palette.primary.main
                          color: "white",
                        },
                        textTransform: "capitalize",
                        marginTop: 1,
                        height: 40,
                      }}
                    ></LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </form>
      </div>
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={confirmOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
}
