import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import crypto from "../Crypto";
import { ModeloGetUrlJson, ModeloDelete, ModeloPost } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import { formatNumberES } from "../utils/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ModalConfirmation from "../modals/ModalConfirmation";
import Tooltip from "@mui/material/Tooltip";
import ModalInformation from "../modals/ModalInformation";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import InsertUpdateCtaCte from "../InsertUpdateCtaCte";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MoneyIcon from "@mui/icons-material/Money";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BackPage from "../BackPage";
import PacienteHeader from "./PacienteHeader";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { CapSentence } from "../utils/Utils";

const PacientePlanTratamiento = () => {
  const inputRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [submitOk, setSubmitOk] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [allPlanes, setAllPlanes] = useState(null);
  const [allDerivaciones, setallDerivaciones] = useState(null);
  const [allSeguimiento, setallSeguimiento] = useState(null);
  const [aprobados, setAprobados] = useState(null);
  const [provisorios, setProvisorios] = useState(null);
  const [allItems, setAllItems] = useState(null);
  let Paciente = location.currentPaciente;
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [borrarId, setBorrarId] = useState(0);
  const [editarId, setEditarId] = useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowCtaCte, setRowCtaCte] = React.useState([]);
  let selectedItems = [];
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const fotopaciente = crypto.decryptDataStorage("dataFoto");
  const [nuevoCtaCte, setNuevoCtaCte] = useState(false);
  const [editarCtaCte, setEditarCtaCte] = useState(false);
  const [updateOk, setUpdateOk] = useState(false);
  const [saldoCtaCte, setSaldoCtaCte] = useState(0);
  const currentPaciente = crypto.decryptDataStorage("dataPaciente");
  let currentPacientePrepaga =
    crypto.decryptDataStorage("currentpatientname") !== null &&
    crypto.decryptDataStorage("currentprepagacodigo") !== null
      ? crypto.decryptDataStorage("currentpatientname") +
        " (" +
        crypto.decryptDataStorage("currentprepagacodigo") +
        ")"
      : null;

  useEffect(() => {
    buscarPlanes();
  }, []);

  function BorrarAnularCtaCte(row) {
    setBorrarId(row.id);
    const operacion =
      row.fecha_transferencia_bas.length === 0 &&
      row.ticket.length === 0 &&
      row.tipo_prev !== "PR"
        ? "Borrar el movimiento ?"
        : "Anular la factura ?";
    setDisplayConfirmationModal(true);
    setModalMessage("Seguro quiere " + operacion);
  }

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };
  const hideInformationModal = () => {
    setModalErrorOpen(false);
    setDisplayConfirmationModal(false);
    setModalInformationOpen(false);
    setModalMessage(null);
  };

  async function buscarPlanes() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      let result = await ModeloGetUrlJson(
        "paciente",
        PacienteId,
        "vista-planes-tratamiento",
        null
      );
      setLoading(false);
      if (result.error === false) {
        setAllPlanes(result.planes);
        setallDerivaciones(result.derivaciones);
        setallSeguimiento(result.seguimiento);
      } else {
      }
    } else {
      handleClickVolver();
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "2px",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const hideErrorModal = () => {
    setErrorMessage(null);
  };

  function handleClickVolver() {
    if (!nuevoCtaCte && !editarCtaCte) {
      history.push("/pacientes");
    } else {
      hideCtaCte();
    }
  }

  const hideCtaCte = () => {
    setNuevoCtaCte(false);
    setEditarCtaCte(false);
  };

  function handleClickItem(value, row) {
    if (value === true) {
      const addItem = {
        id: row.id,
        ctacte_id: row.ctacte_id,
        importe: row.importe,
      };
      selectedItems.push(addItem);
    } else {
      const newItems = selectedItems.filter((item) => item.id !== row.id);
      selectedItems = newItems;
    }
  }

  const handleEdit = (row) => {
    setRowCtaCte(row);
    setEditarCtaCte(true);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const isItemSelected = isSelected(row.id);
    return (
      <React.Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell align="right">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="right" component="th" scope="row">
            {row.id}
          </StyledTableCell>
          <StyledTableCell align="left">{row.fecha_arg}</StyledTableCell>
          <StyledTableCell align="left">{row.especialidad}</StyledTableCell>
          <StyledTableCell align="left">
            {CapSentence(row.odontologo)}
          </StyledTableCell>
          <StyledTableCell align="left">{row.diagnostico}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  ml: 2,
                  mb: -1,
                  mr: 4,
                  bgcolor: "blue100",
                  width: "95%",
                }}
              >
                <Typography
                  sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    marginLeft: 2,
                    fontSize: 20,
                  }}
                >
                  Derivaciones
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="items">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 16 }} align="right">
                        Id
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Especialidad
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Piezas
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Notas
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allDerivaciones !== null &&
                      allDerivaciones
                        .filter((d) => d.pt_id === row.id)
                        .sort((a, b) => (a.de_id > b.de_id ? 1 : -1))
                        .map((rowi) => (
                          <TableRow hover key={rowi.de_id}>
                            <TableCell align="right" component="th" scope="row">
                              {rowi.de_id}
                            </TableCell>
                            <TableCell align="left">
                              {rowi.especialidad_de}
                            </TableCell>
                            <TableCell align="left">{rowi.obs}</TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>

              <Box
                sx={{
                  ml: 2,
                  mb: -1,
                  bgcolor: "blue100",
                  width: "95%",
                }}
              >
                <Typography
                  sx={{
                    marginTop: 0,
                    marginBottom: 1,
                    marginLeft: 2,
                    fontSize: 20,
                  }}
                >
                  Notas
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="items">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 16 }} align="right">
                        Id
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Fecha
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Especialidad
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Odontólogo
                      </TableCell>
                      <TableCell sx={{ fontSize: 16 }} align="left">
                        Notas
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allSeguimiento !== null &&
                      allSeguimiento
                        .filter((s) => s.pt_id === row.id)
                        .sort((a, b) => (a.fecha_usa > b.fecha_usa ? 1 : -1))
                        .map((rowi) => (
                          <TableRow hover key={rowi.pts_id}>
                            <TableCell align="right" component="th" scope="row">
                              {rowi.pts_id}
                            </TableCell>
                            <TableCell align="left">{rowi.fecha}</TableCell>
                            <TableCell align="left">
                              {rowi.especialidad}
                            </TableCell>
                            <TableCell align="left">
                              {rowi.odontologo}
                            </TableCell>
                            <TableCell align="left">
                              {rowi.observaciones}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <BackPage
        text={
          !nuevoCtaCte && !editarCtaCte
            ? "Volver al Paciente"
            : "Volver a Planes"
        }
        handleClick={handleClickVolver}
      />
      <PacienteHeader />
      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {!loading && !nuevoCtaCte && !editarCtaCte && (
        <Container maxWidth="xl">
          <Box sx={{ mr: 2, ml: 2, mb: 4 }}>
            <Box sx={{ display: "flex", marginTop: 2, marginBottom: 1 }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Planes de Tratamiento
              </Typography>
            </Box>

            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Ver</StyledTableCell>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="left">Fecha</StyledTableCell>
                    <StyledTableCell align="left">Especialidad</StyledTableCell>
                    <StyledTableCell align="left">Odontólogo</StyledTableCell>
                    <StyledTableCell align="left">Notas</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allPlanes !== null &&
                    allPlanes.length > 0 &&
                    allPlanes
                      .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                      .map((row) => <Row key={row.id} row={row} />)}
                  {allPlanes !== null && allPlanes.length === 0 && (
                    <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                      <Typography variant="body1" gutterBottom>
                        Sin Planes.
                      </Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
      <BackPage text={"Volver al Paciente"} handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
};
export default PacientePlanTratamiento;
