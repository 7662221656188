import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { ModeloUpdate, ModeloPost } from "./ModeloCrud";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CapSentence } from "./utils/Utils";
import * as yup from "yup";
import FormControlLabel from "@mui/material/FormControlLabel";
import crypto from "./Crypto";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ModalInformation from "./modals/ModalInformation";
import InsertCtaCteItem from "./InsertCtaCteItem";
import InsertCtaCtePago from "./InsertCtaCtePago";
import SaveCancelButtons from "./SaveCancelButtons";
import { CapSentenceArray, BpRadio } from "./utils/Utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { formatNumberES } from "./utils/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import RadioGroup from "@mui/material/RadioGroup";
import DescriptionIcon from "@mui/icons-material/Description";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Fade from "@mui/material/Fade";

const validationSchema = yup.object({
  coe: yup.object().required("Seleccione un Odontólogo"),
  caja: yup.object().required("Seleccione una Caja"),
  cbte: yup.object().required("Seleccione un Comprobante"),
  bonificacion: yup
    .number()
    .typeError("Campo numérico")
    .min(0, "La bonificación no puede ser menor a 0")
    .max(99, "La bonificación debe ser menor a 100"),
  prefijo: yup
    .string()
    .nullable(true)
    .matches(/^[0-9]+$/, "Campo numérico")
    .min(4, "4 dígitos")
    .max(4, "4 dígitos")
    .typeError("Campo numérico"),
  numero: yup
    .string()
    .nullable(true)
    .matches(/^[0-9]+$/, "Campo numérico")
    .min(1, "mínimo 1 dígitos")
    .max(8, "máxino 8 dígitos")
    .typeError("Campo numérico"),
  importe: yup.number().nullable(true).typeError("Campo numérico"),
});

export default function InsertUpdateCtaCte({
  allctacte,
  ctacterow,
  itemsctacte,
  buscarCoes,
  hideModal,
  buscarCtaCtes,
}) {
  const [allCoes, setAllCoes] = useState(null);
  const [myloading, setMyLoading] = React.useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  let [newItems, setNewItems] = useState([]);
  const [newPagos, setNewPagos] = useState([]);
  const [allErrors, setAllErrors] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const hideAlert = () => {
    setAllErrors([]);
    setShowAlert(false);
  };
  const hideInformationModal = () => {
    setModalMessage(null);
  };
  const [expanded, setExpanded] = useState(false);
  const [piezasDentales, setPiezasDentales] = useState(null);
  const [nomencladores, setNomencladores] = useState(null);
  const [cajas, setCajas] = useState(null);
  const [cbtes, setCbtes] = useState(null);
  const [fiscal, setFiscal] = useState("Fiscal");
  const [tipoMovimiento, setTipoMovimiento] = useState("CO");
  const [tipopago, setTipoPago] = useState("CO");
  const [ctaReferencias, setCtaReferencias] = useState(null);
  const [totalBrutoItems, setTotalBrutoItems] = useState(0);
  const [totalNetoItems, setTotalNetoItems] = useState(0);
  const [totalPagos, setTotalPagos] = useState(0);
  const [totalIntereses, setTotalIntereses] = useState(0);
  const [saldo, setSaldo] = useState(null);
  const tasaIva = crypto.decryptDataStorage("currenttasaiva");

  const formik = useFormik({
    initialValues: {
      coe: null,
      caja: null,
      cbte: null,
      referencia: null,
      prefijo: null,
      numero: null,
      importe: null,
      tipo_movimiento: ctacterow !== null ? ctacterow.tipo_movimiento : "CO",
      tipo_cbte: ctacterow !== null ? ctacterow.tipo_cbte : "FB",
      bonificacion: ctacterow !== null ? ctacterow.porc_bonificacion : 0.0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let seguir = true;

      if (
        formik.values.cbte !== null &&
        ["RE", "NC", "RI"].indexOf(formik.values.cbte.cbte) >= 0 &&
        formik.values.referencia === null
      ) {
        formik.setFieldError("referencia", "Campo Obligatorio.");
        seguir = false;
      }
      if (
        formik.values.cbte !== null &&
        ["RE", "NC", "RI"].indexOf(formik.values.cbte.cbte) >= 0 &&
        Number(formik.values.importe) <= 0
      ) {
        formik.setFieldError("importe", "Campo Obligatorio.");
        seguir = false;
      }
      if (
        fiscal === "Manual" &&
        (formik.values.prefijo === null || formik.values.prefijo.trim() === "")
      ) {
        formik.setFieldError("prefijo", "Campo Obligatorio.");
        seguir = false;
      }
      if (
        fiscal === "Manual" &&
        (formik.values.numero === null || formik.values.numero.trim() === "")
      ) {
        formik.setFieldError("numero", "Campo Obligatorio.");
        seguir = false;
      }
      if (seguir) {
        EmitirCtaCte();
      } else {
        alert("Revise campos invalidos.");
      }
    },
  });

  const ldToday = new Date();
  const lcDate =
    ldToday.getFullYear() +
    "-" +
    (ldToday.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    ldToday.getDate().toString().padStart(2, "0");

  function borrarItem(id) {
    const tmpItems = newItems.filter((i) => i.id !== id);
    const tempItems = [...tmpItems];
    setNewItems(tempItems);
    actualizarTotales(formik.values.bonificacion, tempItems);
    setSaldo(totalNetoItems + totalIntereses - totalPagos);
  }

  function borrarPago(id) {
    const tmpPagos = newPagos.filter((p) => p.id !== id);
    const tempPagos = [...tmpPagos];
    setNewPagos(tempPagos);
    actualizarTotalesPagos(tempPagos);
  }

  function actualizarTotales(pbonificacion, pItems) {
    let sumValue = pItems.reduce(function (prev, cur) {
      return prev + parseFloat(cur.importe);
    }, 0);

    setTotalBrutoItems(sumValue);
    if (pbonificacion > 0) {
      const neto = sumValue - (sumValue * pbonificacion) / 100;
      setTotalNetoItems(neto);
      setSaldo(neto + totalIntereses - totalPagos);
    } else {
      setTotalNetoItems(sumValue);
      setSaldo(sumValue + totalIntereses - totalPagos);
    }
  }

  function actualizarTotalesPagos(pPagos) {
    let sumValue = pPagos.reduce(function (prev, cur) {
      return prev + parseFloat(cur.importe);
    }, 0);
    let sumIntereses = pPagos.reduce(function (prev, cur) {
      return prev + parseFloat(cur.intereses);
    }, 0);
    setTotalPagos(sumValue);
    setTotalIntereses(sumIntereses);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "2px",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleChangeFiscal = (event) => {
    formik.setFieldValue("prefijo", null);
    formik.setFieldValue("numero", null);
    setFiscal(event.target.value);
  };

  const handleChangeFTipoMovimiento = (event) => {
    setTipoMovimiento(event.target.value);
  };

  function checkIntegrity() {
    let lbRet = true;
    if (
      ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0 &&
      tipoMovimiento === "CO"
    ) {
      if (newItems.length === 0) {
        allErrors.push("Factura Inconsistente.Ingrese al menos un Item.");
        lbRet = false;
      }
      if (newPagos.length === 0) {
        allErrors.push("Factura Inconsistente.Ingrese al menos un Pago.");
        lbRet = false;
      }
      if (newItems.length > 0 && newPagos.length > 0) {
        if (totalNetoItems + totalIntereses !== totalPagos) {
          allErrors.push(
            "Factura Inconsistente.El Total Neto no coincide con los pagos."
          );
          lbRet = false;
        }
      }
    }
    if (
      ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0 &&
      tipoMovimiento === "FI"
    ) {
      if (newItems.length === 0) {
        allErrors.push("Factura Inconsistente.Ingrese al menos un Item.");
        lbRet = false;
      }
      if (newPagos.length > 0) {
        allErrors.push(
          "Factura Inconsistente.Revise los Items y/o los pagos.(Si es Financiada no se ingresan pagos.)"
        );
        lbRet = false;
      }
    }
    if (["RE"].indexOf(formik.values.cbte.cbte) >= 0) {
      if (newItems.length > 0) {
        allErrors.push("Factura Inconsistente.Un recibo no requiere Items.");
        lbRet = false;
      }
      if (newPagos.length === 0) {
        allErrors.push("Factura Inconsistente.Ingrese al menos un Pago.");
        lbRet = false;
      }
      if (Number(formik.values.importe) !== Number(totalPagos)) {
        allErrors.push(
          "Factura Inconsistente.El importe no coincide con los pagos."
        );
        lbRet = false;
      }
    }
    if (["RE", "NC"].indexOf(formik.values.cbte.cbte) >= 0) {
      if (totalPagos > formik.values.referencia.saldo) {
        allErrors.push(
          "Factura Inconsistente.El importe no puede superar el saldo de la Factura de referencia."
        );
        lbRet = false;
      }
    }
    if (["NC", "RI"].indexOf(formik.values.cbte.cbte) >= 0) {
      if (newItems.length > 0) {
        allErrors.push(
          "Factura Inconsistente.Este movimiento no requiere Items."
        );
        lbRet = false;
      }
      if (newPagos.length > 0) {
        allErrors.push(
          "Factura Inconsistente.Este movimiento no requiere Pagos."
        );
        lbRet = false;
      }
    }
    if (
      formik.values.referencia !== null &&
      formik.values.referencia.centro_caja !== formik.values.caja.centro_id
    ) {
      allErrors.push(
        "Las cajas del comprobante y la referencia son incompatibles, pertenecen a clínicas distintas."
      );
      lbRet = false;
    }
    const newError = [...allErrors];
    setAllErrors(newError);
    setShowAlert(!lbRet);
    return lbRet;
  }

  async function EmitirCtaCte() {
    //Veo si hay que agregera Item de Intereses
    if (
      ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0 &&
      totalIntereses > 0
    ) {
      AddItemIntereses(totalIntereses);
    }
    if (!checkIntegrity()) {
      return;
    }
    let user_id = crypto.decryptLocalStorage("user_id");
    let pacpre_id = crypto.decryptDataStorage("currentpacpreid");
    let coe_id = formik.values.coe.coe_id;
    let fecha_emision = ctacterow !== null ? ctacterow.fecha : lcDate;
    let prefijo =
      formik.values.prefijo !== null
        ? formik.values.prefijo.Trim()
        : formik.values.caja.punto_de_venta;
    let numero =
      formik.values.numero !== null ? formik.values.numero.Trim() : "NULL";
    let referencia =
      formik.values.referencia !== null ? formik.values.referencia.id : "NULL";
    let total_bruto =
      ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0
        ? Number(totalBrutoItems + totalIntereses).toFixed(2)
        : formik.values.importe;
    let importe_neto =
      ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0
        ? Number(totalNetoItems + totalIntereses).toFixed(2)
        : formik.values.importe;
    let neto_iva =
      ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0
        ? Number(importe_neto / (1 + tasaIva / 100)).toFixed(2)
        : formik.values.importe;
    let total_iva =
      ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0
        ? Number(importe_neto - neto_iva).toFixed(2)
        : 0;
    let importe_total =
      ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0
        ? (Number(neto_iva) + Number(total_iva)).toFixed(2)
        : formik.values.importe;
    let stencabezado =
      "id=-1" +
      "&paciente_prepaga_id=" +
      pacpre_id +
      "&tipo_movimiento=" +
      tipoMovimiento +
      "&tipo_cbte=NULL" +
      "&prefijo_cbte=" +
      prefijo +
      "&nro_cbte=" +
      numero +
      "&fecha=" +
      fecha_emision +
      "&referencia=" +
      referencia +
      "&importe_bruto=" +
      total_bruto +
      "&porc_bonificacion=" +
      formik.values.bonificacion +
      "&importe_neto=" +
      neto_iva +
      "&importe_iva=" +
      total_iva +
      "&user_id=" +
      user_id +
      "&centro_odontologo_especialidad_id=" +
      coe_id +
      "&fecha_transferencia_bas=NULL" +
      "&ticket=NULL" +
      "&fecha_ticket=NULL" +
      "&print_ok=0" +
      "&impresora_fiscal=NULL" +
      "&cancelado=0" +
      "&tipo_prev=" +
      formik.values.cbte.cbte +
      "&importe_total=" +
      importe_total +
      "&caja_id=" +
      formik.values.caja.id;
    //Items
    let stitems = "";
    for (var i = 0; i < newItems.length; i++) {
      stitems +=
        "&items[" +
        i +
        "][id]=-1" +
        "&items[" +
        i +
        "][ctacte_id]=-1" +
        "&items[" +
        i +
        "][cantidad]=" +
        newItems[i].cantidad +
        "&items[" +
        i +
        "][precio]=" +
        newItems[i].precio +
        "&items[" +
        i +
        "][importe]=" +
        newItems[i].importe +
        "&items[" +
        i +
        "][tipo]=" +
        newItems[i].tipo +
        "&items[" +
        i +
        "][codigo]=" +
        newItems[i].codigo +
        "&items[" +
        i +
        "][descripcion]=" +
        newItems[i].descripcion +
        "&items[" +
        i +
        "][presupuesto_id]=" +
        newItems[i].presupuesto_id +
        "&items[" +
        i +
        "][tasa_iva]=" +
        newItems[i].tasa_iva +
        "&items[" +
        i +
        "][importe_iva]=" +
        newItems[i].importe_iva +
        "&items[" +
        i +
        "][piezas_dentales_id]=NULL" +
        "&items[" +
        i +
        "][caras]=NULL";
    }
    //Pagos
    let spagos = "";
    for (var i = 0; i < newPagos.length; i++) {
      spagos +=
        "&pago[" +
        i +
        "][id]=-1" +
        "&pago[" +
        i +
        "][ctacte_id]=-1" +
        "&pago[" +
        i +
        "][numero_cheque]=" +
        (newPagos[i].numerotarjeta.length > 0
          ? newPagos[i].numerotarjeta
          : newPagos[i].numerocheque.length > 0
          ? newPagos[i].numerocheque
          : "NULL") +
        "&pago[" +
        i +
        "][codigo_banco]=" +
        (newPagos[i].banco.length > 0 ? newPagos[i].banco : "NULL") +
        "&pago[" +
        i +
        "][fecha_acreditacion]=" +
        (newPagos[i].fechapago.length > 0 ? newPagos[i].fechapago : "NULL") +
        "&pago[" +
        i +
        "][codigo_tarjeta]=" +
        (newPagos[i].tarjeta.length > 0 ? newPagos[i].tarjeta : "NULL") +
        "&pago[" +
        i +
        "][codigo_plan]=" +
        (newPagos[i].plantarjeta.length > 0
          ? newPagos[i].plantarjeta
          : "NULL") +
        "&pago[" +
        i +
        "][numero_cupon]=" +
        (newPagos[i].cupontarjeta.length > 0
          ? newPagos[i].cupontarjeta
          : "NULL") +
        "&pago[" +
        i +
        "][codigo_aprobacion]=" +
        (newPagos[i].aprobtarjeta.length > 0
          ? newPagos[i].aprobtarjeta
          : "NULL") +
        "&pago[" +
        i +
        "][tipo_cambio]=0.00" +
        "&pago[" +
        i +
        "][importe]=" +
        newPagos[i].importe +
        "&pago[" +
        i +
        "][tipo]=" +
        newPagos[i].formapago.slice(0, 1) +
        "&pago[" +
        i +
        "][descripcion]=" +
        newPagos[i].descripcion +
        "&pago[" +
        i +
        "][fecha]=" +
        (newPagos[i].fechacheque.length > 0 ? newPagos[i].fechacheque : "NULL");
    }

    setMyLoading(true);
    if (ctacterow === null) {
      let result = await ModeloPost(
        "factura",
        null,
        null,
        stencabezado + stitems + spagos
      );
      if (result.error === false) {
        crypto.encryptLocalStorage("caja_id", result.listado[0].caja_id);
        buscarCtaCtes();
        handleClose();
      } else {
        setModalMessage(result.errormessage);
        setModalErrorOpen(true);
      }
    } else {
      let result = await ModeloUpdate(
        "ctacte",
        ctacterow.id,
        stencabezado + stitems
      );
      if (result.error === false) {
        crypto.encryptLocalStorage("caja_id", result.listado[0].caja_id);
        buscarCtaCtes();
        handleClose();
      } else {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    }
    setMyLoading(false);
  }

  const handleClose = () => {
    hideModal();
  };

  function searchCoes() {
    const coes = CapSentenceArray(crypto.decryptDataStorage("allcoes"), "coe");
    const nomencladores = CapSentenceArray(
      crypto.decryptDataStorage("nomencladores"),
      ["codigo_descripcion"]
    );
    setAllCoes(coes);
    setNomencladores(nomencladores);
    setPiezasDentales(crypto.decryptDataStorage("piezas_dentales"));
    const allCajas = crypto.decryptDataStorage("cajas");
    setCajas(allCajas);
    const tablafull = crypto.decryptDataStorage("tablafull");
    const cbtes = tablafull
      .filter((obj) => obj.codigo_tabla === "COMPROBANTES_CTACTE")
      .map((obj) => ({
        cbte: obj.valor,
        descripcion: obj.valor,
        debehaber: obj.debehaber,
      }));
    setCbtes(cbtes);
    let caja_id = crypto.decryptLocalStorage("caja_id");
    if (caja_id !== null) {
      const selCaja = allCajas.filter((c) => c.id === caja_id);
      if (selCaja.length > 0) {
        formik.setFieldValue("caja", selCaja[0]);
      }
    }
    if (allctacte !== null) {
      const referencias = allctacte.filter(
        (c) => ["FA", "FB"].indexOf(c.tipo_prev) >= 0
      );

      if (referencias.length > 0) {
        setCtaReferencias(referencias);
      }
    }
    if (itemsctacte !== null) {
      itemsctacte.forEach((element, index, array) => {
        var row = {};
        row["id"] = element.id;
        row["alternativa"] = element.alternativa;
        row["codigo"] = element.codigo_nomenclador;
        row["nomenclador_id"] = element.nomenclador_id;
        row["descripcion"] = element.descripcion_nomenclador;
        row["pieza"] = element.numero_pieza_dental;
        row["pieza_dental_id"] = element.pieza_dental_id;
        row["caras"] = element.caras;
        row["importe"] = element.importe;
        newItems.push(row);
      });
      setNewItems(newItems);
    }

    return;
  }

  function busquedaCoes() {
    searchCoes();
  }

  const onCoeChange = (event, values) => {
    formik.setFieldValue("coe", values);
  };

  const onCajaChange = (event, values) => {
    formik.setFieldValue("caja", values);
  };

  const onCbteChange = (event, values) => {
    formik.setFieldValue("cbte", values);
  };

  const onReferenciaChange = (event, values) => {
    formik.setFieldValue("referencia", values);
  };

  function AddItemIntereses(pmIntereses) {
    newItems = newItems.filter((i) => i.codigo !== "80:00:00");
    var row = {};
    let maxValue = newItems.reduce(
      (acc, item) => (acc = acc > item.id ? acc : item.id),
      0
    );
    row["id"] = maxValue + 1;
    row["tipo"] = "N";
    row["presupuesto_id"] = 0;
    row["codigo"] = "80:00:00";
    row["nomenclador_id"] = null;
    row["descripcion"] = "Gastos Administrativos";
    row["cantidad"] = 1;
    row["precio"] = pmIntereses;
    row["importe"] = pmIntereses;
    row["tasa_iva"] = tasaIva;
    row["neto_iva"] = Number(pmIntereses / (1 + tasaIva / 100)).toFixed(2);
    row["importe_iva"] = (
      Number(pmIntereses) - Number(row["neto_iva"])
    ).toFixed(2);
    newItems.push(row);
    const nextList = [...newItems];
    setNewItems(nextList);
  }

  return (
    <>
      <div>
        {allCoes == null && buscarCoes && busquedaCoes()}
        {allCoes !== null && allCoes.length > 0 && (
          <form key="ctacte-form" onSubmit={formik.handleSubmit}>
            <Container
              maxWidth="xl"
              sx={{
                marginTop: 1,
                borderBottom: 1,
                borderColor: "grey.400",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: "avatar",
                    marginBottom: 2,
                    marginRight: 2,
                  }}
                >
                  <DescriptionIcon />
                </Avatar>
                <Typography sx={{ marginBottom: 2 }} variant="h6">
                  Encabezado
                </Typography>
              </Box>
              <Grid
                container
                spacing={1}
                sx={{
                  marginBottom: 2,
                }}
              >
                <Grid item xs={12} lg={3}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={fiscal}
                    onChange={handleChangeFiscal}
                    sx={{
                      border: 1,
                      paddingX: 2,
                      paddingY: 0.2,
                      borderRadius: 1.2,
                      borderColor: "#bdbdbd",
                    }}
                  >
                    <FormControlLabel
                      value="Fiscal"
                      control={<BpRadio />}
                      label={
                        <Typography sx={{ fontWeight: "bold" }}>
                          Fiscal
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Manual"
                      control={<BpRadio />}
                      label={
                        <Typography sx={{ fontWeight: "bold" }}>
                          Manual
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    id="caja"
                    options={cajas}
                    autoHighlight
                    size="small"
                    onChange={onCajaChange}
                    value={formik.values.caja}
                    getOptionLabel={(option) => CapSentence(option.caja)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Caja"
                        error={
                          formik.touched.caja && Boolean(formik.errors.caja)
                        }
                        helperText={formik.touched.caja && formik.errors.caja}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: { justifyContent: "center" },
                        }}
                      />
                    )}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.caja, inputValue, {
                        insideWords: true,
                      });
                      const parts = parse(option.caja, matches);
                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 800 : 400,
                                  //color: part.highlight ? "#0ea5e9" : "black",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  {fiscal === "Manual" && (
                    <TextField
                      id="prefijo"
                      label="Punto de venta"
                      value={formik.values.prefijo}
                      helperText={
                        formik.touched.prefijo ? formik.errors.prefijo : ""
                      }
                      error={
                        formik.touched.prefijo && Boolean(formik.errors.prefijo)
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="dense"
                      variant="outlined"
                      size="small"
                      sx={{ marginTop: -0.1 }}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={3}>
                  {fiscal === "Manual" && (
                    <TextField
                      id="numero"
                      label="Número"
                      value={formik.values.numero}
                      helperText={
                        formik.touched.numero ? formik.errors.numero : ""
                      }
                      error={
                        formik.touched.numero && Boolean(formik.errors.numero)
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="dense"
                      variant="outlined"
                      size="small"
                      sx={{ marginTop: -0.1 }}
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    id="cbte"
                    options={cbtes}
                    autoHighlight
                    size="small"
                    onChange={onCbteChange}
                    value={formik.values.cbte}
                    getOptionLabel={(option) => option.descripcion}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Comprobante"
                        error={
                          formik.touched.cbte && Boolean(formik.errors.cbte)
                        }
                        helperText={formik.touched.cbte && formik.errors.cbte}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: { justifyContent: "center" },
                        }}
                      />
                    )}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.descripcion, inputValue, {
                        insideWords: true,
                      });
                      const parts = parse(option.descripcion, matches);
                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 800 : 400,
                                  //color: part.highlight ? "#0ea5e9" : "black",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  {formik.values.cbte !== null &&
                    ["RE", "NC", "RI"].indexOf(formik.values.cbte.cbte) >=
                      0 && (
                      <Autocomplete
                        id="referencia"
                        options={ctaReferencias}
                        autoHighlight
                        size="small"
                        onChange={onReferenciaChange}
                        value={formik.values.referencia}
                        getOptionLabel={(option) =>
                          option.tipo_prev + " " + option.fecha.toString()
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Referencia"
                            error={
                              formik.touched.referencia &&
                              Boolean(formik.errors.referencia)
                            }
                            helperText={
                              formik.touched.referencia &&
                              formik.errors.referencia
                            }
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { justifyContent: "center" },
                            }}
                          />
                        )}
                        renderOption={(props, option, { inputValue }) => {
                          return (
                            <li {...props}>
                              <React.Fragment>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td align="left" style={{ width: 100 }}>
                                        {option.tipo_prev}
                                      </td>
                                      <td align="left" style={{ width: 200 }}>
                                        {option.fecha}
                                      </td>
                                      <td
                                        align="right"
                                        style={{
                                          width: 200,
                                        }}
                                      >
                                        {option.importe_bruto}
                                      </td>
                                      <td align="right" style={{ width: 200 }}>
                                        {option.saldo}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </React.Fragment>
                            </li>
                          );
                        }}
                      />
                    )}
                  {formik.values.cbte !== null &&
                    ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0 && (
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={tipoMovimiento}
                        onChange={handleChangeFTipoMovimiento}
                        sx={{
                          border: 1,
                          paddingX: 2,
                          paddingY: 0.2,
                          borderRadius: 1.2,
                          borderColor: "#bdbdbd",
                        }}
                      >
                        <FormControlLabel
                          value="CO"
                          control={<BpRadio />}
                          label={
                            <Typography sx={{ fontWeight: "bold" }}>
                              Contado
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="FI"
                          control={<BpRadio />}
                          label={
                            <Typography sx={{ fontWeight: "bold" }}>
                              Financiado
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    )}
                </Grid>
                <Grid item xs={12} lg={3}>
                  {formik.values.cbte !== null &&
                    ["RE", "NC", "RI"].indexOf(formik.values.cbte.cbte) >=
                      0 && (
                      <TextField
                        id="importe"
                        label="Importe"
                        value={formik.values.importe}
                        helperText={
                          formik.touched.importe ? formik.errors.importe : ""
                        }
                        error={
                          formik.touched.importe &&
                          Boolean(formik.errors.importe)
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: -0.1 }}
                        fullWidth
                      />
                    )}
                </Grid>
                <Grid item xs={12} lg={4.5}>
                  <Autocomplete
                    id="coe"
                    options={allCoes}
                    autoHighlight
                    size="small"
                    onChange={onCoeChange}
                    value={formik.values.coe}
                    getOptionLabel={(option) => CapSentence(option.coe)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Odontólogo"
                        error={formik.touched.coe && Boolean(formik.errors.coe)}
                        helperText={formik.touched.coe && formik.errors.coe}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          style: { justifyContent: "center" },
                        }}
                      />
                    )}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.coe, inputValue, {
                        insideWords: true,
                      });
                      const parts = parse(option.coe, matches);
                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 800 : 400,
                                  //color: part.highlight ? "#0ea5e9" : "black",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={1.5} sx={{ marginTop: -1 }}>
                  {formik.values.cbte !== null &&
                    ["FA", "FB"].indexOf(formik.values.cbte.cbte) >= 0 && (
                      <TextField
                        id="bonificacion"
                        label="Bonificación"
                        value={formik.values.bonificacion}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(b) => {
                          formik.handleChange(b);
                          actualizarTotales(b.currentTarget.value, newItems);
                        }}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.bonificacion
                            ? formik.errors.bonificacion
                            : ""
                        }
                        error={
                          formik.touched.bonificacion &&
                          Boolean(formik.errors.bonificacion)
                        }
                        margin="dense"
                        variant="outlined"
                        size="small"
                      />
                    )}
                </Grid>
              </Grid>
            </Container>

            <Container
              maxWidth="xl"
              sx={{ borderBottom: 1, borderColor: "grey.400" }}
            >
              <Box>
                <InsertCtaCteItem
                  presupuestos={allctacte.filter(
                    (c) => ["PR"].indexOf(c.tipo_prev) >= 0
                  )}
                  newItems={newItems}
                  setNewItems={setNewItems}
                  setTotalBruto={setTotalBrutoItems}
                  setTotalNeto={setTotalNetoItems}
                  bonificacion={formik.values.bonificacion}
                />
              </Box>
              <Box sx={{ m: 1, p: 1 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="right">Id</StyledTableCell>
                        <StyledTableCell align="left">Código</StyledTableCell>
                        <StyledTableCell align="left">
                          Descripción
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          Cantidad
                        </StyledTableCell>
                        <StyledTableCell align="right">Precio</StyledTableCell>
                        <StyledTableCell align="right">Importe</StyledTableCell>
                        <StyledTableCell align="left">Borrar</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {newItems !== null &&
                        newItems.map((rowi) => (
                          <StyledTableRow hover tabIndex={-1} key={rowi.id}>
                            <StyledTableCell
                              align="right"
                              component="th"
                              scope="row"
                            >
                              {rowi.id}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowi.codigo}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Box
                                sx={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {rowi.descripcion.toLowerCase()}
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {rowi.cantidad}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {formatNumberES(rowi.precio, 2)}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {formatNumberES(rowi.importe, 2)}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <DeleteIcon
                                cursor="pointer"
                                sx={{
                                  color: "primary.red",
                                  fontSize: 24,
                                }}
                                onClick={() => borrarItem(rowi.id)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {newItems !== null && newItems.length > 0 && (
                <>
                  <Box sx={{ m: 1, p: 1 }}>
                    <Grid
                      container
                      sx={{
                        bgcolor: "slate200",
                      }}
                    >
                      <Grid item xs={12} lg={3}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: 1,
                              marginLeft: 4,
                              marginBottom: 2,
                            }}
                            variant="h6"
                          >
                            Bruto:
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: 1,
                              marginLeft: 4,
                              marginBottom: 2,
                            }}
                            variant="h6"
                          >
                            {formatNumberES(totalBrutoItems, 2)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ display: "flex", justifyContent: "start" }}>
                          <Typography
                            sx={{
                              marginTop: 1,
                              marginLeft: 4,
                              marginBottom: 2,
                            }}
                            variant="h6"
                          >
                            Neto:
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: 1,
                              marginLeft: 4,
                              marginBottom: 2,
                            }}
                            variant="h6"
                          >
                            {formatNumberES(totalNetoItems, 2)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Box sx={{ display: "flex", justifyContent: "start" }}>
                          <Typography
                            sx={{
                              marginTop: 1,
                              marginLeft: 4,
                              marginBottom: 2,
                            }}
                            variant="h6"
                          >
                            Neto + intereses:
                          </Typography>
                          <Typography
                            sx={{
                              marginTop: 1,
                              marginLeft: 4,
                              marginBottom: 2,
                            }}
                            variant="h6"
                          >
                            {formatNumberES(totalNetoItems + totalIntereses, 2)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Container>

            <Container
              maxWidth="xl"
              sx={{ borderBottom: 1, borderColor: "grey.400" }}
            >
              <Box sx={{ marginTop: 2 }}>
                <InsertCtaCtePago
                  comprobante={
                    formik.values.cbte !== null ? formik.values.cbte.cbte : "ZZ"
                  }
                  presupuestos={allctacte.filter(
                    (c) => ["PR"].indexOf(c.tipo_prev) >= 0
                  )}
                  newItems={newPagos}
                  setNewItems={setNewPagos}
                  setTotalPagos={setTotalPagos}
                  setTotalIntereses={setTotalIntereses}
                  saldo={totalNetoItems + totalIntereses - totalPagos}
                />
              </Box>
              <Box sx={{ m: 1, p: 1 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="right">Id</StyledTableCell>
                        <StyledTableCell align="left">Pago</StyledTableCell>
                        <StyledTableCell align="left">
                          Descripción
                        </StyledTableCell>
                        <StyledTableCell align="right">Importe</StyledTableCell>
                        <StyledTableCell align="right">
                          Intereses
                        </StyledTableCell>
                        <StyledTableCell align="left">Borrar</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {newPagos !== null &&
                        newPagos.map((rowp) => (
                          <StyledTableRow hover tabIndex={-1} key={rowp.id}>
                            <StyledTableCell
                              align="right"
                              component="th"
                              scope="row"
                            >
                              {rowp.id}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {rowp.formapago}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Box
                                sx={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {rowp.descripcion.toLowerCase()}
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {formatNumberES(rowp.importe, 2)}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {formatNumberES(rowp.intereses, 2)}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <DeleteIcon
                                cursor="pointer"
                                sx={{
                                  color: "primary.red",
                                  fontSize: 24,
                                }}
                                onClick={() => borrarPago(rowp.id)}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {newPagos !== null && newPagos.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    bgcolor: "slate200",
                    m: 1,
                  }}
                >
                  <Typography
                    sx={{ marginTop: 1, marginLeft: 4, marginBottom: 2 }}
                    variant="h6"
                  >
                    Total Pagos:
                  </Typography>
                  <Typography
                    sx={{ marginTop: 1, marginLeft: 4, marginBottom: 2 }}
                    variant="h6"
                  >
                    {formatNumberES(totalPagos, 2)}
                  </Typography>
                </Box>
              )}
            </Container>
            <Container maxWidth="sm" sx={{ marginTop: 1, marginBottom: 1 }}>
              {showAlert && allErrors.length > 0 && (
                <Fade
                  in={showAlert} //Write the needed condition here to make it appear
                  timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                  addEndListener={() => {
                    setTimeout(() => {
                      hideAlert();
                    }, 6000);
                  }}
                >
                  <Alert variant="filled" severity="error">
                    <AlertTitle>Error !!!</AlertTitle>
                    {allErrors.map((rowe) => (
                      <div>
                        {rowe} <br />
                      </div>
                    ))}
                  </Alert>
                </Fade>
              )}
            </Container>
            <SaveCancelButtons handleCancel={handleClose} loading={myloading} />
          </form>
        )}
      </div>

      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
    </>
  );
}
