import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import crypto from "./Crypto";
import TextField from "@mui/material/TextField";
import { CapSentence } from "./utils/Utils";
import Enumerable from "linq";
import LoadingButton from "@mui/lab/LoadingButton";
import es from "@fullcalendar/core/locales/es";
import { Typography } from "@mui/material";

export default function CoeAutocomplete_2({
  selectedEspecialidad,
  selectedOdontologo,
  setSelectedEspecialidad,
  setSelectedOdontologo,
  handlebusqueda = null,
  stEspecialidad = null,
  stOdontologo = null,
  odontologosLista = [],
  setOdontologosLista = null,
  handleOnChange = null,
  flexdirection = "row",
  especialidadWidth = "220px",
  odontologoWidth = "280px",
}) {
  let allEspecialidades = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .groupBy((g) => g.especialidad_id)
    .select((e) => ({
      id: e.key(),
      especialidad: e.max((m) => m.especialidad),
    }))
    .toArray();
  let allOdontologos = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .where((w) => w.especialidad_id > 0)
    .groupBy((g) => g.odontologo_id)
    .select((e) => ({
      id: e.key(),
      odontologo: e.max((m) => m.odontologo),
    }))
    .toArray();

  useEffect(() => {}, []);

  const onEspecialidadesChange = (event, values) => {
    if (handleOnChange !== null) {
      handleOnChange();
    }
    if (values !== null) {
      if (stEspecialidad !== null) {
        crypto.encryptDataStorage(stEspecialidad, values);
      }
      setSelectedEspecialidad(values);
      setSelectedOdontologo(null);
      allOdontologos = Enumerable.from(crypto.decryptDataStorage("allcoes"))
        .where((es) => es.especialidad_id === values.id)
        .groupBy((gr) => gr.centro_odontologo)
        .select((e) => ({
          id: e.key(),
          odontologo: e.max((od) => od.odontologo),
          odontologo_id: e.max((odi) => odi.odontologo_id),
          centro_id: e.max((ce) => ce.centro_id),
          identificador: e.max((id) => id.identificador),
          color: e.max((co) => co.color),
        }))
        .toArray();
    } else {
      if (stEspecialidad !== null) {
        sessionStorage.removeItem(stEspecialidad);
      }
      if (stOdontologo !== null) {
        sessionStorage.removeItem(stOdontologo);
      }
      setSelectedEspecialidad(null);
      setSelectedOdontologo(null);
      allOdontologos = [];
    }
    setOdontologosLista(allOdontologos);
  };

  const onOdontologosChange = (event, values) => {
    if (handleOnChange !== null) {
      handleOnChange();
    }
    if (values !== null) {
      if (stOdontologo !== null) {
        crypto.encryptDataStorage(stOdontologo, values);
      }
      setSelectedOdontologo(values);
      if (handlebusqueda !== null) {
        handlebusqueda();
      }
    } else {
      if (stOdontologo !== null) {
        sessionStorage.removeItem(stOdontologo);
      }
      setSelectedOdontologo(null);
    }
  };

  return (
    <Box
      sx={{
        marginTop: -1,
        marginBottom: 2,
        textTransform: "capitalize",
        display: "flex",
        flexDirection: { xs: "column", md: flexdirection },
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <Autocomplete
        id="especialidad"
        sx={{ width: especialidadWidth, px: 0.5, py: 1 }}
        options={allEspecialidades}
        value={selectedEspecialidad}
        autoHighlight
        size="small"
        onChange={onEspecialidadesChange}
        getOptionLabel={(option) => option.especialidad}
        ListboxProps={{
          style: {
            maxHeight: "700px",
          },
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Typography variant="body2">{option.especialidad}</Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Especialidad"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              style: { justifyContent: "center" },
            }}
          />
        )}
      />

      <Autocomplete
        id="odontologo"
        sx={{ width: odontologoWidth, px: { xs: 0.5, lg: 1 }, py: 1 }}
        options={odontologosLista}
        value={selectedOdontologo}
        autoHighlight
        size="small"
        onChange={onOdontologosChange}
        getOptionLabel={(option) =>
          CapSentence(option.odontologo) + " (" + option.identificador + ")"
        }
        ListboxProps={{
          style: {
            maxHeight: "700px",
          },
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: option.color,
                marginBottom: 0.5,
                marginRight: 1,
                border: 1,
                borderColor: "black",
              }}
            >
              {option.identificador}
            </Avatar>
            <Typography variant="body2">
              {CapSentence(option.odontologo) +
                " (" +
                option.identificador +
                ")"}
            </Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Odontólogo"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              style: { justifyContent: "center" },
            }}
          />
        )}
      />
    </Box>
  );
}
