import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import * as moment from "moment";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useState } from "react";
import { highlightedText } from "./utils/Utils.js";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const MultipleResultsTable = ({
  setShowModal,
  pallData,
  objFields,
  objHeaders,
  objDates,
  objNullFields = [],
  selectedMethod,
  highlightText,
  returnfullrow = false,
}) => {
  const [highlightColor, setHighlightColor] = useState("#FFFF00");
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: "5px",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  function CloseModal() {
    setShowModal(false);
  }
  function handleClickRow(row) {
    selectedMethod(returnfullrow ? row : row.id, true);
    CloseModal();
  }

  return (
    <div>
      {pallData !== null && pallData.length > 0 && (
        <div>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Container maxWidth="xl">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: { xs: 512, sm: 1280 } }}
                  aria-label="customized table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      {objHeaders.length > 0 &&
                        objHeaders.map((header) => (
                          <StyledTableCell ocClick={() => alert({ header })}>
                            {header}
                          </StyledTableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pallData.length > 0 &&
                      pallData.map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>
                            <PlayCircleFilledIcon
                              onClick={() => handleClickRow(row)}
                              cursor="pointer"
                              sx={{
                                color: "primary.main",
                                fontSize: 24,
                                mr: { sm: 2 },
                                ml: { xs: 1, sm: 1 },
                              }}
                            />
                          </StyledTableCell>
                          {objFields.length > 0 &&
                            objFields.map((field) => (
                              <StyledTableCell>
                                {objNullFields.indexOf(field) >= 0 &&
                                  row[field] !== null &&
                                  row[field].slice(0, 4) !== "0000" && (
                                    <CancelIcon
                                      sx={{
                                        alignContent: "start",
                                        color: "iconred",
                                        fontSize: 24,
                                        mr: { sm: 2 },
                                        ml: { xs: -1, sm: 0 },
                                      }}
                                    />
                                  )}
                                {objNullFields.indexOf(field) >= 0 &&
                                  (row[field] === null ||
                                    row[field].slice(0, 4) === "0000") && (
                                    <CheckCircleIcon
                                      sx={{
                                        alignContent: "start",
                                        color: "icongreen",
                                        fontSize: 24,
                                        mr: { sm: 2 },
                                        ml: { xs: -1, sm: 0 },
                                      }}
                                    />
                                  )}
                                {objDates.indexOf(field) >= 0
                                  ? row[field] !== null &&
                                    row[field].slice(0, 4) !== "0000"
                                    ? moment(row[field]).format("DD/MM/YYYY")
                                    : ""
                                  : highlightedText(row[field], highlightText)}
                              </StyledTableCell>
                            ))}
                        </StyledTableRow>
                      ))}
                    {pallData.length === 0 && (
                      <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                        {" "}
                        <Typography variant="body1" gutterBottom>
                          Sin coincidencias.
                        </Typography>
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Box>
        </div>
      )}
    </div>
  );
};

export default MultipleResultsTable;
