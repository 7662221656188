import React, { useState } from 'react';

export default function CuadranteStd6Std(data) {
    const [testOdo, setTestOdo] = useState(true);

    const getSourceImage = (dienteimage) => {
        let sourceimage = '/iconos_odontograma/' + dienteimage
        return sourceimage
    }


    return (
        <div className="flex ml-1">
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">61</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente === "61" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">62</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente === "62" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">63</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente === "63" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">64</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente === "64" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">65</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente === "65" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
        </div>
    );
}
