/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import crypto from "../Crypto";
import { ModeloPost, ModeloUpdate } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Draggable from "react-draggable";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      {row !== null && (
        <EditIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {row === null && (
        <AddIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = yup.object({
  fecha: yup
    .date()
    .min(new Date("2024-01-01"), "Fecha inválida")
    .nullable()
    .typeError("Fecha inválida")
    .required("Ingrese la fecha."),
  motivo: yup.object().required("Seleccione unamotivo."),
  queja: yup
    .string()
    .min(4, "La Queja requiere al menos 3 caracteres.")
    .required("Ingrese el detalle de la Queja."),
});

export default function ModalQuejas({
  row,
  showModal,
  hideModal,
  buscarQuejas,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const Today = new Date();
  const lcToday =
    Today.getFullYear() +
    "-" +
    (Today.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    Today.getDate().toString().padStart(2, "0");
  const [myloading, setMyLoading] = React.useState(false);
  const [errorSave, setErrorSave] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const tablafull = crypto.decryptDataStorage("tablafull");
  const motivos = tablafull
    .filter((obj) => obj.codigo_tabla === "MOTIVOS_QUEJAS")
    .map((obj) => ({ codigo: obj.valor, motivo: obj.descripcion }));

  useEffect(() => {
    if (row !== null) {
      const selMotivo = motivos.filter((g) => g.motivo === row.motivo);
      if (selMotivo.length > 0) {
        formik.setFieldValue("motivo", selMotivo[0]);
      }
    }
  }, []);

  const handleClose = () => {
    hideModal();
  };

  const formik = useFormik({
    initialValues: {
      fecha: row !== null ? dayjs(row.fecha) : dayjs(new Date()),
      queja: row ? row.queja : null,
      motivo: row ? row.motivo : null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let operacion = row ? "UPDATE" : "NEW";
      grabarQueja(values, operacion);
    },
  });

  async function grabarQueja(values, operacion) {
    setMyLoading(true);
    setErrorSave(null);

    let currentPatient = crypto.decryptDataStorage("currentpatient");
    const userid = crypto.decryptLocalStorage("user_id");
    const Data = {
      paciente_id: currentPatient,
      fecha: values.fecha.format().slice(0, 10),
      motivo: values.motivo.codigo,
      queja: values.queja,
    };
    if (operacion === "UPDATE") {
      let result = await ModeloUpdate("quejas", row.id, Data);
      if (result.error === false) {
        handleClose();
        buscarQuejas();
      } else {
        setMyLoading(false);
        setModalMessage(result.errormessage);
        setModalInformationOpen(true);
      }
    } else {
      let result = await ModeloPost("quejas", null, null, Data);
      if (result.error === false) {
        handleClose();
        buscarQuejas();
      } else {
        setMyLoading(false);
        setModalMessage(result.errormessage);
        setModalInformationOpen(true);
      }
    }
  }

  return (
    <React.Fragment>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          open={showModal}
          aria-labelledby="draggable-dialog-title"
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
            row={row}
          >
            {row !== null ? "Editar" : "Nuevo Registro"}
          </BootstrapDialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent className="content">
              <DialogContentText>
                <Grid container spacing={1} marginTop={0.5} marginBottom={4}>
                  <Grid item xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"en-gb"}
                    >
                      <DateField
                        id="fecha"
                        label="Fecha"
                        value={formik.values.fecha}
                        inputFormat="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        //defaultValue={dayjs(row.alta_usa)}
                        onChange={(value) =>
                          formik.setFieldValue("fecha", value)
                        }
                        size="small"
                        helperText={
                          formik.touched.fecha ? formik.errors.fecha : ""
                        }
                        error={
                          formik.touched.fecha && Boolean(formik.errors.fecha)
                        }
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      id="motivo"
                      options={motivos}
                      value={formik.values.motivo}
                      autoHighlight
                      size="small"
                      onChange={(e, value) =>
                        formik.setFieldValue("motivo", value)
                      }
                      getOptionLabel={(option) => option.codigo}
                      isOptionEqualToValue={(option, value) =>
                        option.codigo === value.codigo
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Motivo"
                          error={
                            formik.touched.motivo &&
                            Boolean(formik.errors.motivo)
                          }
                          helperText={
                            formik.touched.motivo && formik.errors.motivo
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="queja"
                      label="Queja"
                      multiline
                      rows={10}
                      value={formik.values.queja}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.queja ? formik.errors.queja : ""
                      }
                      error={
                        formik.touched.queja && Boolean(formik.errors.queja)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: 0 }}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="actions">
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                loadingPosition="end"
                sx={{
                  backgroundColor: "#cbd5e1",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                loading={myloading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                <span>Grabar</span>
              </LoadingButton>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </Draggable>
    </React.Fragment>
  );
}
