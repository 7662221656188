import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { FormatHorario45 } from "../utils/Utils.js";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useHistory } from "react-router-dom";
import "../../assetss/css/CalendarMM.css";
import "../../assetss/css/Pacientes.css";
import crypto from "../Crypto";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../assetss/css/SyncfusionMaterial.css";

import { ModeloGetUrl, ModeloPost } from "../ModeloCrud";
import ModalConfirmation from "../modals/ModalConfirmation";
import ModalInformation from "../modals/ModalInformation";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import BackPage from "../BackPage";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CoeAutocomplete from "../CoeAutocomplete";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const CambiarAgendas = () => {
  let [selectedClinica, setSelectedClinica] = useState(null);
  let [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  let [selectedOdontologo, setSelectedOdontologo] = useState(null);
  let [selectedClinicaD, setSelectedClinicaD] = useState(null);
  let [selectedEspecialidadD, setSelectedEspecialidadD] = useState(null);
  let [selectedOdontologoD, setSelectedOdontologoD] = useState(null);
  const [cambiarCampo, setCambiarCampo] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [backPage, setBackPage] = useState("agendassimples");
  let [allAgendas, setAllAgendas] = useState(null);
  const [motivo, setMotivo] = useState(null);
  let [selectedAgendas, setSelectedAgendas] = useState([]);
  const tableRef = useRef();
  let [errores, setErrores] = useState("");
  const history = useHistory();
  const Today = new Date();
  const desde = moment(Today).format("YYYY-MM-DD");
  const hasta = moment(Today).add(120, "months").format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [loadingFecha, setLoadingFecha] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const calendarRef = useRef();
  const now = new Date().toISOString().slice(0, 10);
  const [fechaOrigen, setFechaOrigen] = useState(dayjs(now));
  const [fechaDestino, setFechaDestino] = useState(dayjs(now));
  const [ausenciaChecked, setAusenciaChecked] = useState(true);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  useEffect(() => {
    if (
      userRoles.length === 0 ||
      userRoles.indexOf("CLINICASAPP GESTIONAR AGENDAS") < 0
    ) {
      handleClickVolver();
    }
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: 30,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleCambiarAgendas = () => {
    if (selectedAgendas.length > 0) {
      setCambiarCampo("agenda");
      if (
        selectedClinicaD == null ||
        selectedEspecialidadD == null ||
        selectedOdontologoD == null
      ) {
        setModalMessage(
          "Seleccione clínica, especialidad y odontólogo de destino."
        );
        setModalInformationOpen(true);
        return;
      }
      if (
        selectedClinica.id === selectedClinicaD.id &&
        selectedEspecialidadD.id === selectedEspecialidadD.id &&
        selectedOdontologo.id === selectedOdontologoD.id
      ) {
        setModalMessage("Origen y Destino Coinciden !");
        setModalInformationOpen(true);
        return;
      }
      if (motivo !== null && motivo.trim().length > 0) {
        handleBuscarDestino();
      } else {
        setModalMessage("Describa el motivo.");
        setModalInformationOpen(true);
      }
    } else {
      setModalMessage("Seleccione al menos una agenda.");
      setModalInformationOpen(true);
    }
  };

  const handleCambiarFecha = () => {
    if (selectedAgendas.length > 0) {
      setCambiarCampo("fecha");
      const Today = new Date();
      if (fechaDestino <= Today) {
        setModalMessage("La fecha destino debe ser mayor a hoy.");
        setModalInformationOpen(true);
        return;
      }
      if (
        fechaDestino.toISOString().slice(0, 10) ===
        fechaOrigen.toISOString().slice(0, 10)
      ) {
        setModalMessage("Las fechas coinciden.");
        setModalInformationOpen(true);
        return;
      }
      if (motivo !== null && motivo.trim().length > 0) {
        setModalMessage("Seguro desea cambiar la fecha ?");
        setShowConfirmationModal(true);
      } else {
        setModalMessage("Describa el motivo.");
        setModalInformationOpen(true);
      }
    } else {
      setModalMessage("Seleccione al menos una agenda.");
      setModalInformationOpen(true);
    }
  };

  const handleChangeAusenciaChecked = (event) => {
    setAusenciaChecked(event.target.checked);
  };

  async function handleCambiarAllAgendas() {
    if (cambiarCampo === null) {
      return;
    }
    hideConfirmationModal();
    setErrores("");
    const data = selectedAgendas;
    let pagendas = "";
    let pCoe = "";
    for (let i = 0; i < data.length; i++) {
      pagendas += data[i].id + ",";
    }

    pagendas = pagendas.slice(0, pagendas.length - 1);
    let params = "agendas=" + pagendas + "&observaciones=" + motivo;
    if (cambiarCampo === "agenda") {
      setLoadingAgenda(true);
      const myCoe = crypto
        .decryptDataStorage("allcoes")
        .filter(
          (g) =>
            g.centro_id === selectedClinicaD.id &&
            g.especialidad_id === selectedEspecialidadD.id &&
            g.odontologo_id === selectedOdontologoD.id
        );
      if (myCoe.length > 0) {
        pCoe += myCoe[0].coe_id;
      }
      params += "&campo=agenda&coe_id=" + pCoe;
    } else if (cambiarCampo === "fecha") {
      setLoadingFecha(true);
      params += "&campo=fecha&fecha=" + fechaDestino.toISOString().slice(0, 10);
    } else {
      return;
    }
    if (ausenciaChecked === true) {
      params += "&ausencia=SI";
    }
    let result = await ModeloPost(
      "agenda",
      null,
      "cambiar-coes-agendas",
      params
    );
    setLoadingAgenda(false);
    setLoadingFecha(false);

    if (result.error === false) {
      if (result.listado.ok === data.length) {
        allAgendas = [];
        const tempAg = [...allAgendas];
        setAllAgendas(tempAg);
        selectedAgendas = [];
        const tempSel = [...selectedAgendas];
        setSelectedAgendas(tempSel);
        setMotivo(null);
        setModalMessage("Proceso terminado.");
        setModalInformationOpen(true);
      }
    } else {
    }
  }

  async function handleClickBuscar() {
    allAgendas = [];
    const tempAg = [...allAgendas];
    setAllAgendas(tempAg);
    selectedAgendas = [];
    const tempSel = [...selectedAgendas];
    setSelectedAgendas(tempSel);
    if (
      selectedClinica == null ||
      selectedEspecialidad == null ||
      selectedOdontologo == null
    ) {
      setModalMessage("Seleccione clínica, especialidad y odontólogo.");
      setModalInformationOpen(true);
      return;
    }
    setLoading(true);
    let params =
      "especialidad_id=" +
      selectedEspecialidad.id +
      "&desde=" +
      fechaOrigen.toISOString().slice(0, 10) +
      "&hasta=" +
      fechaOrigen.toISOString().slice(0, 10);
    if (selectedClinica !== null) {
      params += "&centro_id=" + selectedClinica.id;
    }
    if (selectedOdontologo !== null) {
      params += "&odontologo_id=" + selectedOdontologo.id;
    }
    let result = await ModeloGetUrl(
      "centro-odontologo-especialidad",
      null,
      "agendas-coe-fechas",
      params
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        let sorted = result.listado.sort((a, b) => (a.id > b.id ? 1 : -1));
        setAllAgendas(sorted);
      }
    } else {
      setModalMessage(result.mensaje);
      setModalInformationOpen(true);
    }
    return;
  }

  async function handleBuscarDestino() {
    if (
      selectedClinicaD == null ||
      selectedEspecialidadD == null ||
      selectedOdontologoD == null
    ) {
      setModalMessage(
        "Seleccione clínica, especialidad y odontólogo de destino."
      );
      setModalInformationOpen(true);
      return;
    }
    setLoading(true);
    let params =
      "especialidad_id=" +
      selectedEspecialidadD.id +
      "&desde=" +
      fechaOrigen.toISOString().slice(0, 10) +
      "&hasta=" +
      fechaOrigen.toISOString().slice(0, 10);
    if (selectedClinicaD !== null) {
      params += "&centro_id=" + selectedClinicaD.id;
    }
    if (selectedOdontologoD !== null) {
      params += "&odontologo_id=" + selectedOdontologoD.id;
    }
    let result = await ModeloGetUrl(
      "centro-odontologo-especialidad",
      null,
      "agendas-coe-fechas",
      params
    );
    setLoading(false);
    let mensaje = "Se actualizarán " + selectedAgendas.length + " agendas. ";
    if (
      selectedClinica.id !== selectedClinicaD.id ||
      selectedEspecialidad.id !== selectedEspecialidadD.id
    ) {
      mensaje += " La clínica y/o especialidad no coinciden. ";
    }
    if (result.error === false) {
      if (result.listado.length > 0) {
        mensaje +=
          " El odontólogo destino YA TIENE agendas esa fecha. Continúa ? ";
      } else {
        mensaje +=
          " El odontólogo destino NO TIENE agendas esa fecha. Continúa ? ";
      }
      setModalMessage(mensaje);
      setShowConfirmationModal(true);
    } else {
      setModalMessage(result.mensaje);
      setModalInformationOpen(true);
    }
    return;
  }

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow key={row.id}>
          <StyledTableCell component="th" width="10%" scope="row">
            {row.checked === 0 && (
              <i
                class="fa-regular fa-square fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedAgendas(0, row)}
              ></i>
            )}
            {row.checked === 1 && (
              <i
                class="fa-regular fa-square-check fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedAgendas(0, row)}
              ></i>
            )}
          </StyledTableCell>
          <StyledTableCell component="th" width="10%" scope="row">
            {row.habilitado_turnos === 1 && (
              <CheckCircleIcon
                sx={{
                  color: "primary.green",
                  fontSize: 24,
                  mr: { sm: 2 },
                  ml: 2,
                }}
              />
            )}
            {row.habilitado_turnos === 0 && (
              <RemoveCircleIcon
                sx={{
                  color: "primary.red",
                  fontSize: 24,
                  mr: { sm: 2 },
                  ml: 2,
                }}
              />
            )}
          </StyledTableCell>
          <StyledTableCell width="10%" component="th" scope="row" align="right">
            {row.id}
          </StyledTableCell>
          <StyledTableCell width="20%" component="th" scope="row">
            {row.fecha_arg}
          </StyledTableCell>
          <StyledTableCell width="10%" component="th" scope="row">
            {row.horario}
          </StyledTableCell>
          <StyledTableCell width="20%" component="th" scope="row">
            {FormatHorario45(row.horario_desde)}
          </StyledTableCell>
          <StyledTableCell width="20%" component="th" scope="row">
            {FormatHorario45(row.horario_hasta)}
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  function updateSelectedAgendas(pValue, Row) {
    const oldValue = Row.checked;
    const index = allAgendas.findIndex((obj) => {
      return obj.id === Row.id;
    });
    if (index >= 0) {
      allAgendas[index].checked = oldValue === 0 ? 1 : 0;
    }
    var tempAgendas = [...allAgendas];
    setAllAgendas(tempAgendas);
    selectedAgendas = allAgendas.filter((g) => g.checked === 1);
    var tempSelected = [...selectedAgendas];
    setSelectedAgendas(tempSelected);
  }

  function handleClickVolver() {
    history.push("/" + backPage);
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
    setModalMessage(null);
  };

  return (
    <div className="mt-6 ">
      <Box sx={{ display: "flex" }}>
        <BackPage text="Volver" handleClick={handleClickVolver} />
        <div className="w-full -mt-2 flex justify-center items-center  ">
          <Typography variant="h5">Cambiar Agendas</Typography>
        </div>
      </Box>

      <Grid
        container
        maxWidth="xl"
        rowSpacing={{ xs: 2, md: 4 }}
        columnSpacing={{ xs: 0, md: 6 }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 2,
            }}
          >
            <Typography variant="h5">Origen</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"en-gb"}
            >
              <DatePicker
                label="Fecha"
                value={fechaOrigen}
                slotProps={{ textField: { size: "small" } }}
                onChange={(newValue) => setFechaOrigen(newValue)}
                sx={{
                  height: "1rem",
                  width: "17.8rem",
                }}
              />
            </LocalizationProvider>
          </Box>
          <CoeAutocomplete
            selectedClinica={selectedClinica}
            selectedEspecialidad={selectedEspecialidad}
            selectedOdontologo={selectedOdontologo}
            setSelectedClinica={setSelectedClinica}
            setSelectedEspecialidad={setSelectedEspecialidad}
            setSelectedOdontologo={setSelectedOdontologo}
            handlebusqueda={handleClickBuscar}
            loading={loading}
            flexdirection="Column"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 2,
            }}
          >
            <Typography variant="h5">Destino</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CoeAutocomplete
              selectedClinica={selectedClinicaD}
              selectedEspecialidad={selectedEspecialidadD}
              selectedOdontologo={selectedOdontologoD}
              setSelectedClinica={setSelectedClinicaD}
              setSelectedEspecialidad={setSelectedEspecialidadD}
              setSelectedOdontologo={setSelectedOdontologoD}
              flexdirection="Column"
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 4,
          marginTop: 2,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={ausenciaChecked}
              onChange={handleChangeAusenciaChecked}
            />
          }
          label={
            <Typography
              color={ausenciaChecked ? "avatar" : "#94a3b8"}
              fontWeight={ausenciaChecked ? "bold" : "regular"}
            >
              Agregar ausencia
            </Typography>
          }
          sx={{
            marginTop: {
              xs: 0.5,
              md: -1,
            },
            marginLeft: {
              xs: 0,
              md: 0,
            },
          }}
        />

        <TextField
          id="outlined-controlled"
          label="Ingrese el motivo..."
          size="small"
          value={motivo}
          onChange={(event) => {
            setMotivo(event.target.value);
          }}
          sx={{ ml: 1, mt: -1.5, width: { xs: 300, sm: 600, lg: 881 } }}
        />
      </Box>

      <div>
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          <Container maxWidth="lg"></Container>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          <Container maxWidth="lg">
            <LoadingButton
              size="medium"
              onClick={handleCambiarAgendas}
              loading={loadingAgenda}
              loadingPosition="start"
              sx={{
                backgroundColor: "primary.main",
                ":hover": {
                  bgcolor: "#475569", // theme.palette.primary.main
                  color: "white",
                },
                width: 250,
                marginTop: { xs: 1, sm: 0 },
                marginRight: 1,
                marginBottom: 2,
              }}
              variant="contained"
            >
              Cambiar Agenda
            </LoadingButton>
            <LoadingButton
              size="medium"
              onClick={handleCambiarFecha}
              loading={loadingFecha}
              loadingPosition="start"
              sx={{
                backgroundColor: "primary.main",
                ":hover": {
                  bgcolor: "#475569", // theme.palette.primary.main
                  color: "white",
                },
                width: 250,
                marginTop: { xs: 1, sm: 0 },
                marginBottom: 2,
              }}
              variant="contained"
            >
              Cambiar Fecha
            </LoadingButton>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"en-gb"}
            >
              <DatePicker
                label="Nueva Fecha"
                value={fechaDestino}
                slotProps={{ textField: { size: "small" } }}
                onChange={(newValue) => setFechaDestino(newValue)}
                sx={{
                  height: "1rem",
                  width: "17.8rem",
                  marginLeft: 1,
                  marginBottom: { xs: 4, md: 0 },
                }}
              />
            </LocalizationProvider>
          </Container>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Container maxWidth="lg">
            <Box sx={{ display: "flex", justifyContent: "end" }}></Box>
            <TableContainer component={Paper}>
              <Table ref={tableRef} aria-label="customized table" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sel</StyledTableCell>
                    <StyledTableCell>Habilitada</StyledTableCell>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell>Fecha</StyledTableCell>
                    <StyledTableCell>Turno</StyledTableCell>
                    <StyledTableCell>Desde</StyledTableCell>
                    <StyledTableCell>Hasta</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allAgendas !== null &&
                    allAgendas.length > 0 &&
                    allAgendas.map((row) => <Row key={row.id} row={row} />)}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
      </div>

      <BackPage text="Volver" handleClick={handleClickVolver} />
      <ModalConfirmation
        showModal={showConfirmationModal && modalMessage !== null}
        confirmModal={handleCambiarAllAgendas}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
      />
    </div>
  );
};
export default CambiarAgendas;
