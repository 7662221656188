import React, { useState } from 'react';

export default function CuadranteStd7Std(data) {
    const [testOdo, setTestOdo] = useState(true);

    const getSourceImage = (dienteimage) => {
        let sourceimage = '/iconos_odontograma/' + dienteimage
        return sourceimage
    }


    return (
        <div className="flex ml-1">
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">71</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "71" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">72</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "72" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">73</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "73" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">74</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "74" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute mt-8 ml-2">75</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 mt-2" : "absolute mr-1" } src={item.diente === "75" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
        </div>
    );
}
