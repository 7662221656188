import React, { useState, useEffect } from "react";
import CuadranteStd1 from "../CuadranteStd1";
import CuadranteStd2 from "../CuadranteStd2";
import CuadranteStd3 from "../CuadranteStd3";
import CuadranteStd4 from "../CuadranteStd4";
import CuadranteStd5 from "../CuadranteStd5";
import CuadranteStd6 from "../CuadranteStd6";
import CuadranteStd7 from "../CuadranteStd7";
import CuadranteStd8 from "../CuadranteStd8";
import { ModeloGetUrl } from "../ModeloCrud";
import loadingGif from "../../assetss/img/loading_64.gif";
import ModalError from "../modals/ModalError";
import ModalInformation from "../modals/ModalInformation";
import crypto from "../Crypto";

import FootPage from "../FootPage";
import { useHistory } from "react-router-dom";

export default function FichadoStd() {
  const [itemsFichado, setItemsFichado] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  useEffect(() => {
    buscarItems();
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  async function buscarItems() {
    let FichadoId = crypto.decryptDataStorage("currentfichado");
    if (
      FichadoId !== null &&
      typeof FichadoId !== "undefined" &&
      FichadoId !== ""
    ) {
      setLoading(true);
      let result = await ModeloGetUrl(
        "fichado",
        FichadoId,
        "buscar-items-grafico",
        null
      );
      setLoading(false);
      if (result.error === false) {
        setItemsFichado(result.listado);
      } else {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    }
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  const history = useHistory();

  function handleClickVolver() {
    history.push("/pacientes");
  }

  return (
    <>
      <div className="flex justify-start ml-3 mt-3 mb-4 capitalize ">
        <i
          className="fas fa-chevron-circle-left fa-2x text-gray-500 mt-1 mr-2 cursor-pointer"
          onClick={handleClickVolver}
        />
        <button
          className="text-gray-500"
          type="button"
          onClick={handleClickVolver}
        >
          Volver al Paciente
        </button>
        <p className="mt-1 ml-2 text-blue-800 text-lg">
          {crypto.decryptDataStorage("currentpatientname")} , Odontólogo:{" "}
          {crypto.decryptDataStorage("currentfichadoodontologo")}
        </p>
      </div>
      {loading && (
        <div className="ml-2 -mt-2">
          <img alt="Loading" src={loadingGif} />
        </div>
      )}
      {itemsFichado.length > 0 && (
        <>
          <div className="alert alert-warning m-3 sm:hidden" role="alert">
            El ancho de la pantalla no permite visualizar el Odontograma. Pruebe
            colocando el móvil en posición horizontal.
          </div>
          <div className="hidden sm:flex sm:justify-center sm:-mt-4">
            <div className="mt-6  border-b border-r p-2 border-gray-600">
              {itemsFichado.length > 0 && <CuadranteStd1 data={itemsFichado} />}
            </div>
            <div className="mt-6  border-b p-2 border-gray-600">
              {itemsFichado.length > 0 && <CuadranteStd2 data={itemsFichado} />}
            </div>
          </div>
          <div className="hidden sm:flex sm:justify-center sm:-mt-6">
            <div className="mt-6   border-r p-2 border-gray-600">
              {itemsFichado.length > 0 && <CuadranteStd4 data={itemsFichado} />}
            </div>
            <div className="mt-6  p-2 ">
              {itemsFichado.length > 0 && <CuadranteStd3 data={itemsFichado} />}
            </div>
          </div>
          <div className="hidden sm:flex sm:justify-center ">
            <div className="mt-10  border-b border-r p-2 border-gray-600">
              {itemsFichado.length > 0 && <CuadranteStd5 data={itemsFichado} />}
            </div>
            <div className="mt-10  border-b p-2 border-gray-600">
              {itemsFichado.length > 0 && <CuadranteStd6 data={itemsFichado} />}
            </div>
          </div>
          <div className="hidden sm:flex sm:justify-center sm:-mt-6">
            <div className="mt-6   border-r p-2 border-gray-600">
              {itemsFichado.length > 0 && <CuadranteStd8 data={itemsFichado} />}
            </div>
            <div className="mt-6  p-2 ">
              {itemsFichado.length > 0 && <CuadranteStd7 data={itemsFichado} />}
            </div>
          </div>
          <div className="bg-gradient-to-br from-gray-300 to-gray-100 mt-10"></div>
        </>
      )}
      <ModalError
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
      />
    </>
  );
}
