import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { ModeloGetUrlJson, ModeloUpdate, ModeloPost } from "../ModeloCrud";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CapSentence } from "../utils/Utils";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import crypto from "../Crypto";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import ModalInformation from "../modals/ModalInformation";
import Draggable from "react-draggable";
import FacebookCircularProgress from "../FacebookCircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 0,
        bgcolor: "primary.main",
        color: "white",
      }}
      {...other}
    >
      {row !== null && (
        <EditIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {row === null && (
        <AddIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = yup.object({
  prepaga: yup.object().required("Seleccione una Obra Social / Prepaga"),
  plan: yup.object().required("Seleccione un Plan"),
  credencial: yup
    .string()
    .min(4, "La credencial requiere al menos 4 caracteres")
    .max(30, "La credencial no puede superar los 30 caracteres")
    .required("Ingrese el número de credencial"),
  falta: yup
    .date()
    .min(new Date("1986-01-01"), "Fecha inválida")
    .nullable()
    .typeError("Fecha inválida")
    .required("Ingrese la fecha de alta"),
  fbaja: yup
    .date()
    .min(yup.ref("falta"), "Fecha de baja no puede ser menor a la de alta")
    .nullable()
    .typeError("Fecha inválida"),
});

export default function ModalPacientePrepaga({
  row,
  showModal,
  hideModal,
  setUpdateOk,
}) {
  const [allPrepagas, setAllPrepagas] = useState([]);
  const [allPlanes, setAllPlanes] = useState(null);
  const [planesLista, setPlanesLista] = useState([]);
  const [errorSave, setErrorSave] = useState(null);
  const [myloading, setMyLoading] = React.useState(false);
  const [searchLoading, setSearchyLoading] = React.useState(false);
  const [searchPrepagas, setSearchPrepagas] = React.useState(false);
  const [gravadoChecked, setGravadoChecked] = useState(
    row !== null && row.gravado === 1 ? true : false
  );
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  useEffect(() => {
    buscarPrepagas();
  }, []);

  const formik = useFormik({
    initialValues: {
      prepaga: null,
      plan: null,
      credencial: row !== null ? row.numero_credencial : null,
      falta: row !== null ? dayjs(row.alta_usa) : null,
      fbaja:
        row !== null &&
        row.baja_usa !== null &&
        typeof row.baja_usa !== "undefined"
          ? dayjs(row.baja_usa)
          : null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let operacion = row !== null ? "UPDATE" : "NEW";
      grabarPacientePrepaga(values, operacion);
    },
  });

  async function grabarPacientePrepaga(values, operacion) {
    setMyLoading(true);
    setErrorSave(null);
    let currentPatient = crypto.decryptDataStorage("currentpatient");
    let currentPatientPrepaga = row !== null ? row.id : null;
    const Data = {
      paciente_id: currentPatient,
      prepaga_id: formik.values.prepaga.id,
      numero_credencial: formik.values.credencial,
      fecha_alta: formik.values.falta.toISOString().slice(0, 10),
      fecha_baja:
        formik.values.fbaja !== null &&
        typeof formik.values.fbaja !== "undefined"
          ? formik.values.fbaja.toISOString().slice(0, 10)
          : null,
      planes_prepaga_id: formik.values.plan.id,
      gravado: gravadoChecked ? 1 : 0,
    };
    if (operacion === "UPDATE") {
      let result = await ModeloUpdate(
        "paciente-prepaga",
        currentPatientPrepaga,
        Data
      );
      if (result.error === false) {
        setUpdateOk(true);
        handleClose();
      } else {
        setMyLoading(false);
        setModalMessage(result.errormessage);
        setModalInformationOpen(true);
      }
    } else {
      let result = await ModeloPost("paciente-prepaga", null, null, Data);
      if (result.error === false) {
        setUpdateOk(true);
        handleClose();
      } else {
        setMyLoading(false);
        setModalMessage(result.errormessage);
        setModalInformationOpen(true);
      }
    }
  }

  const handleChangeGravadoChecked = (event) => {
    setGravadoChecked(event.target.checked);
  };

  const handleClose = () => {
    hideModal();
  };

  async function buscarPrepagas() {
    buscarPrepagas = false;
    setSearchyLoading(true);
    let result = await ModeloGetUrlJson(
      "prepaga",
      null,
      "vista-prepagas-planes-habilitados",
      null
    );
    setSearchyLoading(false);
    if (result.error === false) {
      setAllPrepagas(result.prepagas);
      setAllPlanes(result.planes);
      if (row !== null) {
        const selPrepaga = result.prepagas.filter(
          (g) => g.id === row.prepaga_id
        );
        if (selPrepaga.length > 0) {
          formik.setFieldValue("prepaga", selPrepaga[0]);
          const planes = result.planes.filter(
            (g) => g.prepaga_id === row.prepaga_id
          );
          setPlanesLista(planes);
        }
        const selPlan = result.planes.filter(
          (g) => g.id === row.planes_prepaga_id
        );
        if (selPlan.length > 0) {
          formik.setFieldValue("plan", selPlan[0]);
        }
      }
    }
    return;
  }

  function busquedaPrepagas() {
    buscarPrepagas();
  }

  const onPrepagasChange = (event, values) => {
    formik.setFieldValue("prepaga", values);
    formik.setFieldValue("plan", null);
    setPlanesLista([]);
    if (values !== null) {
      const planes = allPlanes.filter((g) => g.prepaga_id === values.id);
      setPlanesLista(planes);
    } else {
    }
  };

  const onPlanesChange = (event, values) => {
    formik.setFieldValue("plan", values);
    if (values !== null) {
    }
  };

  return (
    <>
      <div>
        <Draggable cancel=".content, .actions">
          <BootstrapDialog
            hideBackdrop={true}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            open={showModal}
            fullWidth={true}
            maxWidth="xs"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "20px",
              },
              "& .MuiPaper-root": {
                backgroundColor: "bgmodal", // Adjust transparency here
              },
            }}
          >
            <BootstrapDialogTitle
              style={{ cursor: "move" }}
              id="draggable-dialog-title"
              onClose={handleClose}
              row={row}
            >
              {row !== null ? "Editar" : "Nuevo Registro"}
            </BootstrapDialogTitle>

            <form onSubmit={formik.handleSubmit}>
              <>
                <DialogContent className="content" dividers>
                  {searchLoading && allPrepagas.length === 0 && (
                    <Box
                      sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FacebookCircularProgress />
                    </Box>
                  )}
                  {!searchLoading && allPrepagas.length > 0 && (
                    <Grid
                      container
                      spacing={2}
                      marginTop={0.5}
                      marginBottom={4}
                    >
                      <Grid item xs={12}>
                        <Autocomplete
                          id="prepaga"
                          options={allPrepagas}
                          autoHighlight
                          size="small"
                          onChange={onPrepagasChange}
                          value={formik.values.prepaga}
                          getOptionLabel={(option) =>
                            CapSentence(option.codigo_razon)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Prepaga/Obra Social"
                              error={
                                formik.touched.prepaga &&
                                Boolean(formik.errors.prepaga)
                              }
                              helperText={
                                formik.touched.prepaga && formik.errors.prepaga
                              }
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                style: { justifyContent: "center" },
                              }}
                            />
                          )}
                          renderOption={(props, option, { inputValue }) => {
                            const matches = match(
                              option.codigo_razon,
                              inputValue,
                              {
                                insideWords: true,
                              }
                            );
                            const parts = parse(option.codigo_razon, matches);
                            return (
                              <li {...props}>
                                <div>
                                  {parts.map((part, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        fontWeight: part.highlight ? 800 : 400,
                                        //color: part.highlight ? "#0ea5e9" : "black",
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </div>
                              </li>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          id="plan"
                          options={planesLista}
                          autoHighlight
                          size="small"
                          onChange={onPlanesChange}
                          value={formik.values.plan}
                          getOptionLabel={(option) =>
                            CapSentence(option.plan_codigo)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Plan"
                              error={
                                formik.touched.plan &&
                                Boolean(formik.errors.plan)
                              }
                              helperText={
                                formik.touched.plan && formik.errors.plan
                              }
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                style: { justifyContent: "center" },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="credencial"
                          label="Número de credencial"
                          value={formik.values.credencial}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.credencial
                              ? formik.errors.credencial
                              : ""
                          }
                          error={
                            formik.touched.credencial &&
                            Boolean(formik.errors.credencial)
                          }
                          margin="dense"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={"en-gb"}
                        >
                          <DateField
                            id="falta"
                            label="Fecha de alta"
                            value={formik.values.falta}
                            inputFormat="DD/MM/YYYY"
                            format="DD/MM/YYYY"
                            //defaultValue={dayjs(row.alta_usa)}
                            onChange={(value) =>
                              formik.setFieldValue("falta", value)
                            }
                            size="small"
                            helperText={
                              formik.touched.falta ? formik.errors.falta : ""
                            }
                            error={
                              formik.touched.falta &&
                              Boolean(formik.errors.falta)
                            }
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={"en-gb"}
                        >
                          <DateField
                            id="fbaja"
                            label="Fecha de baja"
                            value={formik.values.fbaja}
                            inputFormat="DD/MM/YYYY"
                            format="DD/MM/YYYY"
                            //defaultValue={dayjs(row.alta_usa)}
                            onChange={(value) =>
                              formik.setFieldValue("fbaja", value)
                            }
                            size="small"
                            helperText={formik.errors.fbaja}
                            error={Boolean(formik.errors.fbaja)}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={gravadoChecked}
                              onChange={handleChangeGravadoChecked}
                            />
                          }
                          label={
                            <Typography
                              color={gravadoChecked ? "#0284c7" : "#94a3b8"}
                              fontWeight={gravadoChecked ? "bold" : "regular"}
                            >
                              Gravado
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </DialogContent>
                <DialogActions className="actions">
                  <Button
                    onClick={handleClose}
                    color="primary"
                    variant="contained"
                    loadingPosition="end"
                    sx={{
                      backgroundColor: "#cbd5e1",
                      ":hover": {
                        bgcolor: "#475569", // theme.palette.primary.main
                        color: "white",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    loading={myloading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      ":hover": {
                        bgcolor: "#475569", // theme.palette.primary.main
                        color: "white",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    <span>Grabar</span>
                  </LoadingButton>
                </DialogActions>
              </>
            </form>
          </BootstrapDialog>
        </Draggable>
      </div>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
    </>
  );
}
