import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ApiService from "../apis/ApiService";
import { TodayFullText } from "./utils/Utils";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NearMeIcon from "@mui/icons-material/NearMe";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AnalogClock from "./AnalogClock";

export default function DayWeather() {
  const [loading, setLoading] = useState(false);
  const [temperatura, setTemperatura] = useState("");
  const [tiempo, setTiempo] = useState("");
  const [iconTiempo, setIconTiempo] = useState("");
  const [location, setLocation] = useState("");
  const Today = TodayFullText();

  useEffect(() => {
    GetWeather();
  }, []);

  async function GetWeather() {
    try {
      setLoading(true);
      const urlWeather =
        "https://api.openweathermap.org/data/2.5/weather?q=Buenos%20Aires,AR&APPID=29185d451a85b8ad1763c81a6298bb02&units=metric&lang=es";
      let resultWeather = await ApiService.GetSimpleUrl(urlWeather, null);
      setLoading(false);
      if (typeof resultWeather !== "undefined") {
        setTemperatura(resultWeather.main.temp.toFixed(1));
        setTiempo(resultWeather.weather[0].description);
        setIconTiempo(
          "https://openweathermap.org/img/wn/" +
            resultWeather.weather[0].icon +
            ".png"
        );
        setLocation(resultWeather.name);
      }
    } catch (error) {
      alert("Error ! " + error.message);
    }
    return;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "14rem",
        height: "10rem",
        color: "avatar",
        bgcolor: "#e2e8f0",
        p: 1,
        border: 1,
        borderRadius: 2,
        borderColor: "#94a3b8",
        marginTop: 0,
        boxShadow: 12,
      }}
    >
      {!loading && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 1,
              marginTop: 1,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  textAlign: "start",
                  marginRight: 1,
                  marginTop: 0.5,
                  fontFamily: "Roboto",
                  fontSize: "1.25rem",
                  textTransform: "capitalize",
                }}
              >
                {location}
              </Typography>
              <NearMeIcon
                sx={{ fontSize: 24, marginTop: 0.5, marginRight: 0 }}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  marginTop: 2,
                  color: "primary.main",
                  fontFamily: "Roboto",
                  fontSize: "3rem",
                }}
              >
                {temperatura}°
              </Typography>
              <div>
                <img
                  className="object-cover mt-5 w-16 h-16"
                  src={iconTiempo}
                  alt="webcam"
                />
              </div>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
