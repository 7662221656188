import React from "react";
import { TextField } from "@mui/material";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CustomTextField = ({
  id,
  size = "small",
  value,
  label,
  onKeyboardDown = null,
  inputRef = null,
  onChange = null,
  nextInputRef = null,
  formik = null,
  setValueMethod = null,
  width = "100%",
  fieldType = "text",
  autoFocus = false,
  autoComplete = "off",
  ...props
}) => {
  // Handle Enter key for moving to the next input
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && nextInputRef) {
      e.preventDefault();
      nextInputRef.current?.focus();
    }
    onKeyboardDown?.(e);
  };

  // Handle onChange for Formik, normal onChange, or setValueMethod
  const handleChangeText = (e) => {
    if (formik) {
      formik.handleChange(e);
    } else if (onChange) {
      onChange(e);
    } else if (setValueMethod) {
      setValueMethod(e.target.value);
    }
  };

  const handleChangeDate = (newValue) => {
    if (formik) {
      formik.setFieldValue(id, newValue);
    } else if (onChange) {
      onChange(newValue);
    } else if (setValueMethod) {
      setValueMethod(newValue);
    }
  };

  const isError = formik?.touched[id] && Boolean(formik?.errors[id]);

  return fieldType === "date" ? (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
      <DateField
        id={id}
        size={size}
        label={label}
        value={formik ? formik.values[id] : value}
        onChange={handleChangeDate}
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        error={isError}
        helperText={isError ? formik.errors[id] : ""}
        style={{ width }}
        autoFocus={autoFocus}
        {...props}
      />{" "}
    </LocalizationProvider>
  ) : (
    <TextField
      id={id}
      size={size}
      label={label}
      value={formik ? formik.values[id] : value}
      onChange={handleChangeText}
      inputRef={inputRef}
      onKeyDown={handleKeyDown}
      error={isError}
      helperText={isError ? formik.errors[id] : ""}
      style={{ width }}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      {...props}
    />
  );
};

export default CustomTextField;
