import React, { useState } from "react";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import BadgeIcon from "@mui/icons-material/Badge";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalPacientePrepaga from "../modals/ModalPacientePrepaga";
import {
  CapSentence,
  DateToArgString,
  StyledTableCell,
  ExpandMore,
  hasPermission,
} from "../utils/Utils";
import FacebookCircularProgress from "../FacebookCircularProgress";
import Grid from "@mui/material/Grid";
import ModalInformation from "../modals/ModalInformation";
import { ModeloGetUrlJson } from "../ModeloCrud";
import crypto from "../Crypto";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteModel from "../DeleteModel";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export default function PacientesEmpresas({
  allPrepagas,
  currentPrepagaCodigo,
  onclick,
  setAllPrepagas,
}) {
  const habilitadas = allPrepagas.filter((p) => p.fecha_baja === null);
  const [expanded, setExpanded] = useState(
    habilitadas.length > 0 ? false : true
  );
  const [editarPacientePrepaga, setEditarPacientePrepaga] = useState(false);
  const [insertarPacientePrepaga, setInsertarPacientePrepaga] = useState(false);
  const [selectedPrepaga, setSelectedPrepaga] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateOk, setUpdateOk] = useState(false);
  const [deletePrepagaId, setDeletePrepagaId] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  let currentPatient = crypto.decryptDataStorage("currentpatient");
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const hidePacientePrepaga = () => {
    setEditarPacientePrepaga(false);
    setInsertarPacientePrepaga(false);
  };
  const handleEdit = (row) => {
    setUpdateOk(false);
    setSelectedPrepaga(row);
    setEditarPacientePrepaga(true);
  };
  const handleNew = () => {
    setInsertarPacientePrepaga(true);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  async function buscarPrepagasPaciente(pacienteid) {
    setUpdateOk(false);
    let result = await ModeloGetUrlJson(
      "paciente",
      pacienteid,
      "vista-paciente-prepaga-actualizada",
      null
    );
    if (result.error === false) {
      crypto.encryptDataStorage("dataPrepagas", result.listado);
      setAllPrepagas(result.listado);
      const habilitadas = result.listado.filter((p) => p.fecha_baja === null);
      if (habilitadas.length > 0) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    }
    return;
  }

  return (
    <>
      <Card
        sx={{
          backgroundColor: "bgcards",
          ml: { xs: -1, md: -2 },
          mr: { xs: -2, md: -2 },
        }}
      >
        <CardHeader
          avatar={<BadgeIcon sx={{ color: "avatar", fontSize: 48 }} />}
          title={
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Obras Sociales/Prepagas
              </Typography>
              {hasPermission(userRoles, "ALTA CPACIENTESPREPAGA") && (
                <IconButton
                  aria-label="new"
                  onClick={handleNew}
                  sx={{ p: "1px", marginTop: -1 }}
                >
                  <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
                </IconButton>
              )}
            </Box>
          }
          subheader=""
        />
        <Box sx={{ mt: -4, ml: 1, mr: 1, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mt: -2 }}>
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: 0.2,
                  }}
                >
                  <FacebookCircularProgress />
                </Box>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 16, mr: 2, mb: 1, textAlign: "end" }}>
                Habilitadas
              </Typography>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: { xs: 180, sm: 200 } }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Código</StyledTableCell>
                  <StyledTableCell>Empresa</StyledTableCell>
                  <StyledTableCell>Plan</StyledTableCell>
                  <StyledTableCell>Credencial</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPrepagas
                  .filter((p) => p.fecha_baja === null)
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left" component="th" scope="row">
                        {currentPrepagaCodigo !== null &&
                          currentPrepagaCodigo === row.codigo && (
                            <CheckBoxIcon
                              cursor="pointer"
                              sx={{
                                color: "primary.main",
                                fontSize: 24,
                                mr: { sm: 1 },
                                ml: { xs: -1, sm: -1 },
                              }}
                            />
                          )}
                        {currentPrepagaCodigo !== null &&
                          currentPrepagaCodigo !== row.codigo && (
                            <CheckBoxOutlineBlankIcon
                              onClick={() => onclick(row.codigo, allPrepagas)}
                              cursor="pointer"
                              sx={{
                                color: "primary.main",
                                fontSize: 24,
                                mr: { sm: 1 },
                                ml: { xs: -1, sm: -1 },
                              }}
                            />
                          )}
                        {row.codigo}
                      </TableCell>
                      <TableCell>
                        <CheckCircleIcon
                          onClick={() => onclick(row.codigo, allPrepagas)}
                          cursor="pointer"
                          sx={{
                            color: "primary.green",
                            fontSize: 24,
                            mr: { sm: 1 },
                            ml: { xs: -3, sm: -2 },
                          }}
                        />
                        {CapSentence(row.denominacion_comercial).toLowerCase()}
                      </TableCell>
                      <TableCell>
                        {CapSentence(row.plan_cobertura).toLowerCase()}
                      </TableCell>
                      <TableCell>{row.numero_credencial}</TableCell>
                      {hasPermission(userRoles, "ALTA CPACIENTESPREPAGA") && (
                        <>
                          <TableCell align="right">
                            <EditIcon
                              cursor="pointer"
                              sx={{
                                color: "primary.main",
                                fontSize: 24,
                              }}
                              onClick={() => handleEdit(row)}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <DeleteIcon
                              cursor="pointer"
                              onClick={() => setDeletePrepagaId(row.id)}
                              sx={{
                                color: "primary.red",
                                fontSize: 24,
                              }}
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {allPrepagas.filter((p) => p.fecha_baja === null).length === 0 && (
              <Typography
                sx={{
                  marginLeft: 2,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                Sin empresas habilitadas.
              </Typography>
            )}
          </TableContainer>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: -2 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="caption" sx={{ mt: 2, mr: 1 }}>
              Dadas de baja
            </Typography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandCircleDownIcon
                sx={{ color: "primary.main", fontSize: 36 }}
              />
            </ExpandMore>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Box sx={{ mt: -2, mb: 4, ml: -1, mr: -1 }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: { xs: 180, sm: 200 } }}
                  size="small"
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Código</StyledTableCell>
                      <StyledTableCell>Empresa</StyledTableCell>
                      <StyledTableCell>U.Padrón</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allPrepagas
                      .filter((p) => p.fecha_baja !== null)
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            {currentPrepagaCodigo !== null &&
                              currentPrepagaCodigo === row.codigo && (
                                <CheckBoxIcon
                                  cursor="pointer"
                                  sx={{
                                    color: "primary.main",
                                    fontSize: 24,
                                    mr: { sm: 2 },
                                    ml: { xs: -1, sm: -1 },
                                  }}
                                />
                              )}
                            {currentPrepagaCodigo !== null &&
                              currentPrepagaCodigo !== row.codigo && (
                                <CheckBoxOutlineBlankIcon
                                  onClick={() =>
                                    onclick(row.codigo, allPrepagas)
                                  }
                                  cursor="pointer"
                                  sx={{
                                    color: "primary.main",
                                    fontSize: 24,
                                    mr: { sm: 1 },
                                    ml: { xs: -1, sm: -1 },
                                  }}
                                />
                              )}
                            <Typography variant="caption">
                              {row.codigo}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <CancelIcon
                              onClick={() => onclick(row.codigo, allPrepagas)}
                              cursor="pointer"
                              sx={{
                                color: "iconred",
                                fontSize: 24,
                                mr: { sm: 1 },
                                ml: { xs: -3, sm: -2 },
                              }}
                            />
                            <Typography variant="caption">
                              {CapSentence(
                                row.denominacion_comercial
                              ).toLowerCase()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {DateToArgString(row.fecha_ultimo_padron)}
                            </Typography>
                          </TableCell>
                          {hasPermission(
                            userRoles,
                            "ALTA CPACIENTESPREPAGA"
                          ) && (
                            <>
                              <TableCell>
                                <EditIcon
                                  cursor="pointer"
                                  sx={{
                                    color: "primary.main",
                                    fontSize: 24,
                                  }}
                                  onClick={() => handleEdit(row)}
                                />
                              </TableCell>
                              <TableCell>
                                <DeleteIcon
                                  cursor="pointer"
                                  onClick={() => setDeletePrepagaId(row.id)}
                                  sx={{
                                    color: "primary.red",
                                    fontSize: 24,
                                  }}
                                />
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
      {editarPacientePrepaga && (
        <ModalPacientePrepaga
          row={selectedPrepaga}
          showModal={editarPacientePrepaga}
          hideModal={hidePacientePrepaga}
          setUpdateOk={setUpdateOk}
        />
      )}
      {insertarPacientePrepaga && (
        <ModalPacientePrepaga
          row={null}
          showModal={insertarPacientePrepaga}
          hideModal={hidePacientePrepaga}
          setUpdateOk={setUpdateOk}
        />
      )}
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      {updateOk && buscarPrepagasPaciente(currentPatient)}
      {deletePrepagaId !== null && (
        <DeleteModel
          model={"paciente-prepaga"}
          modelid={deletePrepagaId}
          okmethod={() => buscarPrepagasPaciente(currentPatient)}
          nullmethod={setDeletePrepagaId}
        />
      )}
    </>
  );
}
