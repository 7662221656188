import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FootPage from "../FootPage";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import crypto from "../Crypto";
import { ModeloGetUrlJson, ModeloPost } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import FacebookCircularProgress from "../FacebookCircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ModalInformation from "../modals/ModalInformation";
import { highlightedText, hasPermission } from "../utils/Utils.js";
import BackPage from "../BackPage";
import PacienteHeader from "./PacienteHeader";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import drugallergy from "../../assetss/img/drugallergy.png";
import pillsdrink from "../../assetss/img/pillsdrink.png";
import treatment from "../../assetss/img/treatment.png";
import Image from "mui-image";

const PacienteAnamnesis = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [myloading, setmyLoading] = useState(false);
  const [allTratamientos, setAllTratamientos] = useState(null);
  let [allPreguntas, setAllPreguntas] = useState([]);
  let [selectedPreguntas, setSelectedPreguntas] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const tableRef = useRef();
  const [filterText, setFilterText] = useState("");
  const [backPage, setBackPage] = useState("pacientes");
  const location = useLocation();
  const [fdAlergias, setFdAlergias] = React.useState(null);
  const [fdMedicamentos, setFdMedicamentos] = React.useState(null);
  const [fdTratamientos, setFdTratamientos] = React.useState(null);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  useEffect(() => {
    if (typeof location.backpage !== "undefined") {
      setBackPage(location.backpage);
    }
    buscarAnamnesis();
  }, []);

  async function buscarAnamnesis() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    const params = "paciente_id=" + PacienteId;
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      const result = await ModeloGetUrlJson(
        "paciente",
        PacienteId,
        "anamnesis-respuestas",
        null
      );
      setLoading(false);

      if (result.error === false) {
        if (result.preguntas.length > 0) {
          setAllPreguntas(result.preguntas);
        } else {
          setModalMessage("No se encontraron respuestas.");
          setModalInformationOpen(true);
        }
        if (result.respuestas.length > 0) {
          const data = result.respuestas;

          for (let i = 0; i < data.length; i++) {
            if (data[i].anamnesis_pregunta_id === 3) {
              setFdAlergias(data[i].respuesta);
            }
            if (data[i].anamnesis_pregunta_id === 30) {
              setFdMedicamentos(data[i].respuesta);
            }
            if (data[i].anamnesis_pregunta_id === 31) {
              setFdTratamientos(data[i].respuesta);
            }
          }
        }
      } else {
        history.push("/");
      }
    } else {
      handleClickVolver();
    }
  }

  async function handleGrabar() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    let params = "paciente_id=" + PacienteId;
    const selectedP = allPreguntas.filter((g) => g.checked === 1);
    const data = selectedP;
    for (let i = 0; i < data.length; i++) {
      params +=
        "&pregunta[" +
        data[i].id +
        "]=" +
        data[i].id +
        "&respuesta[" +
        data[i].id +
        "]=SI";
    }
    if (fdAlergias !== null && fdAlergias.length > 0) {
      params += "&pregunta[3]=3&respuesta[3]=" + fdAlergias;
    }
    if (fdMedicamentos !== null && fdMedicamentos.length > 0) {
      params += "&pregunta[30]=30&respuesta[30]=" + fdMedicamentos;
    }
    if (fdTratamientos !== null && fdTratamientos.length > 0) {
      params += "&pregunta[31]=31&respuesta[31]=" + fdTratamientos;
    }
    setmyLoading(true);
    let result = await ModeloPost(
      "anamnesis-respuesta",
      null,
      "responder",
      params
    );
    setmyLoading(false);
  }

  function updateSelectedPreguntas(pValue, Row) {
    const oldValue = Row.checked;
    const index = allPreguntas.findIndex((obj) => {
      return obj.id === Row.id;
    });
    if (index >= 0) {
      allPreguntas[index].checked = oldValue === 0 ? 1 : 0;
    }
    var tempTurnos = [...allPreguntas];
    setAllPreguntas(tempTurnos);
    selectedPreguntas = allPreguntas.filter((g) => g.checked === 1);
    var tempSelected = [...selectedPreguntas];
    setSelectedPreguntas(tempSelected);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: 30,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: "60px",
  }));

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  function handleClickVolver() {
    history.push("/" + backPage);
  }

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow key={row.id}>
          <StyledTableCell component="th" width="5%" scope="row">
            {row.checked === 0 && (
              <i
                class="fa-regular fa-square fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedPreguntas(0, row)}
              ></i>
            )}
            {row.checked === 1 && (
              <i
                class="fa-regular fa-square-check fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedPreguntas(0, row)}
              ></i>
            )}
          </StyledTableCell>
          <StyledTableCell width="95%">
            {row.checked === 1 && (
              <Typography
                variant="body2"
                sx={{
                  border: "1px solid ",
                  borderRadius: 1,
                  color: "black",
                  bgcolor: "lightgreen",
                  fontSize: 14,
                  width: { xs: 250, md: 500 },
                  height: 30,
                  paddingLeft: 2,
                  paddingTop: 0.5,
                  fontWeight: "bold",
                }}
              >
                {row.pregunta}
              </Typography>
            )}
            {row.checked === 0 && (
              <Typography color={"#64748b"} fontWeight={"regular"}>
                {row.pregunta}
              </Typography>
            )}
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <PacienteHeader />
      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {!loading && (
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginRight: { xs: 0, md: 4 },
            }}
          >
            <Container maxWidth="xl">
              <Grid
                container
                spacing={1}
                sx={{
                  marginLeft: {
                    xs: 0,
                    md: 1,
                  },
                  marginTop: 0.5,
                  marginBottom: 2,
                }}
              >
                <Grid xs={12} md={6}>
                  <Box
                    sx={{
                      marginRight: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                      <Image
                        src={drugallergy}
                        height="38px"
                        width="48px"
                        duration={0}
                        sx={{
                          marginLeft: 5,
                          marginTop: 2,
                          marginRight: 12,
                        }}
                      />
                      <TextField
                        id="outlined-controlled"
                        label="Alergia a drogas o medicamentos..."
                        value={fdAlergias}
                        onChange={(event) => {
                          setFdAlergias(event.target.value);
                        }}
                        autoComplete="off"
                        fullWidth
                        sx={{
                          marginBottom: 4,
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                      <Image
                        src={pillsdrink}
                        height="38px"
                        width="48px"
                        duration={0}
                        sx={{
                          marginLeft: 5,
                          marginTop: 2,
                          marginRight: 12,
                        }}
                      />
                      <TextField
                        id="outlined-controlled"
                        label="Medicamentos que está tomando..."
                        value={fdMedicamentos}
                        onChange={(event) => {
                          setFdMedicamentos(event.target.value);
                        }}
                        autoComplete="off"
                        fullWidth
                        sx={{
                          marginBottom: 4,
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                      <Image
                        src={treatment}
                        height="38px"
                        width="48px"
                        duration={0}
                        sx={{
                          marginLeft: 5,
                          marginTop: 2,
                          marginRight: 12,
                        }}
                      />
                      <TextField
                        id="outlined-controlled"
                        label="Tratamientos que está realizando..."
                        value={fdTratamientos}
                        onChange={(event) => {
                          setFdTratamientos(event.target.value);
                        }}
                        autoComplete="off"
                        fullWidth
                        sx={{
                          marginBottom: 4,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      marginLeft: { xs: -2, md: -3 },
                      marginBottom: 3,
                    }}
                  >
                    <Container maxWidth="xl">
                      {hasPermission(userRoles, "ALTA CPACIENTES") && (
                        <LoadingButton
                          loading={myloading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          onClick={handleGrabar}
                          sx={{
                            backgroundColor: "primary.main",
                            ":hover": {
                              bgcolor: "#475569", // theme.palette.primary.main
                              color: "white",
                            },
                            textTransform: "capitalize",
                          }}
                        >
                          <span>Grabar</span>
                        </LoadingButton>
                      )}
                    </Container>
                  </Box>
                </Grid>
                <Grid xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table
                      ref={tableRef}
                      aria-label="customized table"
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Sel</StyledTableCell>
                          <StyledTableCell>Pregunta</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allPreguntas !== null &&
                          allPreguntas.length > 0 &&
                          allPreguntas
                            .sort((a, b) => {
                              if (a.checked === b.checked) {
                                return a.pregunta < b.pregunta ? -1 : 1;
                              } else {
                                return a.checked < b.checked ? 1 : -1;
                              }
                            })
                            .map((row) => <Row key={row.id} row={row} />)}
                        {allPreguntas !== null && allPreguntas.length === 0 && (
                          <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                            <Typography variant="body1" gutterBottom>
                              Sin Respuestas.
                            </Typography>
                          </Box>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>
      )}

      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
};
export default PacienteAnamnesis;
