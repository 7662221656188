/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import crypto from "../Crypto";
import { ModeloPost, ModeloUpdate } from "../ModeloCrud";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import { Grid, Container, Box, Divider } from "@mui/material";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import CustomModal from "./CustomModal";
import SaveCancelButtons from "../SaveCancelButtons";

const validationSchema = yup.object({
  observacion: yup
    .string()
    .min(4, "La nota requiere al menos 3 caracteres.")
    .required("Ingrese el texto de la nota."),
});

export default function ModalNota({ row, showModal, hideModal, setUpdateOk }) {
  const [modalMessage, setModalMessage] = useState(null);

  const Today = new Date();
  const [myloading, setMyLoading] = React.useState(false);
  const [errorSave, setErrorSave] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const handleClose = () => {
    hideModal();
  };

  const formik = useFormik({
    initialValues: {
      observacion: row ? row.observacion : null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let operacion = row ? "UPDATE" : "NEW";
      grabarNota(values, operacion);
    },
  });

  async function grabarNota(values, operacion) {
    setMyLoading(true);
    setErrorSave(null);
    let currentPatient = crypto.decryptDataStorage("currentpatient");
    const userid = crypto.decryptLocalStorage("user_id");
    const Data = {
      observacion: formik.values.observacion,
      paciente_id: currentPatient,
      user_id: userid,
    };
    if (operacion === "UPDATE") {
      let result = await ModeloUpdate("paciente-observacion", row.id, Data);
      if (result.error === false) {
        setUpdateOk(true);
        handleClose();
      } else {
        setMyLoading(false);
        setModalMessage(result.errormessage);
        setModalInformationOpen(true);
      }
    } else {
      let result = await ModeloPost("paciente-observacion", null, null, Data);
      if (result.error === false) {
        setUpdateOk(true);
        handleClose();
      } else {
        setMyLoading(false);
        setModalMessage(result.errormessage);
        setModalInformationOpen(true);
      }
    }
  }

  return (
    <CustomModal
      open={showModal}
      onClose={handleClose}
      title={row !== null ? "Editar nota" : "Nueva nota"}
      disableActions
      maxWidth="xl"
      icon={
        row !== null ? (
          <EditIcon fontSize="medium" sx={{ color: "white" }} />
        ) : (
          <AddIcon fontSize="medium" sx={{ color: "white" }} />
        )
      }
    >
      <Container maxWidth="ms">
        <form onSubmit={formik.handleSubmit}>
          <Grid container sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <TextField
                id="observacion"
                label="Nota"
                multiline
                rows={10}
                value={formik.values.observacion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.observacion ? formik.errors.observacion : ""
                }
                error={
                  formik.touched.observacion &&
                  Boolean(formik.errors.observacion)
                }
                margin="dense"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <SaveCancelButtons
              handleCancel={handleClose}
              loading={myloading}
              isModal={true}
            />
          </Box>
        </form>
      </Container>
    </CustomModal>
  );
}
