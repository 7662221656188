import React, { forwardRef } from "react";
import AvatarAzul from "../../assetss/img/logo_azul_reportes.png";
import { Typography, Avatar, Box, Grid } from "@mui/material";
import "../../assetss/css/PageA4.css";
import { CapSentence } from "../utils/Utils";
import crypto from "../Crypto";
import Image from "mui-image";

export const RenderReportDiscapacidad = forwardRef((props, ref) => {
  const { data } = props;
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-GB").replace(/\//g, "/");
  const fotopaciente = crypto.decryptDataStorage("dataFoto");
  const styles = {
    report: {
      margin: "0 auto",
      paddingLeft: "1cm",
      paddingBottom: "1cm",
      boxSizing: "border-box",
      backgroundColor: "#fff",
      color: "#000",
      fontFamily: "Arial, sans-serif",
    },
    header: {
      textAlign: "center",
      marginLeft: "1.2cm",
      marginTop: "1.2cm",
      marginBottom: "1cm",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      padding: "2px 0",
    },
    footer: {
      position: "absolute",
      bottom: "1cm",
      left: 0,
      right: 0,
      textAlign: "center",
    },
  };
  return (
    <div ref={ref} style={{ width: "100%", padding: "20px" }}>
      <style
        type="text/css"
        media="print"
        dangerouslySetInnerHTML={{
          __html: `
                @page {
                  size: Legal ;
                  margin: 20mm;
                }
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                  }
                  .page-break {
                    page-break-before: always;
                     margin-top: 50mm; 
                     margin-bottom: 2cm; 
                  }
                  .content {
                    margin-top: 3cm; 
                    margin-bottom: 1cm;
                  }
                  .avatar {
                  border-radius: 50%;
                  width: 50px; /* or any size */
                  height: 50px;
                  overflow: hidden; /* Ensures the content doesn't spill outside the rounded border */
                }  
                }
              `,
        }}
      />
      {data !== null && (
        <>
          <header style={styles.header}>
            <img src={AvatarAzul} alt="logo" />
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 1, mb: 2 }}>
              Información del paciente
            </Typography>
            <Typography variant="h7" sx={{ mt: 2, mb: 4 }}>
              Fecha: {formattedDate}
            </Typography>
          </header>
          <div id="report" style={styles.report}>
            <Box
              sx={{ display: "flex", alignItems: "center", padding: "10px 0" }}
            >
              <Avatar
                alt="Patient Avatar"
                src={"data:image/jpg;base64," + fotopaciente}
                sx={{
                  width: 120,
                  height: 120,
                  marginRight: "50px",
                  borderRadius: 50,
                }}
              />
              <Box>
                <Typography variant="subtitle1">
                  <strong>Nombre: </strong>
                  {CapSentence(crypto.decryptDataStorage("currentpatientname"))}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Empresa: </strong>
                  {CapSentence(
                    crypto.decryptDataStorage("currentprepagarazon")
                  ) +
                    " (" +
                    crypto.decryptDataStorage("currentprepagacodigo") +
                    ")"}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Documento: </strong>
                  {crypto.decryptDataStorage("dataPaciente").nro_documento}
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              <strong>Vive con: </strong>
              {data.paciente_vivecon}
            </Typography>
            <Typography variant="subtitle1">
              <strong>Trastorno Genético: </strong>
              {data.paciente_trastorno_genetico}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              <strong>Síndrome: </strong>
              {data.paciente_sindrome}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ textDecoration: "underline" }}
            >
              <strong>Datos de la madre </strong>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Nombre: </strong>
                  {data.madre_nombre}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Documento: </strong>
                  {data.madre_documento}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Domicilio: </strong>
                  {data.madre_domicilio}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Teléfono: </strong>
                  {data.madre_telefono}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">
                  <strong>Email: </strong>
                  {data.madre_email}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              sx={{ textDecoration: "underline" }}
            >
              <strong>Datos del padre </strong>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Nombre: </strong>
                  {data.padre_nombre}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Documento: </strong>
                  {data.padre_documento}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Domicilio: </strong>
                  {data.padre_domicilio}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Teléfono: </strong>
                  {data.padre_telefono}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">
                  <strong>Email: </strong>
                  {data.padre_email}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              sx={{ textDecoration: "underline" }}
            >
              <strong>Datos del neurólogo </strong>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Nombre: </strong>
                  {data.neurologo_nombre}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  <strong>Teléfono: </strong>
                  {data.neurologo_telefono}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              sx={{ textDecoration: "underline" }}
            >
              <strong>Datos del pediatra </strong>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  <strong>Nombre: </strong>
                  {data.pediatra_nombre}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" sx={{ mb: 4 }}>
                  <strong>Teléfono: </strong>
                  {data.pediatra_telefono}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              sx={{ textDecoration: "underline" }}
            >
              <strong>Notas </strong>
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{data.notas}</Typography>
              </Grid>
            </Grid>
          </div>
          <footer style={styles.footer}>
            <p>www.consulmed.com.ar</p>
          </footer>
        </>
      )}
    </div>
  );
});
