import React, { useState } from 'react';

export default function CuadranteStd5Std(data) {
    const [testOdo, setTestOdo] = useState(true);

    const getSourceImage = (dienteimage) => {
        let sourceimage = '/iconos_odontograma/' + dienteimage
        return sourceimage
    }


    return (
        <div className="flex">
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">55</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente==="55" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">54</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente==="54" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">53</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente==="53" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">52</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente==="52" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">51</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className="absolute mr-1" src={item.diente==="51" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
        </div>
    );
}
