import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

const GenericCard = ({
  icon,
  iconSize = 32, // Tamaño del icono configurable por parámetro
  bgColor = "#8FD3F4", // Color de fondo por defecto
  textColor = "#4F4F4F", // Color de texto por defecto
  text1,
  cifra1,
  text2 = null, // Texto opcional
  cifra2 = null, // Cifra opcional
  fixedHeight = 180, // Altura fija por defecto
  width = 280, // Ancho fijo por defecto
  responsive = true, // Control para ancho responsivo
  porcentaje = false,
}) => {
  const porcentajeCalculado =
    porcentaje && cifra1 && cifra2
      ? ((cifra2 / cifra1) * 100).toFixed(2)
      : null;
  return (
    <Card
      fullwidth
      sx={{
        backgroundColor: bgColor,
        borderRadius: "16px",
        //  boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
        color: textColor,
        width: responsive ? "100%" : width, // Ancho fijo o responsivo
        height: fixedHeight, // Altura fija
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Centrado vertical si hay solo una línea
        boxShadow: 10,
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          {/* Icono a la izquierda */}
          <Grid item>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "50%",
                width: 56, // Tamaño del círculo
                height: 56,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: bgColor,
              }}
            >
              {/* Aplicamos el tamaño del icono usando sx */}
              <Box sx={{ fontSize: iconSize }}>
                {React.cloneElement(icon, { sx: { fontSize: iconSize } })}
              </Box>
            </Box>
          </Grid>

          {/* Información principal */}
          <Grid item xs>
            <Box>
              {/* Primera línea: cifra1 y text1 */}
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: textColor }}
              >
                {cifra1}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: textColor }}>
                {text1}
              </Typography>

              {/* Segunda línea opcional */}
              {text2 && (
                <Box mt={2} ml={-8} position="relative">
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ color: textColor }}
                  >
                    {cifra2}
                  </Typography>
                  <Typography variant="body1" sx={{ color: textColor }}>
                    {text2}
                  </Typography>
                  {porcentaje && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 2,
                        paddingRight: 2,
                      }}
                    >
                      <CircularProgress
                        variant="determinate"
                        value={porcentajeCalculado}
                        size={48} // Tamaño pequeño
                        thickness={5} // Grosor del círculo
                        sx={{
                          color: "darkgray", // Color completado (gris oscuro)
                          "& .MuiCircularProgress-circle": {
                            stroke: "white", // Color del faltante (gris claro)
                          },
                        }}
                      />
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          position: "absolute",
                          top: "35%",
                          bottom: "10%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: textColor,
                        }}
                      >
                        {`${porcentajeCalculado}%`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GenericCard;
