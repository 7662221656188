import React, { useRef, useState } from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

Chart.register(ArcElement, Tooltip, Legend);

const InteractiveChart = () => {
  const doughnutRef = useRef(null);
  const [selectedData, setSelectedData] = useState(null);

  const doughnutData = {
    labels: ["Series 1", "Series 2", "Series 3"],
    datasets: [
      {
        data: [300, 200, 100],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  // Use a more muted color palette for the Pie chart
  const mutedColors = [
    "#B39DDB",
    "#90CAF9",
    "#FFAB91",
    "#A5D6A7",
    "#CE93D8",
    "#FFCC80",
  ];

  const pieData = {
    labels: selectedData ? selectedData.labels : [],
    datasets: [
      {
        data: selectedData ? selectedData.values : [],
        backgroundColor: mutedColors,
        hoverBackgroundColor: mutedColors.map((color) => color), // Same muted colors on hover
      },
    ],
  };

  const handleClick = (event) => {
    const chart = doughnutRef.current;

    if (chart) {
      const activePoints = chart.getElementsAtEventForMode(
        event,
        "nearest",
        { intersect: true },
        false
      );

      if (activePoints.length > 0) {
        const { index } = activePoints[0];
        // Mock data for Pie chart related to each series
        if (index === 0) {
          setSelectedData({
            labels: ["Detail 1", "Detail 2", "Detail 3"],
            values: [50, 30, 20],
          });
        } else if (index === 1) {
          setSelectedData({
            labels: ["Detail A", "Detail B", "Detail C"],
            values: [70, 20, 10],
          });
        } else if (index === 2) {
          setSelectedData({
            labels: ["Detail X", "Detail Y", "Detail Z"],
            values: [60, 25, 15],
          });
        }
      }
    }
  };

  const clearPieChart = () => {
    setSelectedData(null); // Clear the selected data, which removes the pie chart
  };

  return (
    <div style={{ position: "relative", width: "400px", height: "400px" }}>
      {/* Doughnut Chart */}
      <Doughnut
        ref={doughnutRef}
        data={doughnutData}
        onClick={handleClick}
        options={{
          cutout: "70%", // Makes the center hollow to fit the pie chart inside
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        }}
      />

      {/* Pie Chart inside Doughnut */}
      {selectedData && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "200px",
            height: "200px",
          }}
        >
          <Pie
            data={pieData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />

          {/* Clear Icon */}
          <IconButton
            onClick={clearPieChart}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "5px",
            }}
            aria-label="clear"
          >
            <ClearIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default InteractiveChart;
