import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import "../assetss/css/Navbar.css";
import "../assetss/css/Button.css";
import crypto from "./Crypto";

class Logout extends Component {
  state = {
    navigate: false,
  };
  logout = () => {
    let lastLogged = crypto.decryptLocalStorage("user_email");
    sessionStorage.clear();
    localStorage.clear();
    if (
      typeof lastLogged !== "undefined" &&
      lastLogged !== null &&
      lastLogged.length > 0
    ) {
      crypto.encryptLocalStorage("user_email", lastLogged);
    }
    this.setState({ navigate: true });
  };
  render() {
    const { navigate } = this.state;
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }
    return (
      <Link to="#" className="nav-links" onClick={this.logout}>
        <i
          className={"fas fa-arrow-right-from-bracket fa-lg text-white px-2"}
        ></i>
        Cerrar sesión
      </Link>
    );
  }
}

export default Logout;
