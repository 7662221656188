import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Enumerable from "linq";
import TodayIcon from "@mui/icons-material/Today";
import { formatNumberES } from "./utils/Utils";
import "./../assetss/css/PageA4.css";

export const RenderReportCajasTable = (props) => {
  const { allData, allTipoPagos } = props;
  let reintegros = 0;

  let allFechas = Enumerable.from(allData)
    .groupBy((g) => g.fecha)
    .select((e) => ({
      fecha: e.key(),
      fecha_arg: e.max((m) => m.fecha_arg),
    }))
    .toArray();

  if (allData !== null && allData.length > 0) {
    reintegros = allData
      .filter((p) => p.tipo_prev === "RI")
      .reduce(function (prev, cur) {
        return prev + parseFloat(cur.importe_total);
      }, 0);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#475569",
      color: "#1e293b",
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "4px 10px",
      border: "1px solid #020617",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "0.5px solid #cbd5e1",
    },
  }));

  const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: "#1e293b",
      fontWeight: "bold",
      fontFamily: "Open Sans",
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "0px 5px",
      backgroundColor: "#d1d5db",
      fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
      border: "1px solid #334155",
    },
  }));

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(true);

    const renderTableHeader = () => (
      <TableRow>
        <StyledHeaderTableCell align="left">Usua.</StyledHeaderTableCell>
        <StyledHeaderTableCell align="left">M.Pago</StyledHeaderTableCell>
        <StyledHeaderTableCell align="left">Cbte.</StyledHeaderTableCell>
        <StyledHeaderTableCell align="left">Mov.</StyledHeaderTableCell>
        <StyledHeaderTableCell align="right">Id</StyledHeaderTableCell>
        <StyledHeaderTableCell align="right">Ref.</StyledHeaderTableCell>
        <StyledHeaderTableCell align="left">Emp.</StyledHeaderTableCell>
        <StyledHeaderTableCell align="left">Doc.</StyledHeaderTableCell>
        <StyledHeaderTableCell align="right">Fac.</StyledHeaderTableCell>
        <StyledHeaderTableCell align="right">Rec.</StyledHeaderTableCell>
      </TableRow>
    );

    return (
      <>
        <div className="mb-2">
          <TableRow>
            <StyledHeaderTableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <TodayIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledHeaderTableCell>
            <StyledHeaderTableCell
              width="100%"
              align="left"
              component="th"
              scope="row"
            >
              <Typography
                sx={{
                  fontSize: 14,
                  textAlign: "left",
                  paddingLeft: 4,
                  height: "25%",
                }}
              >
                Fecha {row.fecha_arg}
              </Typography>
            </StyledHeaderTableCell>
          </TableRow>
        </div>
        <Box sx={{ margin: 0 }}>
          <Table size="small" aria-label="purchases">
            {renderTableHeader()}
            <TableBody>
              {allData
                .filter((p) => p.fecha === row.fecha)
                .map((ctaRow, index) => {
                  const isNewPage = index % 45 === 0;
                  return (
                    <React.Fragment key={index}>
                      {isNewPage && index !== 0 && (
                        <div className="page-break"></div>
                      )}
                      {isNewPage && index !== 0 && renderTableHeader()}
                      <TableRow>
                        <StyledTableCell
                          align="left"
                          component="th"
                          scope="row"
                        >
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.username}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography
                            sx={{
                              fontSize: 10,
                              bgcolor:
                                ctaRow.lineas_pagos2 !== null &&
                                ctaRow.lineas_pagos2.toString().search(",") >= 0
                                  ? "#d1d5db"
                                  : "white",
                            }}
                          >
                            {ctaRow.lineas_pagos2}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.prefcbte}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.tipo_movimiento}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.id}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.referencia}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.codigo}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.documento}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.debehaber === "D"
                              ? ctaRow.importe_total
                              : 0.0}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Typography
                            sx={{
                              fontSize: 10,
                              fontFamily: "Open Sans",
                            }}
                          >
                            {ctaRow.debehaber === "H"
                              ? ctaRow.importe_total
                              : 0.0}
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </>
    );
  }

  function RowTipoPagos(props) {
    return (
      <Box sx={{ margin: 0 }}>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography
                  sx={{
                    fontSize: 10,
                  }}
                >
                  Tipo
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography
                  sx={{
                    fontSize: 10,
                  }}
                >
                  Cantidad
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography
                  sx={{
                    fontSize: 10,
                  }}
                >
                  Valor
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allTipoPagos.map((ctaRow) => {
              const isPaymentInCash =
                ctaRow.tipo_pago === "Dinero" ||
                ctaRow.tipo_pago === "Efectivo";
              const adjustedImporte = isPaymentInCash
                ? ctaRow.importe - reintegros
                : ctaRow.importe;

              return (
                <TableRow key={ctaRow.tipo_pago}>
                  <StyledTableCell align="left" component="th" scope="row">
                    <Typography
                      sx={{
                        fontSize: 10,
                        fontFamily: "Open Sans",
                      }}
                    >
                      {ctaRow.tipo_pago}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Typography
                      sx={{
                        fontSize: 10,
                      }}
                    >
                      {formatNumberES(ctaRow.cantidad)}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Typography
                      sx={{
                        fontSize: 10,
                        fontFamily: "Open Sans",
                      }}
                    >
                      {formatNumberES(adjustedImporte, 2)}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: 2 }}>
      <Table size="small" aria-label="cajas">
        <TableBody>
          {allFechas.map((row) => (
            <>
              <Box>
                <Row key={row.fecha} row={row} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  border: 1,
                  paddingRight: 2,
                }}
              >
                <Typography>Total del {row.fecha_arg}</Typography>
                <Typography variant="subtitle2" gutterBotto>
                  {formatNumberES(
                    allData
                      .filter(
                        (p) => p.debehaber === "D" && p.fecha === row.fecha
                      )
                      .reduce(function (prev, cur) {
                        return prev + parseFloat(cur.importe_total);
                      }, 0),
                    2
                  )}
                </Typography>
                <Typography variant="subtitle2" gutterBotto>
                  {formatNumberES(
                    allData
                      .filter(
                        (p) => p.debehaber === "H" && p.fecha === row.fecha
                      )
                      .reduce(function (prev, cur) {
                        return prev + parseFloat(cur.importe_total);
                      }, 0),
                    2
                  )}
                </Typography>
              </Box>
            </>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              border: 1,
              paddingRight: 2,
              bgcolor: "#d1d5db",
            }}
          >
            <Typography>Total general</Typography>
            <Typography variant="subtitle2" gutterBotto>
              {formatNumberES(
                allData
                  .filter((p) => p.debehaber === "D")
                  .reduce(function (prev, cur) {
                    return prev + parseFloat(cur.importe_total);
                  }, 0),
                2
              )}
            </Typography>
            <Typography variant="subtitle2" gutterBotto>
              {formatNumberES(
                allData
                  .filter((p) => p.debehaber === "H")
                  .reduce(function (prev, cur) {
                    return prev + parseFloat(cur.importe_total);
                  }, 0),
                2
              )}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <RowTipoPagos />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              border: 1,
              paddingRight: 1,
              bgcolor: "#d1d5db",
            }}
          >
            <Typography variant="subtitle2" gutterBotto>
              {formatNumberES(
                allTipoPagos.reduce(function (prev, cur) {
                  return prev + parseFloat(cur.importe);
                }, 0),
                2
              )}
            </Typography>
          </Box>
        </TableBody>
      </Table>
    </Box>
  );
};

export default RenderReportCajasTable;
