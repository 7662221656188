import React, { useState, useRef, useEffect, useContext } from "react";
import "../../assetss/css/App.css";
import { Typography } from "@mui/material";
import { Box, Container } from "@mui/material";
import ReporteTurnosPorAgenda from "./ReporteTurnosPorAgenda";
import ReporteCajaPorDia from "./ReporteCajaPorDia";
import ReporteLiquidacionLaboratorios from "./ReporteLiquidacionLaboratorios";
import ReporteParteDiario from "./ReporteParteDiario";
import { RenderReportParteGuardia } from "../RenderReportParteGuardia";
import { PrintButton } from "../PrintButton";
import { useLocation } from "react-router-dom";
import TopBarContext from "../contexts/TopBarContext";

export default function Reportes() {
  const location = useLocation(); // Get the state from navigation
  const { component } = location.state || {}; // Destructure the passed component name

  const [selectedReport, setSelectedReport] = React.useState(null);
  const { changeButton } = useContext(TopBarContext);

  useEffect(() => {
    // Check if there's a component in the state
    changeButton("Reportes");
    if (location.state && location.state.component) {
      setSelectedReport(location.state.component);
    }
  }, [location.state]);

  const [key, setKey] = useState(0);
  const componentRef = useRef();

  return (
    <>
      <Box sx={{ marginTop: 6 }}>
        {selectedReport === "ReporteTurnosPorAgenda" && (
          <ReporteTurnosPorAgenda />
        )}
        {selectedReport === "ReporteParteDiario" && <ReporteParteDiario />}
        {selectedReport === "ReporteMovimientosCajas" && <ReporteCajaPorDia />}
        {selectedReport === "ReporteLiquidacionLaboratorios" && (
          <ReporteLiquidacionLaboratorios />
        )}
        {selectedReport === "ReporteParteGuardia" && (
          <Container maxWidth="xl">
            <Box
              sx={{
                marginBottom: 4,
                marginLeft: 1,
              }}
            >
              <Typography variant="h6">Parte de guardia</Typography>
            </Box>
            <div key={key} style={{ display: "none" }}>
              <RenderReportParteGuardia ref={componentRef} />
            </div>
            <div className="flex justify-start ml-1 mt-8 mb-8">
              <PrintButton
                componentToPrint={componentRef}
                text={"Imprimir Parte de Guardia"}
                beforePrint={() => setKey(key + 1)}
              />
            </div>
          </Container>
        )}
      </Box>
    </>
  );
}
