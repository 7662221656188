import CryptoJS from "crypto-js";
const SECRET_KEY = "Riquelme10";

const encryptDataStorage = (name, data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  sessionStorage.setItem(name, encrypted);
};

const decryptDataStorage = (name) => {
  const encrypted = sessionStorage.getItem(name);
  if (encrypted !== null) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decrypted);
  } else {
    return null;
  }
};

const encryptLocalStorage = (name, data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  //localStorage.setItem(name, encrypted);
  sessionStorage.setItem(name, encrypted);
};

const decryptLocalStorage = (name) => {
  //const encrypted = localStorage.getItem(name);
  const encrypted = sessionStorage.getItem(name);
  if (encrypted !== null) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decrypted);
  } else {
    return null;
  }
};

export default {
  encryptDataStorage,
  decryptDataStorage,
  encryptLocalStorage,
  decryptLocalStorage,
};
