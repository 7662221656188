import React, { useState, useEffect } from "react";
import "../../assetss/css/App.css";
import { useHistory } from "react-router-dom";
import { ModeloGetUrl, ModeloGetUrlJson } from "../ModeloCrud";
import BorrarImagen from "../BorrarImagen";
import crypto from "../Crypto";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import ModalCapturePhoto from "../modals/ModalCapturePhoto";
import CircularProgress from "@mui/material/CircularProgress";
import ModalInformation from "../modals/ModalInformation";
import ModalShowImage from "../modals/ModalShowImage";
import ModalEnviarImagen from "../modals/ModalEnviarImagen";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import DeleteIcon from "@mui/icons-material/Delete";
import SubirArchivo from "../SubirArchivo";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Container from "@mui/material/Container";
import FacebookCircularProgress from "../FacebookCircularProgress";
import PacienteHeader from "./PacienteHeader";
import Image from "mui-image";
import MailIcon from "@mui/icons-material/Mail";
import RenderCustomTable from "../RenderCustomTable";

export default function PacienteImagenes() {
  const history = useHistory();
  const [loadingRx, setLoadingRx] = React.useState(false);
  const [loadingImg, setLoadingImg] = React.useState(false);
  const [allImagenes, setAllImagenes] = React.useState(null);
  const currentPatient = crypto.decryptDataStorage("dataPaciente");
  const [progress, setProgress] = React.useState(0);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalShowImageOpen, setShowImageOpen] = useState(false);
  const [ImageToShow, setImageToShow] = useState(null);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [openSubirArchivo, setOpenSubirArchivo] = useState(false);
  const [modalCaptureImageOpen, setModalCaptureImageOpen] = useState(false);
  const fotopaciente = crypto.decryptDataStorage("dataFoto");
  const [loading, setLoading] = useState(false);
  const [loadingCarga, setLoadingCarga] = useState(false);
  const [enviarImagen, setEnviarImagen] = useState(false);
  const [selectedImagen, setSelectedImagen] = useState(null);
  let currentPacientePrepaga =
    crypto.decryptDataStorage("currentpatientname") !== null &&
    crypto.decryptDataStorage("currentprepagacodigo") !== null
      ? crypto.decryptDataStorage("currentpatientname") +
        " (" +
        crypto.decryptDataStorage("currentprepagacodigo") +
        ")"
      : null;
  const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  };

  const columnsTable = [
    { field: "fecha", headerName: "Fecha", style: { width: "10%" } },
    { field: "descripcion", headerName: "Imagen", style: { width: "30%" } },
  ];

  const actions = [
    {
      icon: MailIcon,
      handle: (row) => handleEnviar(row),
      sx: { color: "primary.main" },
    },
    {
      icon: ZoomInIcon,
      handle: (row) => handleCargar(row),
      sx: { color: "primary.main" },
    },
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
    },
  ];

  function handleDelete(row) {
    setDeleteImageId(row.id);
  }

  const getColumns = (width) => {
    if (width < breakpoints.sm) {
      return 1;
    } else if (width < breakpoints.md) {
      return 1;
    } else if (width < breakpoints.lg) {
      return 1;
    } else if (width < breakpoints.xl) {
      return 1;
    } else {
      return 1;
    }
  };
  const [columns, setColumns] = useState(getColumns(window.innerWidth));

  useEffect(() => {
    buscarImagenes();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const HideShowImage = () => {
    setShowImageOpen(false);
  };

  const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth));
  };

  function handleClickVolver() {
    history.push("/pacientes");
  }

  const HideCaptureImage = () => {
    setModalCaptureImageOpen(false);
  };

  const base64toBlob = (data, extension) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    // const base64WithoutPrefix = data.substr(
    //  "data:application/pdf;base64,".length
    // );

    const base64WithoutPrefix = data.split(";base64,")[1];

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    let mytype = "application/pdf";
    if (extension === "pdf") {
      mytype = "application/pdf";
    } else if (extension === "txt") {
      mytype = "text/plain";
    } else if (extension === "csv") {
      mytype = "text/plain";
    }
    const url = URL.createObjectURL(new Blob([out], { type: mytype }));

    return url;
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };

  function imageClick(image, extension) {
    if (["jpg", "jpeg", "png", "gif", "bmp"].indexOf(extension) >= 0) {
      //setImageToShow(image);
      //setShowImageOpen(true);
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        `<!DOCTYPE html><head><title>Document preview</title></head><body><img src="${image}" width="100%" height="100%" ></body></html>`
      );
      pdfWindow.document.close();
    } else {
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        `<!DOCTYPE html><head><title>Document preview</title></head><body><iframe width="95%" height="720px" title="Pdf" src=` +
          base64toBlob(image, extension) +
          `></iframe></body></html>`
      );
    }
  }

  async function buscarImagenes() {
    setImageToShow(null);
    setDeleteImageId(null);
    setAllImagenes(null);
    const params = "bucket=pacientes&distinto=SI";
    setLoading(true);
    let result = await ModeloGetUrl(
      "paciente",
      currentPatient.id,
      "vista-imagenes-adjuntos",
      params
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        setAllImagenes(result.listado);
      }
    } else {
      setModalMessage(result.errormessage);
      setModalInformationOpen(true);
    }
    return;
  }

  async function handleCargar(row) {
    let params = "filename=" + row.carpeta + row.nombre_imagen + "&base64=SI";
    setLoadingCarga(true);
    let result = await ModeloGetUrlJson("get-url-file", null, null, params);
    setLoadingCarga(false);
    if (result.error === false) {
      if (["jpg", "jpeg", "png", "gif", "bmp"].indexOf(row.extension) >= 0) {
        setImageToShow("data:image/jpg;base64," + result.imagen);
      } else {
        setImageToShow(null);
        imageClick(
          "data:application/pdf;base64," + result.imagen,
          row.extension
        );
      }
    } else {
    }
  }

  const handleEnviar = (row) => {
    setSelectedImagen(row);
    setEnviarImagen(true);
  };

  const hideEnviarImagen = () => {
    setSelectedImagen(null);
    setEnviarImagen(false);
  };

  return (
    <>
      <div className="flex justify-start mt-3 mb-3 capitalize ">
        <i
          className="fas fa-chevron-circle-left fa-2x text-gray-500 mt-1 ml-3 mr-2 cursor-pointer"
          onClick={handleClickVolver}
        />
        <button
          className="text-gray-500"
          type="button"
          onClick={handleClickVolver}
        >
          Volver al Paciente
        </button>
      </div>
      <div className="m-2 capitalize">
        <PacienteHeader />
      </div>
      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      <Container
        maxWidth="xl"
        sx={{
          marginLeft: { xs: 0, lg: 3 },
          marginRight: { xs: 0, lg: 3 },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 4,
          }}
        >
          <Grid item xs={12} lg={4}>
            {!loading && (
              <Card sx={{ ml: -2, mr: -2, backgroundColor: "bgcards" }}>
                <CardHeader
                  title={
                    <Box
                      sx={{ display: "flex", justifyContent: "start", mt: 0 }}
                    >
                      <Typography sx={{ fontSize: 20, marginTop: 1 }}>
                        Imágenes
                      </Typography>
                      <Tooltip title="Tomar Imagen">
                        <IconButton
                          onClick={() => setModalCaptureImageOpen(true)}
                          size="small"
                          sx={{ ml: 2 }}
                        >
                          <AddAPhotoIcon
                            sx={{ fontSize: 32, color: "icongreen" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Subir Archivo">
                        <IconButton
                          onClick={() => setOpenSubirArchivo(true)}
                          size="small"
                          sx={{ ml: 2, mt: 0.5 }}
                        >
                          <DriveFolderUploadIcon
                            sx={{ fontSize: 36, color: "icongreen" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  }
                  subheader=""
                />

                <Box sx={{ mt: 0, ml: 1, mr: 1, mb: 4 }}>
                  <RenderCustomTable
                    data={allImagenes}
                    columns={columnsTable}
                    paddingLeft={0}
                    paddingRight={0}
                    actions={actions}
                  />
                </Box>
              </Card>
            )}
          </Grid>
          {!loading && (
            <Grid item xs={12} lg={8}>
              <Box
                sx={{
                  with: "100%",
                  height: "100%",
                  marginRight: 4,
                  border: 0,
                  borderRadius: 1,
                  borderColor: "avatar",
                  boxShadow: 6,
                  alignContent: "center",
                  maxHeight: "100%",
                }}
              >
                <div>
                  {loadingCarga && (
                    <Box
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FacebookCircularProgress />
                    </Box>
                  )}
                  {!loadingCarga && ImageToShow !== null && (
                    <Image
                      src={`${ImageToShow}`}
                      height="100%"
                      width="100%"
                      fit="contain"
                      duration={0}
                    />
                  )}
                </div>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
      <div className="bg-gradient-to-br from-gray-300 to-gray-100"></div>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalShowImage
        showModal={modalShowImageOpen}
        hideModal={HideShowImage}
        currentpatient={currentPatient}
        image={ImageToShow}
      />
      {deleteImageId !== null && (
        <BorrarImagen
          imageId={deleteImageId}
          setLoading={setLoading}
          afterMethod={() => buscarImagenes()}
          setDeleteImageId={setDeleteImageId}
        />
      )}
      {openSubirArchivo && (
        <SubirArchivo
          currentPaciente={currentPatient}
          setLoading={setLoadingImg}
          okmethod={() => buscarImagenes()}
          setOpenSubirArchivo={setOpenSubirArchivo}
          setModalMessage={setModalMessage}
          setModalInformationOpen={setModalInformationOpen}
        />
      )}
      <ModalCapturePhoto
        showModal={modalCaptureImageOpen}
        hideModal={HideCaptureImage}
        currentpatient={currentPatient}
        metodorefresh={buscarImagenes}
        folder="adjuntos"
        height={720}
        width={720}
      />
      {selectedImagen !== null && (
        <ModalEnviarImagen
          row={selectedImagen}
          showModal={enviarImagen}
          hideModal={hideEnviarImagen}
        />
      )}
    </>
  );
}
