import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import crypto from "../Crypto";
import { ModeloGetUrl } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ModalInformation from "../modals/ModalInformation";
import BackPage from "../BackPage";
import PacienteHeader from "./PacienteHeader";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import RenderCustomTable from "../RenderCustomTable";

const PacienteLPrecios = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allLista, setAllLista] = useState(null);
  let [filterLista, setFilterLista] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    buscarLPrecios();
  }, []);

  const columns = [
    { field: "codigo_nomenclador", headerName: "Código", isCodigoPrec: true },
    { field: "precio", headerName: "Precio", isPrecio: true },
    {
      field: "descripcion_nomenclador",
      headerName: "Descripción",
      capitalize: true,
    },
    { field: "grupo_dental", headerName: "Piezas", capitalize: true },
    { field: "observaciones", headerName: "Notas", lowercase: true },
  ];

  async function buscarLPrecios() {
    const currentPlanId = crypto.decryptDataStorage("currentplanid");
    if (currentPlanId !== null && typeof currentPlanId !== "undefined") {
      setLoading(true);
      const result = await ModeloGetUrl(
        "plan-prepaga",
        currentPlanId,
        "precios-nomenclador",
        null
      );
      setLoading(false);
      if (result.error === false) {
        if (result.listado.length > 0) {
          const allData = result.listado.sort((a, b) =>
            a.codigo_nomenclador > b.codigo_nomenclador ? -1 : 1
          );
          setAllLista(allData);
          setFilterLista(allData);
          //
        } else {
          setModalMessage("No se encontraron prestaciones.");
          setModalInformationOpen(true);
        }
      } else {
      }
    } else {
      handleClickVolver();
    }
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  function handleClickVolver() {
    history.push("/pacientes");
  }
  function onChangeSearch(e) {
    const filterText = e.target.value;
    setFilterText(filterText);
    FiltrarLista(filterText);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      FiltrarLista(filterText);
    }
  }

  function QuitarFiltro() {
    setFilterText("");
    filterLista = allLista.sort((a, b) =>
      a.codigo_nomenclador > b.codigo_nomenclador ? -1 : 1
    );
    let tempData = [...filterLista];
    setFilterLista(tempData);
  }

  function FiltrarLista(pFiltro) {
    if (pFiltro.length > 0) {
      filterLista = allLista
        .filter(
          (g) =>
            g.codigo_nomenclador.indexOf(pFiltro) >= 0 ||
            g.descripcion_nomenclador
              .toLowerCase()
              .indexOf(pFiltro.toLowerCase()) >= 0
        )
        .sort((a, b) => (a.codigo_nomenclador > b.codigo_nomenclador ? -1 : 1));
      if (filterLista.length <= 0) {
        filterLista = [];
      }
    } else {
      filterLista = allLista.sort((a, b) =>
        a.codigo_nomenclador > b.codigo_nomenclador ? -1 : 1
      );
    }
    let tempData = [...filterLista];
    setFilterLista(tempData);
  }

  return (
    <>
      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <PacienteHeader />
      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {!loading && (
        <div>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Container maxWidth="xl">
              <RenderCustomTable
                data={
                  filterLista !== null &&
                  filterLista.length > 0 &&
                  filterLista.sort((a, b) => (a.orden > b.orden ? 1 : -1))
                }
                columns={columns}
                paddingLeft={0}
                paddingRight={0}
                showSearch={true}
              />
            </Container>
          </Box>
        </div>
      )}
      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
};
export default PacienteLPrecios;
