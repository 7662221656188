import React, { useState, useEffect, useRef } from "react";
// Material-UI Components
import {
  Container,
  Box,
  Avatar,
  Grid,
  Typography,
  TextField,
  TableCell,
  tableCellClasses,
  TableRow,
  tableRowClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// Material-UI Icons
import {
  Delete as DeleteIcon,
  TableRows as TableRowsIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
// Date Picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// Formik
import { useFormik } from "formik";
// Utility Functions
import {
  CapSentenceArray,
  CapSentence,
  handleKeyDownField,
} from "../utils/Utils";
import * as yup from "yup";
import crypto from "../Crypto";
// Local Components
import ModalInformation from "../modals/ModalInformation";
import InsertOrdenItem from "./InsertOrdenItem";
import SaveCancelButtons from "../SaveCancelButtons";
import CustomAutoComplete from "../CustomAutoComplete";
// Modelo API Functions
import { ModeloPost, ModeloGetUrlJson } from "../ModeloCrud";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import FacebookCircularProgress from "../FacebookCircularProgress";
import RenderCustomTable from "../RenderCustomTable";
import { faC } from "@fortawesome/free-solid-svg-icons";

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export default function InsertUpdateOrdenes({
  ordenrow,
  itemsorden,
  presupuestos,
  itemspresupuestos,
  pasos,
  buscarCoes,
  hideModal,
  buscarOrdenes,
}) {
  const [allCoes, setAllCoes] = useState(null);
  const [myloading, setMyLoading] = React.useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [presupuestoItemId, setPresupuestoItemId] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const [newItems, setNewItems] = useState(itemsorden);
  const [expanded, setExpanded] = useState(false);
  const [piezasDentales, setPiezasDentales] = useState(null);
  const [nomencladores, setNomencladores] = useState(null);
  const [loading, setLoading] = useState(false);
  const [facturas, setFacturas] = useState([]);
  const [filteredOptionsWithHeader, setFilteredOptionsWithHeader] = useState(
    []
  );
  const fesperaInputRef = useRef(null);
  const coeInputRef = useRef(null);
  const labInputRef = useRef(null);
  const facInputRef = useRef(null);
  const notasInputRef = useRef(null);
  const coes = CapSentenceArray(crypto.decryptDataStorage("allcoes"), "coe");
  // Crear la fecha de hoy en la zona horaria de Argentina y normalizarla a solo la parte de fecha
  const todayInArgentina = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "America/Argentina/Buenos_Aires",
    })
  );
  todayInArgentina.setHours(0, 0, 0, 0); // Setear la hora a 00:00:00 para evitar problemas de comparación

  const columns = [
    { field: "id", headerName: "Id" },
    { field: "codigo_nomenclador", headerName: "Código" },
    { field: "numero_pieza_dental", headerName: "Pieza" },
    { field: "codigo_nomenclador_paso", headerName: "Paso" },
    {
      field: "descripcion_nomenclador_paso",
      headerName: "Descripción",
      capitalize: true,
    },
    { field: "motivo", headerName: "Motivo" },
    { field: "autorizado_por", headerName: "Autorizó" },
    { field: "cubeta", headerName: "Cubeta" },
  ];
  const actions = [
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
    },
  ];

  const [laboratorios, setLaboratorios] = useState(
    crypto.decryptDataStorage("laboratorios")
  );

  const validationSchema = yup.object({
    presupuesto: yup.object().required("Seleccione un Presupuesto"),
    femision: yup
      .date()
      .nullable()
      .typeError("Fecha inválida")
      .when([], {
        is: () => ordenrow === null,
        then: (schema) =>
          schema.min(
            todayInArgentina,
            "La fecha de emisión no puede ser menor al día de hoy"
          ),
        otherwise: (schema) => schema, // No se aplica la validación del mínimo si ordenrow no es null
      })
      .required("Ingrese la fecha de emisión"),
    fespera: yup
      .date()
      .min(
        yup.ref("femision"),
        "La fecha de espera no puede ser menos a la fecha de emisión"
      )
      .nullable()
      .typeError("Fecha inválida")
      .required("Ingrese la fecha de alta"),
    coe: yup.object().required("Seleccione un Odontólogo"),
    laboratorio: yup.object().required("Seleccione un Laboratorio"),
  });

  useEffect(() => {
    buscarCtaCtes();
  }, []);

  function handleDelete(row) {
    setNewItems(newItems.filter((i) => i.id !== row.id));
  }

  const formik = useFormik({
    initialValues: {
      presupuesto: null,
      femision:
        ordenrow !== null
          ? dayjs(ordenrow.fecha_emision)
          : dayjs().tz("America/Argentina/Buenos_Aires"),
      fespera:
        ordenrow !== null && ordenrow.fecha_espera !== null
          ? dayjs(ordenrow.fecha_espera)
          : dayjs().tz("America/Argentina/Buenos_Aires"),
      coe: null,
      laboratorio: null,
      factura: null,
      observaciones: ordenrow !== null ? ordenrow.observaciones : null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      GrabarOrden();
    },
  });

  const ldToday = new Date();
  const lcDate =
    ldToday.getFullYear() +
    "-" +
    (ldToday.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    ldToday.getDate().toString().padStart(2, "0");

  function borrarItem(id) {
    setNewItems(newItems.filter((i) => i.id !== id));
  }

  async function buscarCtaCtes() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    let params = "fullcta=SI";
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      let result = await ModeloGetUrlJson(
        "paciente",
        PacienteId,
        "vista-cta-cte",
        params
      );
      setLoading(false);
      if (result.error === false) {
        const tempPresu = [
          { isHeader: true }, // Add this as the first item to serve as the header
          ...presupuestos,
        ];
        setFilteredOptionsWithHeader(tempPresu);
        const tempFactu = [
          { isHeader: true }, // Add this as the first item to serve as the header
          ...result.ctacte.filter(
            (c) => ["FA", "FB"].indexOf(c.tipo_prev) >= 0
          ),
        ];
        setFacturas(tempFactu);
        if (ordenrow !== null) {
          let selPre = tempPresu.filter(
            (c) => c.id === ordenrow.presupuesto_id
          );
          if (selPre.length > 0) {
            formik.setFieldValue("presupuesto", selPre[0]);
          }
          let selCta = tempFactu.filter((c) => c.id === ordenrow.factura);
          if (selCta.length > 0) {
            formik.setFieldValue("factura", selCta[0]);
          }
        }
      } else {
      }
      busquedaCoes();
    } else {
      // handleClickVolver();
    }
  }

  const renderPresupuestoOption = (props, option) => {
    const tableStyles = {
      width: "100%", // Ensure the table takes full width
      borderCollapse: "collapse", // Ensure there's no spacing between cells
    };

    const cellStyles = {
      padding: "8px 12px", // Consistent padding for both header and rows
    };
    if (option.isHeader) {
      return (
        <li style={{ pointerEvents: "none", padding: 0 }}>
          {/* Disable click events for the header */}
          <table style={tableStyles}>
            <thead>
              <tr style={{ backgroundColor: "#dbeafe" }}>
                {/* Set header background color */}
                <th align="right" style={{ ...cellStyles, width: 100 }}>
                  Id
                </th>
                <th align="left" style={{ ...cellStyles, width: 100 }}>
                  Fecha
                </th>
                <th align="left" style={{ ...cellStyles, width: 150 }}>
                  Odontólogo
                </th>
                <th align="right" style={{ ...cellStyles, width: 100 }}>
                  Importe
                </th>
              </tr>
            </thead>
          </table>
        </li>
      );
    }

    return (
      <li {...props} style={{ padding: 0 }}>
        <table style={tableStyles}>
          <tbody>
            <tr>
              <td align="right" style={{ ...cellStyles, width: 100 }}>
                {option.id}
              </td>
              <td align="left" style={{ ...cellStyles, width: 100 }}>
                {option.fecha_em_arg}
              </td>
              <td align="left" style={{ ...cellStyles, width: 150 }}>
                {CapSentence(option.odontologo.toLowerCase())}
              </td>
              <td align="right" style={{ ...cellStyles, width: 100 }}>
                {option.importe_neto}
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    );
  };

  const renderCoeOption = (props, option) => (
    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
      <Avatar
        sx={{
          width: 24,
          height: 24,
          bgcolor: option.color,
          marginBottom: 0.5,
          marginRight: 1,
          border: 1,
          borderColor: "black",
        }}
      >
        {option.identificador}
      </Avatar>
      <Typography variant="body2">
        {CapSentence(option.odontologo + " - " + option.especialidad)}
      </Typography>
    </Box>
  );

  const renderFacturaOption = (props, option) => {
    const tableStyles = {
      width: "100%", // Ensure the table takes full width
      borderCollapse: "collapse", // Ensure there's no spacing between cells
    };

    const cellStyles = {
      padding: "8px 12px", // Consistent padding for both header and rows
    };
    if (option.isHeader) {
      return (
        <li style={{ pointerEvents: "none", padding: 0 }}>
          {/* Disable click events for the header */}
          <table style={tableStyles}>
            <thead>
              <tr style={{ backgroundColor: "#dbeafe" }}>
                {/* Set header background color */}
                <th align="right" style={{ ...cellStyles, width: 100 }}>
                  Id
                </th>
                <th align="left" style={{ ...cellStyles, width: 100 }}>
                  Tipo
                </th>
                <th align="left" style={{ ...cellStyles, width: 200 }}>
                  Fecha
                </th>
                <th align="right" style={{ ...cellStyles, width: 200 }}>
                  Importe
                </th>
              </tr>
            </thead>
          </table>
        </li>
      );
    }

    return (
      <li {...props} style={{ padding: 0 }}>
        <table style={tableStyles}>
          <tbody>
            <tr>
              <td align="right" style={{ ...cellStyles, width: 100 }}>
                {option.id}
              </td>
              <td align="left" style={{ ...cellStyles, width: 100 }}>
                {option.tipo_prev}
              </td>
              <td align="left" style={{ ...cellStyles, width: 200 }}>
                {option.fecha}
              </td>
              <td align="right" style={{ ...cellStyles, width: 200 }}>
                {option.importe_neto}
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    );
  };

  function handleKeyPress(event) {
    if (event.target.type != "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  function updateSelectedItem(Row) {
    const oldValue = Row.aprobado;
    const index = newItems.findIndex((obj) => {
      return obj.id === Row.id;
    });
    if (index >= 0) {
      newItems[index].aprobado = oldValue === 1 ? 0 : 1;
    }
    var tempItems = [...newItems];
    setNewItems(tempItems);
  }

  const onPresupuestoChange = (event, values) => {
    formik.setFieldValue("presupuesto", values);
    if (values) {
      setPresupuestoItemId(values.id);
    } else {
      setPresupuestoItemId(null);
    }
    if (!ordenrow && values) {
      let selCoe = coes.filter((c) => c.coe_id === values.coe_id);
      if (selCoe.length > 0) {
        formik.setFieldValue("coe", selCoe[0]);
      } else {
        let selCoe = coes.filter(
          (c) =>
            c.centro_id === values.centro_id &&
            c.especialidad_id === values.especialidad_id &&
            c.odontologo_id === values.odontologo_id
        );
        if (selCoe.length > 0) {
          formik.setFieldValue("coe", selCoe[0]);
        } else {
          formik.setFieldValue("coe", null);
        }
      }
    }
  };

  async function GrabarOrden() {
    if (!newItems?.length) {
      setModalMessage("Ingrese al menos un Item.");
      setModalInformationOpen(true);
      return;
    }
    const user_id = crypto.decryptLocalStorage("user_id");
    const coe_id = formik.values.coe.coe_id;
    const fecha_emision = ordenrow !== null ? ordenrow.fecha_emision : lcDate;
    const fecha_espera = formik.values.fespera.format().slice(0, 10);
    const ctacte_id_factura = formik.values.factura?.id ?? "NULL";
    const laboratorio_id = formik.values.laboratorio.id;
    const presupuesto_id = formik.values.presupuesto.id;
    const observaciones = formik.values.observaciones?.length
      ? formik.values.observaciones.trim()
      : "";
    const stencabezado =
      "id=-1" +
      "&laboratorio_id=" +
      laboratorio_id +
      "&presupuesto_id=" +
      presupuesto_id +
      "&fecha_emision=" +
      fecha_emision +
      "&fecha_espera=" +
      fecha_espera +
      "&user_id_emision=" +
      user_id +
      "&centro_odontologo_especialidad_id=" +
      coe_id +
      "&observaciones=" +
      observaciones +
      "&ctacte_id_factura=" +
      ctacte_id_factura;

    //Items
    let stitems = "&items=";
    for (var i = 0; i < newItems.length; i++) {
      if (stitems.length > 7) {
        stitems += "|";
      }
      const itemid = ordenrow === null ? -1 : newItems[i].id;
      const presupuesto_linea_id = newItems[i].presupuesto_linea_id.toString();
      const motivo = newItems[i].motivo;
      const nomenclador_paso_id = newItems[i].nomenclador_paso_id.toString();
      const autorizado_por =
        newItems[i].autorizado_por?.length > 0
          ? newItems[i].autorizado_por
          : "";
      const cubeta = newItems[i].cubeta?.length > 0 ? newItems[i].cubeta : "";
      stitems +=
        itemid +
        ";" +
        presupuesto_linea_id +
        ";" +
        motivo +
        ";" +
        nomenclador_paso_id +
        ";" +
        autorizado_por +
        ";" +
        cubeta;
    }
    setMyLoading(true);

    let result = null;
    if (ordenrow === null) {
      result = await ModeloPost(
        "orden-trabajo",
        0,
        "insert-update-orden",
        stencabezado + stitems
      );
    } else {
      result = await ModeloPost(
        "orden-trabajo",
        ordenrow.orden_id,
        "insert-update-orden",
        stencabezado + stitems
      );
    }
    if (result?.error === false) {
      buscarOrdenes();
      handleClose();
    } else {
      setModalMessage(result.mensaje);
      setModalErrorOpen(true);
    }
    setMyLoading(false);
  }

  const handleClose = () => {
    hideModal();
  };

  function searchCoes() {
    // buscarCtaCtes();
    const nomencladores = CapSentenceArray(
      crypto.decryptDataStorage("nomencladores"),
      ["codigo_descripcion"]
    );
    setAllCoes(coes);
    setNomencladores(nomencladores);
    setPiezasDentales(crypto.decryptDataStorage("piezas_dentales"));
    if (ordenrow !== null) {
      let selCoe = coes.filter((c) => c.coe_id === ordenrow.coe_id);
      if (selCoe.length > 0) {
        formik.setFieldValue("coe", selCoe[0]);
      } else {
        let selCoe = coes.filter(
          (c) =>
            c.centro_id === ordenrow.centro_id &&
            c.especialidad_id === ordenrow.especialidad_id &&
            c.odontologo_id === ordenrow.odontologo_id
        );
        if (selCoe.length > 0) {
          formik.setFieldValue("coe", selCoe[0]);
        }
      }
      let selLab = laboratorios.filter((c) => c.id === ordenrow.laboratorio_id);
      if (selLab.length > 0) {
        formik.setFieldValue("laboratorio", selLab[0]);
      }
    }

    return;
  }

  function busquedaCoes() {
    searchCoes();
  }

  const onCoeChange = (event, values) => {
    formik.setFieldValue("coe", values);
  };

  return (
    <>
      <div>
        {loading && (
          <div className="flex justify-center mt-2">
            <FacebookCircularProgress />
          </div>
        )}
        {!loading && allCoes !== null && allCoes.length > 0 && (
          <form
            key="orden-form"
            onSubmit={formik.handleSubmit}
            onKeyPress={handleKeyPress}
          >
            <Container maxWidth="lg" sx={{ marginTop: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: "avatar",
                    marginBottom: 2,
                    marginRight: 2,
                  }}
                >
                  <DescriptionIcon />
                </Avatar>
                <Typography sx={{ marginBottom: 2 }} variant="h6">
                  Encabezado
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                sx={{ borderBottom: 1, borderColor: "grey.400" }}
              >
                <Grid item xs={12} lg={6}>
                  <CustomAutoComplete
                    id="presupuesto"
                    options={filteredOptionsWithHeader}
                    value={formik.values.presupuesto}
                    label="Presupuesto"
                    onChange={onPresupuestoChange}
                    formik={formik}
                    getOptionLabel={(option) =>
                      option.isHeader
                        ? ""
                        : ` ${option.fecha_em_arg.toString()} ${CapSentence(
                            option.odontologo.toLowerCase()
                          )}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={renderPresupuestoOption}
                    setNullOnClear={true} // Do not set to null when cleared
                    nextRef={fesperaInputRef}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"en-gb"}
                  >
                    <DateField
                      id="femision"
                      label="Fecha de emisión"
                      readOnly
                      value={formik.values.femision}
                      inputFormat="DD/MM/YYYY"
                      format="DD/MM/YYYY"
                      //defaultValue={dayjs(row.alta_usa)}
                      onChange={(value) =>
                        formik.setFieldValue("femision", value)
                      }
                      size="small"
                      helperText={formik.errors.femision}
                      error={Boolean(formik.errors.femision)}
                      onKeyDown={(e) => handleKeyDownField(e, fesperaInputRef)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={(e) =>
                            handleKeyDownField(e, fesperaInputRef)
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"en-gb"}
                  >
                    <DateField
                      id="fespera"
                      label="Fecha de espera"
                      value={formik.values.fespera}
                      inputFormat="DD/MM/YYYY"
                      inputRef={(el) => (fesperaInputRef.current = el)}
                      format="DD/MM/YYYY"
                      //defaultValue={dayjs(row.alta_usa)}
                      onChange={(value) =>
                        formik.setFieldValue("fespera", value)
                      }
                      size="small"
                      helperText={formik.errors.fespera}
                      error={Boolean(formik.errors.fespera)}
                      onKeyDown={(e) => handleKeyDownField(e, coeInputRef)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={(e) => handleKeyDownField(e, coeInputRef)}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomAutoComplete
                    id="coe"
                    options={allCoes}
                    value={formik.values.coe}
                    label="Odontólogo"
                    formik={formik}
                    getOptionLabel={(option) =>
                      CapSentence(
                        option.odontologo + " - " + option.especialidad
                      ) +
                      " (" +
                      option.identificador +
                      ")"
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.coe === value.coe
                    }
                    renderOption={renderCoeOption} // Custom option rendering
                    inputRef={(el) => (coeInputRef.current = el)}
                    setNullOnClear={true} // Do not set to null when cleared
                    nextRef={labInputRef}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomAutoComplete
                    id="laboratorio"
                    options={laboratorios}
                    value={formik.values.laboratorio}
                    label="Laboratorio"
                    formik={formik}
                    getOptionLabel={(option) =>
                      CapSentence(option.codigo.toLowerCase()) +
                      " - " +
                      CapSentence(option.razon_social.toLowerCase())
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={null} // Custom option rendering
                    inputRef={(el) => (labInputRef.current = el)}
                    nextRef={facInputRef}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomAutoComplete
                    id="factura"
                    options={facturas}
                    value={formik.values.factura}
                    label="Factura"
                    formik={formik}
                    getOptionLabel={(option) =>
                      option.isHeader
                        ? ""
                        : `${option.tipo_prev} ${option.fecha.toString()} ${
                            option.id
                          }`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={renderFacturaOption}
                    inputRef={(el) => (facInputRef.current = el)}
                    nextRef={notasInputRef}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <TextField
                    id="observaciones"
                    label="Notas"
                    multiline
                    inputRef={notasInputRef}
                    rows={4}
                    value={formik.values.observaciones}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.observaciones
                        ? formik.errors.observaciones
                        : ""
                    }
                    error={
                      formik.touched.observaciones &&
                      Boolean(formik.errors.observaciones)
                    }
                    margin="dense"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Container>

            <Container
              maxWidth="lg"
              sx={{ borderBottom: 1, borderColor: "grey.400" }}
            >
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: "avatar",
                    marginTop: 2,
                    marginBottom: 0,
                    marginRight: 2,
                  }}
                >
                  <TableRowsIcon />
                </Avatar>
                <Typography sx={{ marginTop: 2, marginBottom: 0 }} variant="h6">
                  Items
                </Typography>
              </Box>
              <Container maxWidth="xl">
                <Box sx={{ mb: 4, marginTop: 2 }}>
                  <RenderCustomTable
                    data={newItems}
                    columns={columns}
                    iconColumns={null}
                    paddingLeft={0}
                    paddingRight={0}
                    actions={actions}
                  />
                </Box>
              </Container>
              <Box sx={{ marginTop: 2 }}>
                <InsertOrdenItem
                  newItems={newItems}
                  setNewItems={setNewItems}
                  setExpanded={setExpanded}
                  presupuestoItems={itemspresupuestos}
                  presupuestoId={formik.values.presupuesto?.id}
                  pasos={pasos}
                />
              </Box>
            </Container>
            <SaveCancelButtons handleCancel={handleClose} loading={myloading} />
          </form>
        )}
      </div>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
    </>
  );
}
