import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import crypto from "../Crypto";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalInformation from "../modals/ModalInformation";
import ModalConfirmation from "../modals/ModalConfirmation";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { ModeloPost } from "../ModeloCrud";
import { hasPermission } from "../utils/Utils";

const validationSchema = yup.object({
  fecha: yup
    .date()
    .min(new Date("2024-01-01"), "Fecha inválida")
    .nullable()
    .typeError("Fecha inválida")
    .required("Ingrese la fecha de alta"),
  importe: yup
    .number()
    .nullable(false)
    .min(1, "Ingrese un valor mayor a 0.")
    .typeError("Campo numérico")
    .required("Ingrese un valor mayor a 0."),
});

export default function InsertPresupuestoFormaPago({
  row,
  buscarPresupuestos,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const [formaPago, setFormaPago] = useState("");
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const canAdd = hasPermission(userRoles, "ALTA CCTACTES");

  function confirmOk() {
    if (modalMessage === "El importe es mayor al saldo. Continua igual ?") {
      hideConfirmationModal();
      // AddItem();
    }
  }

  const formik = useFormik({
    initialValues: {
      fecha: dayjs(Date()),
      importe: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      agregarPago(values);
    },
  });
  async function agregarPago(values) {
    setLoading(true);
    const userid = crypto.decryptLocalStorage("user_id");
    const Data = {
      presupuesto_id: row.id,
      fecha: values.fecha.format("YYYY-MM-DD"),
      importe: values.importe,
      user_id: userid,
    };

    let result = await ModeloPost("presupuesto-forma-pago", null, null, Data);
    setLoading(false);
    if (result.error === false) {
      buscarPresupuestos();
    } else {
      setModalMessage(result.errormessage);
      setModalInformationOpen(true);
    }
  }

  return (
    <>
      <div>
        <form key="item-form" onSubmit={formik.handleSubmit}>
          <Container maxWidth="xl">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "start",
                marginBottom: 0,
                gap: 2,
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"en-gb"}
              >
                <DateField
                  id="fecha"
                  label="Fecha"
                  value={formik.values.fecha}
                  inputFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  onChange={(value) => formik.setFieldValue("fecha", value)}
                  size="small"
                  helperText={formik.touched.fecha ? formik.errors.fecha : ""}
                  error={formik.touched.fecha && Boolean(formik.errors.fecha)}
                  sx={{ width: "140px" }}
                  disabled={!canAdd} // Disable if canAdd is false
                />
              </LocalizationProvider>
              <TextField
                id="importe"
                label="Importe"
                disabled={formaPago === "Lista" ? true : false}
                value={formik.values.importe}
                helperText={
                  formik.touched.importe ? formik.errors.importe : null
                }
                error={formik.touched.importe && Boolean(formik.errors.importe)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                margin="dense"
                variant="outlined"
                size="small"
                sx={{ width: "140px", marginTop: 0 }}
                InputProps={{
                  readOnly: !canAdd,
                }}
              />
              {canAdd && (
                <LoadingButton
                  onClick={formik.handleSubmit}
                  color="primary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<AddRoundedIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: "icongreen",
                    ":hover": {
                      bgcolor: "#475569", // theme.palette.primary.main
                      color: "white",
                    },
                    textTransform: "capitalize",
                    marginTop: 0,
                    height: 40,
                    fontWeight: "bold",
                  }}
                ></LoadingButton>
              )}
            </Box>
          </Container>
        </form>
      </div>
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={confirmOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
}
