import axios from "axios";
import crypto from "../components/Crypto";
import { useHistory } from "react-router-dom";
import Logout from "../components/Logout";
import { Redirect } from "react-router-dom";

//const REACT_APP_WEBAPI_URL =
// "https://consulmedapi-test-i2aufchu7q-ue.a.run.app/api/";
//const REACT_APP_WEBAPI_URL = process.env.REACT_APP_WEBAPI_URL

let REACT_APP_WEBAPI_URL = "";
if (window.location.hostname.indexOf("clinicasapp.consulmed.com.ar") >= 0) {
  REACT_APP_WEBAPI_URL = "https://api.consulmed.com.ar/api/";
} else {
  REACT_APP_WEBAPI_URL =
    "https://consulmedapi-test-i2aufchu7q-ue.a.run.app/api/";
}

const REACT_APP_URLAPI = "webapi";

const http = axios.create({
  baseURL: process.env.REACT_APP_MAIN_URL,
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
  },
});

async function axiosClient() {
  return http;
}

async function isBeta() {
  const httpGet = axios.create({
    baseURL: REACT_APP_WEBAPI_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
    },
  });
  let apiurl = REACT_APP_WEBAPI_URL + "api-url";

  try {
    let result = await httpGet.get(apiurl);
    return result.data;
  } catch (err) {
    console.log(err);
    alert("err");
    if (err.response) {
      crypto.encryptDataStorage("apistatus", err.response.request.status);

      return err.response.data;
    } else if (err.request) {
      // The client never received a response, and the request was never left
    } else {
      // Anything else
    }
  }
}

const GetAll = (url) => {
  const urlid =
    url +
    "?apikey=" +
    process.env.REACT_APP_API_KEY +
    "&session_key=" +
    crypto.decryptLocalStorage("session_key");
  return http.get(urlid);
};

async function GetSimpleUrl(url, params) {
  let apiurl = url;
  if (params !== null && params !== "") {
    apiurl += "&" + params;
  }
  const httpGet = axios.create({
    headers: {
      "Content-type": "application/json",
    },
  });
  try {
    let result = await httpGet.get(apiurl);
    return result.data;
  } catch (err) {
    if (err.response) {
      return err.response.data;
    } else if (err.request) {
      // The client never received a response, and the request was never left
    } else {
      // Anything else
    }
  }
}

async function GetUrl(url, params) {
  if (REACT_APP_URLAPI !== "webapi") {
    let urlid =
      url +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    if (params !== null && params !== "") {
      urlid += "&" + params;
    }
    return await http.get(urlid);
  } else {
    const httpGet = axios.create({
      baseURL: REACT_APP_WEBAPI_URL,
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
      },
    });
    let apiurl = REACT_APP_WEBAPI_URL + "apiget";
    apiurl += "?api_url=" + url;
    if (params !== null && params !== "") {
      apiurl += "&" + params;
    }
    try {
      return await httpGet.get(apiurl);
    } catch (err) {
      if (err.response) {
        // The client was given an error response (5xx, 4xx)

        crypto.encryptDataStorage("apistatus", err.response.request.status);
        if (err.response.data.errormessage !== null) {
          alert(err.response.data.errormessage);
        }
        var mainurl = window.location.origin;
        window.location = mainurl;
        // if (crypto.decryptLocalStorage("is-beta") === false) {
        //   window.location = "https://clinicasapp.consulmed.com.ar/";
        // } else {
        //   window.location = "http://localhost:3000/";
        // }
        //      return err.response.data;
      } else if (err.request) {
        // The client never received a response, and the request was never left
      } else {
        // Anything else
      }
    }
  }
}

async function GetUrlJson(url, params) {
  const httpGet = axios.create({
    baseURL: REACT_APP_WEBAPI_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
    },
  });
  let apiurl = REACT_APP_WEBAPI_URL + "apigetjson";
  apiurl += "?api_url=" + url;
  if (params !== null && params !== "") {
    apiurl += "&" + params;
  }
  //  return await httpGet.get(apiurl);

  try {
    let result = await httpGet.get(apiurl);

    if (result.error === true) {
      // window.location.href = "clinicasapp.consulmed.com.ar/login";
    } else {
      crypto.encryptDataStorage("apistatus", result.request.status);
      return result.data;
    }
  } catch (err) {
    if (err.response) {
      crypto.encryptDataStorage("apistatus", err.response.request.status);
      if (err.response.data.errormessage !== null) {
        alert(err.response.data.errormessage);
      }
      var mainurl = window.location.origin;
      window.location = mainurl;
      //if (crypto.decryptDataStorage("is-beta") === false) {
      //  window.location = "https://clinicasapp.consulmed.com.ar/";
      // } else {
      //   window.location = "http://localhost:3000/";
      // }
      //      return err.response.data;
    } else if (err.request) {
      // The client never received a response, and the request was never left
    } else {
      // Anything else
    }
  }
}

async function GetUrlJson2(Url, ModeloId, urlVista, Params) {
  const httpGet = axios.create({
    baseURL: REACT_APP_WEBAPI_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
    },
  });
  let apiurl = REACT_APP_WEBAPI_URL + "apigetjson";
  let GettUrl = Url;
  let GetParams = Params;
  if (ModeloId !== null) {
    GettUrl += "/" + ModeloId.toString().trim();
  }
  if (urlVista !== null) {
    GettUrl += "/" + urlVista.toString().trim();
  }
  apiurl += "?api_url=" + GettUrl;
  if (Params !== null && Params !== "") {
    apiurl += "&" + Params;
  }
  try {
    let result = await httpGet.get(apiurl);
    let respuesta = await result.then((response) => response.data);

    return respuesta;
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
    } else if (err.request) {
      // The client never received a response, and the request was never left
    } else {
      // Anything else
    }
  }
}

async function GetFotoPaciente(folder, filename) {
  const httpGet = axios.create({
    baseURL: REACT_APP_WEBAPI_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
    },
  });
  let apiurl = REACT_APP_WEBAPI_URL + "fotopaciente";
  apiurl += "?filename=" + folder + "/" + filename;
  return await httpGet.get(apiurl);
}

async function GetRxsPaciente(nro_doc, carpeta) {
  const httpGet = axios.create({
    baseURL: REACT_APP_WEBAPI_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
    },
  });
  let apiurl = REACT_APP_WEBAPI_URL + "rxpaciente-array";
  apiurl += "?nro_doc=" + nro_doc + "&carpeta=" + carpeta;
  return await httpGet.get(apiurl);
}

async function getUrlMiConsulmed(url) {
  let UrlMiCons = "https://mi.consulmed.com.ar/";
  let urlid = UrlMiCons + url;
  return await http.get(urlid);
}

async function postMiConsulmed(url, data) {
  let UrlMiCons = "https://mi.consulmed.com.ar/";
  let urlid = UrlMiCons + url;
  return await http.post(urlid, data);
}

const GetById = (url, id) => {
  const urlid =
    url +
    "/" +
    id +
    "?apikey=" +
    process.env.REACT_APP_API_KEY +
    "&session_key=" +
    crypto.decryptLocalStorage("session_key");
  return http.get(urlid);
};

async function Informacionpaciente(pacienteid, tipo_doc, nro_doc) {
  if (!REACT_APP_URLAPI == "webapi") {
    const url =
      "paciente/" +
      pacienteid +
      "/paciente-vista-web-app" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key") +
      "&tipo_doc=" +
      tipo_doc +
      "&nro_doc=" +
      nro_doc;
    return http.get(url);
  } else {
    const httpGet = axios.create({
      baseURL: REACT_APP_WEBAPI_URL,
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
      },
    });
    let apiurl = REACT_APP_WEBAPI_URL + "apiget";
    apiurl +=
      "?api_url=paciente&api_id=" +
      pacienteid +
      "&api_vista=paciente-vista-web-app&tipo_doc=" +
      tipo_doc +
      "&nro_doc=" +
      nro_doc;

    return await httpGet.get(apiurl);
  }
}

async function OldInformacionpaciente(pacienteid) {
  if (REACT_APP_URLAPI !== "webapi") {
    const url1 =
      "paciente/" +
      pacienteid +
      "/vista-paciente-prepaga-actualizada" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const url2 =
      "paciente/" +
      pacienteid +
      "/historia-clinica" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const url3 =
      "paciente/" +
      pacienteid +
      "/saldos" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const url4 =
      "paciente/" +
      pacienteid +
      "/ctacte" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const url5 =
      "paciente/" +
      pacienteid +
      "/presupuestos-vista" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const url6 =
      "paciente/" +
      pacienteid +
      "/observaciones" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const request1 = http.get(url1);
    const request2 = http.get(url2);
    const request3 = http.get(url3);
    const request4 = http.get(url4);
    const request5 = http.get(url5);
    const request6 = http.get(url6);
    return await Promise.all([
      request1,
      request2,
      request3,
      request4,
      request5,
      request6,
    ]);
  } else {
    const httpGet = axios.create({
      baseURL: REACT_APP_WEBAPI_URL,
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
      },
    });
    const apiurl = REACT_APP_WEBAPI_URL + "apiget";
    const url1 =
      apiurl + "?api_url=paciente&api_id=" + pacienteid + "&api_vista=prepaga";
    const url2 =
      apiurl +
      "?api_url=paciente&api_id=" +
      pacienteid +
      "&api_vista=historia-clinica";
    const url3 =
      apiurl + "?api_url=paciente&api_id=" + pacienteid + "&api_vista=saldos";
    const url4 =
      apiurl + "?api_url=paciente&api_id=" + pacienteid + "&api_vista=ctacte";
    const url5 =
      apiurl +
      "?api_url=paciente&api_id=" +
      pacienteid +
      "&api_vista=presupuestos-vista";
    const url6 =
      apiurl +
      "?api_url=paciente&api_id=" +
      pacienteid +
      "&api_vista=observaciones";
    const request1 = httpGet.get(url1);
    const request2 = httpGet.get(url2);
    const request3 = httpGet.get(url3);
    const request4 = httpGet.get(url4);
    const request5 = httpGet.get(url5);
    const request6 = httpGet.get(url6);
    return await Promise.all([
      request1,
      request2,
      request3,
      request4,
      request5,
      request6,
    ]);
  }
}

async function InitialData() {
  if (REACT_APP_URLAPI !== "webapi") {
    const url1 =
      "especialidad/motivos-turnos" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const url2 =
      "piezas-dentales" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const url3 =
      "centro-odontologo-especialidad/detalle" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const url4 =
      "tabla/DERIVACION_ODONTOLOGOS" +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    const request1 = http.get(url1);
    const request2 = http.get(url2);
    const request3 = http.get(url3);
    const request4 = http.get(url4);
    return await Promise.all([request1, request2, request3, request4]);
  } else {
    const httpGet = axios.create({
      baseURL: REACT_APP_WEBAPI_URL,
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
      },
    });
    const apiurl = REACT_APP_WEBAPI_URL + "apiget";
    const url1 = apiurl + "?api_url=especialidad&api_vista=motivos-turnos";
    const url2 = apiurl + "?api_url=piezas-dentales";
    const url3 =
      apiurl + "?api_url=centro-odontologo-especialidad&api_vista=vista-full";
    const url4 = apiurl + "?api_url=tabla/DERIVACION_ODONTOLOGOS";
    const url5 = apiurl + "?api_url=nomenclador&api_vista=vista-full";
    const request1 = httpGet.get(url1);
    const request2 = httpGet.get(url2);
    const request3 = httpGet.get(url3);
    const request4 = httpGet.get(url4);
    const request5 = httpGet.get(url5);
    return await Promise.all([
      request1,
      request2,
      request3,
      request4,
      request5,
    ]);
  }
}

async function Informaciongrabarpresupuesto() {
  const url1 =
    "piezas-dentales" +
    "?apikey=" +
    process.env.REACT_APP_API_KEY +
    "&session_key=" +
    crypto.decryptLocalStorage("session_key");
  const url2 =
    "centro-odontologo-especialidad/vista-full" +
    "?apikey=" +
    process.env.REACT_APP_API_KEY +
    "&session_key=" +
    crypto.decryptLocalStorage("session_key");
  const url3 =
    "nomenclador/vista-full" +
    "?apikey=" +
    process.env.REACT_APP_API_KEY +
    "&session_key=" +
    crypto.decryptLocalStorage("session_key");
  const request1 = http.get(url1);
  const request2 = http.get(url2);
  const request3 = http.get(url3);
  return await Promise.all([request1, request2, request3]);
}

async function login(url, data) {
  let apiurl = REACT_APP_WEBAPI_URL + "login";
  return await http.post(apiurl, data);

  // if (REACT_APP_URLAPI !== 'webapi') {
  //   data.apikey = process.env.REACT_APP_API_KEY;
  //   return await http.post(url, data);
  // } else {
  //   let apiurl = REACT_APP_WEBAPI_URL + 'login'
  //   data.email = data.username;
  //   return await http.post(apiurl, data);
  // }
}

async function LoggedUser(jwtoken) {
  const httpGet = axios.create({
    baseURL: REACT_APP_WEBAPI_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + jwtoken,
    },
  });
  let apiurl = REACT_APP_WEBAPI_URL + "loggeduser";
  return await httpGet.get(apiurl);
}

async function setPassword(userId, data) {
  const httpPost = axios.create({
    baseURL: REACT_APP_WEBAPI_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
    },
  });

  // Correct URL with a slash between userId and set-password
  let postUrl = REACT_APP_WEBAPI_URL + "user/" + userId + "/set-password";

  // Make the POST request
  return await httpPost.post(postUrl, data);
}

async function Create(url, data) {
  //const {session_key}  = useContext(UserContext);

  if (REACT_APP_URLAPI !== "webapi") {
    if (data === null) {
      data = {
        apikey: process.env.REACT_APP_API_KEY,
        session_key: crypto.decryptLocalStorage("session_key"),
      };
    } else {
      if (typeof data === "object") {
        data.apikey = process.env.REACT_APP_API_KEY;
        data.session_key = crypto.decryptLocalStorage("session_key");
      }
      if (typeof data === "string") {
        url +=
          "?apikey=" +
          process.env.REACT_APP_API_KEY +
          "&session_key=" +
          crypto.decryptLocalStorage("session_key") +
          "&" +
          data;
        data = null;
      }
    }
    return await http.post(url, data);
  } else {
    const httpPost = axios.create({
      baseURL: REACT_APP_WEBAPI_URL,
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
      },
    });
    let apiurl = REACT_APP_WEBAPI_URL + "apipost";
    if (data === null) {
      data = {
        api_url: url,
      };
    } else {
      if (typeof data === "object") {
        data.api_url = url;
      }
      if (typeof data === "string") {
        apiurl += "?api_url=" + url + "&" + data;
        data = null;
      }
    }
    try {
      let result = await httpPost.post(apiurl, data);

      crypto.encryptDataStorage("apistatus", result.request.status);
      return result.data;
    } catch (err) {
      if (err.response) {
        crypto.encryptDataStorage("apistatus", err.response.request.status);

        return err.response.data;
      } else if (err.request) {
        // The client never received a response, and the request was never left
      } else {
        // Anything else
      }
    }
  }
}

async function Create2(url, data) {
  data.apikey = process.env.REACT_APP_API_KEY;
  data.session_key = crypto.decryptLocalStorage("session_key");
  let retdata = null;
  await http
    .post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {});
}

const Update = (url, id, data) => {
  try {
    if (REACT_APP_URLAPI !== "webapi") {
      let urlid = url + "/" + id;
      if (typeof data === "object") {
        data.apikey = process.env.REACT_APP_API_KEY;
        data.session_key = crypto.decryptLocalStorage("session_key");
      }
      if (typeof data === "string") {
        urlid +=
          "?apikey=" +
          process.env.REACT_APP_API_KEY +
          "&session_key=" +
          crypto.decryptLocalStorage("session_key") +
          "&" +
          data;
        data = null;
      }
      return http.put(urlid, data);
    } else {
      const httpPut = axios.create({
        baseURL: REACT_APP_WEBAPI_URL,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
        },
      });
      let apiurl = REACT_APP_WEBAPI_URL + "apiput";
      if (data === null) {
        data = {
          api_url: url,
          api_id: id,
        };
      } else {
        if (typeof data === "object") {
          data.api_url = url;
          data.api_id = id;
        }
        if (typeof data === "string") {
          apiurl += "?api_url=" + url + "&api_id=" + id + "&" + data;
          data = null;
        }
      }

      return httpPut.put(apiurl, data);
    }
  } catch (error) {
    return error(error);
  }
};

const Remove = (url, id) => {
  if (REACT_APP_URLAPI !== "webapi") {
    const urlid =
      url +
      "/" +
      id +
      "?apikey=" +
      process.env.REACT_APP_API_KEY +
      "&session_key=" +
      crypto.decryptLocalStorage("session_key");
    return http.delete(urlid);
  } else {
    const httpDelete = axios.create({
      baseURL: REACT_APP_WEBAPI_URL,
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + crypto.decryptLocalStorage("jwtcr"),
      },
    });
    let apiurl =
      REACT_APP_WEBAPI_URL + "apidelete?api_url=" + url + "&api_id=" + id;
    return httpDelete.delete(apiurl);
  }
};

// eslint-disable-next-line
export default {
  axiosClient,
  login,
  LoggedUser,
  GetAll,
  GetSimpleUrl,
  GetUrl,
  GetUrlJson,
  GetUrlJson2,
  GetById,
  getUrlMiConsulmed,
  postMiConsulmed,
  Create,
  Update,
  Remove,
  Informacionpaciente,
  GetFotoPaciente,
  setPassword,
  GetRxsPaciente,
  InitialData,
  Informaciongrabarpresupuesto,
  isBeta,
};
