import React, { useEffect, useRef, useState, useContext } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useHistory } from "react-router-dom";
import crypto from "../Crypto";
import ModalConfirmation from "../modals/ModalConfirmation";
import ModalError from "../modals/ModalError";
import ModalInformation from "../modals/ModalInformation";
import ModalNota from "../modals/ModalNota";
import ProtectedRoute from "../ProtectedRoute";
import MultipleResultsTable from "../MultipleResultsTable";
import TextField from "@mui/material/TextField";
import FacebookCircularProgress from "../FacebookCircularProgress";
import {
  ModeloDelete,
  ModeloGetFotoPaciente,
  ModeloGetUrl,
  ModeloGetUrlJson,
  ModeloSearch,
} from "../ModeloCrud";
import PacientesDatosPersonales from "./PacientesDatosPersonales";
import PacientesEmpresas from "./PacientesEmpresas";
import PacientesNotas from "./PacientesNotas";
import PacientesPendientes from "./PacientesPendientes";
import PacientesBusquedaAvanzada from "./PacientesBusquedaAvanzada";
import {
  CapSentenceArray,
  CapSentence,
  FormatHorario45,
  hasPermission,
} from "../utils/Utils.js";
import CustomizedSearch from "../CustomizedSearch.js";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import FaceIcon from "@mui/icons-material/Face";
import Face2Icon from "@mui/icons-material/Face2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import AddCircle from "@mui/icons-material/AddCircle";
import Paper from "@mui/material/Paper";
import PacienteMenuWide from "./PacienteMenuWide";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import TopBarContext from "../contexts/TopBarContext";

const Pacientes = () => {
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchPaciente, setSearchPaciente] = useState("");
  const [allPacientes, setAllPacientes] = useState(null);
  let [filterPacientes, setFilterPacientes] = useState(null);
  const [currentPaciente, setCurrentPaciente] = useState(null);
  const [fotoPaciente, setFotoPaciente] = useState(null);
  const [fotoPacienteId, setFotoPacienteId] = useState(null);
  const [allPrepagas, setAllPrepagas] = useState(null);
  const [currentPrepagaCodigo, setCurrentPrepagaCodigo] = useState(null);
  const [currentPlanId, setCurrentPlanId] = useState(null);
  const [turnosPendientes, setTurnosPendientes] = useState(null);
  const [citasAnteriores, setCitasAnteriores] = useState(null);
  const [saldoFacturas, setSaldoFacturas] = useState(null);
  const [saldoPresupuestos, setSaldoPresupuestos] = useState(null);
  const [allDatosPaciente, SetAllDatosPaciente] = useState(null);
  const [filterObservaciones, setFilterObservaciones] = useState(null);
  const [filterPresupuestos, setFilterPresupuestos] = useState(null);
  const [modalPacientesOpen, setModalPacientesOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [currentPadron, setCurrentPadron] = useState(null);
  const [deleteNotaId, setDeleteNotaId] = useState(0);
  const [modalNotaOpen, setModalNotaOpen] = useState(false);
  const [selectedHistorial, setSelectedHistorial] = useState(null);
  const [currentPrepagaRazon, setCurrentPrepagaRazon] = useState(null);
  const [allCtaCte, setAllCtaCte] = useState(null);
  const [turnosDelDia, setTurnosDelDia] = useState(null);
  const [currentTasaIva, setCurrentTasaIva] = useState(null);
  const [modalCtaCteOpen, setModalCtaCteOpen] = useState(false);
  const [allObservaciones, setAllObservaciones] = useState(null);
  const history = useHistory();
  const $ = require("jquery");

  const objHeaders = [
    "Ver",
    "Documento",
    "Apellido",
    "Nombres",
    "F.Nacimiento",
    "Teléfono",
    "Teléfono 2",
    "Celular",
    "Email",
  ];
  const objFields = [
    "nro_documento",
    "apellido",
    "nombres",
    "fecha_nacimiento",
    "telefono",
    "telefono2",
    "celular",
    "email",
  ];
  const objDates = ["fecha_nacimiento"];
  const { changeButton } = useContext(TopBarContext);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const [anchorElBa, setAnchorElBa] = React.useState(null);

  const hancleClickBusquedaAvanzada = (event) => {
    setModalPacientesOpen(false);
    setAnchorElBa(event.currentTarget);
  };

  const handleCloseBa = () => {
    setAnchorElBa(null);
  };

  const openBa = Boolean(anchorElBa);
  const idBa = openBa ? "simple-popover" : undefined;

  useEffect(() => {
    changeButton("Pacientes");
    if (crypto.decryptDataStorage("searchpatient") !== null) {
      crypto.encryptDataStorage("currentpatient", null);
      BuscarPaciente(crypto.decryptDataStorage("searchpatient"), true, null);
      crypto.encryptDataStorage("searchpatient", null);
    }
    if (crypto.decryptDataStorage("currentpatient") !== null) {
      BuscarPaciente(crypto.decryptDataStorage("currentpatient"), true, null);
    }
  }, []);

  const onPermissionGranted = () => {
    changeButton("Pacientes");
    if (crypto.decryptDataStorage("searchpatient") !== null) {
      crypto.encryptDataStorage("currentpatient", null);
      BuscarPaciente(crypto.decryptDataStorage("searchpatient"), true, null);
      crypto.encryptDataStorage("searchpatient", null);
    }
    if (crypto.decryptDataStorage("currentpatient") !== null) {
      BuscarPaciente(crypto.decryptDataStorage("currentpatient"), true, null);
    }
  };

  const onHistorialChange = (event, values) => {
    setSelectedHistorial(null);
    crypto.encryptDataStorage("currentpatient", null);
    BuscarPaciente(values.id, true, null);
    document.getElementById("search").focus();
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };

  function Transferir() {
    hideConfirmationModal();
    if (typeof currentPadron !== "undefined" && currentPadron !== null) {
      crypto.encryptDataStorage("currentpadronid", currentPadron.id);
      history.push({
        pathname: "/insertupdatepaciente",
        currentPadron,
      });
    } else {
      setModalMessage("No se encontró el afiliado!");
      setModalWarningOpen(true);
    }
  }

  async function buscarCtaCtePaciente() {
    setLoading(true);
    let currentpatient = crypto.decryptDataStorage("currentpatient");
    let result = await ModeloGetUrl(
      "paciente",
      currentpatient,
      "vista-cta-cte",
      null
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        setAllCtaCte(result.listado);
        setModalCtaCteOpen(true);
      } else {
        setModalMessage("No se encontraron movimientos.");
        setModalInformationOpen(true);
      }
    } else {
      setModalMessage(
        result.mensaje != null
          ? result.mensaje
          : result.errormessage != null
          ? result.errormessage
          : "Error desconocido."
      );
      setModalWarningOpen(true);
    }
    return;
  }

  function ConfirmationModalOk() {
    if (modalMessage.search("Desea transferir el afiliado") >= 0) {
      Transferir();
    }
  }

  function borrarnota(id, userid) {
    let user_id = crypto.decryptLocalStorage("user_id");
    if (user_id.toString() !== userid.toString()) {
      setModalMessage(
        "No se puede borrar la Nota de otro Usuario. " + user_id + " " + userid
      );
      setModalInformationOpen(true);
      return;
    } else {
      setDeleteNotaId(id);
      setDisplayConfirmationModal(true);
      setModalMessage("Seguro quiere Borrar la Nota ?");
    }
  }

  function getNObservaciones(data, rows) {
    //const filterObservaciones = Linqer.Enumerable.from(data).orderByDescending(g => g.id).take(rows).toArray();
    const filterObservaciones = data
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .slice(0, rows);
    crypto.encryptDataStorage("dataObservaciones", filterObservaciones);
    setFilterObservaciones(filterObservaciones);
    //const allObservaciones = Linqer.Enumerable.from(data).orderByDescending(g => g.id).toArray();
    const allObservaciones = data.sort((a, b) => (a.id > b.id ? -1 : 1));
    setAllObservaciones(allObservaciones);
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const hideErrorModal = () => {
    setModalMessage(null);
    setModalWarningOpen(false);
  };
  const hideWarningModal = () => {
    setModalWarningOpen(false);
    setModalMessage(null);
  };

  function limpiarPaciente() {
    setCurrentPaciente(null);
    setFotoPaciente(null);
    setFotoPacienteId(null);
    setAllPacientes(null);
    setFilterPacientes(null);
    setAllPrepagas(null);
    setCurrentPrepagaCodigo(null);
    setCurrentPrepagaRazon(null);
    setCurrentPlanId(null);
    setAllCtaCte(null);
    setSaldoFacturas(null);
    setSaldoPresupuestos(null);
    setModalPacientesOpen(null);
    setModalWarningOpen(false);
    setModalInformationOpen(false);
    setModalMessage(null);
    setDeleteNotaId(0);
    SetAllDatosPaciente(null);
    setLoading(false);
    setTurnosPendientes(null);
    setCitasAnteriores(null);

    sessionStorage.removeItem("currentpatient");
    sessionStorage.removeItem("currentpatientname");
    sessionStorage.removeItem("currentpacpreid");
    sessionStorage.removeItem("currentplanid");
    sessionStorage.removeItem("currentprepagacodigo");
    sessionStorage.removeItem("currentprepagarazon");
    sessionStorage.removeItem("currentcredencial");
    sessionStorage.removeItem("currenttasaiva");
    sessionStorage.removeItem("currentfichado");
    sessionStorage.removeItem("bajapacpre");
    sessionStorage.removeItem("dataPaciente");
    sessionStorage.removeItem("dataPrepagas");
    sessionStorage.removeItem("dataObservaciones");
    sessionStorage.removeItem("dataTPendientes");
    sessionStorage.removeItem("dataCitasPendientes");
    sessionStorage.removeItem("dataTDia");
    sessionStorage.removeItem("dataSFacturas");
    sessionStorage.removeItem("dataSPresupuestos");
    sessionStorage.removeItem("dataFoto");
    sessionStorage.removeItem("dataFotoId");
    setSearchPaciente("");
  }

  async function mostrarPaciente(data, ChangeCodigo) {
    const paciente = data.paciente[0];
    const foto = data.foto.length > 0 ? data.foto : null;
    let fotoid = null;
    if (paciente.foto_paciente.length > 0) {
      const arrFoto = paciente.foto_paciente.split(";");
      setFotoPacienteId(arrFoto[2]);
      fotoid = arrFoto[2];
    } else {
      setFotoPacienteId(null);
    }
    setCurrentPaciente(paciente);
    setFotoPaciente(foto);
    getPrepagas(data.prepagas, ChangeCodigo);
    var ldToday = new Date();
    var lcDate =
      ldToday.getFullYear() +
      "-" +
      (ldToday.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      ldToday.getDate().toString().padStart(2, "0");
    const TPendientes = data.tpendientes.sort((a, b) =>
      a.fecha_usa > b.fecha_usa ? 1 : -1
    );

    const TDia = data.tpendientes
      .filter((g) => g.fecha_usa === lcDate)
      .sort((a, b) => (a.hora > b.hora ? 1 : -1));
    setTurnosPendientes(TPendientes);
    setCitasAnteriores(data.ultimascitas);
    setTurnosDelDia(TDia);
    setSaldoFacturas(parseInt(data.saldos.saldo_facturas));
    setSaldoPresupuestos(parseInt(data.saldos.saldo_presupuestos));
    getNObservaciones(data.observaciones, 5);
    crypto.encryptDataStorage("dataPaciente", paciente);
    crypto.encryptDataStorage("currentpatient", paciente.id);
    crypto.encryptDataStorage("dataFoto", foto);
    crypto.encryptDataStorage("dataFotoId", fotoid);
    crypto.encryptDataStorage(
      "currentpatientname",
      paciente.apellido.toLowerCase() + ", " + paciente.nombres.toLowerCase()
    );
    crypto.encryptDataStorage("dataTPendientes", TPendientes);
    crypto.encryptDataStorage("dataCitasPendientes", data.ultimascitas);
    crypto.encryptDataStorage("dataTDia", TDia);
    crypto.encryptDataStorage(
      "dataSFacturas",
      parseInt(data.saldos.saldo_facturas)
    );
    crypto.encryptDataStorage(
      "dataSPresupuestos",
      parseInt(data.saldos.saldo_presupuestos)
    );
    let addPaciente = true;
    if (crypto.decryptLocalStorage("historialpacientes") !== null) {
      const searchHistorial = crypto
        .decryptLocalStorage("historialpacientes")
        .filter((hp) => hp.nro_documento === paciente.nro_documento);
      if (searchHistorial.length > 0) {
        addPaciente = false;
      }
    } else {
      crypto.encryptLocalStorage("historialpacientes", []);
    }
    if (addPaciente === true) {
      let tempHistorial = crypto.decryptLocalStorage("historialpacientes");
      let rowHP = {};
      rowHP["id"] = paciente.id;
      rowHP["nombre"] = paciente.apellido + " " + paciente.nombres;
      rowHP["nro_documento"] = paciente.nro_documento;
      rowHP["sexo"] = paciente.sexo;
      rowHP["foto"] = foto;
      rowHP["orden"] = crypto.decryptLocalStorage("historialpacientes").length;
      tempHistorial.push(rowHP);
      const sortHistorial = tempHistorial.sort((a, b) =>
        a.orden > b.orden ? -1 : 1
      );
      crypto.encryptLocalStorage("historialpacientes", sortHistorial);
    }
    SetAllDatosPaciente("Completo");
  }

  function getPrepagas(data, ChangeCodigo) {
    try {
      sessionStorage.removeItem("currentpacpreid");
      sessionStorage.removeItem("currentplanid");
      sessionStorage.removeItem("currentprepagacodigo");
      sessionStorage.removeItem("currentprepagarazon");
      sessionStorage.removeItem("currentcredencial");
      sessionStorage.removeItem("currenttasaiva");
      sessionStorage.removeItem("bajapacpre", "NO");
      setCurrentPlanId(null);
      setCurrentPrepagaCodigo(null);
      setCurrentPrepagaRazon(null);
      setCurrentTasaIva(null);
      // let currentPrepaga = Linqer.Enumerable.from(data).where(g => g.fecha_baja === null).orderByDescending(g => g.fecha_ultimo_padron).take(1).toArray();
      let currentPrepaga = data
        .filter((p) => p.fecha_baja === null)
        .sort((a, b) =>
          a.fecha_ultimo_padron > b.fecha_ultimo_padron ? -1 : 1
        )
        .slice(0, 1);
      if (currentPrepaga.length === 0) {
        //currentPrepaga = Linqer.Enumerable.from(data).orderByDescending(g => g.fecha_ultimo_padron).take(1).toArray();
        currentPrepaga = data
          .sort((a, b) =>
            a.fecha_ultimo_padron > b.fecha_ultimo_padron ? -1 : 1
          )
          .slice(0, 1);
      }
      if (currentPrepaga.length > 0) {
        crypto.encryptDataStorage("currentpacpreid", currentPrepaga[0].id);
        crypto.encryptDataStorage(
          "currentplanid",
          currentPrepaga[0].planes_prepaga_id
        );
        crypto.encryptDataStorage(
          "currentprepagacodigo",
          currentPrepaga[0].codigo
        );
        crypto.encryptDataStorage(
          "currentprepagarazon",
          currentPrepaga[0].denominacion_comercial
        );
        crypto.encryptDataStorage(
          "currentcredencial",
          currentPrepaga[0].numero_credencial
        );
        crypto.encryptDataStorage("currenttasaiva", currentPrepaga[0].tasa_iva);
        setCurrentPrepagaCodigo(currentPrepaga[0].codigo);
        setCurrentPrepagaRazon(currentPrepaga[0].denominacion_comercial);
        setCurrentTasaIva(currentPrepaga[0].tasa_iva);
        setCurrentPlanId(currentPrepaga[0].planes_prepaga_id);
        if (currentPrepaga[0].fecha_baja !== null) {
          crypto.encryptDataStorage("bajapacpre", "SI");
        }
      }
      crypto.encryptDataStorage("dataPrepagas", data);
      setAllPrepagas(data);

      if (
        currentPrepaga.length &&
        ChangeCodigo !== null &&
        ChangeCodigo !== currentPrepaga[0].codigo
      ) {
        changePrepaga(ChangeCodigo, data);
      }
    } catch (error) {
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }

  function changePrepaga(codigo, data) {
    if (codigo === currentPrepagaCodigo) {
      return;
    }
    // let currentPrepaga = Linqer.Enumerable.from(data).where(g => g.codigo === codigo).take(1).toArray();
    let currentPrepaga = data.filter((g) => g.codigo === codigo).slice(0, 1);
    if (currentPrepaga.length === 0) {
    } else {
      crypto.encryptDataStorage("bajapacpre", "NO");
      crypto.encryptDataStorage("currentpacpreid", currentPrepaga[0].id);
      crypto.encryptDataStorage(
        "currentplanid",
        currentPrepaga[0].planes_prepaga_id
      );
      crypto.encryptDataStorage(
        "currentprepagacodigo",
        currentPrepaga[0].codigo
      );
      crypto.encryptDataStorage(
        "currentprepagarazon",
        currentPrepaga[0].denominacion_comercial
      );
      crypto.encryptDataStorage(
        "currentcredencial",
        currentPrepaga[0].numero_credencial
      );
      crypto.encryptDataStorage("currenttasaiva", currentPrepaga[0].tasa_iva);
      setCurrentPrepagaCodigo(currentPrepaga[0].codigo);
      setCurrentPrepagaRazon(currentPrepaga[0].denominacion_comercial);
      setCurrentTasaIva(currentPrepaga[0].tasa_iva);
      setCurrentPlanId(currentPrepaga[0].planes_prepaga_id);
      if (currentPrepaga[0].fecha_baja !== null) {
        crypto.encryptDataStorage("bajapacpre", "SI");
      }
    }
  }

  $("#prepagas_list li").on("click", function () {
    if (currentPrepagaCodigo !== null && this.id !== currentPrepagaCodigo) {
      changePrepaga(this.id, allPrepagas);
      //
    }
  });

  function onChangeSearchPaciente(e) {
    const searchPaciente = e.target.value;
    setSearchPaciente(searchPaciente);
    if (modalPacientesOpen) {
      FiltrarPacientes(searchPaciente);
    }
  }
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      //buscarPaciente(searchPaciente, false, null);
      BuscarPaciente(searchPaciente, false, null);
    }
  }
  function busqueda() {
    BuscarPaciente(searchPaciente, false, null);
  }

  function FiltrarPacientes(filtro) {
    if (filtro.length > 0) {
      filterPacientes = allPacientes.filter(
        (p) =>
          p.apellido.toLowerCase().indexOf(filtro.toLowerCase()) >= 0 ||
          p.nombres.toLowerCase().indexOf(filtro.toLowerCase()) >= 0 ||
          (p.telefono !== null && p.telefono.indexOf(filtro) >= 0) ||
          (p.telefono2 !== null && p.telefono2.indexOf(filtro) >= 0) ||
          (p.celular !== null && p.celular.indexOf(filtro) >= 0)
      );
      if (filterPacientes.length <= 0) {
        filterPacientes = allPacientes;
      }
    } else {
      filterPacientes = allPacientes;
    }
    //crypto.encryptDataStorage("hctratamientos", filterData);
    let tempData = [...filterPacientes];
    setFilterPacientes(tempData);
    if (filterPacientes.length > 0) {
    }
  }

  function resultadoBusquedaAvanzada(result) {
    if (result !== null && result.length > 0) {
      limpiarPaciente();
      if (result.length === 1) {
        BuscarPaciente(result[0].id, true);
      } else if (result.length > 500) {
        setLoading(false);
        setModalMessage(
          "Demasiados resultados(" +
            result.length.toString() +
            ") refine la búsqueda."
        );
        setModalInformationOpen(true);
      } else {
        setLoading(false);
        setAllPacientes(result);
        setFilterPacientes(result);
        setModalPacientesOpen(true);
        setSearchPaciente("");
      }
    }
  }

  function cleanpaciente() {
    setSearchPaciente("");
    limpiarPaciente();
  }
  function newpaciente() {
    history.push({
      pathname: "/insertupdatepaciente",
      cleanMethod: cleanpaciente,
    });
  }
  async function BuscarPaciente(Busqueda, IsId, ChangeCodigo) {
    if (Busqueda === null || Busqueda.length === 0) {
      return;
    }
    limpiarPaciente();
    if (!IsId && Busqueda.length < 3) {
      setModalMessage("Ingrese al menos 3 caracteres.");
      setModalInformationOpen(true);
      return;
    }
    let params = "busqueda=" + Busqueda;
    if (IsId) {
      params = params + "&is_id=SI";
    }
    setLoading(true);
    let result = await ModeloGetUrlJson(
      "paciente",
      null,
      "busqueda-paciente",
      params
    );

    if (result.error === false) {
      if (result.listado == null && result.paciente !== null) {
        setSearchPaciente("");

        mostrarPaciente(result, ChangeCodigo);
      } else if (result.listado.length > 1) {
        if (result.listado.length > 500) {
          setLoading(false);
          setModalMessage(
            "Demasiados resultados(" +
              result.listado.length.toString() +
              ") refine la búsqueda."
          );
          setModalInformationOpen(true);
        } else {
          setLoading(false);
          setAllPacientes(result.listado);
          setFilterPacientes(result.listado);
          setModalPacientesOpen(true);
          setSearchPaciente("");
        }
      } else if (result.listado.length === 0) {
        setLoading(false);
        if (!isNaN(Busqueda)) {
          buscarPadron(Busqueda, false);
        } else {
          setModalMessage("No se encontraron registros.");
          setModalInformationOpen(true);
        }
      }
    } else {
      setLoading(false);
      setModalMessage(
        result.mensaje != null
          ? result.mensaje
          : result.errormessage != null
          ? result.errormessage
          : "Error desconocido."
      );
      setModalWarningOpen(true);
    }
    return;
  }

  async function buscarPadron(Busqueda, IsId) {
    if (Busqueda.length === 0) {
      return;
    }
    let data = {};
    if (IsId) {
      data = {
        id: Busqueda,
        tipo_busqueda: "and",
        comparacion_busqueda: "exacto",
      };
    } else if (isNaN(Busqueda)) {
      // No es un numero busca por Apellido y Nombres
      const arrName = Busqueda.split(",");
      data = {
        apellido: arrName[0],
        nombres: arrName[1],
        tipo_busqueda: "and",
        comparacion_busqueda: "like",
      };
    } else {
      data = {
        nro_doc: Busqueda,
        tipo_busqueda: "and",
        comparacion_busqueda: "exacto",
      };
    }
    setLoading(true);
    let result = await ModeloSearch("padron", data);

    setLoading(false);
    if (result.error === false) {
      if (result.listado.length === 1) {
        setCurrentPadron(result.listado[0]);
        setDisplayConfirmationModal(true);
        setModalMessage(
          "Desea transferir el afiliado " +
            result.listado[0].apellido +
            " " +
            result.listado[0].nombres +
            " desde padrones ?"
        );
      } else if (result.listado.length > 1) {
        if (result.listado.length > 500) {
          setModalMessage(
            "Demasiados resultados(" +
              result.listado.length.toString() +
              ") refine la búsqueda."
          );
          setModalInformationOpen(true);
        } else {
          // let padronOk = Linqer.Enumerable.from(result.listado).where(g => g.date_baja === null).orderByDescending(g => g.ult_mov).toArray();
          let padronOk = result.listado
            .filter((g) => g.date_baja === null)
            .sort((a, b) => (a.ult_mov > b.ult_mov ? -1 : 1));
          if (padronOk.length === 1) {
            setCurrentPadron(padronOk[0]);
            setDisplayConfirmationModal(true);
            setModalMessage(
              "Desea transferir el afiliado " +
                padronOk[0].apellido +
                " " +
                padronOk[0].nombres +
                " desde padrones ?"
            );
          }

          //               setAllPacientes(result.listado);
          //             setModalPacientesOpen(true);
        }
      } else if (result.listado.length === 0) {
        setModalMessage("No se encontraron registros.");
        setModalInformationOpen(true);
      }
    } else {
      setModalMessage(
        result.mensaje != null
          ? result.mensaje
          : result.errormessage != null
          ? result.errormessage
          : "Error desconocido."
      );
      setModalWarningOpen(true);
    }
    return;
  }

  const hideNotaModal = () => {
    setModalNotaOpen(false);
  };

  function editarPaciente() {
    history.push({
      pathname: "/insertupdatepaciente",
      currentPaciente,
      cleanMethod: cleanpaciente,
    });
  }
  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"VISUALIZAR CPACIENTES"}
    >
      <div className="w-full">
        <div className="flex justify-center mt-4 ml-2 mr-3 ">
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className="flex justify-center ">
                <CustomizedSearch
                  value={searchPaciente}
                  onchange={onChangeSearchPaciente}
                  handlebusqueda={busqueda}
                  placeholder="Documento o Apellido, Nombres..."
                  advancedsearch={hancleClickBusquedaAvanzada}
                  width={{ xs: 400, md: 500 }}
                  starticon={
                    hasPermission(userRoles, "ALTA CPACIENTES") ? (
                      <AddRoundedIcon
                        sx={{ fontSize: 48, color: "icongreen" }}
                      />
                    ) : null
                  }
                  handlestart={
                    hasPermission(userRoles, "ALTA CPACIENTES")
                      ? newpaciente
                      : null
                  }
                />
                <PacientesBusquedaAvanzada
                  open={openBa}
                  anchorEl={anchorElBa}
                  id={idBa}
                  handleClose={handleCloseBa}
                  setResultados={resultadoBusquedaAvanzada}
                  setLoadingPacientes={setLoading}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="flex justify-center  ">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height: "56px",
                    width: { xs: 340, md: 500 },
                  }}
                >
                  <Autocomplete
                    id="historial"
                    options={
                      crypto.decryptLocalStorage("historialpacientes") !== null
                        ? crypto.decryptLocalStorage("historialpacientes")
                        : []
                    }
                    value={selectedHistorial}
                    autoHighlight
                    size="small"
                    onChange={onHistorialChange}
                    getOptionLabel={(option) => option.nombre}
                    sx={{
                      mt: -1,
                      width: { xs: 340, md: 500 },
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          "& > img": {
                            mr: 2,
                            flexShrink: 0,
                            textTransform: "capitalize",
                          },
                        }}
                        {...props}
                      >
                        <Table size="small" aria-label="items">
                          <TableBody>
                            <TableRow key={option.id}>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                <Box sx={{ display: "flex" }}>
                                  {option.foto !== null && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        mr: 2,
                                      }}
                                    >
                                      <Avatar
                                        alt="Foto"
                                        src={
                                          "data:image/jpg;base64," + option.foto
                                        }
                                        sx={{
                                          width: 48,
                                          height: 48,
                                          justifyContent: "center",
                                        }}
                                      />
                                    </Box>
                                  )}
                                  {option.foto === null && (
                                    <div>
                                      {option.sexo === "M" && (
                                        <FaceIcon
                                          sx={{
                                            color: "primary.main",
                                            fontSize: 44,
                                            mr: 2,
                                          }}
                                        />
                                      )}
                                      {option.sexo === "F" && (
                                        <Face2Icon
                                          sx={{
                                            color: "pink",
                                            fontSize: 44,
                                            mr: 2,
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                  <p className="mt-3">
                                    {option.nombre.toLowerCase()}
                                  </p>
                                </Box>
                              </TableCell>
                              <TableCell align="right">
                                {option.nro_documento}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Historial de pacientes"
                        variant="standard"
                        inputProps={{
                          ...params.inputProps,
                          style: { justifyContent: "center" },
                        }}
                      />
                    )}
                  />
                </Paper>
              </div>
            </Grid>
          </Grid>
        </div>

        {(currentPaciente === null || allDatosPaciente === null) && (
          <div className="flex justify-center mt-2">
            {loading && <FacebookCircularProgress />}
          </div>
        )}
        {modalPacientesOpen && filterPacientes.length > 0 && (
          <MultipleResultsTable
            setShowModal={setModalPacientesOpen}
            pallData={CapSentenceArray(filterPacientes, [
              "apellido",
              "nombres",
            ])}
            objFields={objFields}
            objHeaders={objHeaders}
            objDates={objDates}
            selectedMethod={BuscarPaciente}
            highlightText={searchPaciente}
          />
        )}
      </div>
      <div>
        {allDatosPaciente !== null && allDatosPaciente === "Completo" && (
          <>
            <div className="flex justify-center w-full py-1 mt-3 px-8 ">
              <Grid
                container
                rowSpacing={{ xs: 2, md: 4 }}
                columnSpacing={{ xs: 0, md: 6 }}
              >
                <Grid item xs={12} lg={3}>
                  {currentPaciente !== null && (
                    <PacientesDatosPersonales
                      currentPaciente={currentPaciente}
                      metodoeditar={editarPaciente}
                      fotopaciente={fotoPaciente}
                      fotopacienteid={fotoPacienteId}
                      saldo={saldoFacturas + saldoPresupuestos}
                      buscarCtaCte={buscarCtaCtePaciente}
                      metodorefresh={() =>
                        BuscarPaciente(
                          crypto.decryptDataStorage("currentpatient"),
                          true,
                          crypto.decryptDataStorage("currentprepagacodigo")
                        )
                      }
                      metodolimpiar={limpiarPaciente}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Box
                    sx={{
                      display: { xs: "none", lg: "flex" },
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 0,
                      marginBottom: 0.5,
                    }}
                  >
                    <PacienteMenuWide />
                  </Box>
                  <Grid
                    container
                    maxWidth="xl"
                    rowSpacing={{ xs: 2, md: 4 }}
                    columnSpacing={{ xs: 0, md: 6 }}
                  >
                    <Grid item xs={12} lg={6}>
                      <div className="-ml-2  alert bg-neutral-100 capitalize">
                        {currentPaciente !== null && (
                          <PacientesEmpresas
                            allPrepagas={allPrepagas}
                            currentPrepagaCodigo={currentPrepagaCodigo}
                            onclick={changePrepaga}
                            setAllPrepagas={setAllPrepagas}
                          />
                        )}
                      </div>
                      <div className="-ml-2 mt-4 border-sky-600 rounded-lg alert bg-neutral-100 ">
                        <PacientesNotas
                          allNotas={filterObservaciones}
                          setAllNotas={setFilterObservaciones}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div className=" alert bg-neutral-100 capitalize">
                        {currentPaciente !== null && (
                          <PacientesPendientes
                            allPendientes={turnosPendientes}
                            setallPendientes={setTurnosPendientes}
                            allAnteriores={citasAnteriores}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </>
        )}
        <ModalInformation
          showModal={modalInformationOpen && modalMessage !== null}
          hideModal={hideInformationModal}
          message={modalMessage}
        />
        <ModalInformation
          showModal={modalWarningOpen && modalMessage !== null}
          hideModal={hideInformationModal}
          message={modalMessage}
          tipo="warning"
        />

        <ModalError
          showModal={modalErrorOpen && modalMessage !== null}
          hideModal={hideErrorModal}
          message={modalMessage}
        />
        <ModalConfirmation
          showModal={displayConfirmationModal}
          confirmModal={ConfirmationModalOk}
          hideModal={hideConfirmationModal}
          message={modalMessage}
        />
        <ModalNota
          showModal={modalNotaOpen && currentPaciente !== null}
          hideModal={hideNotaModal}
          paciente={currentPaciente}
        />
      </div>
    </ProtectedRoute>
  );
};

export default Pacientes;
