import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import crypto from "./Crypto";
import { useHistory } from "react-router-dom";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SettingsIcon from "@mui/icons-material/Settings";

function MenuMoreOptions({ openoptions, anchoreloptions, handleclose }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const history = useHistory();

  function handleClick(option) {
    if (option === "Reportes") {
      history.push({
        pathname: "/reportes",
      });
    } else if (option === "Procesos") {
      history.push({
        pathname: "/procesos",
      });
    }
    handleclose();
  }

  return (
    <>
      <Menu
        anchorEl={anchoreloptions}
        id="options-menu"
        open={openoptions}
        onClose={handleclose}
        onClick={handleclose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
          style: {
            width: 250,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleClick("Reportes")}>
          <ListItemIcon>
            <SummarizeIcon
              sx={{ fontSize: 32, color: "primary.main", ml: -1 }}
            />
          </ListItemIcon>
          Reportes
        </MenuItem>
        <MenuItem onClick={() => handleClick("Procesos")}>
          <ListItemIcon>
            <SettingsIcon
              sx={{ fontSize: 32, color: "primary.main", ml: -1 }}
            />
          </ListItemIcon>
          Procesos
        </MenuItem>
      </Menu>
    </>
  );
}
export default MenuMoreOptions;
