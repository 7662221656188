/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from "react";
import { ModeloGetUrlJson } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import crypto from "../Crypto";
import FacebookCircularProgress from "../FacebookCircularProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ModalCarteleria({
  row,
  showModal,
  hideModal,
  centroId,
}) {
  let [selectedClinica, setSelectedClinica] = useState(centroId);
  const [loading, setLoading] = React.useState(false);
  const [allData, setAllData] = React.useState(null);

  useEffect(() => {
    buscarCarteleria();
  }, []);

  const handleClose = () => {
    hideModal();
  };

  async function buscarCarteleria() {
    setAllData(null);
    if (selectedClinica === null) {
      return;
    }
    setLoading(true);

    let result = await ModeloGetUrlJson(
      "carteleria",
      selectedClinica,
      null,
      null
    );
    setLoading(false);

    if (result.length > 0) {
      setAllData(result);
    } else {
    }
  }

  function BootstrapDialogTitle(props) {
    const { children, onClose, row, ...other } = props;

    return (
      <DialogTitle
        sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
        {...other}
      >
        <i class="fa-solid fa-info text-white fa-xl p-4 m-2 "></i>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <TableRow
          sx={{ height: "3rem", borderBottom: 3, borderColor: "white" }}
        >
          <TableCell sx={{ bgcolor: "#0054A6", color: "white" }}>
            {row.paciente}
          </TableCell>
          <TableCell align="center" sx={{ bgcolor: "#2367C3", color: "white" }}>
            {row.piso}
          </TableCell>
          <TableCell align="center" sx={{ bgcolor: "#4C7EC3", color: "white" }}>
            {row.consultorio}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
          >
            Últimos llamados
          </BootstrapDialogTitle>
          <IconButton
            aria-label="close"
            onClick={hideModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent className="content" dividers>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                // marginTop: 2,
              }}
            >
              {loading && (
                <Box
                  sx={{
                    marginLeft: 4,
                  }}
                >
                  <FacebookCircularProgress />
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 4,
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 100 }}
                  aria-label="customized table"
                  size="small"
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "3rem",
                        borderBottom: 3,
                        borderColor: "white",
                      }}
                    >
                      <TableCell sx={{ bgcolor: "#3A3D3A", color: "white" }}>
                        PACIENTE
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ bgcolor: "#4D514E", color: "white" }}
                      >
                        PISO
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ bgcolor: "#6E726E", color: "white" }}
                      >
                        CONS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading &&
                      allData?.length > 0 &&
                      allData
                        .sort((a, b) =>
                          a.ultima_fecha > b.ultima_fecha ? -1 : 1
                        )
                        .map((row) => <Row key={row.id} row={row} />)}
                    {allData?.length === 0 && (
                      <Box sx={{ ml: 2, mt: 2, mb: 2 }}> </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </DialogContent>
          <DialogActions className="actions">
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              loadingPosition="end"
              sx={{
                backgroundColor: "primary.main",
                ":hover": {
                  bgcolor: "#475569", // theme.palette.primary.main
                  color: "white",
                },
                textTransform: "capitalize",
              }}
            >
              Salir
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Draggable>
    </div>
  );
}
