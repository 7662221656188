import React, { forwardRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import AvatarAzul from "../../assetss/img/logo_azul_reportes.png";
import qrcode from "../../assetss/img/qrcode64.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../../assetss/css/PageA4.css";
import { CapSentence } from "../utils/Utils";
import crypto from "../Crypto";
import { RenderReportPresupuestosTable } from "./RenderReportPresupuestosTable";

export const RenderReportPresupuestos = forwardRef((props, ref) => {
  const { presupuesto, items, id } = props;

  return (
    <div ref={ref} style={{ width: "100%", padding: "20px" }}>
      <style
        type="text/css"
        media="print"
        dangerouslySetInnerHTML={{
          __html: `
                @page {
                  size: Legal ;
                  margin: 20mm;
                }
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                  }
                  .page-break {
                    page-break-before: always;
                     margin-top: 50mm; 
                     margin-bottom: 2cm; 
                  }
                  .content {
                    margin-top: 3cm; 
                    margin-bottom: 1cm;
                  }
                }
              `,
        }}
      />
      {id !== null && id > 0 && (
        <>
          <div className="header">
            <Box
              display="flex"
              sx={{
                border: 1,
                margin: 4,
                marginLeft: -1,
                py: 1,
                alignItems: "center",
                gap: 10,
              }}
            >
              <img src={AvatarAzul} />
              <Typography
                variant="h5"
                sx={{
                  marginTop: -1,
                }}
              >
                Presupuesto
                {presupuesto.estado === "APROBADO" ? " Oficial" : " Provisorio"}
              </Typography>
              <img src={qrcode} />
            </Box>
            <Box
              display="flex"
              sx={{
                border: 1,
                borderTop: 0,
                flexDirection: "column",
                marginRight: 4,
                marginTop: -4,
                marginLeft: -1,
                marginBottom: 2,
                px: 2,
              }}
            >
              <Typography variant="caption">
                Clínica Ayacucho: Ayacucho 1314 CABA Tel.: (54-11) 5217-4400
              </Typography>
              <Typography variant="caption">
                Clínica Belgrano: Ciudad de la Paz 1808 - 1° CABA Tel.: (54-11)
                4785-1833
              </Typography>
            </Box>
          </div>

          <Box
            display="flex"
            sx={{
              flexDirection: "column",
              borderBottom: 1,
              paddingX: 2,
              paddingY: 2,
              marginTop: 20,
              marginLeft: 1,
              marginRight: 1,
            }}
          >
            <Grid container>
              <Grid item xs={8}>
                <Box
                  display="flex"
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="subtitle2">
                    Número: {presupuesto.id}
                  </Typography>
                  <Typography variant="subtitle2">
                    Paciente:{" "}
                    {CapSentence(
                      crypto.decryptDataStorage("currentpatientname")
                    )}
                  </Typography>
                  <Typography variant="subtitle2">
                    Empresa:{" "}
                    {CapSentence(presupuesto.prepaga_denominacion_comercial) +
                      " (" +
                      presupuesto.prepaga_codigo +
                      ")"}
                  </Typography>
                  <Typography variant="subtitle2">
                    Odontólogo: {CapSentence(presupuesto.odontologo)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  Emitido: {presupuesto.fecha_em_arg}
                </Typography>
                {presupuesto.estado === "APROBADO" && (
                  <Typography variant="subtitle2">
                    Aprobado: {presupuesto.fecha_ap_arg}
                  </Typography>
                )}
                <Typography variant="subtitle2">
                  Documento:{" "}
                  {crypto.decryptDataStorage("dataPaciente").nro_documento +
                    " " +
                    crypto.decryptDataStorage("dataPaciente").tipo_documento}
                </Typography>
                <Typography variant="subtitle2">
                  Clínica: {presupuesto.clinica}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {presupuesto.estado === "APROBADO" && (
            <Box
              display="flex"
              sx={{
                flexDirection: "column",
                paddingY: 2,
                paddingX: 2,
                marginLeft: 4,
                marginRight: 4,
              }}
            >
              <Typography
                sx={{
                  fontSize: 10,
                  fontFamily: "Open Sans",
                }}
              >
                Por la presente dejo constancia que el Sr. Odontólogo abajo
                firmante, me ha informado en términos claros y comprensibles
                para mí, el tipo de tratamiento odontológico que, en base al
                diagnóstico que se me ha realizado, debe efectuarse, por lo que
                reconozco haber comprendido debidamente la tarea propuesta. Me
                ha informado además, también en términos claros y comprensibles
                para mí, sobre los posibles riesgos, y complicaciones. También
                que me ha informado del mismo modo, sobre las distintas
                alternativas de tratamiento, y me ha explicado que en algunos
                casos las prótesis dentales fracasan a pesar de lo correcto de
                la técnica empleada, lo que reconozco comprender y aceptar.Me ha
                informado finalmente el "precio" del tratamiento propuesto y su
                forma de pago. Por todo ello, y considerándome suficientemente
                informado al respecto, solicíto y autorizo se me realice el
                tratamiento odontológico que a continuación se detalla:
              </Typography>
            </Box>
          )}
          <Box sx={{ marginTop: 2 }}>
            <RenderReportPresupuestosTable
              presupuesto={presupuesto}
              allItems={items}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingY: 2,
              paddingX: 2,
              marginTop: 10,
              marginLeft: 8,
              marginRight: 4,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
              }}
            >
              <Grid item xs={3.5}>
                <Typography sx={{ marginLeft: 3, marginTop: -1 }}>
                  V. Administración
                </Typography>
              </Grid>
              <Grid item xs={3.5}>
                <Typography sx={{ marginLeft: 2, marginTop: -1 }}>
                  Conforme del paciente
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Box sx={{ marginLeft: 4 }}>
                  <Typography sx={{ marginLeft: 0, marginTop: -1 }}>
                    Firma y sello del odontólogo
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            sx={{
              flexDirection: "column",
              paddingY: 2,
              paddingX: 2,
              marginLeft: 4,
              marginRight: 4,
            }}
          >
            <Typography
              sx={{
                fontSize: 10,
                fontFamily: "Open Sans",
                marginBottom: 1,
              }}
            >
              <b>NOTA:</b> el siguiente presupuesto tiene una validez de
              {presupuesto.estado === "APROBADO"
                ? " 30 (treinta) "
                : " 15 (quince) "}
              dias a partir de la fecha de su emision.
            </Typography>
            <Typography
              sx={{
                fontSize: 10,
                fontFamily: "Open Sans",
              }}
            >
              <b>IMPORTANTE:</b> los pagos deben ser efectuados en las fechas
              pactadas o contra colocación de la prótesis / realización de la
              práctica.Todo pago parcial o fuera de término será considerado
              "pago a cuenta" y se imputará en primer término a cancelar los
              intereses por mora y el remanente, si existiera, "a cuenta de
              capital" de la prótesis / práctica de mas antigua mora.La mora en
              los pagos hará devengar en forma automática un interés del tres
              por ciento (3%) mensual durante todo el tiempo que dure la
              misma.La mora facultará a CONSULMED a suspender el tratamiento
              hasta la regularización de los pagos.-
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
});
