import React from "react";
import { Autocomplete, TextField, Box, Avatar } from "@mui/material";
import { useFormikContext } from "formik"; // Import useFormikContext to access Formik methods

const FormikCustomAutoComplete = ({
  id,
  options,
  value,
  label,
  getOptionLabel,
  isOptionEqualToValue,
  renderOption, // Optional custom render function
  onChange, // Optional custom onChange handler
  setNullOnClear = true, // Default behavior is to set the field to null on clear
  inputRef = null,
  nextRef = null,
  keyDown = null,
  isClinic = false,
}) => {
  const { setFieldValue, touched, errors } = useFormikContext(); // Access Formik context

  const handleChange = (event, newValue) => {
    if (onChange) {
      // If a custom onChange function is provided, use it
      onChange(event, newValue);
    } else {
      // Default behavior: update the Formik field using Formik's setFieldValue
      if (newValue === null && setNullOnClear) {
        setFieldValue(id, null);
      } else {
        setFieldValue(id, newValue);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (keyDown) {
      keyDown(event);
    } else if (event.key === "Enter" && nextRef) {
      event.preventDefault(); // Prevent form submission
      if (nextRef.current) {
        nextRef.current.focus();
      }
    }
  };

  const renderClinicOption = (props, option) => (
    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          bgcolor: option.color,
          marginBottom: 0.5,
          marginRight: 1,
          border: 1,
          borderColor: "black",
        }}
      >
        {option.identificador}
      </Avatar>
      {option.razonsocial}
    </Box>
  );

  return (
    <Autocomplete
      id={id}
      options={options}
      value={value}
      size="small"
      autoHighlight
      onChange={handleChange} // Use the default or provided handleChange
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onKeyDown={handleKeyDown}
          error={touched[id] && Boolean(errors[id])} // Use touched and errors from Formik context
          helperText={touched[id] && errors[id]} // Display error message
          FormHelperTextProps={{
            sx: { minHeight: "1.5em" }, // Reservar espacio para el helper text
          }}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            style: { justifyContent: "center" },
          }}
          inputRef={inputRef}
        />
      )}
      renderOption={isClinic ? renderClinicOption : renderOption} // Use custom render if provided
    />
  );
};

export default FormikCustomAutoComplete;
