import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  TextField,
  Box,
  Button,
  Modal,
  Typography,
  Container,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import ProtectedRoute from "./ProtectedRoute";
import crypto from "./Crypto";
import { useHistory } from "react-router-dom";
import FacebookCircularProgress from "./FacebookCircularProgress";
import RenderCustomTable from "./RenderCustomTable";
import ModalNomencladorPreciosLista from "./modals/ModalNomencladorPreciosLista";
import DeleteModel from "./DeleteModel";

const useStyles = makeStyles({
  smallRow: {
    height: "40px !important", // Altura de la fila
    padding: "0 10px !important",
  },
  headerCell: {
    backgroundColor: "#475569",
    color: "white !important",
    height: "40px !important", // Altura de la fila
    padding: "10px 10px !important",
  },
  iconButton: {
    padding: "5px !important", // Reduce padding for a smaller button
  },
});

const ListasPreciosFormNomencladores = ({
  allNomencladores,
  onSelectedRowsChange,
  listaId,
}) => {
  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [originalPrice, setOriginalPrice] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openObsModal, setOpenObsModal] = useState(false);
  const [modalObsData, setModalObsData] = useState("");
  const [modalObsIndex, setModalObsIndex] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchLista, setSearchLista] = useState("");
  const [modalListasOpen, setModalListasOpen] = useState(false);
  const [allLista, setAllLista] = useState(allNomencladores);
  let [filterLista, setFilterLista] = useState(allNomencladores);
  let [filterListas, setFilterListas] = useState(null);
  const [editarPrecio, setEditarPrecio] = useState(false);
  const [insertarPrecio, setInsertarPrecio] = useState(false);
  const [deletePrecioId, setDeletePrecioId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const history = useHistory();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const classes = useStyles();

  const sortedData = useMemo(() => {
    if (filterLista !== null && filterLista.length > 0) {
      return [...filterLista].sort((a, b) => (a.codigo > b.codigo ? 1 : -1));
    }
    return [];
  }, [filterLista]); // Se recalcula solo si `filterLista` cambia

  const initialNomencladores = allNomencladores;
  const validationSchema = Yup.object().shape({
    nomencladores: Yup.array().of(
      Yup.object().shape({
        precio: Yup.number()
          .required("Campo obligatorio")
          .min(0, "No puede ser negativo"),
      })
    ),
  });
  const [listaOk, setListaOk] = useState(false);
  const objHeaders = ["Ver", "Código", "Observaciones"];
  const objFields = ["codigo", "observaciones"];
  const objDates = [];

  const formik = useFormik({
    initialValues: {
      nomencladores: initialNomencladores || [],
    },
    validationSchema,
    onSubmit: (values) => {
      setEditIndex(null);
    },
  });

  const columns = [
    { field: "codigo_nomenclador", headerName: "Código", isCodigoPrec: true },
    {
      field: "descripcion_nomenclador",
      headerName: "Descripción",
      capitalize: true,
    },
    { field: "precio", headerName: "Precio", isPrecio: true },
    { field: "grupo_dental", headerName: "Piezas", capitalize: true },
    { field: "observaciones", headerName: "Notas", lowercase: true },
    { field: "requiere_autorizacion", headerName: "Aut" },
    { field: "requiere_odontograma", headerName: "Odo" },
    { field: "requiere_planilla_aparte", headerName: "Pla" },
  ];

  const actions = [
    {
      icon: EditIcon,
      handle: (row) => handleEditarPrecio(row),
      sx: { color: "primary.main" },
      requiredPermission: "MODIFICACION CLISTASPRECIOS",
    },
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
      requiredPermission: "BAJA CLISTASPRECIOS",
    },
  ];

  const iconColumns = {
    requiere_autorizacion: (props) =>
      props?.value === 1 ? (
        <CheckIcon
          fontSize="medium"
          sx={{
            color: "avatar",
          }}
        />
      ) : (
        ""
      ),
    requiere_odontograma: (props) =>
      props?.value === 1 ? (
        <CheckIcon
          fontSize="medium"
          sx={{
            color: "avatar",
          }}
        />
      ) : (
        ""
      ),
    requiere_planilla_aparte: (props) =>
      props?.value === 1 ? (
        <CheckIcon
          fontSize="medium"
          sx={{
            color: "avatar",
          }}
        />
      ) : (
        ""
      ),
  };

  const handleDelete = (row) => {
    setDeletePrecioId(row.id);
  };

  const handleEditarPrecio = (row) => {
    setSelectedRow(row);
    setEditarPrecio(true);
  };

  const handleInsertarPrecio = () => {
    setInsertarPrecio(true);
  };

  const handleCloseInsertarEditarPrecio = () => {
    setEditarPrecio(false);
    setInsertarPrecio(false);
  };

  function onChangeSearchLista(e) {
    setSearchLista(e.target.value);
    if (modalListasOpen) {
      FiltrarListas(searchLista);
    }
  }

  const handleDeleteRow = (id) => {
    setFilterLista((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  function FiltrarListas(filtro) {
    if (filtro.length > 0) {
      filterLista = allLista.filter(
        (p) => p.codigo.toLowerCase().indexOf(filtro.toLowerCase()) >= 0
      );
      if (filterLista.length <= 0) {
        filterLista = allLista;
      }
    } else {
      filterLista = allLista;
    }
    //crypto.encryptDataStorage("hctratamientos", filterData);
    let tempData = [...filterListas];
    setFilterLista(tempData);
  }

  const clearFilter = () => {
    setFilterText("");
    setFilteredData([]);
  };

  const handleModalObsOpen = (index) => {
    setModalObsData(formik.values.nomencladores[index].observaciones);
    setModalObsIndex(index);
    setOpenObsModal(true);
  };

  const handleSaveObs = () => {
    const updatedNomencladores = [...formik.values.nomencladores];
    updatedNomencladores[modalObsIndex].observaciones = modalObsData;

    formik.setValues({
      ...formik.values,
      nomencladores: updatedNomencladores,
    });
    setRefresh(!refresh);
    handleModalObsClose();
  };

  const handleModalObsClose = () => {
    setOpenObsModal(false);
    setModalObsData("");
    setModalObsIndex(null);
  };

  const handleEditPrice = (index) => {
    if (editIndex !== null && editIndex !== index) {
      handleCancelEditPrice(editIndex); // Restaurar el precio si cambias sin guardar
    }
    setEditIndex(index);
    setOriginalPrice(formik.values.nomencladores[index].precio); // Guardamos el valor original
  };

  const handleCancelEditPrice = (index) => {
    formik.setFieldValue(`nomencladores[${index}].precio`, originalPrice); // Restauramos el valor original
    setEditIndex(null);
  };

  const savePriceToApi = async (index) => {
    const nomenclador = formik.values.nomencladores[index];
    try {
      const response = await axios.put(
        `/api/nomenclador/${nomenclador.nomenclador_id}`,
        {
          precio: nomenclador.precio,
        }
      );
      if (response.status === 200) {
        setSnackbarMessage("Precio actualizado con éxito");
        setSnackbarSeverity("success");
      } else {
        throw new Error("Error en la actualización");
      }
    } catch (error) {
      setSnackbarMessage(
        "Error al actualizar el precio. Restaurando valor original."
      );
      setSnackbarSeverity("error");

      // Restaurar el valor original en caso de error
      formik.setFieldValue(`nomencladores[${index}].precio`, originalPrice);
    }
    setSnackbarOpen(true);
    setEditIndex(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const dataToRender = filterText ? filteredData : formik.values.nomencladores;

  function newLista() {
    history.push({
      pathname: "/insertupdatecliente",
    });
  }

  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"ALTA CLISTASPRECIOSNOMENCLADOR"}
    >
      <div className="w-auto mt-4 mx-4">
        {listaOk === false && (
          <div className="flex justify-center mt-2">
            {loading && <FacebookCircularProgress />}
          </div>
        )}
        {allNomencladores?.length > 0 && (
          <div>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Container maxWidth="xl">
                <RenderCustomTable
                  data={filterLista} // Cambio Fabio era sortedData
                  columns={columns}
                  iconColumns={iconColumns}
                  paddingLeft={0}
                  paddingRight={0}
                  showSearch={true}
                  actions={actions}
                  selectable={true}
                  parentIdColumn="id"
                  onSelectedRowsChange={onSelectedRowsChange}
                  handleNew={handleInsertarPrecio}
                />
              </Container>
            </Box>
          </div>
        )}
        {/* Modal para mostrar observaciones completas */}
        <Modal open={openObsModal} onClose={handleModalObsClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Observaciones completas
            </Typography>

            {/* TextField multiline para mostrar la información */}
            <TextField
              fullWidth
              multiline
              rows={10}
              value={modalObsData}
              onChange={(e) => setModalObsData(e.target.value)}
            />

            {/* Botones de acción */}
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Button
                sx={{ width: 100, marginRight: 2 }}
                onClick={handleModalObsClose}
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button
                sx={{ width: 100, marginRight: 0 }}
                onClick={handleSaveObs}
                variant="contained"
              >
                Grabar
              </Button>
            </Box>
          </Box>
        </Modal>
        {editarPrecio && (
          <ModalNomencladorPreciosLista
            open={editarPrecio}
            handleClose={handleCloseInsertarEditarPrecio}
            row={selectedRow}
            listaId={listaId}
            setRows={setFilterLista}
          />
        )}
        {insertarPrecio && (
          <ModalNomencladorPreciosLista
            open={insertarPrecio}
            handleClose={handleCloseInsertarEditarPrecio}
            row={null}
            listaId={listaId}
            setRows={setFilterLista}
          />
        )}
        {deletePrecioId !== null && (
          <DeleteModel
            model={"listas-precios-nomenclador"}
            modelid={deletePrecioId}
            okmethod={() => handleDeleteRow(deletePrecioId)}
            nullmethod={setDeletePrecioId}
          />
        )}
        ;
      </div>
    </ProtectedRoute>
  );
};

export default ListasPreciosFormNomencladores;
