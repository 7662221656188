import React, { useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import loadingGif from "../../assetss/img/loading_64.gif";
import SubirArchivo from "../SubirArchivo";
import PacienteMenu from "./PacienteMenu";
import { CapSentence, grabarFoto, hasPermission } from "../utils/Utils";
import BorrarImagen from "../BorrarImagen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, Tooltip, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import ListItemIcon from "@mui/material/ListItemIcon";
import RefreshIcon from "@mui/icons-material/Refresh";
import ModalCapturePhoto from "../modals/ModalCapturePhoto";
import ModalInformation from "../modals/ModalInformation";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import FaceIcon from "@mui/icons-material/Face";
import Face2Icon from "@mui/icons-material/Face2";
import PaidIcon from "@mui/icons-material/Paid";
import WarningIcon from "@mui/icons-material/Warning";
import CakeIcon from "@mui/icons-material/Cake";
import { useHistory } from "react-router-dom";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { PrintButton } from "../PrintButton";
import { RenderReportFichaGuardia } from "../RenderReportFichaGuardia";
import crypto from "../Crypto";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "../DeleteModel";
import { ModeloUpdate } from "../ModeloCrud";
import StarRateIcon from "@mui/icons-material/StarRate";

export default function PacientesDatosPersonales({
  currentPaciente,
  metodoeditar,
  fotopaciente,
  fotopacienteid,
  saldo,
  buscarCtaCte,
  metodorefresh,
  metodolimpiar,
}) {
  const [loadingRx, setLoadingRx] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalCarrouselRxOpen, setModalCarrouselRxOpen] = useState(false);
  const [rxImagenes, setRxImagenes] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [mostrarHc, setMostrarHc] = useState(false);
  const [mostrarPrecios, setMostrarPrecios] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [modalCapturePhotoOpen, setModalCapturePhotoOpen] = useState(false);
  const [modalCaptureImageOpen, setModalCaptureImageOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [deletePacienteId, setDeletePacienteId] = useState(null);
  const [openSubirArchivo, setOpenSubirArchivo] = useState(false);
  const [today, setToday] = useState(new Date());
  const [key, setKey] = useState(0);
  const history = useHistory();
  const componentRef = useRef();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const okMethod = () => {
    setModalMessage("El archivo se subió correctamente.");
    setModalSuccessOpen(true);
  };

  const hideSuccessModal = () => {
    setModalMessage(null);
    setModalSuccessOpen(false);
  };

  function CapturePhoto() {
    setModalCapturePhotoOpen(true);
  }

  function CaptureImage() {
    setModalCaptureImageOpen(true);
  }

  const HideCapturePhoto = () => {
    setModalCapturePhotoOpen(false);
  };

  const HideCaptureImage = () => {
    setModalCaptureImageOpen(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEncuestas = () => {
    history.push({
      pathname: "/pacienteencuestas",
    });
  };
  const handleQuejas = () => {
    history.push({
      pathname: "/pacientequejas",
    });
  };

  const handleCtaCte = () => {
    history.push({
      pathname: "/pacientectacte",
    });
  };

  const handleBorrar = () => {};

  const handleFichaGuardia = () => {
    history.push({
      pathname: "/renderreport",
      backpage: "pacientes",
    });
  };

  async function NUploadImage() {
    SubirArchivo(currentPaciente, setLoading, alert("ok"));
  }

  async function updateVip(oldValue) {
    const Data = {
      vip: oldValue === 1 ? 0 : 1,
    };
    let result = await ModeloUpdate("paciente", currentPaciente.id, Data);
    if (result.error === false) {
      metodorefresh();
    }
  }

  async function updatePagaEn25(oldValue) {
    const Data = {
      paga_en_25: oldValue === 1 ? 0 : 1,
    };
    let result = await ModeloUpdate("paciente", currentPaciente.id, Data);
    if (result.error === false) {
      metodorefresh();
    }
  }

  async function UploadImage() {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_) => {
      let files = Array.from(input.files);
      const uploadedFile = files[0];
      const filextension = uploadedFile.name.split(".")[1];
      const atypes = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "pdf",
        "txt",
        "csv",
        "doc",
        "docx",
        "xls",
        "xlsx",
      ];
      let itype = atypes.indexOf(filextension);

      if (itype >= 0) {
        toBase64(uploadedFile)
          .then((res) => {
            let result = grabarFoto(
              res,
              currentPaciente,
              "adjuntos",
              setLoading,
              filextension
            ).then((res) => {
              if (res.error === false) {
                setModalMessage("El archivo se subió correctamente.");
                setModalSuccessOpen(true);
              } else {
                setModalMessage(res.mensaje);
                setModalErrorOpen(true);
              }
            });
          })
          .catch((err) => {});
      } else {
        setModalMessage(
          "Tipo de archivo no permitido.\nPermitidos:\nImágenes\nPdf\nTxt\nCsv\nDoc\nXls"
        );
        setModalInformationOpen(true);
      }
    };
    input.click();
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };
  const hideCarrouselRxModal = () => {
    setModalCarrouselRxOpen(false);
  };

  function mostrarhc() {
    setMostrarHc(true);
  }

  function mostrarprecios() {
    setMostrarPrecios(true);
  }

  return (
    <>
      {loadingRx && (
        <div className="ml-2 mt-2">
          <img alt="Loading" src={loadingGif} />
        </div>
      )}
      <Card sx={{ ml: -2, mr: -2, backgroundColor: "bgcards" }}>
        <CardHeader
          avatar={
            <>
              {currentPaciente.sexo !== null &&
                ["f", "m"].indexOf(currentPaciente.sexo.toLowerCase()) >= 0 && (
                  <div className="flex -mt-10 ml-0 items-end">
                    {currentPaciente.sexo.toLowerCase() === "m" && (
                      <FaceIcon sx={{ color: "primary.main", fontSize: 48 }} />
                    )}
                    {currentPaciente.sexo.toLowerCase() === "f" && (
                      <Face2Icon sx={{ color: "pink", fontSize: 48 }} />
                    )}
                  </div>
                )}
              {currentPaciente.sexo === null ||
                (["f", "m"].indexOf(currentPaciente.sexo.toLowerCase()) < 0 && (
                  <Avatar
                    sx={{ bgcolor: "avatar", mt: -4 }}
                    aria-label="recipe"
                  ></Avatar>
                ))}
            </>
          }
          action={
            <>
              <Tooltip title="Menu Pacientes">
                <IconButton
                  onClick={handleClick}
                  size="medium"
                  sx={{ mt: 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32, bgcolor: "avatar" }}>
                    <MoreVertIcon />
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {hasPermission(userRoles, "ALTA CPACIENTES") && (
                  <>
                    <MenuItem onClick={metodoeditar}>
                      <ListItemIcon>
                        <EditIcon
                          fontSize="medium"
                          sx={{ color: "primary.main", mr: 1 }}
                        />
                      </ListItemIcon>
                      Editar
                    </MenuItem>
                    <MenuItem
                      onClick={() => setDeletePacienteId(currentPaciente.id)}
                    >
                      <ListItemIcon>
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ color: "iconred", mr: 1 }}
                        />
                      </ListItemIcon>
                      Borrar
                    </MenuItem>
                  </>
                )}
                {userRoles.indexOf("DESIGNAR AFILIADOS VIP") >= 0 && (
                  <MenuItem
                    onClick={() => updatePagaEn25(currentPaciente.paga_en_25)}
                  >
                    <ListItemIcon>
                      <WarningIcon
                        fontSize="medium"
                        sx={{ color: "iconyellow", mr: 1 }}
                      />
                    </ListItemIcon>
                    {currentPaciente.paga_en_25 === 0
                      ? "Marcar Paga en 25"
                      : "Quitar Paga en 25"}
                  </MenuItem>
                )}
                {userRoles.indexOf("DESIGNAR AFILIADOS VIP") >= 0 && (
                  <MenuItem onClick={() => updateVip(currentPaciente.vip)}>
                    <ListItemIcon>
                      <WorkspacePremiumIcon
                        fontSize="medium"
                        sx={{ color: "#b91c1c", mr: 1 }}
                      />
                    </ListItemIcon>
                    {currentPaciente.vip === 0 ? "Marcar Vip" : "Quitar Vip"}
                  </MenuItem>
                )}
                {hasPermission(userRoles, "ALTA CPACIENTES") &&
                  fotopaciente === null && (
                    <MenuItem
                      onClick={CapturePhoto}
                      disabled={fotopaciente !== null}
                    >
                      <ListItemIcon>
                        <AccountCircleIcon
                          fontSize="medium"
                          sx={{ color: "primary.main", mr: 1 }}
                        />
                      </ListItemIcon>
                      Tomar Foto Perfil
                    </MenuItem>
                  )}
                {hasPermission(userRoles, "ALTA CPACIENTES") &&
                  fotopaciente !== null && (
                    <MenuItem
                      onClick={() => setDeleteImageId(fotopacienteid)}
                      disabled={fotopaciente === null}
                    >
                      <ListItemIcon>
                        <NoAccountsIcon
                          fontSize="medium"
                          sx={{ color: "primary.main", mr: 1 }}
                        />
                      </ListItemIcon>
                      Quitar Foto Perfil
                    </MenuItem>
                  )}
                <MenuItem onClick={metodorefresh}>
                  <ListItemIcon>
                    <RefreshIcon
                      fontSize="medium"
                      sx={{ color: "primary.main", mr: 1 }}
                    />
                  </ListItemIcon>
                  Actualizar
                </MenuItem>
                <MenuItem onClick={metodolimpiar}>
                  <ListItemIcon>
                    <CleaningServicesIcon
                      fontSize="medium"
                      sx={{ color: "primary.main", mr: 1 }}
                    />
                  </ListItemIcon>
                  Limpiar Pantalla
                </MenuItem>

                <Divider />
              </Menu>
            </>
          }
          title={
            <Typography
              sx={{
                fontSize: 22,
                fontFamily: "Open Sans",
                fontWeight: "medium",
              }}
            >
              {CapSentence(currentPaciente.apellido) +
                " " +
                CapSentence(currentPaciente.nombres)}
            </Typography>
          }
          subheader={
            <>
              <Typography
                sx={{
                  fontSize: 18,
                  fontFamily: "Open Sans",
                  fontWeight: "medium",
                }}
              >
                {currentPaciente.tipo_documento +
                  " " +
                  currentPaciente.nro_documento}
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontFamily: "Open Sans",
                  fontWeight: "medium",
                }}
              >
                Id: {currentPaciente.id}
              </Typography>
            </>
          }
        />
        {fotopaciente !== null && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              alt="Foto"
              src={"data:image/jpg;base64," + fotopaciente}
              sx={{
                width: 136,
                height: 136,
                justifyContent: "center",
                boxShadow: 24,
              }}
            />
          </Box>
        )}
        <Box sx={{ mt: 2, ml: 4 }}>
          <div className="flex mt-4 -ml-2 items-end">
            <i className='far fa-calendar-alt fa-xl mr-5" text-slate-500' />
            <p className="ml-2">
              {currentPaciente.fecha_nacimiento !== null &&
              currentPaciente.fecha_nacimiento.length > 0
                ? moment(currentPaciente.fecha_nacimiento).format("DD/MM/YYYY")
                : ""}
            </p>
            <p className="ml-4 mt-1 text-sky-600 text-2xl">
              {currentPaciente.fecha_nacimiento !== null &&
                currentPaciente.fecha_nacimiento.length > 0 &&
                currentPaciente.edad + " años"}
            </p>
          </div>
          <div className="flex mt-2 -ml-2 items-end">
            <i className="fas fa-mobile-alt fa-xl  text-slate-500" />
            <p className="ml-2 mr-6">
              {currentPaciente.celular !== null &&
              currentPaciente.celular.length > 0
                ? currentPaciente.celular
                : ""}
            </p>
            {currentPaciente.celular !== null &&
              currentPaciente.celular.length > 0 && (
                <a href="https://www.consulmed.com.ar">
                  <i className="fa-brands fa-whatsapp fa-2x text-emerald-500"></i>
                </a>
              )}
          </div>
          <div className="flex mt-4 -ml-2 items-end lowercase">
            <i className="fas fa-envelope fa-xl text-slate-500" />
            <p className=" text-sky-600 underline ml-2">
              {currentPaciente.email !== null &&
              currentPaciente.email.length > 0
                ? currentPaciente.email.toLowerCase()
                : ""}
            </p>
          </div>

          {currentPaciente.telefono !== null &&
            currentPaciente.telefono.length > 0 && (
              <div className="flex mt-2 -ml-2 items-end">
                <i className="fas fa-phone-alt fa-xl text-slate-500" />
                <p className="ml-2">{currentPaciente.telefono}</p>
              </div>
            )}
          {currentPaciente.telefono2 !== null &&
            currentPaciente.telefono2.length > 0 && (
              <div className="flex mt-2 -ml-2 items-end">
                <i className="fas fa-phone-alt fa-xl  text-slate-500" />
                <p className="ml-2">{currentPaciente.telefono2}</p>
              </div>
            )}
          {currentPaciente.domicilio !== null &&
            currentPaciente.domicilio.length > 0 && (
              <div className="flex mt-2 -ml-2 items-end">
                <i className="fas fa-house-user fa-xl  text-slate-500" />
                <p className="ml-2">{currentPaciente.domicilio}</p>
              </div>
            )}
          {currentPaciente.localidad !== null &&
            currentPaciente.localidad.length > 0 && (
              <div className="flex mt-2 -ml-1 items-end">
                <i className="fas fa-location-dot fa-xl text-slate-500" />
                <p className="ml-2">{currentPaciente.localidad}</p>
              </div>
            )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            ml: 2,
            mr: 2,
            mt: 2,
            mb: 1,
            // boxShadow: 1,
            width: "100%",
            marginTop: 3,
            marginLeft: 3,
            // backgroundColor: "bgcards",
          }}
        >
          {currentPaciente.vip === 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 1,
                borderColor: "mediumgrey",
                width: 48,
                height: 48,
                borderRadius: 2,
                boxShadow: 2,
                marginRight: 2,
              }}
            >
              <Tooltip title="Paciente Vip">
                <WorkspacePremiumIcon
                  sx={{
                    color: "#b91c1c",
                    fontSize: 36,
                  }}
                />
              </Tooltip>
            </Box>
          )}
          {currentPaciente.paga_en_25 === 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 1,
                borderColor: "mediumgrey",
                width: 48,
                height: 48,
                borderRadius: 2,
                boxShadow: 2,
                marginRight: 2,
              }}
            >
              <Tooltip title="No Reclamar ! Paga en 25">
                <WarningIcon
                  sx={{
                    color: "iconyellow",
                    fontSize: 36,
                  }}
                />
              </Tooltip>
            </Box>
          )}
          {currentPaciente.feliz_cumple == "1" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 1,
                borderColor: "mediumgrey",
                width: 48,
                height: 48,
                borderRadius: 2,
                boxShadow: 2,
                marginRight: 2,
              }}
            >
              <Tooltip title=" Feliz Cumpleaños !">
                <CakeIcon
                  sx={{
                    color: "#a855f7",
                    fontSize: 36,
                  }}
                />
              </Tooltip>
            </Box>
          )}
          {saldo > 10 && currentPaciente.paga_en_25 === 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 1,
                borderColor: "mediumgrey",
                width: 48,
                height: 48,
                borderRadius: 2,
                boxShadow: 2,
                marginRight: 2,
              }}
            >
              <Tooltip title="Ver Saldo en Cuenta Corriente !">
                <PaidIcon
                  onClick={() => handleCtaCte()}
                  sx={{
                    color: "iconred",
                    fontSize: 36,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Box>
          )}
          {currentPaciente.presento_queja == "1" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 1,
                borderColor: "mediumgrey",
                width: 48,
                height: 48,
                borderRadius: 2,
                boxShadow: 2,
                marginRight: 2,
              }}
            >
              <Tooltip title="Presento Queja">
                <Button onClick={() => handleQuejas()}>
                  <i className="fa-solid fa-face-angry fa-2xl text-red-500"></i>
                </Button>
              </Tooltip>
            </Box>
          )}
          {currentPaciente.respondio_encuesta === "1" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 1,
                borderColor: "mediumgrey",
                width: 48,
                height: 48,
                borderRadius: 2,
                boxShadow: 2,
                marginRight: 2,
              }}
            >
              <Tooltip title="Respondió encuesta(s)">
                <StarRateIcon
                  sx={{
                    color: "icongreen",
                    fontSize: 36,
                    cursor: "pointer",
                  }}
                  onClick={() => handleEncuestas()}
                />
              </Tooltip>
            </Box>
          )}
        </Box>
        {crypto.decryptDataStorage("dataPaciente") !== null && (
          <>
            <div key={key} style={{ display: "none" }}>
              <RenderReportFichaGuardia today={today} ref={componentRef} />
            </div>
            <div className="flex justify-center mt-8 mb-8">
              <PrintButton
                componentToPrint={componentRef}
                text={"Ficha de Guardia"}
                beforePrint={() => setKey(key + 1)}
              />
            </div>
          </>
        )}
        <Box
          sx={{ display: { xs: "flex", lg: "none" }, justifyContent: "center" }}
        >
          <PacienteMenu
            metodorefresh={metodorefresh}
            metodoeditar={metodoeditar}
            metodohc={mostrarhc}
            metodoprecios={mostrarprecios}
            loading={loading}
            setLoading={setLoading}
          />
        </Box>
      </Card>
      <ModalCapturePhoto
        showModal={modalCapturePhotoOpen}
        hideModal={HideCapturePhoto}
        currentpatient={currentPaciente}
        metodorefresh={metodorefresh}
        folder="pacientes"
        height={360}
        width={360}
      />
      <ModalCapturePhoto
        showModal={modalCaptureImageOpen}
        hideModal={HideCaptureImage}
        currentpatient={currentPaciente}
        metodorefresh={null}
        folder="adjuntos"
        height={720}
        width={720}
      />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalSuccessOpen && modalMessage !== null}
        hideModal={hideSuccessModal}
        message={modalMessage}
        tipo="success"
      />
      {deleteImageId !== null && (
        <BorrarImagen
          imageId={deleteImageId}
          setLoading={setLoading}
          afterMethod={() => metodorefresh()}
          setDeleteImageId={setDeleteImageId}
        />
      )}
      {openSubirArchivo && (
        <SubirArchivo
          currentPaciente={currentPaciente}
          setLoading={setLoading}
          okmethod={okMethod}
          setOpenSubirArchivo={setOpenSubirArchivo}
        />
      )}
      {deletePacienteId !== null && (
        <DeleteModel
          model={"paciente"}
          modelid={deletePacienteId}
          okmethod={metodolimpiar}
          nullmethod={setDeletePacienteId}
        />
      )}
    </>
  );
}
