import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import ModalError from "../modals/ModalError";
import crypto from "../Crypto";
import { ModeloGetUrlJson, ModeloDelete } from "../ModeloCrud";
import FacebookCircularProgress from "../FacebookCircularProgress";
import ModalConfirmation from "../modals/ModalConfirmation";
import ModalInformation from "../modals/ModalInformation";
import BackPage from "../BackPage";
import PacienteHeader from "./PacienteHeader";
import { AddRounded } from "@mui/icons-material";
import RenderCustomTable from "../RenderCustomTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertUpdateOrdenes from "../presupuestos/InsertUpdateOrdenes";
import { PrintButton } from "../PrintButton";
import { RenderReportOrdenes } from "../presupuestos/RenderReportOrdenes";
import { hasPermission } from "../utils/Utils";

const PacienteOrdenes = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [allOrdenes, setAllOrdenes] = useState(null);
  const [allItems, setAllItems] = useState(null);
  const [allPresupuestos, setAllPresupuestos] = useState([]);
  const [allPresupuestosItems, setAllPresupuestosItems] = useState([]);
  const [allPasos, setAllPasos] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [borrarId, setBorrarId] = useState(0);
  const [editarId, setEditarId] = useState(0);
  const [selected, setSelected] = React.useState([]);
  let [rowOrden, setRowOrden] = React.useState([]);
  let [ordenToPrint, setOrdenToPrint] = useState(null);
  let [itemsToPrint, setItemsToPrint] = useState(null);
  const [idToPrint, setIdToPrint] = useState(0);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [nuevaOrden, setNuevaOrden] = useState(false);
  const [editarOrden, setEditarOrden] = useState(false);
  const [updateOk, setUpdateOk] = useState(false);
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const checkRefs = useRef([]);
  const [openRows, setOpenRows] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];
  const columns = [
    { field: "orden_id", headerName: "Id" },
    { field: "fecha_emision", headerName: "Fecha", isDate: true },
    { field: "fecha_espera", headerName: "F.Espera", isDate: true },
    { field: "presupuesto_id", headerName: "Presupuesto" },
    { field: "identificador", headerName: "Clínica", isClinic: true },
    { field: "especialidad", headerName: "Especialidad" },
    { field: "odontologo_or", headerName: "Odontólogo", capitalize: true },
    { field: "laboratorio_razon", headerName: "Laboratorio", capitalize: true },
    { field: "usuario", headerName: "Usuario", capitalize: true },
    { field: "factura", headerName: "Factura" },
    { field: "observaciones", headerName: "Notas", lowercase: true },
  ];

  const columnsChild = [
    { field: "id", headerName: "Id" },
    { field: "codigo_nomenclador", headerName: "Código" },
    { field: "numero_pieza_dental", headerName: "Pieza" },
    { field: "codigo_nomenclador_paso", headerName: "Paso" },
    {
      field: "descripcion_nomenclador_paso",
      headerName: "Descripción",
      capitalize: true,
    },
    { field: "motivo", headerName: "Motivo" },
    { field: "autorizado_por", headerName: "Autorizó" },
    { field: "tipo_cubeta", headerName: "Cubeta" },
    { field: "fecha_dev_en", headerName: "Devolución", isDate: true },
    { field: "estado_devolucion", headerName: "Estado" },
    { field: "remito_devolucion", headerName: "Remito" },
    { field: "usuario_recibido_nombre", headerName: "Recibió" },
    { field: "precio", headerName: "Precio" },
  ];
  const actions = [
    {
      icon: EditIcon,
      handle: (row) => handleEdit(row),
      sx: { color: "primary.main" },
      requiredPermission: "APROBAR PRESUPUESTOS",
    },
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
      requiredPermission: "APROBAR PRESUPUESTOS",
    },
    {
      // Custom action for PrintButton
      render: (row) => (
        <PrintButton
          componentToPrint={componentRef}
          promiseResolveRef={promiseResolveRef}
          beforePrint={() => handlePrint(row.orden_id)}
          buttonType={"table"}
          isPrinting={isPrinting}
          setIsPrinting={setIsPrinting}
        />
      ),
      sx: { color: "primary.main", marginLeft: 2 }, // You can customize the styling as needed
      requiredPermission: "ALTA CORDENESTRABAJO",
    },
  ];

  useEffect(() => {
    buscarOrdenes();
  }, []);

  function handleDelete(row) {
    setBorrarId(row.orden_id);
    setDisplayConfirmationModal(true);
    setModalMessage("Seguro quiere Borrar la orden ?");
  }

  const handleEdit = (row) => {
    setRowOrden(row);
    setEditarOrden(true);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };

  const hideInformationModal = () => {
    setModalInformationOpen(false);
    setModalMessage(null);
  };

  const handleNew = () => {
    setNuevaOrden(true);
  };

  const handleClickItem = (isChecked, row) => {
    if (isChecked) {
      setSelectedItems([...selectedItems, row]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item.id !== row.id));
    }
  };

  function handlePrint(id) {
    setIdToPrint(id);
    const tempOrdenes = allOrdenes.filter((p) => p.orden_id === id);
    setOrdenToPrint(tempOrdenes[0]);
    const tempItems = allItems.filter((i) => i.orden_id === id);
    setItemsToPrint(tempItems);
  }

  function confirmOk() {
    if (modalMessage === "Seguro quiere Borrar la orden ?") {
      EliminarOrden();
    }
  }

  async function EliminarOrden() {
    hideConfirmationModal();
    if (borrarId > 0) {
      let result = await ModeloDelete("orden-trabajo", borrarId);
      if (result.error === false) {
        buscarOrdenes();
      } else {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    }
  }

  async function buscarOrdenes() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      let result = await ModeloGetUrlJson(
        "paciente",
        PacienteId,
        "vista-ordenes-paciente-full",
        null
      );
      setLoading(false);
      if (result.error === false) {
        setAllOrdenes(result.ordenes);
        setAllItems(result.ordenes_lineas);
        setAllPresupuestos(result.presupuestos);
        setAllPresupuestosItems(result.presupuestos_lineas);
        setAllPasos(result.pasos);
      } else {
      }
    } else {
      handleClickVolver();
    }
  }

  const hideErrorModal = () => {
    setErrorMessage(null);
  };

  function handleClickVolver() {
    if (!nuevaOrden && !editarOrden) {
      history.push("/pacientes");
    } else {
      hideOrden();
    }
  }

  const hideOrden = () => {
    setRowOrden([]);
    setNuevaOrden(false);
    setEditarOrden(false);
  };

  return (
    <>
      <BackPage
        text={
          !nuevaOrden && !editarOrden
            ? "Volver al Paciente"
            : "Volver a Ordenes"
        }
        handleClick={handleClickVolver}
      />
      <PacienteHeader />

      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {!loading && !nuevaOrden && !editarOrden && (
        <Container maxWidth="xl">
          <Box sx={{ display: "flex", marginTop: 0, marginBottom: 3 }}>
            <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
              Ordenes de Trabajo
            </Typography>
            {hasPermission(userRoles, "ALTA CORDENESTRABAJO") && (
              <IconButton
                aria-label="new"
                onClick={handleNew}
                sx={{ p: "1px", marginTop: -1 }}
              >
                <AddRounded sx={{ fontSize: 48, color: "icongreen" }} />
              </IconButton>
            )}
          </Box>
          <Box sx={{ mb: 4, marginTop: -2 }}>
            <RenderCustomTable
              data={allOrdenes}
              columns={columns}
              paddingLeft={0}
              paddingRight={0}
              dataChild={allItems}
              columnsChild={columnsChild}
              parentIdColumn="orden_id"
              alternateRowColor={false}
              actions={actions}
            />
          </Box>
          <div>
            {allOrdenes && allItems && ordenToPrint && (
              <div style={{ display: "none" }}>
                <RenderReportOrdenes
                  ref={componentRef}
                  orden={ordenToPrint}
                  items={itemsToPrint}
                  orderNumber={idToPrint}
                />
              </div>
            )}
          </div>
        </Container>
      )}
      {errorMessage !== null && (
        <ModalError
          showModal={errorMessage !== null}
          hideModal={hideErrorModal}
          message={errorMessage}
        />
      )}
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={confirmOk}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      {nuevaOrden && (
        <InsertUpdateOrdenes
          ordenrow={null}
          itemsorden={[]}
          presupuestos={allPresupuestos}
          itemspresupuestos={allPresupuestosItems}
          pasos={allPasos}
          buscarCoes={true}
          showModal={nuevaOrden}
          hideModal={hideOrden}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
          buscarOrdenes={buscarOrdenes}
        />
      )}
      {editarOrden && (
        <InsertUpdateOrdenes
          ordenrow={rowOrden}
          itemsorden={allItems.filter((i) => i.orden_id === rowOrden.orden_id)}
          presupuestos={allPresupuestos}
          itemspresupuestos={allPresupuestosItems}
          pasos={allPasos}
          buscarCoes={true}
          showModal={nuevaOrden}
          hideModal={hideOrden}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
          buscarOrdenes={buscarOrdenes}
        />
      )}
      <BackPage
        text={
          !nuevaOrden && !editarOrden
            ? "Volver al Paciente"
            : "Volver a Ordenes"
        }
        handleClick={handleClickVolver}
      />
    </>
  );
};
export default PacienteOrdenes;
