/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import crypto from "../Crypto";
import { ModeloPost, ModeloUpdate } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs from "dayjs";
import * as moment from "moment";
import Draggable from "react-draggable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
      {...other}
    >
      {row !== null && (
        <EditIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {row === null && (
        <AddIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = yup.object({
  fecha_desde: yup
    .date()
    .nullable()
    .typeError("Fecha inválida.")
    .required("Campo obligatorio."),
  fecha_hasta: yup
    .date()
    .min(yup.ref("fecha_desde."), "Fecha inválida.")
    .nullable()
    .typeError("Fecha inválida.")
    .required("Campo obligatorio."),
  motivo: yup
    .string()
    .min(4, "El motivo requiere al menos 3 caracteres.")
    .required("Campo obligatorio."),
});

export default function ModalOdontologoAusencia({
  row,
  showModal,
  hideModal,
  setUpdateOk,
  okmethod = null,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const Today = new Date();
  const [myloading, setMyLoading] = React.useState(false);
  const [errorSave, setErrorSave] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  useEffect(() => {}, []);

  const handleClose = () => {
    formik.resetForm();
    hideModal();
  };

  const formik = useFormik({
    initialValues: {
      motivo: row ? row.motivo : null,
      fecha_desde: row ? dayjs(row.fecha_desde) : dayjs(Date()),
      fecha_hasta: row ? dayjs(row.fecha_hasta) : dayjs(Date()),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let operacion = row ? "UPDATE" : "NEW";
      grabarAusencia(values, operacion);
    },
  });

  async function grabarAusencia(values, operacion) {
    setMyLoading(true);
    setErrorSave(null);
    const Data = {
      odontologo_id: crypto.decryptDataStorage("currentOdontologo").id,
      fecha_desde: formik.values.fecha_desde.format().slice(0, 10),
      fecha_hasta: formik.values.fecha_hasta.format().slice(0, 10),
      motivo: formik.values.motivo,
    };
    if (operacion === "UPDATE") {
      let result = await ModeloUpdate("ausencia-odontologo", row.id, Data);
      if (result.error === false) {
        if (okmethod !== null) {
          okmethod();
        }
        handleClose();
      } else {
        setMyLoading(false);
        setModalMessage(result.errormessage);
        setModalInformationOpen(true);
      }
    } else {
      let result = await ModeloPost("ausencia-odontologo", null, null, Data);
      if (result.error === false) {
        if (okmethod !== null) {
          okmethod();
        }
        handleClose();
      } else {
        setMyLoading(false);
        setModalMessage(result.errormessage);
        setModalInformationOpen(true);
      }
    }
  }

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
            row={row}
          >
            {row !== null ? "Editar" : "Nuevo Registro"}
          </BootstrapDialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent className="content" dividers>
              <Grid container spacing={2} marginTop={0.5} marginBottom={4}>
                <Grid item xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"en-gb"}
                  >
                    <DateField
                      id="fecha_desde"
                      label="Desde"
                      value={formik.values.fecha_desde}
                      inputFormat="DD/MM/YYYY"
                      format="DD/MM/YYYY"
                      //defaultValue={dayjs(row.alta_usa)}
                      onChange={(value) =>
                        formik.setFieldValue("fecha_desde", value)
                      }
                      size="small"
                      helperText={
                        formik.touched.fecha_desde
                          ? formik.errors.fecha_desde
                          : ""
                      }
                      error={
                        formik.touched.fecha_desde &&
                        Boolean(formik.errors.fecha_desde)
                      }
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"en-gb"}
                  >
                    <DateField
                      id="fecha_hasta"
                      label="Hasta"
                      value={formik.values.fecha_hasta}
                      inputFormat="DD/MM/YYYY"
                      format="DD/MM/YYYY"
                      //defaultValue={dayjs(row.alta_usa)}
                      onChange={(value) =>
                        formik.setFieldValue("fecha_hasta", value)
                      }
                      size="small"
                      helperText={formik.errors.fecha_hasta}
                      error={Boolean(formik.errors.fecha_hasta)}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="motivo"
                    label="Motivo"
                    multiline
                    rows={10}
                    value={formik.values.motivo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.motivo ? formik.errors.motivo : ""
                    }
                    error={
                      formik.touched.motivo && Boolean(formik.errors.motivo)
                    }
                    margin="dense"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="actions">
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                loadingPosition="end"
                sx={{
                  backgroundColor: "#cbd5e1",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                loading={myloading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
              >
                <span>Grabar</span>
              </LoadingButton>
            </DialogActions>
          </form>
        </BootstrapDialog>
      </Draggable>
    </div>
  );
}
