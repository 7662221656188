import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assetss/css/EditarPaciente.css";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FootPage from "../FootPage";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import crypto from "../Crypto";
import { ModeloGetUrl } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import FacebookCircularProgress from "../FacebookCircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ModalInformation from "../modals/ModalInformation";
import { highlightedText, FormatHorario45 } from "../utils/Utils.js";
import BackPage from "../BackPage";
import PacienteHeader from "../pacientes/PacienteHeader";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import ModalAsignarTurnos from "../modals/ModalAsignarTurnos";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const TurnosLibres = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allTratamientos, setAllTratamientos] = useState(null);
  let [filterTratamientos, setFilterTratamientos] = useState([]);
  let [selectedDays, setSelectedDays] = useState([]);
  let [inputDay, setInputDay] = useState("");
  let [selectedHorarios, setSelectedHorarios] = useState([]);
  let [inputHorario, setInputHorario] = useState("");
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const tableRef = useRef();
  const [filterText, setFilterText] = useState("");
  const [backPage, setBackPage] = useState("agendassimples");
  const location = useLocation();
  let [selectedClinica, setSelectedClinica] = useState(null);
  let [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  let [selectedOdontologo, setSelectedOdontologo] = useState(null);
  let [selectedTurnos, setSelectedTurnos] = useState([]);
  let [filterData, setFilterData] = useState([]);
  const [showSinTurnos, setShowSinTurnos] = useState(false);
  const diasSemana = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];
  const horarios = ["Mañana", "Tarde"];
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const currentpacpreid = crypto.decryptDataStorage("currentpacpreid");
  const [asignarTurnos, setAsignarTurnos] = useState(false);
  const [asignarTurnosId, setAsignarTurnosId] = useState(false);
  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];

  useEffect(() => {
    sessionStorage.removeItem("ags_centro_id");
    sessionStorage.removeItem("ags_especialidad_id");
    sessionStorage.removeItem("ags_odontologo_id");
    sessionStorage.removeItem("ags_fecha");
    if (typeof location.backpage !== "undefined") {
      setBackPage(location.backpage);
    }
    if (crypto.decryptDataStorage("selectedclinica") !== null) {
      setSelectedClinica(crypto.decryptDataStorage("selectedclinica"));
    }
    if (crypto.decryptDataStorage("selectedespecialidad") !== null) {
      setSelectedEspecialidad(
        crypto.decryptDataStorage("selectedespecialidad")
      );
    }
    if (crypto.decryptDataStorage("selectedodontologo") !== null) {
      setSelectedOdontologo(crypto.decryptDataStorage("selectedodontologo"));
    }
    handleClickBuscar();
  }, []);

  async function handleClickBuscar() {
    setFilterTratamientos([]);
    let lcParam = "";
    const myClinica = crypto.decryptDataStorage("selectedclinica");
    const myEspecialidad = crypto.decryptDataStorage("selectedespecialidad");
    const myOdontologo = crypto.decryptDataStorage("selectedodontologo");

    if (selectedClinica !== null) {
      lcParam += '"centros"' + ":[" + selectedClinica.id + "]";
    }
    if (myOdontologo !== null) {
      lcParam += ',"odontologos"' + ":[" + myOdontologo.odontologo_id + "]";
    }
    if (selectedDays.length > 0) {
      let dias = "";
      lcParam += ',"dias"' + ":[";
      for (let i = 0; i < selectedDays.length; i++) {
        dias +=
          dias.length === 0
            ? '"' + selectedDays[i] + '"'
            : "," + '"' + selectedDays[i] + '"';
      }
      lcParam += dias + "]";
    }
    if (selectedHorarios.length > 0) {
      let horario = "";
      lcParam += ',"turnos"' + ":[";
      for (let i = 0; i < selectedHorarios.length; i++) {
        horario +=
          horario.length === 0
            ? '"' + selectedHorarios[i].toString().slice(0, 1) + '"'
            : "," + '"' + selectedHorarios[i].toString().slice(0, 1) + '"';
      }
      lcParam += horario + "]";
    }

    if (lcParam.length > 0) {
      if (lcParam.slice(0, 1) === ",") {
        lcParam = lcParam.slice(1);
      }
      lcParam = "param={" + lcParam + "}";
    }

    if (myEspecialidad !== null) {
      setLoading(true);
      const result = await ModeloGetUrl(
        "especialidad",
        myEspecialidad.id,
        "turnos-libres",
        lcParam
      );
      setLoading(false);
      if (result.error === false) {
        if (result.listado.length > 0) {
          setFilterTratamientos(result.listado);
        } else {
          setShowSinTurnos(true);
        }
      }
    }
  }

  const handleGoToAgenda = (row) => {
    crypto.encryptDataStorage("ags_centro_id", row.centro_id);
    crypto.encryptDataStorage("ags_especialidad_id", row.especialidad_id);
    crypto.encryptDataStorage("ags_odontologo_id", row.odontologo_id);
    crypto.encryptDataStorage("ags_fecha", row.fecha_ag);
    history.push("/agendassimples");
  };

  const onClinicasChange = (event, values) => {
    setSelectedClinica(values);
  };

  const handleAsignarTurnos = () => {
    if (currentpacpreid === null) {
      setModalMessage("Seleccione un paciente.");
      setModalInformationOpen(true);
      return;
    }
    if (selectedTurnos.length > 0) {
      const data = selectedTurnos;
      for (let i = 0; i < data.length; i++) {
        if (data[i].habilitada !== 1) {
          setModalMessage("Agenda Bloqueada.");
          setModalInformationOpen(true);
          return;
        } else if (
          data[i].estado !== "L" &&
          data[i].nro_documento !== 10101010 &&
          data[i].nro_documento !== 10101012
        ) {
          setModalMessage("Algunos turnos NO están libres.");
          setModalInformationOpen(true);
          return;
        }
      }
      setAsignarTurnosId(selectedTurnos);
      setAsignarTurnos(true);
    } else {
      setModalMessage("Seleccione al menos un turno.");
      setModalInformationOpen(true);
    }
  };

  function updateSelectedTurnos(pValue, Row) {
    const oldValue = Row.checked;
    const index = filterTratamientos.findIndex((obj) => {
      return obj.id === Row.id;
    });
    if (index >= 0) {
      filterTratamientos[index].checked = oldValue === 0 ? 1 : 0;
    }
    var tempTurnos = [...filterTratamientos];
    setFilterTratamientos(tempTurnos);
    selectedTurnos = filterTratamientos.filter((g) => g.checked === 1);
    var tempSelected = [...selectedTurnos];
    setSelectedTurnos(tempSelected);
  }

  const onDaysChange = (event, values) => {
    if (values !== null) {
      setSelectedDays(values);
    } else {
      setSelectedDays([]);
    }
  };

  const onHorariosChange = (event, values) => {
    if (values !== null) {
      selectedHorarios = values;
    } else {
      selectedHorarios = [];
    }
    const tempHorarios = [...selectedHorarios];
    setSelectedHorarios(tempHorarios);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: 30,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  function handleClickVolver() {
    history.push("/" + backPage);
  }
  function onChangeSearch(e) {
    const filterText = e.target.value;
    setFilterText(filterText);
    FiltrarHc(filterText);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      FiltrarHc(filterText);
    }
  }

  function QuitarFiltro() {
    setFilterText("");
    setFilterTratamientos(allTratamientos);
  }

  function FiltrarHc(pFiltro) {
    if (pFiltro.length > 0) {
      filterTratamientos = allTratamientos.filter(
        (g) =>
          g.codigo_nomenclador.indexOf(pFiltro) >= 0 ||
          g.odontologo.toLowerCase().indexOf(pFiltro.toLowerCase()) >= 0 ||
          g.descripcion.toLowerCase().indexOf(pFiltro.toLowerCase()) >= 0
      );
      if (filterTratamientos.length <= 0) {
        filterTratamientos = [];
      }
    } else {
      filterTratamientos = allTratamientos;
    }
    let tempData = [...filterTratamientos];
    setFilterTratamientos(tempData);
  }

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow key={row.id}>
          <StyledTableCell component="th" width="1%" scope="row">
            {row.checked === 0 && (
              <i
                class="fa-regular fa-square fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedTurnos(0, row)}
              ></i>
            )}
            {row.checked === 1 && (
              <i
                class="fa-regular fa-square-check fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedTurnos(0, row)}
              ></i>
            )}
          </StyledTableCell>

          <StyledTableCell width="5%" component="th" scope="row">
            {row.fecha}
          </StyledTableCell>
          <StyledTableCell width="5%" component="th" scope="row">
            {FormatHorario45(row.hora_desde)}
          </StyledTableCell>
          <StyledTableCell width="10%" component="th" scope="row">
            {row.dia_semana}
          </StyledTableCell>
          <StyledTableCell width="4%" component="th" scope="row">
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: row.centro_color,
                marginBottom: 0.5,
                border: 1,
                borderColor: "black",
              }}
            >
              {row.centro_identificador}
            </Avatar>
          </StyledTableCell>
          <StyledTableCell width="25%" component="th" scope="row">
            {row.especialidad}
          </StyledTableCell>
          <StyledTableCell width="20%" component="th" scope="row">
            <Box
              sx={{
                textTransform: "capitalize",
              }}
            >
              {row.odontologo.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell width="5%" component="th" scope="row">
            <Tooltip title="Ir a la agenda">
              <EventNoteIcon
                cursor="pointer"
                sx={{
                  color: "primary.main",
                  fontSize: 24,
                }}
                onClick={() => handleGoToAgenda(row)}
              />
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <BackPage text="Volver" handleClick={handleClickVolver} />
      <div className="mt-1 ">
        <PacienteHeader backpage="agendassimples" />
        <div className="-mt-3 mb-1 capitalize justify-center items-center flex flex-col md:flex-row    ">
          <Autocomplete
            id="clinica"
            sx={{
              width: "17.8rem",
              marginTop: { xs: 3, md: 0 },
              marginLeft: { xs: 0, md: 1 },
            }}
            options={allCentros}
            value={selectedClinica}
            autoHighlight
            size="small"
            onChange={onClinicasChange}
            getOptionLabel={(option) => option.razonsocial}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: option.color,
                    marginBottom: 0.5,
                    marginRight: 1,
                    border: 1,
                    borderColor: "black",
                  }}
                >
                  {option.identificador}
                </Avatar>
                {option.razonsocial}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Clínica"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { justifyContent: "center" },
                }}
              />
            )}
          />

          <Autocomplete
            multiple
            id="checkboxes-days"
            options={diasSemana}
            size="small"
            sx={{ width: { xs: 300, sm: 600 }, px: 1, py: 1 }}
            disableCloseOnSelect
            filterSelectedOptions
            autoHighlight
            onChange={(event, newDays) => {
              setSelectedDays(newDays);
            }}
            inputValue={inputDay}
            onInputChange={(event, newPetInputValue) => {
              setInputDay(newPetInputValue);
            }}
            renderInput={(params) => {
              return <TextField label="Dias..." {...params} />;
            }}
          />

          <Autocomplete
            multiple
            id="horarios"
            options={horarios}
            size="small"
            sx={{ width: 300, px: 1, py: 1 }}
            disableCloseOnSelect
            filterSelectedOptions
            autoHighlight
            onChange={(event, newHorario) => {
              setSelectedHorarios(newHorario);
            }}
            inputValue={inputHorario}
            onInputChange={(event, newPetInputValue) => {
              setInputHorario(newPetInputValue);
            }}
            renderInput={(params) => {
              return <TextField label="Horario..." {...params} />;
            }}
          />

          <LoadingButton
            size="medium"
            onClick={handleClickBuscar}
            loading={loading}
            loadingPosition="start"
            endIcon={<SearchIcon />}
            sx={{
              backgroundColor: "primary.main",
              ":hover": {
                bgcolor: "#475569", // theme.palette.primary.main
                color: "white",
              },
              width: 80,
              marginLeft: 1,
              marginTop: { xs: 1, sm: 0 },
            }}
            variant="contained"
          >
            <span></span>
          </LoadingButton>
        </div>
      </div>
      {!loading && (
        <div>
          <Box sx={{ display: "flex", justifyContent: "start", mt: -0.5 }}>
            <Container maxWidth="xl">
              <IconButton
                aria-label="new"
                onClick={handleAsignarTurnos}
                sx={{ p: "1px", marginLeft: 1, marginTop: -1 }}
              >
                <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
              </IconButton>
            </Container>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Container maxWidth="xl">
              <Box sx={{ display: "flex", justifyContent: "end" }}></Box>
              <TableContainer component={Paper}>
                <Table
                  ref={tableRef}
                  sx={{ minWidth: { xs: 512, sm: 1280 } }}
                  aria-label="customized table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Sel</StyledTableCell>
                      <StyledTableCell>Fecha</StyledTableCell>
                      <StyledTableCell>Hora</StyledTableCell>
                      <StyledTableCell>Dia</StyledTableCell>
                      <StyledTableCell>Cli</StyledTableCell>
                      <StyledTableCell>Especialidad</StyledTableCell>
                      <StyledTableCell>Odóntologo</StyledTableCell>
                      <StyledTableCell>Agenda</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterTratamientos !== null &&
                      filterTratamientos.length > 0 &&
                      filterTratamientos
                        .sort((a, b) =>
                          a.fecha_hora_id > b.fecha_hora_id ? 1 : -1
                        )
                        .map((row) => <Row key={row.id} row={row} />)}
                    {filterTratamientos !== null &&
                      filterTratamientos.length === 0 && (
                        <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                          {showSinTurnos === true && (
                            <Typography variant="body1" gutterBottom>
                              Sin Turnos Libres.
                            </Typography>
                          )}
                        </Box>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Box>
        </div>
      )}

      <BackPage text="Volver" handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      {asignarTurnos &&
        selectedTurnos !== null &&
        selectedTurnos.length > 0 && (
          <ModalAsignarTurnos
            turnos={selectedTurnos}
            tipoTurno="Turno"
            showModal={asignarTurnos}
            hideModal={() => setAsignarTurnos(false)}
            buscaragenda={handleClickBuscar}
          />
        )}
    </>
  );
};
export default TurnosLibres;
