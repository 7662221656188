import React from "react";

const MyButton = ({
  type,
  onClick,
  text,
  width,
  icon = null,
  fasfa = null,
  textcolor = "white",
  backcolor = "sky-600",
  isdisabled = false,
  tooltipid = null,
  tooltipcontent = null,
}) => {
  return (
    <button
      type={type}
      className={
        "text-" +
        textcolor +
        " border-2 border-slate-500  bg-" +
        backcolor +
        " hover:bg-opacity-75 rounded-lg text-sm px-2 py-2  text-center ml-1 mr-1 flex items-center justify-center"
      }
      // className="bg-[#3F8CCB] hover:bg-[#129FDA] text-white  py-2 px-4 border border-[#64748b] rounded flex items-center justify-center "
      style={{ width: width, height: 38 }}
      onClick={onClick !== null ? () => onClick() : null}
      disabled={isdisabled}
      data-tooltip-id={tooltipid}
      data-tooltip-content={tooltipcontent}
    >
      {icon !== null && <img src={icon} class="px-2" />}
      {fasfa !== null && (
        <i
          className={"fas fa-" + fasfa + " fa-xl text-" + textcolor + " px-2"}
        ></i>
      )}
      {text}
    </button>
  );
};

export default MyButton;
