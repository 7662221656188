import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

// Componente que permite arrastrar el modal

const DraggableComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title" // Solo el título es arrastrable
      cancel={'[class*="MuiDialogContent-root"]'} // Evitamos que el contenido sea draggable
    >
      <Paper {...props} />
    </Draggable>
  );
};

const CustomModal = ({
  open,
  onClose,
  title,
  icon,
  children,
  disableActions = false,
  width = "50%",
  maxWidth = "600px",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={DraggableComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={maxWidth} // Ajusta el tamaño máximo del modal
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "20px",
          width: { width }, // Ajusta el ancho aquí
          maxWidth: { maxWidth }, // Establece un ancho máximo
        },
        "& .MuiPaper-root": {
          //backgroundColor: "bgmodal", // Adjust transparency here
        },
      }}
    >
      <DialogTitle
        sx={{
          cursor: "move",
          backgroundColor: "primary.main", // Azul oscuro
          padding: "8px 16px", // Espaciado alrededor del contenido
          display: "flex",
          alignItems: "center",
          color: "#fff", // Color del texto blanco
          height: "1.2cm", // Altura aproximada de 1 cm
        }}
        id="draggable-dialog-title"
      >
        {icon && <div style={{ marginRight: 8 }}>{icon}</div>}
        <Typography sx={{ fontSize: 22 }} component="span">
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: "absolute",
            right: 8,
            top: 4,
            color: "#fff", // Color del ícono de cerrar en blanco
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {children} {/* Aquí va el formulario o contenido */}
      </DialogContent>
      <Divider />
      {!disableActions && <DialogActions></DialogActions>}
    </Dialog>
  );
};

export default CustomModal;
