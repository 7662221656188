import React, { useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import { ModeloSearch } from "../ModeloCrud";
import SearchIcon from "@mui/icons-material/Search";

const PacientesBusquedaAvanzada = ({
  open,
  anchorEl,
  id,
  handleClose,
  setResultados,
  setLoadingPacientes,
}) => {
  const [apellido, setApellido] = React.useState(null);
  const [nombres, setNombres] = React.useState(null);
  const [fnacimiento, setFNacimiento] = React.useState(null);
  const [telefono, setTelefono] = React.useState(null);
  const [telefono2, setTelefono2] = React.useState(null);
  const [celular, setCelular] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  function handleCloseForm() {
    setApellido(null);
    setNombres(null);
    setFNacimiento(null);
    setTelefono(null);
    setTelefono2(null);
    setCelular(null);
    setEmail(null);
    handleClose();
  }

  async function handleBuscar() {
    let data = {};
    if (apellido !== null) {
      data["apellido"] = apellido;
    }
    if (nombres !== null) {
      data["nombres"] = nombres;
    }
    if (fnacimiento !== null) {
      data["fecha_nacimiento"] = fnacimiento;
    }
    if (telefono !== null) {
      data["telefono"] = telefono;
    }
    if (telefono2 !== null) {
      data["telefono2"] = telefono2;
    }
    if (celular !== null) {
      data["celular"] = celular;
    }
    if (email !== null) {
      data["email"] = email;
    }
    const keysArray = Object.keys(data);
    const count = keysArray.length;
    if (count > 0) {
      data["tipo_busqueda"] = "and";
      data["comparacion_busqueda"] = "like";
      setLoading(true);
      let result = await ModeloSearch("paciente", data);

      setLoading(false);
      if (result.error === false) {
        setResultados(result.listado);
        handleCloseForm();
        return;
      } else {
        handleCloseForm();
      }
    }
    handleCloseForm();
    return;
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseForm}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          p: 1,
          m: 1,
          bgcolor: "background.paper",
          borderRadius: 1,
          width: 400,
        }}
      >
        <TextField
          id="standard-search"
          label="Apellido"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={apellido}
          onChange={(event) => {
            setApellido(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="Nombres"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={nombres}
          onChange={(event) => {
            setNombres(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="F.Nacimiento (yyyy-mm-dd)"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={fnacimiento}
          onChange={(event) => {
            setFNacimiento(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="Teléfono"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={telefono}
          onChange={(event) => {
            setTelefono(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="Teléfono alternativo"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={telefono2}
          onChange={(event) => {
            setTelefono2(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="Celular"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={celular}
          onChange={(event) => {
            setCelular(event.target.value);
          }}
        />
        <TextField
          id="standard-search"
          label="Email"
          type="search"
          variant="standard"
          sx={{
            marginTop: 2,
            marginRight: 2,
            width: { xs: 300, md: 380 },
          }}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          marginTop: 4,
          marginBottom: 2,
          marginRight: 2,
        }}
      >
        <Button
          onClick={handleCloseForm}
          color="primary"
          variant="contained"
          loadingPosition="end"
          sx={{
            backgroundColor: "#cbd5e1",
            ":hover": {
              bgcolor: "#475569", // theme.palette.primary.main
              color: "white",
            },
            textTransform: "capitalize",
            width: "120px",
          }}
        >
          Cancelar
        </Button>
        <LoadingButton
          onClick={handleBuscar}
          color="primary"
          loading={loading}
          loadingPosition="start"
          startIcon={<SearchIcon />}
          variant="contained"
          sx={{
            backgroundColor: "primary.main",
            ":hover": {
              bgcolor: "#475569", // theme.palette.primary.main
              color: "white",
            },
            textTransform: "capitalize",
            width: "120px",
          }}
        >
          <span>Buscar</span>
        </LoadingButton>
      </Box>
    </Popover>
  );
};

export default PacientesBusquedaAvanzada;
