import React from "react";
import "../assetss/css/Odontogram.css";
import Teeth from "./Teeth";
import Box from "@mui/material/Box";

function Odontogram({
  data,
  tratamiento,
  color,
  readonly,
  handleToothUpdate,
  cleanodontograma,
  itemstoadd,
  setItemsToAdd,
}) {
  function handleClick() {}

  return (
    <>
      <Box sx={{ height: "100%", marginLeft: { sm: 11, lg: 0 } }}>
        <div className="hidden sm:grid  ">
          <div className="Odontogram">
            <svg svg id="svg" version="1.1" height="100%" width="100%">
              <Teeth
                start={18}
                end={11}
                x={6}
                y={10}
                handleChange={handleToothUpdate}
                data={data}
                tratamiento={tratamiento}
                color={color}
                readonly={readonly}
                cleanodontograma={cleanodontograma}
                itemstoadd={itemstoadd}
                setItemsToAdd={setItemsToAdd}
              />
              <Teeth
                start={21}
                end={28}
                x={216}
                y={10}
                handleChange={handleToothUpdate}
                data={data}
                tratamiento={tratamiento}
                color={color}
                readonly={readonly}
                cleanodontograma={cleanodontograma}
                itemstoadd={itemstoadd}
                setItemsToAdd={setItemsToAdd}
              />
              <Teeth
                start={48}
                end={41}
                x={6}
                y={40}
                handleChange={handleToothUpdate}
                data={data}
                tratamiento={tratamiento}
                color={color}
                readonly={readonly}
                cleanodontograma={cleanodontograma}
                itemstoadd={itemstoadd}
                setItemsToAdd={setItemsToAdd}
              />
              <Teeth
                start={31}
                end={38}
                x={216}
                y={40}
                handleChange={handleToothUpdate}
                data={data}
                tratamiento={tratamiento}
                color={color}
                readonly={readonly}
                cleanodontograma={cleanodontograma}
                itemstoadd={itemstoadd}
                setItemsToAdd={setItemsToAdd}
              />
              <line x1="313" y1="0" x2="313" y2="220" stroke="black" />
              <line x1="0" y1="110" x2="626" y2="110" stroke="black" />
              <Teeth
                start={55}
                end={51}
                x={81}
                y={86}
                handleChange={handleToothUpdate}
                data={data}
                tratamiento={tratamiento}
                color={color}
                readonly={readonly}
                cleanodontograma={cleanodontograma}
                itemstoadd={itemstoadd}
                setItemsToAdd={setItemsToAdd}
              />
              <Teeth
                start={61}
                end={65}
                x={216}
                y={86}
                handleChange={handleToothUpdate}
                data={data}
                tratamiento={tratamiento}
                color={color}
                readonly={readonly}
                cleanodontograma={cleanodontograma}
                itemstoadd={itemstoadd}
                setItemsToAdd={setItemsToAdd}
              />

              <Teeth
                start={85}
                end={81}
                x={81}
                y={116}
                handleChange={handleToothUpdate}
                data={data}
                tratamiento={tratamiento}
                color={color}
                readonly={readonly}
                cleanodontograma={cleanodontograma}
                itemstoadd={itemstoadd}
                setItemsToAdd={setItemsToAdd}
              />
              <Teeth
                start={71}
                end={75}
                x={216}
                y={116}
                handleChange={handleToothUpdate}
                data={data}
                tratamiento={tratamiento}
                color={color}
                readonly={readonly}
                cleanodontograma={cleanodontograma}
                itemstoadd={itemstoadd}
                setItemsToAdd={setItemsToAdd}
              />
            </svg>
          </div>
        </div>
      </Box>
    </>
  );
}

export default Odontogram;
