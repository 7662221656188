import React from "react";
import { Modal, Button } from "react-bootstrap";
//import 'bootstrap/dist/css/bootstrap.min.css';

const ModalError = ({ showModal, hideModal, message }) => {
  //
  let titulo = "Error";
  // if(message !== null && message.search("Integrity constraint violation") >= 0){
  //   message="Error de Integridad Referencial."
  // }
  // if(message !== null && message.search("Request failed with status code 401") >= 0){
  //   message="Por favor ingrese nuevamente.";
  //   titulo="La sesión ha expirado."
  // }
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      animation={true}
      backdrop="static"
    >
      <Modal.Header
        className="bg-gradient-to-r from-red-500 to-red-300"
        closeButton
      >
        <div className="flex justify-center items-center">
          <i className="fas fa-times-circle fa-3x text-white mr-2" />
          <Modal.Title>
            <div className="text-white text-2xl">{titulo}</div>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="rounded-lg mt-3 mb-3 flex items-center justify-center text-2xl text-gray-500 ">
          {message}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="focus:outline-none h-10 text-white text-sm py-1 px-4 rounded-md bg-red-400 hover:bg-red-500 hover:shadow-lg"
          onClick={hideModal}
        >
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalError;
