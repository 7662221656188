import esLocale from "@fullcalendar/core/locales/es";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Enumerable from "linq";
import * as moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";
import "../../assetss/css/CalendarMM.css";
import "../../assetss/css/Pacientes.css";
import Dental from "../../assetss/img/dental-24-blue.png";
import Dentist from "../../assetss/img/dentist-24-blue.png";
import crypto from "../Crypto";
import ModalError from "../modals/ModalError";
import ModalInformation from "../modals/ModalInformation";
import ModalAsignarTurnos from "../modals/ModalAsignarTurnos";
import ModalConfirmation from "../modals/ModalConfirmation";
import { ModeloGetUrl, ModeloPost } from "../ModeloCrud";
import { CapSentence, StringToDate, hasPermission } from "../utils/Utils";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../assetss/css/SyncfusionMaterial.css";
import PacienteHeader from "../pacientes/PacienteHeader";
import CoeAutocomplete from "../CoeAutocomplete";
import ProtectedRoute from "../ProtectedRoute";

const AgendasMultiples = () => {
  const inputRef = useRef();
  const [searchAgenda, setSearchAgenda] = useState("");
  const [currentEspecialidad, setCurrentEspecialidad] = useState(null);
  const [currentOdontologo, setCurrentOdontologo] = useState(null);
  const [currentAgenda, setCurrentAgenda] = useState(null);
  const [modalDentistOpen, setModalDentistOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(null);
  let [eventosTurnos, setEventosTurnos] = useState(null);
  const [eventosOdontologo, setEventosOdontologo] = useState(null);
  const [monthday, setMonthDay] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [dataModalTable, setDataModalTable] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalTableInformationOpen, setModalTableInformationOpen] =
    useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [currentPaciente, setCurrentPaciente] = useState(null);
  const [currentPrepaga, setCurrentPrepaga] = useState(null);
  const [configuracionAgendas, setConfiguracionAgendas] = useState(null);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [showEventTime, setShowEventTime] = useState(true);
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const [selectedTurnos, setSelectedTurnos] = useState([]);
  const [selectedClinica, setSelectedClinica] = useState(null);
  const [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  const [selectedOdontologo, setSelectedOdontologo] = useState(null);
  const [selectedMotivo, setSelectedMotivo] = useState(null);
  const [odontologosLista, setOdontologosLista] = useState(null);
  const [especialidadesLista, setEspecialidadesLista] = useState(null);
  const [libresChecked, setLibresChecked] = useState(false);
  const fotopaciente = crypto.decryptDataStorage("dataFoto");
  const [motivosEspecialidad, setMotivosEspecialidad] = useState([]);
  const [piezasDentales, setPiezasDentales] = useState([]);
  const [derivadores, setDerivadores] = useState(null);
  const [asignarTurnos, setAsignarTurnos] = useState(false);
  const [asignarTurnosId, setAsignarTurnosId] = useState(false);
  const [renderCalendar, setRenderCalendar] = useState(true);
  let [hiddenDays, setHiddendays] = useState([]);
  let [durationsDays, setDurationDays] = useState(7);
  const Today = new Date();
  const [tipoTurno, setTipoTurno] = useState("");
  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];
  let [initialCalendarDate, setInitialCalendarDay] = useState(
    moment(Today).format("YYYY-MM-DD")
  );
  let [sinTurnos, setSinTurnos] = useState([]);
  let [conTurnos, setConTurnos] = useState([]);
  let [bloqueados, setBloqueados] = useState([]);
  let [feriados, setFeriados] = useState([]);
  let [ausencias, setAusencias] = useState([]);
  let allEspecialidades = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .groupBy((g) => g.especialidad_id)
    .select((e) => ({
      id: e.key(),
      especialidad: e.max((m) => m.especialidad),
    }))
    .toArray();
  let allOdontologos = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((w) => w.centro_id > 0)
    .where((w) => w.especialidad_id > 0)
    .groupBy((g) => g.odontologo_id)
    .select((e) => ({
      id: e.key(),
      odontologo: e.max((m) => m.odontologo),
    }))
    .toArray();
  const currentpacpreid = crypto.decryptDataStorage("currentpacpreid");
  let currentPacientePrepaga =
    crypto.decryptDataStorage("currentpatientname") !== null &&
    crypto.decryptDataStorage("currentprepagacodigo") !== null
      ? crypto.decryptDataStorage("currentpatientname") +
        " (" +
        crypto.decryptDataStorage("currentprepagacodigo") +
        ")"
      : null;
  const history = useHistory();
  //const Linqer = require('@siderite/linqer');
  const desde = moment(Today).format("YYYY-MM-DD");
  const hasta = moment(Today).add(120, "months").format("YYYY-MM-DD");
  let errorturnotomado = crypto.decryptDataStorage("errorturnotomado");
  const [minHora, setMinHora] = useState(
    crypto.decryptDataStorage("minhora") === null
      ? "08:00"
      : crypto.decryptDataStorage("minhora")
  );
  const [maxHora, setMaxHora] = useState(
    crypto.decryptDataStorage("maxhora") === null
      ? "08:00"
      : crypto.decryptDataStorage("maxhora")
  );
  let [selectedDays, setSelectedDays] = useState(null);
  let [inputDay, setInputDay] = useState("");
  let [selectedHorarios, setSelectedHorarios] = useState(null);
  let [inputHorario, setInputHorario] = useState("");
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const diasSemana = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];
  const horarios = ["Mañana", "Tarde"];

  // formula dias = 5 / cantidad de agendas semanales * 7
  // 1 agenda   35 dias
  // 2 agendas   17  dias
  // 3 agendas   11 dias
  // 4 agendas  8 dias
  // 5 agendas   7 dias

  //hiddenDays.push(0, 6);
  let ActualView = "Agenda";
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (hasPermission(userRoles, "VISUALIZAR AGENDAS")) {
      document.documentElement.style.setProperty(
        "--buttonBgColor",
        crypto.decryptLocalStorage("primarymain")
      );
      const allferiados = crypto.decryptDataStorage("feriados");
      if (allferiados !== null) {
        for (let i = 0; i < allferiados.length; i++) {
          feriados.push(allferiados[i]["fecha"]);
        }
        let tempFeriados = [...feriados];
        setFeriados(tempFeriados);
      }
      setSelectedTurnos([]);
      crypto.encryptDataStorage("agmuselectedturnos", []);
      if (crypto.decryptDataStorage("agmuselectedclinica") !== null) {
        setSelectedClinica(crypto.decryptDataStorage("agmuselectedclinica"));
      }
      if (crypto.decryptDataStorage("agmuselectedespecialidad") !== null) {
        setSelectedEspecialidad(
          crypto.decryptDataStorage("agmuselectedespecialidad")
        );
      }
      if (crypto.decryptDataStorage("agmuselectedodontologo") !== null) {
        setSelectedOdontologo(
          crypto.decryptDataStorage("agmuselectedodontologo")
        );
      }
      if (crypto.decryptDataStorage("agmulibreschecked") !== null) {
        setLibresChecked(crypto.decryptDataStorage("agmulibreschecked"));
      }
      if (crypto.decryptDataStorage("agmuespecialidadeslista") !== null) {
        setEspecialidadesLista(
          crypto.decryptDataStorage("agmuespecialidadeslista")
        );
      } else {
        setEspecialidadesLista(allEspecialidades);
      }
      if (crypto.decryptDataStorage("agmuodontologoslista") !== null) {
        setOdontologosLista(crypto.decryptDataStorage("agmuodontologoslista"));
      } else {
        setOdontologosLista(allOdontologos);
      }
      if (crypto.decryptDataStorage("agmuallagendas") !== null) {
        agregarEventosOdontologo(crypto.decryptDataStorage("agmuallagendas"));
      }
      if (crypto.decryptDataStorage("agmudataevents") !== null) {
        agregarEventosAgendasMultiples(
          crypto.decryptDataStorage("agmudataevents")
        );
      }
      if (crypto.decryptDataStorage("agmueventturnos") !== null) {
        setEventosTurnos(crypto.decryptDataStorage("agmueventturnos"));
      }
      if (crypto.decryptDataStorage("agmuhiddendays") !== null) {
        setHiddendays(crypto.decryptDataStorage("agmuhiddendays"));
      }
      if (crypto.decryptDataStorage("agmucalendarduration") !== null) {
        setDurationDays(crypto.decryptDataStorage("agmucalendarduration"));
      }
      if (crypto.decryptDataStorage("agmucurrentcalendarday") !== null) {
        setInitialCalendarDay(
          crypto.decryptDataStorage("agmucurrentcalendarday")
        );
      }
      if (crypto.decryptDataStorage("allausencias") !== null) {
        setAusencias(crypto.decryptDataStorage("allausencias"));
      }
      if (crypto.decryptDataStorage("agmuactualview") === null) {
        crypto.encryptDataStorage("agmuactualview", "Agenda");
      }
    }
  }, []);

  function buscarInformacionGrabarTurno() {
    const especialidadid = crypto.decryptDataStorage(
      "agmuselectedespecialidad"
    ).id;
    const motivos = crypto.decryptDataStorage("motivos_especialidades");
    if (motivos !== null) {
      const motivos_especialidades = motivos.filter(
        (g) => g.especialidad_id === especialidadid
      );
      setMotivosEspecialidad(motivos_especialidades);
    }
    setPiezasDentales(crypto.decryptDataStorage("piezas_dentales"));
    setDerivadores(crypto.decryptDataStorage("derivadores"));
    setLoading(false);
    return;
  }

  const calendarRef = React.createRef();

  let calendarApi = null;

  function onChangeSearchAgenda(e) {
    const searchAgenda = e.target.value;
    setSearchAgenda(searchAgenda);
  }

  function getWindowWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  async function VistaAgendaTurnos(Coe, Fecha, newView = null) {
    const params =
      "centro_id=" +
      selectedClinica.id +
      "&especialidad_id=" +
      selectedEspecialidad.id +
      "&odontologo_id=" +
      selectedOdontologo.id +
      "&fecha_desde=" +
      desde +
      "&fecha_hasta=" +
      hasta;
    // Fecha;
    setSelectedTurnos([]);
    crypto.encryptDataStorage("agmuselectedturnos", []);
    setLoading(true);
    let result = await ModeloGetUrl(
      "centro-odontologo-especialidad",
      null,
      "parte-diario-web",
      params
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        crypto.encryptDataStorage("agmudataevents", result.listado);
        agregarEventosAgendasMultiples(
          result.listado,
          crypto.decryptDataStorage("agmuactualview")
        );
      }
    } else {
      setModalMessage(result.mensaje);
      setModalInformationOpen(true);
    }
    return;
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };
  const hideDentistModal = () => {
    setModalDentistOpen(false);
  };

  function modaldentist() {
    setModalDentistOpen(true);
  }

  function limpiarAgenda() {
    setModalMessage(null);
    setModalErrorOpen(false);
    setModalInformationOpen(false);
    setSelectedTurnos([]);
    sessionStorage.removeItem("agmuallagendas");
    sessionStorage.removeItem("agmudataevents");
    sessionStorage.removeItem("agmueventturnos");
    sessionStorage.removeItem("agmuhiddendays");
    sessionStorage.removeItem("agmucalendarduration");
    sessionStorage.removeItem("agmucurrentcalendarday");
    sessionStorage.removeItem("agmucurrentagenda");
    sessionStorage.removeItem("agmudataOdontologo");
    if (calendarRef.current !== null) {
      let calendarApi = calendarRef.current.getApi();
      crypto.encryptDataStorage("agmuactualview", "Agenda");
      calendarApi.changeView("Agenda", Date);
    }
  }

  function multiFilter(array, filters) {
    let filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return array.filter((item) =>
      filterKeys.every((key) => filters[key].indexOf(item[key]) !== -1)
    );
  }

  const handledatesSet = (dateInfo) => {
    let newDate = dateInfo.start.toISOString().slice(0, 10);
    setInitialCalendarDay(newDate);
    crypto.encryptDataStorage("agmucurrentcalendarday", newDate);
    let lcOldView = crypto.decryptDataStorage("agmuactualview");
    if (calendarRef.current !== null) {
      calendarApi = calendarRef.current.getApi();

      //
      //   let events = calendarApi.getEventSources();
      //   if (events !== null) {
      //      events.forEach((event) => {
      //        event.remove();
      //       });
      //     }
      let lcView = calendarApi.view.type;
      crypto.encryptDataStorage("agmuactualview", lcView);
      calendarApi.refetchEvents();
      if (eventosTurnos !== null) {
        agregarEventosAgendasMultiples(
          crypto.decryptDataStorage("agmudataevents"),
          lcView
        );
      }
    } else {
      if (eventosTurnos !== null) {
        agregarEventosAgendasMultiples(
          crypto.decryptDataStorage("agmudataevents"),
          crypto.decryptDataStorage("agmuactualview")
        );
      }
    }
  };

  const handleAsignarTurnos = () => {
    if (currentpacpreid === null) {
      setModalMessage("Seleccione un paciente.");
      setModalInformationOpen(true);
      return;
    }
    if (selectedTurnos.length > 0) {
      const data = selectedTurnos;

      for (let i = 0; i < data.length; i++) {
        if (data[i].estado !== "libre") {
          setModalMessage("Algunos turnos NO están libres.");
          setModalInformationOpen(true);
          return;
        }
      }
      setTipoTurno("T");
      setAsignarTurnosId(selectedTurnos);
      setAsignarTurnos(true);
    } else {
      setModalMessage("Seleccione al menos un turno.");
      setModalInformationOpen(true);
    }
  };

  const handleAsignarEntreTurnos = () => {
    if (currentpacpreid === null) {
      setModalMessage("Seleccione un paciente.");
      setModalInformationOpen(true);
      return;
    }
    if (selectedTurnos.length === 1) {
      const data = selectedTurnos;

      if (data[0].tipo_turno !== "T") {
        setModalMessage("Debe seleccionar un turno.");
        setModalInformationOpen(true);
        return;
      }
      setTipoTurno("E");
      setAsignarTurnosId(selectedTurnos);
      setAsignarTurnos(true);
    } else {
      setModalMessage("Seleccione solo un turno.");
      setModalInformationOpen(true);
    }
  };

  function DesAsignarTurnos() {
    if (selectedTurnos.length > 0) {
      const data = selectedTurnos;
      for (let i = 0; i < data.length; i++) {
        if (data[i].estado === "libre") {
          setModalMessage("Algunos turnos están libres.");
          setModalInformationOpen(true);
          return;
        }
      }
      setModalMessage(
        "Confirma la liberación de " +
          data.length.toString() +
          (data.length === 1 ? " turno. ?" : " turnos. ?")
      );
      setShowConfirmationModal(true);
    } else {
      setModalMessage("Seleccione al menos un turno.");
      setModalInformationOpen(true);
    }
  }

  async function handleDesAsignarTurnos() {
    hideConfirmationModal();
    let allOk = true;
    let errormessage = "";
    if (selectedTurnos.length > 0) {
      const data = selectedTurnos;
      for (let i = 0; i < data.length; i++) {
        if (data[i].estado === "libre") {
          setModalMessage("Algunos turnos están libres.");
          setModalInformationOpen(true);
          return;
        }
      }
      for (let i = 0; i < selectedTurnos.length; i++) {
        setLoading(true);

        let result = await ModeloPost("turno", data[i].id, "liberar", null);
        setLoading(false);
        if (result.error === true) {
          allOk = false;
          errormessage += " " + result.mensaje;
        }
      }

      if (allOk) {
        VistaAgendaTurnos();
      } else {
        setModalMessage(errormessage);
        setModalErrorOpen(true);
      }
    } else {
      setModalMessage("Seleccione al menos un turno.");
      setModalInformationOpen(true);
    }
  }

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
    setModalMessage(null);
  };

  const handleOnChange = () => {
    setEventosTurnos(null);
    setEventosOdontologo(null);
    setRenderCalendar(false);
    sessionStorage.removeItem("agmuallagendas");
    sessionStorage.removeItem("agmudataevents");
    sessionStorage.removeItem("agmueventturnos");
    sessionStorage.removeItem("agmuhiddendays");
    sessionStorage.removeItem("agmucalendarduration");
    sessionStorage.removeItem("agmucurrentcalendarday");
    sessionStorage.removeItem("agmucurrentagenda");
    sessionStorage.removeItem("agmudataOdontologo");
  };

  async function handleClickBuscar() {
    if (
      crypto.decryptDataStorage("agmuselectedclinica") == null ||
      crypto.decryptDataStorage("agmuselectedespecialidad") == null ||
      crypto.decryptDataStorage("agmuselectedodontologo") == null
    ) {
      setModalMessage("Seleccione clínica, especialidad y odontólogo.");
      setModalInformationOpen(true);
      return;
    }
    limpiarAgenda();
    if (calendarRef.current !== null) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(moment(Today).format("YYYY-MM-DD"));
    }
    ausencias = [];
    eventosTurnos = [];
    var temp = [...eventosTurnos];
    setEventosTurnos(temp);
    hiddenDays = [];
    var tempHD = [...hiddenDays];
    setHiddendays(tempHD);
    setDurationDays(7);
    setLoading(true);
    let params =
      "centro_id=" +
      selectedClinica.id +
      "&especialidad_id=" +
      selectedEspecialidad.id +
      "&odontologo_id=" +
      selectedOdontologo.id +
      "&fecha_desde=" +
      desde +
      "&fecha_hasta=" +
      hasta;
    // Fecha;
    if (selectedDays !== null && selectedDays.length > 0) {
      params += "&dia_semana=" + selectedDays;
    }
    if (selectedHorarios !== null && selectedHorarios.length > 0) {
      params += "&turno=" + selectedHorarios.slice(0, 1);
    }
    setSelectedTurnos([]);
    crypto.encryptDataStorage("agmuselectedturnos", []);
    let result = await ModeloGetUrl(
      "centro-odontologo-especialidad",
      null,
      "parte-diario-web",
      params
    );
    if (result.error === false) {
      if (result.listado.length > 0) {
        setRenderCalendar(true);
        let minValue = result.listado.reduce((acc, turno) => {
          if (acc === null || turno.hora_desde < acc) return turno.hora_desde;
          return acc;
        }, null);
        setMinHora(minValue.substring(0, 2) + ":" + minValue.substring(4, 2));
        crypto.encryptDataStorage(
          "minhora",
          minValue.substring(0, 2) + ":" + minValue.substring(4, 2)
        );
        let maxValue = result.listado.reduce((acc, turno) => {
          if (acc === null || turno.hora_desde > acc) return turno.hora_desde;
          return acc;
        }, null);
        setMaxHora(maxValue.substring(0, 2) + ":" + maxValue.substring(4, 2));
        crypto.encryptDataStorage(
          "maxhora",
          maxValue.substring(0, 2) + ":" + maxValue.substring(4, 2)
        );
        crypto.encryptDataStorage("agmudataevents", result.listado);
        eventosTurnos = result.listado;
        for (let i = 0; i < 7; i++) {
          const tempDay = result.listado.filter(
            (t) => StringToDate(t.fecha_ag).getDay() === i
          );
          if (tempDay.length === 0) {
            hiddenDays.push(i);
          }
        }
      }
      var tempHidden = [...hiddenDays];
      setHiddendays(tempHidden);
      //formula dias = 5 / cantidad de agendas semanales * 7
      let agSemanales = 7 - tempHidden.length;
      let duration = Math.floor((5 / agSemanales) * 7);
      if (duration < 7) {
        duration = 7;
      }
      setDurationDays(duration);
      crypto.encryptDataStorage("agmuhiddendays", tempHidden);
      crypto.encryptDataStorage("agmucalendarduration", duration);
      const paramAusencias = "desde=" + desde + "&hasta=" + hasta;
      let resultAus = await ModeloGetUrl(
        "odontologo",
        selectedOdontologo.id,
        "ausencia-odontologo-web",
        paramAusencias
      );
      setLoading(false);
      if (resultAus.error === false) {
        if (resultAus.listado.length > 0) {
          for (let i = 0; i < resultAus.listado.length; i++) {
            let ausDesde = resultAus.listado[i]["fecha_desde"];
            let ausHasta = resultAus.listado[i]["fecha_hasta"];
            let tempString = "";
            while (ausDesde <= ausHasta) {
              ausencias.push(ausDesde);
              tempString += ausDesde;
              //var tempDate = new Date(ausDesde + " ");
              var tempDate = StringToDate(ausDesde);
              ausDesde = moment(tempDate).add(1, "days").format("YYYY-MM-DD");
            }
          }
        }
      }
      var tempAus = [...ausencias];
      setAusencias(tempAus);
      crypto.encryptDataStorage("allausencias", tempAus);
      agregarEventosAgendasMultiples(result.listado, "Agenda");
    } else {
      setLoading(false);
      setModalMessage(result.mensaje);
      setModalInformationOpen(true);
    }
    return;
  }

  const gopatient = (turnoid) => {
    const result = crypto
      .decryptDataStorage("agmudataevents")
      .find((turno) => turno.turno_id.toString() === turnoid.toString());
    crypto.encryptDataStorage("searchpatient", result.paciente_id);
    history.push("/pacientes");
  };

  function agregarEventosAgendasMultiples(data, pView) {
    setLoading(true);
    conTurnos = [];
    sinTurnos = [];
    bloqueados = [];
    eventosTurnos = [];
    var tempEv = [...eventosTurnos];
    setEventosTurnos(tempEv);
    let View = pView;
    if (typeof View === "undefined" || pView !== "listDay") {
      View = "Agenda";
    }
    const SelectedTurnos =
      typeof crypto.decryptDataStorage("agmuselectedturnos") !== "undefined" &&
      crypto.decryptDataStorage("agmuselectedturnos") !== null
        ? crypto.decryptDataStorage("agmuselectedturnos")
        : [];
    if (data !== null) {
      for (let i = 0; i < data.length; i++) {
        //
        if (data[i].habilitada == "1") {
          if (data[i].turnos === "TRUE") {
            conTurnos.push(data[i].fecha.toString());
          } else {
            sinTurnos.push(data[i].fecha.toString());
          }
        } else {
          bloqueados.push(data[i].fecha_ag);
        }
        var row = {};
        let check = "";
        const result = SelectedTurnos.find(
          (turno) => turno.id === data[i].turno_id.toString()
        );
        if (typeof result === "undefined") {
          check =
            "<i class='fa-regular fa-square fa-xl text-slate-800 mr-2 ml-2'></i></div>  ";
        } else {
          check =
            "<i class='fa-regular fa-square-check fa-xl text-slate-800 mr-2 ml-2'></i></div>  ";
        }
        row["fecha_ag_arg"] = data[i].fecha_ag_arg;
        row["hora_desde"] = data[i].hora_desde;
        row["tipo_turno"] = data[i].tipo_turno;
        if (data[i].estado === "L") {
          row["id"] = data[i].turno_id;
          row["title"] =
            check + "<i class='fa-solid fa-l text-slate-800 mr-2 ml-2'></i>  ";
          row["start"] =
            data[i].fecha_ag +
            " " +
            data[i].hora_desde.substring(0, 2) +
            ":" +
            data[i].hora_desde.substring(2, 4);
          row["end"] =
            data[i].fecha_ag +
            " " +
            data[i].hora_hasta.substring(0, 2) +
            ":" +
            data[i].hora_hasta.substring(2, 4);
          row["className"] =
            "justify-center items-center pl-1 capitalize cursor-pointer text-base text-slate-500 font-regular  ";

          if (data[i].habilitada == "1") {
            row["color"] = "#d1fae5";
            row["borderColor"] = "#10b981";
          } else {
            row["color"] = "#fee2e2";
            row["borderColor"] = "#fca5a5";
          }

          row["description"] = "libre";
        } else {
          if (View !== null && View === "Agenda") {
            let motivo =
              data[i].motivo !== "" && data[i].motivo !== null
                ? data[i].tipo_turno === "T"
                  ? data[i].motivo.toLowerCase()
                  : data[i].motivo.toLowerCase().slice(0, 5)
                : "";
            let piezas =
              data[i].piezas !== null && data[i].piezas !== ""
                ? "  <i class='fa-solid fa-tooth text-pink-600 fa-md'></i> " +
                  data[i].piezas
                : "";
            row["id"] = data[i].turno_id;
            row["title"] = check + motivo + piezas;
            row["className"] =
              "justify-center items-center pl-1 capitalize cursor-pointer text-sm text-slate-500   ";
          } else {
            let tipo_turno = "";
            if (data[i].tipo_turno === "T") {
              tipo_turno =
                "<i class='fa-solid fa-t text-sky-600 mr-2 ml-2'></i> ";
            } else {
              tipo_turno =
                "<i class='fa-solid fa-e text-rose-500 mr-2 ml-2'></i> ";
            }
            let estado_turno = "";
            if (data[i].estado_turno === "L") {
              estado_turno =
                "<i class='fa-solid fa-circle text-emerald-600 mr-2 ml-2'></i> ";
            } else if (data[i].estado_turno === "AT") {
              estado_turno =
                "<i class='fa-solid fa-circle-user text-emerald-600 mr-2 ml-2'></i> ";
            } else if (data[i].estado_turno === "AU") {
              estado_turno =
                "<i class='fa-solid fa-circle-user text-rose-600 mr-2 ml-2'></i> ";
            } else if (data[i].estado_turno === "SE") {
              estado_turno =
                "<i class='fa-solid fa-circle-user text-amber-500 mr-2 ml-2'></i> ";
            } else if (data[i].estado_turno === "CO") {
              estado_turno =
                "<i class='fa-solid fa-circle-user text-sky-600 mr-2 ml-2'></i> ";
            } else if (data[i].estado_turno === "TF") {
              estado_turno =
                "<i class='fa-solid fa-circle-user text-slate-400 mr-2 ml-2'></i>";
            }
            let nombre =
              data[i].nombre !== null ? data[i].nombre.toLowerCase() : "";
            let motivo =
              data[i].motivo !== "" && data[i].motivo !== null
                ? "  <i class='fa-solid fa-circle-info text-sky-600 mr-2 ml-2'></i> " +
                  data[i].motivo.toLowerCase()
                : "";
            let piezas =
              data[i].piezas !== null && data[i].piezas !== ""
                ? "  <i class='fa-solid fa-tooth text-orange-500 mr-2 ml-2'></i> " +
                  data[i].piezas
                : "";
            let derivador =
              data[i].derivado_por !== null && data[i].derivado_por !== ""
                ? "  <i class='fa-solid fa-user-doctor text-purple-500 mr-2 ml-2'></i> " +
                  data[i].derivado_por.toLowerCase()
                : "";
            let observaciones =
              data[i].obs_turno !== null && data[i].obs_turno !== ""
                ? "  <i class='fa-solid fa-comment text-emerald-500 mr-2 ml-2'></i> " +
                  data[i].obs_turno
                : "";
            let fuera_agenda =
              "<i class='fa-solid fa-circle text-gray-100 mr-2 ml-2'></i> ";
            if (data[i].fuera_de_agenda.toString().toLowerCase() === "si") {
              fuera_agenda =
                "<i class='fa-solid fa-asterisk text-red-700 mr-2 ml-2'></i>";
            }
            row["id"] = data[i].turno_id;
            row["title"] =
              check +
              tipo_turno +
              fuera_agenda +
              estado_turno +
              nombre +
              " (" +
              data[i].tipo_documento +
              " " +
              data[i].nro_documento +
              ")" +
              motivo +
              piezas +
              derivador +
              observaciones +
              "</div>";
            row["className"] =
              "justify-center items-center pl-1 capitalize cursor-pointer text-base text-slate-700 font-regular  ";
          }
          row["start"] =
            data[i].fecha_ag +
            " " +
            data[i].hora_desde.substring(0, 2) +
            ":" +
            data[i].hora_desde.substring(2, 4);
          row["end"] =
            data[i].fecha_ag +
            " " +
            data[i].hora_hasta.substring(0, 2) +
            ":" +
            data[i].hora_hasta.substring(2, 4);
          row["textColor"] = "#334155";

          if (data[i].tipo_turno === "T") {
            row["borderColor"] = "#0ea5e9";
            row["color"] = "#e0f2fe";
            row["textColor"] = "#334155";
            row["description"] = "turno";
          } else {
            row["borderColor"] = "#e11d48";
            row["color"] = "#fecaca";
            row["textColor"] = "#334155";
            row["description"] = "entreturno";
          }
        }
        eventosTurnos.push(row);
      }
      var tempCt = [...conTurnos];
      setConTurnos(tempCt);
      var tempSt = [...sinTurnos];
      setSinTurnos(tempSt);
      var tempBl = [...bloqueados];
      setBloqueados(tempBl);
      //
      //
      //
    }
    var tempEvTurnos = [...eventosTurnos];
    setEventosTurnos(tempEvTurnos);
    crypto.encryptDataStorage("agmueventturnos", eventage);
    //if (newView !== null) {
    //  crypto.encryptDataStorage("actualview", "listDay");
    //   calendarApi.changeView("listDay", Fecha);
    // }
    if (calendarRef.current !== null) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.refetchEvents();
    }
    setLoading(false);
  }

  function agregarEventosOdontologo(data1, OdontologoId) {
    setLoading(true);
    const data = data1;
    eventodo = [];
    if (data !== null) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].habilitado_turnos == "1") {
          var row = {};
          let libres = "";
          let clinica = "";
          let odontologo = "";
          //const arr_apellido = data[i].odo_apellido.split(" ");
          //const arr_nombres = data[i].odo_nombres.split(" ");
          //const apellido = arr_apellido[0];
          //const nombres = arr_nombres[0];
          const apellido = data[i].odo_apellido;
          const nombres = data[i].odo_nombres;
          const avatar =
            "fa-regular fa-" + data[i].centro.substring(0, 1).toLowerCase();
          if (data[i].turnos === "TRUE") {
            libres =
              "<span class='fa-stack fa-md ' ><i class='fa-solid fa-circle text-emerald-600 fa-stack-2x'></i><i class='" +
              avatar +
              " text-white fa-stack-1x font-bold text-lg -mt-0.5'></i></span>";
          } else {
            libres =
              "<span class='fa-stack fa-md ' ><i class='fa-solid fa-circle text-amber-600 fa-stack-2x'></i><i class='" +
              avatar +
              " text-white fa-stack-1x font-bold text-lg -mt-0.5'></i></span>";
          }
          if (data[i].centro.substring(8, 11) === "Aya") {
            clinica =
              "<span class='fa-stack fa-xs' ><i class='fa-solid fa-circle text-sky-600 fa-stack-2x'></i><i class='fa-regular fa-a text-white fa-stack-1x'></i></span>";
          } else {
            clinica =
              "<span class='fa-stack fa-xs'><i class='fa-solid fa-circle text-pink-600 fa-stack-2x'></i><i class='fa-regular fa-b text-white fa-stack-1x'></i></span>";
          }
          if (windowWidth < 768) {
            row["title"] = libres;
          } else if (windowWidth >= 768 && windowWidth < 1280) {
            odontologo =
              "<p class='ml-1 text-sm'>" + CapSentence(apellido) + "</p>";
            row["title"] = libres + odontologo;
          } else if (windowWidth >= 1280 && windowWidth < 1360) {
            odontologo =
              "<p class='ml-1 text-sm'>" +
              CapSentence(nombres).slice(0, 1) +
              ". " +
              CapSentence(apellido) +
              "</p>";
            row["title"] = libres + odontologo;
          } else if (windowWidth >= 1360) {
            odontologo =
              "<p class='ml-1 text-sm'>" +
              CapSentence(nombres) +
              " " +
              CapSentence(apellido) +
              "</p>";
            row["title"] = libres + odontologo;
          }
          //row["id"] = data[i].centro_id + ';' + data[i].especialidad_id + ';' + data[i].odontologo_id;
          row["id"] = i;
          row["start"] =
            data[i].fecha.toString() +
            "T" +
            data[i].horario_desde.substring(0, 2) +
            ":" +
            data[i].horario_desde.substring(2, 4);
          row["end"] =
            data[i].fecha.toString() +
            "T" +
            data[i].horario_hasta.substring(0, 2) +
            ":" +
            data[i].horario_hasta.substring(2, 4);
          row["className"] = "cursor-pointer";
          row["borderColor"] = "transparent";
          row["textColor"] = "black";
          row["description"] =
            nombres + " " + apellido + "-" + data[i].especialidad;
          eventodo.push(row);
        } else {
          var row = {};
          let lnChars = 0;
          if (windowWidth >= 900 && windowWidth < 1280) {
            lnChars = 10;
          } else if (windowWidth >= 1280 && windowWidth < 1360) {
            lnChars = 15;
          } else if (windowWidth >= 1360) {
            lnChars = 30;
          }
          const observaciones =
            data[i].observaciones !== null
              ? data[i].observaciones.length <= lnChars
                ? data[i].observaciones
                : data[i].observaciones.slice(0, lnChars)
              : null;
          row["id"] = "-1";
          if (windowWidth >= 900) {
            row["title"] =
              observaciones !== null
                ? "<i class='fa-solid fa-ban fa-lg text-white font-bold text-lg mr-2 ml-2'></i>" +
                  observaciones
                : "";
          } else {
            row["title"] =
              observaciones !== null
                ? "<i class='fa-solid fa-ban fa-lg text-white font-bold text-lg mr-2 ml-2'></i>"
                : "";
          }
          row["start"] = data[i].fecha;
          row["end"] = data[i].fecha;
          row["color"] = "#dc2626";
          row["textColor"] = "white";
          eventodo.push(row);
        }
      }
    }
    setEventosOdontologo(eventodo);
    setLoading(false);
  }

  function renderEventContent(eventInfo) {
    return { html: eventInfo.event.title };
  }

  const handleDateClick = (arg) => {
    // bind with an arrow function
    if (currentOdontologo !== null) {
    }
  };

  const handleDayLink = (Date) => {
    if (calendarRef.current !== null) {
      let calendarApi = calendarRef.current.getApi();
      crypto.encryptDataStorage("agmuactualview", "listDay");
      calendarApi.changeView("listDay", Date);
      agregarEventosAgendasMultiples(
        crypto.decryptDataStorage("agmudataevents"),
        "listDay"
      );
    }
  };

  function HandleEventClick(info) {
    if (info.event.id >= "0") {
      // setSelectedTurnos([]);
      if (calendarRef.current !== null) {
        calendarApi = calendarRef.current.getApi();
        let lcView = calendarApi.view.type;
        let EventDate = moment(info.event.start).format("YYYY-MM-DD");
        setInitialCalendarDay(EventDate);
        crypto.encryptDataStorage("agmuactualview", lcView);
        let arrTurnos = selectedTurnos;
        const result = selectedTurnos.find(
          (turno) => turno.id === info.event.id.toString()
        );
        if (typeof result === "undefined") {
          const addTurno = {
            id: info.event.id,
            estado: info.event.extendedProps.description,
            fecha_ag_arg: info.event.extendedProps.fecha_ag_arg,
            hora_desde: info.event.extendedProps.hora_desde,
            tipo_turno: info.event.extendedProps.tipo_turno,
          };
          if (selectedClinica !== null) {
            addTurno["clinica_razon"] = selectedClinica.razonsocial;
            addTurno["clinica_domicilio"] = selectedClinica.domicilio;
            addTurno["clinica_localidad"] = selectedClinica.localidad;
          } else {
            addTurno["clinica_razon"] = "";
            addTurno["clinica_domicilio"] = "";
            addTurno["clinica_localidad"] = "";
          }
          if (selectedEspecialidad !== null) {
            addTurno["especialidad_id"] = selectedEspecialidad.id;
            addTurno["especialidad"] = selectedEspecialidad.especialidad;
          } else {
            addTurno["especialidad_id"] = "";
            addTurno["especialidad"] = "";
          }
          if (selectedOdontologo !== null) {
            addTurno["odontologo_id"] = selectedOdontologo.id;
            addTurno["odontologo"] = selectedOdontologo.odontologo;
          } else {
            addTurno["odontologo_id"] = "";
            addTurno["odontologo"] = "";
          }
          arrTurnos.push(addTurno);
          setSelectedTurnos(arrTurnos);
          crypto.encryptDataStorage("agmuselectedturnos", arrTurnos);
        } else {
          const newArr = arrTurnos.filter(
            (turno) => turno.id !== info.event.id.toString()
          );
          setSelectedTurnos(newArr);
          crypto.encryptDataStorage("agmuselectedturnos", newArr);
        }
        agregarEventosAgendasMultiples(
          crypto.decryptDataStorage("agmudataevents"),
          lcView
        );
      }
    }
    //   }
  }

  let eventodo = [];
  let eventesp = [];
  let eventage = [];

  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"VISUALIZAR AGENDAS"}
    >
      <>
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            marginLeft: 4,
            marginTop: 4,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Se requiere una resolución mínima de 900 px.
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <div>
            <div className="mt-6 ">
              <PacienteHeader collapse={false} />
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <CoeAutocomplete
                selectedClinica={selectedClinica}
                selectedEspecialidad={selectedEspecialidad}
                selectedOdontologo={selectedOdontologo}
                setSelectedClinica={setSelectedClinica}
                setSelectedEspecialidad={setSelectedEspecialidad}
                setSelectedOdontologo={setSelectedOdontologo}
                handlebusqueda={null}
                loading={loading}
                stClinica="agmuselectedclinica"
                stEspecialidad="agmuselectedespecialidad"
                stOdontologo="agmuselectedodontologo"
                handleOnChange={handleOnChange}
                stEspecialidadesLista="agmEspecialidadesLista"
                stOdontologosLista="agmOdontologosLista"
              />
              <Autocomplete
                value={selectedDays}
                size="small"
                onChange={(event, newValue) => {
                  setSelectedDays(newValue);
                }}
                inputValue={inputDay}
                onInputChange={(event, newInputValue) => {
                  setInputDay(newInputValue);
                }}
                id="days"
                options={diasSemana}
                sx={{ width: 300, px: 1, py: 0 }}
                renderInput={(params) => <TextField {...params} label="Dia" />}
              />
              <Autocomplete
                value={selectedHorarios}
                size="small"
                onChange={(event, newValue) => {
                  setSelectedHorarios(newValue);
                }}
                inputValue={inputHorario}
                onInputChange={(event, newInputValue) => {
                  setInputHorario(newInputValue);
                }}
                id="horarios"
                options={horarios}
                sx={{ width: 150, px: 1, py: 0 }}
                renderInput={(params) => (
                  <TextField {...params} label="Horario" />
                )}
              />
              <LoadingButton
                onClick={handleClickBuscar}
                color="primary"
                loading={loading}
                loadingPosition="end"
                endIcon={<SearchIcon />}
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  textTransform: "capitalize",
                  marginTop: 0,
                  height: 40,
                }}
              ></LoadingButton>
            </Box>

            {currentEspecialidad !== null && (
              <div className="h-auto border-2 rounded-lg ml-2 mb-1 flex items-center justify-start text-sm max-w-sm shadow-sm capitalize sm:text-base">
                <img className="p-2 mr-1" src={Dental} />
                <h1>{currentEspecialidad.especialidad.toLowerCase()}</h1>
              </div>
            )}
            {currentOdontologo !== null && (
              <div className="h-auto border-2 rounded-lg ml-2 mb-1 flex items-center justify-start text-sm max-w-sm shadow-sm capitalize sm:text-base">
                <img
                  className="cursor-pointer p-2 mr-1"
                  src={Dentist}
                  onClick={modaldentist}
                />
              </div>
            )}

            {!loading &&
              renderCalendar &&
              (eventosTurnos !== null || eventosOdontologo !== null) && (
                <>
                  <Paper
                    elevation={2}
                    sx={{
                      ml: 2,
                      mr: 2,
                      mb: 2,
                      backgroundColor: "#f5f5f4",
                      py: 2,
                    }}
                  >
                    <div className="  ml-3 mr-3 mb-3">
                      <FullCalendar
                        ref={calendarRef}
                        initialDate={initialCalendarDate}
                        id="cal_odo"
                        height="auto"
                        selectMirror="true"
                        titleFormat={{
                          day: "numeric",
                          year: "numeric",
                          month: "short",
                        }}
                        headerToolbar={{
                          left:
                            currentpacpreid === null
                              ? "prev,next,today,D"
                              : "prev,next,today,A,E,D",

                          center: "title",
                          right: "Agenda",
                        }}
                        views={{
                          Agenda: {
                            type: "timeGrid",
                            duration: { days: durationsDays, firstDay: 1 },
                          },
                        }}
                        customButtons={{
                          ...(hasPermission(userRoles, "ASIGNAR TURNOS") && {
                            A: {
                              text: "Turno",
                              click: function () {
                                handleAsignarTurnos();
                              },
                            },
                          }),
                          ...(hasPermission(
                            userRoles,
                            "ASIGNAR ENTRETURNOS"
                          ) && {
                            E: {
                              text: "Entreturno",
                              click: function () {
                                handleAsignarEntreTurnos();
                              },
                            },
                          }),
                          ...(hasPermission(userRoles, "DESASIGNAR TURNOS") && {
                            D: {
                              text: "Desasignar",
                              click: function () {
                                DesAsignarTurnos();
                              },
                            },
                          }),
                        }}
                        hiddenDays={hiddenDays}
                        eventTimeFormat={{
                          hour: "2-digit",
                          minute: "2-digit",
                          omitZeroMinute: false,
                          meridiem: "narrow",
                        }}
                        buttonText={{
                          today: "Hoy",
                          month: windowWidth >= 460 ? "Calendario" : "Cal",
                          week: "Semana",
                          day: "Dia",
                          list: "Lista",
                        }}
                        navLinks="true"
                        timeZone="UTC-3"
                        eventOrder="start"
                        events={eventosTurnos}
                        eventOverlap="false"
                        eventContent={renderEventContent}
                        datesSet={handledatesSet}
                        locale={esLocale}
                        plugins={[
                          dayGridPlugin,
                          interactionPlugin,
                          timeGridPlugin,
                          listPlugin,
                        ]}
                        dateClick={handleDateClick}
                        initialView={crypto.decryptDataStorage(
                          "agmuactualview"
                        )}
                        eventClick={HandleEventClick}
                        navLinkDayClick={function (date, jsEvent) {
                          handleDayLink(date);
                        }}
                        slotMinTime={minHora}
                        slotMaxTime={maxHora}
                        slotDuration="00:10:00"
                        timeFormat="h:mm"
                        eventDisplay="auto"
                        themeSystem="bootstrap5"
                        displayEventTime={showEventTime}
                        firstDay={1}
                        eventDidMount={function (info) {
                          const eventId = info.event.id;
                          info.el.addEventListener("contextmenu", (jsEvent) => {
                            jsEvent.preventDefault();
                            gopatient(eventId);
                          });
                          info.el.className =
                            info.el.className + " context-menu-one";
                          info.el.addEventListener("dblclick", (jsEvent) => {
                            jsEvent.preventDefault();
                            gopatient(eventId);
                          });
                        }}
                        dayCellDidMount={function (info) {
                          var date = info.date.toISOString().slice(0, 10);
                          var el = info.el;
                          if (
                            info.isDisabled === false &&
                            feriados.indexOf(date) >= 0
                          ) {
                            el.style.backgroundColor = "#ede9fe";
                            el.style.color = "#fff";
                          }
                          if (
                            info.isDisabled === false &&
                            ausencias.indexOf(date) >= 0 &&
                            feriados.indexOf(date) < 0
                          ) {
                            el.style.background =
                              "repeating-linear-gradient(45deg,#ddd6fe,#ddd6fe 4px,#f1f5f9 4px,#f1f5f9 8px";
                          }
                        }}
                        dayHeaderDidMount={function (info) {
                          var date = info.date.toISOString().slice(0, 10);
                          var el = info.el;
                          el.style.backgroundColor = "#d6d3d1";
                          el.style.fontSize = "1em";
                          el.style.fontWeight = "bold";
                          if (
                            info.isDisabled === false &&
                            feriados.indexOf(date) >= 0
                          ) {
                            el.style.backgroundColor = "#a78bfa";
                            el.style.color = "#fff";
                          }
                          if (
                            info.isDisabled === false &&
                            ausencias.indexOf(date) >= 0 &&
                            feriados.indexOf(date) < 0
                          ) {
                            el.style.background =
                              "repeating-linear-gradient(45deg,#a78bfa,#a78bfa 4px,#FFFFFF 4px,#FFFFFF 8px";
                          }
                          if (
                            info.isDisabled === false &&
                            bloqueados.indexOf(date) >= 0
                          ) {
                            el.style.backgroundColor = "#fee2e2";
                            //el.style.color = "#fff";
                          }
                          if (
                            info.isDisabled === false &&
                            bloqueados.indexOf(date) >= 0
                          ) {
                            el.style.backgroundColor = "#ef4444";
                            el.style.color = "#fff";
                          }
                        }}
                        //datesSet={function (info) {
                        //   alert(info.start);
                        //   alert(info.end);
                        //  }}
                      />
                    </div>
                  </Paper>
                </>
              )}
            {showAlert !== null && (
              <div className="flex items-center justify-center mt-5 ml-12">
                <Alert variant="primary">{showAlert}</Alert>
              </div>
            )}
          </div>
          <ModalInformation
            showModal={modalInformationOpen && modalMessage !== null}
            hideModal={hideInformationModal}
            message={modalMessage}
          />
          <ModalError
            showModal={modalErrorOpen && modalMessage !== null}
            hideModal={hideErrorModal}
            message={modalMessage}
          />
          {asignarTurnos &&
            crypto.decryptDataStorage("agmuselectedturnos") !== null &&
            crypto.decryptDataStorage("agmuselectedturnos").length > 0 && (
              <ModalAsignarTurnos
                turnos={selectedTurnos}
                tipoTurno={tipoTurno}
                showModal={asignarTurnos}
                hideModal={() => setAsignarTurnos(false)}
                buscaragenda={VistaAgendaTurnos}
              />
            )}
          <ModalConfirmation
            showModal={showConfirmationModal}
            confirmModal={handleDesAsignarTurnos}
            hideModal={hideConfirmationModal}
            message={modalMessage}
          />
        </Box>
      </>
    </ProtectedRoute>
  );
};

export default AgendasMultiples;
