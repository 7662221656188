import React, { createContext, useState, useEffect } from "react";
import crypto from "../Crypto";
import { useLocation } from "react-router-dom";

const TopBarContext = createContext();

const TopBarProvider = ({ children }) => {
  const [activeButton, setActiveButton] = useState(null);

  const changeButton = (button) => {
    setActiveButton(button);
    crypto.encryptLocalStorage("activebutton", button);
  };

  const data = { activeButton, changeButton };

  return (
    <TopBarContext.Provider value={data}>{children}</TopBarContext.Provider>
  );
};

export { TopBarProvider };
export default TopBarContext;
