import React, { useState } from 'react';

export default function CuadranteStd2Std(data) {
    const [testOdo, setTestOdo] = useState(true);

    const getSourceImage = (dienteimage) => {
        let sourceimage = '/iconos_odontograma/' + dienteimage
        return sourceimage
    }


    return (
        <div className="flex ml-1">
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">21</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 -mt-2" : "absolute mr-1" } src={item.diente==="21" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">22</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 -mt-2" : "absolute mr-1" } src={item.diente==="22" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">23</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 -mt-2" : "absolute mr-1" } src={item.diente==="23" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">24</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 -mt-2" : "absolute mr-1" } src={item.diente==="24" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">25</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 -mt-2" : "absolute mr-1" } src={item.diente==="25" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">26</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 -mt-2" : "absolute mr-1" } src={item.diente==="26" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">27</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 -mt-2" : "absolute mr-1" } src={item.diente==="27" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>
            <div className="flex">
                <img className="mr-1" src={getSourceImage("diente.png")} />
                <p className="absolute -mt-6 ml-2">28</p>
                {(data.data.length > 0) &&
                    data.data.map((item) => {
                        return (
                            < img className={item.imagen.search("periodoncia") > 0 ? "absolute mr-1 -mt-2" : "absolute mr-1" } src={item.diente==="28" ? getSourceImage(item.imagen) : ""} />
                        );
                    })
                }
            </div>

        </div>
    );
}
