import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import "../../assetss/css/EditarPaciente.css";
import "../../assetss/css/MyTypeahead.css";
import ModalError from "../modals/ModalError";
import ModalInformation from "../modals/ModalInformation";
import { ModeloGetUrlJson, ModeloPost, ModeloUpdate } from "../ModeloCrud";
import TextField from "@mui/material/TextField";
import crypto from "../Crypto";
import {
  CapSentenceArray,
  TraduccionError,
  FormikValidatePhone,
  hasPermission,
  CapSentence,
  BpRadio,
  SinPermisos,
} from "../utils/Utils";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Autocomplete from "@mui/material/Autocomplete";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import BackPage from "../BackPage";
import SaveCancelButtons from "../SaveCancelButtons";
import PacienteHeader from "./PacienteHeader";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Switch from "@mui/material/Switch";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import FacebookCircularProgress from "../FacebookCircularProgress";
import MultipleResultsTable from "../MultipleResultsTable";
import PadronesBusquedaAvanzada from "./PadronesBusquedaAvanzada";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CustomizedSearch from "../CustomizedSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import ProtectedRoute from "../ProtectedRoute";

const InsertUpdatePaciente = () => {
  const [allPacientes, setAllPacientes] = useState(null);
  let [filterPacientes, setFilterPacientes] = useState(null);
  const [modalPacientesOpen, setModalPacientesOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [submitOk, setSubmitOk] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [formTitle, setFormTitle] = useState("Paciente nuevo");
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState([]);
  const [allEmpresas, setAllEmpresas] = useState([]);
  const Today = new Date();
  const $ = require("jquery");
  // const Linqer = require('@siderite/linqer');
  const refPlan = React.createRef();
  const fotopaciente = crypto.decryptDataStorage("dataFoto");
  const tablafull = crypto.decryptDataStorage("tablafull");
  const [isAlta, setIsAlta] = useState(true);
  const [pacienteId, setPacienteId] = useState(null);
  const [padronCodigo, setPadronCodigo] = useState(null);
  const [padronPlan, setPadronPlan] = useState(null);
  const documentos =
    tablafull !== null
      ? tablafull
          .filter((obj) => obj.codigo_tabla === "TIPOS_DOCUMENTO")
          .map((obj) => ({ codigo: obj.valor, descripcion: obj.descripcion }))
      : [];
  const [allPrepagas, setAllPrepagas] = useState([]);
  const [allPlanes, setAllPlanes] = useState([]);
  const [planesLista, setPlanesLista] = useState([]);
  const [gravadoChecked, setGravadoChecked] = useState(false);
  const [searchPaciente, setSearchPaciente] = useState("");
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const objHeaders = [
    "Ver",
    "Código",
    "Credencial",
    "Documento",
    "Apellido",
    "Nombres",
    "F.Nacimiento",
    "F.Alta",
    "F.Baja",
    "F.Ultimo Mov.",
  ];
  const objFields = [
    "codigo",
    "credencial",
    "nro_doc",
    "apellido",
    "nombres",
    "date_nac",
    "date_asoc",
    "date_baja",
    "ult_mov",
  ];
  const objDates = ["date_nac", "date_asoc", "date_baja", "ult_mov"];
  var cleanMethod = location.cleanMethod;

  useEffect(() => {
    if (typeof location.currentPaciente === "undefined") {
      setIsAlta(true);
      if (typeof cleanMethod === "function") {
        cleanMethod();
      }
      if (typeof location.currentPadron !== "undefined") {
        setPadronCodigo(location.currentPadron.codigo);
        setPadronPlan(location.currentPadron.plan);
        fillInitialValuesPadron(location.currentPadron);
        buscarPrepagas(location.currentPadron.codigo);
      } else {
        buscarPrepagas();
      }
    } else {
      setIsAlta(false);
      setPacienteId(location.currentPaciente.id);
      fillInitialValues(location.currentPaciente);
      buscarPrepagas();
    }
  }, []);

  const [anchorElBa, setAnchorElBa] = React.useState(null);

  const hancleClickBusquedaAvanzada = (event) => {
    setModalPacientesOpen(false);
    setAnchorElBa(event.currentTarget);
  };

  const handleCloseBa = () => {
    setAnchorElBa(null);
  };

  const openBa = Boolean(anchorElBa);
  const idBa = openBa ? "simple-popover" : undefined;

  function resultadoBusquedaAvanzada(result) {
    if (result !== null && result.length > 0) {
      // limpiarPaciente();
      if (result.length === 1) {
        BuscarPadron(result[0].id, true);
      } else if (result.length > 500) {
        setLoading(false);
        setModalMessage(
          "Demasiados resultados(" +
            result.length.toString() +
            ") refine la búsqueda."
        );
        setModalInformationOpen(true);
      } else {
        setLoading(false);
        setAllPacientes(result);
        setFilterPacientes(result);
        setModalPacientesOpen(true);
        setSearchPaciente("");
      }
    }
  }

  $(document).ready(function () {
    var allInputs = $(":text:visible"); //(1)collection of all the inputs I want (not all the inputs on my form)
    $(":text").on("keydown", function (e) {
      //(2)When an input field detects a keydown event
      if (e.keyCode == 13) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) + 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
      if (e.keyCode == 27) {
        e.preventDefault();
        var nextInput = allInputs.get(allInputs.index(this) - 1); //(3)The next input in my collection of all inputs
        if (nextInput) {
          nextInput.focus(); //(4)focus that next input if the input is not null
        }
      }
    });
    /*    if ($('#tipo_doc').length) {
              $('#tipo_doc').focus()
          } */
  });

  function onChangeSearchPaciente(e) {
    const searchPaciente = e.target.value;
    setSearchPaciente(searchPaciente);
    if (modalPacientesOpen) {
      FiltrarPacientes(searchPaciente);
    }
  }
  function cleanPaciente() {
    setIsAlta(true);
    window.location.reload(false);
  }
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      BuscarPadron(searchPaciente, false, null);
    }
  }
  function busqueda() {
    BuscarPadron(searchPaciente, false, null);
  }

  async function BuscarPadron(Busqueda, IsId) {
    setIsAlta(true);
    setFormTitle("Transferencia de pacientes");
    formik.resetForm();
    if (typeof cleanMethod === "function") {
      cleanMethod();
    }
    if (Busqueda.length === 0) {
      return;
    }
    //limpiarPaciente();
    if (!IsId && Busqueda.length < 3) {
      setModalMessage("Ingrese al menos 3 caracteres.");
      setModalInformationOpen(true);
      return;
    }
    let params = "busqueda=" + Busqueda;
    if (IsId) {
      params = params + "&is_id=SI";
    }
    setSearchLoading(true);
    let result = await ModeloGetUrlJson(
      "padron",
      null,
      "busqueda-padron",
      params
    );
    setSearchLoading(false);

    if (result.error === false) {
      if (result.listado.length === 1) {
        setSearchPaciente("");
        fillInitialValuesPadron(result.listado[0]);
        // mostrarPaciente(result, ChangeCodigo);
      } else if (result.listado.length > 1) {
        if (result.listado.length > 500) {
          setModalMessage(
            "Demasiados resultados(" +
              result.listado.length.toString() +
              ") refine la búsqueda."
          );
          setModalInformationOpen(true);
        } else {
          const sortresult = result.listado.sort((a, b) =>
            a.date_baja > b.date_baja ? -1 : 1
          );
          setAllPacientes(sortresult);
          setFilterPacientes(sortresult);
          setModalPacientesOpen(true);
          setSearchPaciente("");
        }
      } else if (result.listado.length === 0) {
        setModalMessage("No se encontraron resultados.");
        setModalInformationOpen(true);
      }
    } else {
      setModalMessage(result.errormessage);
      setModalWarningOpen(true);
    }
    return;
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };
  const hideWarningModal = () => {
    setModalMessage(null);
    setModalWarningOpen(false);
  };

  function handleKeyPress(event) {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  function FiltrarPacientes(filtro) {
    if (filtro.length > 0) {
      filterPacientes = allPacientes.filter(
        (p) =>
          p.apellido.toLowerCase().indexOf(filtro.toLowerCase()) >= 0 ||
          p.nombres.toLowerCase().indexOf(filtro.toLowerCase()) >= 0 ||
          (p.credencial !== null && p.credencial.indexOf(filtro) >= 0) ||
          (p.nro_doc !== null && p.nro_doc.toString().indexOf(filtro) >= 0)
      );
      if (filterPacientes.length <= 0) {
        filterPacientes = allPacientes;
      }
    } else {
      filterPacientes = allPacientes;
    }
    //crypto.encryptDataStorage("hctratamientos", filterData);
    let tempData = [...filterPacientes];
    setFilterPacientes(tempData);
    if (filterPacientes.length > 0) {
    }
  }

  async function buscarPrepagas(pCodigo = null) {
    let result = await ModeloGetUrlJson(
      "prepaga",
      null,
      "vista-prepagas-planes-habilitados",
      null
    );
    setLoading(false);
    if (result.error === false) {
      let tempPrepagas = [...allPrepagas];
      tempPrepagas = result.prepagas;
      let tempPlanes = [...allPlanes];
      tempPlanes = result.planes;
      setAllPrepagas(tempPrepagas);
      setAllPlanes(tempPlanes);
      if (pCodigo !== null) {
        const selprepaga = tempPrepagas.filter((p) => p.codigo === pCodigo);
        if (selprepaga.length > 0) {
          formik.setFieldValue("prepaga", selprepaga[0]);
          const planes = result.planes.filter(
            (g) => g.prepaga_id === selprepaga[0].id
          );
          setPlanesLista(planes);
          if (planes.length === 1) {
            formik.setFieldValue("plan", planes[0]);
          } else if (planes.length > 1) {
            const selPlan = result.planes.filter(
              (g) => g.codigo === padronPlan
            );
            if (selPlan.length > 0) {
              formik.setFieldValue("plan", selPlan[0]);
            }
          }
        }
      }
    }
    return;
  }

  const onPrepagasChange = (event, values) => {
    formik.setFieldValue("prepaga", values);
    formik.setFieldValue("plan", null);
    setPlanesLista([]);
    if (values !== null) {
      const planes = allPlanes.filter((g) => g.prepaga_id === values.id);
      setPlanesLista(planes);
    } else {
    }
  };

  const onPlanesChange = (event, values) => {
    formik.setFieldValue("plan", values);
    if (values !== null) {
    }
  };

  const handleChangeGravadoChecked = (event) => {
    setGravadoChecked(event.target.checked);
  };

  const validationSchemaAlta = yup.object({
    tipo_documento: yup.object().required("Campo Obligatorio."),
    nro_documento: yup
      .string()
      .required("Campo Obligatorio.")
      .matches(/^[0-9]+$/, "Campo numérico")
      .min(5, "mínimo 5 dígitos")
      .max(8, "máxino 8 dígitos")
      .typeError("Campo numérico"),
    apellido: yup
      .string()
      .test(
        "empty-check",
        "El apellido requiere al menos 2 caracteres.",
        (apellido) => apellido.trim().length >= 2
      )
      .max(50, "Máximo 50 caracteres.")
      .required("Campo Obligatorio."),
    nombres: yup
      .string()
      .test(
        "empty-check",
        "El nombre requiere al menos 2 caracteres.",
        (nombres) => nombres.trim().length >= 2
      )
      .max(50, "Máximo 50 caracteres.")
      .required("Campo Obligatorio."),
    fecha_nacimiento: yup.date().nullable().typeError("Fecha inválida"),
    cuit: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]+$/, "Campo numérico")
      .min(10, "mínimo 10 dígitos")
      .max(11, "máxino 1 dígitos")
      .typeError("Campo numérico"),
    sexo: yup.string().required("Seleccione una opción."),
    domicilio: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    localidad: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    telefono: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    telefono2: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    celular: yup
      .string()
      .required("Campo Obligatorio.")
      .test("validator-custom-celular", function (value) {
        const validation = FormikValidatePhone(value);
        if (!validation.isValid) {
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        } else {
          return true;
        }
      }),
    email: yup.string().nullable(true).email("Ingrese un Email válido."),
    prepaga: yup.object().required("Campo Obligatorio"),
    plan: yup.object().required("Campo Obligatorio"),
    credencial: yup
      .string()
      .required("Campo Obligatorio")
      .min(4, "La credencial requiere al menos 4 caracteres")
      .max(30, "La credencial no puede superar los 30 caracteres"),
    falta: yup
      .date()
      .min(new Date("1986-01-01"), "Fecha inválida")
      .nullable()
      .typeError("Fecha inválida")
      .required("Ingrese la fecha de alta"),
  });
  const validationSchemaModificaion = yup.object({
    tipo_documento: yup.object().required("Campo Obligatorio."),
    nro_documento: yup
      .string()
      .required("Campo Obligatorio.")
      .matches(/^[0-9]+$/, "Campo numérico")
      .min(5, "mínimo 5 dígitos")
      .max(8, "máxino 8 dígitos")
      .typeError("Campo numérico"),
    apellido: yup
      .string()
      .test(
        "empty-check",
        "El apellido requiere al menos 2 caracteres.",
        (apellido) => apellido.trim().length >= 2
      )
      .max(50, "Máximo 50 caracteres.")
      .required("Campo Obligatorio."),
    nombres: yup
      .string()
      .test(
        "empty-check",
        "El nombre requiere al menos 2 caracteres.",
        (nombres) => nombres.trim().length >= 2
      )
      .max(50, "Máximo 50 caracteres.")
      .required("Campo Obligatorio."),
    fecha_nacimiento: yup.date().nullable().typeError("Fecha inválida"),
    cuit: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]+$/, "Campo numérico")
      .min(10, "mínimo 10 dígitos")
      .max(11, "máxino 1 dígitos")
      .typeError("Campo numérico"),
    sexo: yup.string().required("Seleccione una opción."),
    domicilio: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    localidad: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    telefono: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    telefono2: yup.string().nullable(true).max(50, "Máximo 50 caracteres."),
    celular: yup
      .string()
      .required("Campo Obligatorio.")
      .test("validator-custom-celular", function (value) {
        const validation = FormikValidatePhone(value);
        if (!validation.isValid) {
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        } else {
          return true;
        }
      }),
    email: yup
      .string()
      .required("Campo Obligatorio.")
      .email("Ingrese un Email válido."),
    prepaga: yup.object().nullable(true),
    plan: yup.object().nullable(true),
    credencial: yup
      .string()
      .min(4, "La credencial requiere al menos 4 caracteres")
      .max(30, "La credencial no puede superar los 30 caracteres")
      .nullable(true),
    falta: yup
      .date()
      .min(new Date("1986-01-01"), "Fecha inválida")
      .nullable()
      .typeError("Fecha inválida"),
  });

  function fillInitialValues(Paciente) {
    formik.resetForm();
    const selDoc = documentos.filter(
      (d) => d.codigo === Paciente.tipo_documento
    );
    if (selDoc.length > 0) {
      formik.setFieldValue("tipo_documento", selDoc[0]);
    }
    formik.setFieldValue("nro_documento", Paciente.nro_documento);
    formik.setFieldValue("nombres", Paciente.nombres);
    formik.setFieldValue("apellido", Paciente.apellido);
    formik.setFieldValue("sexo", Paciente.sexo);
    if (Paciente.fecha_nacimiento !== null) {
      formik.setFieldValue(
        "fecha_nacimiento",
        dayjs(Paciente.fecha_nacimiento)
      );
    }
    formik.setFieldValue("domicilio", Paciente.domicilio);
    formik.setFieldValue("localidad", Paciente.localidad);
    formik.setFieldValue("telefono", Paciente.telefono);
    formik.setFieldValue("telefono2", Paciente.telefono2);
    formik.setFieldValue("celular", Paciente.celular);
    formik.setFieldValue("email", Paciente.email);
    formik.setFieldValue("cuit", Paciente.cuit);
  }

  function fillInitialValuesPadron(Paciente) {
    if (typeof cleanMethod === "function") {
      cleanMethod();
    }
    setIsAlta(true);
    setFormTitle("Transferencia de pacientes");
    formik.resetForm();
    const selDocPad = documentos.filter((d) => d.codigo === Paciente.tipo_doc);
    if (selDocPad.length > 0) {
      formik.setFieldValue("tipo_documento", selDocPad[0]);
    }
    formik.setFieldValue("nro_documento", Paciente.nro_doc);
    formik.setFieldValue("nombres", Paciente.nombres);
    formik.setFieldValue("apellido", Paciente.apellido);
    formik.setFieldValue(
      "plan_padron",
      Paciente.plan !== null ? Paciente.plan : null
    );
    formik.setFieldValue(
      "ref_plan",
      Paciente.ref_plan !== null ? Paciente.ref_plan : null
    );
    formik.setFieldValue(
      "sexo",
      ["F", "M"].indexOf(Paciente.sexo) >= 0 ? Paciente.sexo : null
    );
    formik.setFieldValue("credencial", Paciente.credencial);
    formik.setFieldValue("codigo_empresa", Paciente.codigo);
    setGravadoChecked(Paciente.gravado === 1 ? true : false);
    if (Paciente.date_nac !== null) {
      formik.setFieldValue("fecha_nacimiento", dayjs(Paciente.date_nac));
    }
    if (Paciente.date_asoc !== null) {
      formik.setFieldValue("falta", dayjs(Paciente.date_asoc));
    }
    if (Paciente.date_baja !== null) {
      formik.setFieldValue("fbaja", dayjs(Paciente.date_baja));
    }
    if (Paciente.ult_mov !== null) {
      formik.setFieldValue("ultmov", dayjs(Paciente.ult_mov));
    }
    const selPrepaga = allPrepagas.filter((p) => p.codigo === Paciente.codigo);
    if (selPrepaga.length > 0) {
      formik.setFieldValue("prepaga", selPrepaga[0]);
      const planes = allPlanes.filter((g) => g.prepaga_id === selPrepaga[0].id);
      setPlanesLista(planes);
      if (planes.length === 1) {
        formik.setFieldValue("plan", planes[0]);
      } else if (planes.length > 1) {
        let temPlan = padronPlan;
        const selPlan = planes.filter((g) => g.codigo === temPlan);
        if (selPlan.length > 0) {
          formik.setFieldValue("plan", selPlan[0]);
        }
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      tipo_documento: null,
      nro_documento: null,
      nombres: null,
      apellido: null,
      sexo: null,
      fecha_nacimiento: null,
      domicilio: null,
      localidad: null,
      telefono: null,
      telefono2: null,
      celular: null,
      email: null,
      codigo_empresa: null,
      prepaga: null,
      plan: null,
      credencial: null,
      falta: null,
      fbaja: null,
      ultmov: null,
      plan_padron: null,
      ref_plan: null,
      cuit: null,
    },
    validationSchema: isAlta
      ? validationSchemaAlta
      : validationSchemaModificaion,
    onSubmit: (values) => {
      let seguir = true;
      if (seguir) {
        grabarPaciente(values);
      }
    },
  });

  const handleChangeSexo = (event) => {
    formik.setFieldValue("sexo", event.target.value);
  };

  async function grabarPaciente(values) {
    setLoading(true);
    setErrorMessage(null);
    const Data = {
      apellido: values.apellido,
      nombres: values.nombres,
      tipo_documento: values.tipo_documento.codigo,
      nro_documento: values.nro_documento,
      sexo: values.sexo,
      iva_id: 4,
      provincia_id: 1,
      pais_id: 1,
      fecha_nacimiento:
        values.fecha_nacimiento !== null
          ? values.fecha_nacimiento.toISOString().slice(0, 10)
          : null,
      localidad: values.localidad !== null ? values.localidad : "",
      domicilio: values.domicilio !== null ? values.domicilio : "",
      telefono: values.telefono !== null ? values.telefono : "",
      telefono2: values.telefono2 !== null ? values.telefono2 : "",
      celular: values.celular !== null ? values.celular : "",
      email: values.email !== null ? values.email : "",
      cuit: values.cuit !== null ? values.cuit : "",
    };
    if (isAlta) {
      Data["prepaga_id"] = values.prepaga.id;
      Data["numero_credencial"] = values.credencial;
      Data["fecha_alta"] =
        values.falta !== null ? values.falta.toISOString().slice(0, 10) : null;
      Data["planes_prepaga_id"] = values.plan.id;
      Data["gravado"] = gravadoChecked;
      let result = await ModeloPost(
        "paciente",
        null,
        "insert-update-paciente",
        Data
      );
      setLoading(false);

      if (result.error === false) {
        const pacienteid = result.listado.id;
        crypto.encryptDataStorage("searchpatient", pacienteid);
        handleClickVolver();
      } else {
        setModalMessage(TraduccionError(result.errormessage));
        setModalWarningOpen(true);
      }
    } else {
      let result = await ModeloUpdate("paciente", pacienteId, Data);
      setLoading(false);
      if (result.error === false) {
        crypto.encryptDataStorage("searchpatient", pacienteId);
        handleClickVolver();
      } else {
        setModalMessage(TraduccionError(result.errormessage));
        setModalWarningOpen(true);
      }
    }
  }

  async function grabarPacientePrepaga(values, pacienteid) {
    setLoading(true);
    setErrorMessage(null);

    const Data = {
      paciente_id: pacienteid,
      prepaga_id: values.prepaga.id,
      numero_credencial: values.credencial,
      fecha_alta:
        values.falta !== null ? values.falta.toISOString().slice(0, 10) : null,
      planes_prepaga_id: values.plan.id,
      gravado: gravadoChecked,
    };

    let result = await ModeloPost(
      "paciente",
      null,
      "insert-update-paciente",
      Data
    );

    setLoading(false);
    if (result.error === false) {
      crypto.encryptDataStorage("searchpatient", pacienteid);
      handleClickVolver();
    } else {
      setModalMessage(result.mensaje[0].error);
      setModalWarningOpen(true);
    }
  }

  function handleClickVolver() {
    history.push("/pacientes");
  }

  /*  const handleOnBlurEmpresa = () => {
         let Empresa = document.getElementById("codigo").value;
         if (Empresa !== null && Empresa.length > 0) {
             buscarPrepaga(Empresa)
         }
     }; */

  const handleReset = () => {
    setSelectedEmpresa([]);
    setSelectedPlan([]);
    setAllPlanes([]);
    $("plan_id").value = "";
    $("empresa_id").value = "";
  };

  return (
    <ProtectedRoute
      userRoles={userRoles}
      requiredPermission={"ALTA CPACIENTES"}
    >
      <BackPage text="Volver a pacientes" handleClick={handleClickVolver} />
      <Container maxWidth="xl">
        <Grid
          container
          spacing={1}
          sx={{
            marginBottom: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <Grid xs={12} md={6}>
            <PacienteHeader
              title={isAlta ? formTitle : null}
              foto={isAlta ? false : true}
              prepaga={false}
              menu={false}
            />
          </Grid>

          <Grid xs={11} md={6}>
            {isAlta && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: { xs: 3, md: 0 },
                  marginLeft: { xs: 3, md: 0 },
                }}
              >
                <CustomizedSearch
                  value={searchPaciente}
                  onchange={onChangeSearchPaciente}
                  handlebusqueda={busqueda}
                  placeholder="Documento o Apellido, Nombres..."
                  advancedsearch={hancleClickBusquedaAvanzada}
                  width={{ xs: 400, md: 500 }}
                  starticon={
                    <DeleteIcon sx={{ fontSize: 32, color: "iconred" }} />
                  }
                  handlestart={cleanPaciente}
                />
                <PadronesBusquedaAvanzada
                  open={openBa}
                  anchorEl={anchorElBa}
                  id={idBa}
                  handleClose={handleCloseBa}
                  setResultados={resultadoBusquedaAvanzada}
                  setLoadingPacientes={setLoading}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      {searchLoading && (
        <div className="flex justify-center mt-2">
          <FacebookCircularProgress />
        </div>
      )}
      {modalPacientesOpen && !searchLoading && filterPacientes.length > 0 && (
        <MultipleResultsTable
          setShowModal={setModalPacientesOpen}
          pallData={CapSentenceArray(filterPacientes, ["apellido", "nombres"])}
          objFields={objFields}
          objHeaders={objHeaders}
          objDates={objDates}
          objNullFields={["date_baja"]}
          selectedMethod={fillInitialValuesPadron}
          highlightText={searchPaciente}
          returnfullrow={true}
        />
      )}
      {!modalPacientesOpen && !searchLoading && (
        <form
          key="paciente-form"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Container
            maxWidth="xl"
            sx={{
              borderBottom: 1,
              borderColor: "grey.400",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{
                marginLeft: {
                  xs: 0,
                  md: 2,
                },
                marginBottom: 2,
              }}
            >
              <Grid xs={12} md={2}>
                <Autocomplete
                  id="tipo_documento"
                  options={documentos}
                  value={formik.values.tipo_documento}
                  autoHighlight
                  size="small"
                  onChange={(e, value) => {
                    formik.setFieldValue("tipo_documento", value);
                  }}
                  getOptionLabel={(option) => option.codigo}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo Documento"
                      error={
                        formik.touched.tipo_documento &&
                        Boolean(formik.errors.tipo_documento)
                      }
                      helperText={
                        formik.touched.tipo_documento &&
                        formik.errors.tipo_documento
                      }
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        style: { justifyContent: "center" },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} md={2}>
                <TextField
                  id="nro_documento"
                  label="Nro. Documento"
                  value={formik.values.nro_documento}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.nro_documento
                      ? formik.errors.nro_documento
                      : ""
                  }
                  error={
                    formik.touched.nro_documento &&
                    Boolean(formik.errors.nro_documento)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 0,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={8}></Grid>
              <Grid xs={12} md={4}>
                <TextField
                  id="apellido"
                  label="apellido"
                  value={formik.values.apellido}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.apellido ? formik.errors.apellido : ""
                  }
                  error={
                    formik.touched.apellido && Boolean(formik.errors.apellido)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <TextField
                  id="nombres"
                  label="nombres"
                  value={formik.values.nombres}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.nombres ? formik.errors.nombres : ""
                  }
                  error={
                    formik.touched.nombres && Boolean(formik.errors.nombres)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}></Grid>
              <Grid xs={12} md={1.5}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                >
                  <DateField
                    id="falta"
                    label="Fecha de Nacimiento"
                    value={formik.values.fecha_nacimiento}
                    inputFormat="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    //defaultValue={dayjs(row.alta_usa)}
                    onChange={(value) =>
                      formik.setFieldValue("fecha_nacimiento", value)
                    }
                    sx={{
                      marginTop: {
                        xs: 2,
                        md: 2,
                      },
                    }}
                    size="small"
                    helperText={
                      formik.touched.fecha_nacimiento
                        ? formik.errors.fecha_nacimiento
                        : ""
                    }
                    error={
                      formik.touched.fecha_nacimiento &&
                      Boolean(formik.errors.fecha_nacimiento)
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid xs={12} md={2.5}>
                <RadioGroup
                  id="sexo"
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formik.values.sexo}
                  onChange={handleChangeSexo}
                  error={formik.touched.sexo && Boolean(formik.errors.sexo)}
                  helperText={formik.touched.sexo ? formik.errors.sexo : ""}
                  sx={{
                    border: 1,
                    paddingX: 2,
                    paddingY: 0.2,
                    borderRadius: 1.2,
                    borderColor:
                      formik.touched.sexo && Boolean(formik.errors.sexo)
                        ? "#d32f2f"
                        : "#bdbdbd",
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                    marginRight: 2,
                  }}
                >
                  <FormControlLabel
                    value="F"
                    control={<BpRadio />}
                    label={
                      <Typography sx={{ fontSize: 14 }}>Femenino</Typography>
                    }
                  />
                  <FormControlLabel
                    value="M"
                    control={<BpRadio />}
                    label={
                      <Typography sx={{ fontSize: 14 }}>Masculino</Typography>
                    }
                  />
                </RadioGroup>
                <FormHelperText sx={{ marginLeft: 4, color: "#d32f2f" }}>
                  {formik.touched.sexo ? formik.errors.sexo : ""}
                </FormHelperText>
              </Grid>
              <Grid xs={12} md={2}>
                <TextField
                  id="cuit"
                  label="Cuit"
                  value={formik.values.cuit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.cuit ? formik.errors.cuit : ""}
                  error={formik.touched.cuit && Boolean(formik.errors.cuit)}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={6}></Grid>
              <Grid xs={12} md={4}>
                <TextField
                  id="domicilio"
                  label="Domicilio"
                  value={formik.values.domicilio}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.domicilio ? formik.errors.domicilio : ""
                  }
                  error={
                    formik.touched.domicilio && Boolean(formik.errors.domicilio)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                />
              </Grid>
              <Grid xs={12} md={3}>
                <TextField
                  id="localidad"
                  label="Localidad"
                  value={formik.values.localidad}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.localidad ? formik.errors.localidad : ""
                  }
                  error={
                    formik.touched.localidad && Boolean(formik.errors.localidad)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={3}>
                <TextField
                  id="telefono"
                  label="Teléfono"
                  value={formik.values.telefono}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.telefono ? formik.errors.telefono : ""
                  }
                  error={
                    formik.touched.telefono && Boolean(formik.errors.telefono)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 4,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={3}>
                <TextField
                  id="telefono2"
                  label="Teléfono alternativo"
                  value={formik.values.telefono2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.telefono2 ? formik.errors.telefono2 : ""
                  }
                  error={
                    formik.touched.telefono2 && Boolean(formik.errors.telefono2)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                />
              </Grid>
              <Grid xs={12} md={3}>
                <TextField
                  id="celular"
                  label="Celular"
                  value={formik.values.celular}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.celular ? formik.errors.celular : ""
                  }
                  error={
                    formik.touched.celular && Boolean(formik.errors.celular)
                  }
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 2,
                    },
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <TextField
                  id="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.email ? formik.errors.email : ""}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    marginTop: {
                      xs: 2,
                      md: 2,
                    },
                    marginLeft: {
                      xs: 0,
                      md: 4,
                    },
                  }}
                />
              </Grid>
              {isAlta === true && (
                <>
                  {" "}
                  <Grid xs={12}>
                    <Typography
                      sx={{ marginTop: 2, marginBottom: 1.5 }}
                      variant="h6"
                    >
                      Empresa
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="codigo_empresa"
                      disabled
                      label="Código Empresa"
                      value={formik.values.codigo_empresa}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{
                        marginTop: 0,
                        marginLeft: -1,
                      }}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="prepaga"
                      options={allPrepagas}
                      autoHighlight
                      size="small"
                      onChange={onPrepagasChange}
                      value={formik.values.prepaga}
                      getOptionLabel={(option) =>
                        CapSentence(option.codigo_razon)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Prepaga/Obra Social"
                          error={
                            formik.touched.prepaga &&
                            Boolean(formik.errors.prepaga)
                          }
                          helperText={
                            formik.touched.prepaga && formik.errors.prepaga
                          }
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.codigo_razon, inputValue, {
                          insideWords: true,
                        });
                        const parts = parse(option.codigo_razon, matches);
                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 800 : 400,
                                    //color: part.highlight ? "#0ea5e9" : "black",
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                      sx={{
                        marginLeft: {
                          xs: -1,
                          md: -1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id="plan"
                      options={planesLista}
                      autoHighlight
                      size="small"
                      onChange={onPlanesChange}
                      value={formik.values.plan}
                      getOptionLabel={(option) =>
                        CapSentence(option.plan_codigo)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Plan"
                          error={
                            formik.touched.plan && Boolean(formik.errors.plan)
                          }
                          helperText={formik.touched.plan && formik.errors.plan}
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            style: { justifyContent: "center" },
                          }}
                        />
                      )}
                      sx={{
                        marginLeft: {
                          xs: -1,
                          md: 1,
                        },
                        marginTop: {
                          xs: 1.5,
                          md: 0,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}></Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="credencial"
                      label="Número de credencial"
                      value={formik.values.credencial}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.credencial
                          ? formik.errors.credencial
                          : ""
                      }
                      error={
                        formik.touched.credencial &&
                        Boolean(formik.errors.credencial)
                      }
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{
                        marginTop: {
                          xs: 0.5,
                          md: 1.5,
                        },
                        marginLeft: {
                          xs: -1,
                          md: -1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"en-gb"}
                    >
                      <DateField
                        id="falta"
                        label="Fecha de alta"
                        value={formik.values.falta}
                        inputFormat="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        //defaultValue={dayjs(row.alta_usa)}
                        onChange={(value) =>
                          formik.setFieldValue("falta", value)
                        }
                        size="small"
                        helperText={
                          formik.touched.falta ? formik.errors.falta : ""
                        }
                        error={
                          formik.touched.falta && Boolean(formik.errors.falta)
                        }
                        sx={{
                          marginTop: {
                            xs: 1,
                            md: 1.5,
                          },
                          marginLeft: {
                            xs: -1,
                            md: 0,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={gravadoChecked}
                          onChange={handleChangeGravadoChecked}
                        />
                      }
                      label={
                        <Typography
                          color={gravadoChecked ? "#0284c7" : "#94a3b8"}
                          fontWeight={gravadoChecked ? "bold" : "regular"}
                        >
                          Gravado
                        </Typography>
                      }
                      sx={{
                        marginTop: {
                          xs: 0.5,
                          md: 1.5,
                        },
                        marginLeft: {
                          xs: -1.5,
                          md: 0,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      {formik.values.fbaja !== null && (
                        <EventBusyIcon
                          sx={{
                            color: "iconred",
                            fontSize: 48,
                            mr: { sm: 2 },
                            ml: { xs: -3, sm: -2 },
                            marginTop: 1,
                          }}
                        />
                      )}
                      {formik.values.fbaja === null && (
                        <EventAvailableIcon
                          sx={{
                            color: "icongreen",
                            fontSize: 48,
                            mr: { sm: 2 },
                            ml: { xs: -3, sm: -2 },
                            marginTop: 1,
                          }}
                        />
                      )}

                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"en-gb"}
                      >
                        <DateField
                          id="fbaja"
                          label="Fecha de baja"
                          value={formik.values.fbaja}
                          inputFormat="DD/MM/YYYY"
                          format="DD/MM/YYYY"
                          size="small"
                          sx={{
                            bgcolor:
                              formik.values.fbaja !== null
                                ? "lightred"
                                : "lightgreen",
                            marginTop: {
                              xs: 1,
                              md: 1.5,
                            },
                            marginLeft: {
                              xs: -1,
                              md: -1,
                            },
                          }}
                          readOnly
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="plan"
                      label="Plan"
                      value={formik.values.plan_padron}
                      size="small"
                      fullWidth
                      sx={{
                        marginTop: {
                          xs: 0.5,
                          md: 1.5,
                        },
                        marginLeft: {
                          xs: -1,
                          md: 0,
                        },
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="ref_plan"
                      label="Referencia Plan"
                      value={formik.values.ref_plan}
                      size="small"
                      fullWidth
                      sx={{
                        marginTop: {
                          xs: 0.5,
                          md: 1.5,
                        },
                        marginLeft: {
                          xs: -1,
                          md: 1,
                        },
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2.5}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"en-gb"}
                    >
                      <DateField
                        id="ultmov"
                        label="Ultimo Movimiento"
                        value={formik.values.ultmov}
                        inputFormat="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        size="small"
                        sx={{
                          marginTop: {
                            xs: 1,
                            md: 1.5,
                          },
                          marginLeft: {
                            xs: -1,
                            md: 2,
                          },
                        }}
                        readOnly
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
          <SaveCancelButtons
            handleCancel={handleClickVolver}
            loading={loading}
          />
        </form>
      )}
      <BackPage text="Volver a pacientes" handleClick={handleClickVolver} />
      <ModalError
        showModal={modalErrorOpen === true && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalWarningOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo={"warning"}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
      />
    </ProtectedRoute>
  );
};
export default InsertUpdatePaciente;
