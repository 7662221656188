import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { ModeloDelete, ModeloPost } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import FacebookCircularProgress from "../FacebookCircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ModalInformation from "../modals/ModalInformation";
import { highlightedText, hasPermission } from "../utils/Utils.js";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Checkbox from "@mui/material/Checkbox";
import { formatNumberES, OnlyNumbers } from "../utils/Utils";
import IconButton from "@mui/material/IconButton";
import crypto from "../Crypto";
import ModalAsignarTurnos from "../modals/ModalAsignarTurnos";
import ModalConfirmation from "../modals/ModalConfirmation";
import ModalPacientePerfil from "../modals/ModalPacientePerfil";
import ModalAgendaConsultorio from "../modals/ModaAgendaConsultorio";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Enumerable from "linq";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import * as moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import ModalInformationTurno from "../modals/ModalInformationTurno";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import EventNoteIcon from "@mui/icons-material/EventNote";

const AgendasTurnosTable = ({
  dataAgenda,
  dateAgenda,
  dateAgendaEng,
  buscarAgenda,
  entreturnos = 0,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  let [filterData, setFilterData] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const tableRef = useRef();
  const [filterText, setFilterText] = useState("");
  let [selectedTurnos, setSelectedTurnos] = useState([]);
  const currentpacpreid = crypto.decryptDataStorage("currentpacpreid");
  const [asignarTurnos, setAsignarTurnos] = useState(false);
  const [asignarTurnosId, setAsignarTurnosId] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationBorrar, setShowConfirmationBorrar] = useState(false);
  const [tipoTurno, setTipoTurno] = useState("");
  const [modalPerfilOpen, setModalPerfilOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  let [selectedPerfil, setSelectedPerfil] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectedRow, setSelectedRow] = useState(null);
  const [obsAgenda, setObsAgenda] = useState(null);
  const [obsDia, setObsDia] = useState(null);
  const [modalTurnoInfoOpen, setModalTurnoInfoOpen] = useState(false);
  const [modalAgendaConsulorioOpen, setModalAgendaConsultorioOpen] =
    useState(false);
  const [proximosChecked, setProximosChecked] = useState(
    crypto.decryptDataStorage("soloproximos") !== null ? true : false
  );
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2px 5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: 30,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    let allObs = Enumerable.from(dataAgenda)
      .where((w) => w.obs_agenda !== null && w.obs_agenda !== ".")
      .groupBy((g) => g.obs_agenda)
      .select((e) => ({
        obs: e.key(),
      }))
      .toArray();
    if (allObs.length > 0) {
      let tempObs = "";
      allObs.forEach((element) => {
        tempObs += element.obs + " ";
      });
      setObsAgenda(tempObs);
    }
    let allObsDia = Enumerable.from(dataAgenda)
      .where((w) => w.obs_dia !== null && w.obs_dia !== ".")
      .groupBy((g) => g.obs_dia)
      .select((e) => ({
        obs: e.key(),
      }))
      .toArray();
    if (allObsDia.length > 0) {
      let tempObsDia = "";
      allObsDia.forEach((element) => {
        tempObsDia += element.obs + " ";
      });
      setObsDia(tempObsDia);
    }

    setFilterData(dataAgenda);
    if (crypto.decryptDataStorage("soloproximos") !== null) {
      FiltrarProximos(true);
    }
  }, []);

  const handleSelectAll = (value) => {
    setSelectAll(value);
    selectUnselectAll(value);
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  function handleClickTurnoInfo(row) {
    setSelectedRow(row);
    setModalTurnoInfoOpen(true);
  }

  const handleChangeProximos = (event) => {
    if (event.target.checked) {
      crypto.encryptDataStorage("soloproximos", true);
    } else {
      sessionStorage.removeItem("soloproximos");
    }
    setProximosChecked(event.target.checked);
    FiltrarProximos(event.target.checked);
  };

  const hidePerfilModal = () => {
    setModalPerfilOpen(false);
  };

  const gopatient = (pacienteid) => {
    crypto.encryptDataStorage("searchpatient", pacienteid);
    crypto.encryptDataStorage("lastbutton", "Pacientes");
    history.push("/pacientes");
  };
  const showpatientdata = (row) => {
    setSelectedPerfil(row);
    setModalPerfilOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBloquearAgenda = () => {
    let allAgendas = Enumerable.from(dataAgenda)
      .groupBy((g) => g.agenda_id)
      .select((a) => ({
        id: a.key(),
        hora_desde: a.min((m) => m.hora_desde),
        hora_hasta: a.max((m) => m.hora_hasta),
      }))
      .toArray();
  };

  async function handleDeleteAgenda() {
    hideConfirmationBorrar();
    let allAgendas = Enumerable.from(dataAgenda)
      .groupBy((g) => g.agenda_id)
      .select((a) => ({
        id: a.key(),
      }))
      .toArray();
    for (var i = 0; i < allAgendas.length; i++) {
      const agendaid = allAgendas[i].id;
      let result = await ModeloDelete("agenda", agendaid);

      if (result.error !== false) {
        setModalMessage(result.mensaje);
        setModalErrorOpen(true);
      }
    }
    handleLimpiarAgenda();
  }

  async function handleAgendaConsultorio() {
    setModalAgendaConsultorioOpen(true);
  }

  const hideTurnoInfoModal = () => {
    setModalTurnoInfoOpen(false);
  };

  const hideModalAgendaConsultorio = () => {
    setModalAgendaConsultorioOpen(false);
  };

  const handleBorrarAgenda = () => {
    const asignados = filterData.filter((g) => g.estado !== "L");
    if (asignados.length > 0) {
      setModalMessage(
        "Hay " +
          asignados.length +
          " citas tomadas.\r No se puede borrar la agenda."
      );
      setModalInformationOpen(true);
    } else {
      setModalMessage("Seguro quiere borrar la agenda(s) ?");
      setShowConfirmationBorrar(true);
    }
  };

  const handleLimpiarAgenda = () => {
    sessionStorage.removeItem("currenttableday");
    setFilterData(null);
  };

  function updateSelectedTurnos(pValue, Row) {
    const oldValue = Row.checked;
    const index = filterData.findIndex((obj) => {
      return obj.id === Row.id;
    });
    if (index >= 0) {
      filterData[index].checked = oldValue === 0 ? 1 : 0;
    }
    var tempTurnos = [...filterData];
    setFilterData(tempTurnos);
    selectedTurnos = filterData.filter((g) => g.checked === 1);
    var tempSelected = [...selectedTurnos];
    setSelectedTurnos(tempSelected);
  }

  function selectUnselectAll(value) {
    selectedTurnos = [];
    filterData.forEach((row) => (row.checked = value === true ? 1 : 0));
    selectedTurnos = filterData.filter((g) => g.checked === 1);
    var tempSelected = [...selectedTurnos];
    setSelectedTurnos(tempSelected);
  }

  function estadoturno(
    pEstadoTurno,
    pPacienteId,
    pPacienteDocumento,
    pHabilitada
  ) {
    let estado_turno = "";
    if (pPacienteDocumento === 10101010 || pPacienteDocumento === 10101012) {
      estado_turno = (
        <i class="fa-regular fa-user fa-lg text-slate-400 mr-2 ml-2 mt-1.5"></i>
      );
    } else if (pHabilitada === 0) {
      estado_turno = (
        <i
          class="fa-regular fa-user fa-lg text-slate-400 mr-2 ml-2 mt-1.5 cursor-pointer"
          onClick={() => gopatient(pPacienteId)}
        ></i>
      );
    } else if (pEstadoTurno === "AT") {
      estado_turno = (
        <i
          class="fa-solid fa-user fa-lg text-emerald-600 mr-2 ml-2 mt-1.5 cursor-pointer"
          onClick={() => gopatient(pPacienteId)}
        ></i>
      );
    } else if (pEstadoTurno === "AU") {
      estado_turno = (
        <i
          class="fa-solid fa-user fa-lg text-rose-600 mr-2 ml-2 mt-1.5 cursor-pointer"
          onClick={() => gopatient(pPacienteId)}
        ></i>
      );
    } else if (pEstadoTurno === "SE") {
      estado_turno = (
        <i
          class="fa-solid fa-user fa-lg  text-amber-500 mr-2 ml-2 mt-1.5 cursor-pointer"
          onClick={() => gopatient(pPacienteId)}
        ></i>
      );
    } else if (pEstadoTurno === "CO") {
      estado_turno = (
        <i
          class="fa-solid fa-user fa-lg text-sky-600 mr-2 ml-2 mt-1.5 cursor-pointer"
          onClick={() => gopatient(pPacienteId)}
        ></i>
      );
    } else if (pEstadoTurno === "TF") {
      estado_turno = (
        <i
          class="fa-solid fa-user fa-lg text-slate-400 mr-2 ml-2 mt-1.5 cursor-pointer"
          onClick={() => gopatient(pPacienteId)}
        ></i>
      );
    }
    return estado_turno;
  }

  function handleClickVolver() {
    history.push("/pacientes");
  }
  function onChangeSearch(e) {
    const filterText = e.target.value;
    setFilterText(filterText);
    FiltrarHc(filterText);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      FiltrarHc(filterText);
    }
  }

  const handleAsignarTurnos = () => {
    if (currentpacpreid === null) {
      setModalMessage("Seleccione un paciente.");
      setModalInformationOpen(true);
      return;
    }
    if (selectedTurnos.length > 0) {
      const data = selectedTurnos;
      for (let i = 0; i < data.length; i++) {
        if (data[i].habilitada !== 1) {
          setModalMessage("Agenda Bloqueada.");
          setModalInformationOpen(true);
          return;
        } else if (
          data[i].estado !== "L" &&
          data[i].nro_documento !== 10101010 &&
          data[i].nro_documento !== 10101012
        ) {
          setModalMessage("Algunos turnos NO están libres.");
          setModalInformationOpen(true);
          return;
        }
      }
      setTipoTurno("Turno");
      setAsignarTurnosId(selectedTurnos);
      setAsignarTurnos(true);
    } else {
      setModalMessage("Seleccione al menos un turno.");
      setModalInformationOpen(true);
    }
  };

  const handleEditarTurnos = () => {
    if (selectedTurnos.length > 0) {
      const data = selectedTurnos;
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].estado === "L" ||
          data[i].nro_documento === 10101010 ||
          data[i].nro_documento === 10101012
        ) {
          setModalMessage("Algunos turnos  están libres.");
          setModalInformationOpen(true);
          return;
        }
      }
      setTipoTurno("Editar");
      setAsignarTurnosId(selectedTurnos);
      setAsignarTurnos(true);
    } else {
      setModalMessage("Seleccione al menos un turno.");
      setModalInformationOpen(true);
    }
  };

  const handleAsignarEntreTurnos = () => {
    if (currentpacpreid === null) {
      setModalMessage("Seleccione un paciente.");
      setModalInformationOpen(true);
      return;
    }
    if (selectedTurnos.length === 1) {
      const data = selectedTurnos;
      if (data[0].habilitada !== 1) {
        setModalMessage("Agenda Bloqueada.");
        setModalInformationOpen(true);
        return;
      } else if (data[0].tipo_turno !== "T") {
        setModalMessage("Debe seleccionar un turno.");
        setModalInformationOpen(true);
        return;
      }
      setTipoTurno("Entreturno");
      setAsignarTurnosId(selectedTurnos);
      setAsignarTurnos(true);
    } else {
      setModalMessage("Seleccione solo un turno.");
      setModalInformationOpen(true);
    }
  };

  function DesAsignarTurnos() {
    if (selectedTurnos.length > 0) {
      let cuantos = 0;
      const data = selectedTurnos;
      for (let i = 0; i < data.length; i++) {
        if (data[i].estado !== "L") {
          cuantos += 1;
        }
      }
      if (cuantos === 0) {
        setModalMessage("Seleccione al menos un turno asignado.");
        setModalInformationOpen(true);
        return;
      }
      setModalMessage(
        "Confirma la liberación de " +
          cuantos.toString() +
          (cuantos === 1 ? " turno. ?" : " turnos. ?")
      );
      setShowConfirmationModal(true);
    } else {
      setModalMessage("Seleccione al menos un turno asignado.");
      setModalInformationOpen(true);
    }
  }

  async function handleDesAsignarTurnos() {
    hideConfirmationModal();
    let allOk = true;
    let errormessage = "";
    if (selectedTurnos.length > 0) {
      const data = selectedTurnos;
      for (let i = 0; i < data.length; i++) {
        if (data[i].estado !== "L") {
          setLoading(true);
          let result = await ModeloPost("turno", data[i].id, "liberar", null);
          setLoading(false);
          if (result.error === true) {
            allOk = false;
            errormessage += " " + result.errormessage;
          }
        }
      }
      if (allOk) {
        buscarAgenda(dateAgendaEng);
      } else {
        setModalMessage(errormessage);
        setModalErrorOpen(true);
      }
    } else {
      setModalMessage("Seleccione al menos un turno.");
      setModalInformationOpen(true);
    }
  }

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
    setModalMessage(null);
  };

  const hideConfirmationBorrar = () => {
    setShowConfirmationBorrar(false);
    setModalMessage(null);
  };

  function QuitarFiltro() {
    setFilterText("");
    setFilterData(dataAgenda);
  }

  function FiltrarHc(pFiltro) {
    if (dataAgenda !== null && dataAgenda.length > 0 && pFiltro.length > 0) {
      filterData = dataAgenda.filter(
        (g) =>
          (g.nombre !== null &&
            g.nombre.toLowerCase().indexOf(pFiltro.toLowerCase()) >= 0) ||
          (g.nro_documento !== null &&
            g.nro_documento.toString().indexOf(pFiltro.toLowerCase()) >= 0)
      );
      if (filterData.length <= 0) {
        filterData = dataAgenda;
      }
    } else {
      filterData = dataAgenda;
    }
    let tempData = [...filterData];
    setFilterData(tempData);
  }

  function FiltrarProximos(pFiltro) {
    if (pFiltro === true) {
      const hora = moment(new Date()).format("HH");
      filterData = dataAgenda.filter(
        (g) => Number(g.hora_desde.slice(0, 2)) >= Number(hora) - 1
      );
      if (filterData.length <= 0) {
        filterData = dataAgenda;
      }
    } else {
      filterData = dataAgenda;
    }
    let tempData = [...filterData];
    setFilterData(tempData);
  }

  function Row(props) {
    const { row } = props;
    return (
      <>
        <React.Fragment>
          <StyledTableRow key={row.id}>
            <StyledTableCell component="th" width="1%" scope="row">
              {row.checked === 0 && (
                <i
                  class="fa-regular fa-square fa-xl text-slate-800 mr-2 ml-2"
                  onClick={() => updateSelectedTurnos(0, row)}
                ></i>
              )}
              {row.checked === 1 && (
                <i
                  class="fa-regular fa-square-check fa-xl text-slate-800 mr-2 ml-2"
                  onClick={() => updateSelectedTurnos(0, row)}
                ></i>
              )}
            </StyledTableCell>
            <StyledTableCell align="center" width="1%">
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                {row.habilitada !== 1 && (
                  <RemoveCircleIcon
                    sx={{
                      color: "primary.red",
                      fontSize: 24,
                    }}
                  />
                )}
                {row.estado === "L" && row.habilitada === 1 && (
                  <Avatar
                    sx={{
                      width: 20,
                      height: 20,
                      bgcolor: "icongreen",
                      fontSize: 14,
                      fontWeight: "bold",
                      marginTop: 0,
                    }}
                  >
                    {row.estado}
                  </Avatar>
                )}

                {row.tipo_turno === "T" &&
                  row.estado !== "L" &&
                  row.habilitada === 1 && (
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: "primary.main",
                        fontSize: 14,
                        fontWeight: "bold",
                        marginTop: 0,
                      }}
                    >
                      {row.tipo_turno}
                    </Avatar>
                  )}
                {row.tipo_turno === "E" &&
                  row.estado !== "L" &&
                  row.habilitada === 1 && (
                    <Avatar
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: "iconred",
                        fontSize: 14,
                        fontWeight: "bold",
                        marginTop: 0,
                      }}
                    >
                      {row.tipo_turno}
                    </Avatar>
                  )}
                {row.fuera_de_agenda === "SI" && row.estado !== "L" && (
                  <i class="fa-solid fa-asterisk text-red-700 mr-2 ml-2 mt-0.5"></i>
                )}
              </Box>
            </StyledTableCell>
            <StyledTableCell
              width="5%"
              sx={{
                color: row.habilitada === 1 ? "primary.main" : "iconred",
                fontSize: 20,
                fontWeight: "bold",
              }}
              align="center"
            >
              <Box
                sx={{
                  textTransform: "capitalize",
                  display: "flex",
                  justifyContent: "start",
                  marginTop: 0.5,
                }}
              >
                {row.horara_desde !== null &&
                  row.hora_desde.length === 4 &&
                  row.hora_desde.substring(0, 2) +
                    ":" +
                    row.hora_desde.substring(2, 4)}
              </Box>
            </StyledTableCell>
            <StyledTableCell width="6%" align="right">
              {row.nro_documento !== null &&
                highlightedText(row.nro_documento.toString(), filterText)}
            </StyledTableCell>
            <StyledTableCell>
              {row.nombre !== null && (
                <Tooltip title="Ir al paciente">
                  <div>
                    {estadoturno(
                      row.estado_turno,
                      row.paciente_id,
                      row.nro_documento,
                      row.habilitada
                    )}
                  </div>
                </Tooltip>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {row.nombre !== null &&
                row.nro_documento !== 10101010 &&
                row.nro_documento !== 10101012 && (
                  <>
                    <Tooltip title="Datos de contacto">
                      <IconButton
                        onClick={() => showpatientdata(row)}
                        size="small"
                        sx={{ mt: 0, marginRight: 1 }}
                      >
                        <ContactPhoneIcon
                          sx={{ color: "#94a3b8", ml: -1.5, mt: 0.5, mr: -1.5 }}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
            </StyledTableCell>
            <StyledTableCell>
              {row.nombre !== null &&
                row.nro_documento !== 10101010 &&
                row.nro_documento !== 10101012 && (
                  <Tooltip title={"Información del turno"}>
                    <InfoIcon
                      cursor="pointer"
                      onClick={() => handleClickTurnoInfo(row)}
                      sx={{
                        color: "primary.main",
                        fontSize: 24,
                      }}
                    />
                  </Tooltip>
                )}
            </StyledTableCell>
            <StyledTableCell width="20%">
              <Box
                sx={{
                  textTransform: "capitalize",
                  display: "flex",
                  justifyContent: "start",
                  marginTop: 0.5,
                }}
              >
                {row.nombre !== null && row.es_vip == "1" && (
                  <Tooltip title="Paciente Vip">
                    <WorkspacePremiumIcon
                      sx={{
                        color: "#b91c1c",
                        fontSize: 24,
                        marginTop: 0.2,
                        marginLeft: -0.5,
                        marginRight: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
                {row.nro_documento !== 10101010 &&
                  row.nro_documento !== 10101012 && (
                    <Typography
                      sx={{
                        border:
                          crypto.decryptDataStorage("dataPaciente") !== null &&
                          crypto.decryptDataStorage("dataPaciente")
                            .nro_documento === row.nro_documento
                            ? 1
                            : 0,
                        borderColor: "primary.main",
                        borderRadius: 1,
                        px: 1,
                        fontSize: 15,
                        bgcolor:
                          crypto.decryptDataStorage("dataPaciente") !== null &&
                          crypto.decryptDataStorage("dataPaciente")
                            .nro_documento === row.nro_documento
                            ? "#dbeafe"
                            : null,
                      }}
                    >
                      {row.nombre !== null &&
                        highlightedText(
                          row.nombre.toLowerCase().length > 25
                            ? row.nombre.slice(0, 25).toLowerCase()
                            : row.nombre.toLowerCase(),
                          filterText
                        )}
                    </Typography>
                  )}
                {[10101010, 10101012].indexOf(row.nro_documento) >= 0 && (
                  <Typography
                    sx={{
                      border: 1,
                      borderColor:
                        row.nro_documento === 10101012
                          ? "primary.red"
                          : "primary.yellow",
                      borderRadius: 1,
                      px: 1,
                      fontSize: 15,
                      bgcolor:
                        row.nro_documento === 10101012 ? "red100" : "yellow100",
                    }}
                  >
                    {row.nombre !== null &&
                      highlightedText(
                        row.nombre.toLowerCase().length > 25
                          ? row.nombre.slice(0, 25).toLowerCase()
                          : row.nombre.toLowerCase(),
                        filterText
                      )}
                  </Typography>
                )}
              </Box>
            </StyledTableCell>
            <StyledTableCell width="5%">{row.codigo}</StyledTableCell>
            <StyledTableCell width="6%">
              <Box sx={{ textTransform: "capitalize" }}>
                {row.motivo !== null &&
                  highlightedText(row.motivo.toLowerCase(), filterText)}
              </Box>
            </StyledTableCell>
            <StyledTableCell width="10%">{row.piezas}</StyledTableCell>
            <StyledTableCell width="10%">
              <Box sx={{ textTransform: "capitalize" }}>
                {row.derivado_por !== null
                  ? row.derivado_por.toLowerCase()
                  : ""}
              </Box>
            </StyledTableCell>
            <StyledTableCell width="40%">{row.obs_turno}</StyledTableCell>
          </StyledTableRow>
        </React.Fragment>
      </>
    );
  }

  return (
    <>
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {filterData !== null && filterData.length > 0 && (
            <Container
              maxWidth="xl"
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: { xs: 4, md: 0 },
                marginTop: { xs: 2, md: 0 },
              }}
            >
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "start" },
                      ml: 0,
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        border: 1,
                        borderColor: "primary.main",
                        backgroundColor: "blue100",
                        borderRadius: 1,
                        fontFamily: "Open Sans",
                        paddingX: 1,
                        paddingY: 1,
                        fontSize: 15,
                        marginTop: -1,
                      }}
                    >
                      {dateAgenda}
                      <i class="fa-solid fa-e text-red-500 ml-3 mr-4"></i>
                      {entreturnos}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: selectedTurnos.length === 0 ? 0.5 : 0.5,
                    }}
                  >
                    {loading === false && selectedTurnos.length === 0 && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={proximosChecked}
                            onChange={handleChangeProximos}
                          />
                        }
                        label={
                          <Typography
                            color={proximosChecked ? "avatar" : "#94a3b8"}
                            fontWeight={proximosChecked ? "bold" : "regular"}
                          >
                            Próximos
                          </Typography>
                        }
                        sx={{
                          marginTop: {
                            xs: 0.5,
                            md: 0,
                          },
                          marginLeft: {
                            xs: 0,
                            md: 0,
                          },
                        }}
                      />
                    )}
                    {loading === false && selectedTurnos.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "center", md: "start" },
                          alignItems: "end",
                          height: "100%",
                        }}
                      >
                        {currentpacpreid !== null && (
                          <>
                            {hasPermission(userRoles, "ASIGNAR TURNOS") && (
                              <Tooltip title="Asignar Turnos">
                                <IconButton
                                  onClick={handleAsignarTurnos}
                                  size="small"
                                  sx={{ ml: 2 }}
                                >
                                  <Avatar
                                    sx={{
                                      width: 32,
                                      height: 32,
                                      bgcolor: "primary.main",
                                      marginBottom: 0.5,
                                    }}
                                  >
                                    T
                                  </Avatar>
                                </IconButton>
                              </Tooltip>
                            )}
                            {hasPermission(
                              userRoles,
                              "ASIGNAR ENTRETURNOS"
                            ) && (
                              <Tooltip title="Asignar Entre Turnos">
                                <IconButton
                                  onClick={handleAsignarEntreTurnos}
                                  size="small"
                                  sx={{ ml: 0 }}
                                >
                                  <Avatar
                                    sx={{
                                      width: 32,
                                      height: 32,
                                      bgcolor: "primary.main",
                                      marginBottom: 0.5,
                                    }}
                                  >
                                    E
                                  </Avatar>
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                        {hasPermission(userRoles, "ASIGNAR TURNOS") && (
                          <Tooltip title="Editar Turno">
                            <IconButton
                              onClick={handleEditarTurnos}
                              size="small"
                              sx={{ ml: currentpacpreid !== null ? 0 : 2 }}
                            >
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  bgcolor: "primary.main",
                                  marginBottom: 0.5,
                                }}
                              >
                                <EditIcon
                                  cursor="pointer"
                                  sx={{
                                    color: "white",
                                    fontSize: 24,
                                  }}
                                />
                              </Avatar>
                            </IconButton>
                          </Tooltip>
                        )}
                        {hasPermission(userRoles, "DESASIGNAR TURNOS") && (
                          <Tooltip title="Desasignar Turnos">
                            <IconButton onClick={DesAsignarTurnos} size="small">
                              <Avatar
                                sx={{
                                  width: 32,
                                  height: 32,
                                  bgcolor: "primary.main",
                                  marginBottom: 0.5,
                                }}
                              >
                                <DeleteIcon
                                  cursor="pointer"
                                  sx={{
                                    color: "white",
                                    fontSize: 24,
                                  }}
                                />
                              </Avatar>
                            </IconButton>
                          </Tooltip>
                        )}
                        <div className="flex justify-center ml-2 mb-1">
                          {loading && <FacebookCircularProgress size={32} />}
                        </div>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "end" },
                    }}
                  >
                    <Tooltip title="Menu Agendas">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2, mr: 0 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar
                          sx={{ width: 32, height: 32, bgcolor: "avatar" }}
                        >
                          <MoreVertIcon />
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      {userRoles.indexOf("CLINICASAPP GESTIONAR AGENDAS") >=
                        0 && (
                        <>
                          <MenuItem onClick={handleBorrarAgenda}>
                            <ListItemIcon>
                              <DeleteIcon
                                fontSize="medium"
                                sx={{ color: "iconred", mr: 1 }}
                              />
                            </ListItemIcon>
                            Borrar agenda
                          </MenuItem>
                        </>
                      )}
                      <MenuItem onClick={handleAgendaConsultorio}>
                        <ListItemIcon>
                          <EditIcon
                            fontSize="medium"
                            sx={{ color: "primary.main", mr: 1 }}
                          />
                        </ListItemIcon>
                        Cambiar consultorio
                      </MenuItem>
                      <MenuItem onClick={handleLimpiarAgenda}>
                        <ListItemIcon>
                          <CleaningServicesIcon
                            fontSize="medium"
                            sx={{ color: "primary.main", mr: 1 }}
                          />
                        </ListItemIcon>
                        Quitar agenda
                      </MenuItem>
                      <Divider />
                    </Menu>
                    <TextField
                      id="search"
                      sx={{
                        width: { xs: 300, md: 300 },
                      }}
                      label="Apellido o Documento..."
                      type="text"
                      value={filterText}
                      onChange={onChangeSearch}
                      onKeyDown={handleKeyDown}
                      autoFocus
                      autoComplete="off"
                      //     error={Boolean(formik.errors.password)}
                      //   helperText={formik.errors.password}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        style: {
                          height: "40px",
                        },
                        startAdornment: (
                          <Tooltip title="Buscar" placement="right">
                            <InputAdornment position="start">
                              <SearchIcon
                                onClick={() => FiltrarHc(filterText)}
                                edge="start"
                                fontSize="medium"
                                cursor="pointer"
                              />
                            </InputAdornment>
                          </Tooltip>
                        ),
                        endAdornment: (
                          <Tooltip title="Quitar Filtro" placement="left">
                            <InputAdornment position="end">
                              <ClearIcon
                                onClick={QuitarFiltro}
                                edge="end"
                                fontSize="medium"
                                cursor="pointer"
                              />
                            </InputAdornment>
                          </Tooltip>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              {obsAgenda !== null && (
                <Box
                  sx={{
                    border: 1,
                    bgcolor: "iconred",
                    borderColor: "avatar",
                    borderRadius: 1,
                    display: "flex",
                    p: 0.5,
                    mb: 0.5,
                  }}
                >
                  <Typography
                    sx={{ px: 1, color: "white", fontFamily: "Open Sans" }}
                    variant="h5"
                  >
                    {obsAgenda}
                  </Typography>
                </Box>
              )}
              {obsDia !== null && (
                <Box
                  sx={{
                    border: 1,
                    bgcolor: "blue100",
                    borderColor: "primary.main",
                    borderRadius: 1,
                    display: "flex",
                    p: 0.5,
                    mb: 0.5,
                  }}
                >
                  <Typography
                    sx={{ px: 1, color: "black", fontFamily: "Open Sans" }}
                    variant="h7"
                  >
                    {obsDia}
                  </Typography>
                </Box>
              )}
              <TableContainer component={Paper}>
                <Table
                  ref={tableRef}
                  sx={{ minWidth: { xs: 512, sm: 1280 }, marginBottom: 10 }}
                  size="small"
                  aria-label="a dense table"
                  checkboxSelection
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        {selectAll === false && (
                          <i
                            class="fa-regular fa-square fa-xl text-white mr-2 ml-2 cursor-pointer"
                            onClick={() => handleSelectAll(true)}
                          ></i>
                        )}
                        {selectAll === true && (
                          <i
                            class="fa-regular fa-square-check fa-xl text-white mr-2 ml-2 cursor-pointer"
                            onClick={() => handleSelectAll(false)}
                          ></i>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>Tipo</StyledTableCell>
                      <StyledTableCell align="center">Hora</StyledTableCell>
                      <StyledTableCell>Documento</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Nombre</StyledTableCell>
                      <StyledTableCell>Código</StyledTableCell>
                      <StyledTableCell>Motivo</StyledTableCell>
                      <StyledTableCell>Piezas</StyledTableCell>
                      <StyledTableCell>Derivado</StyledTableCell>
                      <StyledTableCell>Observaciones</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterData !== null &&
                      filterData.length > 0 &&
                      filterData
                        .sort((a, b) => (a.orden > b.orden ? 1 : -1))
                        .map((row) => <Row key={row.id} row={row} />)}
                    {filterData !== null && filterData.length === 0 && (
                      <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                        {" "}
                        <Typography variant="body1" gutterBottom>
                          Cargando...
                        </Typography>
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          )}
        </Box>
      </div>
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      {asignarTurnos &&
        selectedTurnos !== null &&
        selectedTurnos.length > 0 && (
          <ModalAsignarTurnos
            turnos={selectedTurnos}
            tipoTurno={tipoTurno}
            showModal={asignarTurnos}
            hideModal={() => setAsignarTurnos(false)}
            buscaragenda={buscarAgenda}
          />
        )}
      <ModalConfirmation
        showModal={showConfirmationModal}
        confirmModal={handleDesAsignarTurnos}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalConfirmation
        showModal={showConfirmationBorrar}
        confirmModal={handleDeleteAgenda}
        hideModal={hideConfirmationBorrar}
        message={modalMessage}
      />
      {modalPerfilOpen && (
        <ModalPacientePerfil
          row={selectedPerfil}
          showModal={modalPerfilOpen}
          hideModal={hidePerfilModal}
        />
      )}
      {modalTurnoInfoOpen && (
        <ModalInformationTurno
          clinica={selectedRow.clinica_razon}
          especialidad={selectedRow.especialidad}
          especialidad_id={selectedRow.especialidad_id}
          odontologo={selectedRow.odontologo}
          fecha={selectedRow.fecha_ag_arg}
          hora={selectedRow.hora_desde}
          direccion={selectedRow.clinica_domicilio}
          tipoturno={selectedRow.tipo_turno}
          showModal={modalTurnoInfoOpen}
          hideModal={hideTurnoInfoModal}
        />
      )}
      {ModalAgendaConsultorio && (
        <ModalAgendaConsultorio
          dataAgenda={dataAgenda}
          showModal={modalAgendaConsulorioOpen}
          hideModal={hideModalAgendaConsultorio}
        />
      )}
    </>
  );
};
export default AgendasTurnosTable;
