import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ModeloGetUrl } from "../ModeloCrud";
import crypto from "../Crypto";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material";
import BackPage from "../BackPage";
import PacienteHeader from "./PacienteHeader";
import FacebookCircularProgress from "../FacebookCircularProgress";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import { PrintButton } from "../PrintButton";
import { RenderReportDiscapacidad } from "../pacientes/RenderReportDiscapacidad";

// Configuración de los campos a mostrar
const fieldConfig = [
  { key: "paciente_vivecon", label: "Vive Con" },
  { key: "paciente_trastorno_genetico", label: "Trastorno Genético" },
  { key: "paciente_sindrome", label: "Síndrome" },
  { key: "padre_nombre", label: "Nombre del Padre" },
  { key: "padre_documento", label: "Documento del Padre" },
  { key: "padre_domicilio", label: "Domicilio del Padre", icon: <PlaceIcon /> },
  { key: "padre_telefono", label: "Teléfono del Padre", icon: <PhoneIcon /> },
  { key: "padre_email", label: "Email del Padre", icon: <EmailIcon /> },
  { key: "madre_nombre", label: "Nombre de la Madre" },
  { key: "madre_documento", label: "Documento de la Madre" },
  {
    key: "madre_domicilio",
    label: "Domicilio de la Madre",
    icon: <PlaceIcon />,
  },
  { key: "madre_telefono", label: "Teléfono de la Madre", icon: <PhoneIcon /> },
  { key: "madre_email", label: "Email de la Madre", icon: <EmailIcon /> },
  { key: "neurologo_nombre", label: "Nombre del Neurólogo" },
  {
    key: "neurologo_telefono",
    label: "Teléfono del Neurólogo",
    icon: <PhoneIcon />,
  },
  { key: "neurologo_email", label: "Email del Neurólogo", icon: <EmailIcon /> },
  { key: "pediatra_nombre", label: "Nombre del Pediatra" },
  {
    key: "pediatra_telefono",
    label: "Teléfono del Pediatra",
    icon: <PhoneIcon />,
  },
  { key: "pediatra_email", label: "Email del Pediatra", icon: <EmailIcon /> },
  { key: "notas", label: "Notas", multiline: true },
];

const PacienteDiscapacidad = () => {
  const history = useHistory();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const backPage = "pacientes";
  const componentRef = useRef();

  useEffect(() => {
    searchData();
  }, []);

  function handleClickVolver() {
    history.push("/" + backPage);
  }

  async function searchData() {
    let PacienteId = crypto.decryptDataStorage("currentpatient");
    if (PacienteId !== null && typeof PacienteId !== "undefined") {
      setLoading(true);
      const result = await ModeloGetUrl(
        "paciente",
        PacienteId,
        "discapacidad",
        null
      );
      setLoading(false);
      if (result.error === false) {
        setData(result.listado);
      } else {
        history.push("/");
      }
    } else {
      handleClickVolver();
    }
  }

  if (!data) {
    return (
      <div className="flex justify-center mt-10">
        <FacebookCircularProgress />
      </div>
    );
  }

  return (
    <Container maxWidth="xl">
      <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
      <PacienteHeader />
      <div className="flex justify-center mt-2">
        {loading && <FacebookCircularProgress />}
      </div>
      {data?.length > 0 && (
        <div style={{ display: "none" }}>
          <RenderReportDiscapacidad ref={componentRef} data={data[0]} />
        </div>
      )}
      {data.map((item) => (
        <>
          <Paper
            key={item.id}
            sx={{ marginBottom: 2, padding: 2, backgroundColor: "transparent" }}
          >
            <PrintButton
              componentToPrint={componentRef}
              text={"Imprimir"}
              beforePrint={null}
            />
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {fieldConfig.map((field) => (
                <Grid item xs={12} sm={6} md={4} key={field.key}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "primary.main" }}
                      gutterBottom
                    >
                      {field.label}
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={item[field.key] || ""}
                      InputProps={{
                        readOnly: true,
                        startAdornment: field.icon ? (
                          <InputAdornment position="start">
                            {field.icon}
                          </InputAdornment>
                        ) : null,
                      }}
                      multiline={field.multiline || false}
                      rows={field.multiline ? 10 : 1}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
          <BackPage text="Volver al Paciente" handleClick={handleClickVolver} />
        </>
      ))}
    </Container>
  );
};

export default PacienteDiscapacidad;
