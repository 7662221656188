import React from "react";
import { Route, Redirect } from "react-router-dom";

// Hook personalizado para verificar el sessionStorage
const useAuth = () => {
  const userId = sessionStorage.getItem("user_id");
  const userName = sessionStorage.getItem("user_name");
  const jwtToken = sessionStorage.getItem("jwtcr");

  // Devuelve true si las claves existen
  return userId && userName && jwtToken;
};

// Componente para rutas protegidas
const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useAuth(); // Verifica si está autenticado

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" /> // Redirige a login si no está autenticado
        )
      }
    />
  );
};

export default PrivateRoute;
