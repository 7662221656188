import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import crypto from "../Crypto";
import { ModeloGetUrl, ModeloPost } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import FacebookCircularProgress from "../FacebookCircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ModalInformation from "../modals/ModalInformation";
import ModalConfirmation from "../modals/ModalConfirmation";
import BackPage from "../BackPage";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Enumerable from "linq";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Slider from "@mui/material/Slider";

const GenerarAgendas = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allTratamientos, setAllTratamientos] = useState(null);
  let [filterTratamientos, setFilterTratamientos] = useState([]);
  let [selectedEspecialidades, setSelectedEspecialidades] = useState([]);
  let [inputEspecialidad, setInputEspecialidad] = useState("");
  let [selectedHorarios, setSelectedHorarios] = useState([]);
  let [allAgendas, setAllAgendas] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const tableRef = useRef();
  const [filterText, setFilterText] = useState("");
  const [backPage, setBackPage] = useState("agendassimples");
  let [selectedAgendas, setSelectedAgendas] = useState([]);
  const [showSinAgendas, setShowSinAgendas] = useState(false);
  let [errores, setErrores] = useState("");
  const currentpacpreid = crypto.decryptDataStorage("currentpacpreid");
  const [asignarTurnos, setAsignarTurnos] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [valueDias, setValueDias] = React.useState(30);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  let allEspecialidades = Enumerable.from(crypto.decryptDataStorage("allcoes"))
    .where((a) => a.genera_agendas === 1)
    .groupBy((g) => g.especialidad_id)
    .select((e) => e.max((m) => m.especialidad))
    .toArray();

  useEffect(() => {
    const userRoles =
      crypto.decryptLocalStorage("user_roles") !== null
        ? crypto.decryptLocalStorage("user_roles")
        : [];
    if (
      userRoles.length === 0 ||
      userRoles.indexOf("CLINICASAPP GESTIONAR AGENDAS") < 0
    ) {
      handleClickVolver();
    }
  }, []);

  const handleSelectAll = (value) => {
    setSelectAll(value);
    selectUnselectAll(value);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    setModalMessage(null);
  };

  const handleChangeDias = (event, newValue) => {
    if (typeof newValue === "number") {
      setValueDias(newValue);
    }
  };

  function selectUnselectAll(value) {
    selectedAgendas = [];
    allAgendas.forEach((row) => (row.checked = value === true ? 1 : 0));
    selectedAgendas = allAgendas.filter((g) => g.checked === 1);
    var tempSelected = [...selectedAgendas];
    setSelectedAgendas(tempSelected);
  }

  async function handleClickBuscar() {
    setAllAgendas([]);
    setSelectedAgendas([]);
    if (selectedEspecialidades.length === 0) {
      setModalMessage("Seleccione al menos una especialidad.");
      setModalInformationOpen(true);
      return;
    }
    setSelectAll(false);
    let pespecialidades = "";
    for (let i = 0; i < selectedEspecialidades.length; i++) {
      const tempEsp = selectedEspecialidades[i];
      const myEsp = Enumerable.from(crypto.decryptDataStorage("allcoes"))
        .where((w) => w.especialidad === tempEsp)
        .groupBy((g) => g.especialidad_id)
        .select((e) => ({
          id: e.key(),
          especialidad: e.max((m) => m.especialidad),
        }))
        .toArray();
      if (myEsp.length > 0) {
        pespecialidades += myEsp[0].id + ",";
      }
    }
    pespecialidades = pespecialidades.slice(0, pespecialidades.length - 1);

    setLoading(true);
    const params =
      "especialidad=" + pespecialidades + "&dias=" + (valueDias - 1);
    setLoading(true);
    let result = await ModeloGetUrl(
      "centro-odontologo-especialidad",
      null,
      "buscar-agendas-apertura-automatica",
      params
    );
    setLoading(false);

    if (result.error === false) {
      if (result.listado.length > 0) {
        let sorted = result.listado.sort((a, b) =>
          a.orden > b.orden ? 1 : -1
        );
        setAllAgendas(sorted);
      } else {
        setShowSinAgendas(true);
      }
    } else {
      setModalMessage(result.errormessage);
      setModalErrorOpen(true);
    }
  }

  const handleGenerarAgendas = () => {
    if (selectedAgendas.length > 0) {
      setDisplayConfirmationModal(true);
      setModalMessage(
        "Se generarán " + selectedAgendas.length + " agengas. Continúa ?"
      );
    } else {
      setModalMessage("Seleccione al menos una agenda.");
      setModalInformationOpen(true);
    }
  };

  async function handleGenerarAllAgendas() {
    hideConfirmationModal();
    setLoadingAgenda(true);
    setErrores("");
    const data = selectedAgendas;
    for (let i = 0; i < data.length; i++) {
      updateFinished(data[i].id, -1, "Procesando...");
      let result = await GenerarAgenda(
        data[i].centro_id,
        data[i].especialidad_id,
        data[i].odontologo_id,
        data[i].fecha,
        data[i].id
      );
    }
    setLoadingAgenda(false);
    setModalMessage("Proceso terminado.");
    setModalInformationOpen(true);
  }

  async function GenerarAgenda(
    pclinica,
    pespecialidad,
    podontologo,
    pfecha,
    pid
  ) {
    setShowConfirmationModal(false);
    if (
      pclinica !== null &&
      pespecialidad !== null &&
      podontologo !== null &&
      pfecha !== null
    ) {
      const params =
        "centro_id=" +
        pclinica +
        "&especialidad_id=" +
        pespecialidad +
        "&odontologo_id=" +
        podontologo +
        "&fecha=" +
        pfecha;
      let result = await ModeloPost(
        "centro-odontologo-especialidad",
        null,
        "crear-agenda-web",
        params
      );
      if (result.error === false) {
        updateFinished(pid, 1, result.listado);
        return 1;
      } else {
        updateFinished(pid, 2, result.errormessage);
        return 2;
      }
    }
    return 0;
  }

  function updateSelectedTurnos(pValue, Row) {
    const oldValue = Row.checked;
    const index = allAgendas.findIndex((obj) => {
      return obj.id === Row.id;
    });
    if (index >= 0) {
      allAgendas[index].checked = oldValue === 0 ? 1 : 0;
    }
    var tempAgendas = [...allAgendas];
    setAllAgendas(tempAgendas);
    selectedAgendas = allAgendas.filter((g) => g.checked === 1);
    var tempSelected = [...selectedAgendas];
    setSelectedAgendas(tempSelected);
  }

  function updateFinished(pid, value, resultado) {
    setProgress(1);
    const index = allAgendas.findIndex((obj) => {
      return obj.id === pid;
    });
    if (index >= 0) {
      allAgendas[index].finished = value;
      allAgendas[index].resultado = resultado;
    }
    var tempAgendas = [...allAgendas];
    setAllAgendas(tempAgendas);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: 30,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
    setModalInformationOpen(false);
  };

  function handleClickVolver() {
    history.push("/" + backPage);
  }
  function onChangeSearch(e) {
    const filterText = e.target.value;
    setFilterText(filterText);
    FiltrarHc(filterText);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      FiltrarHc(filterText);
    }
  }

  function QuitarFiltro() {
    setFilterText("");
    setFilterTratamientos(allTratamientos);
  }

  function FiltrarHc(pFiltro) {
    if (pFiltro.length > 0) {
      filterTratamientos = allTratamientos.filter(
        (g) =>
          g.codigo_nomenclador.indexOf(pFiltro) >= 0 ||
          g.odontologo.toLowerCase().indexOf(pFiltro.toLowerCase()) >= 0 ||
          g.descripcion.toLowerCase().indexOf(pFiltro.toLowerCase()) >= 0
      );
      if (filterTratamientos.length <= 0) {
        filterTratamientos = [];
      }
    } else {
      filterTratamientos = allTratamientos;
    }
    let tempData = [...filterTratamientos];
    setFilterTratamientos(tempData);
  }

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow key={row.id}>
          <StyledTableCell component="th" width="5%" scope="row">
            {row.checked === 0 && (
              <i
                class="fa-regular fa-square fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedTurnos(0, row)}
              ></i>
            )}
            {row.checked === 1 && (
              <i
                class="fa-regular fa-square-check fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedTurnos(0, row)}
              ></i>
            )}
          </StyledTableCell>
          <StyledTableCell component="th" width="5%" scope="row">
            {row.finished >= 0 ? (
              <CheckCircleIcon
                sx={{
                  color:
                    row.finished === 0
                      ? "icondisabled"
                      : row.finished === 1
                      ? "icongreen"
                      : "iconred",
                  fontSize: 24,
                }}
              />
            ) : (
              <div className="ml-0.5 mt-0.5">
                <FacebookCircularProgress size={20} />
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell width="5%" component="th" scope="row">
            {row.dia_semana}
          </StyledTableCell>
          <StyledTableCell width="10%" component="th" scope="row">
            {row.fecha_arg}
          </StyledTableCell>
          <StyledTableCell width="5%" component="th" scope="row">
            <Avatar
              sx={{
                width: 24,
                height: 24,
                bgcolor: row.centro_color,
                marginBottom: 0.5,
                border: 1,
                borderColor: "black",
              }}
            >
              {row.centro_identificador}
            </Avatar>
          </StyledTableCell>
          <StyledTableCell width="15%" component="th" scope="row">
            {row.especialidad}
          </StyledTableCell>
          <StyledTableCell width="15%" component="th" scope="row">
            <Box
              sx={{
                textTransform: "capitalize",
              }}
            >
              {row.odontologo.toLowerCase()}
            </Box>
          </StyledTableCell>
          <StyledTableCell width="5%" component="th" scope="row">
            {row.turno}
          </StyledTableCell>
          <StyledTableCell width="35%" component="th" scope="row">
            {row.resultado}
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <BackPage text="Volver" handleClick={handleClickVolver} />
        <div className="w-full -mt-2 flex justify-center items-center  ">
          <Typography variant="h5">Generar Agendas</Typography>
        </div>
      </Box>
      <div className="mt-1 ">
        <div className="-mt-3 mb-1 capitalize justify-center items-center flex flex-col md:flex-row    ">
          <Autocomplete
            multiple
            id="check-especialidades"
            options={allEspecialidades}
            size="small"
            sx={{ width: { xs: 300, sm: 600, lg: 800 }, px: 1, py: 1 }}
            disableCloseOnSelect
            filterSelectedOptions
            autoHighlight
            onChange={(event, newEspecialidad) => {
              setSelectedEspecialidades(newEspecialidad);
            }}
            inputValue={inputEspecialidad}
            onInputChange={(event, newPetInputValue) => {
              setInputEspecialidad(newPetInputValue);
            }}
            renderInput={(params) => {
              return <TextField label="Especialidades..." {...params} />;
            }}
          />
          <Box
            sx={{
              width: 200,
              marginLeft: 4,
              marginRight: 4,
              marginTop: { xs: 4, sm: 0 },
            }}
          >
            <Slider
              value={valueDias}
              min={35}
              step={7}
              max={70}
              onChange={handleChangeDias}
              aria-label="Always visible"
              defaultValue={49}
              valueLabelDisplay="on"
            />
          </Box>
          <LoadingButton
            size="medium"
            onClick={handleClickBuscar}
            loading={loading}
            loadingPosition="start"
            endIcon={<SearchIcon />}
            sx={{
              backgroundColor: "primary.main",
              ":hover": {
                bgcolor: "#475569", // theme.palette.primary.main
                color: "white",
              },
              width: 80,
              marginLeft: 1,
              marginTop: { xs: 1, sm: 0 },
            }}
            variant="contained"
          >
            <span></span>
          </LoadingButton>
        </div>
      </div>
      {!loading && (
        <div>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Container maxWidth="xl">
              <Tooltip title="Generar Agendas">
                <LoadingButton
                  size="medium"
                  onClick={handleGenerarAgendas}
                  loading={loadingAgenda}
                  loadingPosition="start"
                  endIcon={<PostAddIcon />}
                  sx={{
                    backgroundColor: "icongreen",
                    ":hover": {
                      bgcolor: "#475569", // theme.palette.primary.main
                      color: "white",
                    },
                    width: 80,
                    marginTop: { xs: 1, sm: 0 },
                    marginBottom: 2,
                  }}
                  variant="contained"
                ></LoadingButton>
              </Tooltip>
            </Container>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Container maxWidth="xl">
              <Box sx={{ display: "flex", justifyContent: "end" }}></Box>
              <TableContainer component={Paper}>
                <Table
                  ref={tableRef}
                  sx={{ minWidth: { xs: 512, sm: 1280 } }}
                  aria-label="customized table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        {selectAll === false && (
                          <i
                            class="fa-regular fa-square fa-xl text-white mr-2 ml-1 cursor-pointer"
                            onClick={() => handleSelectAll(true)}
                          ></i>
                        )}
                        {selectAll === true && (
                          <i
                            class="fa-regular fa-square-check fa-xl text-white mr-2 ml-1 cursor-pointer"
                            onClick={() => handleSelectAll(false)}
                          ></i>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>Ok</StyledTableCell>
                      <StyledTableCell>Dia</StyledTableCell>
                      <StyledTableCell>Fecha</StyledTableCell>
                      <StyledTableCell>Clínica</StyledTableCell>
                      <StyledTableCell>Especialidad</StyledTableCell>
                      <StyledTableCell>Odóntologo</StyledTableCell>
                      <StyledTableCell>Turno</StyledTableCell>
                      <StyledTableCell>Resultado</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allAgendas !== null &&
                      allAgendas.length > 0 &&
                      allAgendas.map((row) => <Row key={row.id} row={row} />)}
                    {allAgendas !== null && allAgendas.length === 0 && (
                      <Box sx={{ ml: 2, mt: 2, mb: 2 }}>
                        {showSinAgendas === true && (
                          <Typography variant="body1" gutterBottom>
                            Sin agendas para generar.
                          </Typography>
                        )}
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Box>
        </div>
      )}

      <BackPage text="Volver" handleClick={handleClickVolver} />
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleGenerarAllAgendas}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
    </>
  );
};
export default GenerarAgendas;
