import React, { useState, useRef } from "react";
import "../../assetss/css/App.css";
import Container from "@mui/material/Container";
import ReporteCajaPorDia1 from "../ReporteCajaPorDia_1";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Enumerable from "linq";
import { LetterAvatar2 } from "../utils/Utils.js";
import ModalInformation from "../modals/ModalInformation";
import { PrintButton } from "../PrintButton";
import { RenderReportCajas } from "../RenderReportCajas";
import Typography from "@mui/material/Typography";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function ReporteCajaPorDia() {
  const [allData, setAllData] = useState(null);
  const [allPagos, setAllPagos] = useState(null);
  const [allPagosTarjetas, setAllPagosTarjetas] = useState(null);
  const [reportPagos, setReportAllPagos] = useState(null);
  const [reportTipoPagos, setReportTipoPagos] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const componentRef = useRef();
  const [pagosChecked, setPagosChecked] = useState(true);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    const tempReport = allData.filter(
      (ca) => newChecked.indexOf(ca.username) >= 0
    );
    const tempPagos = Enumerable.from(allPagos)
      .where((w) => newChecked.indexOf(w.username) >= 0)
      .groupBy((g) => g.tipo_pago)
      .select((e) => ({
        tipo_pago: e.key(),
        cantidad: e.count((x) => x.tipo_pago),
        importe: e.sum((x) => Number(x.importe)),
      }))
      .toArray();
    const tempTarjetas = allPagos.filter(
      (ca) =>
        newChecked.indexOf(ca.username) >= 0 &&
        ["Dinero", "Efectivo"].indexOf(ca.descripcion) < 0
    );
    setReportData(tempReport);
    setReportTipoPagos(tempPagos);
    setAllPagosTarjetas(tempTarjetas);
    setChecked(newChecked);
  };

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const handleChangePagosChecked = (event) => {
    setPagosChecked(event.target.checked);
  };

  function showCajas() {
    const allCajas = Enumerable.from(allData)
      .groupBy((us) => us.user_id)
      .select((e) => ({
        id: e.key(),
        caja: e.max((m) => m.username),
      }))
      .toArray();

    function printCajas() {
      if (checked.length === 0) {
        setModalMessage("Seleccione al menos un usuario.");
        setModalInformationOpen(true);
        return;
      }
      const tempReport = allData.filter(
        (ca) => checked.indexOf(ca.username) >= 0
      );
    }

    return (
      <Box sx={{ marginLeft: 4, marginTop: -2 }}>
        {allCajas !== null && allCajas.length > 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                marginBottom: 2,
                marginTop: 8,
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={pagosChecked}
                    onChange={handleChangePagosChecked}
                  />
                }
                label={
                  <Typography
                    color={pagosChecked ? "avatar" : "#94a3b8"}
                    fontWeight={pagosChecked ? "bold" : "regular"}
                  >
                    Informe de tarjetas y transferencias
                  </Typography>
                }
                sx={{
                  marginTop: {
                    xs: 0.5,
                    md: -0.5,
                  },
                  marginLeft: {
                    xs: 0,
                    md: 0,
                  },
                }}
              />
            </Box>
            <List dense sx={{ width: "100%", maxWidth: 360 }}>
              {allCajas.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    key={value.caja}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(value.caja)}
                        checked={checked.indexOf(value.caja) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        {LetterAvatar2(value.caja.toUpperCase())}
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={`Caja de  ${value.caja}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {checked.length > 0 && reportData.length > 0 && (
          <Box
            sx={{
              marginLeft: 3,
              marginTop: 4,
              marginBottom: 1,
            }}
          >
            <div style={{ display: "none" }}>
              <RenderReportCajas
                ref={componentRef}
                allData={reportData}
                allTipoPagos={reportTipoPagos}
                allPagos={pagosChecked ? allPagosTarjetas : null}
              />
            </div>
            <PrintButton
              componentToPrint={componentRef}
              text={"Imprimir Cajas"}
              beforePrint={null}
              buttonType={"button"}
            />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <>
      <ReporteCajaPorDia1
        setAllData={setAllData}
        setChecked={setChecked}
        setAllPagos={setAllPagos}
        setAllPagosTarjetas={setAllPagosTarjetas}
      />
      {allData !== null && allData.length > 0 && showCajas()}
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
}
