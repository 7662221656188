/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from "react";
import { ModeloGetUrlJson } from "../ModeloCrud";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import { CapSentence } from "../utils/Utils";
import FacebookCircularProgress from "../FacebookCircularProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Draggable from "react-draggable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ModalPacientePerfil({ row, showModal, hideModal }) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const Today = new Date();
  const [loading, setLoading] = React.useState(false);
  const [errorSave, setErrorSave] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fotoPaciente, setFotoPaciente] = useState(null);
  const [fotoPacienteId, setFotoPacienteId] = useState(null);
  const [currentPaciente, setCurrentPaciente] = useState(null);
  const [saldoFacturas, setSaldoFacturas] = useState(null);
  const [saldoPresupuestos, setSaldoPresupuestos] = useState(null);

  useEffect(() => {
    buscarPaciente();
  }, []);

  const handleClose = () => {
    hideModal();
  };

  async function buscarPaciente() {
    let params = "busqueda=" + row.paciente_id + "&is_id=SI";
    setLoading(true);
    let result = await ModeloGetUrlJson(
      "paciente",
      null,
      "busqueda-paciente",
      params
    );
    setLoading(false);

    if (result.error === false) {
      const paciente = result.paciente[0];
      const foto = result.foto.length > 0 ? result.foto : null;
      let fotoid = null;
      if (paciente.foto_paciente.length > 0) {
        const arrFoto = paciente.foto_paciente.split(";");
        setFotoPacienteId(arrFoto[2]);
        setSaldoFacturas(parseInt(result.saldos.saldo_facturas));
        setSaldoPresupuestos(parseInt(result.saldos.saldo_presupuestos));
        fotoid = arrFoto[2];
      } else {
        setFotoPacienteId(null);
      }
      setCurrentPaciente(paciente);
      setFotoPaciente(foto);
    } else {
      setErrorMessage(result.mensaje);
    }
  }

  function BootstrapDialogTitle(props) {
    const { children, onClose, row, ...other } = props;

    return (
      <DialogTitle
        sx={{ m: 0, p: 0, bgcolor: "primary.main", color: "white" }}
        {...other}
      >
        <i class="fa-solid fa-info text-white fa-xl p-4 m-2 "></i>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
          >
            Datos del paciente
          </BootstrapDialogTitle>
          <DialogContent className="content" dividers>
            {loading && currentPaciente === null && (
              <Box
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FacebookCircularProgress />
              </Box>
            )}
            {currentPaciente !== null && (
              <Box
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 22,
                    fontFamily: "Open Sans",
                    fontWeight: "medium",
                  }}
                >
                  {CapSentence(currentPaciente.nombres) +
                    " " +
                    CapSentence(currentPaciente.apellido)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontFamily: "Open Sans",
                    fontWeight: "medium",
                  }}
                >
                  {currentPaciente.tipo_documento +
                    " " +
                    currentPaciente.nro_documento}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontFamily: "Open Sans",
                    fontWeight: "medium",
                  }}
                >
                  Id: {currentPaciente.id}
                </Typography>
              </Box>
            )}
            {fotoPaciente !== null && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  alt="Foto"
                  src={"data:image/jpg;base64," + fotoPaciente}
                  sx={{
                    width: 136,
                    height: 136,
                    justifyContent: "center",
                    boxShadow: 24,
                  }}
                />
              </Box>
            )}
            {fotoPaciente === null && currentPaciente !== null && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <AccountCircleIcon
                  sx={{ color: "#94a3b8", fontSize: "64px" }}
                />
              </Box>
            )}
            {currentPaciente !== null && (
              <>
                <Box sx={{ mt: 2, ml: 4, marginBottom: 4 }}>
                  <div className="flex mt-2 -ml-2 items-end">
                    <i className='far fa-calendar-alt fa-xl mr-5" text-slate-500' />
                    <p className="ml-2">
                      {currentPaciente.fecha_nacimiento !== null &&
                      currentPaciente.fecha_nacimiento.length > 0
                        ? moment(currentPaciente.fecha_nacimiento).format(
                            "DD/MM/YYYY"
                          ) +
                          " (" +
                          currentPaciente.edad +
                          ")"
                        : ""}
                    </p>
                  </div>
                  <div className="flex mt-2 -ml-2 items-end">
                    <i className="fas fa-mobile-alt fa-xl  text-slate-500" />
                    <p className="ml-2">
                      {currentPaciente.celular !== null &&
                      currentPaciente.celular.length > 0
                        ? currentPaciente.celular
                        : ""}
                    </p>
                  </div>
                  <div className="flex mt-2 -ml-2 items-end lowercase">
                    <i className="fas fa-envelope fa-xl text-slate-500" />
                    <p className=" text-sky-600 underline ml-2">
                      {currentPaciente.email !== null &&
                      currentPaciente.email.length > 0
                        ? currentPaciente.email.toLowerCase()
                        : ""}
                    </p>
                  </div>
                  {currentPaciente.telefono !== null &&
                    currentPaciente.telefono.length > 0 && (
                      <div className="flex mt-2 -ml-2 items-end">
                        <i className="fas fa-phone-alt fa-xl text-slate-500" />
                        <p className="ml-2">{currentPaciente.telefono}</p>
                      </div>
                    )}
                  {currentPaciente.telefono2 !== null &&
                    currentPaciente.telefono2.length > 0 && (
                      <div className="flex mt-2 -ml-2 items-end">
                        <i className="fas fa-phone-alt fa-xl  text-slate-500" />
                        <p className="ml-2">{currentPaciente.telefono2}</p>
                      </div>
                    )}
                  {currentPaciente.domicilio !== null &&
                    currentPaciente.domicilio.length > 0 && (
                      <div className="flex mt-2 -ml-2 items-end">
                        <i className="fas fa-house-user fa-xl  text-slate-500" />
                        <p className="ml-2">
                          {CapSentence(currentPaciente.domicilio.toLowerCase())}
                        </p>
                      </div>
                    )}
                  {currentPaciente.localidad !== null &&
                    currentPaciente.localidad.length > 0 && (
                      <div className="flex mt-2 -ml-1 items-end">
                        <i className="fas fa-location-dot fa-xl text-slate-500" />
                        <p className="ml-2">
                          {CapSentence(currentPaciente.localidad.toLowerCase())}
                        </p>
                      </div>
                    )}
                </Box>
                <Box sx={{ mt: -2, ml: 4, py: 2 }}>
                  {(currentPaciente.vip == "1" ||
                    currentPaciente.paga_en_25 == "1" ||
                    currentPaciente.viene_derivado == "1" ||
                    currentPaciente.feliz_cumple == "1" ||
                    saldoFacturas + saldoPresupuestos > 10 ||
                    currentPaciente.ultima_visita !== "") && (
                    <div className="-ml-2 -mr-2 mt-2 mb-2capitalize">
                      {currentPaciente.ultima_visita !== "" && (
                        <div className="flex mt-2 capitalize items-end">
                          <i className="fas fa-calendar-check fa-xl text-sky-700" />
                          <p className="text-sm text-gray-500 ml-2 mt-3 mb-1 font-semibold">
                            U.Visita:
                            {currentPaciente.ultima_visita.toLowerCase()}
                          </p>
                        </div>
                      )}
                      {currentPaciente.vip == "1" && (
                        <div className="flex mt-2 items-end">
                          <i className="fas fa-crown fa-xl text-yellow-700" />
                          <p className="text-sm text-gray-500 ml-2 mb-1">
                            Paciente Vip !
                          </p>
                        </div>
                      )}
                      {currentPaciente.paga_en_25 == "1" && (
                        <div className="flex mt-2 items-end">
                          <i className="fas fa-exclamation-triangle fa-xl text-amber-500" />
                          <p className="text-sm text-gray-500 ml-2 mb-1">
                            No Reclamar ! Paga en 25.
                          </p>
                        </div>
                      )}
                      {currentPaciente.viene_derivado == "1" && (
                        <div className="flex mt-2 items-end">
                          <i className="fas fa-thumbs-down fa-xl text-rose-500" />
                          <p className="text-sm text-gray-500 ml-2 mb-1">
                            No priorizar turnos !!! Viene derivado.
                          </p>
                        </div>
                      )}
                      {currentPaciente.feliz_cumple == "1" && (
                        <div className="flex mt-2 items-end">
                          <i className="fas fa-cake-candles fa-xl text-indigo-500" />
                          <p className="text-sm text-gray-500 ml-2 mb-1">
                            Feliz Cumpleaños !
                          </p>
                        </div>
                      )}
                      {currentPaciente.presento_queja == "1" && (
                        <div className="flex mt-2 items-end">
                          <i className="fas fa-face-angry fa-xl text-rose-500" />
                          <p className="text-sm text-gray-500 ml-2 mb-1">
                            Paciente presentó queja !
                          </p>
                        </div>
                      )}
                      {saldoFacturas + saldoPresupuestos > 10 && (
                        <div className="flex mt-2 items-end">
                          <i className="fas fa-dollar-sign fa-xl text-rose-500  " />
                          <p className="text-sm text-gray-500 ml-2 mb-1 ">
                            Ver Cuenta Corriente !
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions className="actions">
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              loadingPosition="end"
              sx={{
                backgroundColor: "primary.main",
                ":hover": {
                  bgcolor: "#475569", // theme.palette.primary.main
                  color: "white",
                },
                textTransform: "capitalize",
              }}
            >
              Salir
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Draggable>
    </div>
  );
}
