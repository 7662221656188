import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import "../../assetss/css/CalendarMM.css";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { FormatHorario45 } from "../utils/Utils.js";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useHistory } from "react-router-dom";
import "../../assetss/css/CalendarMM.css";
import "../../assetss/css/Pacientes.css";
import crypto from "../Crypto";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../assetss/css/SyncfusionMaterial.css";
import { ModeloGetUrl, ModeloPost } from "../ModeloCrud";
import ModalConfirmation from "../modals/ModalConfirmation";
import ModalInformation from "../modals/ModalInformation";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import BackPage from "../BackPage";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CoeAutocomplete from "../CoeAutocomplete";

const BloquearAgendas = () => {
  let [selectedClinica, setSelectedClinica] = useState(null);
  let [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  let [selectedOdontologo, setSelectedOdontologo] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [backPage, setBackPage] = useState("agendassimples");
  const [allAgendas, setAllAgendas] = useState(null);
  const [motivo, setMotivo] = useState(null);
  let [selectedAgendas, setSelectedAgendas] = useState([]);
  const tableRef = useRef();
  let [errores, setErrores] = useState("");
  const history = useHistory();
  const Today = new Date();
  const desde = moment(Today).format("YYYY-MM-DD");
  const hasta = moment(Today).add(120, "months").format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const calendarRef = useRef();
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  useEffect(() => {
    if (
      userRoles.length === 0 ||
      userRoles.indexOf("CLINICASAPP GESTIONAR AGENDAS") < 0
    ) {
      handleClickVolver();
    }
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#374151",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.root}`]: {
      padding: "2px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: 30,
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleBloquearAgendas = () => {
    if (selectedAgendas.length > 0) {
      if (motivo !== null && motivo.trim().length > 0) {
        setShowConfirmationModal(true);
        setModalMessage(
          "Se actualizarán " + selectedAgendas.length + " agengas. Continúa ?"
        );
      } else {
        setModalMessage("Describa el motivo.");
        setModalInformationOpen(true);
      }
    } else {
      setModalMessage("Seleccione al menos una agenda.");
      setModalInformationOpen(true);
    }
  };

  function updateFinished(pid, value, resultado) {
    const index = allAgendas.findIndex((obj) => {
      return obj.id === pid;
    });
    if (index >= 0) {
      allAgendas[index].finished = value;
      allAgendas[index].resultado = resultado;
    }
    var tempAgendas = [...allAgendas];
    setAllAgendas(tempAgendas);
  }

  async function handleBloquearAllAgendas() {
    hideConfirmationModal();
    setLoadingAgenda(true);
    setErrores("");
    const data = selectedAgendas;
    for (let i = 0; i < data.length; i++) {
      updateFinished(data[i].id, -1, "Procesando...");
      let result = await BloquearAgenda(data[i].id, data[i].habilitado_turnos);
    }
    setLoadingAgenda(false);
    setModalMessage("Proceso terminado.");
    setModalInformationOpen(true);
  }

  async function BloquearAgenda(pid, pvalor) {
    setShowConfirmationModal(false);
    if (pid !== null) {
      let accion = pvalor === 1 ? "deshabilitar" : "habilitar";
      const params = "observaciones=" + motivo;
      let result = await ModeloPost("agenda", pid, accion, params);
      if (result.error === false) {
        updateFinished(pid, 1, result.listado);
        return 1;
      } else {
        updateFinished(pid, 2, result.errormessage);
        return 2;
      }
    }
    return 0;
  }

  async function handleClickBuscar() {
    if (
      selectedClinica == null ||
      selectedEspecialidad == null ||
      selectedOdontologo == null
    ) {
      setModalMessage("Seleccione clínica, especialidad y odontólogo.");
      setModalInformationOpen(true);
      return;
    }
    setLoading(true);
    let params =
      "especialidad_id=" +
      selectedEspecialidad.id +
      "&desde=" +
      desde +
      "&hasta=" +
      hasta;
    if (selectedClinica !== null) {
      params += "&centro_id=" + selectedClinica.id;
    }
    if (selectedOdontologo !== null) {
      params += "&odontologo_id=" + selectedOdontologo.id;
    }
    let result = await ModeloGetUrl(
      "centro-odontologo-especialidad",
      null,
      "agendas-coe-fechas",
      params
    );
    setLoading(false);
    if (result.error === false) {
      if (result.listado.length > 0) {
        let sorted = result.listado.sort((a, b) =>
          a.fecha > b.fecha ? 1 : -1
        );
        setAllAgendas(sorted);
      }
    } else {
      setModalMessage(result.mensaje);
      setModalInformationOpen(true);
    }
    return;
  }

  function Row(props) {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow key={row.id}>
          <StyledTableCell component="th" width="10%" scope="row">
            {row.checked === 0 && (
              <i
                class="fa-regular fa-square fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedAgendas(0, row)}
              ></i>
            )}
            {row.checked === 1 && (
              <i
                class="fa-regular fa-square-check fa-xl text-slate-800 mr-2 ml-2"
                onClick={() => updateSelectedAgendas(0, row)}
              ></i>
            )}
          </StyledTableCell>
          <StyledTableCell component="th" width="10%" scope="row">
            {row.habilitado_turnos === 1 && (
              <CheckCircleIcon
                sx={{
                  color: "primary.green",
                  fontSize: 24,
                  mr: { sm: 2 },
                  ml: 2,
                }}
              />
            )}
            {row.habilitado_turnos === 0 && (
              <RemoveCircleIcon
                sx={{
                  color: "primary.red",
                  fontSize: 24,
                  mr: { sm: 2 },
                  ml: 2,
                }}
              />
            )}
          </StyledTableCell>
          <StyledTableCell width="20%" component="th" scope="row">
            {row.fecha_arg}
          </StyledTableCell>
          <StyledTableCell width="20%" component="th" scope="row">
            {row.horario}
          </StyledTableCell>
          <StyledTableCell width="20%" component="th" scope="row">
            {FormatHorario45(row.horario_desde)}
          </StyledTableCell>
          <StyledTableCell width="20%" component="th" scope="row">
            {FormatHorario45(row.horario_hasta)}
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  function updateSelectedAgendas(pValue, Row) {
    const oldValue = Row.checked;
    const index = allAgendas.findIndex((obj) => {
      return obj.id === Row.id;
    });
    if (index >= 0) {
      allAgendas[index].checked = oldValue === 0 ? 1 : 0;
    }
    var tempAgendas = [...allAgendas];
    setAllAgendas(tempAgendas);
    selectedAgendas = allAgendas.filter((g) => g.checked === 1);
    var tempSelected = [...selectedAgendas];
    setSelectedAgendas(tempSelected);
  }

  function handleClickVolver() {
    history.push("/" + backPage);
  }

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
    setModalMessage(null);
  };

  return (
    <div className="mt-6 ">
      <Box sx={{ display: "flex" }}>
        <BackPage text="Volver" handleClick={handleClickVolver} />
        <div className="w-full -mt-2 flex justify-center items-center  ">
          <Typography variant="h5">Bloquear Agendas</Typography>
        </div>
      </Box>
      <CoeAutocomplete
        selectedClinica={selectedClinica}
        selectedEspecialidad={selectedEspecialidad}
        selectedOdontologo={selectedOdontologo}
        setSelectedClinica={setSelectedClinica}
        setSelectedEspecialidad={setSelectedEspecialidad}
        setSelectedOdontologo={setSelectedOdontologo}
        handlebusqueda={handleClickBuscar}
        loading={loading}
      />
      <div>
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          <Container maxWidth="lg">
            <Tooltip title="Bloquear Agendas">
              <LoadingButton
                size="medium"
                onClick={handleBloquearAgendas}
                loading={loadingAgenda}
                loadingPosition="start"
                endIcon={<RemoveCircleIcon />}
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    bgcolor: "#475569", // theme.palette.primary.main
                    color: "white",
                  },
                  width: 80,
                  marginTop: { xs: 1, sm: 0 },
                  marginBottom: 2,
                }}
                variant="contained"
              ></LoadingButton>
            </Tooltip>
            <TextField
              id="outlined-controlled"
              label="Ingrese el motivo..."
              size="small"
              value={motivo}
              onChange={(event) => {
                setMotivo(event.target.value);
              }}
              sx={{ ml: 1, mb: 2, width: { xs: 300, sm: 600, lg: 881 } }}
            />
          </Container>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Container maxWidth="lg">
            <Box sx={{ display: "flex", justifyContent: "end" }}></Box>
            <TableContainer component={Paper}>
              <Table ref={tableRef} aria-label="customized table" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sel</StyledTableCell>
                    <StyledTableCell>Habilitada</StyledTableCell>
                    <StyledTableCell>Fecha</StyledTableCell>
                    <StyledTableCell>Turno</StyledTableCell>
                    <StyledTableCell>Desde</StyledTableCell>
                    <StyledTableCell>Hasta</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allAgendas !== null &&
                    allAgendas.length > 0 &&
                    allAgendas.map((row) => <Row key={row.id} row={row} />)}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
      </div>

      <BackPage text="Volver" handleClick={handleClickVolver} />
      <ModalConfirmation
        showModal={showConfirmationModal}
        confirmModal={handleBloquearAllAgendas}
        hideModal={hideConfirmationModal}
        message={modalMessage}
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
      />
    </div>
  );
};
export default BloquearAgendas;
