import React, { useState } from "react";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalNota from "../modals/ModalNota";
import FacebookCircularProgress from "../FacebookCircularProgress";
import Grid from "@mui/material/Grid";
import ModalInformation from "../modals/ModalInformation";
import { ModeloGetUrlJson } from "../ModeloCrud";
import crypto from "../Crypto";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import DeleteModel from "../DeleteModel";
import { ExpandMore, hasPermission } from "../utils/Utils";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RenderCustomTable from "../RenderCustomTable";

export default function PacientesNotas({ allNotas, setAllNotas }) {
  const [expanded, setExpanded] = useState(false);
  const [editarPacienteNota, setEditarPacienteNota] = useState(false);
  const [insertarPacienteNota, setInsertarPacienteNota] = useState(false);
  const [selectedNota, setSelectedNota] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateOk, setUpdateOk] = useState(false);
  const [deleteNotaId, setDeleteNotaId] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  let currentPatient = crypto.decryptDataStorage("currentpatient");
  let limitdate = new Date();
  limitdate.setMonth(limitdate.getMonth() - 3);
  let lcLimitDate = limitdate.toISOString().split("T")[0];
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const columns = [
    { field: "fecha_hora", headerName: "Fecha", isDate: false },
    { field: "usuario", headerName: "Usuario", isDate: false },
    { field: "observacion", headerName: "Nota", isDate: false },
  ];

  const actions = [
    {
      icon: EditIcon,
      handle: (row) => handleEdit(row),
      sx: { color: "primary.main" },
      requiredPermission: "ALTA CPACIENTESOBSERVACIONES",
    },
    {
      icon: DeleteIcon,
      handle: (row) => handleDelete(row),
      sx: { color: "iconred" },
      requiredPermission: "ALTA CPACIENTESOBSERVACIONES",
    },
  ];

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };

  const hidePacienteNota = () => {
    setEditarPacienteNota(false);
    setInsertarPacienteNota(false);
  };
  const handleEdit = (row) => {
    setUpdateOk(false);
    setSelectedNota(row);
    setEditarPacienteNota(true);
  };
  function handleDelete(row) {
    setDeleteNotaId(row.id);
  }
  const handleNew = () => {
    setInsertarPacienteNota(true);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  async function buscarNotasPaciente(pacienteid) {
    setUpdateOk(false);
    let result = await ModeloGetUrlJson(
      "paciente",
      pacienteid,
      "vista-observaciones",
      null
    );
    if (result.error === false) {
      crypto.encryptDataStorage("dataNotas", result.listado);

      setAllNotas(result.listado);
    }
    return;
  }

  return (
    <>
      <Card
        sx={{
          backgroundColor: "bgcards",
          ml: { xs: -1, md: -2 },
          mr: { xs: -2, md: -2 },
        }}
      >
        <CardHeader
          avatar={<SpeakerNotesIcon sx={{ color: "avatar", fontSize: 48 }} />}
          title={
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ fontSize: 22, marginTop: 0, marginRight: 2 }}>
                Notas
              </Typography>
              {hasPermission(userRoles, "ALTA CPACIENTESOBSERVACIONES") && (
                <IconButton
                  aria-label="new"
                  onClick={handleNew}
                  sx={{ p: "1px", marginTop: -1 }}
                >
                  <AddRoundedIcon sx={{ fontSize: 48, color: "icongreen" }} />
                </IconButton>
              )}
            </Box>
          }
          subheader=""
        />
        <Box sx={{ mt: -4, ml: 1, mr: 1, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mt: -2 }}>
              {loading && <FacebookCircularProgress />}
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 16, mb: 1, mr: 2, textAlign: "end" }}>
                Últimos 3 meses
              </Typography>
            </Grid>
          </Grid>
          <RenderCustomTable
            data={allNotas.filter((n) => n.fecha_usa >= lcLimitDate)}
            columns={columns}
            paddingLeft={0}
            paddingRight={0}
            actions={actions}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-center" }}>
            <Typography variant="caption" sx={{ mt: 2, mr: 1 }}>
              Historial
            </Typography>
            <Box>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandCircleDownIcon
                  sx={{ color: "primary.main", fontSize: 36 }}
                />
              </ExpandMore>
            </Box>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Box sx={{ mt: -2, mb: 4, ml: -1, mr: -1 }}>
              <RenderCustomTable
                data={allNotas.filter((n) => n.fecha_usa < lcLimitDate)}
                columns={columns}
                paddingLeft={0}
                paddingRight={0}
                actions={actions}
              />
            </Box>
          </CardContent>
        </Collapse>
      </Card>
      {editarPacienteNota && (
        <ModalNota
          row={selectedNota}
          showModal={editarPacienteNota}
          hideModal={hidePacienteNota}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
        />
      )}
      {insertarPacienteNota && (
        <ModalNota
          row={null}
          showModal={insertarPacienteNota}
          hideModal={hidePacienteNota}
          setLoading={setLoading}
          setUpdateOk={setUpdateOk}
        />
      )}
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="error"
      />
      {updateOk && buscarNotasPaciente(currentPatient)}
      {deleteNotaId !== null && (
        <DeleteModel
          model={"paciente-observacion"}
          modelid={deleteNotaId}
          okmethod={() => buscarNotasPaciente(currentPatient)}
          nullmethod={setDeleteNotaId}
        />
      )}
    </>
  );
}
