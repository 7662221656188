import React, { useRef, useState } from "react";
import {
  Switch,
  FormControlLabel,
  styled,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  DialogContent,
  DialogActions,
  Box,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import CustomTextField from "../CustomTextField";
import crypto from "../Crypto";
import { ModeloGetUrlJson, ModeloPost, ModeloUpdate } from "../ModeloCrud";
import SaveCancelButtons from "../SaveCancelButtons";
import Draggable from "react-draggable";
import FacebookCircularProgress from "../FacebookCircularProgress";
import { createTheme } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, row, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 0,
        bgcolor: "primary.main",
        color: "white",
      }}
      {...other}
    >
      {row !== null && (
        <EditIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {row === null && (
        <AddIcon sx={{ color: "white", fontSize: 48, ml: 2, mr: 2 }} />
      )}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const validationSchema = Yup.object({
  codigo: Yup.string().max(20).required("Campo obligatorio"),
  observaciones: Yup.string(),
  habilitado: Yup.boolean(),
});

export default function ModalListaPrecios({
  open,
  handleClose,
  row,
  handleSuccess,
}) {
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState(null);

  const inputRefs = {
    plan_cobertura_id: useRef(null),
    codigo: useRef(null),
    descripcion: useRef(null),
    lista_precios_id: useRef(null),
    lista_basica_id: useRef(null),
    observaciones: useRef(null),
    exclusivo_centro_id: useRef(null),
  };

  const formik = useFormik({
    initialValues: {
      codigo: row?.codigo || "",
      habilitado: row !== null ? (row.habilitado === 1 ? true : false) : true,
      observaciones: row?.observaciones || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const theme = createTheme({
    components: {
      MuiAlert: {
        styleOverrides: {
          filledError: {
            backgroundColor: "#f44336", // Red color for error
          },
        },
      },
    },
  });

  async function handleSubmit(values) {
    setSaveLoading(true);
    setSaveError(null);
    const Data = {
      codigo: values.codigo,
      habilitado: values.habilitado ? 1 : 0,
      observaciones: values.observaciones,
    };
    let result = null;
    if (!row) {
      result = await ModeloPost("listas-precios", null, null, Data);
    } else {
      result = await ModeloUpdate("listas-precios", row.id, Data);
    }
    setSaveLoading(false);
    if (result.error === false) {
      if (row === null) {
        handleSuccessInsertOrEdit(result.listado[0].id);
      } else {
        handleSuccessInsertOrEdit(row.id);
      }
    } else {
      if (Array.isArray(result.errormessage)) {
        setSaveError(result.errormessage[0].error);
      } else if (typeof result.errormessage === "string") {
        setSaveError(result.errormessage);
      } else {
        setSaveError("Error desconocido !");
      }
    }
  }

  const handleSuccessInsertOrEdit = (insertedOrUpdatedId) => {
    handleSuccess(insertedOrUpdatedId);
    handleClose(); // Llama `handleSuccess` con el nuevo o actualizado ID
  };

  return (
    <div>
      <Draggable cancel=".content, .actions">
        <BootstrapDialog
          hideBackdrop={true}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth="md"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
            "& .MuiPaper-root": {
              // backgroundColor: "bgmodal", // Adjust transparency here
            },
          }}
        >
          <BootstrapDialogTitle
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
            onClose={handleClose}
            row={row}
          >
            {row !== null ? "Editar" : "Nuevo Registro"}
          </BootstrapDialogTitle>

          <form onSubmit={formik.handleSubmit}>
            <>
              <DialogContent className="content" dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      label="Código"
                      name="codigo"
                      value={formik.values.codigo}
                      inputRef={inputRefs.codigo}
                      nextInputRef={inputRefs.descripcion}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.codigo && Boolean(formik.errors.codigo)
                      }
                      helperText={formik.touched.codigo && formik.errors.codigo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* Observaciones Field */}
                    <CustomTextField
                      label="Observaciones"
                      name="observaciones"
                      multiline
                      rows={4}
                      inputRef={inputRefs.observaciones}
                      value={formik.values.observaciones}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.observaciones &&
                        Boolean(formik.errors.observaciones)
                      }
                      helperText={
                        formik.touched.observaciones &&
                        formik.errors.observaciones
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Habilitado Field */}
                    <FormControlLabel
                      control={
                        <Switch
                          name="habilitado"
                          checked={formik.values.habilitado}
                          onChange={formik.handleChange}
                        />
                      }
                      label="Habilitado"
                    />
                  </Grid>
                </Grid>

                {saveError !== null && (
                  <Alert variant="filled" severity="error">
                    {saveError}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions className="actions">
                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                  <SaveCancelButtons
                    handleCancel={handleClose}
                    loading={saveLoading}
                  />
                </Grid>
              </DialogActions>
            </>
          </form>
        </BootstrapDialog>
      </Draggable>
    </div>
  );
}
