import React, { useState, useEffect } from "react";
import { ModeloDelete } from "./ModeloCrud";
import ModalConfirmation from "./modals/ModalConfirmation";
import ModalInformation from "./modals/ModalInformation";
function DeleteModel({
  model = null,
  modelid = 0,
  okmethod = null,
  errormethod = null,
  setloading = null,
  nullmethod = null,
}) {
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    setShowConfirmationModal(true);
  }, []);

  const hideConfirmationModal = () => {
    if (nullmethod !== null) {
      nullmethod(null);
    }
    setShowConfirmationModal(false);
  };

  const hideErrorModal = () => {
    hideConfirmationModal();
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  async function handleDeleteModel() {
    const deleteid = modelid;
    if (deleteid !== null && deleteid > 0) {
      if (setloading !== null) {
        setloading(true);
      }
      let result = await ModeloDelete(model, deleteid);
      if (setloading !== null) {
        setloading(false);
      }
      if (result.error === false) {
        if (okmethod !== null) {
          okmethod();
        }
        hideConfirmationModal();
      } else {
        if (errormethod !== null) {
          errormethod();
        }
        setModalMessage(result.errormessage);
        setModalErrorOpen(true);
      }
    }
  }

  return (
    <>
      <ModalConfirmation
        showModal={showConfirmationModal}
        confirmModal={handleDeleteModel}
        hideModal={hideConfirmationModal}
        message={"Seguro quiere borrar el registro ?"}
      />
      <ModalInformation
        showModal={modalErrorOpen}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
    </>
  );
}

export default DeleteModel;
