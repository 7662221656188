import React from "react";
import { Box } from "@mui/material";
import ReportSection from "./RenderReportOrdenesSection";

export const RenderReportOrdenes = React.forwardRef((props, ref) => {
  const {
    orden,
    orderNumber,
    customerName,
    documentNumber,
    budgetNumber,
    issueDate,
    waitDate,
    labName,
    items,
  } = props;
  return (
    <Box ref={ref} p={2}>
      <style
        type="text/css"
        media="print"
        dangerouslySetInnerHTML={{
          __html: `
                  @page {
                    size: Legal landscape;
                    margin: 20mm;
                  }
                  @media print {
                    body {
                      -webkit-print-color-adjust: exact;
                    }
                    .page-break {
                      page-break-before: always;
                       margin-top: 25mm; 
                    }
                    .content {
                      margin-top: 2cm; 
                    }
                  }
                `,
        }}
      />
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        {/* Reporte original */}
        <Box
          sx={{
            width: "48%",
            paddingRight: 2,
            marginLeft: 1,
            //  borderRight: "1px solid #000",
          }}
        >
          <ReportSection
            type="Original"
            orden={orden}
            orderNumber={orderNumber}
            customerName={customerName}
            documentNumber={documentNumber}
            budgetNumber={budgetNumber}
            issueDate={issueDate}
            waitDate={waitDate}
            labName={labName}
            items={items}
          />
        </Box>

        {/* Reporte duplicado */}
        <Box
          sx={{ width: "50%", borderLeft: "1px solid #000", paddingLeft: 2 }}
        >
          <ReportSection
            type="Duplicado"
            orden={orden}
            orderNumber={orderNumber}
            customerName={customerName}
            documentNumber={documentNumber}
            budgetNumber={budgetNumber}
            issueDate={issueDate}
            waitDate={waitDate}
            labName={labName}
            items={items}
          />
        </Box>
      </Box>
    </Box>
  );
});
