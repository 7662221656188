import React from "react";
import Tooth from "./Tooth";

function Teeth({
  start,
  end,
  x,
  y,
  handleChange,
  data,
  tratamiento,
  color,
  readonly,
  cleanodontograma,
  itemstoadd,
  setItemsToAdd,
}) {
  let tooths = getArray(start, end);

  return (
    <g transform="scale(1.5)" id="gmain">
      {tooths.map((i) => (
        <Tooth
          onChange={handleChange}
          key={i}
          number={i}
          positionY={y}
          positionX={Math.abs((i - start) * 25) + x}
          data={data}
          tratamiento={tratamiento}
          color={color}
          readonly={readonly}
          cleanodontograma={cleanodontograma}
          itemstoadd={itemstoadd}
          setItemsToAdd={setItemsToAdd}
        />
      ))}
    </g>
  );
}

function getArray(start, end) {
  if (start > end) return getInverseArray(start, end);

  let list = [];
  for (var i = start; i <= end; i++) {
    list.push(i);
  }

  return list;
}

function getInverseArray(start, end) {
  let list = [];

  for (var i = start; i >= end; i--) {
    list.push(i);
  }

  return list;
}

export default Teeth;
