import React, { useEffect, useReducer, useRef, useState } from "react";
import "../assetss/css/Tooth.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import crypto from "./Crypto";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

function Tooth({
  number,
  positionX,
  positionY,
  onChange,
  data,
  tratamiento,
  color,
  readonly,
  cleanodontograma,
  itemstoadd,
  setItemsToAdd,
}) {
  const initialState = {
    Cavities: {
      center: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    Extract: 0,
    Crown: 0,
    Cimplante: 0,
    Endodoncia: 0,
    Implant: 0,
    Perio: 0,
    Fija: 0,
    Removible: 0,
    Perno: 0,
  };
  const [contextMenu, setContextMenu] = React.useState(null);
  const [piezasDentales, setPiezasDentales] = useState(
    crypto.decryptDataStorage("piezas_dentales")
  );
  const [rfichado, serRFichado] = useState(
    crypto.decryptDataStorage("rfichado")
  );

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleMenu = (accion) => {
    handleClose();
    if (accion === "limpiar") {
      dispatch(clear());
    } else if (accion === "ver") {
      //
      alert(number);
    }
  };

  function reducer(toothState, action) {
    switch (action.type) {
      case "perio":
        return { ...toothState, Perio: action.value };
      case "crown":
        return { ...toothState, Crown: action.value };
      case "fija":
        return { ...toothState, Fija: action.value };
      case "removible":
        return { ...toothState, Removible: action.value };
      case "perno":
        return { ...toothState, Perno: action.value };
      case "extract":
        return { ...toothState, Extract: action.value };
      case "implant":
        return { ...toothState, Implant: action.value };
      case "cimplante":
        return { ...toothState, Cimplante: action.value };
      case "endodoncia":
        return { ...toothState, Endodoncia: action.value };
      case "carie":
        return {
          ...toothState,
          Cavities: setCavities(toothState, action.zone, action.value),
        };
      case "clear":
        return initialState;
      default:
        throw new Error();
    }
  }

  const crown = (val) => ({ type: "crown", value: val });
  const extract = (val) => ({ type: "extract", value: val });
  const implant = (val) => ({ type: "implant", value: val });
  const cimplante = (val) => ({ type: "cimplante", value: val });
  const endodoncia = (val) => ({ type: "endodoncia", value: val });
  const carie = (z, val) => ({ type: "carie", value: val, zone: z });
  const perio = (val) => ({ type: "perio", value: val });
  const fija = (val) => ({ type: "fija", value: val });
  const removible = (val) => ({ type: "removible", value: val });
  const perno = (val) => ({ type: "perno", value: val });
  const clear = () => ({ type: "clear" });

  const [toothState, dispatch] = useReducer(reducer, initialState);
  //const [contextMenu, useCM] = useContextMenu({ submenuSymbol: ">" });

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      addTratamientos();
      return;
    }
    if (itemstoadd?.length > 0) {
      addRangeItems();
    } else {
      if (cleanodontograma) {
        dispatch(clear());
      } else {
        onChange(number, toothState);
      }
    }
  }, [toothState, onChange, number]);

  let getClassNamesByZone = (zone) => {
    if (toothState.Cavities) {
      if (toothState.Cavities[zone] === 1) {
        return "to-do";
      } else if (toothState.Cavities[zone] === 2) {
        return "done";
      } else if (toothState.Cavities[zone] === 3) {
        return "estetica-to-do";
      } else if (toothState.Cavities[zone] === 4) {
        return "estetica-done";
      }
    }

    return "";
  };

  // Tooth position
  const translate = `translate(${positionX},${positionY})`;

  return (
    <svg
      className="tooth"
      onContextMenu={handleContextMenu}
      style={{ cursor: "context-menu" }}
    >
      <g transform={translate}>
        <polygon
          points="0,0 20,0 15,5 5,5"
          // onContextMenu={useCM(menuConfig("top"))}
          className={getClassNamesByZone("top")}
          onClick={() => newTratamiento("top")}
        />
        <polygon
          points="5,15 15,15 20,20 0,20"
          //  onContextMenu={useCM(menuConfig("bottom"))}
          className={getClassNamesByZone("bottom")}
          onClick={() => newTratamiento("bottom")}
        />
        <polygon
          points="15,5 20,0 20,20 15,15"
          // onContextMenu={useCM(menuConfig("left"))}
          className={getClassNamesByZone("left")}
          onClick={() => newTratamiento("left")}
        />
        <polygon
          points="0,0 5,5 5,15 0,20"
          // onContextMenu={useCM(menuConfig("right"))}
          className={getClassNamesByZone("right")}
          onClick={() => newTratamiento("right")}
        />
        <polygon
          points="5,5 15,5 15,15 5,15"
          //   onContextMenu={useCM(menuConfig("center"))}
          className={getClassNamesByZone("center")}
          onClick={() => newTratamiento("center")}
        />
        {drawToothActions()}
        <text
          x="6"
          y={
            ["1", "2", "5", "6"].indexOf(number.toString().substring(0, 1)) >= 0
              ? "-4"
              : "30"
          }
          stroke="navy"
          fill="navy"
          strokeWidth="0.1"
          className="tooth"
        >
          {number}
        </text>
      </g>
      <Menu
        open={contextMenu !== null && !readonly}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        sx={{ "& .MuiMenu-paper": { backgroundColor: "#F5F5F5" } }}
      >
        <MenuItem onClick={() => handleMenu("ver")}>
          <ListItemIcon>
            <SearchIcon
              fontSize="medium"
              sx={{ color: "primary.main", mr: 1 }}
            />
          </ListItemIcon>
          Ver diente
        </MenuItem>
        <MenuItem onClick={() => handleMenu("limpiar")}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium" sx={{ color: "iconred", mr: 1 }} />
          </ListItemIcon>
          Limpiar diente
        </MenuItem>
      </Menu>
    </svg>
  );

  function newTratamiento(zone) {
    if (!readonly) {
      addItem(tratamiento, zone, color);
    }
  }

  function addTratamientos() {
    const zone = ["top", "left", "right", "bottom", "center"];
    if (data.length > 0) {
      const filterData = data
        .filter((g) => g.diente == number)
        .sort((a, b) => (a.color > b.color ? -1 : 1));
      if (filterData.length > 0) {
        filterData.forEach(function (elemento, indice, array) {
          const lnColor = elemento.color === "a" ? 2 : 1;
          const lnColor2 = elemento.color === "a" ? 4 : 3;
          addItem(elemento.tratamiento, zone[elemento.cavidad - 1], lnColor);
        });
      }
    }
  }

  function addRangeItems() {
    if (itemstoadd?.length > 0) {
      const filterData = itemstoadd
        .filter((g) => g.diente === number)
        .sort((a, b) => (a.color > b.color ? -1 : 1));
      if (filterData.length > 0) {
        filterData.forEach(function (elemento) {
          const lnColor = elemento.color === "a" ? 2 : 1;
          addItem(elemento.tratamiento, 0, lnColor);
        });
      }
      if (number === 75) {
        setItemsToAdd([]);
      }
    }
  }

  function addItem(tratamiento, zona, valor) {
    // agregar en el vector
    if (tratamiento !== null) {
      //   const selPieza = piezasDentales.filter((p) => p.diente == number);
      //    const selTratamiento = rfichado.filter(
      //     (t) => t.descripcion === tratamiento
      //   );
      //    const row = {
      //       referencia_fichado_id: selTratamiento[0].id,
      //       pieza_dental_id: selPieza[0].id,
      //    };
      //   let addRow = crypto.decryptDataStorage("odontogramaRows");
      //    addRow.push(row);
      //
      //    crypto.encryptDataStorage("odontogramaRows", addRow);
    }

    //
    if (tratamiento === "DIENTES AUSENTES") {
      dispatch(extract(valor));
    } else if (tratamiento === "CORONA") {
      dispatch(crown(valor));
    } else if (tratamiento === "IMPLANTE") {
      dispatch(implant(valor));
    } else if (tratamiento === "OBTURACION") {
      dispatch(carie(zona, valor));
    } else if (tratamiento === "ESTETICA") {
      dispatch(carie(zona, valor === 1 ? 3 : 4));
    } else if (tratamiento === "PERIODONCIA") {
      dispatch(perio(valor));
    } else if (tratamiento === "PROTESIS FIJA") {
      dispatch(fija(valor));
    } else if (tratamiento === "PROTESIS REMOVIBLE") {
      dispatch(removible(valor));
    } else if (tratamiento === "PERNO MUÑON") {
      dispatch(perno(valor));
    } else if (tratamiento === "ENDODONCIA") {
      dispatch(endodoncia(valor));
    } else if (tratamiento === "CORONA SOBRE IMPLANTE") {
      dispatch(cimplante(valor));
    } else if (tratamiento === "EXTRACCION") {
      dispatch(extract(valor));
    }
  }

  function setCavities(prevState, zone, value) {
    if (prevState && prevState.Cavities) {
      if (zone === "all") {
        prevState.Cavities = {
          center: value,
          top: value,
          bottom: value,
          left: value,
          right: value,
        };
      } else {
        prevState.Cavities[zone] = value;
      }

      return prevState.Cavities;
    }
  }

  function drawToothActions() {
    let otherFigures = null;
    otherFigures = (
      <>
        {toothState.Implant > 0 && (
          <>
            (
            <g stroke={toothState.Implant === 1 ? "#ef4444" : "#3b82f6"}>
              <line x1="10" y1="1" x2="10" y2="20" strokeWidth="4" />
              <line x1="5" y1="1" x2="15" y2="1" strokeWidth="3" />
              <line x1="5" y1="19" x2="15" y2="19" strokeWidth="3" />
            </g>
            <g stroke={"black"}>
              <line x1="8" y1="4" x2="12" y2="4" strokeWidth="2" />
              <line x1="8" y1="8" x2="12" y2="8" strokeWidth="2" />
              <line x1="8" y1="12" x2="12" y2="12" strokeWidth="2" />
              <line x1="8" y1="16" x2="12" y2="16" strokeWidth="2" />
            </g>
            )
          </>
        )}
        {toothState.Perio > 0 &&
          ["1", "2", "5", "6"].indexOf(number.toString().substring(0, 1)) >=
            0 && (
            <g stroke={toothState.Perio === 1 ? "#ef4444" : "#3b82f6"}>
              <line x1="-2" y1="-5" x2="-2" y2="0" strokeWidth="2" />
              <line x1="-2" y1="-4" x2="22" y2="-4" strokeWidth="2" />
              <line x1="22" y1="-5" x2="22" y2="0" strokeWidth="2" />
            </g>
          )}
        {toothState.Perio > 0 &&
          ["3", "4", "7", "8"].indexOf(number.toString().substring(0, 1)) >=
            0 && (
            <g stroke={toothState.Perio === 1 ? "#ef4444" : "#3b82f6"}>
              <line x1="-2" y1="25" x2="-2" y2="20" strokeWidth="2" />
              <line x1="-2" y1="24" x2="22" y2="24" strokeWidth="2" />
              <line x1="22" y1="25" x2="22" y2="20" strokeWidth="2" />
            </g>
          )}
        {toothState.Extract === 1 && (
          <g stroke="#ef4444">
            <polygon fill="#cbd5e1" points="5,5 15,5 15,15 5,15" />
            <line x1="0" y1="0" x2="20" y2="20" strokeWidth="2" />
            <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2" />
          </g>
        )}
        {toothState.Extract === 2 && (
          <g stroke="#3b82f6">
            <line x1="0" y1="5" x2="20" y2="5" strokeWidth="3" />
            <line x1="0" y1="15" x2="20" y2="15" strokeWidth="3" />
          </g>
        )}
        {toothState.Endodoncia > 0 && (
          <g stroke={toothState.Endodoncia === 1 ? "#ef4444" : "#3b82f6"}>
            <line x1="1" y1="0" x2="1" y2="5" strokeWidth="3" />
            <line x1="0" y1="0" x2="20" y2="0" strokeWidth="2" />
            <line x1="19" y1="0" x2="19" y2="5" strokeWidth="3" />
            <line x1="8" y1="6" x2="8" y2="15" strokeWidth="3"></line>
            <line x1="7" y1="6" x2="14" y2="6" strokeWidth="2"></line>
            <line x1="8" y1="10" x2="12" y2="10" strokeWidth="2"></line>
            <line x1="8" y1="14" x2="14" y2="14" strokeWidth="2"></line>
          </g>
        )}
        {toothState.Crown > 0 && (
          <g stroke={toothState.Crown === 1 ? "#ef4444" : "#3b82f6"}>
            <line x1="0" y1="0" x2="0" y2="20" strokeWidth="2"></line>
            <line x1="-1" y1="0" x2="21" y2="0" strokeWidth="2"></line>
            <line x1="20" y1="0" x2="20" y2="20" strokeWidth="2"></line>
            <line x1="-1" y1="20" x2="21" y2="20" strokeWidth="2"></line>
          </g>
        )}
        {toothState.Cimplante > 0 && (
          <>
            <g stroke={toothState.Cimplante === 1 ? "#ef4444" : "#3b82f6"}>
              <line x1="0" y1="0" x2="0" y2="20" strokeWidth="2"></line>
              <line x1="-1" y1="0" x2="21" y2="0" strokeWidth="2"></line>
              <line x1="20" y1="0" x2="20" y2="20" strokeWidth="2"></line>
              <line x1="-1" y1="20" x2="21" y2="20" strokeWidth="2"></line>
            </g>
            <g stroke={toothState.Cimplante === 1 ? "#ef4444" : "#3b82f6"}>
              <line x1="10" y1="1" x2="10" y2="20" strokeWidth="4" />
              <line x1="5" y1="1" x2="15" y2="1" strokeWidth="3" />
              <line x1="5" y1="19" x2="15" y2="19" strokeWidth="3" />
            </g>
            <g stroke={"black"}>
              <line x1="8" y1="4" x2="12" y2="4" strokeWidth="2" />
              <line x1="8" y1="8" x2="12" y2="8" strokeWidth="2" />
              <line x1="8" y1="12" x2="12" y2="12" strokeWidth="2" />
              <line x1="8" y1="16" x2="12" y2="16" strokeWidth="2" />
            </g>
          </>
        )}
        {toothState.Fija > 0 && (
          <g stroke={toothState.Fija === 1 ? "#ef4444" : "#3b82f6"}>
            <line x1="0" y1="0" x2="0" y2="20" strokeWidth="2"></line>
            <line x1="-1" y1="0" x2="21" y2="0" strokeWidth="2"></line>
            <line x1="20" y1="0" x2="20" y2="20" strokeWidth="2"></line>
            <line x1="-1" y1="20" x2="21" y2="20" strokeWidth="2"></line>
          </g>
        )}
        {toothState.Removible > 0 && (
          <g stroke={toothState.Removible === 1 ? "#ef4444" : "#3b82f6"}>
            <polygon fill="#cbd5e1" points="5,5 15,5 15,15 5,15" />
            <line x1="0" y1="0" x2="0" y2="20" strokeWidth="2"></line>
            <line x1="-1" y1="0" x2="21" y2="0" strokeWidth="2"></line>
            <line x1="20" y1="0" x2="20" y2="20" strokeWidth="2"></line>
            <line x1="-1" y1="20" x2="21" y2="20" strokeWidth="2"></line>
            <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2" />
          </g>
        )}
        {toothState.Perno > 0 && (
          <g stroke={toothState.Perno === 1 ? "#ef4444" : "#3b82f6"}>
            <circle
              cx="10"
              cy="10"
              r="5"
              fill="none"
              stroke={toothState.Perno === 1 ? "#ef4444" : "#3b82f6"}
              strokeWidth="3"
            />
            <line x1="15" y1="5" x2="5" y2="15" strokeWidth="3" />
          </g>
        )}
      </>
    );
    return otherFigures;
  }
}

export default Tooth;
