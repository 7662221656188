import React, { useState, useRef, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { ModeloGetUrl } from "../ModeloCrud";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CapSentence } from "../utils/Utils";
import * as yup from "yup";
import "dayjs/locale/en-gb";
import crypto from "../Crypto";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalInformation from "../modals/ModalInformation";
import { CapSentenceArray } from "../utils/Utils";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CustomAutoComplete from "../CustomAutoComplete";

const validationSchema = yup.object({
  presupuestoitem: yup.object().required("Seleccione un Item"),
  paso: yup.object().required("Seleccione un Paso"),
  motivo: yup.object().required("Seleccione un Motivo"),
});

export default function InsertOrdenItem({
  newItems,
  setNewItems,
  setExpanded,
  presupuestoItems,
  presupuestoId,
  pasos,
}) {
  const [modalMessage, setModalMessage] = useState(null);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [itemPasos, setItemPasos] = useState([]);
  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const piezasDentales = crypto.decryptDataStorage("piezas_dentales");
  const nomencladores = CapSentenceArray(
    crypto.decryptDataStorage("nomencladores"),
    ["codigo_descripcion"]
  );
  const planId = crypto.decryptDataStorage("currentplanid");
  const [loading, setLoading] = useState(false);
  const tablafull = crypto.decryptDataStorage("tablafull");
  const motivos = tablafull
    .filter((obj) => obj.codigo_tabla === "MOTIVOS_ORDENES_TRABAJO")
    .map((obj) => ({ codigo: obj.valor, descripcion: obj.descripcion }));
  const autorizo = tablafull
    .filter((obj) => obj.codigo_tabla === "AUTORIZACIONES PRESUPUESTOS")
    .map((obj) => ({ codigo: obj.valor, descripcion: obj.descripcion }));
  const cubeta = tablafull
    .filter((obj) => obj.codigo_tabla === "TIPOS_DE_CUBETA")
    .map((obj) => ({ codigo: obj.valor, descripcion: obj.descripcion }));
  const formik = useFormik({
    initialValues: {
      presupuestoitem: null,
      paso: null,
      motivo: null,
      autorizo: null,
      cubeta: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      agregarItem(values);
    },
  });

  const onItemChange = (event, values) => {
    formik.setFieldValue("paso", null);
    formik.setFieldValue("presupuestoitem", values);
    const tmpPasos = pasos.filter(
      (item) => item.nomenclador_id === values?.nomenclador_id
    );
    setItemPasos(tmpPasos);
    if (values) {
      let selMotivo = motivos.filter((c) => c.codigo === "TN");
      if (selMotivo.length > 0) {
        formik.setFieldValue("motivo", selMotivo[0]);
      }
    }
  };

  const itemInputRef = useRef(null);
  const pasoInputRef = useRef(null);
  const motivoInputRef = useRef(null);
  const autorizoInputRef = useRef(null);
  const cubetaInputRef = useRef(null);
  const addButtonInputRef = useRef(null);
  const filteredOptions = presupuestoId
    ? [
        { isHeader: true }, // Add this as the first item to serve as the header
        ...presupuestoItems?.filter(
          (item) => item.presupuesto_id === presupuestoId
        ),
      ]
    : [];

  useEffect(() => {
    // Focus on the input element when it mounts
    if (addButtonInputRef.current) {
      addButtonInputRef.current.focus();
    }
  }, []);

  const renderPresupuestoOption = (props, option) => {
    const tableStyles = {
      width: "100%", // Ensure the table takes full width
      borderCollapse: "collapse", // Ensure there's no spacing between cells
    };

    const cellStyles = {
      padding: "8px 12px", // Consistent padding for both header and rows
    };
    if (option.isHeader) {
      return (
        <li style={{ pointerEvents: "none", padding: 0 }}>
          {/* Disable click events for the header */}
          <table style={tableStyles}>
            <thead>
              <tr style={{ backgroundColor: "#dbeafe" }}>
                {/* Set header background color */}
                <th align="left" style={{ ...cellStyles, width: 100 }}>
                  Código
                </th>
                <th align="left" style={{ ...cellStyles, width: 150 }}>
                  Descripción
                </th>
                <th align="left" style={{ ...cellStyles, width: 150 }}>
                  Pieza
                </th>
              </tr>
            </thead>
          </table>
        </li>
      );
    }

    return (
      <li {...props} style={{ padding: 0 }}>
        <table style={tableStyles}>
          <tbody>
            <tr>
              <td align="left" style={{ ...cellStyles, width: 100 }}>
                {option.codigo_nomenclador}
              </td>
              <td align="left" style={{ ...cellStyles, width: 150 }}>
                {CapSentence(option.descripcion_nomenclador.toLowerCase())}
              </td>
              <td align="left" style={{ ...cellStyles, width: 100 }}>
                {option.numero_pieza_dental}
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    );
  };

  const handleKeyDownPresupuesto = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      if (pasoInputRef.current) {
        pasoInputRef.current.focus();
      }
    }
  };

  async function agregarItem(values) {
    var row = {};
    if (newItems === null) {
      setNewItems([]);
    }
    let maxValue = newItems?.reduce(
      (acc, item) => (acc = acc > item.id ? acc : item.id),
      0
    );
    row["id"] = maxValue + 1;
    row["presupuesto_linea_id"] = formik.values.presupuestoitem.id;
    row["nomenclador_paso_id"] = formik.values.paso.id;
    row["motivo"] = formik.values.motivo?.codigo;
    row["autorizado_por"] = formik.values.autorizo?.codigo;
    row["cubeta"] = formik.values.cubeta?.codigo;

    row["codigo_nomenclador"] = formik.values.paso?.codigo_nom;
    row["numero_pieza_dental"] =
      formik.values.presupuestoitem?.numero_pieza_dental;
    row["codigo_nomenclador_paso"] = formik.values.paso?.codigo_paso;
    row["descripcion_nomenclador_paso"] = formik.values.paso?.descripcion_paso;

    newItems.push(row);
    const nextList = [...newItems];
    setNewItems(nextList);
    // setExpanded(false);
    //  setExpanded(true);
  }

  const handleKeyDownAutoComplete = (event) => {
    if (event.key === "Enter") {
      // Simulate tab key press
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 4].focus();
      event.preventDefault();
    }
  };

  const handleKeyDownAddButton = (event) => {
    if (event.key === "Enter") {
      // Trigger the click event
      event.preventDefault();
      event.target.click();
    }
  };

  const handleClose = () => {
    setExpanded(false);
  };

  return (
    <>
      <div>
        <form key="item-form" onSubmit={formik.handleSubmit}>
          <Container maxWidth="lg">
            <Box sx={{ marginTop: 2, marginBottom: 6 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <CustomAutoComplete
                    id="presupuestoitem"
                    options={filteredOptions}
                    value={formik.values.presupuestoitem}
                    label="Item Presupuesto"
                    formik={formik}
                    onChange={onItemChange}
                    getOptionLabel={(option) => {
                      if (
                        !option ||
                        !filteredOptions.some((opt) => opt.id === option.id) ||
                        option.isHeader
                      ) {
                        return ""; // Clear the label if the option is not in the options array or is a header
                      }
                      return `${option.codigo_nomenclador}  ${CapSentence(
                        option.descripcion_nomenclador.toLowerCase()
                      )} ${option.numero_pieza_dental.toString()}`;
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={renderPresupuestoOption}
                    setNullOnClear={true} // Do not set to null when cleared
                    nextRef={pasoInputRef}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomAutoComplete
                    id="paso"
                    options={formik.values.presupuestoitem ? itemPasos : []}
                    value={formik.values.paso}
                    label="Paso"
                    formik={formik}
                    getOptionLabel={(option) => {
                      if (!option || option.isHeader) {
                        return ""; // Clear the label if the option is not in the options array or is a header
                      }
                      return `${option.codigo_paso}  ${CapSentence(
                        option.descripcion_paso.toLowerCase()
                      )} `;
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={null}
                    inputRef={(el) => (pasoInputRef.current = el)}
                    nextRef={motivoInputRef}
                    setNullOnClear={true} // Do not set to null when cleared
                    handleKeyDown={null}
                  />
                </Grid>
                <Grid item xs={12} lg={3.5}>
                  <CustomAutoComplete
                    id="motivo"
                    options={motivos}
                    value={formik.values.motivo}
                    label="Motivo"
                    formik={formik}
                    getOptionLabel={(option) => {
                      return `${option.codigo}  ${CapSentence(
                        option.descripcion.toLowerCase()
                      )} `;
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.codigo === value.codigo
                    }
                    renderOption={null}
                    inputRef={(el) => (motivoInputRef.current = el)}
                    nextRef={autorizoInputRef}
                    setNullOnClear={true} // Do not set to null when cleared
                    handleKeyDown={null}
                  />
                </Grid>
                <Grid item xs={12} lg={3.5}>
                  <CustomAutoComplete
                    id="autorizo"
                    options={autorizo}
                    value={formik.values.autorizo}
                    label="Autorizó"
                    formik={formik}
                    getOptionLabel={(option) => {
                      return `${CapSentence(
                        option.descripcion.toLowerCase()
                      )} `;
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.codigo === value.codigo
                    }
                    renderOption={null}
                    inputRef={(el) => (autorizoInputRef.current = el)}
                    nextRef={cubetaInputRef}
                    setNullOnClear={true} // Do not set to null when cleared
                    handleKeyDown={null}
                  />
                </Grid>
                <Grid item xs={12} lg={3.5}>
                  <CustomAutoComplete
                    id="cubeta"
                    options={cubeta}
                    value={formik.values.cubeta}
                    label="Cubeta"
                    formik={formik}
                    getOptionLabel={(option) => {
                      return `${CapSentence(
                        option.descripcion.toLowerCase()
                      )} `;
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.codigo === value.codigo
                    }
                    renderOption={null}
                    inputRef={(el) => (cubetaInputRef.current = el)}
                    nextRef={addButtonInputRef}
                    setNullOnClear={true} // Do not set to null when cleared
                    keyDown={handleKeyDownAutoComplete}
                  />
                </Grid>

                <Grid item xs={4} lg={1}>
                  <Tooltip title="Agegar Item">
                    <LoadingButton
                      inputRef={addButtonInputRef} // Callback ref to assign the input element
                      onClick={formik.handleSubmit}
                      onKeyDown={handleKeyDownAddButton}
                      color="primary"
                      fullWidth
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<AddRoundedIcon />}
                      variant="contained"
                      sx={{
                        backgroundColor: "icongreen",
                        ":hover": {
                          bgcolor: "#475569", // theme.palette.primary.main
                          color: "white",
                        },
                        textTransform: "capitalize",
                        marginTop: 0,
                        height: 40,
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </form>
      </div>
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
    </>
  );
}
