import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as moment from "moment";
import loadingGif from "../assetss/img/loading_64.gif";
import SubirArchivo from "./SubirArchivo";
import { CapSentence, hasPermission } from "./utils/Utils";
import BorrarImagen from "./BorrarImagen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ModalInformation from "./modals/ModalInformation";
import ModalConfirmation from "./modals/ModalConfirmation";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { subirLogoOdontologos } from "./utils/Utils";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import FaceIcon from "@mui/icons-material/Face";
import Face2Icon from "@mui/icons-material/Face2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import { ModeloDelete } from "./ModeloCrud";
import crypto from "./Crypto";

export default function OdontologosDatos({ currentOdontologo, metodolimpiar }) {
  const [loadingRx, setLoadingRx] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [openSubirArchivo, setOpenSubirArchivo] = useState(false);
  const history = useHistory();
  const [showConfirmationBorrar, setShowConfirmationBorrar] = useState(false);
  const userRoles =
    crypto.decryptLocalStorage("user_roles") !== null
      ? crypto.decryptLocalStorage("user_roles")
      : [];

  const hideInformationModal = () => {
    setModalMessage(null);
    setModalInformationOpen(false);
  };
  const hideConfirmationBorrar = () => {
    setShowConfirmationBorrar(false);
    setModalMessage(null);
  };
  async function handleDelete() {
    hideConfirmationBorrar();
    const odontologoid = currentOdontologo.id;
    let result = await ModeloDelete("odontologo", odontologoid);

    if (result.error !== false) {
      setModalMessage(result.errormessage);
      setModalErrorOpen(true);
    } else {
      metodolimpiar();
    }
  }

  const okMethod = () => {
    setModalMessage("El archivo se subió correctamente.");
    setModalSuccessOpen(true);
  };

  const hideSuccessModal = () => {
    setModalMessage(null);
    setModalSuccessOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditar = () => {
    history.push({
      pathname: "/insertupdateodontologo",
      currentOdontologo,
    });
  };
  const handleBorrar = () => {
    setModalMessage("Seguro quiere borrar el registro ?");
    setShowConfirmationBorrar(true);
  };
  useEffect(() => {}, []);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const hideErrorModal = () => {
    setModalMessage(null);
    setModalErrorOpen(false);
  };

  return (
    <>
      {loadingRx && (
        <div className="ml-2 mt-2">
          <img alt="Loading" src={loadingGif} />
        </div>
      )}
      <Card
        sx={{
          ml: -2,
          mr: -2,
          backgroundColor: "bgcards",
          marginBottom: 2,
          height: "100%",
        }}
      >
        <CardHeader
          action={
            <>
              <Tooltip title="Menu Odontologos">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ mt: 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32, bgcolor: "avatar" }}>
                    <MoreVertIcon />
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {hasPermission(userRoles, "MODIFICACION CODONTO") && (
                  <MenuItem onClick={handleEditar}>
                    <ListItemIcon>
                      <EditIcon
                        fontSize="medium"
                        sx={{ color: "primary.main", mr: 1 }}
                      />
                    </ListItemIcon>
                    Editar
                  </MenuItem>
                )}
                {hasPermission(userRoles, "BAJA CODONTO") && (
                  <MenuItem onClick={handleBorrar}>
                    <ListItemIcon>
                      <DeleteIcon
                        fontSize="medium"
                        sx={{ color: "iconred", mr: 1 }}
                      />
                    </ListItemIcon>
                    Borrar
                  </MenuItem>
                )}
                <MenuItem onClick={metodolimpiar}>
                  <ListItemIcon>
                    <CleaningServicesIcon
                      fontSize="medium"
                      sx={{ color: "primary.main", mr: 1 }}
                    />
                  </ListItemIcon>
                  Limpiar Pantalla
                </MenuItem>

                <Divider />
              </Menu>
            </>
          }
          title={CapSentence(currentOdontologo.nombre_completo.toLowerCase())}
          subheader={
            <>
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: "Open Sans",
                  fontWeight: "medium",
                  color: "primary.main",
                }}
              >
                Matrícula: {currentOdontologo.matricula}
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontFamily: "Open Sans",
                  fontWeight: "medium",
                }}
              >
                Id: {currentOdontologo.id}
              </Typography>
            </>
          }
        />

        <Box sx={{ mt: 0, ml: 3 }}>
          {currentOdontologo.sexo !== null &&
            ["f", "m"].indexOf(currentOdontologo.sexo.toLowerCase()) >= 0 && (
              <div className="flex mt-2 -ml-3 mb-4 items-end">
                {currentOdontologo.sexo.toLowerCase() === "m" && (
                  <FaceIcon sx={{ color: "primary.main", fontSize: 40 }} />
                )}
                {currentOdontologo.sexo.toLowerCase() === "f" && (
                  <Face2Icon sx={{ color: "pink", fontSize: 40 }} />
                )}
              </div>
            )}

          <div className="flex mt-2 -ml-2 items-end">
            <i className='far fa-calendar-alt fa-xl mr-5" text-sky-600' />
            <p className="ml-2">
              {currentOdontologo.fechaalta !== null &&
              currentOdontologo.fechaalta.length > 0
                ? "Fecha  Alta: " +
                  moment(currentOdontologo.fechaalta).format("DD/MM/YYYY")
                : ""}
            </p>
          </div>
          <div className="flex mt-4 -ml-2 items-end">
            {currentOdontologo.fechabaja !== null && (
              <i className='far fa-calendar-alt fa-xl mr-5" text-red-600' />
            )}
            {currentOdontologo.fechabaja === null && (
              <i className='far fa-calendar-alt fa-xl mr-5" text-emerald-600' />
            )}
            <p className="ml-2">
              {currentOdontologo.fechabaja !== null &&
              currentOdontologo.fechabaja.length > 0
                ? "Fecha Baja: " +
                  moment(currentOdontologo.fechabaja).format("DD/MM/YYYY")
                : "Fecha de Baja:"}
            </p>
          </div>
          <div className="flex mt-4 -ml-2 items-end">
            <i className="fas fa-mobile-alt fa-xl  text-slate-500" />
            <p className="ml-2 mr-6">
              {currentOdontologo.celular !== null &&
              currentOdontologo.celular.length > 0
                ? currentOdontologo.celular
                : ""}
            </p>
          </div>
          <div className="flex mt-4 -ml-2 items-end lowercase">
            <i className="fas fa-envelope fa-xl text-slate-500" />
            <p className=" text-sky-600 underline ml-2">
              {currentOdontologo.email !== null &&
              currentOdontologo.email.length > 0
                ? currentOdontologo.email.toLowerCase()
                : ""}
            </p>
          </div>
          <div className="flex mt-4 -ml-2 items-end">
            <p className="ml-0">
              {currentOdontologo.cuit !== null &&
              currentOdontologo.cuit.length > 0
                ? "Cuit: " + currentOdontologo.cuit
                : ""}
            </p>
          </div>
          <div className="flex mt-4 -ml-2 mb-4 items-end ">
            <p className="ml-0 mr-2">Relación de Dependencia: </p>
            {currentOdontologo.relacion_dependencia !== null &&
              currentOdontologo.relacion_dependencia.toString() === "1" && (
                <CheckCircleIcon
                  sx={{
                    color: "primary.green",
                    fontSize: 24,
                    mr: 2,
                  }}
                />
              )}
            {currentOdontologo.relacion_dependencia === null ||
              (currentOdontologo.relacion_dependencia.toString() !== "1" && (
                <CancelIcon
                  sx={{
                    color: "iconred",
                    fontSize: 24,
                    mr: 2,
                  }}
                />
              ))}
          </div>
          <div className="flex mt-4 -ml-2 items-end">
            <p className="ml-0">
              {currentOdontologo.importe_seguro !== null &&
              currentOdontologo.importe_seguro !== "0.00"
                ? "Seguro: " + currentOdontologo.importe_seguro
                : "Seguro: "}
            </p>
          </div>
        </Box>
      </Card>
      <ModalInformation
        showModal={modalErrorOpen && modalMessage !== null}
        hideModal={hideErrorModal}
        message={modalMessage}
        tipo="error"
      />
      <ModalInformation
        showModal={modalInformationOpen && modalMessage !== null}
        hideModal={hideInformationModal}
        message={modalMessage}
        tipo="info"
      />
      <ModalInformation
        showModal={modalSuccessOpen && modalMessage !== null}
        hideModal={hideSuccessModal}
        message={modalMessage}
        tipo="success"
      />
      <ModalConfirmation
        showModal={showConfirmationBorrar}
        confirmModal={handleDelete}
        hideModal={hideConfirmationBorrar}
        message={modalMessage}
      />
    </>
  );
}
