import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Avatar,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import * as moment from "moment";
import crypto from "../Crypto";
import { CapSentence, handleKeyDownField } from "../utils/Utils";
import { ModeloGetUrlJson } from "../ModeloCrud";
import CustomAutoComplete from "../CustomAutoComplete";
import { RenderReportLiquidacionLaboratorios } from "../presupuestos/RenderReportLiquidacionLaboratorios";
import { PrintButton } from "../PrintButton";

export default function ReporteCLiquidacionLaboratorios(props) {
  const [loading, setLoading] = useState(false);
  let [reportData, setReportData] = useState(null);
  const now = new Date().toISOString().slice(0, 10);
  const [cajas, setCajas] = useState(crypto.decryptDataStorage("cajas"));
  let [cajasLista, setCajasLista] = useState([]);
  const [remitoDesde, setRemitoDesde] = useState("1");
  const [remitoHasta, setRemitoHasta] = useState("99999999");
  const allCentros =
    crypto.decryptDataStorage("centros") !== null
      ? crypto.decryptDataStorage("centros")
      : [];
  const [laboratorios, setLaboratorios] = useState(
    crypto.decryptDataStorage("laboratorios")
  );
  const validationSchema = yup.object({
    clinica: yup.object().required("Campo Obligatorio."),
    laboratorio: yup.object().required("Campo Obligatorio."),
    fecha_desde: yup
      .date()
      .nullable()
      .typeError("Fecha inválida.")
      .required("Campo obligatorio."),
    fecha_hasta: yup
      .date()
      .min(
        yup.ref("fecha_desde."),
        "Fecha hasta debe ser mayor o igual a desde."
      )
      .nullable()
      .typeError("Fecha inválida.")
      .required("Campo obligatorio."),
  });
  const fechadesdeInputRef = useRef(null);
  const fechahastaInputRef = useRef(null);
  const clinicaInputRef = useRef(null);
  const laboratorioInputRef = useRef(null);
  const remitodesdeInputRef = useRef(null);
  const remitohastaInputRef = useRef(null);
  const searchInputRef = useRef(null);
  const componentRef = useRef();

  useEffect(() => {
    if (fechadesdeInputRef.current) {
      fechadesdeInputRef.current.focus(); // Set focus on the first input field
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      fecha_desde: dayjs(Date()),
      fecha_hasta: dayjs(Date()),
      clinica: null,
      laboratorio: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClickBuscar(values);
    },
  });

  const onClinicasChange = (event, values) => {
    formik.setFieldValue("clinica", values);
  };

  async function handleClickBuscar(values) {
    setReportData(null);
    let params =
      "fecha-desde=" +
      values.fecha_desde.format().slice(0, 10) +
      "&fecha-hasta=" +
      values.fecha_hasta.format().slice(0, 10);
    if (values.clinica !== null) {
      params += "&centro_id=" + values.clinica.id;
    }
    if (values.laboratorio !== null) {
      params += "&laboratorio_id=" + values.laboratorio.id;
    }
    params +=
      "&remito-desde=" +
      String(remitoDesde).padStart(8, "0") +
      "&remito-hasta=" +
      String(remitoHasta).padStart(8, "0");
    setLoading(true);
    const result = await ModeloGetUrlJson(
      "orden-trabajo",
      null,
      "remitos-entregados",
      params
    );
    setLoading(false);
    if (result.error === false) {
      setReportData(result.listado);
    }
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          marginBottom: 4,
        }}
      >
        <Typography variant="h6">Liquidación de laboratorios</Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          paddingBottom: 2,
          borderBottom: 1,
          borderColor: "grey.400",
        }}
      >
        <Grid item xs={12} md={6} lg={2}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DateField
              id="fecha_desde"
              label="Desde"
              inputRef={(el) => (fechadesdeInputRef.current = el)}
              value={formik.values.fecha_desde}
              inputFormat="DD/MM/YYYY"
              format="DD/MM/YYYY"
              onChange={(value) => formik.setFieldValue("fecha_desde", value)}
              size="small"
              helperText={
                formik.touched.fecha_desde ? formik.errors.fecha_desde : ""
              }
              error={
                formik.touched.fecha_desde && Boolean(formik.errors.fecha_desde)
              }
              onKeyDown={(e) => handleKeyDownField(e, fechahastaInputRef)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"en-gb"}
          >
            <DateField
              id="fecha_hasta"
              label="Hasta"
              inputRef={(el) => (fechahastaInputRef.current = el)}
              value={formik.values.fecha_hasta}
              inputFormat="DD/MM/YYYY"
              format="DD/MM/YYYY"
              onChange={(value) => formik.setFieldValue("fecha_hasta", value)}
              size="small"
              helperText={formik.errors.fecha_hasta}
              error={Boolean(formik.errors.fecha_hasta)}
              onKeyDown={(e) => handleKeyDownField(e, clinicaInputRef)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Autocomplete
            id="clinica"
            options={allCentros}
            value={formik.values.clinica}
            autoHighlight
            size="small"
            onChange={onClinicasChange}
            getOptionLabel={(option) => option.razonsocial}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: option.color,
                    marginBottom: 0.5,
                    marginRight: 1,
                    border: 1,
                    borderColor: "black",
                  }}
                >
                  {option.identificador}
                </Avatar>
                {option.razonsocial}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Clínica"
                error={formik.touched.clinica && Boolean(formik.errors.clinica)}
                helperText={formik.touched.clinica && formik.errors.clinica}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { justifyContent: "center" },
                }}
                inputRef={(el) => (clinicaInputRef.current = el)}
                onKeyDown={(e) => handleKeyDownField(e, laboratorioInputRef)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CustomAutoComplete
            id="laboratorio"
            options={laboratorios}
            value={formik.values.laboratorio}
            label="Laboratorio"
            formik={formik}
            getOptionLabel={(option) =>
              CapSentence(option.codigo.toLowerCase()) +
              " - " +
              CapSentence(option.razon_social.toLowerCase())
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={null} // Custom option rendering
            inputRef={(el) => (laboratorioInputRef.current = el)}
            nextRef={remitodesdeInputRef}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            label="Remito desde"
            inputRef={(el) => (remitodesdeInputRef.current = el)}
            size="small"
            fullWidth
            value={remitoDesde}
            onChange={(e) => {
              setRemitoDesde(e.target.value);
            }}
            onKeyDown={(e) => handleKeyDownField(e, remitohastaInputRef)}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            label="Remito hasta"
            inputRef={(el) => (remitohastaInputRef.current = el)}
            size="small"
            fullWidth
            value={remitoHasta}
            onChange={(e) => {
              setRemitoHasta(e.target.value);
            }}
            onKeyDown={(e) => handleKeyDownField(e, searchInputRef, true)}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <LoadingButton
            size="medium"
            inputRef={(el) => (searchInputRef.current = el)}
            onClick={formik.handleSubmit}
            loading={loading}
            loadingPosition="start"
            endIcon={<SearchIcon />}
            sx={{
              backgroundColor: "primary.main",
              ":hover": {
                bgcolor: "#475569", // theme.palette.primary.main
                color: "white",
              },
              width: 80,
              marginTop: { xs: 3, md: 0.5 },
              marginLeft: { xs: 0, md: 1 },
            }}
            variant="contained"
          >
            <span></span>
          </LoadingButton>
        </Grid>
      </Grid>
      {reportData !== null && reportData.length > 0 && (
        <Box
          sx={{
            marginLeft: 3,
            marginTop: 4,
            marginBottom: 1,
          }}
        >
          <div style={{ display: "none" }}>
            <RenderReportLiquidacionLaboratorios
              data={reportData}
              ref={componentRef}
              labInfo={formik.values.laboratorio.razon_social}
              dateRange={
                formik.values.fecha_desde.format("DD/MM/YYYY").slice(0, 10) +
                "-" +
                formik.values.fecha_hasta.format("DD/MM/YYYY").slice(0, 10)
              }
              clinic={formik.values.clinica.razonsocial}
            />
          </div>
          <PrintButton
            componentToPrint={componentRef}
            text={"Imprimir liquidación"}
            beforePrint={null}
            buttonType={"button"}
          />
        </Box>
      )}

      {reportData !== null && reportData.length === 0 && (
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          No se encontraron resultados.
        </Typography>
      )}
    </Container>
  );
}
