import React, { useState, useEffect } from "react";
import "../../assetss/css/App.css";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CoeAutocomplete from "../CoeAutocomplete";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ModeloGetUrl } from "../ModeloCrud";
import { Typography } from "@mui/material";
import { CSVLink } from "react-csv";
import * as moment from "moment";

export default function ReporteTurnosPorAgenda() {
  let [selectedClinica, setSelectedClinica] = useState(null);
  let [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  let [selectedOdontologo, setSelectedOdontologo] = useState(null);
  let [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const now = new Date().toISOString().slice(0, 10);
  const [fechaDesde, setFechaDesde] = useState(dayjs(now));
  const [fechaHasta, setFechaHasta] = useState(dayjs(now));
  const [valueHabilitadas, setValueHabilitadas] = React.useState("todas");
  const [fileName, setFileName] = useState(null);

  const handleChangeHabilitadas = (event) => {
    setValueHabilitadas(event.target.value);
  };

  async function handleClickBuscar() {
    setReportData(null);
    const Today = new Date();
    const Fecha = moment(Today).format("YYYY-MM-DD-HHmmss");
    const FileName = "TurnosAgendas" + Fecha + ".csv";
    setFileName(FileName);
    let params =
      "fechadesde=" +
      fechaDesde.toISOString().slice(0, 10) +
      "&fechahasta=" +
      fechaHasta.toISOString().slice(0, 10);
    if (selectedClinica !== null) {
      params += "&centro_id=" + selectedClinica.id;
    }
    if (selectedEspecialidad !== null) {
      params += "&especialidad_id=" + selectedEspecialidad.id;
    }
    if (selectedOdontologo !== null) {
      params += "&odontologo_id=" + selectedOdontologo.id;
    }
    if (valueHabilitadas === "habilitadas") {
      params += "&habilitadas=SI";
    }
    if (valueHabilitadas === "bloqueadas") {
      params += "&bloqueadas=SI";
    }

    setLoading(true);
    const result = await ModeloGetUrl(
      "turno",
      null,
      "turnos-por-agenda",
      params
    );
    setLoading(false);
    if (result.error === false) {
      setReportData(result.listado);
    }
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          marginBottom: 4,
          marginLeft: 1,
        }}
      >
        <Typography variant="h6">Turnos por agenda</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "start",
          marginTop: 1,
          marginBottom: 5,
          marginLeft: 1,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={"en-gb"}
        >
          <DatePicker
            label="Fecha Desde"
            value={fechaDesde}
            slotProps={{ textField: { size: "small" } }}
            onChange={(newValue) => setFechaDesde(newValue)}
            sx={{
              height: "1rem",
              width: "17.8rem",
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={"en-gb"}
        >
          <DatePicker
            label="Fecha Hasta"
            value={fechaHasta}
            slotProps={{ textField: { size: "small" } }}
            onChange={(newValue) => setFechaHasta(newValue)}
            sx={{
              height: "1rem",
              width: "17.8rem",
              marginLeft: { xs: 0, md: 2 },
              marginTop: { xs: 6, md: 0 },
            }}
          />
        </LocalizationProvider>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={valueHabilitadas}
          onChange={handleChangeHabilitadas}
          sx={{
            marginLeft: { xs: 0, md: 4 },
            marginTop: { xs: 6, md: 0 },
          }}
        >
          <FormControlLabel value="todas" control={<Radio />} label="Todas" />
          <FormControlLabel
            value="habilitadas"
            control={<Radio />}
            label="Habilitadas"
          />
          <FormControlLabel
            value="bloqueadas"
            control={<Radio />}
            label="Bloqueadas"
          />
        </RadioGroup>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          marginBottom: 5,
        }}
      >
        <CoeAutocomplete
          selectedClinica={selectedClinica}
          selectedEspecialidad={selectedEspecialidad}
          selectedOdontologo={selectedOdontologo}
          setSelectedClinica={setSelectedClinica}
          setSelectedEspecialidad={setSelectedEspecialidad}
          setSelectedOdontologo={setSelectedOdontologo}
          handlebusqueda={handleClickBuscar}
          loading={loading}
          onlyEspecialidad={true}
        />
      </Box>
      {reportData !== null && reportData.length > 0 && (
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6" sx={{ marginLeft: 2 }}>
            Se encontraron {reportData.length} resultados.
          </Typography>
          <CSVLink data={reportData} separator={";"} filename={fileName}>
            <Box sx={{ display: "flex", marginLeft: 4 }}>
              <i class="fa-solid fa-file-csv fa-xl ml-1 mt-3 mr-2 text-emerald-600"></i>
              <Typography variant="h6">Descargar </Typography>
            </Box>
          </CSVLink>
        </Box>
      )}
      {reportData !== null && reportData.length === 0 && (
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          No se encontraron resultados.
        </Typography>
      )}
    </Container>
  );
}
