import React from "react";
import Box from "@mui/material/Box";
import Image from "mui-image";
import Typography from "@mui/material/Typography";

const BoxButton = (props) => {
  const { handleClick, text, image } = props;
  return (
    <Box
      onClick={() => handleClick()}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "12rem",
        height: image ? "10rem" : "2.5rem",
        color: "#64748b",
        p: 1,
        border: 1,
        borderRadius: 2,
        borderColor: "#94a3b8",
        marginTop: image ? 4 : 0,
        "&:hover": {
          boxShadow: 12,
          cursor: "pointer",
          color: "primary.main",
          opacity: "100%",
        },
        opacity: "70%",
      }}
    >
      {image && <Image src={image} height="80px" width="80px" duration={0} />}
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontSize: 22,
          marginTop: 1,
          marginLeft: 1,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default BoxButton;
